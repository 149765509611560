import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/images/IconLogo.webp";
import IconOtp from "../assets/images/OTP.webp";
import "./SendOtp.css";
import { useSelector } from "react-redux";
import axios from "axios";
import CONFIG from "../config/config";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useDispatch } from "react-redux";
import { userAction } from "../actions";
import CircularProgress from "@mui/material/CircularProgress";
import Loading from "../components/Loading";
import PopUP from "../components/SuccessLogin";
let interval;

export const SendOtp = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { dataUser } = useSelector((state) => state.UserReducer);
  const { screenName } = useSelector((state) => state.ScreenReducer);
  const [timer, setTimer] = useState(60);
  const [otp_code, setOtp_code] = useState(0);
  const [loadingApi, setLoadingApi] = useState(false);
  const [alertSukses, setAlertSukses] = useState(false);
  const [alertDataSukses, setAlertDataSukses] = useState("");
  const [buttonTimbul, setButtonTimbul] = useState(false);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);
  const [popUpDes, setPopUpDes] = useState("");
  const [popUpTitle, setPopUpTitle] = useState("");
  const [popUpView, setPopUpView] = useState(false);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  function setTime() {
    interval = setInterval(() => setTimer((prevState) => prevState - 1), 1000);
  }

  function alertSuccess() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertSukses(false);
            if (alertDataSukses === "Silahkan register ulang kembali") {
              window.location.href = "/login";
            } else if (
              alertDataSukses ===
              "Harap menunggu dari sales kami untuk menghubungi nomor anda yang teregistrasi untuk keperluan persyaratan dokumen dan akses login"
            ) {
              window.location.href = "/login";
            } else if (
              alertDataSukses ===
              "Anda telah berhasil mendaftar, silahkan klik ok untuk melanjutkan menggunakan aplikasi."
            ) {
              window.location.href = "/login";
            } else if (
              alertDataSukses ===
              "Nomor telepon sudah digunakan, silahkan menggunakan nomor telepon lainnya"
            ) {
              navigate(-2)
            } else if (alertDataSukses === "Akun anda sudah terdaftar") {
              window.location.href = "/login";
            }
          }}
          variant="filled"
          severity="success"
        >
          {alertDataSukses}
        </Alert>
      </Stack>
    );
  }

  useEffect(() => {
    setTime();

    return () => {};
  }, []);

  useEffect(() => {
    return () => {
      clearInterval(interval);
    };
  }, [timer == 0 ? clearTime() : null]);

  function clearTime() {
    setTimer(60);
    setButtonTimbul(true);
    clearInterval(interval);
  }

  async function sendOTP() {
    const formData = {
      phone: dataUser.phone,
      customer_code: dataUser.customer_code,
    };
    setLoadingApi(true);
    try {
      let response;
      if (screenName == "WAOTPNEW" || screenName == "WAOTPOLD" || screenName == 'WAOTPDISTRIBUTOR') {
        response = await axios({
          method: "POST",
          url: `${CONFIG.BASE_URL}/api/otp/wa`,
          data: formData,
        });
      } else if (screenName == "SMSOTPOLD" || screenName == "SMSOTPNEW" || screenName == "SMSOTPDISTRIBUTOR") {
        console.log('masuk register old atau new via sms')
        response = await axios({
          method: "POST",
          url: `${CONFIG.BASE_URL}/api/otp`,
          data: formData,
        });
      } else if (screenName == "SMSOTPFORGET") {
        console.log('masuk sms forget')
        response = await axios({
          method: "POST",
          url: `${CONFIG.BASE_URL}/api/otp/no-auth/sms`,
          data: formData,
        });
      } else if (screenName == "WAOTPFORGET") {
        console.log('masuk wa forget')
        response = await axios({
          method: "POST",
          url: `${CONFIG.BASE_URL}/api/otp/no-auth/wa`,
          data: formData,
        });
      }
      // console.log(response)
      const data = response.data;
      if (data !== "" && data["success"] == true) {
        setLoadingApi(true);
        setTime();
        console.log("HASIL SEND OTP", data.data);
      } else {
        console.log("", typeof data);
        // this.setState({
        //   alertData: 'Gagal mengirim otp ' + data.message,
        //   modalAlert: !this.state.modalAlert,
        // });
        return false;
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error===========sendOTP=============",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        // navigate('/home')
      }
    }
  }

  async function cekOTP() {
    const formData = {
      otp_code: otp_code,
      phone: dataUser.phone,
    };
    const newFormData = new FormData();
    newFormData.append("name", dataUser.name);
    newFormData.append("shop_name", dataUser.shop_name);
    newFormData.append("phone", dataUser.phone);
    newFormData.append("email", dataUser.email);
    newFormData.append("password", dataUser.password);
    newFormData.append("password_confirmation", dataUser.password_confirmation);
    newFormData.append("address", dataUser.address);
    newFormData.append("province", dataUser.province);
    newFormData.append("city", dataUser.city);
    newFormData.append("fcm_token", dataUser.fcm_token);
    newFormData.append("district", dataUser.district);
    newFormData.append("subdistrict", dataUser.subdistrict);
    newFormData.append("postal_code", dataUser.postal_code);
    newFormData.append("shareloc", dataUser.shareloc);
    newFormData.append("photo_toko", dataUser.photo_toko);
    newFormData.append("selfie_ktp", dataUser.selfie_ktp);
    dataUser?.photo_npwp == null
      ? newFormData.append("photo_ktp", dataUser.photo_ktp)
      : newFormData.append("photo_npwp", dataUser.photo_npwp);
    setLoadingApi(true);
    try {
      let response = await axios({
        method: "POST",
        url: `${CONFIG.BASE_URL}/api/otp/verify`,
        data: formData,
      });
      const data = response.data;
      if (data !== "" && data["success"] == true) {
        if (dataUser.name_company) {
          const formData = JSON.stringify(dataUser);
          await axios
            .post(
              `${CONFIG.BASE_URL}/api/distributor-partner/auth/register`,
              formData,
              {
                headers: {
                  "content-type": "application/json",
                },
              }
            )
            .then((response) => {
              const data = response.data;
              if (data !== "" && data["success"] == true) {
                setLoadingApi(false);
                setPopUpTitle("VERIFIKASI SUKSES");
                setPopUpDes(
                  "Harap menunggu dari sales kami untuk menghubungi nomor anda yang teregistrasi untuk keperluan persyaratan dokumen dan akses login"
                );
                setPopUpView(true);
              } else {
                let message = data.message;
                if (message == "The phone has already been taken.") {
                  setPopUpDes(
                    "Nomor telepon sudah digunakan, silahkan menggunakan nomor telepon lainnya"
                  );
                  setPopUpView(true);
                } else if (message == "Account has been registered") {
                  setPopUpDes("Akun anda sudah terdaftar");
                } else {
                  setPopUpDes(message);
                }
                setPopUpTitle("VERIFIKASI GAGAL");
                setPopUpView(true);
              }
            })
            .catch((error) => {
              let error429 =
                JSON.parse(JSON.stringify(error)).message ===
                "Request failed with status code 429";
              let errorNetwork =
                JSON.parse(JSON.stringify(error)).message === "Network Error";
              let error400 =
                JSON.parse(JSON.stringify(error)).message ===
                "Request failed with status code 400";
              console.log(
                "Cek Error===========DataSearching=============",
                JSON.parse(JSON.stringify(error)).message
              );
              if (error429) {
                setAlertServerSibuk(true);
              } else if (errorNetwork) {
                setAlertInet(true);
              } else if (error400) {
                localStorage.removeItem("token");
                // navigate('/home')
              }
            });
        } else {
          const formData = JSON.stringify(dataUser);
          if (screenName == "SMSOTPOLD" || screenName == "WAOTPOLD") {
            await axios
              .post(`${CONFIG.BASE_URL}/api/auth/erp/register`, newFormData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${dataUser.fcm_token}`,
                },
              })
              .then((response) => {
                const data = response.data;
                if (data !== "" && data["success"] == true) {
                  dispatch(userAction(data.data.user, "dataUser"));
                  setLoadingApi(false);
                  setPopUpTitle("VERIFIKASI SUKSES");
                  setPopUpDes(
                    "Akun anda sudah terdaftar di sistem kami, silahkan masuk dengan nomor telepon dan password yang sudah terdaftar"
                  );
                  setPopUpView(true);
                } else {
                  let message = data.message;
                  setLoadingApi(false);
                  if (message == "The phone has already been taken.") {
                    setPopUpDes(
                      "Nomor telepon sudah digunakan, silahkan menggunakan nomor telepon lainnya"
                    );
                  } else if (message == "Account has been registered") {
                    setPopUpDes("Akun anda sudah terdaftar");
                  } else {
                    setPopUpDes(message);
                  }
                  setPopUpTitle("VERIFIKASI GAGAL");
                  setPopUpView(true);
                }
              })
              .catch((error) => {
                setLoadingApi(false);
                let error429 =
                  JSON.parse(JSON.stringify(error)).message ===
                  "Request failed with status code 429";
                let errorNetwork =
                  JSON.parse(JSON.stringify(error)).message === "Network Error";
                let error400 =
                  JSON.parse(JSON.stringify(error)).message ===
                  "Request failed with status code 400";
                console.log(
                  "Cek Error===========DataSearching=============",
                  JSON.parse(JSON.stringify(error)).message
                );
                if (error429) {
                  setAlertServerSibuk(true);
                } else if (errorNetwork) {
                  setAlertInet(true);
                } else if (error400) {
                  localStorage.removeItem("token");
                  // navigate('/home')
                }
              });
          } else if (screenName == "WAOTPNEW" || screenName == "SMSOTPNEW") {
            await axios
              .post(
                `${CONFIG.BASE_URL}/api/auth/register?status=2`,
                newFormData,
                {
                  headers: {
                    "content-type": "multipart/form-data",
                  },
                }
              )
              .then((response) => {
                const data = response.data;
                if (data !== "" && data["success"] == true) {
                  setLoadingApi(false);
                  setPopUpTitle("VERIFIKASI SUKSES");
                  setPopUpDes(
                    "Anda telah berhasil mendaftar, silahkan klik ok untuk melanjutkan menggunakan aplikasi."
                  );
                  setPopUpView(true);
                } else {
                  let message = data.message;
                  setLoadingApi(false);
                  if (message == "The phone has already been taken.") {
                    setPopUpDes(
                      "Nomor telepon sudah digunakan, silahkan menggunakan nomor telepon lainnya"
                    );
                  } else if (message == "Account has been registered") {
                    setPopUpDes("Akun anda sudah terdaftar");
                  } else {
                    setPopUpDes(message);
                  }
                  setPopUpTitle("VERIFIKASI GAGAL");
                  setPopUpView(true);
                }
              })
              .catch((error) => {
                setLoadingApi(false);
                let error429 =
                  JSON.parse(JSON.stringify(error)).message ===
                  "Request failed with status code 429";
                let errorNetwork =
                  JSON.parse(JSON.stringify(error)).message === "Network Error";
                let error400 =
                  JSON.parse(JSON.stringify(error)).message ===
                  "Request failed with status code 400";
                console.log(
                  "Cek Error===========DataSearching=============",
                  JSON.parse(JSON.stringify(error)).message
                );
                if (error429) {
                  setAlertServerSibuk(true);
                } else if (errorNetwork) {
                  setAlertInet(true);
                } else if (error400) {
                  localStorage.removeItem("token");
                  // navigate('/home')
                }
              });
          } else if (
            screenName == "SMSOTPFORGET" ||
            screenName == "WAOTPFORGET"
          ) {
            setLoadingApi(false);
            navigate("/forget-password/form");
          }
        }
      } else {
        setPopUpTitle(data?.message);
        setPopUpView(true);
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error===========DataSearching=============",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        // navigate('/home')
      } else {
        setPopUpTitle("VERIFIKASI GAGAL");
        setPopUpDes("pastikan kode otp sesuai")
        setPopUpView(true);
      }
    }
  }

  function handleBackToRegister() {
    setAlertDataSukses("Silahkan register ulang kembali");
    setAlertSukses(true);
  }
  function closeView() {
    setPopUpView(false);
  }
  return (
    <div className="container">
      <img
        onClick={() => navigate(-1)}
        src={Logo}
        alt="Logo"
        className="logo"
      />
      <h1 className="judul">{"Kode OTP"}</h1>
      <PopUP
        titleDescription={popUpDes}
        titleAlert={popUpTitle}
        handleClose={closeView}
        open={popUpView}
      />
      <div className="containerInputLogin">
        <img src={IconOtp} alt="iconOtp" className="iconOtp" />
        <input
          onChange={(e) => setOtp_code(e.target.value)}
          className="textInputLogin"
          placeholder="Kode OTP"
        />
      </div>
      <button className="buttonOK" onClick={cekOTP}>
        <p className="textButtonOK">{"OK"}</p>
      </button>
      {buttonTimbul ? (
        <div className="link">
          {timer == 60 ? (
            <a onClick={sendOTP} className="linkResendOtp">
              Kirim Ulang OTP
            </a>
          ) : (
            <a className="linkResendOtp">
              {"Kirim Ulang OTP"}
              {" ("}
              {timer}
              {")"}
            </a>
          )}
          <a onClick={handleBackToRegister} className="linkWrongNumber">
            Nomor Telepon Salah ?
          </a>
        </div>
      ) : null}
      {alertSukses ? alertSuccess() : null}
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SendOtp);
