import Splash from "./assets/images/Splash.webp";
import Splash2 from "./assets/images/Splash2.webp";
import Splash3 from "./assets/images/Splash3.webp";
import Splash4 from "./assets/images/Splash4.webp";

export default [
  {
    src: Splash,
    text: "Semua view dan produk kami ada disini",
  },
  {
    src: Splash2,
    text: "Pembelian aman dan data lebih transparan",
  },
  {
    src: Splash3,
    text: "Pembayaran lebih mudah dengan system\ncod dan tempo",
  },
  {
    src: Splash4,
    text: "Belanja makin hemat dengan fitur\nBebas Ongkir",
  },
];
