import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Slider from "../components/Slider";
import Images from "../images";
import NumberFormat from "react-number-format";
import ReactStars from "react-rating-stars-component";
import ProgressBar from "@ramonak/react-progress-bar";
// import "./ReviewHistory.css";
import "./WaitingRating.css";
import Product2 from "../assets/dummy/product2.jpg";
import Product1 from "../assets/dummy/product1.jpg";
import IconArrow from "../assets/images/backArrow.webp";
import IconSearch from "../assets/images/Search.webp";
import { useSelector } from "react-redux";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import Dialog from "../components/Dialog";
import { produkAction, screenAction, orderAction } from "../actions";
import moment from "moment";
import error404 from "../assets/images/404.webp";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Header from "../components/HeaderDistributor";

export const Home = (props) => {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const { produkDesc } = useSelector((state) => state.ProdukReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [shoppingCartItems, setShoppingCartItems] = useState({
    product_id: "",
    brand_id: "",
    satuan_online: "",
    konversi_sedang_ke_kecil: "",
    qty_konversi: "",
    qty: 1,
    notes: "",
    price_apps: "",
  });
  const [listWaitingRating, setListWaitingRating] = useState([]);
  const [listHistoryRating, setListHistoryRating] = useState([]);
  const [search, setSearch] = useState("");
  const [qty, setQty] = useState(0);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [alertTambahan, setAlertTambahan] = useState("");
  const [alertJudul, setAlertJudul] = useState("");
  const [loadingApi, setLoadingApi] = useState(true);
  const [
    onEndReachedCalledDuringMomentum,
    setOnEndReachedCalledDuringMomentum,
  ] = useState(false);
  const [buttonUlasan, setButtonUlasan] = useState(true);
  const [buttonRiwayat, setButtonRiwayat] = useState(false);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  function NoData() {
    return (
      <div className="NoData">
        <img style={{ height: "50%", width: "100%" }} src={error404} />
        <p
          style={{
            fontSize: "14pt",
            fontFamily: "Lato-Bold",
            textAlign: "center",
            marginTop: "-20%",
          }}
        >
          {"Wah, ulasan mu kosong"}
        </p>
        <p
          style={{
            fontSize: "12pt",
            fontFamily: "Lato-Bold",
            width: "75%",
            textAlign: "center",
            marginTop: "2px",
          }}
        >
          {"Yuk, saatnya belanja dan kasih ulasan untuk barang-barang mu"}
        </p>
        <button
          onClick={() => {
            dispatch(screenAction("produk", "screenName"));
            navigate("/produk");
          }}
          style={{
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#529F45",
            width: "65%",
            height: "8%",
            borderRadius: "5px",
            marginTop: "5px",
            color: "#FFFFFF",
            fontSize: "10pt",
            fontFamily: "Lato-Bold",
          }}
        >
          {"Mulai Belanja"}
        </button>
      </div>
    );
  }

  useEffect(() => {
    getWaitingRating();
    getHistoryRating();
  }, []);

  useEffect(() => {
    if (page && !search) {
      console.log("masuk 1");
      if (buttonUlasan) {
        getWaitingRating();
      } else {
        getHistoryRating();
      }
    } else if (page && search.length > 1) {
      console.log("masuk 2");
      if (buttonUlasan) {
        getWaitingRating();
      } else {
        getHistoryRating();
      }
    }

    return () => {
      setOnEndReachedCalledDuringMomentum(false);
    };
  }, [page, loadingMore, buttonUlasan, buttonRiwayat, search]);

  async function getWaitingRating() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/reviews?review=false&page=${page}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      // console.log("Data Waiting Rating", JSON.stringify(data));
      setListWaitingRating(
        page == 1 ? data.data : [...listWaitingRating, ...data.data]
      );
      setRefreshing(false);
      setLoadingMore(data.last_page > page);
      setMaxPage(data.last_page);
      setTimeout(() => setLoadingApi(false), 1000);
      console.log("masuk kesni");
    } catch (error) {
      setLoadingApi(false);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  async function getHistoryRating() {
    // console.log('page=====', value);
    try {
      let response;
      if (search) {
        if (dataUser?.user?.account_type == 5) {
          response = await axios.get(
            `${CONFIG.BASE_URL}/api/distributor-partner/reviews?review=true&search=${search}&page=${page}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
        } else {
          response = await axios.get(
            `${CONFIG.BASE_URL}/api/reviews?review=true&search=${search}&page=${page}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
        }
      } else {
        if (dataUser?.user?.account_type == 5) {
          response = await axios.get(
            `${CONFIG.BASE_URL}/api/distributor-partner/reviews?review=true&page=${page}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
        } else {
          response = await axios.get(
            `${CONFIG.BASE_URL}/api/reviews?review=true&page=${page}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
        }
      }
      const data = response.data.data;
      console.log("DATA REVIEW", data);
      setListHistoryRating(
        page == 1 ? data.data : [...listHistoryRating, ...data.data]
      );
      setRefreshing(false);
      setLoadingMore(data.last_page > page);
      setMaxPage(data.last_page);
      setTimeout(() => setLoadingApi(false), 1000);
    } catch (error) {
      setLoadingApi(false);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  //load more data
  async function getMoreData() {
    console.log("cekk", !onEndReachedCalledDuringMomentum);
    console.log("cekk", page);
    console.log("cekk", maxPage);
    if (!onEndReachedCalledDuringMomentum && page < maxPage) {
      console.log("masuk load");
      setPage(page + 1);
      setLoadingMore(page < maxPage);
      console.log("ini ketiga", page, loadingMore);
    }
    setOnEndReachedCalledDuringMomentum(page >= maxPage);
  }

  function handleRefresh() {
    setPage(1);
    setRefreshing(true);
  }

  function handleLoadMore() {
    console.log("cekkkkk saiki", !loadingMore);
    if (!loadingMore) return null;
    return (
      <div>
        <CircularProgress color="success" />
      </div>
    );
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async (e) => {
    setOpen(false);
  };
  console.log("list ",JSON.stringify(listHistoryRating));
  return (
    <div className="_waitingRatingContainer">
      <Dialog
        alertData={alertData}
        alertJudul={alertJudul}
        handleClose={handleClose}
        open={open}
      />

      <div className="headerReview">
        <div className="headerSecondContainer">
          <button
            onClick={() => {
              setButtonUlasan(true);
              setButtonRiwayat(false);
              setLoadingApi(true);
            }}
            className="headerSecondMenuButton"
          >
            MENUNGGU ULASAN
          </button>
          <button
            onClick={() => {
              setButtonUlasan(false);
              setButtonRiwayat(true);
              setLoadingApi(true);
            }}
            className="headerSecondMenuButton"
          >
            RIWAYAT
          </button>
        </div>
        <Header style={{ position: "fixed" }} titleHeader="Daftar Ulasan" />
        {buttonRiwayat && (
          <div className="reviewHistorySearch">
            <div className="reviewHistorySearchContainer">
              <input
                onChange={(e) => setSearch(e.target.value)}
                className="reviewHistoryTextInput"
                placeholder="Ketik kata kunci yang ingin anda cari"
                type="text"
              />
              <img
                src={IconSearch}
                alt="headerSearchLogo"
                className="reviewHistorySearchLogo"
              />
            </div>
          </div>
        )}
      </div>
      {buttonUlasan && (
        <div className="homeContent">
          <div className="_waitingRatingContainer">
            {listWaitingRating.length > 0 && !loadingApi ? (
              <InfiniteScroll
                dataLength={listWaitingRating.length}
                next={() => getMoreData()}
                hasMore={true}
                loader={() => handleLoadMore()}
                // refreshFunction={handleRefresh}
                // pullDownToRefresh
                // pullDownToRefreshThreshold={50}
                // pullDownToRefreshContent={handleLoadMore}
              >
                {listWaitingRating.map((item, index) => {
                  if (item.data_unreview[0].product) {
                    return (
                      <div
                        onClick={() => {
                          dispatch(produkAction(item, "ratingProduk"));
                          dispatch(screenAction("", "screenName"));
                          console.log(item);
                          navigate("/rating/add");
                        }}
                        key={index}
                        className="waitingRatingCard"
                      >
                        <div className="waitingRatingCardLeftContainer">
                          {item.data_unreview[0].product.image ? (
                            <img
                              src={
                                CONFIG.BASE_URL +
                                item.data_unreview[0].product.image
                              }
                              alt="product1"
                              className="waitingRatingCardLeftImage"
                            />
                          ) : (
                            <img
                              src={DummyImage}
                              alt="product1"
                              className="waitingRatingCardLeftImage"
                            />
                          )}
                          {item.data_unreview.length > 1 ? (
                            <p className="waitingRatingCardLeftText">
                              +{item.data_unreview.length - 1} produk lainnya
                            </p>
                          ) : null}
                        </div>
                        <div className="waitingRatingCardRightContainer">
                          <p className="waitingRatingCardRightTitle">
                            {item.data_unreview[0].product.name}
                          </p>
                          {item.data_unreview[0].half ? (
                            <NumberFormat
                              value={
                                Math.round(
                                  item.data_unreview[0].price_apps / 2
                                ) ?? "0"
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"Rp "}
                              renderText={(value) => (
                                <p className="waitingRatingCardRightText">
                                  {item.data_unreview[0].qty} barang x {value}
                                </p>
                              )}
                            />
                          ) : (
                            <NumberFormat
                              value={
                                Math.round(item.data_unreview[0].price_apps) ??
                                "0"
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"Rp "}
                              renderText={(value) => (
                                <p className="waitingRatingCardRightText">
                                  {item.data_unreview[0].qty} barang x {value}
                                </p>
                              )}
                            />
                          )}
                          {item.data_unreview[0].half ? (
                            <p className="waitingRatingCardRightTextHalf">
                              {" ( "}
                              {item?.data_unreview[0].qty_konversi}{" "}
                              {item?.data_unreview[0].small_unit.charAt(0) +
                                item?.data_unreview[0]?.small_unit
                                  .slice(1)
                                  .toLowerCase()}
                              {" )"}
                            </p>
                          ) : null}
                          <NumberFormat
                            value={Math.round(item.payment_final) ?? "0"}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p className="waitingRatingCardRightText">
                                Total Belanja : {value}
                              </p>
                            )}
                          />
                          <div className="waitingRatingCardRightStarContainer">
                            <ReactStars
                              count={5}
                              value={0}
                              size={40}
                              edit={false}
                              color={"#adabae"}
                              activeColor={"#ffd700"}
                              classNames="waitingRatingCardRightStar"
                            />
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </InfiniteScroll>
            ) : listWaitingRating.length == 0 && !loadingApi ? (
              <NoData />
            ) : null}
          </div>
        </div>
      )}
      {buttonRiwayat && (
        <div className="reviewHistoryCard">
          {listHistoryRating.length > 0 && !loadingApi ? (
            <InfiniteScroll
              dataLength={listHistoryRating.length}
              next={() => getMoreData()}
              hasMore={true}
              loader={() => handleLoadMore()}
              // refreshFunction={handleRefresh}
              // pullDownToRefresh
              // pullDownToRefreshThreshold={50}
              // pullDownToRefreshContent={handleLoadMore}
            >
              {listHistoryRating.map((item, index) => {
                if (item?.data_review[0]?.review) {
                  return (
                    <React.Fragment key={index}>
                      <hr></hr>
                      <div
                        onClick={() => {
                          dispatch(produkAction(item, "ratingDetail"));
                          navigate("/review/detail");
                        }}
                        
                        className="card"
                      >
                        <div className="waitingRatingCardLeftContainer">
                          {item.data_review[0]?.review?.image ? (
                            <img
                              src={
                                CONFIG.BASE_URL +
                                item.data_review[0]?.review?.image
                              }
                              alt="image"
                              className="image"
                            />
                          ) : (
                            <img
                              src={DummyImage}
                              alt="image"
                              className="image"
                            />
                          )}
                          {item.data_review.length > 1 ? (
                            <p className="waitingRatingCardLeftText">
                              +{item.data_review.length - 1} produk lainnya
                            </p>
                          ) : null}
                        </div>
                        <div className="info">
                          <div className="name">
                            {item.data_review[0]?.review?.name}
                          </div>
                          <div className="star">
                            <ReactStars
                              count={5}
                              value={item.data_review[0]?.review?.star_review}
                              size={40}
                              edit={false}
                              color={"#adabae"}
                              activeColor={"#ffd700"}
                              classNames="productRatingBottomStar"
                            />
                          </div>
                          <div className="date">
                            {moment(
                              item.data_review[0]?.review?.created_at
                            ).format("DD MMM YYYY HH:mm")}
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                }
              })}
            </InfiniteScroll>
          ) : listHistoryRating.length == 0 && !loadingApi ? (
            <NoData />
          ) : null}
        </div>
      )}
      <Loading loadingApi={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
