import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/images/IconLogo.webp";
import IconBack from "../assets/images/backArrow.webp";
import "./UserEdit.css";
import axios from "axios";
import CONFIG from "../config/config";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "../components/Dialog";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Header from "../components/HeaderDistributor";

export const UserEditEmail = (props) => {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const navigate = useNavigate();
  const [email, setEmail] = useState(dataUser.user.email);
  const [open, setOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [alertTambahan, setAlertTambahan] = useState("");
  const [alertJudul, setAlertJudul] = useState("");
  const [loadingApi, setLoadingApi] = useState(false);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async (e) => {
    setOpen(false);
    if (e == "ok") {
      await axios
        .post(
          `${CONFIG.BASE_URL}/api/profile/${dataUser.user.id}`,
          {
            name: dataUser.user.name,
            email: email,
            phone: dataUser.user.phone,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          const data = response.data;
          console.log("DATA", data);
          if (data != null && data["success"] == true) {
            console.log("MASUKKK");
            let dataTemp = dataUser;
            dataTemp.user = data.data;
            localStorage.setItem("dataUser", JSON.stringify(dataTemp));
            navigate(-1);
          }
        })
        .catch((error) => {
          let error429 =
            JSON.parse(JSON.stringify(error)).message ===
            "Request failed with status code 429";
          let errorNetwork =
            JSON.parse(JSON.stringify(error)).message === "Network Error";
          let error400 =
            JSON.parse(JSON.stringify(error)).message ===
            "Request failed with status code 400";
          console.log(
            "Cek Error========================",
            JSON.parse(JSON.stringify(error)).message
          );
          if (error429) {
            setAlertServerSibuk(true);
          } else if (errorNetwork) {
            setAlertInet(true);
          } else if (error400) {
            localStorage.removeItem("token");
            navigate("/home");
          }
        });
    }
  };

  return (
    <div className="userEditContainer">
      <Dialog
        alertData={alertData}
        alertJudul={alertJudul}
        handleClose={handleClose}
        open={open}
      />
      <Header titleHeader="Ubah Email" />
      <div className="userEditList">
        <div className="userEditTextTitle">Email</div>
        <div className="subUserEdit">
          <input
            onChange={(e) => setEmail(e.target.value)}
            className="userEditInput"
            value={email}
          ></input>
        </div>
        <hr></hr>
        <button
          onClick={() => {
            setAlertData("Apakah yakin ingin mengganti email anda?");
            setAlertJudul("Pemberitahuan");
            setOpen(true);
          }}
          className="editButtonOK"
        >
          <p className="editTextButtonOK">{"Simpan"}</p>
        </button>
      </div>
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserEditEmail);
