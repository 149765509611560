import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import IconBack from "../assets/images/backArrow.webp";
import "./TambahProdukDistributor.css";
import axios from "axios";
import CONFIG from "../config/config";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "../components/Dialog";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import ImageUploading from "react-images-uploading";
import IconTrash from "../assets/images/Trash.webp";
import IconEdit from "../assets/images/edit.webp";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import NumberFormat from "react-number-format";
import Calendar from "../components/Calendar";
import Header from "../components/HeaderDistributor";
import IconPensil from "../assets/images/Pensil.svg";

export const VoucherPartner = (props) => {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [type, setType] = useState("");
  const [percent, setPercent] = useState("");
  const [nominal, setNominal] = useState("");
  const [max_nominal, setMax_nominal] = useState("");
  const [max_use, setMax_use] = useState("");
  const [max_use_user, setMax_use_user] = useState("");
  const [daily_use, setDaily_use] = useState("");
  const [used, setUsed] = useState("");
  const [min_transaction, setMin_transaction] = useState("");
  const [max_transaction, setMax_transaction] = useState("");
  const [description, setDescription] = useState("");
  const [termandcondition, setTermandcondition] = useState("");
  const [status, setStatus] = useState("");
  const [status_ekslusif, setStatus_ekslusif] = useState("");
  const [start_at, setStart_at] = useState("");
  const [end_at, setEnd_at] = useState("");
  const [category_id, setCategory_id] = useState("");
  const [satuan_online, setSatuan_online] = useState("");
  const [harga_ritel_gt, setHarga_ritel_gt] = useState("");
  const [dataEdit, setDataEdit] = useState("");
  const [photo, setPhoto] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [alertTambahan, setAlertTambahan] = useState("");
  const [alertJudul, setAlertJudul] = useState("");
  const [loadingApi, setLoadingApi] = useState(true);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);
  const [deleteItem, setDeleteItem] = useState("");
  const [isEnabled, setIsEnabled] = useState(false);
  const [isEnabled2, setIsEnabled2] = useState(false);
  const [tombolVoucher, setTombolVoucher] = useState(true);
  const [tombolAddVoucher, setTombolAddVoucher] = useState(false);
  const [vouchers, setVouchers] = useState([]);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const [
    onEndReachedCalledDuringMomentum,
    setOnEndReachedCalledDuringMomentum,
  ] = useState(false);
  const [showPickerDate, setShowPickerDate] = useState(false);
  const [showPickerDate2, setShowPickerDate2] = useState(false);
  const [minDate, setMinDate] = useState(new Date());

  useEffect(() => {
    getDataVoucher();
  }, []);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  const handleClickOpen = () => {
    setAlertData("Apakah yakin ingin mengganti nama anda?");
    setAlertJudul("Pemberitahuan");
    setOpen(true);
  };

  const handleClose = async (e) => {
    setOpen(false);
    if (
      e == "ok" &&
      deleteItem != null &&
      alertData == "Apakah anda yakin ingin menghapus voucher ini?"
    ) {
      try {
        await axios({
          method: "delete",
          url: `${CONFIG.BASE_URL}/api/distributor-partner/vouchers/${deleteItem}`,
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }).then((response) => {
          const data = response.data;
          if (data["success"] == true) {
            setDeleteItem("");
            getDataVoucher();
          }
          // console.log('dataPartners', JSON.stringify(data));
        });
      } catch (error) {
        let error429 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 429";
        let errorNetwork =
          JSON.parse(JSON.stringify(error)).message === "Network Error";
        let error400 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 400";
        console.log(
          "Cek Error========================",
          JSON.parse(JSON.stringify(error)).message
        );
        if (error429) {
          setAlertServerSibuk(true);
        } else if (errorNetwork) {
          setAlertInet(true);
        } else if (error400) {
          localStorage.removeItem("token");
          navigate("/dashboardhome");
        }
      }
    }
  };

  const toggleSwitch = () => {
    if (dataEdit.status) {
      setDataEdit((prevState) => ({
        ...prevState,
        status: (dataEdit["status"] = 1 ? 0 : 1),
      }));
    } else {
      setIsEnabled((previousState) => !previousState);
    }
  };

  const toggleSwitch2 = () => {
    if (dataEdit.status_eksklusif) {
      setDataEdit((prevState) => ({
        ...prevState,
        status_eksklusif: (dataEdit["status_eksklusif"] = 1 ? 0 : 1),
      }));
    } else {
      setIsEnabled2((previousState) => !previousState);
    }
  };

  const postVoucher = async () => {
    const reg = /^[0-9\b]+$/;
    console.log("cek tanggal", Date.parse(start_at));
    console.log("cek tanggal", Date.parse(end_at));
    setButtonDisabled(true);
    if (photo === null) {
      setAlertData("Harap menambah foto produk");
      setButtonDisabled(false);
      setOpen(true);
    } else if (code == "") {
      setAlertData("Harap mengisi nama voucher");
      setButtonDisabled(false);
      setOpen(true);
    } else if (description == "") {
      setAlertData("Harap mengisi deskripsi voucher");
      setButtonDisabled(false);
      setOpen(true);
    } else if (termandcondition == "") {
      setAlertData("Harap mengisi syarat dan ketentuan voucher");
      setButtonDisabled(false);
      setOpen(true);
    } else if (min_transaction == "") {
      setAlertData("Harap mengisi minimal transaksi");
      setButtonDisabled(false);
      setOpen(true);
    } else if (!reg.test(min_transaction)) {
      setAlertData("Harap mengisi angka saja di dalam minimal transaksi");
      setButtonDisabled(false);
      setOpen(true);
    } else if (type == "") {
      setAlertData("Harap memilih type voucher");
      setButtonDisabled(false);
      setOpen(true);
    } else if (type == "nominal" && nominal == "") {
      setAlertData("Harap mengisi jumlah nominal dari type voucher");
      setButtonDisabled(false);
      setOpen(true);
    } else if (type == "percent" && percent == "") {
      setAlertData("Harap mengisi jumlah persen dari type voucher");
      setButtonDisabled(false);
      setOpen(true);
    } else if (max_use == "") {
      setAlertData("Harap mengisi max pengggunaan voucher untuk semua");
      setButtonDisabled(false);
      setOpen(true);
    } else if (!reg.test(max_use)) {
      setAlertData(
        "Harap mengisi angka saja di dalam max pengggunaan voucher untuk semua"
      );
      setButtonDisabled(false);
      setOpen(true);
    } else if (max_use_user == "") {
      setAlertData("Harap mengisi max pengggunaan voucher untuk per user");
      setButtonDisabled(false);
      setOpen(true);
    } else if (!reg.test(max_use_user)) {
      setAlertData(
        "Harap mengisi angka saja di dalam max pengggunaan voucher untuk per user"
      );
      setButtonDisabled(false);
      setOpen(true);
    } else if (daily_use == "") {
      setAlertData("Harap mengisi max pengggunaan voucher per hari");
      setButtonDisabled(false);
      setOpen(true);
    } else if (!reg.test(daily_use)) {
      setAlertData(
        "Harap mengisi angka saja di dalam max pengggunaan voucher per hari"
      );
      setButtonDisabled(false);
      setOpen(true);
    } else if (start_at == "") {
      setAlertData("Harap mengisi tanggal mulai berlaku");
      setButtonDisabled(false);
      setOpen(true);
    } else if (end_at == "") {
      setAlertData("Harap mengisi tanggal mulai berakhir");
      setButtonDisabled(false);
      setOpen(true);
    } else if (Date.parse(start_at) > Date.parse(end_at)) {
      setAlertData(
        "Tanggal mulai berakhir harus lebih dari tanggal mulai berlaku"
      );
      setButtonDisabled(false);
      setOpen(true);
    } else {
      const formData = new FormData();
      formData.append("code", code);
      formData.append("type", type);
      formData.append("percent", percent);
      formData.append("nominal", nominal);
      formData.append("max_nominal", max_nominal);
      formData.append("max_use", max_use);
      formData.append("max_use_user", max_use_user);
      formData.append("daily_use", daily_use);
      formData.append("used", used);
      formData.append("min_transaction", min_transaction);
      formData.append("max_transaction", max_transaction);
      formData.append("description", description);
      formData.append("termandcondition", termandcondition);
      formData.append("status", isEnabled ? 1 : 0);
      formData.append("status_eksklusif", isEnabled2 ? 1 : 0);
      formData.append("start_at", moment(start_at).format("YYYY-MM-DD"));
      formData.append("end_at", moment(end_at).format("YYYY-MM-DD"));
      formData.append("file", photo[0].file, photo[0].file.type);
      console.log("TESSSS", JSON.stringify(formData.get("file")));
      try {
        console.log("masuk");
        let response = await axios({
          method: "post",
          url: `${CONFIG.BASE_URL}/api/distributor-partner/vouchers/store`,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          data: formData,
        });
        let data = response.data;
        console.log("DATA SUKSESSS", data);
        if (data !== "" && data["success"] == true) {
          setButtonDisabled(false);
          setPhoto(null);
          navigate(-1);
        } else {
          console.log("Gagal input komplain===>", data);
        }
      } catch (error) {
        setButtonDisabled(false);
        let error429 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 429";
        let errorNetwork =
          JSON.parse(JSON.stringify(error)).message === "Network Error";
        let error400 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 400";
        console.log(
          "Cek Error========================",
          JSON.parse(JSON.stringify(error)).message
        );
        if (error429) {
          setAlertServerSibuk(true);
        } else if (errorNetwork) {
          setAlertInet(true);
        } else if (error400) {
          localStorage.removeItem("token");
          navigate("/dashboardhome");
        }
      }
    }
  };

  const editVoucher = async () => {
    const reg = /^[0-9\b]+$/;
    console.log("masuk ubah", dataEdit);
    setButtonDisabled(true);
    if (dataEdit.code == "") {
      setAlertData("Harap mengisi nama voucher");
      setButtonDisabled(false);
      setOpen(true);
    } else if (dataEdit.description == "") {
      setAlertData("Harap mengisi deskripsi voucher");
      setButtonDisabled(false);
      setOpen(true);
    } else if (dataEdit.termandcondition == "") {
      setAlertData("Harap mengisi syarat dan ketentuan voucher");
      setButtonDisabled(false);
      setOpen(true);
    } else if (dataEdit.min_transaction == "") {
      setAlertData("Harap mengisi minimal transaksi");
      setButtonDisabled(false);
      setOpen(true);
    } else if (!reg.test(dataEdit.min_transaction)) {
      setAlertData("Harap mengisi angka saja di dalam minimal transaksi");
      setButtonDisabled(false);
      setOpen(true);
    } else if (dataEdit.type == "") {
      setAlertData("Harap memilih type voucher");
      setButtonDisabled(false);
      setOpen(true);
    } else if (dataEdit.type == "nominal" && dataEdit.nominal == "") {
      setAlertData("Harap mengisi jumlah nominal dari type voucher");
      setButtonDisabled(false);
      setOpen(true);
    } else if (dataEdit.type == "percent" && dataEdit.percent == "") {
      setAlertData("Harap mengisi jumlah persen dari type voucher");
      setButtonDisabled(false);
      setOpen(true);
    } else if (dataEdit.max_use == "") {
      setAlertData("Harap mengisi max pengggunaan voucher untuk semua");
      setButtonDisabled(false);
      setOpen(true);
    } else if (!reg.test(dataEdit.max_use)) {
      setAlertData(
        "Harap mengisi angka saja di dalam max pengggunaan voucher untuk semua"
      );
      setButtonDisabled(false);
      setOpen(true);
    } else if (dataEdit.max_use_user == "") {
      setAlertData("Harap mengisi max pengggunaan voucher untuk per user");
      setButtonDisabled(false);
      setOpen(true);
    } else if (!reg.test(dataEdit.max_use_user)) {
      setAlertData(
        "Harap mengisi angka saja di dalam max pengggunaan voucher untuk per user"
      );
      setButtonDisabled(false);
      setOpen(true);
    } else if (dataEdit.daily_use == "") {
      setAlertData("Harap mengisi max pengggunaan voucher per hari");
      setButtonDisabled(false);
      setOpen(true);
    } else if (!reg.test(dataEdit.daily_use)) {
      setAlertData(
        "Harap mengisi angka saja di dalam max pengggunaan voucher per hari"
      );
      setButtonDisabled(false);
      setOpen(true);
    } else if (dataEdit.start_at == "") {
      setAlertData("Harap mengisi tanggal mulai berlaku");
      setButtonDisabled(false);
      setOpen(true);
    } else if (dataEdit.end_at == "") {
      setAlertData("Harap mengisi tanggal mulai berakhir");
      setButtonDisabled(false);
      setOpen(true);
    } else if (Date.parse(dataEdit.start_at) > Date.parse(dataEdit.end_at)) {
      setAlertData(
        "Tanggal mulai berakhir harus lebih dari tanggal mulai berlaku"
      );
      setButtonDisabled(false);
      setOpen(true);
    } else {
      const formData = new FormData();
      formData.append("code", dataEdit.code);
      formData.append("type", dataEdit.type);
      formData.append("percent", dataEdit.percent);
      formData.append("nominal", dataEdit.nominal);
      // formData.append("max_nominal", dataEdit.max_nominal);
      formData.append("max_use", dataEdit.max_use);
      formData.append("max_use_user", dataEdit.max_use_user);
      formData.append("daily_use", dataEdit.daily_use);
      // formData.append("used", dataEdit.used);
      formData.append("min_transaction", dataEdit.min_transaction);
      // formData.append("max_transaction", dataEdit.max_transaction);
      formData.append("description", dataEdit.description);
      formData.append("termandcondition", dataEdit.termandcondition);
      formData.append("status", dataEdit.status);
      formData.append("status_eksklusif", dataEdit.status_eksklusif);
      formData.append(
        "start_at",
        moment(dataEdit.start_at).format("YYYY-MM-DD")
      );
      formData.append("end_at", moment(dataEdit.end_at).format("YYYY-MM-DD"));
      if (photo !== null) {
        formData.append("file", photo[0].file, photo[0].file.type);
      }
      formData.append("max_nominal", max_nominal);
      formData.append("max_transaction", max_transaction);
      formData.append("used", used);
      try {
        console.log("masuk");
        let response = await axios({
          method: "post",
          url: `${CONFIG.BASE_URL}/api/distributor-partner/vouchers/${dataEdit.id}`,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          data: formData,
        });
        let data = response.data;
        console.log("DATA SUKSESSS", data);
        if (data !== "" && data["success"] == true) {
          setButtonDisabled(false);
          setPhoto(null);
          navigate(-1);
        } else {
          console.log("Gagal input komplain===>", data);
        }
      } catch (error) {
        setButtonDisabled(false);
        let error429 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 429";
        let errorNetwork =
          JSON.parse(JSON.stringify(error)).message === "Network Error";
        let error400 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 400";
        console.log(
          "Cek Error========================",
          JSON.parse(JSON.stringify(error)).message
        );
        if (error429) {
          setAlertServerSibuk(true);
        } else if (errorNetwork) {
          setAlertInet(true);
        } else if (error400) {
          localStorage.removeItem("token");
          navigate("/dashboardhome");
        }
      }
    }
  };

  const onChangePhoto = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setPhoto(imageList);
  };

  const getDataVoucher = async () => {
    try {
      await axios({
        method: "get",
        url: `${CONFIG.BASE_URL}/api/distributor-partner/vouchers?page=${page}`,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }).then((response) => {
        const data = response.data;
        // console.log("dataVoucher", JSON.stringify(data.data));
        setLoadingApi(false);
        setVouchers(page === 1 ? data.data : [...vouchers, ...data.data]);
        setLoadingMore(data.last_page > page);
        setMaxPage(data.last_page);
      });
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  };

  //load more data
  async function getMoreData() {
    console.log("cekk", !onEndReachedCalledDuringMomentum);
    console.log("cekk", page);
    console.log("cekk", maxPage);
    if (!onEndReachedCalledDuringMomentum && page < maxPage) {
      console.log("masuk load");
      setPage(page + 1);
      setLoadingMore(page < maxPage);
      console.log("ini ketiga", page, loadingMore);
    }
    setOnEndReachedCalledDuringMomentum(page >= maxPage);
  }

  function handleRefresh() {
    setPage(1);
    setRefreshing(true);
    getDataVoucher();
  }

  function handleLoadMore() {
    console.log("cekkkkk saiki", !loadingMore);
    if (!loadingMore) return null;
    return (
      <div>
        <CircularProgress color="success" />
      </div>
    );
  }

  return (
    <div className="tambahProdukDistributorContainer">
      <Dialog
        alertData={alertData}
        alertJudul={alertJudul}
        handleClose={handleClose}
        open={open}
      />
      <Header titleHeader={"Voucher"} />
      <div className="voucherPartnerDistributorButtonTabContainer">
        {tombolVoucher ? (
          <div
            style={{ backgroundColor: "#529F45" }}
            className="voucherPartnerDistributorButtonTab"
          >
            <p
              style={{ color: "#FFF" }}
              className="voucherPartnerDistributorTextButton"
            >
              {"Voucher"}
            </p>
          </div>
        ) : (
          <div
            onClick={() => {
              setTombolVoucher(true);
              setTombolAddVoucher(false);
              setDataEdit("");
            }}
            className="voucherPartnerDistributorButtonTab"
          >
            <p className="voucherPartnerDistributorTextButton">{"Voucher"}</p>
          </div>
        )}
        {tombolAddVoucher ? (
          <div
            style={{ backgroundColor: "#529F45" }}
            className="voucherPartnerDistributorButtonTab"
          >
            <p
              style={{ color: "#FFF" }}
              className="voucherPartnerDistributorTextButton"
            >
              {"Tambah Voucher"}
            </p>
          </div>
        ) : (
          <div
            onClick={() => {
              setTombolVoucher(false);
              setTombolAddVoucher(true);
            }}
            className="voucherPartnerDistributorButtonTab"
          >
            <p className="voucherPartnerDistributorTextButton">
              {"Daftar Voucher"}
            </p>
          </div>
        )}
      </div>
      {tombolVoucher && (
        <InfiniteScroll
          dataLength={vouchers.length}
          next={() => getMoreData()}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "8px",
            marginLeft: "15px",
            marginRight: "15px",
            padding: "10px",
            marginTop: "20px",
          }}
          hasMore={true}
          loader={() => handleLoadMore()}
          // refreshFunction={handleRefresh}
          // pullDownToRefresh
          // pullDownToRefreshThreshold={50}
          // pullDownToRefreshContent={handleLoadMore}
        >
          {vouchers.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  marginTop:"3%",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div className="containerViewVoucher">
                  <p className="textDateVoucher">
                    {moment(item?.start_at).format("DD MMM YYYY")}
                    {" - "}
                    {moment(item?.end_at).format("DD MMM YYYY")}
                  </p>
                  <div className="viewIconVoucher">
                    <img
                      onClick={() => {
                        setDataEdit(item);
                        setTombolVoucher(false);
                        setTombolAddVoucher(true);
                      }}
                      src={IconEdit}
                      alt="menuIcon"
                      className="voucherPartnerDistributorIcon1"
                    />
                    <img
                      onClick={() => {
                        setAlertData(
                          "Apakah anda yakin ingin menghapus voucher ini?"
                        );
                        setDeleteItem(item.id);
                        setButtonDisabled(false);
                        setOpen(true);
                      }}
                      src={IconTrash}
                      alt="menuIcon"
                      className="voucherPartnerDistributorIcon2"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // alignItems: "center",
                    width: "100%",
                    marginBottom: "15px",
                    marginTop: "1%",
                    backgroundColor: "#F6F6F6",
                    // justifyContent: "space-between",
                    borderRadius: "8px",
                  }}
                >
                  <img
                    src={CONFIG.BASE_URL + item?.file}
                    alt="complaintCreateContentImage"
                    className="voucherPartnerDistributorImageVoucher"
                  />
                  <div className="textVoucher">
                    <p style={{ fontSize: "13pt" }}>{item?.code}</p>
                    <div className="textVoucherMinimal">
                      <p className="textStatusVoucher">
                        Minimal belanja : &nbsp;
                      </p>
                      <NumberFormat
                        value={item.min_transaction}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={" Rp "}
                        renderText={(value) => (
                          <p className="textValueVoucher">{value || 0}</p>
                        )}
                      />
                    </div>
                    <div className="textVoucherMinimal">
                      <p className="textStatusVoucher">Status :&nbsp; </p>
                      <p className="textValueVoucher">
                        {" "}
                        {item.status == 1 ? " Aktif " : " Tidak aktif "}
                      </p>
                    </div>
                  </div>
                  <div className="btnDetailVoucher">
                    <p
                      onClick={() => {
                        navigate("/menu/voucher/detail?idVoucher=" + item.id);
                      }}
                    >
                      Detail
                    </p>
                  </div>
                </div>
                <div className="garisVoucher" />
              </div>
            );
          })}
        </InfiniteScroll>
      )}
      {tombolAddVoucher && (
        <div className="voucherPartnerDistributorList">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <p
              style={{
                fontSize: "15px",
                color: "#000",
                fontFamily: "Lato-Medium",
              }}
            >
              {"Foto voucher"}
            </p>
            <div>
              {dataEdit ? (
                <ImageUploading
                  // multiple
                  value={photo}
                  onChange={onChangePhoto}
                  // maxNumber={maxNumber}
                  dataURLKey="data_url"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div
                      style={{ width: "100%" }}
                      className="complaintCreateFooterUploadLampiran"
                    >
                      <p
                        style={{
                          fontSize: "2vh",
                          fontFamily: "Lato-Medium",
                          color: "#529F45",
                        }}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        {"Ganti foto"}
                      </p>
                      {/* {imageList.map((image, index) => {
                          console.log("cekkk", photo);
                          if (photo) {
                            return (
                              <div key={index} className="image-item">
                                <img
                                  src={image["data_url"]}
                                  alt=""
                                  width="100"
                                />
                                <div className="image-item__btn-wrapper">
                                  <button onClick={() => onImageUpdate(index)}>
                                    Update
                                  </button>
                                  <button onClick={() => onImageRemove(index)}>
                                    Remove
                                  </button>
                                </div>
                              </div>
                            );
                          }
                        })} */}
                    </div>
                  )}
                </ImageUploading>
              ) : (
                <>
                  {photo ? (
                    <ImageUploading
                      // multiple
                      value={photo}
                      onChange={onChangePhoto}
                      // maxNumber={maxNumber}
                      dataURLKey="data_url"
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div
                          style={{ width: "100%" }}
                          className="complaintCreateFooterUploadLampiran"
                        >
                          <p
                            style={{
                              fontSize: "2vh",
                              fontFamily: "Lato-Medium",
                              color: "#529F45",
                            }}
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            {"Ganti foto"}
                          </p>
                          {/* {imageList.map((image, index) => {
                          console.log("cekkk", photo);
                          if (photo) {
                            return (
                              <div key={index} className="image-item">
                                <img
                                  src={image["data_url"]}
                                  alt=""
                                  width="100"
                                />
                                <div className="image-item__btn-wrapper">
                                  <button onClick={() => onImageUpdate(index)}>
                                    Update
                                  </button>
                                  <button onClick={() => onImageRemove(index)}>
                                    Remove
                                  </button>
                                </div>
                              </div>
                            );
                          }
                        })} */}
                        </div>
                      )}
                    </ImageUploading>
                  ) : (
                    <ImageUploading
                      // multiple
                      value={photo}
                      onChange={onChangePhoto}
                      // maxNumber={maxNumber}
                      dataURLKey="data_url"
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div
                          style={{ width: "100%" }}
                          className="complaintCreateFooterUploadLampiran"
                        >
                          <p
                            style={{
                              fontSize: "2vh",
                              fontFamily: "Lato-Medium",
                              color: "#529F45",
                            }}
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            {"Tambah foto"}
                          </p>
                          {/* {imageList.map((image, index) => {
                          console.log("cekkk", photo);
                          if (photo) {
                            return (
                              <div key={index} className="image-item">
                                <img
                                  src={image["data_url"]}
                                  alt=""
                                  width="100"
                                />
                                <div className="image-item__btn-wrapper">
                                  <button onClick={() => onImageUpdate(index)}>
                                    Update
                                  </button>
                                  <button onClick={() => onImageRemove(index)}>
                                    Remove
                                  </button>
                                </div>
                              </div>
                            );
                          }
                        })} */}
                        </div>
                      )}
                    </ImageUploading>
                  )}
                </>
              )}
            </div>
          </div>
          {dataEdit.file ? (
            <div className="complaintCreateFooterUploadHasil">
              <img
                src={CONFIG.BASE_URL + dataEdit.file}
                alt="complaintCreateItemQtyMinusImage"
                className="complaintCreateItemQtyHasilImage"
              />
            </div>
          ) : (
            <>
              {photo ? (
                <div className="complaintCreateFooterUploadHasil">
                  <img
                    src={photo[0].data_url}
                    alt="complaintCreateItemQtyMinusImage"
                    className="complaintCreateItemQtyHasilImage"
                  />
                </div>
              ) : null}
            </>
          )}
          <div className="tambahProdukDistributorTextTitle">
            Isi nama voucher
          </div>
          <div className="subUserEdit">
            {dataEdit.code ? (
              <input
                onChange={(e) =>
                  setDataEdit((prevState) => ({
                    ...prevState,
                    code: e.target.value,
                  }))
                }
                className="userEditInput"
                placeholder={dataEdit.code}
                value={dataEdit.code}
              ></input>
            ) : (
              <input
                onChange={(e) => setCode(e.target.value)}
                className="userEditInput"
                placeholder="Nama voucher"
                value={code}
              ></input>
            )}
          </div>
          <hr></hr>
          <div className="tambahProdukDistributorTextTitle">
            Isi deskripsi voucher
          </div>
          <div className="subUserEdit">
            {dataEdit.description ? (
              <input
                onChange={(e) =>
                  setDataEdit((prevState) => ({
                    ...prevState,
                    description: e.target.value,
                  }))
                }
                className="userEditInput"
                placeholder={dataEdit.description}
                value={dataEdit.description}
              ></input>
            ) : (
              <input
                onChange={(e) => setDescription(e.target.value)}
                className="userEditInput"
                placeholder="Deskripsi voucher"
                value={description}
              ></input>
            )}
          </div>
          <hr></hr>
          <div className="tambahProdukDistributorTextTitle">
            Isi syarat dan ketentuan voucher
          </div>
          <div className="subUserEdit">
            {dataEdit.termandcondition ? (
              <input
                onChange={(e) =>
                  setDataEdit((prevState) => ({
                    ...prevState,
                    termandcondition: e.target.value,
                  }))
                }
                className="userEditInput"
                placeholder={dataEdit.termandcondition}
                value={dataEdit.termandcondition}
              ></input>
            ) : (
              <input
                onChange={(e) => setTermandcondition(e.target.value)}
                className="userEditInput"
                placeholder="Syarat dan ketentuan voucher"
                value={termandcondition}
              ></input>
            )}
          </div>
          <hr></hr>
          <div className="tambahProdukDistributorTextTitle">
            Tentukan minimal transaksi
          </div>
          <div className="subUserEdit">
            <p className="tambahProdukDistributorTextRp">{"Rp  "}</p>
            {dataEdit.min_transaction ? (
              <input
                onChange={(e) =>
                  setDataEdit((prevState) => ({
                    ...prevState,
                    min_transaction: e.target.value,
                  }))
                }
                type="number"
                className="userEditInput"
                placeholder={dataEdit.min_transaction}
                value={dataEdit.min_transaction}
              ></input>
            ) : (
              <input
                onChange={(e) => setMin_transaction(e.target.value)}
                type="number"
                className="userEditInput"
                placeholder="Min transaksi"
                value={min_transaction}
              ></input>
            )}
          </div>
          <hr></hr>
          <div className="tambahProdukDistributorTextTitle">
            Tentukan type voucher
          </div>
          <div className="subUserEdit">
            {dataEdit ? (
              <select
                onChange={(e) => {
                  if (e.target.value == "nominal") {
                    setDataEdit((prevState) => ({
                      ...prevState,
                      percent: "",
                      type: e.target.value,
                    }));
                  } else {
                    setDataEdit((prevState) => ({
                      ...prevState,
                      nominal: "",
                      type: e.target.value,
                    }));
                  }
                }}
                className="complaintCreateItemSelect"
              >
                <option value={dataEdit.type}>{dataEdit.type}</option>
                <option value={"nominal"}>{"Nominal"}</option>
                <option value={"percent"}>{"Persen"}</option>
              </select>
            ) : (
              <select
                onChange={(e) => {
                  if (e.target.value !== "Pilih Kategori") {
                    if (e.target.value == "nominal") {
                      setPercent("");
                      setType(e.target.value);
                    } else {
                      setNominal("");
                      setType(e.target.value);
                    }
                  } else {
                    setPercent("");
                    setNominal("");
                    setType("");
                  }
                }}
                className="complaintCreateItemSelect"
              >
                <option value={"Pilih Kategori"}>Pilih Kategori</option>
                <option value={"nominal"}>{"Nominal"}</option>
                <option value={"percent"}>{"Persen"}</option>
              </select>
            )}
          </div>
          {dataEdit.type ? (
            <>
              {dataEdit.type === "nominal" ? (
                <>
                  <hr></hr>
                  <div className="tambahProdukDistributorTextTitle">
                    Tentukan nominal potongan voucher
                  </div>
                  <div className="subUserEdit">
                    <p className="tambahProdukDistributorTextRp">{"Rp  "}</p>
                    <input
                      onChange={(e) =>
                        setDataEdit((prevState) => ({
                          ...prevState,
                          nominal: e.target.value,
                        }))
                      }
                      type="number"
                      className="userEditInput"
                      placeholder={dataEdit.nominal}
                      value={dataEdit.nominal}
                    ></input>
                  </div>
                </>
              ) : null}
              {dataEdit.type === "percent" ? (
                <>
                  <hr></hr>
                  <div className="tambahProdukDistributorTextTitle">
                    Tentukan nominal potongan voucher
                  </div>
                  <div className="subUserEdit">
                    <input
                      onChange={(e) =>
                        setDataEdit((prevState) => ({
                          ...prevState,
                          percent: e.target.value,
                        }))
                      }
                      type="number"
                      className="userEditInput"
                      placeholder={dataEdit.percent}
                      value={dataEdit.percent}
                    ></input>
                    <p className="tambahProdukDistributorTextRp">{" %"}</p>
                  </div>
                </>
              ) : null}
            </>
          ) : (
            <>
              {type === "nominal" ? (
                <>
                  <hr></hr>
                  <div className="tambahProdukDistributorTextTitle">
                    Tentukan nominal potongan voucher
                  </div>
                  <div className="subUserEdit">
                    <p className="tambahProdukDistributorTextRp">{"Rp  "}</p>
                    <input
                      onChange={(e) => setNominal(e.target.value)}
                      type="number"
                      className="userEditInput"
                      placeholder="isi nominal potongan"
                      value={nominal}
                    ></input>
                  </div>
                </>
              ) : null}
              {type === "percent" ? (
                <>
                  <hr></hr>
                  <div className="tambahProdukDistributorTextTitle">
                    Tentukan nominal potongan voucher
                  </div>
                  <div className="subUserEdit">
                    <input
                      onChange={(e) => setPercent(e.target.value)}
                      type="number"
                      className="userEditInput"
                      placeholder="isi nominal potongan"
                      value={percent}
                    ></input>
                    <p className="tambahProdukDistributorTextRp">{" %"}</p>
                  </div>
                </>
              ) : null}
            </>
          )}
          <hr></hr>
          <div className="tambahProdukDistributorTextTitle">
            Tentukan max penggunaan voucher untuk semua
          </div>
          <div className="subUserEdit">
            {dataEdit.max_use ? (
              <input
                onChange={(e) =>
                  setDataEdit((prevState) => ({
                    ...prevState,
                    max_use: e.target.value,
                  }))
                }
                type="number"
                className="userEditInput"
                placeholder={dataEdit.max_use.toString()}
                value={dataEdit.max_use.toString()}
              ></input>
            ) : (
              <input
                onChange={(e) => setMax_use(e.target.value)}
                type="number"
                className="userEditInput"
                placeholder="Max penggunaan voucher semua"
                value={max_use}
              ></input>
            )}
          </div>
          <hr></hr>
          <div className="tambahProdukDistributorTextTitle">
            Tentukan max penggunaan voucher untuk per user
          </div>
          <div className="subUserEdit">
            {dataEdit.max_use_user ? (
              <input
                onChange={(e) =>
                  setDataEdit((prevState) => ({
                    ...prevState,
                    max_use_user: e.target.value,
                  }))
                }
                type="number"
                className="userEditInput"
                placeholder="Max penggunaan voucher per user"
                value={dataEdit.max_use_user.toString()}
              ></input>
            ) : (
              <input
                onChange={(e) => setMax_use_user(e.target.value)}
                type="number"
                className="userEditInput"
                placeholder="Max penggunaan voucher per user"
                value={max_use_user}
              ></input>
            )}
          </div>
          <hr></hr>
          <div className="tambahProdukDistributorTextTitle">
            Tentukan max penggunaan voucher untuk per hari
          </div>
          <div className="subUserEdit">
            {dataEdit.daily_use ? (
              <input
                onChange={(e) =>
                  setDataEdit((prevState) => ({
                    ...prevState,
                    daily_use: e.target.value,
                  }))
                }
                type="number"
                className="userEditInput"
                placeholder={dataEdit.daily_use.toString()}
                value={dataEdit.daily_use.toString()}
              ></input>
            ) : (
              <input
                onChange={(e) => setDaily_use(e.target.value)}
                type="number"
                className="userEditInput"
                placeholder="Max penggunaan voucher per hari"
                value={daily_use}
              ></input>
            )}
          </div>
          <hr></hr>
          <div className="tambahProdukDistributorTextTitle">Status voucher</div>
          <div className="subUserEdit">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <p className="tambahProdukDistributorInfo">
                {"Jika status aktif, berarti produkmu dapat dicari pembeli"}
              </p>
              {dataEdit.status ? (
                <Switch
                  checked={dataEdit.status == 1 ? true : false}
                  onChange={toggleSwitch}
                  inputProps={{ "aria-label": "controlled" }}
                  color="success"
                />
              ) : (
                <Switch
                  checked={isEnabled}
                  onChange={toggleSwitch}
                  inputProps={{ "aria-label": "controlled" }}
                  color="success"
                />
              )}
            </div>
          </div>
          <hr></hr>
          <div className="tambahProdukDistributorTextTitle">Status voucher</div>
          <div className="subUserEdit">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <p className="tambahProdukDistributorInfo">
                {
                  "Jika status aktif, berarti user harus input nama vouchernya untuk memunculkan voucher anda"
                }
              </p>
              {dataEdit.status_eksklusif ? (
                <Switch
                  checked={dataEdit.status_eksklusif == 1 ? true : false}
                  onChange={toggleSwitch}
                  inputProps={{ "aria-label": "controlled" }}
                  color="success"
                />
              ) : (
                <Switch
                  checked={isEnabled2}
                  onChange={toggleSwitch2}
                  inputProps={{ "aria-label": "controlled" }}
                  color="success"
                />
              )}
            </div>
          </div>
          <hr></hr>
          <div
            style={{
              justifyContent: "space-between",
              width: "100%",
              marginTop: "15px",
            }}
            className="subUserEdit"
          >
            <div
              style={{
                marginTop: "0px",
              }}
              className="tambahProdukDistributorTextTitle"
            >
              Berlaku mulai tanggal
            </div>
            <div
              onClick={() => setShowPickerDate(true)}
              style={{
                borderStyle: "solid",
                borderColor: "#bcbcbc",
                padding: "5px 15px",
                borderRadius: "7px",
                borderWidth: "1.5px",
              }}
            >
              {dataEdit.start_at ? (
                <p
                  style={{ color: "#000" }}
                  className="tambahProdukDistributorInfo"
                >
                  {moment(dataEdit.start_at).format("DD MMM YYYY")}
                </p>
              ) : (
                <>
                  {start_at ? (
                    <p
                      style={{ color: "#000" }}
                      className="tambahProdukDistributorInfo"
                    >
                      {moment(start_at).format("DD MMM YYYY")}
                    </p>
                  ) : (
                    <p
                      style={{ color: "#000" }}
                      className="tambahProdukDistributorInfo"
                    >
                      {"Pilih Tanggal"}
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
          <Calendar
            date={start_at}
            setDate={(date) => {
              if (dataEdit) {
                setDataEdit((prevState) => ({
                  ...prevState,
                  start_at: date,
                }));
                setShowPickerDate(false);
              } else {
                setStart_at(date);
                setShowPickerDate(false);
              }
            }}
            openCalendar={showPickerDate}
            minDate={minDate}
            // selectRange={selectRange}
            // setDate={(date) => {
            //     setDate(date);
            //     if(date.length > 0){
            //       setShowPickerDate(false)
            //     };
            //     console.log(JSON.stringify(date));
            //   }}
          />
          <hr></hr>
          <div
            style={{
              justifyContent: "space-between",
              width: "100%",
              marginTop: "15px",
            }}
            className="subUserEdit"
          >
            <div
              style={{
                marginTop: "0px",
              }}
              className="tambahProdukDistributorTextTitle"
            >
              Berakhir mulai tanggal
            </div>
            <div
              onClick={() => setShowPickerDate2(true)}
              style={{
                borderStyle: "solid",
                borderColor: "#bcbcbc",
                padding: "5px 15px",
                borderRadius: "7px",
                borderWidth: "1.5px",
              }}
            >
              {dataEdit.end_at ? (
                <p
                  style={{ color: "#000" }}
                  className="tambahProdukDistributorInfo"
                >
                  {moment(dataEdit.end_at).format("DD MMM YYYY")}
                </p>
              ) : (
                <>
                  {end_at ? (
                    <p
                      style={{ color: "#000" }}
                      className="tambahProdukDistributorInfo"
                    >
                      {moment(end_at).format("DD MMM YYYY")}
                    </p>
                  ) : (
                    <p
                      style={{ color: "#000" }}
                      className="tambahProdukDistributorInfo"
                    >
                      {"Pilih Tanggal"}
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
          <Calendar
            date={end_at}
            setDate={(date) => {
              if (dataEdit) {
                setDataEdit((prevState) => ({
                  ...prevState,
                  end_at: date,
                }));
                setShowPickerDate2(false);
              } else {
                setEnd_at(date);
                setShowPickerDate2(false);
              }
            }}
            openCalendar={showPickerDate2}
            minDate={minDate}
            // selectRange={selectRange}
            // setDate={(date) => {
            //     setDate(date);
            //     if(date.length > 0){
            //       setShowPickerDate(false)
            //     };
            //     console.log(JSON.stringify(date));
            //   }}
          />
          <hr></hr>
          {buttonDisabled ? (
            <button className="tambahProdukDistributorKirim">
              <p className="tambahProdukDistributorTextKirim">{"Kirim"}</p>
            </button>
          ) : (
            <button
              onClick={dataEdit ? editVoucher : postVoucher}
              className="tambahProdukDistributorKirim"
            >
              {dataEdit ? (
                <p className="tambahProdukDistributorTextKirim">{"Ubah"}</p>
              ) : (
                <p className="tambahProdukDistributorTextKirim">{"Kirim"}</p>
              )}
            </button>
          )}
        </div>
      )}
      <Loading loadingApi={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(VoucherPartner);
