import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import IconBack from "../assets/images/backArrow.webp";
import Product1 from "../assets/dummy/product1.jpg";
import "./ComplaintChoose.css";
import { useSelector } from "react-redux";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { produkAction, screenAction, orderAction } from "../actions";
import moment from "moment";
import NumberFormat from "react-number-format";
import error404 from "../assets/images/404.webp";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Header from "../components/HeaderDistributor"

export const ComplaintChoose = (props) => {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const { screenName } = useSelector((state) => state.ScreenReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [listDataComplaint, setListDataComplaint] = useState([]);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const [loadingApi, setLoadingApi] = useState(true);
  const [
    onEndReachedCalledDuringMomentum,
    setOnEndReachedCalledDuringMomentum,
  ] = useState(false);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  function NoData() {
    return (
      <div className="NoData">
        <img style={{ height: "50%", width: "100%" }} src={error404} />
        <p
          style={{
            fontSize: "14pt",
            fontFamily: "Lato-Bold",
            textAlign: "center",
            marginTop: "-20%",
          }}
        >
          {"Wah, daftar transaksi mu kosong"}
        </p>
        <p
          style={{
            fontSize: "12pt",
            fontFamily: "Lato-Bold",
            width: "75%",
            textAlign: "center",
            marginTop: "2px",
          }}
        >
          {"Yuk, isi dengan barang-barang yang ingin kamu beli"}
        </p>
        <button
          onClick={() => {
            dispatch(screenAction("produk", "screenName"));
            navigate("/produk");
          }}
          style={{
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#529F45",
            width: "65%",
            height: "8%",
            borderRadius: "5px",
            marginTop: "5px",
            color: "#FFFFFF",
            fontSize: "10pt",
            fontFamily: "Lato-Bold",
          }}
        >
          {"Mulai Belanja"}
        </button>
      </div>
    );
  }

  useEffect(() => {
    getDataComplaint();

    return () => {};
  }, []);

  // get complaint
  async function getDataComplaint() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/complaint?status=havent complained&page=${page}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      setLoadingApi(false);
      setListDataComplaint(
        page == 1 ? data.data : [...listDataComplaint, ...data.data]
      );
      setRefreshing(false);
      setLoadingMore(data.last_page > page);
      setMaxPage(data.last_page);
    } catch (error) {
      setLoadingApi(false);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  //load more data
  async function getMoreData() {
    if (!onEndReachedCalledDuringMomentum && page < maxPage) {
      setPage(page + 1);
      setLoadingMore(page < maxPage);
    }
    setOnEndReachedCalledDuringMomentum(page >= maxPage);
  }

  function handleRefresh() {
    setPage(1);
    setRefreshing(true);
  }

  function handleLoadMore() {
    if (!loadingMore) return null;
    return (
      <div>
        <CircularProgress color="success" />
      </div>
    );
  }

  return (
    <div className="complaintChooseContainer">
      <Header 
      titleHeader="Komplain Pesanan"
      />
      {listDataComplaint.length > 0 && !loadingApi ? (
        <div className="complaintDetail">
          <div className="complaintChooseTile">Pilih Transaksi</div>
          <InfiniteScroll
            dataLength={listDataComplaint.length}
            next={() => getMoreData()}
            hasMore={true}
            loader={() => handleLoadMore()}
            // refreshFunction={handleRefresh}
            // pullDownToRefresh
            // pullDownToRefreshThreshold={50}
            // pullDownToRefreshContent={handleLoadMore}
          >
            {listDataComplaint.map((item, index) => {
              return (
                <React.Fragment>
                  <hr></hr>
                  <div key={index} className="complaintChooseMain">
                    <a
                      onClick={() => {
                        dispatch(orderAction(item, "orderComplaint"));
                        navigate("/complaint/create");
                      }}
                    >
                      <div className="complaintChooseTitle">
                        <div className="complaintChooseTitleDate">
                          {moment(item.created_at).format("DD MMM YYYY HH:mm")}
                        </div>
                      </div>
                      <div className="complaintChooseContent">
                        {item.data_item[0]?.product?.image ? (
                          <img
                            src={
                              CONFIG.BASE_URL +
                              item.data_item[0]?.product?.image
                            }
                            alt="complaintChooseContentImage"
                            className="complaintChooseContentImage"
                          />
                        ) : (
                          <img
                            src={DummyImage}
                            alt="complaintChooseContentImage"
                            className="complaintChooseContentImage"
                          />
                        )}

                        <div className="complaintChooseContent2">
                          <div className="complaintChooseContentProduct">
                            {item.data_item[0].product.name ?? ""}
                          </div>
                          {item.data_item[0]?.half ? (
                            <p className="complaintContentPriceHalf">
                              {" ( "}
                              {item.data_item[0]?.qty_konversi}{" "}
                              {item.data_item[0]?.small_unit.charAt(0) +
                                item.data_item[0]?.small_unit
                                  .slice(1)
                                  .toLowerCase()}
                              {" )"}
                            </p>
                          ) : null}
                          <NumberFormat
                            value={Math.round(item.payment_final)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <div className="complaintChooseContentPrice">
                                Total : {value || 0}
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </a>
                  </div>
                </React.Fragment>
              );
            })}
          </InfiniteScroll>
        </div>
      ) : listDataComplaint.length == 0 && !loadingApi ? (
        <NoData />
      ) : null}
      <Loading loadingApi={loadingApi} /> 
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ComplaintChoose);
