import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Slider from "../components/Slider";
import Images from "../images";
import NumberFormat from "react-number-format";
import ReactStars from "react-rating-stars-component";
import "./Home.css";
import "./PaymentMethod.css";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import Product1 from "../assets/dummy/product1.jpg";
import Product2 from "../assets/dummy/product2.jpg";
import IconNotifAll from "../assets/images/NotifAll.webp";
import IconMenu from "../assets/images/Menu.webp";
import IconArrow from "../assets/images/backArrow.webp";
import IconCod from "../assets/images/COD.webp";
import IconCreditcard from "../assets/images/Credit-card.webp";
import IconBayar from "../assets/images/Bayar.webp";
import { useSelector } from "react-redux";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import CircularProgress from "@mui/material/CircularProgress";
import IconCart from "../assets/images/KeranjangActive.webp";
import {
  produkAction,
  screenAction,
  orderAction,
  LoginAction,
} from "../actions";
import { useDispatch } from "react-redux";
import Dialog from "../components/Dialog";
import IconClose from "../assets/images/Closemodalsearch.webp";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import IconShoppingCart from "../assets/images/Shopping-Cart.webp";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";
import IconMidtrans from "../assets/images/Midtrans.webp";
import Switch from "@mui/material/Switch";

export const Home = (props) => {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const { shop_total } = useSelector((state) => state.ProdukReducer);
  const { hasilPromo } = useSelector((state) => state.ProdukReducer);
  const { hasilPromoNotif } = useSelector((state) => state.ProdukReducer);
  const { hargaPromo } = useSelector((state) => state.ProdukReducer);
  const { shop } = useSelector((state) => state.ProdukReducer);
  const { partnerShop } = useSelector((state) => state.ProdukReducer);
  const idPartners = Object.keys(partnerShop);
  const { kredit } = useSelector((state) => state.ProdukReducer);
  const { pengirimanOrder } = useSelector((state) => state.ProdukReducer);
  const { totalResult } = useSelector((state) => state.ProdukReducer);
  const { promoOrder } = useSelector((state) => state.ProdukReducer);
  const { totalDiscount } = useSelector((state) => state.ProdukReducer);
  const { promoResult } = useSelector((state) => state.ProdukReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pilihPembayaran, setPilihPembayaran] = useState(
    dataUser.user.type_payment === "T"
      ? "Bayar langsung di tempat (COD)"
      : "Tempo"
  );
  const [keteranganPembayaran, setKeteranganPembayaran] = useState("");
  const [valuePembayaran, setValuePembayaran] = useState(
    dataUser.user.type_payment === "T" ? "cod" : "tempo"
  );
  const [image, setImage] = useState(
    dataUser.user.type_payment === "T" ? (
      <img
        src={IconCod}
        alt="paymentTopButtonIcon"
        className="paymentTopButtonIcon"
      />
    ) : (
      <img
        src={IconCreditcard}
        alt="paymentTopButtonIcon"
        className="paymentTopButtonIcon"
      />
    )
  );
  const [disableButton, setDisableButton] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [alertTambahan, setAlertTambahan] = useState("");
  const [alertJudul, setAlertJudul] = useState("");
  const [notifOrders, setNotifOrders] = useState(0);
  const [notifMessages, setNotifMessages] = useState(0);
  const [notifComplaints, setNotifComplaints] = useState(0);
  const [notifBroadcast, setNotifBroadcast] = useState(0);
  const [notifSubscribe, setNotifSubscribe] = useState(0);
  const [countNotifCart, setCountNotifCart] = useState(0);
  const [modalRewards, setModalRewards] = useState(false);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);
  const [isEnabled, setIsEnabled] = React.useState(false);
  const [dataQtyPartner, setDataQtyPartner] = useState({});
  const [dataMpmShop, setDataMpmShop] = useState({});

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            // setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            // setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  useEffect(() => {
    getNotifAll();
    getDataUser();
    hitungQtyPartner();
    return () => {};
  }, []);

  function hitungQtyPartner() {
    if (idPartners.length != 0) {
      let qty = [];
      let produk = [];
      let harga = [];
      let price_apps = [];
      idPartners.map((id) => {
        partnerShop[id].products.map((item) => qty.push(parseInt(item.qty)));
        partnerShop[id].products.map(
          (item) =>
            price_apps.push(parseInt(item.qty)) * Math.round(item.price_apps)
        );
        produk.push(partnerShop[id].products.length);
        harga.push(
          Math.round(partnerShop[id]?.detail[1].total_price_after_promo) +
            Math.round(partnerShop[id]?.detail[2].total_non_promo)
        );
      });
      setDataQtyPartner({
        qty: qty?.reduce((a, b) => a + b, 0),
        produk: produk?.reduce((a, b) => a + b, 0),
        price: harga?.reduce((a, b) => a + b, 0),
        price_apps: price_apps?.reduce((a, b) => a + b, 0),
      });
    } else {
      setDataQtyPartner({
        qty: 0,
        produk: 0,
        price: 0,
      });
    }
  }

  async function postOrder() {
    const total_payment_final =
      hargaPromo[1].total_price_after_promo + hargaPromo[2].total_non_promo;
    let dataShop = shop;
    let dataPartnerShop = partnerShop;
    if (shop?.length != 0) {
      const sumTotal = (arr = []) =>
        arr.reduce(
          (sum, { price_apps, qty }) =>
            sum + parseFloat(price_apps) * parseInt(qty),
          0
        );
      const total = sumTotal(shop?.products ?? 0);
      dataShop["data"] = {
        payment_method: "cod",
        delivery_service: "",
        payment_total: total || 0,
        payment_point: isEnabled
          ? JSON.parse(dataUser.user.point) > total_payment_final
            ? total_payment_final
            : dataUser.user.point
          : "",
        payment_final:
          parseFloat(hargaPromo[1].total_price_after_promo) +
          parseFloat(hargaPromo[2].total_non_promo),
      };
    } else {
      dataShop = {};
    }
    if (idPartners != 0) {
      idPartners.map(
        (id) =>
          (dataPartnerShop[id]["data"] = {
            payment_method: "cod",
            delivery_service: "",
            payment_total: Math.round(
              parseFloat(partnerShop[id]?.detail[0]?.total_price_before_promo) +
                parseFloat(partnerShop[id]?.detail[2]?.total_non_promo)
            ),
            payment_final: Math.round(
              parseFloat(partnerShop[id]?.detail[2]?.total_non_promo) +
                parseFloat(partnerShop[id]?.detail[0]?.total_price_before_promo)
            ),
          })
      );
    } else {
      dataPartnerShop = {};
    }
    try {
      let response = await axios.post(
        `${CONFIG.BASE_URL}/api/orders`,
        {
          data: {
            shoppingCartMpm: dataShop,
            shoppingCartPartner: dataPartnerShop,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = response.data;
      const dataOrder = response.data.data;

      if (data !== "" && data["success"] == true) {
        console.log("dataLainnya", JSON.stringify(data));
        if (valuePembayaran == "tempo" && dataUser.user?.credit_limit == 0) {
          setAlertData("Kredit limit Anda tidak cukup");
          setAlertJudul("Pemberitahuan");
          dispatch(orderAction(dataOrder, "paymentOrder"));
          setDisableButton(false);
          handleClickOpen();
        } else {
          dispatch(orderAction(dataOrder, "paymentOrder"));
          setDisableButton(false);
          navigate("/order/confirmation");
        }
      }
    } catch (error) {
      setDisableButton(false);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  const hitungQuantity = () => {
    let total;
    if (shop.length != 0) {
      const sumTotal = (arr = []) =>
        arr?.reduce((sum, { qty }) => sum + parseInt(qty), 0);
      total = sumTotal(shop?.products ?? 0);
    } else {
      total = 0;
    }

    // if (total === 0) return null;
    return (
      <p className="paymentBottomRowLeft">
        {parseInt(total || 0)}
        {" barang"}
        {" ( "}
        {parseInt(shop?.products?.length || 0)}
        {" produk"}
        {" )"}
      </p>
    );
  };
  const hitungQuantityPartner = (data) => {
    let total;
    if (data.length != 0) {
      const sumTotal = (arr = []) =>
        arr?.reduce((sum, { qty }) => sum + parseInt(qty), 0);
      total = sumTotal(data ?? 0);
    } else {
      total = 0;
    }
    return (
      <p className="paymentBottomRowLeft">
        {parseInt(total || 0)}
        {" barang"}
        {" ( "}
        {parseInt(data.length || 0)}
        {" produk"}
        {" )"}
      </p>
    );
  };

  const hitungHargaBarang = () => {
    let total;
    if (shop.length != 0) {
      const sumTotal = (arr = []) =>
        arr.reduce(
          (sum, { price_apps, qty }) =>
            sum + Math.round(price_apps) * parseInt(qty),
          0
        );
      total = sumTotal(shop?.products ?? 0);
    } else {
      total = 0;
    }
    return (
      <NumberFormat
        value={Math.round(total)}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"Rp "}
        renderText={(value) => (
          <p className="paymentBottomRowRight">{value || 0}</p>
        )}
      />
    );
  };
  const hitungHargaBarangPartner = (data) => {
    let total;
    if (data.length != 0) {
      const sumTotal = (arr = []) =>
        arr.reduce(
          (sum, { price_apps, qty }) =>
            sum + Math.round(price_apps) * parseInt(qty),
          0
        );
      total = sumTotal(data ?? 0);
    } else {
      total = 0;
    }
    return (
      <NumberFormat
        value={Math.round(total)}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"Rp "}
        renderText={(value) => (
          <p className="paymentBottomRowRight">{value || 0}</p>
        )}
      />
    );
  };

  const hitungHargaPromoDiskon = () => {
    const sumTotal = (arr = []) =>
      arr.reduce(
        (sum, item) =>
          sum + (item.promo_status == 1 ? item.promo_reward.disc : 0),
        0
      );
    const total = sumTotal(hasilPromo ?? 0);
    if (total === 0) return null;
    return (
      <NumberFormat
        value={Math.round(total)}
        displayType={"text"}
        thousandSeparator={true}
        // prefix={'Rp '}
        // decimalScale={2}
        fixedDecimalScale={true}
        renderText={(value) => (
          <p className="paymentBottomRowRight">
            {value || 0}
            {" %"}
          </p>
        )}
      />
    );
  };

  const hitungHargaPromoNominal = () => {
    const sumTotal = (arr = []) =>
      arr.reduce(
        (sum, item) =>
          sum + (item.promo_status == 1 ? item.promo_reward.nominal : 0),
        0
      );
    const total = sumTotal(hasilPromo ?? 0);
    if (total === 0) return null;
    return (
      <NumberFormat
        value={Math.round(total)}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"Rp "}
        // decimalScale={2}
        fixedDecimalScale={true}
        renderText={(value) => (
          <p className="paymentBottomRowRight">{value || 0}</p>
        )}
      />
    );
  };

  const hitungHargaPromoPoint = () => {
    const sumTotal = (arr = []) =>
      arr.reduce(
        (sum, item) =>
          sum + (item.promo_status == 1 ? item.promo_reward.point : 0),
        0
      );
    const total = sumTotal(hasilPromo ?? 0);
    if (total === 0) return null;
    return (
      <NumberFormat
        value={Math.round(total)}
        displayType={"text"}
        thousandSeparator={true}
        // prefix={'Rp '}
        // decimalScale={2}
        fixedDecimalScale={true}
        renderText={(value) => (
          <p className="paymentBottomRowRight">{value || 0}</p>
        )}
      />
    );
  };

  const hitungHargaBayar = () => {
    const sumTotalDiskon = (arr = []) =>
      arr.reduce((sum, { promo_reward }) => sum + promo_reward.disc, 0);
    const diskon = sumTotalDiskon(promoResult ?? 0);
    const sumTotalNominal = (arr = []) =>
      arr.reduce((sum, { promo_reward }) => sum + promo_reward.nominal, 0);
    const nominal = sumTotalNominal(promoResult ?? 0);
    const result = totalResult ?? shop_total;
    const total = result - result * (diskon / 100) - nominal; // (this.props.promoResult ?? 0)
    if (total === 0) return null;
    return (
      <NumberFormat
        value={Math.round(total)}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"Rp "}
        // decimalScale={2}
        fixedDecimalScale={true}
        renderText={(value) => (
          <p className="paymentBottomRowRight">{value || 0}</p>
        )}
      />
    );
  };

  const hitungDiskon = () => {
    let total;
    if (shop.length != 0) {
      const sumTotal = (arr = []) =>
        arr.reduce((sum, { order_disc }) => sum + Math.round(order_disc), 0);
      total = sumTotal(shop?.products ?? 0);
    }
    return (
      <NumberFormat
        value={Math.round(total)}
        displayType={"text"}
        thousandSeparator={true}
        // prefix={'Rp '}
        // decimalScale={2}
        fixedDecimalScale={true}
        renderText={(value) => (
          <p className="paymentBottomRowRight">{value || 0}</p>
        )}
      />
    );
  };

  async function getNotifAll() {
    console.log("cek hit notif");
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/notification-all`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      // console.log('DATA TEST', data.total_chat);
      setNotifOrders(data.total_order);
      setNotifMessages(data.total_chat);
      setNotifComplaints(data.total_complaint);
      setNotifSubscribe(data.total_subscribe);
      setNotifBroadcast(data.total_broadcast);
      setCountNotifCart(data.total_cart);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========NOTIF================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  function renderCountNotificationBadge() {
    try {
      let count = 0;
      let hasil = notifMessages;
      let hasil2 = notifSubscribe;
      let hasil3 = notifOrders;
      let hasil4 = notifComplaints;
      let hasil5 = notifBroadcast;
      count =
        count +
        parseInt(hasil) +
        parseInt(hasil2) +
        parseInt(hasil3) +
        parseInt(hasil4) +
        parseInt(hasil5);
      // console.log(count);
      if (count > 0) {
        return count;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (alertData == "Kredit limit Anda tidak cukup") {
      navigate("/order/confirmation");
    }
  };

  const list = () => (
    <Box
      sx={{ width: "auto" }}
      role="presentation"
      onClick={() => setModalRewards(false)}
      onKeyDown={() => setModalRewards(false)}
    >
      <List>
        {(dataUser.user.type_payment === "T" ||
          dataUser.user.type_payment === "K") && (
          <ListItem
            button
            onClick={() => {
              setPilihPembayaran("Bayar langsung di tempat (COD)");
              setImage(
                <img
                  src={IconCod}
                  alt="paymentTopButtonIcon"
                  className="paymentTopButtonIcon"
                />
              );
              setValuePembayaran("cod");
            }}
          >
            <ListItemIcon>
              <img
                src={IconCod}
                alt="paymentTopButtonIcon"
                className="paymentTopButtonIcon"
              />
            </ListItemIcon>
            <ListItemText>Bayar langsung di tempat (COD)</ListItemText>
          </ListItem>
        )}
        {dataUser.user.type_payment === "K" && (
          <ListItem
            button
            onClick={() => {
              setPilihPembayaran("Tempo");
              setImage(
                <img
                  src={IconCreditcard}
                  alt="paymentTopButtonIcon"
                  className="paymentTopButtonIcon"
                />
              );
              setValuePembayaran("tempo");
            }}
          >
            <ListItemIcon>
              <img
                src={IconCreditcard}
                alt="paymentTopButtonIcon"
                className="paymentTopButtonIcon"
              />
            </ListItemIcon>
            <ListItemText>Tempo</ListItemText>
          </ListItem>
        )}
        {/* <ListItem
          button
          onClick={() => {
            setPilihPembayaran("Transfer Bank");
            setImage(
              <img
                src={IconMidtrans}
                alt="paymentTopButtonIcon"
                className="paymentTopButtonIcon"
              />
            );
            setValuePembayaran("transfer");
          }}
        >
          <ListItemIcon>
            <img
              src={IconMidtrans}
              alt="paymentTopButtonIcon"
              className="paymentTopButtonIcon"
            />
          </ListItemIcon>
          <ListItemText>Transfer Bank</ListItemText>
        </ListItem> */}
      </List>
    </Box>
  );

  const toggleSwitch = () => {
    setIsEnabled((previousState) => !previousState);
  };

  const getDataUser = async () => {
    try {
      let response = await axios.get(`${CONFIG.BASE_URL}/api/profile`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const data = response.data.data;
      let dataTemp = dataUser;
      dataTemp.user = data;
      localStorage.setItem("dataUser", JSON.stringify(dataTemp));
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error===========DataUser=============",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        this.showSnackbarBusy();
      } else if (errorNetwork) {
        this.showSnackbarInet();
      } else if (error400) {
        Storage.removeItem("token");
        this.setState({
          alertData: "Waktu Sesi Anda Telah Habis",
          modalAlert: !this.state.modalAlert,
        });
      }
    }
  };

  function HitungTotal() {
    if (isEnabled && JSON.parse(Math.round(dataUser.user?.point)) >= 5000) {
      let poin;
      poin =
        JSON.parse(parseInt(dataUser.user?.point)) >
        parseFloat(hargaPromo[1].total_price_after_promo) +
          parseFloat(hargaPromo[2].total_non_promo)
          ? parseFloat(hargaPromo[1].total_price_after_promo) +
            parseFloat(hargaPromo[2].total_non_promo)
          : parseInt(dataUser.user?.point);
      return Math.round(shop_total) - poin;
    } else {
      return Math.round(shop_total);
    }
  }
  function cekDiskon() {
    const w = shop?.products?.map((item) => {
      return item.disc_cabang;
    });
    return w;
  }
  console.log("shop " + JSON.stringify(partnerShop));
  return (
    <div className="homeContainer">
      <Drawer
        anchor={"bottom"}
        open={modalRewards}
        onClose={() => setModalRewards(false)}
      >
        {list()}
      </Drawer>
      <Dialog
        alertData={alertData}
        alertJudul={alertJudul}
        handleClose={handleClose}
        open={open}
      />
      <Header
        notifMessages={notifMessages}
        notifSubscribe={notifSubscribe}
        notifOrders={notifOrders}
        notifComplaints={notifComplaints}
        notifBroadcast={notifBroadcast}
        countNotifCart={countNotifCart}
        titleHeader={"Pengiriman"}
        item={false}
      />

      <div className="deliveryContent">
        <div className="paymentTopContainer">
          <p className="paymentTopTitle">Metode Pembayaran</p>
          <div
            onClick={() => setModalRewards(true)}
            className="paymentTopButtonContainer"
          >
            {image}
            <p className="paymentTopButtonText">{pilihPembayaran}</p>
          </div>
        </div>
        <div className="paymentBottomContainer">
          <p className="paymentBottomTitle">Ringkasan Pembayaran</p>
          <hr></hr>

          {/* ubah disini */}
          <p className="payment-method-text-shop">Produk Semut Gajah</p>
          <div className="paymentBottomRow">
            {hitungQuantity()}
            {hitungHargaBarang()}
          </div>
          {hitungDiskon != null ? (
            <div className="paymentBottomRow">
              <p className="paymentBottomRowLeft">
                {"Total Diskon "}
                {"( "}
                {"kelas "}
                {dataUser.user?.salur_code}
                {" )"}
              </p>
              {hitungDiskon()}
            </div>
          ) : null}
          <div className="paymentBottomRow">
            <p className="paymentBottomRowLeft">Total Ongkos Kirim</p>
            <p className="paymentBottomRowRight">
              {pengirimanOrder == "bebasOngkir" ? "Bebas Ongkir" : null}
            </p>
          </div>
          <div className="paymentBottomRow">
            <p className="paymentBottomRowLeft">Total Belanja</p>
            <NumberFormat
              value={
                Math.round(shop.detail[1].total_price_after_promo) +
                Math.round(shop.detail[2].total_non_promo)
              }
              displayType={"text"}
              thousandSeparator={true}
              prefix={"Rp "}
              renderText={(value) => (
                <p className="paymentBottomRowRight">{value || 0}</p>
              )}
            />
          </div>
          {hasilPromo != null ? (
            <>
              {hitungHargaPromoNominal() != null ? (
                <div className="paymentBottomRow">
                  <p className="paymentBottomRowLeft">
                    Total Promo Potongan Harga
                  </p>
                  {hitungHargaPromoNominal()}
                </div>
              ) : null}
              {hitungHargaPromoPoint() != null ? (
                <div className="paymentBottomRow">
                  <p className="paymentBottomRowLeft">Total Promo Poin</p>
                  {hitungHargaPromoPoint()}
                </div>
              ) : null}
              <hr />
              {hasilPromo?.map((item, index) => {
                if (item?.promo_reward?.nominal && item?.promo_reward?.disc) {
                  return (
                    <>
                      {shop.map((value, i) => {
                        if (value.promo_id == item.promo_id) {
                          return (
                            <div key={i} className="paymentBottomRow">
                              <p className="paymentBottomRowLeft">
                                Nama Produk
                              </p>
                              <p className="paymentBottomRowRight">
                                {value?.name}
                              </p>
                            </div>
                          );
                        }
                      })}
                      <div className="paymentBottomRow">
                        <p className="paymentBottomRowLeft">Nama Promo</p>
                        <p className="paymentBottomRowRight">
                          {item?.promo_title}
                        </p>
                      </div>
                      <div className="paymentBottomRow">
                        <p className="paymentBottomRowLeft">
                          {"Diskon Promo "}
                          {"( "}
                          {item?.promo_reward?.disc}
                          {"%"}
                          {" )"}
                        </p>
                        <p className="paymentBottomRowRight">
                          {"- Rp "}
                          {item?.promo_reward?.nominal}
                        </p>
                      </div>
                      <hr />
                    </>
                  );
                } else if (
                  item?.promo_reward?.nominal &&
                  !item?.promo_reward?.disc
                ) {
                  return (
                    <>
                      {shop.map((value, i) => {
                        if (value.promo_id == item.promo_id) {
                          return (
                            <div key={i} className="paymentBottomRow">
                              <p className="paymentBottomRowLeft">
                                Nama Produk
                              </p>
                              <p className="paymentBottomRowRight">
                                {value?.name}
                              </p>
                            </div>
                          );
                        }
                      })}
                      <div className="paymentBottomRow">
                        <p className="paymentBottomRowLeft">Nama Promo</p>
                        <p className="paymentBottomRowRight">
                          {item?.promo_title}
                        </p>
                      </div>
                      <div className="paymentBottomRow">
                        <p className="paymentBottomRowLeft">
                          {"Nominal Promo "}
                        </p>
                        <p className="paymentBottomRowRight">
                          {"- Rp "}
                          {item?.promo_reward?.nominal}
                        </p>
                      </div>
                      <hr />
                    </>
                  );
                } else if (item?.promo_reward?.point) {
                  return (
                    <>
                      {shop.map((value, i) => {
                        if (value.promo_id == item.promo_id) {
                          return (
                            <div key={i} className="paymentBottomRow">
                              <p className="paymentBottomRowLeft">
                                Nama Produk
                              </p>
                              <p className="paymentBottomRowRight">
                                {value?.name}
                              </p>
                            </div>
                          );
                        }
                      })}
                      <div className="paymentBottomRow">
                        <p className="paymentBottomRowLeft">Nama Promo</p>
                        <p className="paymentBottomRowRight">
                          {item?.promo_title}
                        </p>
                      </div>
                      <div className="paymentBottomRow">
                        <p className="paymentBottomRowLeft">{"Poin Promo "}</p>
                        <p className="paymentBottomRowRight">
                          {"+ "}
                          {item?.promo_reward?.point}
                        </p>
                      </div>
                      <hr />
                    </>
                  );
                } else if (item?.promo_reward?.product.length > 0) {
                  return (
                    <>
                      {item?.promo_reward?.product?.map((val, ind) => {
                        if (val.product_name != null && val.qty != null) {
                          return (
                            <>
                              {shop?.products?.map((value, i) => {
                                if (value.promo_id == item.promo_id) {
                                  return (
                                    <>
                                      <div className="paymentBottomRow">
                                        <p className="paymentBottomRowLeft">
                                          Nama Produk
                                        </p>
                                        <p className="paymentBottomRowRight">
                                          {value?.name}
                                        </p>
                                      </div>
                                      <div className="paymentBottomRow">
                                        <p className="paymentBottomRowLeft">
                                          {"Hadiah Produk"}
                                        </p>
                                        <p className="paymentBottomRowRight">
                                          {val.product_name}
                                        </p>
                                      </div>
                                      <div className="paymentBottomRow">
                                        <p className="paymentBottomRowLeft">
                                          {"Jumlah"}
                                        </p>
                                        <p className="paymentBottomRowRight">
                                          {val.qty}
                                          {" pcs"}
                                        </p>
                                      </div>
                                    </>
                                  );
                                }
                              })}
                              {item.promo_all == 1 && (
                                <div>
                                  <div className="paymentBottomRow">
                                    <p className="paymentBottomRowLeft">
                                      {"Hadiah Produk Umum"}
                                    </p>
                                    <p className="paymentBottomRowRight">
                                      {val.product_name}
                                    </p>
                                  </div>
                                  <div className="paymentBottomRow">
                                    <p className="paymentBottomRowLeft">
                                      {"Jumlah"}
                                    </p>
                                    <p className="paymentBottomRowRight">
                                      {val.qty}
                                      {" pcs"}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </>
                          );
                        }
                      })}
                      <hr />
                    </>
                  );
                }
              })}
              {/* {hasilPromo?.map((item, index) => {
                return (
                  <>
                    <hr />
                    {item?.promo_reward?.nominal && item?.promo_reward?.disc && (
                      <>
                        {shop.map((value, i) => {
                          if (value.promo_id == item.promo_id) {
                            return (
                              <div key={i} className="paymentBottomRow">
                                <p className="paymentBottomRowLeft">
                                  Nama Produk
                                </p>
                                <p className="paymentBottomRowRight">
                                  {value?.name}
                                </p>
                              </div>
                            );
                          }
                        })}
                        <div className="paymentBottomRow">
                          <p className="paymentBottomRowLeft">Nama Promo</p>
                          <p className="paymentBottomRowRight">
                            {item?.promo_title}
                          </p>
                        </div>
                        <div className="paymentBottomRow">
                          <p className="paymentBottomRowLeft">
                            {"Diskon Promo "}
                            {"( "}
                            {item?.promo_reward?.disc}
                            {"%"}
                            {" )"}
                          </p>
                          <p className="paymentBottomRowRight">
                            {"- Rp "}
                            {item?.promo_reward?.nominal}
                          </p>
                        </div>
                      </>
                    )}
                    {item?.promo_reward?.nominal && !item?.promo_reward?.disc && (
                      <>
                        {shop.map((value, i) => {
                          if (value.promo_id == item.promo_id) {
                            return (
                              <div key={i} className="paymentBottomRow">
                                <p className="paymentBottomRowLeft">
                                  Nama Produk
                                </p>
                                <p className="paymentBottomRowRight">
                                  {value?.name}
                                </p>
                              </div>
                            );
                          }
                        })}
                        <div className="paymentBottomRow">
                          <p className="paymentBottomRowLeft">Nama Promo</p>
                          <p className="paymentBottomRowRight">
                            {item?.promo_title}
                          </p>
                        </div>
                        <div className="paymentBottomRow">
                          <p className="paymentBottomRowLeft">
                            {"Nominal Promo "}
                          </p>
                          <p className="paymentBottomRowRight">
                            {"- Rp "}
                            {item?.promo_reward?.nominal}
                          </p>
                        </div>
                      </>
                    )}
                    {item?.promo_reward?.point && (
                      <>
                        {shop.map((value, i) => {
                          if (value.promo_id == item.promo_id) {
                            return (
                              <div key={i} className="paymentBottomRow">
                                <p className="paymentBottomRowLeft">
                                  Nama Produk
                                </p>
                                <p className="paymentBottomRowRight">
                                  {value?.name}
                                </p>
                              </div>
                            );
                          }
                        })}
                        <div className="paymentBottomRow">
                          <p className="paymentBottomRowLeft">Nama Promo</p>
                          <p className="paymentBottomRowRight">
                            {item?.promo_title}
                          </p>
                        </div>
                        <div className="paymentBottomRow">
                          <p className="paymentBottomRowLeft">
                            {"Poin Promo "}
                          </p>
                          <p className="paymentBottomRowRight">
                            {"+ "}
                            {item?.promo_reward?.point}
                          </p>
                        </div>
                      </>
                    )}
                    {item?.promo_reward?.product?.map((val, ind) => {
                      if (val.product_name != null && val.qty != null) {
                        return (
                          <>
                            {shop.map((value, i) => {
                              if (value.promo_id == item.promo_id) {
                                return (
                                  <>
                                    <div className="paymentBottomRow">
                                      <p className="paymentBottomRowLeft">
                                        Nama Produk
                                      </p>
                                      <p className="paymentBottomRowRight">
                                        {value?.name}
                                      </p>
                                    </div>
                                    <div className="paymentBottomRow">
                                      <p className="paymentBottomRowLeft">
                                        {"Hadiah Produk"}
                                      </p>
                                      <p className="paymentBottomRowRight">
                                        {val.product_name}
                                      </p>
                                    </div>
                                    <div className="paymentBottomRow">
                                      <p className="paymentBottomRowLeft">
                                        {"Jumlah"}
                                      </p>
                                      <p className="paymentBottomRowRight">
                                        {val.qty}
                                        {" pcs"}
                                      </p>
                                    </div>
                                  </>
                                );
                              }
                            })}
                            {item.promo_all == 1 && (
                              <div>
                                <div className="paymentBottomRow">
                                  <p className="paymentBottomRowLeft">
                                    {"Hadiah Produk Umum"}
                                  </p>
                                  <p className="paymentBottomRowRight">
                                    {val.product_name}
                                  </p>
                                </div>
                                <div className="paymentBottomRow">
                                  <p className="paymentBottomRowLeft">
                                    {"Jumlah"}
                                  </p>
                                  <p className="paymentBottomRowRight">
                                    {val.qty}
                                    {" pcs"}
                                  </p>
                                </div>
                              </div>
                            )}
                          </>
                        );
                      }
                    })}
                  </>
                );
              })} */}
            </>
          ) : null}
          {/* produk distributor */}
          {idPartners.length !== 0
            ? idPartners.map((id, _index) => {
                const lastArray = partnerShop[id]?.promo_result.length - 1;
                return (
                  <div className="payment-method-div-partner" key={_index}>
                    <p className="payment-method-text-shop-partner">
                      {partnerShop[id].products[0].shop_name}
                    </p>
                    <div className="paymentBottomRowPartner">
                      {hitungQuantityPartner(partnerShop[id].products)}
                      {hitungHargaBarangPartner(partnerShop[id].products)}
                    </div>
                    {/* {hitungDiskon != null ? (
                            <div className="paymentBottomRowPartner">
                              <p className="paymentBottomRowLeft">
                                {"Total Diskon "}
                                {"( "}
                                {"kelas "}
                                {dataUser.user?.salur_code}
                                {" )"}
                              </p>
                              {hitungDiskon()}
                            </div>
                          ) : null} */}
                    <div className="paymentBottomRowPartner">
                      <p className="paymentBottomRowLeft">Total Ongkos Kirim</p>
                      <p className="paymentBottomRowRight">
                        {pengirimanOrder == "bebasOngkir"
                          ? "Bebas Ongkir"
                          : null}
                      </p>
                    </div>

                    {partnerShop[id]?.promo_result[lastArray].nominal !==
                      null ||
                    partnerShop[id]?.promo_result[lastArray].disc !== null ? (
                      <div className="paymentBottomRowPartner">
                        <p className="paymentBottomRowLeft">
                          {"Potongan (Voucher)"}
                        </p>
                        <NumberFormat
                          value={
                            Math.round(
                              partnerShop[id].detail[1].total_price_after_promo
                            ) +
                            Math.round(
                              partnerShop[id].detail[2].total_non_promo
                            )
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"Rp "}
                          renderText={(value) => (
                            <p className="paymentBottomRowRight">
                              {partnerShop[id]?.promo_result[lastArray].nominal
                                ? value || 0
                                : partnerShop[id]?.promo_result[lastArray]
                                    .disc + "%"}
                            </p>
                          )}
                        />
                      </div>
                    ) : null}
                    <div className="paymentBottomRowPartner">
                      <p className="paymentBottomRowLeft">Total Belanja</p>
                      <NumberFormat
                        value={
                          Math.round(
                            partnerShop[id].detail[1].total_price_after_promo
                          ) +
                          Math.round(partnerShop[id].detail[2].total_non_promo)
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Rp "}
                        renderText={(value) => (
                          <p className="paymentBottomRowRight">{value || 0}</p>
                        )}
                      />
                    </div>
                  </div>
                );
              })
            : null}

          {JSON.parse(Math.round(dataUser.user?.point)) >= 5000 &&
          hargaPromo != undefined ? (
            <>
              <p className="paymentBottomTitle">Pakai Poin?</p>
              <hr></hr>
              <div className="buttonSwitch">
                <NumberFormat
                  value={
                    JSON.parse(dataUser.user?.point) >
                    Math.round(hargaPromo[1]?.total_price_after_promo) +
                      Math.round(hargaPromo[2]?.total_non_promo)
                      ? Math.round(hargaPromo[1]?.total_price_after_promo) +
                        Math.round(hargaPromo[2]?.total_non_promo)
                      : Math.round(dataUser.user?.point)
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                  renderText={(value) => (
                    <p
                      style={{ color: "#529F45" }}
                      className="paymentBottomRowLeft"
                    >
                      {value || 0}
                    </p>
                  )}
                />

                <Switch
                  checked={isEnabled}
                  onChange={toggleSwitch}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
            </>
          ) : null}

          <div className="paymentBottomPay">
            <div className="paymentBottomPayLeft">
              <p className="paymentBottomPayLeftTitle">Total Bayar</p>
              <NumberFormat
                value={HitungTotal()}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"Rp "}
                renderText={(value) => (
                  <p className="paymentBottomPayLeftPrice">{value || 0}</p>
                )}
              />
            </div>
            <div className="paymentBottomPayRight">
              {disableButton ? (
                <button className="paymentBottomPayButton">
                  <img
                    src={IconBayar}
                    alt="paymentBottomPayButtonIcon"
                    className="paymentBottomPayButtonIcon"
                  />
                  <p className="paymentBottomPayButtonText">Pesan</p>
                </button>
              ) : (
                <button
                  onClick={() => {
                    setDisableButton(true);
                    postOrder();
                  }}
                  className="paymentBottomPayButton"
                >
                  <img
                    src={IconBayar}
                    alt="paymentBottomPayButtonIcon"
                    className="paymentBottomPayButtonIcon"
                  />
                  <p className="paymentBottomPayButtonText">Pesan</p>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Navbar screenName={"Keranjang"} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
