import React, {useEffect} from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import IconBack from "../assets/images/backArrow.webp";
import IconSearch from "../assets/images/Search.webp";
import Product1 from "../assets/dummy/product1.jpg"
import Product2 from "../assets/dummy/product2.jpg"
import "./Transaction.css";

export const Transaction = (props) => {
  const navigate = useNavigate();
  return (
    <div className="transactionContainer">
        <div className="transactionHeader">
            <div className="transactionHeaderTitle">
                <div className="headerArrowContainer">
                    <img src={IconBack} alt="userLogo" className="transactionBack" />
                </div>
                <div className="transactionBackText">
                    Daftar Transaksi
                </div>
            </div>
            <div className="transactionSearchContainer">
                <input
                    className="transactionTextInput"
                    placeholder="Pencarian..."
                    type="text"
                />
                <img src={IconSearch} alt="headerSearchLogo" className="transactionSearchLogo" />
            </div>
            <div className="transactionHeaderFilter">
                <div className="transactionStatusContainer">
                    <select
                        className="transactionSelect"
                    >
                        <option>Semua Status</option>
                    </select>
                </div>
                <div className="transactionDateContainer">
                    <select
                        className="transactionSelect"
                    >
                        <option>Semua Tanggal</option>
                    </select>
                </div>
            </div>
        </div>
        <div className="transaction">
            <div className="transactionMain">
                <div className="transactionTitle">
                    <div className="transactionTitleDate">
                        5 Februari 2022 pukul 13.57
                    </div>
                    <div className="transactionTitleStatus">
                        <div className="transactionTitleStatusText">
                            Transaksi Baru
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="transactionContent">
                    <img src={Product1} alt="transactionContentImage" className="transactionContentImage"/>
                    <div className="transactionContent2"> 
                        <div className="transactionContentProduct">
                            Rapet Wangi Kaplet Box (Isi 1 Blister)
                        </div>
                        <div className="transactionContentPrice">
                            Rp. 8,976 (1 barang)
                        </div>
                    </div>
                </div>
            </div>
            <div className="transactionMain">
                <div className="transactionTitle">
                    <div className="transactionTitleDate">
                        5 Februari 2022 pukul 13.57
                    </div>
                    <div className="transactionTitleStatus">
                        <div className="transactionTitleStatusText">
                            Transaksi Terkonfirmasi
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="transactionContent">
                    <img src={Product2} alt="transactionContentImage" className="transactionContentImage"/>
                    <div className="transactionContent2"> 
                        <div className="transactionContentProduct">
                            Freshcare Strong & Rilex Pump
                        </div>
                        <div className="transactionContentPrice">
                            Rp. 8,976 (1 barang)
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Transaction);
