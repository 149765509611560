import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import "./TopSpender.css";
import { useNavigate } from "react-router-dom";
import SimpleHeader from "../components/HeaderDistributor";
import axios from "axios";
import { promoAction } from "../actions";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Loading from "../components/Loading";

export const TopSpender = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);
  const [loadingApi, setLoadingApi] = useState(true);
  const { promo } = useSelector((state) => state.PromoReducer);
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));

  useEffect(() => {
    if(id != null ){
      getTopSpender();
    }else{
      setTimeout(() => setLoadingApi(false),1000)
    }
    return () => {};
  }, []);
  async function getTopSpender() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/top-spender/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = response.data.data;
      dispatch(promoAction(data, "promo"));
      setLoadingApi(false);
    } catch (error) {
      setLoadingApi(false)
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  function lihatTopSpender() {
    window.open(
      CONFIG.BASE_URL +
        "/top-spender/" +
        promo.id +
        "/" +
        dataUser.user.customer_code
    );
  }

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  return (
    <div className="topSpenderContainer">
      <SimpleHeader titleHeader="Top Spender" />
      {loadingApi == true ? null : (
        <div className="topSpenderContent">
          {promo?.banner ? (
            <img
              src={CONFIG.BASE_URL + promo.banner}
              alt="homeBannerPromoImage"
              className="topSpenderImage"
            />
          ) : (
            <img
              src={DummyImage}
              alt="homeBannerPromoImage"
              className="topSpenderImage"
            />
          )}
          <div className="judulTopSpender">{promo.title}</div>
          <div className="tableTopSpender">
            <table>
              <tr>
                <th>Peringkat</th>
                <th>Reward</th>
              </tr>
              {promo?.rank_reward?.length > 0
                ? promo?.rank_reward?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td
                          className={index % 2 === 0 ? "colGanjil" : "colGenap"}
                        >
                          Peringkat {item.pos}
                        </td>
                        <td
                          className={index % 2 === 0 ? "colGanjil" : "colGenap"}
                        >
                          {item.nominal}
                        </td>
                      </tr>
                    );
                  })
                : null}
            </table>
          </div>
          <div className="cardDescription">
            Deskripsi
            <div className="description">{promo.description}</div>
          </div>
          <div className="btnTopSpender" onClick={() => lihatTopSpender()}>
            Lihat top spender
          </div>
        </div>
      )}
      <Loading loadingApi={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TopSpender);
