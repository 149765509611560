import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CONFIG from "../config/config";
import Header from "../components/HeaderDistributor";
import "./DetailVoucher.css";
import Dialog from "../components/Dialog";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import moment from "moment";
import NumberFormat from "react-number-format";

export const DetailVoucher = () => {
  const navigate = useNavigate();
  const [loadingApi, setLoadingApi] = useState(true);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);
  const [page, setPage] = useState(1);
  const [detailVoucher, setDetailVoucher] = useState({});
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("idVoucher");

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  useEffect(() => {
    getDataVoucher();
  }, []);

  async function getDataVoucher() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/distributor-partner/vouchers?page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = response.data.data.find((obj) => obj.id === parseInt(id));
      setDetailVoucher(data);
      setLoadingApi(false);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }
  console.log("data", detailVoucher);
  return (
    <div className="containerDetailVoucher">
      <Header titleHeader="Detail Voucher" />
      <div className="viewContentDetailVoucher">
        <img
          src={CONFIG.BASE_URL + detailVoucher.file}
          className="detailVoucherImage"
        />
        <div className="viewTextDetailVoucher">
          <p className="textCodeDetailVoucher">{detailVoucher.code}</p>
          <div className="garisDetailsVoucher"/>
          <div className="viewTextKiriKanan">
            <p className="textKiriDetailVoucher">Periode Voucher</p>
            <p className="textKananDetailVoucher">
              {moment(detailVoucher?.start_at).format("DD MMM YYYY")}
              {" - "}
              {moment(detailVoucher?.end_at).format("DD MMM YYYY")}
            </p>
          </div>
          <div className="garisDetailsVoucher"/>
          <p className="textKiriDeskripsilVoucher">Deskripsi</p>
          <p className="textnilaiDeskripsiVoucher">{detailVoucher.description}</p>
          <div className="garisDetailsVoucher"/>
          <div className="viewTextKiriKanan">
            <p className="textKiriDetailVoucher">Promo diskon</p>
            {detailVoucher.type === "percent" ? (
              <p className="textKananDetailVoucher">
                {detailVoucher.percent}
                {" %"}
              </p>
            ) : (
              <NumberFormat
                value={detailVoucher.nominal}
                displayType={"text"}
                thousandSeparator={true}
                prefix={" Rp "}
                renderText={(value) => (
                  <p className="textKananDetailVoucher">{value || 0}</p>
                )}
              />
            )}
          </div>
          <div className="viewTextKiriKanan">
            <p className="textKiriDetailVoucher">Minimal transaksi</p>
            <NumberFormat
                value={detailVoucher.min_transaction}
                displayType={"text"}
                thousandSeparator={true}
                prefix={" Rp "}
                renderText={(value) => (
                  <p className="textKananDetailVoucher">{value || 0}</p>
                )}
              />
          </div>
          <div className="viewTextKiriKanan">
            <p className="textKiriDetailVoucher">Status Voucher</p>
            <p className="textKananDetailVoucher">{detailVoucher.status === 1 ? "Aktif":"Tidak aktif"}</p>
          </div>
          <div className="viewTextKiriKanan">
            <p className="textKiriDetailVoucher">Voucher tidak terlihat</p>
            <p className="textKananDetailVoucher">{detailVoucher.status_eksklusif === 1 ? "Terlihat":"Tidak terlihat"}</p>
          </div>
          <div className="viewTextKiriKanan"></div>
        </div>
      </div>
      <Loading loadingApi={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

export default DetailVoucher;
