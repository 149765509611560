import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default function DialogPartner({
    open,
    handleClose,
    handleClickOpen,
    alertData,
    alertJudul,
    alertTambahan,
    buttonDialogCancel,
    buttonDialogOK,
}) {
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{alertJudul}</DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{ fontSize: 20, color: "#529F45", marginBottom: 2 }}
            id="alert-dialog-slide-description"
          >
            {alertTambahan}
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
            {alertData}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {buttonDialogCancel ? (
            <Button onClick={(e) => handleClose((e = "cancel"))}>
              {buttonDialogCancel}
            </Button>
          ) : (
            <Button onClick={(e) => handleClose((e = "cancel"))}>Batal</Button>
          )}
          {buttonDialogOK ? (
            <Button onClick={(e) => handleClose((e = "ok"))}>
              {buttonDialogOK}
            </Button>
          ) : (
            <Button onClick={(e) => handleClose((e = "ok"))}>OK</Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  )
}
