import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/images/IconLogo.webp";
import IconPhone from "../assets/images/Phone.webp";
import IconUser from "../assets/images/User.webp";
import "./ChooseVerification.css";
import axios from "axios";
import CONFIG from "../config/config";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { screenAction } from "../actions";

export const ChooseVerification = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { dataUser } = useSelector((state) => state.UserReducer);
  const { phone } = useSelector((state) => state.UserReducer);
  const { screenName } = useSelector((state) => state.ScreenReducer);

  async function sendOTPWA() {
    if (screenName == "EditTelepon") {
      const formData = {
        phone: phone,
        customer_code: dataUser.user.customer_code,
      };
      // console.log("formData", formData);
      if (formData) {
        try {
          let response = await axios({
            method: "POST",
            url: `${CONFIG.BASE_URL}/api/otp/phone-number/wa`,
            data: formData,
          });
          console.log("hasil data", response.data);
          const data = response.data;
          if (data != "" && data["success"] == true) {
            dispatch(screenAction("WAEDITTELEPON", "screenName"));
            navigate("/user/edit/phone/otp");
          }
        } catch (error) {
          let error429 =
            JSON.parse(JSON.stringify(error)).message ===
            "Request failed with status code 429";
          let errorNetwork =
            JSON.parse(JSON.stringify(error)).message === "Network Error";
          console.log(
            "Cek Error============cek versi============",
            JSON.parse(JSON.stringify(error)).message
          );
          //   if (errorNetwork) {
          //     this.setState({
          //       alertData: 'jaringan bermasalah',
          //       modalAlert: !this.state.modalAlert,
          //     });
          //   }
        }
      }
    } else {
      const formData = {
        phone: dataUser.phone,
        customer_code: dataUser.customer_code,
      };
      console.log("formData", formData);
      if (formData) {
        try {
          let response = await axios({
            method: "POST",
            url: `${CONFIG.BASE_URL}/api/otp/wa`,
            data: formData,
          });
          console.log("hasil data", response.data);
          const data = response.data;
          if (data != "" && data["success"] == true) {
            if (screenName == "RegisterOld") {
              dispatch(screenAction("WAOTPOLD", "screenName"));
              navigate("/send-otp");
            } else if(screenName == "RegisterDistributor") {
              dispatch(screenAction("WAOTPDISTRIBUTOR", "screenName"));
              navigate("/send-otp");
            } else {
              dispatch(screenAction("WAOTPNEW", "screenName"));
              navigate("/send-otp");
            }
          }
        } catch (error) {
          let error429 =
            JSON.parse(JSON.stringify(error)).message ===
            "Request failed with status code 429";
          let errorNetwork =
            JSON.parse(JSON.stringify(error)).message === "Network Error";
          console.log(
            "Cek Error============cek versi============",
            JSON.parse(JSON.stringify(error)).message
          );
          //   if (errorNetwork) {
          //     this.setState({
          //       alertData: 'jaringan bermasalah',
          //       modalAlert: !this.state.modalAlert,
          //     });
          //   }
        }
      }
    }
  }

  async function sendOTPSMS() {
    if (screenName == "EditTelepon") {
      const formData = {
        phone: phone,
        customer_code: dataUser.user.customer_code,
      };
      console.log("formData", formData);
      if (formData) {
        try {
          let response = await axios({
            method: "POST",
            url: `${CONFIG.BASE_URL}/api/otp/phone-number/sms`,
            data: formData,
          });
          console.log("hasil data", response.data);
          const data = response.data;
          if (data != "" && data["success"] == true) {
            dispatch(screenAction("SMSEDITTELEPON", "screenName"));
            navigate("/user/edit/phone/otp");
          }
        } catch (error) {
          let error429 =
            JSON.parse(JSON.stringify(error)).message ===
            "Request failed with status code 429";
          let errorNetwork =
            JSON.parse(JSON.stringify(error)).message === "Network Error";
          console.log(
            "Cek Error============cek versi============",
            JSON.parse(JSON.stringify(error)).message
          );
          //   if (errorNetwork) {
          //     this.setState({
          //       alertData: 'jaringan bermasalah',
          //       modalAlert: !this.state.modalAlert,
          //     });
          //   }
        }
      }
    } else {
      const formData = {
        phone: dataUser.phone,
        customer_code: dataUser.customer_code,
      };
      console.log("formData", formData);
      if (formData) {
        try {
          let response = await axios({
            method: "POST",
            url: `${CONFIG.BASE_URL}/api/otp`,
            data: formData,
          });
          console.log("hasil data", response.data);
          const data = response.data;
          if (data != "" && data["success"] == true) {
            if (screenName == "RegisterOld") {
              dispatch(screenAction("SMSOTPOLD", "screenName"));
              navigate("/send-otp");
            } else if(screenName == "RegisterDistributor") {
              dispatch(screenAction("SMSOTPDISTRIBUTOR", "screenName"));
              navigate("/send-otp");
            } else {
              dispatch(screenAction("SMSOTPNEW", "screenName"));
              navigate("/send-otp");
            }
          }
        } catch (error) {
          let error429 =
            JSON.parse(JSON.stringify(error)).message ===
            "Request failed with status code 429";
          let errorNetwork =
            JSON.parse(JSON.stringify(error)).message === "Network Error";
          console.log(
            "Cek Error============sendOTPSMS============",
            JSON.parse(JSON.stringify(error)).message
          );
          //   if (errorNetwork) {
          //     this.setState({
          //       alertData: 'jaringan bermasalah',
          //       modalAlert: !this.state.modalAlert,
          //     });
          //   }
        }
      }
    }
  }

  useEffect(() => {
    console.log("cekkkk", JSON.stringify(dataUser));

    return () => {};
  }, []);

  return (
    <div className="chooseVerifContainer">
      <img src={Logo} alt="chooseVerifLogo" className="chooseVerifLogo" />
      <h1 className="chooseVerifJudul">{"Pilih Metode Verifikasi"}</h1>
      {screenName == "EditTelepon" ? (
        <>
          <p className="chooseVerifDescription">
            Silahkan masukkan nomor telepon yang terdaftar
          </p>
          <div className="chooseVerifContainerInput">
            <img
              src={IconPhone}
              alt="registerOldIconUser"
              className="registerOldIconUser"
            />
            <input
              type="number"
              className="registerOldTextInput"
              placeholder="Nomor telepon"
            />
          </div>
        </>
      ) : null}
      <p className="chooseVerifDescription">
        Pilih salah satu metode dibawah ini untuk mendapatkan kode verifikasi
      </p>
      <div className="chooseVerifButton">
        <button className="chooseVerifButtonSMS" onClick={sendOTPSMS}>
          <p className="forgetPasswordTextButtonOK">{"SMS"}</p>
        </button>
        <button className="chooseVerifButtonWA" onClick={sendOTPWA}>
          <p className="forgetPasswordTextButtonOK">{"Whatsapp"}</p>
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseVerification);
