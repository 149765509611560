import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Logo from "../assets/images/Logo.webp";
import IconSearch from "../assets/images/Search.webp";
import IconNotifAll from "../assets/images/NotifAll.webp";
import IconShoppingCart from "../assets/images/Shopping-Cart.webp";
import IconMenu from "../assets/images/Menu.webp";
import "./SearchList.css";
import Navbar from "../components/Navbar";
import Product1 from "../assets/dummy/product1.jpg";
import Product2 from "../assets/dummy/product2.jpg";
import Product3 from "../assets/dummy/product3.jpg";
import Product4 from "../assets/dummy/product4.jpg";
import Product5 from "../assets/dummy/product5.jpg";
import Product6 from "../assets/dummy/product6.jpg";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import CircularProgress from "@mui/material/CircularProgress";
import InfiniteScroll from "react-infinite-scroll-component";
import { screenAction, paramsAction, produkAction } from "../actions";
import { useDispatch } from "react-redux";
import NumberFormat from "react-number-format";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

export default function SearchList({ search, param }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loadingApi, setLoadingApi] = useState(false);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const [
    onEndReachedCalledDuringMomentum,
    setOnEndReachedCalledDuringMomentum,
  ] = useState(false);
  const [listSearch, setListSearch] = useState([]);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  useEffect(() => {
    if (page) {
      getDataSearching();
    } else if (search) {
      getDataSearching();
    }

    return () => {
      setOnEndReachedCalledDuringMomentum(false);
    };
  }, [page, loadingMore, search]);

  //searching data
  const getDataSearching = async (value = search) => {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/products?search=${value}&page=${page}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      setListSearch(page === 1 ? data.data : [...listSearch, ...data.data]);
      setLoadingMore(data.last_page + 1 > page);
      setMaxPage(data.last_page);
      setRefreshing(true);
      setLoadingApi(false);
    } catch (error) {
      setLoadingApi(false);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error===========DataSearching=============",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  };

  //load more data
  async function getMoreData() {
    if (!onEndReachedCalledDuringMomentum && page < maxPage) {
      setPage(page + 1);
      setLoadingMore(page < maxPage);
    }
    setOnEndReachedCalledDuringMomentum(page >= maxPage);
  }

  function handleRefresh() {
    setPage(1);
    setRefreshing(true);
  }

  function handleLoadMore() {
    if (!loadingMore) return null;
    return (
      <div>
        <CircularProgress color="success" />
      </div>
    );
  }

  async function postRecent(item) {
    try {
      let response = await axios.post(
        `${CONFIG.BASE_URL}/api/recent/products`,
        {
          product_id: item.id,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      // let data = response.data.data.data;
      // const dataTotal = response.data.data.total;
      handleRefresh();
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error==========POST RECENT==============",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  return (
    <div className="searchListContainer">
      <InfiniteScroll
        dataLength={listSearch.length}
        next={() => getMoreData()}
        hasMore={true}
        loader={() => handleLoadMore()}
        // refreshFunction={handleRefresh}
        // pullDownToRefresh
        // pullDownToRefreshThreshold={50}
        // pullDownToRefreshContent={handleLoadMore}
      >
        {listSearch.map((item, index) => {
          return (
            <div
              onClick={() => {
                postRecent(item);
                dispatch(produkAction(item, "produkDesc"));
                navigate(
                  "/produk/deskripsi?idProduct=" +
                    item.id +
                    "&" +
                    param +
                    "=" +
                    search
                );
              }}
              key={index}
              className="content"
            >
              <div className="product">
                {item.image ? (
                  <img
                    src={CONFIG.BASE_URL + item.image}
                    alt="transactionContentImage"
                    className="image"
                  />
                ) : item?.product_image[0] ? (
                  <img
                    src={CONFIG.BASE_URL + item?.product_image[0]?.src_image}
                    alt="transactionContentImage"
                    className="image"
                  />
                ) : (
                  <img
                    src={DummyImage}
                    alt="transactionContentImage"
                    className="image"
                  />
                )}
                <div className="desc">
                  <div className="name">{item.name}</div>
                  <NumberFormat
                    value={Math.round(item?.price?.harga_ritel_gt) ?? "0"}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"Rp "}
                    renderText={(value) => (
                      <div className="price">{value || 0}</div>
                    )}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </InfiniteScroll>
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
}
