import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/images/IconLogo.webp";
import IconIdentify from "../assets/images/IdentifyUser.webp";
import "./Identify.css";

export const Identification = (props) => {
  const navigate = useNavigate();
  return (
    <div className="identifyContainer">
      <img src={Logo} alt="identifyLogo" className="identifyLogo" />
      <h1 className="identifyJudul">{"Identifikasi User"}</h1>
      <img src={IconIdentify} alt="identifyLogo" className="identifyIcon" />
      <button
        onClick={() => navigate("/register/old")}
        className="identifyButtonUser"
      >
        <p className="identifyTextButtonUser">{"Daftar Pelanggan Lama"}</p>
      </button>
      <button
        onClick={() => navigate("/register/distributor")}
        className="identifyButtonUser"
      >
        <p className="identifyTextButtonUser">{"Daftar Distributor Baru"}</p>
      </button>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Identification);
