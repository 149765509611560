import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/images/IconLogo.webp";
import IconUser from "../assets/images/User.webp";
import IconToko from "../assets/images/Toko.webp";
import IconPhone from "../assets/images/Phone.webp";
import IconEmail from "../assets/images/Email.webp";
import IconSandi from "../assets/images/Sandi.webp";
import IconReSandi from "../assets/images/ReSandi.webp";
import IconProvinsi from "../assets/images/Provinsi.webp";
import IconKabupaten from "../assets/images/Kabupaten.webp";
import IconKecamatan from "../assets/images/Kecamatan.webp";
import IconKelurahan from "../assets/images/Kelurahan.webp";
import IconKodepos from "../assets/images/Kodepos.webp";
import "./RegisterNew.css";
import axios from "axios";
import CONFIG from "../config/config";
import { useDispatch } from "react-redux";
import { userAction, screenAction } from "../actions";
import CircularProgress from "@mui/material/CircularProgress";
import { getDataToken } from "../config/firebase";
import FormDialog from "../components/Dialog";
import IconEye from "../assets/images/Eye.webp";


export const RegisterNew = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [store, setStore] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirm, setPassword_confirm] = useState("");
  const [address, setAddress] = useState("");
  const [provinsi, setProvinsi] = useState([]);
  const [city, setCity] = useState([]);
  const [kecamatan, setKecamatan] = useState([]);
  const [kelurahan, setKelurahan] = useState([]);
  const [poscode, setPoscode] = useState([]);
  const [provinsi_id, setProvinsi_id] = useState([]);
  const [city_id, setCity_id] = useState([]);
  const [kecamatan_id, setKecamatan_id] = useState([]);
  const [dataprovinsi, setDataprovinsi] = useState("");
  const [datacity, setDatacity] = useState("");
  const [datakecamatan, setDatakecamatan] = useState("");
  const [datakelurahan, setDatakelurahan] = useState("");
  const [dataposcode, setDataposcode] = useState("");
  const [alertPass, setAlertPass] = useState(false);
  const [alertPass2, setAlertPass2] = useState(false);
  const [alertAdress, setAlertAdress] = useState(false);
  const [token, setToken] = useState("");
  const [random_number_1, setRandom_number_1] = useState("");
  const [random_number_2, setRandom_number_2] = useState("");
  const [sum_holder, setSum_holder] = useState("");
  const [shouldShow, setShouldShow] = useState(false);
  const [textInput_text_holder, setTextInput_text_holder] = useState("");
  const [hidePass, setHidePass] = useState(false);
  const [hidePass2, setHidePass2] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [alertTambahan, setAlertTambahan] = useState("");
  const [alertJudul, setAlertJudul] = useState("");

  function generate_captcha() {
    var number_1 = Math.floor(Math.random() * 100) + 1;

    var number_2 = Math.floor(Math.random() * 100) + 1;

    var sum = number_1 + number_2;

    setRandom_number_1(number_1);
    setRandom_number_2(number_2);
    setSum_holder(sum);
  }

  function run_captcha_function() {
    var temp = random_number_1 + random_number_2;

    if (textInput_text_holder == temp) {
      //Write Your Code Here Which you want to execute on RIGHT Captcha Entered.
      setShouldShow(true);
    } else {
      //Write Your Code Here Which you want to execute on WRONG Captcha Entered.
      // this.setState({
      //   alertData: "Captcha Tidak Cocok",
      //   modalAlert: !this.state.modalAlert,
      // });
      alert("Captcha Tidak Cocok");
    }

    // Calling captcha function, So each time new captcha number generates on button clicks.
    generate_captcha();
  }

  useEffect(() => {
    let data;

    async function getToken() {
      data = await getDataToken();
      if (data) {
        console.log("token adalah : ", data);
        setToken(data);
      }
      return data;
    }

    getToken();
    getProvinsi();
    generate_captcha();

    return () => {};
  }, []);

  async function getProvinsi() {
    try {
      let response = await axios({
        method: "GET",
        url: `${CONFIG.BASE_URL}/api/location`,
        params: {
          type: "province",
        },
      });
      console.log("getProvinsi", response.data);
      const data = response.data.data;
      setProvinsi(data);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      console.log(
        "Cek Error============getProvinsi============",
        JSON.parse(JSON.stringify(error)).message
      );
      //   if (errorNetwork) {
      //     this.setState({
      //       alertData: 'jaringan bermasalah',
      //       modalAlert: !this.state.modalAlert,
      //     });
      //   }
    }
  }

  async function pickerProvinsi(e) {
    let dataProvinsi;
    provinsi?.filter((item, index) => {
      if (item.id == e) {
        dataProvinsi = item;
      }
    });
    if (dataProvinsi) {
      try {
        let response = await axios({
          method: "GET",
          url: `${CONFIG.BASE_URL}/api/location`,
          params: {
            type: "city",
            province_id: dataProvinsi.id,
          },
        });
        const data = response.data.data;
        setDataprovinsi(dataProvinsi.name);
        setProvinsi_id(dataProvinsi.id);
        setCity(data);
      } catch (error) {
        let error429 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 429";
        let errorNetwork =
          JSON.parse(JSON.stringify(error)).message === "Network Error";
        console.log(
          "Cek Error============getCity============",
          JSON.parse(JSON.stringify(error)).message
        );
        //   if (errorNetwork) {
        //     this.setState({
        //       alertData: 'jaringan bermasalah',
        //       modalAlert: !this.state.modalAlert,
        //     });
        //   }
      }
    }
  }

  async function pickerCity(e) {
    let dataCity;
    city?.filter((item, index) => {
      if (item.id == e) {
        dataCity = item;
      }
    });
    if (dataCity) {
      try {
        let response = await axios({
          method: "GET",
          url: `${CONFIG.BASE_URL}/api/location`,
          params: {
            type: "district",
            city_id: dataCity.id,
          },
        });
        console.log("getKecamatan", response.data);
        const data = response.data.data;
        setDatacity(dataCity.name);
        setCity_id(dataCity.id);
        setKecamatan(data);
      } catch (error) {
        let error429 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 429";
        let errorNetwork =
          JSON.parse(JSON.stringify(error)).message === "Network Error";
        console.log(
          "Cek Error============getKecamatan============",
          JSON.parse(JSON.stringify(error)).message
        );
        //   if (errorNetwork) {
        //     this.setState({
        //       alertData: 'jaringan bermasalah',
        //       modalAlert: !this.state.modalAlert,
        //     });
        //   }
      }
    }
  }

  async function pickerKecamatan(e) {
    let dataKecamatan;
    kecamatan?.filter((item, index) => {
      if (item.id == e) {
        dataKecamatan = item;
      }
    });
    if (dataKecamatan) {
      try {
        let response = await axios({
          method: "GET",
          url: `${CONFIG.BASE_URL}/api/location`,
          params: {
            type: "subdistrict",
            district_id: dataKecamatan.id,
          },
        });
        console.log("getKelurahan", response.data);
        const data = response.data.data;
        setDatakecamatan(dataKecamatan.name);
        setKecamatan_id(dataKecamatan.id);
        setKelurahan(data);
      } catch (error) {
        let error429 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 429";
        let errorNetwork =
          JSON.parse(JSON.stringify(error)).message === "Network Error";
        console.log(
          "Cek Error============getKelurahan============",
          JSON.parse(JSON.stringify(error)).message
        );
        //   if (errorNetwork) {
        //     this.setState({
        //       alertData: 'jaringan bermasalah',
        //       modalAlert: !this.state.modalAlert,
        //     });
        //   }
      }
    }
  }

  async function pickerKelurahan(e) {
    console.log("cekkk", e);
    console.log("cekkk", provinsi_id, city_id, kecamatan_id);
    let dataKelurahan;
    kelurahan?.filter((item, index) => {
      if (item.id == e) {
        dataKelurahan = item;
      }
    });
    console.log("dataKelurahan", dataKelurahan);
    if (dataKelurahan) {
      try {
        let response = await axios({
          method: "GET",
          url: `${CONFIG.BASE_URL}/api/location`,
          params: {
            type: "postal_code",
            province_id: provinsi_id,
            city_id: city_id,
            district_id: kecamatan_id,
          },
        });
        console.log("getPoscode", response.data);
        const data = response.data.data;
        const pos = [];
        data.forEach((obj) => {
          if (!pos.some((o) => o.postcode === obj.postcode)) {
            pos.push({ ...obj });
          }
        });
        setDatakelurahan(dataKelurahan.name);
        setPoscode(pos);
      } catch (error) {
        let error429 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 429";
        let errorNetwork =
          JSON.parse(JSON.stringify(error)).message === "Network Error";
        console.log(
          "Cek Error============getPoscode============",
          JSON.parse(JSON.stringify(error)).message
        );
        //   if (errorNetwork) {
        //     this.setState({
        //       alertData: 'jaringan bermasalah',
        //       modalAlert: !this.state.modalAlert,
        //     });
        //   }
      }
    }
  }

  async function pickerPos(e) {
    console.log("cekkk", e);
    if (e) {
      setDataposcode(e);
    }
  }

  function handleRegister() {
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!name.trim()) {
      setAlertData("Pastikan nama tidak ada yang kosong");
      setAlertJudul("Pemberitahuan");
      setOpen(true);
      return;
    } else if (!store.trim()) {
      setAlertData("Pastikan nama toko tidak ada yang kosong");
      setAlertJudul("Pemberitahuan");
      setOpen(true);
      return;
    } else if (!phone.trim()) {
      setAlertData("Pastikan nomor telepon tidak ada yang kosong");
      setAlertJudul("Pemberitahuan");
      setOpen(true);
      return;
    } else if (!password.trim()) {
      setAlertData("Pastikan password tidak ada yang kosong");
      setAlertJudul("Pemberitahuan");
      setOpen(true);
      return;
    } else if (!password_confirm.trim()) {
      setAlertData("Pastikan konfirm password tidak ada yang kosong");
      setAlertJudul("Pemberitahuan");
      setOpen(true);
      return;
    } else if (alertPass == true) {
      setAlertData("Pastikan password diisi dengan benar");
      setAlertJudul("Pemberitahuan");
      setOpen(true);
      return;
    } else if (alertPass2 == true) {
      setAlertData("Pastikan password konfirm diisi dengan benar");
      setAlertJudul("Pemberitahuan");
      setOpen(true);
      return;
    } else if (!address.trim()) {
      setAlertData("Pastikan alamat tidak ada yang kosong");
      setAlertJudul("Pemberitahuan");
      setOpen(true);
      return;
    } else if (alertAdress == true) {
      setAlertData("Alamat harap diisi dengan lengkap");
      setAlertJudul("Pemberitahuan");
      setOpen(true);
      alert("Alamat harap diisi dengan lengkap");
      return;
    } else if (dataposcode == "") {
      setAlertData("Pastikan diisi kodeposnya");
      setAlertJudul("Pemberitahuan");
      setOpen(true);
      return;
    } else {
      const formData = {
        name: name,
        shop_name: store,
        phone: phone,
        email: email,
        password: password,
        password_confirmation: password_confirm,
        address: address,
        province: dataprovinsi,
        city: datacity,
        district: datakecamatan,
        subdistrict: datakelurahan,
        postal_code: dataposcode,
        fcm_token: token,
      };
      dispatch(userAction(formData, "dataUser"));
      dispatch(screenAction("RegisterNew", "screenName"));
      navigate("/choose-verification");
    }
  }

  const handleClose = async (e) => {
    setOpen(false);
  };

  return (
    <div className="registerNewContainer">
      <FormDialog
        alertData={alertData}
        alertJudul={alertJudul}
        handleClose={handleClose}
        open={open}
      />
      <img src={Logo} alt="registerNewLogo" className="registerNewLogo" />
      <h1 className="registerNewJudul">{"Daftar"}</h1>
      <div className="registerNewContainerInput">
        <img
          src={IconUser}
          alt="registerNewIconUser"
          className="registerNewIconUser"
        />
        <input
          onChange={(e) => setName(e.target.value)}
          className="registerNewTextInput"
          placeholder="Nama"
        />
      </div>
      <div className="registerNewContainerInput">
        <img
          src={IconToko}
          alt="registerNewIconUser"
          className="registerNewIconUser"
        />
        <input
          onChange={(e) => setStore(e.target.value)}
          className="registerNewTextInput"
          placeholder="Nama Toko"
        />
      </div>
      <div className="registerNewContainerInput">
        <img
          src={IconPhone}
          alt="registerNewIconUser"
          className="registerNewIconUser"
        />
        <input
          type="number"
          onChange={(e) => setPhone(e.target.value)}
          className="registerNewTextInput"
          placeholder="Nomor Telepon"
        />
      </div>
      <div className="registerNewContainerInput">
        <img
          src={IconEmail}
          alt="registerNewIconUser"
          className="registerNewIconUser"
        />
        <input
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          className="registerNewTextInput"
          placeholder="Email (Tidak Wajib Diisi)"
        />
      </div>
      <div className="registerNewContainerInput">
        <img
          src={IconSandi}
          alt="registerNewIconUser"
          className="registerNewIconUser"
        />
        <input
          type={hidePass ? "text" : "password"}
          onChange={(e) => {
            setPassword(e.target.value);
            if (password.length < 7) {
              setAlertPass(true);
            } else {
              setAlertPass(false);
            }
          }}
          className="registerNewTextInput"
          placeholder="Kata Sandi"
        />
        <img
          onClick={() => {
            setHidePass((previous) => !previous);
          }}
          src={IconEye}
          className="registerEye"
        />
      </div>
      {alertPass && <p className="textAlert">Password harus 8 character</p>}
      <div className="registerNewContainerInput">
        <img
          src={IconReSandi}
          alt="registerNewIconUser"
          className="registerNewIconUser"
        />
        <input
          type={hidePass2 ? "text" : "password"}
          onChange={(e) => {
            setPassword_confirm(e.target.value);
            if (e.target.value != password) {
              setAlertPass2(true);
            } else {
              setAlertPass2(false);
            }
          }}
          className="registerNewTextInput"
          placeholder="Konfirmasi Kata Sandi"
        />
        <img
          onClick={() => {
            setHidePass2((previous) => !previous);
          }}
          src={IconEye}
          className="registerEye"
        />
      </div>
      {alertPass2 && (
        <p className="textAlert">Password konfirmasi tidak sama</p>
      )}
      <div className="registerNewContainerInput">
        <img
          src={IconProvinsi}
          alt="registerNewIconUser"
          className="registerNewIconUser"
        />
        <input
          onChange={(e) => {
            setAddress(e.target.value);
            if (address.length < 10) {
              setAlertAdress(true);
            } else {
              setAlertAdress(false);
            }
          }}
          className="registerNewTextInput"
          placeholder="Alamat Detail"
        />
      </div>
      {alertAdress && <p className="textAlert">Alamat minimal 8 karakter</p>}
      <div className="registerNewContainerInput">
        <img
          src={IconProvinsi}
          alt="registerNewIconUser"
          className="registerNewIconUser"
        />
        <select
          onChange={(e) => pickerProvinsi(e.target.value)}
          placeholder="tes"
          className="registerNewSelect"
        >
          <option value={provinsi}>Pilih Provinsi</option>
          {provinsi &&
            provinsi.map((province) => {
              return (
                <option
                  key={province.id}
                  label={province.name}
                  value={province.id}
                />
              );
            })}
        </select>
      </div>
      <div className="registerNewContainerInput">
        <img
          src={IconKabupaten}
          alt="registerNewIconUser"
          className="registerNewIconUser"
        />
        <select
          onChange={(e) => pickerCity(e.target.value)}
          placeholder="tes"
          className="registerNewSelect"
        >
          {console.log("datacity", datacity)}
          <option value={city}>Pilih Kabupaten/Kota</option>
          {city &&
            city.map((city) => {
              return <option key={city.id} label={city.name} value={city.id} />;
            })}
        </select>
      </div>
      <div className="registerNewContainerInput">
        <img
          src={IconKecamatan}
          alt="registerNewIconUser"
          className="registerNewIconUser"
        />
        <select
          onChange={(e) => pickerKecamatan(e.target.value)}
          placeholder="tes"
          className="registerNewSelect"
        >
          {console.log("datakecamatan", datakecamatan)}
          <option value={kecamatan}>Pilih Kecamatan</option>
          {kecamatan &&
            kecamatan.map((kecamatan) => {
              return (
                <option
                  key={kecamatan.id}
                  label={kecamatan.name}
                  value={kecamatan.id}
                />
              );
            })}
        </select>
      </div>
      <div className="registerNewContainerInput">
        <img
          src={IconKelurahan}
          alt="registerNewIconUser"
          className="registerNewIconUser"
        />
        <select
          onChange={(e) => pickerKelurahan(e.target.value)}
          placeholder="tes"
          className="registerNewSelect"
        >
          {console.log("datakelurahan", datakelurahan)}
          <option value={kelurahan}>Pilih Kelurahan</option>
          {kelurahan &&
            kelurahan.map((kelurahan) => {
              return (
                <option
                  key={kelurahan.id}
                  label={kelurahan.name}
                  value={kelurahan.id}
                />
              );
            })}
        </select>
      </div>
      <div className="registerNewContainerInput">
        <img
          src={IconKodepos}
          alt="registerNewIconUser"
          className="registerNewIconUser"
        />
        <select
          onChange={(e) => pickerPos(e.target.value)}
          placeholder="tes"
          className="registerNewSelect"
        >
          {console.log("dataposcode", dataposcode)}
          <option value={poscode}>Pilih Kodepos</option>
          {poscode &&
            poscode.map((poscode) => {
              return (
                <option
                  key={poscode.id}
                  label={poscode.postcode}
                  value={poscode.postcode}
                />
              );
            })}
        </select>
      </div>
      {shouldShow ? (
        <button onClick={handleRegister} className="registerNewButtonOk">
          <p className="registerNewTextButtonOK">{"OK"}</p>
        </button>
      ) : (
        <div className="containerCaptcha">
          <div className="captcha">
            <p className="captchaText">
              {random_number_1}
              {" + "}
              {random_number_2}
              {" = "}
            </p>
            <input
              type="number"
              className="captchaInput"
              onChange={(e) => setTextInput_text_holder(e.target.value)}
              placeholder={"Hasil"}
            ></input>
            <button className="buttonReload" onClick={generate_captcha}>
              <img
                className="captchaImage"
                src="https://reactnativecode.com/wp-content/uploads/2019/08/reload_image.jpg"
              />
            </button>
          </div>
          <button onClick={run_captcha_function} className="buttonCheckCaptcha">
            <p className="captchaTextBtn">Periksa Hasil Capctha</p>
          </button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterNew);
