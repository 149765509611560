import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import IconSearch from "../assets/images/Search.webp";
import IconTrash from "../assets/images/Trash.webp";
import Product1 from "../assets/dummy/product1.jpg";
import Product2 from "../assets/dummy/product2.jpg";
import "./Wishlist.css";
import { useSelector } from "react-redux";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import NumberFormat from "react-number-format";
import Dialog from "../components/Dialog";
import error404 from "../assets/images/404.webp";
import { produkAction, screenAction, orderAction } from "../actions";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Header from "../components/HeaderDistributor";

export const Wishlist = (props) => {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const { produkDesc } = useSelector((state) => state.ProdukReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [shoppingCartItems, setShoppingCartItems] = useState({
    product_id: "",
    brand_id: "",
    satuan_online: "",
    konversi_sedang_ke_kecil: "",
    qty_konversi: "",
    qty: 1,
    notes: "",
    price_apps: "",
  });
  const [wishlistsItems, setWishlistsItems] = useState([]);
  const [search, setSearch] = useState("");
  const [qty, setQty] = useState(0);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [alertTambahan, setAlertTambahan] = useState("");
  const [alertJudul, setAlertJudul] = useState("");
  const [loadingApi, setLoadingApi] = useState(true);
  const [dataDelete, setDataDelete] = useState(null);
  const [
    onEndReachedCalledDuringMomentum,
    setOnEndReachedCalledDuringMomentum,
  ] = useState(false);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  function NoData() {
    return (
      <div className="NoData">
        <img style={{ height: "50%", width: "100%" }} src={error404} />
        <p
          style={{
            fontSize: "14pt",
            fontFamily: "Lato-Bold",
            textAlign: "center",
            marginTop: "-20%",
          }}
        >
          {"Wah, daftar keinginan mu kosong"}
        </p>
        <p
          style={{
            fontSize: "12pt",
            fontFamily: "Lato-Bold",
            width: "75%",
            textAlign: "center",
            marginTop: "2px",
          }}
        >
          {"Simpan dan favoritkan barang yang kamu inginkan"}
        </p>
        <button
          onClick={() => {
            dispatch(screenAction("produk", "screenName"));
            navigate("/produk");
          }}
          style={{
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#529F45",
            width: "65%",
            height: "8%",
            borderRadius: "5px",
            marginTop: "5px",
            color: "#FFFFFF",
            fontSize: "10pt",
            fontFamily: "Lato-Bold",
          }}
        >
          {"Mulai Belanja"}
        </button>
      </div>
    );
  }

  useEffect(() => {
    getWishlist();

    return () => {};
  }, []);

  useEffect(() => {
    if (page) {
      getWishlist();
    } else if (search) {
      getWishlist();
    }

    return () => {
      setOnEndReachedCalledDuringMomentum(false);
    };
  }, [page, loadingMore, search]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async (e) => {
    setOpen(false);
    if (
      e == "ok" &&
      dataDelete != null &&
      alertData == "Apakah yakin ingin menghapus item ini?"
    ) {
      try {
        let response = await axios.delete(
          `${CONFIG.BASE_URL}/api/wishlist/${dataDelete.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        console.log(response.data);
        let data = response.data.success;
        if (data == true) {
          setLoadingApi(true);
          setPage(1);
          setLoadingMore(true);
        } else {
          console.log("Gagal menghapus data===>", data);
        }
      } catch (error) {
        let error429 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 429";
        let errorNetwork =
          JSON.parse(JSON.stringify(error)).message === "Network Error";
        let error400 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 400";
        console.log(
          "Cek Error========================",
          JSON.parse(JSON.stringify(error)).message
        );
        if (error429) {
          setAlertServerSibuk(true);
        } else if (errorNetwork) {
          setAlertInet(true);
        } else if (error400) {
          localStorage.removeItem("token");
          navigate("/home");
        }
      }
    }
  };

  async function getWishlist() {
    try {
      let response;
      if (search) {
        response = await axios.get(
          `${CONFIG.BASE_URL}/api/wishlists?search=${search}&page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      } else {
        response = await axios.get(
          `${CONFIG.BASE_URL}/api/wishlists?page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      }
      const data = response.data.data;
      setLoadingApi(false);
      setWishlistsItems(
        page == 1 ? data.data : [...wishlistsItems, ...data.data]
      );
      setRefreshing(false);
      setLoadingMore(data.last_page > page);
      setMaxPage(data.last_page);
    } catch (error) {
      setLoadingApi(false);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  //load more data
  async function getMoreData() {
    console.log("cekk", !onEndReachedCalledDuringMomentum);
    console.log("cekk", page);
    console.log("cekk", maxPage);
    if (!onEndReachedCalledDuringMomentum && page < maxPage) {
      console.log("masuk load");
      setPage(page + 1);
      setLoadingMore(page < maxPage);
      console.log("ini ketiga", page, loadingMore);
    }
    setOnEndReachedCalledDuringMomentum(page >= maxPage);
  }

  function handleRefresh() {
    setPage(1);
    setRefreshing(true);
  }

  function handleLoadMore() {
    console.log("cekkkkk saiki", !loadingMore);
    if (!loadingMore) return null;
    return (
      <div>
        <CircularProgress color="success" />
      </div>
    );
  }

  async function postShoppingCart(index) {
    try {
      let response;
      console.log(
        "cek whistlist===============",
        JSON.stringify({
          product_id: wishlistsItems[index].product_id,
          brand_id: wishlistsItems[index].product.brand_id,
          qty: shoppingCartItems.qty,
          notes: shoppingCartItems.notes,
          price_apps: wishlistsItems[index].price_apps ?? "0",
          satuan_online: wishlistsItems[index].product.satuan_online,
          konversi_sedang_ke_kecil:
            wishlistsItems[index].product.konversi_sedang_ke_kecil,
          qty_konversi:
            shoppingCartItems.qty *
            wishlistsItems[index].product.konversi_sedang_ke_kecil,
          half: wishlistsItems[index].half,
        })
      );
      if (wishlistsItems[index].half) {
        console.log("masuk half");
        response = await axios.post(
          `${CONFIG.BASE_URL}/api/shopping-cart`,
          {
            product_id: wishlistsItems[index].product_id,
            brand_id: wishlistsItems[index].product.brand_id,
            qty: shoppingCartItems.qty,
            notes: shoppingCartItems.notes,
            price_apps: wishlistsItems[index].price_apps ?? "0",
            satuan_online: wishlistsItems[index].product.satuan_online,
            konversi_sedang_ke_kecil:
              wishlistsItems[index].product.konversi_sedang_ke_kecil,
            qty_konversi:
              shoppingCartItems.qty *
              wishlistsItems[index].product.konversi_sedang_ke_kecil,
            half: wishlistsItems[index].half,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      } else {
        response = await axios.post(
          `${CONFIG.BASE_URL}/api/shopping-cart`,
          {
            product_id: wishlistsItems[index].product_id,
            brand_id: wishlistsItems[index].product.brand_id,
            qty: shoppingCartItems.qty,
            notes: shoppingCartItems.notes,
            price_apps: wishlistsItems[index].price_apps ?? "0",
            satuan_online: wishlistsItems[index].product.satuan_online,
            konversi_sedang_ke_kecil:
              wishlistsItems[index].product.konversi_sedang_ke_kecil,
            qty_konversi:
              shoppingCartItems.qty *
              wishlistsItems[index].product.konversi_sedang_ke_kecil,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      }
      const data = response.data;
      console.log("data", data);
      if (data !== "" ) {
        navigate("/keranjang");
      } else {
        console.log("Gagal memasukkan keranjang===>", data);
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  console.log("susss "+ JSON.stringify(wishlistsItems));
  return (
    <div className="wishlistContainer">
      <Dialog
        alertData={alertData}
        alertJudul={alertJudul}
        handleClose={handleClose}
        open={open}
      />

      <div className="wishlistHeader">
        <Header titleHeader="Daftar keinginan" />
        <div className="wishlistSearchContainer">
          <input
            onChange={(e) => setSearch(e.target.value)}
            className="wishlistTextInput"
            placeholder="Ketik kata kunci yang ingin anda cari"
            type="text"
          />
          <img
            src={IconSearch}
            alt="headerSearchLogo"
            className="wishlistSearchLogo"
          />
        </div>
      </div>
      <div className="wishlist">
        {wishlistsItems.length > 0 && !loadingApi ? (
          <InfiniteScroll
            dataLength={wishlistsItems.length}
            next={() => getMoreData()}
            hasMore={true}
            loader={() => handleLoadMore()}
            // refreshFunction={handleRefresh}
            // pullDownToRefresh
            // pullDownToRefreshThreshold={50}
            // pullDownToRefreshContent={handleLoadMore}
          >
            {wishlistsItems.map((item, index) => {
              return (
                <div key={index} className="wishlistMain">
                  <div className="wishlistContent">
                    {item.product.image ? (
                      <img
                        src={CONFIG.BASE_URL + item.product.image}
                        alt="wishlistContentImage"
                        className="wishlistContentImage"
                      />
                    ) : (
                      <img
                        src={DummyImage}
                        alt="wishlistContentImage"
                        className="wishlistContentImage"
                      />
                    )}
                    <div className="wishlistContent2">
                      <div className="wishlistContentProduct">
                        {item.product.name}
                      </div>
                      <div className="halfView">
                        {item.half ? (
                          <NumberFormat
                            value={Math.round(item.price_apps / 2) ?? "0"}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <div className="wishlistContentPrice">
                                {value || 0}
                              </div>
                            )}
                          />
                        ) : (
                          <NumberFormat
                            value={Math.round(item.price_apps) ?? "0"}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <div className="wishlistContentPrice">
                                {value || 0}
                              </div>
                            )}
                          />
                        )}
                        {item.half ? (
                          <div className="isiHalf">
                            {"  ( "}
                            {item.product.konversi_sedang_ke_kecil / 2 +
                              " " +
                              item.product.kecil.charAt(0) +
                              item.product.kecil.slice(1).toLowerCase()}
                            {" )"}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="wishlistFooterButton">
                    <button
                      onClick={() => {
                        setAlertData("Apakah yakin ingin menghapus item ini?");
                        setAlertJudul("Pemberitahuan");
                        handleClickOpen();
                        setDataDelete(item);
                      }}
                      className="wishlistButtonHapus"
                    >
                      <img
                        src={IconTrash}
                        alt="userLogo"
                        className="wishlistTrashIcon"
                      />
                    </button>
                    <button
                      onClick={() => postShoppingCart(index)}
                      className="wishlistButtonOK"
                    >
                      <p className="wishlistTextButtonOK">
                        {"Tambah ke Keranjang"}
                      </p>
                    </button>
                  </div>
                </div>
              );
            })}
          </InfiniteScroll>
        ) : wishlistsItems.length == 0 && !loadingApi ? (
          <NoData />
        ) : null}
      </div>
      <Loading loadingApi={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Wishlist);
