import { SET_ORDER } from "../../actions";
const initialState = {
  paymentOrder: [],
  orderDetail: [],
  orderComplaint: [],
  ratingOrder: [],
  detailComplaint: [],
};

const order = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDER:
      return {
        ...state,
        [action.inputType]: action.inputValue,
      };
    default:
      return state;
  }
};

export default order;
