import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import IconChat from "../assets/images/ChatActive.webp";
import IconSubscribe from "../assets/images/Subscribe.webp";
import IconCart from "../assets/images/Shopping-Cart.webp";
import IconComplaint from "../assets/images/Komplain.webp";
import IconBroadcast from "../assets/images/Broadcast.webp";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import NavbarDistributor from "../components/NavbarDistributor";
import "./Notification.css";
import NumberFormat from "react-number-format";
import ReactStars from "react-rating-stars-component";
import Product1 from "../assets/dummy/product1.jpg";
import Product2 from "../assets/dummy/product2.jpg";
import Product3 from "../assets/dummy/product3.jpg";
import Product4 from "../assets/dummy/product4.jpg";
import Product5 from "../assets/dummy/product5.jpg";
import Product6 from "../assets/dummy/product6.jpg";
import axios from "axios";
import CONFIG from "../config/config";
import { useSelector } from "react-redux";
import DummyImage from "../assets/images/Logo.webp";
import { useDispatch } from "react-redux";
import { screenAction, produkAction } from "../actions";
import ModalShopping from "../components/DialogShopping";
import SearchComponent from "../pages/SearchList";
import { CircularProgress } from "@mui/material";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

export const Notification = (props) => {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const { screenName } = useSelector((state) => state.ScreenReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [recomenProduct, setRecomenProduct] = useState([]);
  const [notifOrders, setNotifOrders] = useState(0);
  const [notifMessages, setNotifMessages] = useState(0);
  const [notifComplaints, setNotifComplaints] = useState(0);
  const [notifBroadcast, setNotifBroadcast] = useState(0);
  const [notifSubscribe, setNotifSubscribe] = useState(0);
  const [countNotifCart, setCountNotifCart] = useState(0);
  const [search, setSearch] = useState("");
  const [openShopping, setOpenShopping] = useState(false);
  const [loadAll, setLoadAll] = useState(false);
  const [loadingApi, setLoadingApi] = useState(true);
  const [shoppingCartItems, setShoppingCartItems] = useState({
    product_id: "",
    brand_id: "",
    satuan_online: "",
    konversi_sedang_ke_kecil: "",
    qty_konversi: "",
    qty: 1,
    notes: "",
    price_apps: "",
  });
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  useEffect(() => {
    getNotifAll();
    getProdukRecomen();

    return () => {};
  }, []);

  useEffect(() => {
    if (loadAll) {
      getNotifAll();
      getProdukRecomen();
    }

    return () => {};
  }, [loadAll]);

  async function getNotifAll() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/notification-all`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      // console.log('DATA TEST', data.total_chat);
      setNotifOrders(data.total_order);
      setNotifMessages(data.total_chat);
      setNotifComplaints(data.total_complaint);
      setNotifSubscribe(data.total_subscribe);
      setNotifBroadcast(data.total_broadcast);
      setCountNotifCart(data.total_cart);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========NOTIF================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  async function getProdukRecomen() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/recomen/products`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data.data;
      // const dataTotal = response.data.data.total;
      setRecomenProduct(data);
      setLoadingApi(false);
    } catch (error) {
      setLoadingApi(false);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  async function postRecent(item) {
    console.log("masuk");
    console.log("postRecent", JSON.stringify(item));
    try {
      let response = await axios.post(
        `${CONFIG.BASE_URL}/api/recent/products`,
        {
          product_id: item.id,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      // let data = response.data.data.data;
      // const dataTotal = response.data.data.total;
      console.log("postRecent", response.data.data);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error==========POST RECENT==============",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  async function postShoppingCart(item) {
    const brand_id_1 = ["001"];
    const brand_id_2 = [
      "002",
      "003",
      "004",
      "007",
      "008",
      "009",
      "010",
      "011",
      "012",
      "013",
      "014",
    ];
    const brand_id_3 = ["005"];

    // console.log('price=====>',item)
    let price = item.price.harga_ritel_gt ?? "0";
    if (brand_id_1.includes(item.brand_id)) {
      if (item.status_promosi_coret == 1) {
        // console.log('if 1');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          default:
            break;
        }
      } else if (
        (item.status_promosi_coret !== "0" ||
          item.status_promosi_coret !== null) &&
        item.status_herbana !== "1"
      ) {
        // console.log('if 2');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_ritel_gt;
            break;
          default:
            break;
        }
      } else if (item.status_herbana === "1") {
        // console.log('if 3');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_grosir_mt;
            break;
          default:
            break;
        }
      } else {
        // console.log('if 4');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_ritel_gt;
            break;
          default:
            break;
        }
      }
    } else if (brand_id_2.includes(item.brand_id)) {
      if (item.status_promosi_coret == 1) {
        // console.log('if 5');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          default:
            break;
        }
      } else if (
        item.status_promosi_coret !== "0" ||
        item.status_promosi_coret !== null
      ) {
        // console.log('if 6');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_grosir_mt;
            break;
          default:
            break;
        }
      } else {
        // console.log('if 7');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_grosir_mt;
            break;
          default:
            break;
        }
      }
    } else if (brand_id_3.includes(item.brand_id)) {
      if (item.status_promosi_coret == 1) {
        // console.log('if 8');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          default:
            break;
        }
      } else if (
        item.status_promosi_coret !== "0" ||
        item.status_promosi_coret !== null
      ) {
        // console.log('if 9');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_ritel_gt;
            break;
          default:
            break;
        }
      } else {
        // console.log('if 10');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_ritel_gt;
            break;
          default:
            break;
        }
      }
    }

    console.log("price_apps", price);

    try {
      let response = await axios.post(
        `${CONFIG.BASE_URL}/api/shopping-cart`,
        {
          product_id: item.id,
          brand_id: item.brand_id,
          satuan_online: item.satuan_online,
          konversi_sedang_ke_kecil: item.konversi_sedang_ke_kecil,
          qty_konversi: shoppingCartItems.qty * item.konversi_sedang_ke_kecil,
          qty: shoppingCartItems.qty,
          notes: shoppingCartItems.notes,
          price_apps: price ?? "0",
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data;
      if (data !== "" && data["success"] == true) {
        setOpenShopping(true);
        setLoadAll(true);
      } else {
        console.log("Gagal memasukkan keranjang===>", data);
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  const handleClickOpenShopping = () => {
    setOpenShopping(true);
  };

  const handleCloseShopping = (e) => {
    setOpenShopping(false);
    if (e == "ok") {
      navigate("/keranjang");
    }
  };

  const simpanSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div className="notificationContainer">
      <ModalShopping
        handleCloseShopping={handleCloseShopping}
        openShopping={openShopping}
      />
      <Header
        notifMessages={notifMessages}
        notifSubscribe={notifSubscribe}
        notifOrders={notifOrders}
        notifComplaints={notifComplaints}
        notifBroadcast={notifBroadcast}
        countNotifCart={countNotifCart}
        simpanSearch={simpanSearch}
        titleHeader="Notifikasi"
      />
      {search.length > 0 && <SearchComponent search={search} />}
      {search.length == 0 && (
        <div className="notificationMain">
          <a
            onClick={() => {
              dispatch(screenAction("chat", "screenName"));
              navigate("/listchats");
            }}
            className="subNotif"
          >
            <img src={IconChat} alt="menuIcon" className="notifIcon" />
            <p className="notifText">Chat</p>
            {notifMessages > 0 ? (
              <div className="dot">
                <div className="dotNumber">{notifMessages}</div>
              </div>
            ) : null}
          </a>
          <hr></hr>
          <a
            onClick={() => {
              dispatch(screenAction("notifBroadcast", "screenName"));
              navigate("/notification/belanja");
            }}
            className="subNotif"
          >
            <img src={IconSubscribe} alt="menuIcon" className="notifIcon" />
            <p className="notifText">Langganan</p>
            {notifSubscribe > 0 ? (
              <div className="dot">
                <div className="dotNumber">{notifSubscribe}</div>
              </div>
            ) : null}
          </a>
          <hr></hr>
          <a
            onClick={() => {
              dispatch(screenAction("notifOrders", "screenName"));
              navigate("/notification/belanja");
            }}
            className="subNotif"
          >
            <img src={IconCart} alt="menuIcon" className="notifIcon" />
            <p className="notifText">Belanja</p>
            {notifOrders > 0 ? (
              <div className="dot">
                <div className="dotNumber">{notifOrders}</div>
              </div>
            ) : null}
          </a>
          <hr></hr>
          <a
            onClick={() => {
              dispatch(screenAction("notifKomplain", "screenName"));
              navigate("/notification/belanja");
            }}
            className="subNotif"
          >
            <img src={IconComplaint} alt="menuIcon" className="notifIcon" />
            <p className="notifText">Komplain</p>
            {notifComplaints > 0 ? (
              <div className="dot">
                <div className="dotNumber">{notifComplaints}</div>
              </div>
            ) : null}
          </a>
          <hr></hr>
          <a
            onClick={() => {
              dispatch(screenAction("notifBroadcast", "screenName"));
              navigate("/notification/belanja");
            }}
            className="subNotif"
          >
            <img src={IconBroadcast} alt="menuIcon" className="notifIcon" />
            <p className="notifText">Info Semut Gajah</p>
            {notifBroadcast > 0 ? (
              <div className="dot">
                <div className="dotNumber">{notifBroadcast}</div>
              </div>
            ) : null}
          </a>
          <hr></hr>
          {loadingApi ? (
            <div className="notifProduct">
              <CircularProgress className="posisiLoading" color="success" />
            </div>
          ) : (
            <div className="notifProduct">
              <p className="notifProductTitle">Produk Rekomendasi</p>
              <div className="notifProductContainer">
                {recomenProduct.map((item, index) => {
                  return (
                    <div key={index} className="homeProductItem">
                      {item.promo_sku.length > 0 && (
                        <div
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemPromo"
                        >
                          <p className="homeProductItemPromoText">Promo</p>
                        </div>
                      )}
                      {item.image ? (
                        <img
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          src={CONFIG.BASE_URL + item.image}
                          alt="homeProductImage"
                          className="homeProductImage"
                        />
                      ) : (
                        <img
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          src={DummyImage}
                          alt="homeProductImage"
                          className="homeProductImage"
                        />
                      )}
                      <p
                        onClick={() => {
                          postRecent(item);
                          dispatch(produkAction(item, "produkDesc"));
                          navigate("/produk/deskripsi?idProduct=" + item.id);
                        }}
                        className="homeProductItemTitle"
                      >
                        {item.name}
                      </p>
                      {item.status_promosi_coret == 1 ? (
                        <NumberFormat
                          value={
                            Math.round(
                              item?.price?.harga_promosi_coret_ritel_gt ||
                                item?.price?.harga_promosi_coret_grosir_mt ||
                                item?.price?.harga_promosi_coret_semi_grosir
                            ) ?? "0"
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"Rp "}
                          renderText={(value) => (
                            <p
                              onClick={() => {
                                postRecent(item);
                                dispatch(produkAction(item, "produkDesc"));
                                navigate("/produk/deskripsi?idProduct=" + item.id);
                              }}
                              className="homeProductItemPrice"
                            >
                              {value || 0}
                            </p>
                          )}
                        />
                      ) : (
                        <NumberFormat
                          value={Math.round(item?.price?.harga_ritel_gt) ?? "0"}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"Rp "}
                          renderText={(value) => (
                            <p
                              onClick={() => {
                                postRecent(item);
                                dispatch(produkAction(item, "produkDesc"));
                                navigate("/produk/deskripsi?idProduct=" + item.id);
                              }}
                              className="homeProductItemPrice"
                            >
                              {value || 0}
                            </p>
                          )}
                        />
                      )}
                      <div
                        onClick={() => {
                          postRecent(item);
                          dispatch(produkAction(item, "produkDesc"));
                          navigate("/produk/deskripsi?idProduct=" + item.id);
                        }}
                        className="homeProductItemView"
                      >
                        <div className="homeProductItemRating">
                          <ReactStars
                            count={1}
                            size={24}
                            edit={false}
                            color={"#ffd700"}
                          />
                          <p className="homeProductItemRatingValue">
                            {item.review
                              ? item.review[0]
                                ? item.review[0].avg_rating
                                : "0"
                              : "0"}
                          </p>
                        </div>
                        {item.cart && (
                          <div className="homeProductItemRating">
                            <img className="notifListIcon" src={IconCart} />
                            <p className="homeProductItemRatingValue">
                              {item.cart.qty}
                            </p>
                          </div>
                        )}
                      </div>
                      {item.cart ? (
                        <button
                          onClick={() => postShoppingCart(item)}
                          className="homeProductItemButton"
                        >
                          <p className="homeProductItemButtonBeli">Tambah</p>
                        </button>
                      ) : (
                        <button
                          onClick={() => postShoppingCart(item)}
                          className="homeProductItemButton"
                        >
                          <p className="homeProductItemButtonBeli">Beli</p>
                        </button>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}
      {/* {dataUser.user.account_type == 4 ? (
        <Navbar screenName={"Home"} />
      ) : (
        <NavbarDistributor screenName={"Home"} />
      )} */}
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

export default Notification;
