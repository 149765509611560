import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Slider from "../components/Slider";
import Images from "../images";
import IconHome from "../assets/images/Home.webp";
import IconHomeActive from "../assets/images/HomeActive.webp";
import IconProduk from "../assets/images/Produk.webp";
import IconProdukActive from "../assets/images/ProdukActive.webp";
import IconKeranjang from "../assets/images/Keranjang.webp";
import IconKeranjangActive from "../assets/images/KeranjangActive.webp";
import IconPembayaran from "../assets/images/Pembayaran.webp";
import IconPembayaranActive from "../assets/images/PembayaranActive.webp";
import IconChat from "../assets/images/Chat.webp";
import IconChatActive from "../assets/images/ChatActive.webp";
import "./Navbar.css";
import { useDispatch } from "react-redux";
import { screenAction } from "../actions";

export const Header = (props) => {
  console.log("cek props navbar=======", props);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // return (
  //   <div>
  //     <div className="container">
  //       <div className="jumbotron">
  //         <h1>Auto rentcar</h1>
  //         <button
  //           onClick={() => navigate("/about")}
  //         >
  //           Test00
  //         </button>
  //         {/* <Link to="/SplashScreen">Home</Link> */}
  //       </div>
  //     </div>
  //   </div>
  // );
  // return <Slider images={Images} />
  return (
    <div className="navbar">
      {props.screenName == "Home" ? (
        <div
          className="containerNavbar"
          // onClick={() => {
          //   dispatch(screenAction("home", "screenName"));
          //   navigate("/home");
          // }}
        >
          <img src={IconHomeActive} alt="navbarIcon" className="navbarIcon" />
          <p>Home</p>
        </div>
      ) : (
        <div
          className="containerNavbar"
          onClick={() => {
            dispatch(screenAction("home", "screenName"));
            navigate("/home");
          }}
        >
          <img src={IconHome} alt="navbarIcon" className="navbarIcon" />
          <p>Home</p>
        </div>
      )}
      {props.screenName == "Produk" ? (
        <div
          className="containerNavbar"
          // onClick={() => {
          //   dispatch(screenAction("produk", "screenName"));
          //   navigate("/produk");
          // }}
        >
          <img src={IconProdukActive} alt="navbarIcon" className="navbarIcon" />
          <p>Produk</p>
        </div>
      ) : (
        <div
          className="containerNavbar"
          onClick={() => {
            dispatch(screenAction("produk", "screenName"));
            navigate("/produk");
          }}
        >
          <img src={IconProduk} alt="navbarIcon" className="navbarIcon" />
          <p>Produk</p>
        </div>
      )}
      {props.screenName == "Keranjang" ? (
        <div
          className="containerNavbar"
          // onClick={() => {
          //   dispatch(screenAction("keranjang", "screenName"));
          //   navigate("/keranjang");
          // }}
        >
          <img
            src={IconKeranjangActive}
            alt="navbarIcon"
            className="navbarIcon"
          />
          <p>Keranjang</p>
        </div>
      ) : (
        <div
          className="containerNavbar"
          onClick={() => {
            dispatch(screenAction("keranjang", "screenName"));
            navigate("/keranjang");
          }}
        >
          <img src={IconKeranjang} alt="navbarIcon" className="navbarIcon" />
          <p>Keranjang</p>
        </div>
      )}
      {props.screenName == "Pembayaran" ? (
        <div
          className="containerNavbar"
          // onClick={() => {
          //   dispatch(screenAction("pembayaran", "screenName"));
          //   navigate("/pembayaran");
          // }}
        >
          <img
            src={IconPembayaranActive}
            alt="navbarIcon"
            className="navbarIcon"
          />
          <p>Pembayaran</p>
        </div>
      ) : (
        <div
          className="containerNavbar"
          onClick={() => {
            dispatch(screenAction("pembayaran", "screenName"));
            navigate("/pembayaran");
          }}
        >
          <img src={IconPembayaran} alt="navbarIcon" className="navbarIcon" />
          <p>Pembayaran</p>
        </div>
      )}
      {props.screenName == "chat" ? (
        <div className="containerNavbar">
          <img src={IconChatActive} alt="navbarIcon" className="navbarIcon" />
          <p>Chat</p>
        </div>
      ) : (
        <div
          className="containerNavbar"
          onClick={() => {
            dispatch(screenAction("chat", "screenName"));
            navigate("/listchats");
          }}
        >
          <img src={IconChat} alt="navbarIcon" className="navbarIcon" />
          <p>Chat</p>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
