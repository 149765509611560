import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Slider from "../components/Slider";
import Images from "../images";
import './FirstPage.css'

export const FirstPage = (props) => {
  // console.log(props)
  const navigate = useNavigate();
    // return (
    //     <div>
    //     <div className="container">
    //         <div className="jumbotron">
    //         <h1>Auto rentcar</h1>
    //         <button
    //             onClick={() => navigate("/about")}
    //         >
    //             Test00
    //         </button>
    //         {/* <Link to="/SplashScreen">Home</Link> */}
    //         </div>
    //     </div>
    //     </div>
    // );
  return (
      <div className="firstPageContainer">
          <Slider images={Images} />
      </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FirstPage);
