import React, { useEffect, useState } from "react";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import Slider from "../components/Slider";
import Images from "../images";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.css";
import NumberFormat from "react-number-format";
import ReactStars from "react-rating-stars-component";
import "./Home.css";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import CircularProgress from "@mui/material/CircularProgress";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  screenAction,
  paramsAction,
  produkAction,
  promoAction,
} from "../actions";
import { useDispatch } from "react-redux";
import IconCart from "../assets/images/KeranjangActive.webp";
import IconNextBawah from "../assets/images/Next.webp";
import IconNextAtas from "../assets/images/Next2.webp";
import ModalShopping from "../components/DialogShopping";
import SearchComponent from "../pages/SearchList";
import { getDataToken } from "../config/firebase";
import { getMessaging, onMessage } from "firebase/messaging";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Dialog from "../components/Dialog";
import Loading from "../components/Loading";
import AlertBlacklist from "../components/AlertBlacklist";
import AlertCustom from "../components/CustomAlert";

export const Home = (props) => {
  const [steps, setSteps] = useState([
    {
      target: ".headerMenuLonceng",
      content: "Lonceng!",
    },
    {
      target: ".headerMenuKeranjang",
      content: "Keranjang!",
    },
    {
      target: ".homeCategory",
      content: "This another awesome feature!",
    },
  ]);
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const [listSearch, setListSearch] = useState([]);
  const [qty, setQty] = useState(0);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const [recomenProduct, setRecomenProduct] = useState([]);
  const [loadingApi, setLoadingApi] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [rating, setRating] = useState(1);
  const [shoppingCartItems, setShoppingCartItems] = useState({
    product_id: "",
    brand_id: "",
    satuan_online: "",
    konversi_sedang_ke_kecil: "",
    qty_konversi: "",
    qty: 1,
    notes: "",
    price_apps: "",
  });
  const [banners, setBanners] = useState([]);
  const [promo, setPromo] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products_newest, setProducts_newest] = useState([]);
  const [products_popular, setProducts_popular] = useState([]);
  const [products_recent, setProducts_recent] = useState([]);
  const [products_promo, setProducts_promo] = useState([]);
  const [qtyTotalNew, setQtyTotalNew] = useState(0);
  const [qtyTotalPopular, setQtyTotalPopular] = useState(0);
  const [qtyTotalPromo, setQtyTotalPromo] = useState(0);
  const [open, setOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [alertTambahan, setAlertTambahan] = useState("");
  const [alertJudul, setAlertJudul] = useState("");
  const [
    onEndReachedCalledDuringMomentum,
    setOnEndReachedCalledDuringMomentum,
  ] = useState(false);
  const [notifOrders, setNotifOrders] = useState(0);
  const [notifMessages, setNotifMessages] = useState(0);
  const [notifComplaints, setNotifComplaints] = useState(0);
  const [notifBroadcast, setNotifBroadcast] = useState(0);
  const [notifSubscribe, setNotifSubscribe] = useState(0);
  const [countNotifCart, setCountNotifCart] = useState(0);
  const [openShopping, setOpenShopping] = useState(false);
  const [loadAll, setLoadAll] = useState(false);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);
  const [numberWASub, setNumberWASub] = useState("");
  const [products_partner, setProducts_partner] = useState([]);
  const [qtyTotalPartner, setQtyTotalPartner] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState("");
  const parameter = queryParams.get("parameter");
  const [search, setSearch] = useState(parameter||"");
  const [customAlert, setCustomAlert] = useState("");
  const [openCustomAlert, setOpenCustomAlert] = useState(true);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  async function getToken() {
    let data;
    data = await getDataToken();
    // if (data) {
    //   console.log("token adalah : ", data);
    // }
    return data;
  }

  useEffect(() => {
    getToken();
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      // console.log("Message received. ", payload);
      // ...
    });
    getDataLoadAll();
    getWhatsappNumber();
    getNotifAll();
    handleRestart()
  }, []);

  useEffect(() => {
    if (page && !loadAll) {
      addPage();
    } else if (page && loadAll) {
      getDataLoadAll();
      getNotifAll();
      setLoadAll(false);
    }

    return () => {
      setOnEndReachedCalledDuringMomentum(false);
    };
  }, [page, loadingMore, loadAll]);

  async function getDataLoadAll() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/load-all?page=${page}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      // const dataTotal = response.data.data.total;
      setRecomenProduct(
        page === 1
          ? data.products_recomen[0].data
          : [...recomenProduct, ...data.products_recomen[0].data]
      );
      setLoadingMore(data.products_recomen[0].last_page > page);
      setMaxPage(data.products_recomen[0].last_page);
      setCategories(data.categories[0]);
      setBanners(data.banners[0]);
      setPromo(data.promo[0].data);
      setProducts_newest(data.products_newest[0].data);
      setProducts_popular(data.products_popular[0].data);
      setProducts_recent(data.products_recent[0].data);
      setProducts_promo(data.products_promo[0].data);
      setProducts_partner(data.products_partner[0].data);
      setQtyTotalNew(data.products_newest[0].total);
      setQtyTotalPopular(data.products_popular[0].total);
      setQtyTotalPartner(data.products_partner[0].total);
      setLoadingApi(false);
    } catch (error) {
      setLoadingApi(false);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error=======DATA LOAD=================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        setAlertData("Waktu sesi anda sudah berakhir");
        setAlertJudul("Pemberitahuan");
        setOpen(true);
      }
    }
  }

  async function addPage() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/load-all?page=${page}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      // const dataTotal = response.data.data.total;
      setRecomenProduct(
        page === 1
          ? data.products_recomen[0].data
          : [...recomenProduct, ...data.products_recomen[0].data]
      );
      setLoadingMore(data.products_recomen[0].last_page + 1 > page);
      setMaxPage(data.products_recomen[0].last_page);
      setLoadingApi(false);
    } catch (error) {
      setLoadingApi(false);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error=======DATA LOAD=================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
      }
    }
  }

  //load more data
  async function getMoreData() {
    if (!onEndReachedCalledDuringMomentum && page < maxPage) {
      setPage(page + 1);
      setLoadingMore(page < maxPage);
    }
    setOnEndReachedCalledDuringMomentum(page >= maxPage);
  }

  function handleRefresh() {
    setPage(1);
    setRefreshing(true);
    getDataLoadAll();
  }

  function handleLoadMore() {
    if (!loadingMore) return null;
    return (
      <div>
        <CircularProgress color="success" />
      </div>
    );
  }

  function getNavigasiCategories(item) {
    if (item.id == 1) {
      dispatch(screenAction("DetailHerbal", "screenName"));
      navigate("/produk");
    } else if (item.id == 2) {
      dispatch(screenAction("DetailSupMul", "screenName"));
      navigate("/produk");
    } else if (item.id == 3) {
      dispatch(screenAction("DetailFoodBev", "screenName"));
      navigate("/produk");
    } else if (item.id == 4) {
      dispatch(screenAction("DetailMinyak", "screenName"));
      navigate("/produk");
    }
  }

  function getNavigasiBanners(item, index) {
    if (item?.identity == "promo") {
      if (index == 0) {
        dispatch(screenAction("HerbalPromo", "screenName"));
        dispatch(paramsAction(item.id, "idPromo"));
        navigate("/produk/list");
      } else if (index == 1) {
        dispatch(screenAction("SupMulPromo", "screenName"));
        dispatch(paramsAction(item.id, "idPromo"));
        navigate("/produk/list");
      } else if (index == 2) {
        dispatch(screenAction("FoodBevPromo", "screenName"));
        dispatch(paramsAction(item.id, "idPromo"));
        navigate("/produk/list");
      } else if (index == 3) {
        dispatch(screenAction("MinyakPromo", "screenName"));
        dispatch(paramsAction(item.id, "idPromo"));
        navigate("/produk/list");
      }
    } else if (item?.identity == "top_spender") {
      navigate("/top-spender?id=" + item.id);
    }
  }

  async function getNotifAll() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/notification-all`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      setNotifOrders(data.total_order);
      setNotifMessages(data.total_chat);
      setNotifComplaints(data.total_complaint);
      setNotifSubscribe(data.total_subscribe);
      setNotifBroadcast(data.total_broadcast);
      setCountNotifCart(data.total_cart);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========NOTIF================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
      }
    }
  }

  async function postRecent(item) {
    try {
      let response = await axios.post(
        `${CONFIG.BASE_URL}/api/recent/products`,
        {
          product_id: item.id,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      // let data = response.data.data.data;
      // const dataTotal = response.data.data.total;
      handleRefresh();
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error==========POST RECENT==============",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
      }
    }
  }

  async function postShoppingCart(item) {
    const brand_id_1 = ["001"];
    const brand_id_2 = [
      "002",
      "003",
      "004",
      "007",
      "008",
      "009",
      "010",
      "011",
      "012",
      "013",
      "014",
    ];
    const brand_id_3 = ["005"];

    let price = item.price.harga_ritel_gt ?? "0";
    if (brand_id_1.includes(item.brand_id)) {
      if (item.status_promosi_coret == 1) {
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          default:
            break;
        }
      } else if (
        (item.status_promosi_coret !== "0" ||
          item.status_promosi_coret !== null) &&
        item.status_herbana !== "1"
      ) {
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_ritel_gt;
            break;
          default:
            break;
        }
      } else if (item.status_herbana === "1") {
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_grosir_mt;
            break;
          default:
            break;
        }
      } else {
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_ritel_gt;
            break;
          default:
            break;
        }
      }
    } else if (brand_id_2.includes(item.brand_id)) {
      if (item.status_promosi_coret == 1) {
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          default:
            break;
        }
      } else if (
        item.status_promosi_coret !== "0" ||
        item.status_promosi_coret !== null
      ) {
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_grosir_mt;
            break;
          default:
            break;
        }
      } else {
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_grosir_mt;
            break;
          default:
            break;
        }
      }
    } else if (brand_id_3.includes(item.brand_id)) {
      if (item.status_promosi_coret == 1) {
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          default:
            break;
        }
      } else if (
        item.status_promosi_coret !== "0" ||
        item.status_promosi_coret !== null
      ) {
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_ritel_gt;
            break;
          default:
            break;
        }
      } else {
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_ritel_gt;
            break;
          default:
            break;
        }
      }
    }


    try {
      let response = await axios.post(
        `${CONFIG.BASE_URL}/api/shopping-cart`,
        {
          product_id: item.id,
          brand_id: item.brand_id,
          satuan_online: item.satuan_online,
          konversi_sedang_ke_kecil: item.konversi_sedang_ke_kecil,
          qty_konversi: shoppingCartItems.qty * item.konversi_sedang_ke_kecil,
          qty: shoppingCartItems.qty,
          notes: shoppingCartItems.notes,
          price_apps: price ?? "0",
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data;
      if (data !== "" || data["success"] == true) {
        setOpenShopping(true);
        setLoadAll(true);
        setPage(1);
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
      }
    }
  }

  const handleClickOpenShopping = () => {
    setOpenShopping(true);
  };

  const handleCloseShopping = (e) => {
    setOpenShopping(false);
    if (e == "ok") {
      navigate("/keranjang");
    }
  };

  const handleCloseAlertBlacklist = () => {
    setOpenDialog(false);
  };
  const simpanSearch = (e) => {
    setSearch(e.target.value);
    setPage(1);
    if(e.target.value === ''){
      navigate("/home", {replace: true})
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async (e) => {
    setOpen(false);
    if (alertData == "Waktu sesi anda sudah berakhir") {
      navigate("/login");
    }
  };

  if (!localStorage.getItem("token")) {
    return <Navigate to={"/login"} />;
  }

  const getWhatsappNumber = async () => {
    try {
      let response = await axios.get(`${CONFIG.BASE_URL}/api/wa/number`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const data = response.data;
      if (data.data !== "" && data["success"] == true) {
        setNumberWASub(data.data[0]);
      } else {
        setAlertData("Gagal ambil data whatsapp");
        setAlertJudul("Pemberitahuan");
        setOpen(true);
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        // navigate('/home')
      }
    }
  };

  const handleChatWhatsapp = () => {
    let url = "https://wa.me/62" + numberWASub.telp_wa;
    window.open(url);
  };
  
  async function handleRestart () {
    try{
      let response = await axios.post(
        `${CONFIG.BASE_URL}/api/version/restart`,
        {
          version: "1.1.9",
          customer_code: dataUser.user.customer_code,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      const data = response.data;
      if (data?.restart != null) {
        setCustomAlert(data);
        setOpenCustomAlert(data?.alert?.is_show);
      }
    }catch(error){
      let error429 =
      JSON.parse(JSON.stringify(error)).message ===
      "Request failed with status code 429";
    let errorNetwork =
      JSON.parse(JSON.stringify(error)).message === "Network Error";
    let error400 =
      JSON.parse(JSON.stringify(error)).message ===
      "Request failed with status code 400";
    console.log(
      "Cek Error========================",
      JSON.parse(JSON.stringify(error)).message
    );
    if (error429) {
      setAlertServerSibuk(true);
    } else if (errorNetwork) {
      setAlertInet(true);
    } else if (error400) {
      localStorage.removeItem("token");
    }
    }
  }
  const handleCloseAlertCustom = () => {
    setOpenCustomAlert(!openCustomAlert);
    if (customAlert?.alert?.type === "top-spender") {
      navigate("/top-spender?id=" + customAlert?.alert?.parameter);
    } else if (customAlert?.alert?.type === "promo") {
      navigate("/promo/detail?id=" + customAlert?.alert?.parameter);
    } else if (customAlert?.alert?.type === "link") {
      window.open(customAlert?.alert?.parameter);
    }
  };
  return (
    <div className="homeContainer">
      <Dialog
        alertData={alertData}
        alertJudul={alertJudul}
        handleClose={handleClose}
        open={open}
      />
      <ModalShopping
        handleCloseShopping={handleCloseShopping}
        openShopping={openShopping}
      />
      <AlertBlacklist
        alertData={message}
        alertJudul="Pemberitahuan"
        handleClose={handleCloseAlertBlacklist}
        open={openDialog}
      />
      {loadingApi == true ? null : (
        <AlertCustom
          open={openCustomAlert}
          images={CONFIG.BASE_URL + "/" + customAlert?.alert?.parameter}
          typeOf={customAlert?.alert?.type === "image" ? true : false}
          titleAlert={customAlert?.alert?.title || "Title"}
          titleDescription={customAlert?.alert?.description || "Description"}
          textBtn={
            customAlert?.alert?.type === "info"
              ? "Tutup"
              : customAlert?.alert?.type === "link"
              ? "Lihat"
              : customAlert?.alert?.type === "promo"
              ? "Lihat Promo"
              : customAlert?.alert?.type === "top-spender"
              ? "Lihat Top-Spender"
              : "Tutup"
          }
          handleClose={handleCloseAlertCustom}
        />
      )}
      <Header
        notifMessages={notifMessages}
        notifSubscribe={notifSubscribe}
        notifOrders={notifOrders}
        notifComplaints={notifComplaints}
        notifBroadcast={notifBroadcast}
        countNotifCart={countNotifCart}
        titleHeader="Keranjang"
        simpanSearch={simpanSearch}
        text={search}
        item={true}
        search={false}
      />
      {search.length > 0 && <SearchComponent search={search} param={"home-param"}/>}
      {search.length == 0 && (
        <div className="homeContent">
          <div className="homeBannerPromo">
            <Carousel
              showArrows={true}
              showStatus={false}
              showIndicators={true}
              infiniteLoop={true}
              showThumbs={false}
              useKeyboardArrows={true}
              autoPlay={true}
              stopOnHover={true}
              swipeable={false}
              dynamicHeight={true}
              emulateTouch={false}
              autoFocus={false}
              interval={5000}
              swipeScrollTolerance={5}
            >
              {promo.map((item, index) => {
                return (
                  <div
                    onClick={() => {
                      dispatch(promoAction(item, "promo"));
                      navigate("/promo/detail?id=" + item.id);
                    }}
                    key={index}
                    className="homeBannerPromoImage"
                    style={{ minHeight: 100 }}
                  >
                    {item.banner ? (
                      <img
                        src={CONFIG.BASE_URL + item.banner}
                        type="carousel"
                        alt="homeBannerPromoImage"
                      />
                    ) : (
                      <img
                        src={DummyImage}
                        type="carousel"
                        alt="homeBannerPromoImage"
                      />
                    )}
                  </div>
                );
              })}
            </Carousel>
          </div>
          <hr className="hr"></hr>
          <div className="homeCategory">
            <p className="homeCategoryTitle">Kategori</p>
            <div className="homeCategoryContainer">
              {categories.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={getNavigasiCategories.bind(this, item)}
                    // className="homeCategoryItem"
                    style={{ width: "calc((100vw * 0.25) - 30px)" }}
                  >
                    <div className="homeCategoryCard">
                      <img
                        src={CONFIG.BASE_URL + item.icon}
                        alt="homeCategoryImage"
                        className="homeCategoryImage"
                      />
                    </div>
                    <p className="homeCategoryItemTitle">{item.name}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <hr className="hr"></hr>
          <div className="homeBanner">
            {/* <p>Banner</p> */}
            {banners.map((item, index) => {
              return (
                <img
                  key={index}
                  onClick={getNavigasiBanners.bind(this, item, index)}
                  src={CONFIG.BASE_URL + item.banner}
                  alt="homeBannerImage"
                  className="homeBannerImage"
                />
              );
            })}
          </div>
          <hr className="hr"></hr>
          {products_promo.length > 0 && (
            <>
              <div className="homeProduct">
                <div className="homeViewLihatSemua">
                  <p className="homeProductTitle">Produk Promo</p>
                  <div
                    onClick={() => {
                      navigate("/promo/calendar");
                    }}
                    className="homeGroupLihatSemua"
                  >
                    <p className="homeLihatSemua">Lihat Semua</p>
                    <img className="homeImageIconNextAtas" src={IconNextAtas} />
                  </div>
                </div>
                <div className="homeProductContainer">
                  {products_promo.map((item, index) => {
                    return (
                      <div key={index} className="homeProductItem">
                        {item.promo_sku.length > 0 && (
                          <div
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate(
                                "/produk/deskripsi?idProduct=" + item.id
                              );
                            }}
                            className="homeProductItemPromo"
                          >
                            <p className="homeProductItemPromoText">Promo</p>
                          </div>
                        )}
                        {item.image ? (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate(
                                "/produk/deskripsi?idProduct=" + item.id
                              );
                            }}
                            src={CONFIG.BASE_URL + item.image}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        ) : item?.product_image?.length != 0 ? (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate(
                                "/produk/deskripsi?idProduct=" + item.id
                              );
                            }}
                            src={CONFIG.BASE_URL + item?.product_image[0]?.path}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        ) : (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate(
                                "/produk/deskripsi?idProduct=" + item.id
                              );
                            }}
                            src={DummyImage}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        )}
                        <p
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemTitle"
                        >
                          {item.name}
                        </p>
                        {item.status_promosi_coret == 1 ? (
                          <NumberFormat
                            value={
                              Math.round(
                                item?.price?.harga_promosi_coret_ritel_gt ||
                                  item?.price?.harga_promosi_coret_grosir_mt ||
                                  item?.price?.harga_promosi_coret_semi_grosir
                              ) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate(
                                    "/produk/deskripsi?idProduct=" + item.id
                                  );
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        ) : (
                          <NumberFormat
                            value={
                              Math.round(item?.price?.harga_ritel_gt) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate(
                                    "/produk/deskripsi?idProduct=" + item.id
                                  );
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        )}
                        <div
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemView"
                        >
                          <div className="homeProductItemRating">
                            <ReactStars
                              count={1}
                              size={24}
                              edit={false}
                              color={"#ffd700"}
                            />
                            <p className="homeProductItemRatingValue">
                              {item.review
                                ? item.review[0]
                                  ? item.review[0].avg_rating
                                  : "0"
                                : "0"}
                            </p>
                          </div>
                          {item.cart && (
                            <div className="homeProductItemRating">
                              <img className="notifListIcon" src={IconCart} />
                              <p className="homeProductItemRatingValue">
                                {item.cart.qty}
                              </p>
                            </div>
                          )}
                        </div>
                        {item.cart ? (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Tambah</p>
                          </button>
                        ) : (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Beli</p>
                          </button>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <hr className="hr"></hr>
            </>
          )}
          {products_recent.length > 0 && (
            <>
              <div className="homeProduct">
                <p className="homeProductTitle">Produk Terakhir Dilihat</p>
                <div className="homeProductContainer">
                  {products_recent.map((item, index) => {
                    return (
                      <div key={index} className="homeProductItem">
                        {item.promo_sku.length > 0 && (
                          <div
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate(
                                "/produk/deskripsi?idProduct=" + item.id
                              );
                            }}
                            className="homeProductItemPromo"
                          >
                            <p className="homeProductItemPromoText">Promo</p>
                          </div>
                        )}
                        {item.image ? (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate(
                                "/produk/deskripsi?idProduct=" + item.id
                              );
                            }}
                            src={CONFIG.BASE_URL + item.image}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        )  : item?.product_image?.length != 0 ? (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate(
                                "/produk/deskripsi?idProduct=" + item.id
                              );
                            }}
                            src={CONFIG.BASE_URL + item?.product_image[0]?.path}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        ) : (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate(
                                "/produk/deskripsi?idProduct=" + item.id
                              );
                            }}
                            src={DummyImage}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        )}
                        <p
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemTitle"
                        >
                          {item.name}
                        </p>
                        {item.status_promosi_coret == 1 ? (
                          <NumberFormat
                            value={
                              Math.round(
                                item?.price?.harga_promosi_coret_ritel_gt ||
                                  item?.price?.harga_promosi_coret_grosir_mt ||
                                  item?.price?.harga_promosi_coret_semi_grosir
                              ) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate(
                                    "/produk/deskripsi?idProduct=" + item.id
                                  );
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        ) : (
                          <NumberFormat
                            value={
                              Math.round(item?.price?.harga_ritel_gt) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate(
                                    "/produk/deskripsi?idProduct=" + item.id
                                  );
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        )}
                        <div
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemView"
                        >
                          <div className="homeProductItemRating">
                            <ReactStars
                              count={1}
                              size={24}
                              edit={false}
                              color={"#ffd700"}
                            />
                            <p className="homeProductItemRatingValue">
                              {item.review
                                ? item.review[0]
                                  ? item.review[0].avg_rating
                                  : "0"
                                : "0"}
                            </p>
                          </div>
                          {item.cart && (
                            <div className="homeProductItemRating">
                              <img className="notifListIcon" src={IconCart} />
                              <p className="homeProductItemRatingValue">
                                {item.cart.qty}
                              </p>
                            </div>
                          )}
                        </div>
                        {item.cart ? (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Tambah</p>
                          </button>
                        ) : (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Beli</p>
                          </button>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <hr className="hr"></hr>
            </>
          )}
          {products_newest.length > 0 && (
            <>
              <div className="homeProduct">
                {qtyTotalNew > 10 ? (
                  <div className="homeViewLihatSemua">
                    <p className="homeProductTitle">Produk Terbaru</p>
                    <div
                      onClick={() => {
                        dispatch(screenAction("GeneralNew", "screenName"));
                        navigate("/produk/list");
                      }}
                      className="homeGroupLihatSemua"
                    >
                      <p className="homeLihatSemua">Lihat Semua</p>
                      <img
                        className="homeImageIconNextAtas"
                        src={IconNextAtas}
                      />
                    </div>
                  </div>
                ) : (
                  <p className="homeProductTitle">Produk Terbaru</p>
                )}
                <div className="homeProductContainer">
                  {products_newest.map((item, index) => {
                    return (
                      <div key={index} className="homeProductItem">
                        {item.promo_sku.length > 0 && (
                          <div
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate(
                                "/produk/deskripsi?idProduct=" + item.id
                              );
                            }}
                            className="homeProductItemPromo"
                          >
                            <p className="homeProductItemPromoText">Promo</p>
                          </div>
                        )}
                        {item.image ? (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate(
                                "/produk/deskripsi?idProduct=" + item.id
                              );
                            }}
                            src={CONFIG.BASE_URL + item.image}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        ) : item?.product_image?.length != 0 ? (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate(
                                "/produk/deskripsi?idProduct=" + item.id
                              );
                            }}
                            src={CONFIG.BASE_URL + item?.product_image[0]?.path}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        )  : (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate(
                                "/produk/deskripsi?idProduct=" + item.id
                              );
                            }}
                            src={DummyImage}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        )}
                        <p
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemTitle"
                        >
                          {item.name}
                        </p>
                        {item.status_promosi_coret == 1 ? (
                          <NumberFormat
                            value={
                              Math.round(
                                item?.price?.harga_promosi_coret_ritel_gt ||
                                  item?.price?.harga_promosi_coret_grosir_mt ||
                                  item?.price?.harga_promosi_coret_semi_grosir
                              ) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate(
                                    "/produk/deskripsi?idProduct=" + item.id
                                  );
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        ) : (
                          <NumberFormat
                            value={
                              Math.round(item?.price?.harga_ritel_gt) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate(
                                    "/produk/deskripsi?idProduct=" + item.id
                                  );
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        )}
                        <div
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemView"
                        >
                          <div className="homeProductItemRating">
                            <ReactStars
                              count={1}
                              size={24}
                              edit={false}
                              color={"#ffd700"}
                            />
                            <p className="homeProductItemRatingValue">
                              {item.review
                                ? item.review[0]
                                  ? item.review[0].avg_rating
                                  : "0"
                                : "0"}
                            </p>
                          </div>
                          {item.cart && (
                            <div className="homeProductItemRating">
                              <img className="notifListIcon" src={IconCart} />
                              <p className="homeProductItemRatingValue">
                                {item.cart.qty}
                              </p>
                            </div>
                          )}
                        </div>
                        {item.cart ? (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Tambah</p>
                          </button>
                        ) : (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Beli</p>
                          </button>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <hr className="hr"></hr>
            </>
          )}
          {products_popular.length > 0 && (
            <>
              <div className="homeProduct">
                {qtyTotalPopular > 10 ? (
                  <div className="homeViewLihatSemua">
                    <p className="homeProductTitle">Produk Populer</p>
                    <div
                      onClick={() => {
                        dispatch(screenAction("GeneralPopular", "screenName"));
                        navigate("/produk/list");
                      }}
                      className="homeGroupLihatSemua"
                    >
                      <p className="homeLihatSemua">Lihat Semua</p>
                      <img
                        className="homeImageIconNextAtas"
                        src={IconNextAtas}
                      />
                    </div>
                  </div>
                ) : (
                  <p className="homeProductTitle">Produk Populer</p>
                )}
                <div className="homeProductContainer">
                  {products_popular.map((item, index) => {
                    return (
                      <div key={index} className="homeProductItem">
                        {item.promo_sku.length > 0 && (
                          <div
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate(
                                "/produk/deskripsi?idProduct=" + item.id
                              );
                            }}
                            className="homeProductItemPromo"
                          >
                            <p className="homeProductItemPromoText">Promo</p>
                          </div>
                        )}
                        {item.image ? (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate(
                                "/produk/deskripsi?idProduct=" + item.id
                              );
                            }}
                            src={CONFIG.BASE_URL + item.image}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        ) : item?.product_image?.length != 0 ? (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate(
                                "/produk/deskripsi?idProduct=" + item.id
                              );
                            }}
                            src={CONFIG.BASE_URL + item?.product_image[0]?.path}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        )  : (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate(
                                "/produk/deskripsi?idProduct=" + item.id
                              );
                            }}
                            src={DummyImage}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        )}
                        <p
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemTitle"
                        >
                          {item.name}
                        </p>
                        {item.status_promosi_coret == 1 ? (
                          <NumberFormat
                            value={
                              Math.round(
                                item?.price?.harga_promosi_coret_ritel_gt ||
                                  item?.price?.harga_promosi_coret_grosir_mt ||
                                  item?.price?.harga_promosi_coret_semi_grosir
                              ) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate(
                                    "/produk/deskripsi?idProduct=" + item.id
                                  );
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        ) : (
                          <NumberFormat
                            value={
                              Math.round(item?.price?.harga_ritel_gt) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate(
                                    "/produk/deskripsi?idProduct=" + item.id
                                  );
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        )}
                        <div
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemView"
                        >
                          <div className="homeProductItemRating">
                            <ReactStars
                              count={1}
                              size={24}
                              edit={false}
                              color={"#ffd700"}
                            />
                            <p className="homeProductItemRatingValue">
                              {item.review
                                ? item.review[0]
                                  ? item.review[0].avg_rating
                                  : "0"
                                : "0"}
                            </p>
                          </div>
                          {item.cart && (
                            <div className="homeProductItemRating">
                              <img className="notifListIcon" src={IconCart} />
                              <p className="homeProductItemRatingValue">
                                {item.cart.qty}
                              </p>
                            </div>
                          )}
                        </div>
                        {item.cart ? (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Tambah</p>
                          </button>
                        ) : (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Beli</p>
                          </button>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <hr className="hr"></hr>
            </>
          )}
          {products_partner.length > 0 && (
            <>
              <div className="homeProduct">
                {qtyTotalPartner > 10 ? (
                  <div className="homeViewLihatSemua">
                    <p className="homeProductTitle">Produk Lainnya</p>
                    <div
                      onClick={() => {
                        dispatch(screenAction("GeneralPopular", "screenName"));
                        navigate("/produk/list");
                      }}
                      className="homeGroupLihatSemua"
                    >
                      <p className="homeLihatSemua">Lihat Semua</p>
                      <img
                        className="homeImageIconNextAtas"
                        src={IconNextAtas}
                      />
                    </div>
                  </div>
                ) : (
                  <p className="homeProductTitle">Produk Lainnya</p>
                )}
                <div className="homeProductContainer">
                  {products_partner.map((item, index) => {
                    return (
                      <div key={index} className="homeProductItem">
                        {item.promo_sku.length > 0 && (
                          <div
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate(
                                "/produk/deskripsi?idProduct=" + item.id
                              );
                            }}
                            className="homeProductItemPromo"
                          >
                            <p className="homeProductItemPromoText">Promo</p>
                          </div>
                        )}
                        {item.product_image.length > 0 ? (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate(
                                "/produk/deskripsi?idProduct=" + item.id
                              );
                            }}
                            src={CONFIG.BASE_URL + item.product_image[0].path}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        ) : (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate(
                                "/produk/deskripsi?idProduct=" + item.id
                              );
                            }}
                            src={DummyImage}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        )}
                        <p
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemTitle"
                        >
                          {item.name}
                        </p>
                        {item.status_promosi_coret == 1 ? (
                          <NumberFormat
                            value={
                              Math.round(
                                item?.price?.harga_promosi_coret_ritel_gt ||
                                  item?.price?.harga_promosi_coret_grosir_mt ||
                                  item?.price?.harga_promosi_coret_semi_grosir
                              ) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate(
                                    "/produk/deskripsi?idProduct=" + item.id
                                  );
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        ) : (
                          <NumberFormat
                            value={
                              Math.round(item?.price?.harga_ritel_gt) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate(
                                    "/produk/deskripsi?idProduct=" + item.id
                                  );
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        )}
                        <div
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemView"
                        >
                          <div className="homeProductItemRating">
                            <ReactStars
                              count={1}
                              size={24}
                              edit={false}
                              color={"#ffd700"}
                            />
                            <p className="homeProductItemRatingValue">
                              {item.review
                                ? item.review[0]
                                  ? item.review[0].avg_rating
                                  : "0"
                                : "0"}
                            </p>
                          </div>
                          {item.cart && (
                            <div className="homeProductItemRating">
                              <img className="notifListIcon" src={IconCart} />
                              <p className="homeProductItemRatingValue">
                                {item.cart.qty}
                              </p>
                            </div>
                          )}
                        </div>
                        {item.cart ? (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Tambah</p>
                          </button>
                        ) : (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Beli</p>
                          </button>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <hr className="hr"></hr>
            </>
          )}
          <div className="homeProductRecoment">
            <p className="homeProductTitle">Produk Rekomendasi</p>
            <InfiniteScroll
              dataLength={recomenProduct.length}
              next={() => getMoreData()}
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                marginTop: "20px",
              }}
              hasMore={true}
              loader={() => handleLoadMore()}
              // refreshFunction={handleRefresh}
              // pullDownToRefresh
              // pullDownToRefreshThreshold={50}
              // pullDownToRefreshContent={handleLoadMore}
            >
              {recomenProduct.map((item, index) => {
                return (
                  <div key={index} className="homeProductRecomenItem">
                    {item.promo_sku.length > 0 && (
                      <div
                        onClick={() => {
                          postRecent(item);
                          dispatch(produkAction(item, "produkDesc"));
                          navigate("/produk/deskripsi?idProduct=" + item.id);
                        }}
                        className="homeProductItemPromo"
                      >
                        <p className="homeProductItemPromoText">Promo</p>
                      </div>
                    )}
                    {item.image ? (
                      <img
                        onClick={() => {
                          postRecent(item);
                          dispatch(produkAction(item, "produkDesc"));
                          navigate("/produk/deskripsi?idProduct=" + item.id);
                        }}
                        src={CONFIG.BASE_URL + item.image}
                        alt="homeProductImage"
                        className="homeProductImage"
                      />
                    )  : item?.product_image?.length != 0 ? (
                      <img
                        onClick={() => {
                          postRecent(item);
                          dispatch(produkAction(item, "produkDesc"));
                          navigate(
                            "/produk/deskripsi?idProduct=" + item.id
                          );
                        }}
                        src={CONFIG.BASE_URL + item?.product_image[0]?.path}
                        alt="homeProductImage"
                        className="homeProductImage"
                      />
                    ) : (
                      <img
                        onClick={() => {
                          postRecent(item);
                          dispatch(produkAction(item, "produkDesc"));
                          navigate("/produk/deskripsi?idProduct=" + item.id);
                        }}
                        src={DummyImage}
                        alt="homeProductImage"
                        className="homeProductImage"
                      />
                    )}
                    <p
                      onClick={() => {
                        postRecent(item);
                        dispatch(produkAction(item, "produkDesc"));
                        navigate("/produk/deskripsi?idProduct=" + item.id);
                      }}
                      className="homeProductItemTitle"
                    >
                      {item.name}
                    </p>
                    {item.status_promosi_coret == 1 ? (
                      <NumberFormat
                        value={
                          Math.round(
                            item?.price?.harga_promosi_coret_ritel_gt ||
                              item?.price?.harga_promosi_coret_grosir_mt ||
                              item?.price?.harga_promosi_coret_semi_grosir
                          ) ?? "0"
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Rp "}
                        renderText={(value) => (
                          <p
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate(
                                "/produk/deskripsi?idProduct=" + item.id
                              );
                            }}
                            className="homeProductItemPrice"
                          >
                            {value || 0}
                          </p>
                        )}
                      />
                    ) : (
                      <NumberFormat
                        value={Math.round(item?.price?.harga_ritel_gt) ?? "0"}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Rp "}
                        renderText={(value) => (
                          <p
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate(
                                "/produk/deskripsi?idProduct=" + item.id
                              );
                            }}
                            className="homeProductItemPrice"
                          >
                            {value || 0}
                          </p>
                        )}
                      />
                    )}
                    <div
                      onClick={() => {
                        postRecent(item);
                        dispatch(produkAction(item, "produkDesc"));
                        navigate("/produk/deskripsi?idProduct=" + item.id);
                      }}
                      className="homeProductItemView"
                    >
                      <div className="homeProductItemRating">
                        <ReactStars
                          count={1}
                          size={24}
                          edit={false}
                          color={"#ffd700"}
                        />
                        <p className="homeProductItemRatingValue">
                          {item.review
                            ? item.review[0]
                              ? item.review[0].avg_rating
                              : "0"
                            : "0"}
                        </p>
                      </div>
                      {item.cart && (
                        <div className="homeProductItemRating">
                          <img className="notifListIcon" src={IconCart} />
                          <p className="homeProductItemRatingValue">
                            {item.cart.qty}
                          </p>
                        </div>
                      )}
                    </div>
                    {item.cart ? (
                      <button
                        onClick={() => postShoppingCart(item)}
                        className="homeProductItemButton"
                      >
                        <p className="homeProductItemButtonBeli">Tambah</p>
                      </button>
                    ) : (
                      <button
                        onClick={() => postShoppingCart(item)}
                        className="homeProductItemButton"
                      >
                        <p className="homeProductItemButtonBeli">Beli</p>
                      </button>
                    )}
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        </div>
      )}
      <Navbar screenName={"Home"} />
      <div
        onClick={handleChatWhatsapp}
        className="styles-module_whatsappButton"
        aria-hidden="true"
      >
        <svg focusable="false" viewBox="0 0 24 24" width="55" height="55">
          <path d="M16.75 13.96c.25.13.41.2.46.3.06.11.04.61-.21 1.18-.2.56-1.24 1.1-1.7 1.12-.46.02-.47.36-2.96-.73-2.49-1.09-3.99-3.75-4.11-3.92-.12-.17-.96-1.38-.92-2.61.05-1.22.69-1.8.95-2.04.24-.26.51-.29.68-.26h.47c.15 0 .36-.06.55.45l.69 1.87c.06.13.1.28.01.44l-.27.41-.39.42c-.12.12-.26.25-.12.5.12.26.62 1.09 1.32 1.78.91.88 1.71 1.17 1.95 1.3.24.14.39.12.54-.04l.81-.94c.19-.25.35-.19.58-.11l1.67.88M12 2a10 10 0 0 1 10 10 10 10 0 0 1-10 10c-1.97 0-3.8-.57-5.35-1.55L2 22l1.55-4.65A9.969 9.969 0 0 1 2 12 10 10 0 0 1 12 2m0 2a8 8 0 0 0-8 8c0 1.72.54 3.31 1.46 4.61L4.5 19.5l2.89-.96A7.95 7.95 0 0 0 12 20a8 8 0 0 0 8-8 8 8 0 0 0-8-8z"></path>
        </svg>
      </div>
      <Loading loadingApi={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
