import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import "./AlertBlacklist.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertBlacklist({
  open,
  handleClose,
  handleClickOpen,
  alertData,
  alertJudul,
  alertTambahan,
  buttonDialogCancel,
  buttonDialogOK,
}) {
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{alertJudul}</DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{ fontSize: 20, color: "#529F45", marginBottom: 2 }}
            id="alert-dialog-slide-description"
          >
            {alertTambahan}
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
            <div style={{ color: "#000",textAlign:'center'}}>{alertData}</div>
          </DialogContentText>
        </DialogContent>
        <div className="div-btn-alert-blacklist">
          <div
            className="btn-alert-blacklist"
            onClick={(e) => handleClose((e = "ok"))}
          >
            Tutup
          </div>
        </div>
      </Dialog>
    </div>
  );
}
