import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import IconBack from "../assets/images/backArrow.webp";
import IconSearch from "../assets/images/Search.webp";
import Product1 from "../assets/dummy/product1.jpg";
import Product2 from "../assets/dummy/product2.jpg";
import IconNotif from "../assets/images/NotifAll.webp";
import IconMenu from "../assets/images/Menu.webp";
import "./Pembayaran.css";
import Navbar from "../components/Navbar";
import { useSelector } from "react-redux";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import CircularProgress from "@mui/material/CircularProgress";
import { produkAction, screenAction, orderAction } from "../actions";
import { useDispatch } from "react-redux";
import Dialog from "../components/Dialog";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import InfiniteScroll from "react-infinite-scroll-component";
import NumberFormat from "react-number-format";
import Calendar from "../components/CalendarDateRange";
import moment from "moment";
import error404 from "../assets/images/404.webp";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";
import Header from "../components/HeaderDistributor";
export const TransactionDistributor = (props) => {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const { screenName } = useSelector((state) => state.ScreenReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [listSearch, setListSearch] = useState([]);
  const [notifOrders, setNotifOrders] = useState(0);
  const [notifMessages, setNotifMessages] = useState(0);
  const [notifComplaints, setNotifComplaints] = useState(0);
  const [notifBroadcast, setNotifBroadcast] = useState(0);
  const [notifSubscribe, setNotifSubscribe] = useState(0);
  const [countNotifCart, setCountNotifCart] = useState(0);
  const [open, setOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [alertTambahan, setAlertTambahan] = useState("");
  const [alertJudul, setAlertJudul] = useState("");
  const [loadingApi, setLoadingApi] = useState(true);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const [refreshing, setRefreshing] = useState(false);
  const [
    onEndReachedCalledDuringMomentum,
    setOnEndReachedCalledDuringMomentum,
  ] = useState(false);
  const [pilihStatus, setPilihStatus] = useState({
    status: "",
  });
  const [pilihTanggal, setPilihTanggal] = useState({
    date: "",
  });
  const [params, setParams] = useState({});
  const [listDataTransaction, setListDataTransaction] = useState([]);
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  const [showPickerDate, setShowPickerDate] = useState(false);
  const [date, setDate] = useState(new Date());
  const [minDate, setMinDate] = useState(new Date());
  const [selectRange, setSelectRange] = useState(true);
  const [valueDate, setValueDate] = useState(null);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  function NoData() {
    return (
      <div className="NoData">
        <img style={{ height: "50%", width: "100%" }} src={error404} />
        <p
          style={{
            fontSize: "14pt",
            fontFamily: "Lato-Bold",
            textAlign: "center",
            marginTop: "-20%",
          }}
        >
          {"Wah, daftar pembayaran mu kosong"}
        </p>
        <p
          style={{
            fontSize: "12pt",
            fontFamily: "Lato-Bold",
            width: "75%",
            textAlign: "center",
            marginTop: "2px",
          }}
        >
          {"Yuk, isi dengan barang-barang yang ingin kamu beli"}
        </p>
        <button
          onClick={() => {
            dispatch(screenAction("produk", "screenName"));
            navigate("/produk");
          }}
          style={{
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#529F45",
            width: "65%",
            height: "8%",
            borderRadius: "5px",
            marginTop: "5px",
            color: "#FFFFFF",
            fontSize: "10pt",
            fontFamily: "Lato-Bold",
          }}
        >
          {"Mulai Belanja"}
        </button>
      </div>
    );
  }

  useEffect(() => {
    getNotifAll();
    getDataOrder("status", pilihStatus.status);
    return () => {};
  }, []);

  useEffect(() => {
    console.log("page", page);
    if (dateRange[1]) {
      handleDateChange(valueDate);
    }

    if (page >= 1 && search.length == 0) {
      if (pilihTanggal.date === "Pilih periode tanggal") {
        console.log("masukk status");
        getDataOrderWithBetweenDate("status", pilihStatus.status);
      } else {
        console.log("masukk status 2");
        getDataOrder("status", pilihStatus.status);
      }
    }
    if (page >= 1 && search.length > 0) {
      if (pilihTanggal.date === "Pilih periode tanggal") {
        getDataOrderWithBetweenDate("search", search);
      } else {
        getDataOrder("search", search);
      }
    }

    return () => {
      setOnEndReachedCalledDuringMomentum(false);
    };
  }, [page, loadingMore, search, dateRange]);

  //filter show data
  function handleStatusChange(item) {
    console.log("cek item status", item);
    if (page > 1) {
      setPage(1);
    }
    setPilihStatus((prevState) => ({
      ...prevState,
      status: item,
    }));
    setLoadingApi(true);
    getDataOrder("status", item);
  }

  function handleDateChange(item) {
    console.log("item nih", item);
    if (item == "Pilih periode tanggal") {
      console.log("masuk ke between");
      if (page > 1) {
        setPage(1);
      }
      setPilihTanggal({
        date: item,
      });
      setLoadingApi(true);
      getDataOrderWithBetweenDate("date", "");
      //   this.setState(
      //     {
      //       pilihTanggal: {
      //         title: item.title,
      //         status: item,
      //       },
      //       modalVisibleTanggal: false,
      //       loadingApi: true,
      //     },
      //     () => {
      //       this.getDataOrderWithBetweenDate();
      //     }
      //   );
    } else {
      console.log("masuk ke single date");
      if (page > 1) {
        setPage(1);
      }
      setPilihTanggal((prevState) => ({
        ...prevState,
        date: item,
      }));
      setLoadingApi(true);
      getDataOrder("date", item);
      //   this.setState(
      //     {
      //       params: {},
      //       pilihTanggal: {
      //         title: item.title,
      //         status: item,
      //       },
      //       modalVisibleTanggal: false,
      //       loadingApi: true,
      //     },
      //     () => {
      //       this.getDataOrder();
      //     }
      //   );
    }
  }

  //load more data
  function getMoreData() {
    console.log(
      "cekk onEndReachedCalledDuringMomentum",
      !onEndReachedCalledDuringMomentum
    );
    console.log("cekk page", page);
    console.log("cekk maxPage", maxPage);
    console.log("cekk loadingMore", loadingMore);
    console.log("pilihTanggal.date", pilihTanggal.date);
    if (pilihTanggal.date == "Pilih periode tanggal") {
      if (!onEndReachedCalledDuringMomentum && page < maxPage) {
        setPage(page + 1);
        setLoadingMore(page < maxPage);
        console.log("masuk pertama", page, loadingMore);
      }
      setOnEndReachedCalledDuringMomentum(page >= maxPage);
    } else {
      if (!onEndReachedCalledDuringMomentum && page < maxPage) {
        setPage(page + 1);
        setLoadingMore(page < maxPage);
        console.log("masuk kedua", page, loadingMore);
      }
      setOnEndReachedCalledDuringMomentum(page >= maxPage);
    }
  }

  function handleRefresh() {
    setPage(1);
    setRefreshing(true);
  }

  function handleLoadMore() {
    console.log("cekkkkk saiki", !loadingMore);
    if (!loadingMore) return null;
    return (
      <div>
        <CircularProgress color="success" />
      </div>
    );
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async (e) => {
    setOpen(false);
  };

  async function getNotifAll() {
    console.log("cek hit notif");
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/notification-all`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      // console.log('DATA TEST', data.total_chat);
      setNotifOrders(data.total_order);
      setNotifMessages(data.total_chat);
      setNotifComplaints(data.total_complaint);
      setNotifSubscribe(data.total_subscribe);
      setNotifBroadcast(data.total_broadcast);
      setCountNotifCart(data.total_cart);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========NOTIF================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  function renderCountNotificationBadge() {
    try {
      let count = 0;
      let hasil = notifMessages;
      let hasil2 = notifSubscribe;
      let hasil3 = notifOrders;
      let hasil4 = notifComplaints;
      let hasil5 = notifBroadcast;
      count =
        count +
        parseInt(hasil) +
        parseInt(hasil2) +
        parseInt(hasil3) +
        parseInt(hasil4) +
        parseInt(hasil5);
      // console.log(count);
      if (count > 0) {
        return count;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
    }
  }

  // get order
  async function getDataOrder(name, value) {
    console.log("masuk getDataOrder single", page);
    let parameter = {
      ...params,
      [name]: value,
    };
    if (parameter.date) {
      delete parameter.start_date;
      delete parameter.end_date;
    }
    let _params = "?";
    console.log("parameter", parameter);
    Object.keys(parameter).forEach((key, i) => {
      if (i > 0) {
        _params += "&";
      }
      _params += `${key}=${parameter[key]}`;
    });
    console.log("params", _params);
    // console.log('paramsState', this.state.params);
    try {
      let response;
      if (dataUser.user.account_type == 5) {
        response = await axios.get(
          `${CONFIG.BASE_URL}/api/distributor-partner/transactions${_params}&page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      } else {
        response = await axios.get(
          `${CONFIG.BASE_URL}/api/transactions${_params}&page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      }
      const data = response.data.data;
      setLoadingApi(false);
      setListDataTransaction(
        page == 1 ? data.data : [...listDataTransaction, ...data.data]
      );
      // setListDataTransaction((prevState) =>
      //   [...prevState, ...data.data]
      // );
      setLoadingMore(data.last_page > page);
      setMaxPage(data.last_page);
      // setParams((prevState) => ({
      //   ...prevState,
      //   [name]: value,
      // }));
      setParams(parameter);
      console.log("DATA ORDER BERHASIL", data.last_page > page);
    } catch (error) {
      setLoadingApi(false);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  // get order
  async function getDataOrderWithBetweenDate(name, value) {
    const start_date = moment(dateRange[0]).format("YYYY-MM-DD");
    const end_date = moment(dateRange[1]).format("YYYY-MM-DD");
    let parameter = {
      ...params,
      [name]: value,
      start_date: start_date,
      end_date: end_date,
    };
    let _params = "?";
    console.log("parameter between", parameter);
    console.log("page between", page);
    Object.keys(parameter).forEach((key, i) => {
      if (i > 0) {
        _params += "&";
      }
      _params += `${key}=${parameter[key]}`;
    });
    console.log("params", _params);
    // console.log('paramsState', this.state.params);
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/transactions${_params}&page=${page}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      setLoadingApi(false);
      setListDataTransaction(
        page == 1 ? data.data : [...listDataTransaction, ...data.data]
      );
      setLoadingMore(data.last_page > page);
      setMaxPage(data.last_page);
      setParams(parameter);
      setDateRange([null, null]);
    } catch (error) {
      setLoadingApi(false);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  const hitungQuantity = (item) => {
    const sumTotal = (arr = []) =>
      arr.reduce((sum, { qty }) => sum + parseInt(qty), 0);
    const total = sumTotal(item.data_item ?? 0);
    if (total === 0) return null;
    return total;
  };

  function pasangTanggal(update) {
    setDateRange(update);
    setTimeout(() => {
      setShowPickerDate(!showPickerDate);
    }, 3000);
  }

  return (
    <div className="pembayaranContainer">
      <Dialog
        alertData={alertData}
        alertJudul={alertJudul}
        handleClose={handleClose}
        open={open}
      />
      <div className="pembayaranHeader">
        <div className="pembayaranHeaderTitle">
          <Header
            notifMessages={notifMessages}
            notifSubscribe={notifSubscribe}
            notifOrders={notifOrders}
            notifComplaints={notifComplaints}
            notifBroadcast={notifBroadcast}
            countNotifCart={countNotifCart}
            // simpanSearch={simpanSearch}
            titleHeader="Riwayat Ulasan"
            badge={true}
            linkProfile={true}
            item={false}
          />
        </div>
        <div className="pembayaranSearchContainer">
          <input
            className="pembayaranTextInput"
            placeholder="Pencarian..."
            type="text"
            onChange={(e) => setSearch(e.target.value)}
          />
          <img
            src={IconSearch}
            alt="headerSearchLogo"
            className="pembayaranSearchLogo"
          />
        </div>
        <div className="pembayaranHeaderFilter">
          <div className="pembayaranStatusContainer">
            <select
              onChange={(e) => {
                handleStatusChange(e.target.value);
              }}
              className="pembayaranSelect"
            >
              <option value={""}>Semua Status</option>
              <option value={"new transaction"}>Transaksi Baru</option>
              <option value={"order confirmed"}>Transaksi Terkonfirmasi</option>
              <option value={"delivery process"}>Barang Diproses</option>
              <option value={"completed"}>Selesai</option>
              <option value={"canceled"}>Batal</option>
            </select>
          </div>
          <div className="pembayaranDateContainer">
            <select
              onChange={(e) => {
                if (e.target.value == "Pilih periode tanggal") {
                  console.log("Masuk date 111111");
                  setShowPickerDate(!showPickerDate);
                  setValueDate(e.target.value);
                } else {
                  console.log("Masuk date 2222222");
                  handleDateChange(e.target.value);
                }
              }}
              className="pembayaranSelect"
            >
              <option value={""}>Semua Tanggal</option>
              <option value={"30"}>30 hari terakhir</option>
              <option value={"90"}>90 hari terakhir</option>
              <option value={"Pilih periode tanggal"}>
                Pilih periode tanggal
              </option>
            </select>
          </div>
        </div>
        {showPickerDate ? (
          <div className="pembayaranDateContainer">
            <Calendar
              openCalendar={showPickerDate}
              startDate={startDate}
              endDate={endDate}
              setDate={(update) => pasangTanggal(update)}
            />
          </div>
        ) : null}
      </div>
      <div className="pembayaran">
        {listDataTransaction.length > 0 && !loadingApi ? (
          <InfiniteScroll
            dataLength={listDataTransaction.length}
            next={() => getMoreData()}
            hasMore={true}
            loader={() => handleLoadMore()}
            style={{ paddingBottom: "4vh" }}
            // refreshFunction={handleRefresh}
            // pullDownToRefresh
            // pullDownToRefreshThreshold={50}
            // pullDownToRefreshContent={handleLoadMore}
          >
            {listDataTransaction.map((item, index) => {
              return (
                <div key={index} className="pembayaranMain">
                  <div
                    onClick={() => {
                      dispatch(orderAction(item, "orderDetail"));
                      setPilihStatus((prevState) => ({
                        ...prevState,
                        value: "",
                      }));
                      navigate("/pembayaran/detail?transaksi="+item.id);
                    }}
                    className="pembayaranTitle"
                  >
                    <div className="pembayaranTitleDate">
                      {moment(item.created_at).format("DD MMM YYYY HH:mm")}
                    </div>
                    {item.status == 1 ? (
                      <div className="pembayaranTitleStatusBaru">
                        <div className="text">Transaksi Baru</div>
                      </div>
                    ) : item.status == 2 ? (
                      <div className="pembayaranTitleStatusKonfirmasi">
                        <div className="text">Transaksi Terkonfirmasi</div>
                      </div>
                    ) : item.status == 3 ? (
                      <div className="pembayaranTitleStatusKonfirmasi">
                        <div className="text">Barang Diproses</div>
                      </div>
                    ) : item.status == 4 ? (
                      <div className="pembayaranTitleStatusKonfirmasi">
                        <div className="text">Selesai</div>
                      </div>
                    ) : item.status == 10 ? (
                      <div className="pembayaranTitleStatusBatal">
                        <div className="text">Batal</div>
                      </div>
                    ) : item.status == "R" ? (
                      <div className="pembayaranTitleStatusRetur">
                        <div className="text">Retur Barang</div>
                      </div>
                    ) : null}
                  </div>
                  <hr></hr>
                  <div
                    onClick={() => {
                      dispatch(orderAction(item, "orderDetail"));
                      setPilihStatus((prevState) => ({
                        ...prevState,
                        value: "",
                      }));
                      navigate("/pembayaran/detail?transaksi="+item.id);
                    }}
                    className="pembayaranContent"
                  >
                    {item?.data_item[0]?.product.product_image.length ? (
                      <img
                        src={
                          CONFIG.BASE_URL +
                          item?.data_item[0]?.product.product_image[0].path
                        }
                        alt="pembayaranContentImage"
                        className="pembayaranContentImage"
                      />
                    ) : (
                      <img
                        src={DummyImage}
                        alt="pembayaranContentImage"
                        className="pembayaranContentImage"
                      />
                    )}
                    <div className="pembayaranContent2">
                      <div className="pembayaranContentProduct">
                        {item?.data_item[0]?.product.name}
                      </div>
                      <div className="halfView">
                        <NumberFormat
                          value={Math.round(item.payment_final)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"Rp "}
                          // decimalScale={2}
                          fixedDecimalScale={true}
                          renderText={(value) => (
                            <div className="pembayaranContentPrice">
                              {value || 0}
                              {" ( "}
                              {hitungQuantity(item)}
                              {" barang"}
                              {" )"}
                            </div>
                          )}
                        />
                        {item?.data_item[0]?.half ? (
                          <div className="isiHalf">
                            {" ( "}
                            {item?.data_item[0]?.qty_konversi}{" "}
                            {item?.data_item[0]?.small_unit.charAt(0) +
                              item.data_item[0]?.small_unit
                                .slice(1)
                                .toLowerCase()}
                            {" )"}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {item.data_item.length > 1 ? (
                    <div
                      onClick={() => {
                        dispatch(orderAction(item, "orderDetail"));
                        setPilihStatus((prevState) => ({
                          ...prevState,
                          value: "",
                        }));
                        navigate("/pembayaran/detail?transaksi="+item.id);
                      }}
                      className="productQty"
                    >
                      +{item.data_item.length - 1} produk lainnya
                    </div>
                  ) : null}
                  {/* {item.status === "4" &&
                  item.data_review.length === 0 &&
                  item.data_complaint.length === 0 &&
                  item.status_complaint == null &&
                  item.status_review == null ? ( */}
                  {item.status === "4" &&
                  item.data_review.length === 0 &&
                  dataUser.user.account_type != 5 ? (
                    <>
                      <div className="footer">
                        {item.complaint_id === null ? (
                          <button
                            onClick={() => {
                              dispatch(orderAction(item, "orderComplaint"));
                              setPilihStatus((prevState) => ({
                                ...prevState,
                                value: "",
                              }));
                              navigate("/complaint/create");
                            }}
                            className="btnComplaint"
                          >
                            <p className="text">Ajukan Komplain</p>
                          </button>
                        ) : null}
                        <button
                          onClick={() => {
                            dispatch(produkAction(item, "ratingProduk"));
                            dispatch(screenAction("pembayaran", "screenName"));
                            setPilihStatus((prevState) => ({
                              ...prevState,
                              value: "",
                            }));
                            navigate("/rating/add");
                          }}
                          className="btnReview"
                        >
                          <p className="text">Kirim Ulasan</p>
                        </button>
                      </div>
                    </>
                  ) : null}
                  {item.status === "4" &&
                  item.data_review.length === 0 &&
                  item.data_complaint.length !== 0 &&
                  item.status_review == null ? (
                    <div className="footer">
                      <button
                        onClick={() => {
                          dispatch(produkAction(item, "ratingProduk"));
                          dispatch(screenAction("pembayaran", "screenName"));
                          setPilihStatus((prevState) => ({
                            ...prevState,
                            value: "",
                          }));
                          navigate("/rating/add");
                        }}
                        className="btnReview"
                      >
                        <p className="text">Kirim Ulasan</p>
                      </button>
                    </div>
                  ) : null}
                  {item.status === "4" &&
                  item.data_review.length !== 0 &&
                  item.data_complaint.length === 0 &&
                  item.status_complaint == null ? (
                    <div className="footer">
                      <button
                        onClick={() => {
                          dispatch(orderAction(item, "orderComplaint"));
                          setPilihStatus((prevState) => ({
                            ...prevState,
                            value: "",
                          }));
                          navigate("/complaint/create");
                        }}
                        className="btnComplaint"
                      >
                        <p className="text">Ajukan Komplain</p>
                      </button>
                    </div>
                  ) : null}
                </div>
              );
            })}
          </InfiniteScroll>
        ) : listDataTransaction.length == 0 && !loadingApi ? (
          <NoData />
        ) : null}
      </div>
      {dataUser.user.account_type == 5 ? null : (
        <Navbar screenName={"Pembayaran"} />
      )}
      <Loading loadingApi={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionDistributor);
