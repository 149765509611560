import React from "react";
import "./SplashMission.css";
import { useNavigate, Navigate } from "react-router-dom";
import SplashImages from "../assets/images/IconSplashMission.webp";
import IconBack from "../assets/images/backArrow.webp";
export const SplashMission = () => {
  const navigate = useNavigate();
  return (
    <div className="container-splash">
      <div onClick={() => navigate(-1)} style={{paddingTop:"5%", paddingLeft:"5%"}}>
        <img src={IconBack} alt="headerIconArrow" className="headerIconArrow" />
      </div>
      <div className="content-splash">
        <img className="images-splash" src={SplashImages} />
        <div className="text-title-splash">Memulai Misi</div>
        <div className="text-isi-splash">
          Ayo Mulai Misi untuk mendapatkan hadiah
        </div>
      </div>
      <div className="btn-mulai-misi">
        <button
          onClick={() => {
            navigate("/mission/list");
          }}
          className="text-btn-mulai-misi"
        >
          Mulai
        </button>
      </div>
    </div>
  );
};

export default SplashMission;
