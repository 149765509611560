import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import IconBack from "../assets/images/backArrow.webp";
import "./TambahProdukDistributor.css";
import axios from "axios";
import CONFIG from "../config/config";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "../components/Dialog";
import Loading from "../components/Loading";
import Header from "../components/HeaderDistributor";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import ImageUploading from "react-images-uploading";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import DummyImage from "../assets/images/Logo.webp";
import IconTrash from "../assets/images/Trash.webp";

export const TambahProdukDistributor = (props) => {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const { produkDesc } = useSelector((state) => state.ProdukReducer);
  const navigate = useNavigate();
  const location = useLocation();
  const ubahProduk = location.state?.screen;
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [stock, setStock] = useState("");
  const [min_pembelian, setMin_pembelian] = useState("");
  const [category_id, setCategory_id] = useState("");
  const [satuan_online, setSatuan_online] = useState("");
  const [harga_ritel_gt, setHarga_ritel_gt] = useState("");
  const [photo, setPhoto] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [alertTambahan, setAlertTambahan] = useState("");
  const [alertJudul, setAlertJudul] = useState("");
  const [loadingApi, setLoadingApi] = useState(false);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);
  const [allCategory, setAllCategory] = useState([]);
  const [dataProduk, setDataProduk] = useState(produkDesc);
  const [isEnabled, setIsEnabled] = React.useState(false);
  const [idPhoto, setIdPhoto] = useState("");
  const URL = CONFIG.BASE_URL;

  useEffect(() => {
    getCategory();
    getDetailProduk();
  }, []);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  const handleClickOpen = () => {
    setAlertData("Apakah yakin ingin mengganti nama anda?");
    setAlertJudul("Pemberitahuan");
    setOpen(true);
  };

  const handleClose = async (e) => {
    setOpen(false);
    if (e == "ok" && idPhoto) {
      await axios
        .delete(
          `${CONFIG.BASE_URL}/api/distributor-partner/delete-products-image?id=${idPhoto}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          const data = response.data;
          if (data["success"] == true) {
            getDetailProduk();
          }
        })
        .catch((error) => {
          let error429 =
            JSON.parse(JSON.stringify(error)).message ===
            "Request failed with status code 429";
          let errorNetwork =
            JSON.parse(JSON.stringify(error)).message === "Network Error";
          let error400 =
            JSON.parse(JSON.stringify(error)).message ===
            "Request failed with status code 400";
          console.log(
            "Cek Error========================",
            JSON.parse(JSON.stringify(error)).message
          );
          if (error429) {
            setAlertServerSibuk(true);
          } else if (errorNetwork) {
            setAlertInet(true);
          } else if (error400) {
            localStorage.removeItem("token");
            navigate("/home");
          }
        });
    }
  };

  const getDetailProduk = async () => {
    try {
      let response = await axios({
        method: "get",
        url: `${CONFIG.BASE_URL}/api/distributor-partner/products/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      console.log("Data",response);
      const data = response.data;
      if (data["success"] == true) {
        setDataProduk(data.data);
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  };

  const getCategory = async () => {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/distributor-partner/products/categories`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      console.log("AllCategory", data);
      setAllCategory(data);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  };

  const toggleSwitch = () => {
    // setIsEnabled(previousState => !previousState);
    setIsEnabled((previousState) => !previousState);
    setDataProduk((prevState) => ({
      ...prevState,
      status: dataProduk.status == 1 ? 0 : 1,
    }));
  };

  const postProduk = async () => {
    const reg = /^[0-9\b]+$/;
    setButtonDisabled(true);
    if (ubahProduk) {
      const formData = new FormData();
      formData.append("name", dataProduk.name);
      formData.append("category_id", dataProduk.category_id);
      formData.append("description", dataProduk.description);
      formData.append("stock", dataProduk.stock);
      formData.append("satuan_online", dataProduk.satuan_online);
      formData.append("status", dataProduk.status);
      formData.append("harga_ritel_gt", dataProduk.price.harga_ritel_gt);
      formData.append("min_pembelian", dataProduk.min_pembelian);
      if (photo != null) {
        console.log("masuk sini 1");
        photo.forEach((item, i) => {
          formData.append("image_multi[]", item.file, item.file.type);
        });
      }
      console.log("TESSSS", JSON.stringify(formData.get("image_multi[]")));
      // setButtonDisabled(false);
      try {
        let response = await axios({
          method: "post",
          url: `${CONFIG.BASE_URL}/api/distributor-partner/products/${dataProduk.id}`,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          data: formData,
        });
        let data = response.data;
        if (data !== "" && data["success"] == true) {
          setButtonDisabled(false);
          setPhoto(null);
          navigate(-1);
        } else {
          console.log("Gagal input komplain===>", data);
        }
      } catch (error) {
        setButtonDisabled(false);
        let error429 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 429";
        let errorNetwork =
          JSON.parse(JSON.stringify(error)).message === "Network Error";
        let error400 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 400";
        console.log(
          "Cek Error========================",
          JSON.parse(JSON.stringify(error)).message
        );
        if (error429) {
          setAlertServerSibuk(true);
        } else if (errorNetwork) {
          setAlertInet(true);
        } else if (error400) {
          localStorage.removeItem("token");
          navigate("/dashboardhome");
        }
      }
    } else {
      if (photo === null) {
        setAlertData("Harap menambah foto produk");
        setButtonDisabled(false);
        setOpen(true);
      } else if (name == "") {
        setAlertData("Harap mengisi nama produk");
        setButtonDisabled(false);
        setOpen(true);
      } else if (description == "") {
        setAlertData("Harap mengisi deskripsi produk");
        setButtonDisabled(false);
        setOpen(true);
      } else if (harga_ritel_gt == "") {
        setAlertData("Harap mengisi harga produk");
        setButtonDisabled(false);
        setOpen(true);
      } else if (!reg.test(harga_ritel_gt)) {
        setAlertData("Harap mengisi angka saja di dalam harga produk");
        setButtonDisabled(false);
        setOpen(true);
      } else if (category_id == "") {
        setAlertData("Harap memilih pilihan kategori produk");
        setButtonDisabled(false);
        setOpen(true);
      } else if (satuan_online == "") {
        setAlertData("Harap mengisi satuan berat jenis produk");
        setButtonDisabled(false);
        setOpen(true);
      } else if (stock == "") {
        setAlertData("Harap mengisi stok produk");
        setButtonDisabled(false);
        setOpen(true);
      } else if (min_pembelian == "") {
        setAlertData("Harap mengisi minimal pembelian produk");
        setButtonDisabled(false);
        setOpen(true);
      } else {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("category_id", category_id);
        formData.append("description", description);
        formData.append("stock", stock);
        formData.append("satuan_online", satuan_online);
        formData.append("status", isEnabled ? 1 : 0);
        formData.append("harga_ritel_gt", harga_ritel_gt);
        formData.append("min_pembelian", min_pembelian);
        if (photo != null) {
          console.log("masuk sini 1");
          if (photo?.length > 0) {
            console.log("masuk sini 2");
            photo.forEach((item, i) => {
              formData.append("image_multi[]", item.file, item.file.type);
            });
          } else {
            console.log("masuk sini 3");
            formData.append("image_multi", photo[0].file, photo[0].file.type);
          }
        }
        // setButtonDisabled(false);
        try {
          console.log("masuk post tambah produk");
          let response = await axios({
            method: "post",
            url: `${CONFIG.BASE_URL}/api/distributor-partner/products/store`,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            data: formData,
          });
          let data = response.data;
          if (data !== "" && data["success"] == true) {
            setButtonDisabled(false);
            setPhoto(null);
            navigate(-1);
          } else {
            console.log("Gagal input komplain===>", data);
          }
        } catch (error) {
          setButtonDisabled(false);
          let error429 =
            JSON.parse(JSON.stringify(error)).message ===
            "Request failed with status code 429";
          let errorNetwork =
            JSON.parse(JSON.stringify(error)).message === "Network Error";
          let error400 =
            JSON.parse(JSON.stringify(error)).message ===
            "Request failed with status code 400";
          console.log(
            "Cek Error========================",
            JSON.parse(JSON.stringify(error)).message
          );
          if (error429) {
            setAlertServerSibuk(true);
          } else if (errorNetwork) {
            setAlertInet(true);
          } else if (error400) {
            localStorage.removeItem("token");
            navigate("/dashboardhome");
          }
        }
      }
    }
  };

  const onChangePhoto = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    let temp = addUpdateIndex.map((image) => {
      return imageList[image];
    });
    // console.log("imageList", temp);
    setPhoto(temp);
  };

  return (
    <div className="tambahProdukDistributorContainer">
      <Dialog
        alertData={alertData}
        alertJudul={alertJudul}
        handleClose={handleClose}
        open={open}
      />
      <Header
      titleHeader={ubahProduk ? "Ubah Produk" : "Tambah Produk"}
      />
      <div className="tambahProdukDistributorList">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <p
            style={{
              fontSize: "15px",
              color: "#000",
              fontFamily: "Lato-Medium",
            }}
          >
            {"Foto produk"}
          </p>
          <div>
            {ubahProduk ? (
              <ImageUploading
                multiple
                value={photo}
                onChange={onChangePhoto}
                // maxNumber={maxNumber}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <div
                    style={{ width: "100%" }}
                    className="complaintCreateFooterUploadLampiran"
                  >
                    <p
                      style={{
                        fontSize: "2vh",
                        fontFamily: "Lato-Medium",
                        color: "#529F45",
                      }}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      {"Tambah foto"}
                    </p>
                  </div>
                )}
              </ImageUploading>
            ) : photo ? (
              <ImageUploading
                multiple
                value={photo}
                onChange={onChangePhoto}
                // maxNumber={maxNumber}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <div
                    style={{ width: "100%" }}
                    className="complaintCreateFooterUploadLampiran"
                  >
                    <p
                      style={{
                        fontSize: "2vh",
                        fontFamily: "Lato-Medium",
                        color: "#529F45",
                      }}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      {"Ganti foto"}
                    </p>
                  </div>
                )}
              </ImageUploading>
            ) : (
              <ImageUploading
                multiple
                value={photo}
                onChange={onChangePhoto}
                // maxNumber={maxNumber}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <div
                    style={{ width: "100%" }}
                    className="complaintCreateFooterUploadLampiran"
                  >
                    <p
                      style={{
                        fontSize: "2vh",
                        fontFamily: "Lato-Medium",
                        color: "#529F45",
                      }}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      {"Tambah foto"}
                    </p>
                  </div>
                )}
              </ImageUploading>
            )}
          </div>
        </div>
        {ubahProduk ? (
          <>
            {dataProduk.product_image?.length > 0 ? (
              <>
                {photo
                  ? photo.map((item, index) => {
                      return (
                        <div
                          style={{ height: "37vh", marginBottom: "10px" }}
                          className="complaintCreateFooterUploadHasil"
                        >
                          <img
                            src={item.data_url}
                            alt="complaintCreateItemQtyMinusImage"
                            className="complaintCreateItemQtyHasilImage"
                          />
                        </div>
                      );
                    })
                  : null}
                {dataProduk.product_image.map((item, index) => {
                  return (
                    <div
                      style={{ height: "37vh", marginBottom: "10px" }}
                      className="complaintCreateFooterUploadHasil"
                    >
                      <img
                        src={URL + item.path}
                        alt="complaintCreateItemQtyMinusImage"
                        className="complaintCreateItemQtyHasilImage"
                      />
                      <img
                        onClick={() => {
                          setAlertData(
                            "Apakah anda yakin ingin menghapus item ini?"
                          );
                          setOpen(true);
                          setIdPhoto(item.id);
                        }}
                        alt="menuIcon"
                        className="tambahProdukDistributorIcon"
                        src={IconTrash}
                      />
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="complaintCreateFooterUploadHasil">
                <img
                  src={DummyImage}
                  alt="complaintCreateItemQtyMinusImage"
                  className="complaintCreateItemQtyHasilImage"
                />
              </div>
            )}
          </>
        ) : photo ? (
          <>
            {photo.map((item, index) => {
              return (
                <div className="complaintCreateFooterUploadHasil">
                  <img
                    src={item.data_url}
                    alt="complaintCreateItemQtyMinusImage"
                    className="complaintCreateItemQtyHasilImage"
                  />
                </div>
              );
            })}
          </>
        ) : null}
        {ubahProduk ? (
          <div className="tambahProdukDistributorTextTitle">
            Ubah nama produk
          </div>
        ) : (
          <div className="tambahProdukDistributorTextTitle">
            Isi nama produk
          </div>
        )}
        <div className="subUserEdit">
          {ubahProduk ? (
            <input
              onChange={(e) =>
                setDataProduk((prevState) => ({
                  ...prevState,
                  name: e.target.value,
                }))
              }
              className="userEditInput"
              placeholder="Nama Produk"
              value={dataProduk.name}
            />
          ) : (
            <input
              onChange={(e) => setName(e.target.value)}
              className="userEditInput"
              placeholder="Nama Produk"
              value={name}
            />
          )}
        </div>
        <hr></hr>
        {ubahProduk ? (
          <div className="tambahProdukDistributorTextTitle">
            Ubah deskripsi produk
          </div>
        ) : (
          <div className="tambahProdukDistributorTextTitle">
            Isi deskripsi produk
          </div>
        )}
        <div className="subUserEdit">
          {ubahProduk ? (
            <input
              onChange={(e) =>
                setDataProduk((prevState) => ({
                  ...prevState,
                  description: e.target.value,
                }))
              }
              className="userEditInput"
              placeholder="Deskripsi Produk"
              value={dataProduk.description}
            />
          ) : (
            <input
              onChange={(e) => setDescription(e.target.value)}
              className="userEditInput"
              placeholder="Deskripsi Produk"
              value={description}
            />
          )}
        </div>
        <hr></hr>
        {ubahProduk ? (
          <div className="tambahProdukDistributorTextTitle">
            Ubah harga produk
          </div>
        ) : (
          <div className="tambahProdukDistributorTextTitle">
            Tentukan harga produk
          </div>
        )}
        <div className="subUserEdit">
          <p className="tambahProdukDistributorTextRp">{"Rp  "}</p>
          {ubahProduk ? (
            <input
              onChange={(e) => {
                let dataTemp = { ...dataProduk };
                dataTemp.price.harga_ritel_gt = e.target.value;
                setDataProduk(dataTemp);
              }}
              type="number"
              className="userEditInput"
              placeholder="Harga Produk"
              value={dataProduk.price?.harga_ritel_gt}
            />
          ) : (
            <input
              onChange={(e) => setHarga_ritel_gt(e.target.value)}
              type="number"
              className="userEditInput"
              placeholder="Harga Produk"
              value={harga_ritel_gt}
            />
          )}
        </div>
        <hr></hr>
        {ubahProduk ? (
          <div className="tambahProdukDistributorTextTitle">
            Ubah kategori produk
          </div>
        ) : (
          <div className="tambahProdukDistributorTextTitle">
            Tentukan kategori produk
          </div>
        )}
        <div className="subUserEdit">
          {ubahProduk ? (
            <select
              onChange={(e) => {
                if (e.target.value !== "Pilih Kategori") {
                  setDataProduk((prevState) => ({
                    ...prevState,
                    category_id: e.target.value,
                  }));
                }
              }}
              className="complaintCreateItemSelect"
              placeholder="tes"
            >
              {allCategory.map((item, index) => {
                if (dataProduk.category_id === item.id) {
                  return (
                    <option key={index} value={item.category}>
                      {item.name}
                      {" ( pilihan )"}
                    </option>
                  );
                }
              })}
              {allCategory.map((item, index) => {
                return (
                  <option key={index} value={item.category}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          ) : (
            <select
              onChange={(e) => {
                if (e.target.value !== "Pilih Kategori") {
                  setCategory_id(e.target.value);
                }
              }}
              className="complaintCreateItemSelect"
              placeholder="tes"
            >
              <option value={"Pilih Kategori"}>Pilihan</option>
              {allCategory.map((item, index) => {
                return (
                  <option key={index} value={item.category}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          )}
        </div>
        <hr></hr>
        {ubahProduk ? (
          <div className="tambahProdukDistributorTextTitle">
            Ubah satuan jenis produk
          </div>
        ) : (
          <div className="tambahProdukDistributorTextTitle">
            Tentukan satuan jenis produk
          </div>
        )}
        <div className="subUserEdit">
          {ubahProduk ? (
            <input
              onChange={(e) =>
                setDataProduk((prevState) => ({
                  ...prevState,
                  satuan_online: e.target.value,
                }))
              }
              className="userEditInput"
              placeholder="Satuan Jenis"
              value={dataProduk.satuan_online}
            />
          ) : (
            <input
              onChange={(e) => setSatuan_online(e.target.value.toUpperCase())}
              className="userEditInput"
              placeholder="Satuan Jenis"
              value={satuan_online}
            />
          )}
        </div>
        <hr></hr>
        {ubahProduk ? (
          <div className="tambahProdukDistributorTextTitle">Ubah Stok</div>
        ) : (
          <div className="tambahProdukDistributorTextTitle">Stok</div>
        )}
        <div className="subUserEdit">
          {ubahProduk ? (
            <input
              onChange={(e) =>
                setDataProduk((prevState) => ({
                  ...prevState,
                  stock: e.target.value,
                }))
              }
              type="number"
              className="userEditInput"
              placeholder="Stok Tersedia"
              value={dataProduk.stock?.toString()}
            />
          ) : (
            <input
              onChange={(e) => setStock(e.target.value)}
              type="number"
              className="userEditInput"
              placeholder="Stok Tersedia"
              value={stock}
            />
          )}
        </div>
        <hr></hr>
        {ubahProduk ? (
          <div className="tambahProdukDistributorTextTitle">
            Ubah Minimal pesanan
          </div>
        ) : (
          <div className="tambahProdukDistributorTextTitle">
            Minimal pesanan
          </div>
        )}
        <div className="subUserEdit">
          {ubahProduk ? (
            <input
              onChange={(e) =>
                setDataProduk((prevState) => ({
                  ...prevState,
                  min_pembelian: e.target.value,
                }))
              }
              type="number"
              className="userEditInput"
              placeholder="Pemesanan Minimum"
              value={dataProduk.min_pembelian}
            />
          ) : (
            <input
              onChange={(e) => setMin_pembelian(e.target.value)}
              type="number"
              className="userEditInput"
              placeholder="Pemesanan Minimum"
              value={min_pembelian}
            />
          )}
        </div>
        <hr></hr>
        {ubahProduk ? (
          <div className="tambahProdukDistributorTextTitle">
            Ubah Status produk
          </div>
        ) : (
          <div className="tambahProdukDistributorTextTitle">Status produk</div>
        )}
        <div className="subUserEdit">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <p className="tambahProdukDistributorInfo">
              {"Jika status aktif, berarti produkmu dapat dicari pembeli"}
            </p>
            {ubahProduk ? (
              <Switch
                checked={dataProduk.status == 1 ? true : false}
                onChange={toggleSwitch}
                inputProps={{ "aria-label": "controlled" }}
                color="success"
              />
            ) : (
              <Switch
                checked={isEnabled}
                onChange={toggleSwitch}
                inputProps={{ "aria-label": "controlled" }}
                color="success"
              />
            )}
          </div>
        </div>
        <hr></hr>
        {buttonDisabled ? (
          <button className="tambahProdukDistributorKirim">
            {ubahProduk ? (
              <p className="tambahProdukDistributorTextKirim">{"Simpan"}</p>
            ) : (
              <p className="tambahProdukDistributorTextKirim">{"Kirim"}</p>
            )}
          </button>
        ) : (
          <button onClick={postProduk} className="tambahProdukDistributorKirim">
            {ubahProduk ? (
              <p className="tambahProdukDistributorTextKirim">{"Simpan"}</p>
            ) : (
              <p className="tambahProdukDistributorTextKirim">{"Kirim"}</p>
            )}
          </button>
        )}
      </div>
      <Loading loadingApi={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TambahProdukDistributor);
