import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/images/IconLogo.webp";
import IconPhone from "../assets/images/Phone.webp";
import "./ForgetPassword.css";
import axios from "axios";
import CONFIG from "../config/config";
import { useDispatch } from "react-redux";
import { userAction, screenAction } from "../actions";
import CircularProgress from "@mui/material/CircularProgress";
import IconUser from "../assets/images/User.webp";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

export const ForgetPassword = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [customer_code, setCustomer_code] = useState("");
  const [loadingApi, setLoadingApi] = useState(false);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  async function sendOTPWA() {
    const formData = {
      phone: phone,
      customer_code: customer_code,
    };
    setLoadingApi(true);
    try {
      let response = await axios.post(
        `${CONFIG.BASE_URL}/api/otp/no-auth/wa`,
        formData
        // {
        //   headers: {Authorization: `Bearer ${this.props.token}`},
        // },
      );
      // console.log(response)
      const data = response.data;
      if (data !== "" && data["success"] == true) {
        console.log("HASIL SEND OTP", data.data);
        dispatch(screenAction("WAOTPFORGET", "screenName"));
        dispatch(userAction(formData, "dataUser"));
        setLoadingApi(false);
        navigate("/send-otp");
      } else {
        alert("Gagal memproses data " + data.message);
        return false;
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        // navigate('/home')
      }
    }
  }

  async function sendOTPSMS() {
    const formData = {
      phone: phone,
      customer_code: customer_code,
    };
    setLoadingApi(true);
    try {
      let response = await axios.post(
        `${CONFIG.BASE_URL}/api/otp/no-auth/sms`,
        formData
        // {
        //     headers: {Authorization: `Bearer ${this.props.token}`},
        // }
      );
      // console.log(response)
      const data = response.data;
      if (data !== "" && data["success"] == true) {
        console.log("HASIL SEND OTP", data.data);
        dispatch(screenAction("SMSOTPFORGET", "screenName"));
        dispatch(userAction(formData, "dataUser"));
        setLoadingApi(false);
        navigate("/send-otp");
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        // navigate('/home')
      }
    }
  }

  return (
    <div className="forgetPasswordContainer">
      <img src={Logo} alt="forgetPasswordLogo" className="forgetPasswordLogo" />
      <h1 className="forgetPasswordJudul">{"Pilih Metode Verifikasi"}</h1>
      <p className="forgetPasswordDescription">
        Silahkan masukkan nomor telepon yang terdaftar
      </p>
      <div className="forgetPasswordContainerInput">
        <img
          src={IconPhone}
          alt="forgetPasswordIconUser"
          className="forgetPasswordIconUser"
        />
        <input
          onChange={(e) => setPhone(e.target.value)}
          className="forgetPasswordTextInput"
          placeholder="Nomor Telepon"
          type="number"
        />
      </div>
      <p className="forgetPasswordDescription">
        Silahkan masukkan kode kustomer yang terdaftar
      </p>
      <div className="forgetPasswordContainerInput">
        <img
          src={IconUser}
          alt="forgetPasswordIconUser"
          className="forgetPasswordIconUser"
        />
        <input
          onChange={(e) => setCustomer_code(e.target.value)}
          className="forgetPasswordTextInput"
          placeholder="kode Kustomer"
        />
      </div>
      <p className="forgetPasswordDescription">
        Pilih salah satu metode dibawah ini untuk mendapatkan kode verifikasi
      </p>
      <div className="forgetPasswordButton">
        <button
          className="forgetPasswordButtonSMS"
          onClick={() => sendOTPSMS()}
        >
          <p className="forgetPasswordTextButtonOK">{"SMS"}</p>
        </button>
        <button className="forgetPasswordButtonWA" onClick={() => sendOTPWA()}>
          <p className="forgetPasswordTextButtonOK">{"Whatsapp"}</p>
        </button>
      </div>
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
