import React, { useEffect, useState } from "react";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import Header from "../components/HeaderDistributor";
import Navbar from "../components/Navbar";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import {
  screenAction,
  paramsAction,
  produkAction,
  promoAction,
} from "../actions";
import { useDispatch } from "react-redux";
import Dialog from "../components/Dialog";
import Loading from "../components/Loading";
import InfiniteScroll from "react-infinite-scroll-component";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import IconBack from "../assets/images/backArrow.webp";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import "./DiskusiProdukDistributor.css";
import ReactStars from "react-rating-stars-component";

export default function DiskusiProdukDistributor() {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  // const [produkDesc, setProdukDesc] = useState({});
  const [images, setImages] = useState([]);
  const { produkDesc } = useSelector((state) => state.ProdukReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const [
    onEndReachedCalledDuringMomentum,
    setOnEndReachedCalledDuringMomentum,
  ] = useState(false);
  const [loadingApi, setLoadingApi] = useState(true);
  const [open, setOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [alertTambahan, setAlertTambahan] = useState("");
  const [alertJudul, setAlertJudul] = useState("");
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);
  const location = useLocation();
  const diskusi = location.state.diskusi;

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  //load more data
  async function getMoreData() {
    console.log("cekk", !onEndReachedCalledDuringMomentum);
    console.log("cekk", page);
    console.log("cekk", maxPage);
    if (!onEndReachedCalledDuringMomentum && page < maxPage) {
      console.log("masuk load");
      setPage(page + 1);
      setLoadingMore(page < maxPage);
      console.log("ini ketiga", page, loadingMore);
    }
    setOnEndReachedCalledDuringMomentum(page >= maxPage);
  }

  function handleRefresh() {
    setPage(1);
    setRefreshing(true);
    // getDataLoadAll();
  }

  function handleLoadMore() {
    console.log("cekkkkk saiki", !loadingMore);
    if (!loadingMore) return null;
    return (
      <div>
        <CircularProgress color="success" />
      </div>
    );
  }

  const handleClose = async (e) => {
    setOpen(false);
    if (alertData == "Waktu sesi anda sudah berakhir") {
      navigate("/login");
    }
  };

  useEffect(() => {
    setLoadingApi(false);
    getProduk();
  }, []);

  async function getProduk() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/distributor-partner/products/${id}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      dispatch(produkAction(data, "produkDesc"))
      setImages(data.product_image);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }
  console.log("token", produkDesc);
  return (
    <div className="homeContainer">
      <Dialog
        alertData={alertData}
        alertJudul={alertJudul}
        handleClose={handleClose}
        open={open}
      />
      <Header titleHeader="Diskusi" />
      <div style={{ marginTop: "28px", padding: "0px 10px" }}>
        <div
          style={{
            display: "flex",
            margin: "10px 0px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "100px", height: "100px", marginRight: "10px" }}>
            {images.length > 0}
            <img
              style={{ width: "100%", height: "100%",borderRadius:"13px" }}
              alt="diskusiProdukDistributorImage"
              src={CONFIG.BASE_URL + images[0]?.path}
            />
          </div>
          <div>
            <p className="produkDeskripsiDistributorDiskusiMessage">
              {produkDesc.name}
            </p>
            <p className="produkDeskripsiDistributorDiskusiMessage">
              {produkDesc.price.harga_ritel_gt}
            </p>
            <ReactStars
              size={20}
              value={parseInt(produkDesc.review[0]?.avg_rating) || 0}
              edit={false}
            />
          </div>
        </div>
        <hr />
        <div className="diskusiProdukDistributorScroll">
          {diskusi?.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  navigate(
                    "/diskusi/input?id=" + id + "&idDiskusi=" + item.id,
                    { state: { diskusi: item } }
                  );
                }}
                className="produkDeskripsiDistributorDiskusiContainerMessage"
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "15px 0px",
                  }}
                >
                  <div className="produkDeskripsiDistributorDiskusi">
                    <img
                      alt="produkDeskripsiDistributorDiskusiImage"
                      src={CONFIG.BASE_URL + item.photo}
                    />
                  </div>
                  <p className="produkDeskripsiDistributorDiskusiNamaUser">
                    {item.name}
                  </p>
                  <p className="produkDeskripsiDistributorDiskusiTanggal">
                    {moment(item.created_at).format("MMM YYYY")}
                  </p>
                </div>
                <div>
                  <p className="produkDeskripsiDistributorDiskusiMessage">
                    {item.message}
                  </p>
                </div>
                {item.reply?.length > 0 ? (
                  <div className="produkDeskripsiDistributorDiskusiContainerReply">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "15px 0px",
                      }}
                    >
                      <div className="produkDeskripsiDistributorDiskusi">
                        <img
                          alt="produkDeskripsiDistributorDiskusiImage"
                          src={CONFIG.BASE_URL + item.reply[0].photo}
                        />
                      </div>
                      {item.reply[0]?.admin_id == item.reply[0]?.user_id ? (
                        <p className="produkDeskripsiDistributorDiskusiNamaToko">
                          {"Penjual"}
                        </p>
                      ) : (
                        <p className="produkDeskripsiDistributorDiskusiNamaUser">
                          {item.reply[0].name}
                        </p>
                      )}
                      <p className="produkDeskripsiDistributorDiskusiTanggal">
                        {moment(item.reply[0]?.created_at).format("MMM YYYY")}
                      </p>
                    </div>
                    <div>
                      <p className="produkDeskripsiDistributorDiskusiMessage">
                        {item.reply[0]?.message}
                      </p>
                    </div>
                    {item.reply?.length > 1 ? (
                      <div style={{ marginTop: "5px" }}>
                        <p className="produkDeskripsiDistributorDiskusiMessageLainnya">
                          {item.reply.length - 1} jawaban lainnya
                        </p>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
      <Loading loadingApi={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
}
