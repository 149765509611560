import { SET_PRODUK } from "../../actions";
const initialState = {
  produkDesc: [],
  shop_total: 0,
  hasilPromo: [],
  hasilPromoNotif: [],
  hargaPromo: [],
  shop: {},
  partnerShop: {},
  kredit: [],
  totalResult: 0,
  pengirimanOrder: "",
  promoOrder: "",
  totalDiscount: "",
  promoResult: "",
  produkSubscribe: [],
  ratingProduk: [],
  ratingDetail: [],
  rewardDetail: [],
};

const produk = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUK:
      return {
        ...state,
        [action.inputType]: action.inputValue,
      };
    default:
      return state;
  }
};

export default produk;
