import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import IconBack from "../assets/images/backArrow.webp";
import Product1 from "../assets/dummy/product1.jpg";
import Product2 from "../assets/dummy/product2.jpg";
import Product3 from "../assets/dummy/product3.jpg";
import "./ListSubscribe.css";
import { useSelector } from "react-redux";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import CircularProgress from "@mui/material/CircularProgress";
import { screenAction, paramsAction, produkAction } from "../actions";
import { useDispatch } from "react-redux";
import Dialog from "../components/Dialog";
import InfiniteScroll from "react-infinite-scroll-component";
import NumberFormat from "react-number-format";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Header from "../components/HeaderDistributor";

export const ListSubscribe = (props) => {
  const queryParams = new URLSearchParams(window.location.search);
  const screenName  = queryParams.get("list")
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [listSubscribe, setListSubscribe] = useState([]);
  const [loadingApi, setLoadingApi] = useState(true);
  const [open, setOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [alertTambahan, setAlertTambahan] = useState("");
  const [alertJudul, setAlertJudul] = useState("");
  const [dataDelete, setDataDelete] = useState(null);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const [refreshing, setRefreshing] = useState(false);
  const [
    onEndReachedCalledDuringMomentum,
    setOnEndReachedCalledDuringMomentum,
  ] = useState(false);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);


  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  useEffect(() => {
    if (page) {
      getDataSubscribe();
    }
    return () => {
      setOnEndReachedCalledDuringMomentum(false);
    };
  }, [page, loadingMore]);

  //data subscribe
  async function getDataSubscribe() {
    try {
      let response;
      if (screenName == "monthSubs") {
        response = await axios.get(
          `${CONFIG.BASE_URL}/api/subscribes?type=month&page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      } else if (screenName == "weekSubs") {
        response = await axios.get(
          `${CONFIG.BASE_URL}/api/subscribes?type=2_week&page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      }
      const data = response.data.data;
      setListSubscribe(
        page === 1 ? data.data : [...listSubscribe, ...data.data]
      );
      setLoadingMore(data.last_page > page);
      setMaxPage(data.last_page);
      setLoadingApi(false);
      // if (listSubscribe.length == 0) {
      //   navigate(-1);
      // }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  //load more data
  async function getMoreData() {
    if (!onEndReachedCalledDuringMomentum && page < maxPage) {
      await setPage(page + 1);
      setLoadingMore(page < maxPage);
    }
    setOnEndReachedCalledDuringMomentum(page >= maxPage);
  }

  function handleRefresh() {
    setPage(1);
    setRefreshing(true);
  }

  function handleLoadMore() {
    if (!loadingMore) return null;
    return (
      <div>
        <CircularProgress color="success" />
      </div>
    );
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async (e) => {
    setOpen(false);
    if (
      e == "ok" &&
      dataDelete != null &&
      alertData == "Apakah yakin ingin menghapus item ini?"
    ) {
      try {
        let response = await axios.delete(
          `${CONFIG.BASE_URL}/api/subscribe/${dataDelete.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const data = response.data;
        if (data["success"] == true) {
          setDataDelete(null);
          getDataSubscribe();
        } else {
          console.log("data gagal dihapus===>", data);
        }
      } catch (error) {
        let error429 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 429";
        let errorNetwork =
          JSON.parse(JSON.stringify(error)).message === "Network Error";
        let error400 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 400";
        console.log(
          "Cek Error========================",
          JSON.parse(JSON.stringify(error)).message
        );
        if (error429) {
          setAlertServerSibuk(true);
        } else if (errorNetwork) {
          setAlertInet(true);
        } else if (error400) {
          localStorage.removeItem("token");
          navigate("/home");
        }
      }
    } else {
      setDataDelete(null);
      getDataSubscribe();
    }
  };

  return (
    <div className="listSubscribeContainer">
      <Dialog
        alertData={alertData}
        alertJudul={alertJudul}
        handleClose={handleClose}
        open={open}
      />
      {/* <div className="listSubscribeBackContainer">
        <div onClick={() => navigate(-1)} className="listSubscribeButtonBack">
          <img
            src={IconBack}
            alt="listSubscribeLogo"
            className="listSubscribeBack"
          />
          <div className="listSubscribBackText">
            {screenName == "monthSubs"
              ? "Daftar Langganan Bulanan"
              : "Daftar Langganan Mingguan"}
          </div>
        </div>
      </div> */}
      <Header 
      titleHeader="Langganan mingguan"
      />
      <InfiniteScroll
        dataLength={listSubscribe.length}
        next={() => getMoreData()}
        hasMore={true}
        loader={() => handleLoadMore()}
        // refreshFunction={handleRefresh}
        // pullDownToRefresh
        // pullDownToRefreshThreshold={50}
        // pullDownToRefreshContent={handleLoadMore}
      >
        {listSubscribe.map((item, index) => {
          return (
            <div key={index} className="listSubscribeMain">
              <div className="listSubscribeImage">
                {item.product.image ? (
                  <img
                    src={CONFIG.BASE_URL + item.product.image}
                    alt="listSubscribeImage"
                    className="listSubscribeImage"
                  />
                ) : (
                  <img
                    src={DummyImage}
                    alt="listSubscribeImage"
                    className="listSubscribeImage"
                  />
                )}
              </div>
              <div className="listSubscribeInform">
                <div className="listSubscribeProductName">
                  {item.product.name}
                </div>
                <div className="listSubscribePriceView">
                  {item.half ? (
                    <NumberFormat
                      value={
                        Math.round(item.product.price.harga_ritel_gt / 2) ?? "0"
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"Rp "}
                      renderText={(value) => (
                        <div className="listSubscribeProductPrice">
                          {value || 0}
                        </div>
                      )}
                    />
                  ) : (
                    <NumberFormat
                      value={
                        Math.round(item.product.price.harga_ritel_gt) ?? "0"
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"Rp "}
                      renderText={(value) => (
                        <div className="listSubscribeProductPrice">
                          {value || 0}
                        </div>
                      )}
                    />
                  )}
                  {item.half ? (
                    <div className="listHalf">
                      {"  ( "}
                      {item.product.konversi_sedang_ke_kecil / 2 +
                        " " +
                        item.product.kecil.charAt(0) +
                        item.product.kecil.slice(1).toLowerCase()}
                      {" )"}
                    </div>
                  ) : null}
                </div>
                {item.status == 1 ? (
                  <div className="listSubscribeProductStatus">
                    Status: Aktif
                  </div>
                ) : (
                  <div className="listSubscribeProductStatus">
                    Status: Tidak Aktif
                  </div>
                )}
              </div>
              <div className="listSubscribeButton">
                <div className="detail">
                  <button
                    onClick={() => {
                      dispatch(produkAction(item, "produkDesc"));
                      navigate("/subscribe/detail?id="+item.id);
                    }}
                    className="button"
                  >
                    <p className="text">{"Detail"}</p>
                  </button>
                </div>
                <div className="hapus">
                  <button
                    onClick={() => {
                      setAlertData("Apakah yakin ingin menghapus item ini?");
                      setAlertJudul("Pemberitahuan");
                      setDataDelete(item);
                      handleClickOpen();
                    }}
                    className="button"
                  >
                    <p className="text">{"Hapus"}</p>
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </InfiniteScroll>
      <Loading loadingApi={loadingApi}/>
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ListSubscribe);
