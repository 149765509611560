import React, { useEffect, useState } from "react";
import "./MissionDetail.css";
import SimpleHeader from "../components/HeaderDistributor";
import IconListMisi from "../assets/images/IconListMisi.webp";
import IconAvailable from "../assets/images/availableUntil.webp";
import axios from "axios";
import CONFIG from "../config/config";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import TotalTask from "../assets/images/Detail-Misi-Total-Task.webp";
import Reward from "../assets/images/rewardMission.webp";
import Available from "../assets/images/Detail-Misi-Available.webp";

const MissionDetail = () => {
  const [loadingApi, setLoadingApi] = useState(true);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const idDetail = queryParams.get("idMission");
  const indexDetail = queryParams.get("index");
  const [detailMission, setDetialMission] = useState("");

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  useEffect(() => {
    if (loadingApi) {
      getDetailMission();
      setTimeout(() => setLoadingApi(false), 10000);
    }
    return () => {};
  }, [loadingApi]);

  async function getDetailMission() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/mission/${idDetail}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      setLoadingApi(false);
      setDetialMission(data);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========NOTIF================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        // navigate('/home')
      }
    }
  }
  return (
    <div className="missionContainer">
      <SimpleHeader titleHeader="Detail Misi" />
      {loadingApi === true ? null : (
        <div className="missionContent">
          <div className="card-container-detail-mission">
            <div
              className={
                indexDetail % 3 === 0
                  ? "div-detail-mission-card-yellow"
                  : indexDetail % 3 === 1
                  ? "div-detail-mission-card-blue"
                  : "div-detail-mission-card-green"
              }
            >
              <div className="card-text-detail-mission">
                <div>{detailMission?.name}</div>
                <div className="card-desk-detail-mission">
                  {detailMission?.description}
                </div>
                <div className="card-reward-detail-mission">
                  Dapatkan {detailMission?.reward} poin
                </div>
                <div className="card-date-detail-mission">
                  <img
                    className="icon-available-detail-mission"
                    src={IconAvailable}
                  />
                  Available Until : {detailMission?.end_date}
                </div>
              </div>
              <div className="card-icon-detail-mission">
                <img className="icon-detail-mission" src={IconListMisi} />
              </div>
            </div>
          </div>
          <div className="deskripsi-detail-mission">
            Deskripsi
            <div className="text-deskripsi">{detailMission?.description}</div>
          </div>
          <div className="task-detail-mission">
            <div className="div-icon-detail-mission">
              <img className="icon-dettial-mission" src={Reward} />
              <div className="icon-task-title">Reward</div>
              <div className="icon-task-text">{detailMission?.reward} </div>
            </div>
            <div className="div-icon-detail-mission" style={{ flex: 2 }}>
              <img className="icon-dettial-mission" src={TotalTask} />
              <div className="icon-task-title">Total Task</div>
              <div className="icon-task-text">
                {detailMission?.finish_task + " / " + detailMission?.total_task}{" "}
              </div>
            </div>
            <div className="div-icon-detail-mission">
              <img className="icon-dettial-mission" src={Available} />
              <div className="icon-task-title">Avalaible Until</div>
              <div className="icon-task-text">{detailMission?.end_date} </div>
            </div>
          </div>
          <div className="div-list-task">
            Tugas Anda
            {detailMission?.tasks?.map((item, index) => {
              return (
                <div key={index} className="div-task-card">
                  <div
                    className={
                      item?.is_finish == true ? "task-icon-active" : "task-icon"
                    }
                  >
                    {index + 1}
                  </div>
                  <div className="task-container">
                    <div className="task-title">Tugas {index + 1}</div>
                    <div className="task-text">{item?.name}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <Loading loadingApi={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

export default MissionDetail;
