import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import "./SubscribeEdit.css";
import IconChat from "../assets/images/ChatThumbnail.webp";
import IconPlus from "../assets/images/Plus.webp";
import IconMinus from "../assets/images/Minus.webp";
import { useSelector } from "react-redux";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import { screenAction, paramsAction } from "../actions";
import { useDispatch } from "react-redux";
import Stack from "@mui/material/Stack";
import Dialog from "../components/Dialog";
import Calendar from "../components/Calendar";
import moment from "moment";
import Alert from "@mui/material/Alert";
import Header from "../components/HeaderDistributor";
import Loading from "../components/Loading";

export const SubscribeEdit = (props) => {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const [produkDesc, setProdukDesc] = useState({});
  console.log(produkDesc);
  const screenName = queryParams.get("screen");
  console.log(screenName);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadingApi, setLoadingApi] = useState(true);
  const [addData, setAddData] = useState({});
  const [time, setTime] = useState("");
  const [notes, setNotes] = useState("");
  const [status, setStatus] = useState("");
  const [half, setHalf] = useState(0);
  const [jumlah, setJumlah] = useState(1);
  const [notifOrders, setNotifOrders] = useState(0);
  const [notifMessages, setNotifMessages] = useState(0);
  const [notifComplaints, setNotifComplaints] = useState(0);
  const [notifBroadcast, setNotifBroadcast] = useState(0);
  const [notifSubscribe, setNotifSubscribe] = useState(0);
  const [countNotifCart, setCountNotifCart] = useState(0);
  const [showPickerDate, setShowPickerDate] = useState(false);
  const [date, setDate] = useState(new Date());
  const [minDate, setMinDate] = useState(new Date());
  const [open, setOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [alertTambahan, setAlertTambahan] = useState("");
  const [alertJudul, setAlertJudul] = useState("");
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  useEffect(() => {
    getNotifAll();
    getDataSubscribe();
    screenName == "Edit Subscribe" ? setLoadingApi(true) : setLoadingApi(false);
    return () => {};
  }, []);

  async function postSubscribe() {
    if (time == "Pilih Waktu") {
      setAlertData("Harap memilih rentang waktu berlangganan, di pilih waktu");
      setAlertJudul("Pemberitahuan");
      handleClickOpen();
    } else if (date == "Pilih Tanggal") {
      setAlertData("Harap memilih di pilih waktu langganan");
      setAlertJudul("Pemberitahuan");
      handleClickOpen();
      // this.setState({dateSubscibe: ''});
    } else {
      try {
        let response;
        if (screenName == "Edit Subscribe") {
          console.log("masuk sini", produkDesc.id);
          if (half == "Setengah") {
            response = await axios.post(
              `${CONFIG.BASE_URL}/api/subscribe/${produkDesc.id}`,
              {
                product_id: produkDesc.product_id,
                qty: produkDesc.qty,
                time: time,
                notes: notes,
                status: status,
                start_at: moment(date).format("YYYY-MM-DD"),
                half: "1",
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            );
          } else {
            response = await axios.post(
              `${CONFIG.BASE_URL}/api/subscribe/${produkDesc.id}`,
              {
                product_id: produkDesc.product_id,
                qty: produkDesc.qty,
                time: time,
                notes: notes,
                status: status,
                start_at: moment(date).format("YYYY-MM-DD"),
                half: "0",
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            );
          }
        } else {
          console.log("no edit");
          if (produkDesc.half == "Setengah") {
            response = await axios.post(
              `${CONFIG.BASE_URL}/api/subscribe`,
              {
                product_id: produkDesc.id,
                qty: jumlah,
                time: time,
                notes: notes,
                status: status,
                start_at: moment(date).format("YYYY-MM-DD"),
                half: 1,
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            );
          } else {
            response = await axios.post(
              `${CONFIG.BASE_URL}/api/subscribe`,
              {
                product_id: produkDesc.id,
                qty: jumlah,
                time: time,
                notes: notes,
                status: status,
                start_at: moment(date).format("YYYY-MM-DD"),
                half: 0,
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            );
          }
        }
        let data = response.data;
        console.log("masuk data", response.data);
        if (data !== "" && data["success"] == true) {
          navigate("/subscribe");
        } else {
          console.log("gagal=====", response.data);
        }
      } catch (error) {
        let error429 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 429";
        let errorNetwork =
          JSON.parse(JSON.stringify(error)).message === "Network Error";
        let error400 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 400";
        console.log(
          "Cek Error========================",
          JSON.parse(JSON.stringify(error)).message
        );
        if (error429) {
          setAlertServerSibuk(true);
        } else if (errorNetwork) {
          setAlertInet(true);
        } else if (error400) {
          localStorage.removeItem("token");
          navigate("/home");
        }
      }
    }
  }

  function quantityHandler(action) {
    if (screenName == "Edit Subscribe") {
      let currentQty = parseInt(produkDesc.qty, 10);
      if (action == "more") {
        // console.log("tambah 1")
        currentQty += 1;
      } else if (action == "less" && currentQty > 1) {
        // console.log(" 2")
        currentQty -= 1;
      } else {
        // console.log("else")
        currentQty = 1;
      }
      setProdukDesc((prevState) => ({
        ...prevState,
        qty: currentQty,
      }));
    } else {
      if (action == "more") {
        // console.log("tambah 1")
        setJumlah(jumlah + 1);
      } else if (action == "less" && jumlah > 1) {
        // console.log(" 2")
        setJumlah(jumlah - 1);
      }
    }
  }

  function getQuantity(e) {
    console.log("masuk get quantity e", e.target.value);
    let hasilText = e.target.value; // this is the search text
    // if (inputQtySubs) {
    //   clearTimeout(inputQtySubs);
    // }
    let currentQty = produkDesc.qty;
    if (hasilText < 1) {
      currentQty = 1;
    } else {
      currentQty = hasilText;
    }
    setProdukDesc((prevState) => ({
      ...prevState,
      jumlah: currentQty,
    }));
  }

  async function getNotifAll() {
    console.log("cek hit notif");
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/notification-all`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      // console.log('DATA TEST', data.total_chat);
      setNotifOrders(data.total_order);
      setNotifMessages(data.total_chat);
      setNotifComplaints(data.total_complaint);
      setNotifSubscribe(data.total_subscribe);
      setNotifBroadcast(data.total_broadcast);
      setCountNotifCart(data.total_cart);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========NOTIF================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }
  async function getDataSubscribe() {
    try {
      let response;
      if (screenName === "Edit Subscribe") {
        response = await axios.get(
          `${CONFIG.BASE_URL}/api/subscribes/detail/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      } else {
        response = await axios.get(
          `${CONFIG.BASE_URL}/api/distributor-partner/products/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      }
      // console.log("response "+ JSON.stringify(response.data.data));
      const data = response.data.data;
      setProdukDesc(data);
      setLoadingApi(false);
      if (screenName === "Edit Subscribe") {
        setTime(data.time);
        setHalf(data.half);
        setStatus(data.status);
        setNotes(data.notes);
        const tgl = new Date(data.start_at);
        setDate(tgl);
      } else {
        console.log("Kosong");
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  function renderCountNotificationBadge() {
    try {
      let count = 0;
      let hasil = notifMessages;
      let hasil2 = notifSubscribe;
      let hasil3 = notifOrders;
      let hasil4 = notifComplaints;
      let hasil5 = notifBroadcast;
      count =
        count +
        parseInt(hasil) +
        parseInt(hasil2) +
        parseInt(hasil3) +
        parseInt(hasil4) +
        parseInt(hasil5);
      // console.log(count);
      if (count > 0) {
        return count;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
    }
  }

  function changeNotes(e){
    setProdukDesc((prevState) => ({
      ...prevState,
      notes: e.target.value,
    }))
    setNotes(e.target.value)
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  console.log(
    jumlah,
    time,
    notes,
    date,
    half,
    status,
    "produk",
    produkDesc
  );
  return (
    <div className="subscribeDetailContainer">
      <Dialog
        alertData={alertData}
        alertJudul={alertJudul}
        handleClose={handleClose}
        open={open}
      />
      <Header
        titleHeader={
          screenName === "Edit Subscribe"
            ? "Edit Langganan"
            : "Tambah Langganan"
        }
      />
      <div className="subscribeDetailContent">
        <div className="subscribeDetailImageContainer">
          {screenName == "Edit Subscribe" ? (
            <>
              {produkDesc.product?.image ? (
                <img
                  src={CONFIG.BASE_URL + produkDesc.product?.image}
                  alt="subscribeDetailImage"
                  className="subscribeDetailImage"
                />
              ) : (
                <img
                  src={DummyImage}
                  alt="subscribeDetailImage"
                  className="subscribeDetailImage"
                />
              )}
            </>
          ) : (
            <>
              {produkDesc.product_image?.length > 0 ? (
                <img
                  src={CONFIG.BASE_URL + produkDesc.product_image[0]?.path}
                  alt="subscribeDetailImage"
                  className="subscribeDetailImage"
                />
              ) : (
                <img
                  src={DummyImage}
                  alt="subscribeDetailImage"
                  className="subscribeDetailImage"
                />
              )}
            </>
          )}
          <div className="subscribeDetailChatPosition">
            <button className="subscribeDetailChatButton">
              <img
                src={IconChat}
                alt="subscribeDetailChatImage"
                className="subscribeDetailChatImage"
              />
            </button>
          </div>
        </div>
        <div className="subscribeDetailDetailContainer">
          <p className="subscribeDetailDetailTitle">Langganan</p>
          <p
            style={{
              textAlign: "left",
              marginTop: "3%",
              fontSize: "small",
              fontFamily: "Lato-Bold, sans-serif",
            }}
          >
            Produk yang dipesan
          </p>
          <div className="subscribeDetailDetailTextContainer">
            <p
              style={{ textAlign: "left", marginTop: "1%", marginBottom: "1%" }}
            >
              {produkDesc.name}
            </p>
          </div>
          <div className="subscribeDetailDetailTextContainer">
            <p className="subscribeEditDetailText">Jumlah</p>
            <div className="subscribeEditQtyContainer">
              <button
                onClick={() => quantityHandler("less")}
                className="subscribeEditItemQtyMinus"
              >
                <img
                  src={IconMinus}
                  alt="subscribeEditItemQtyMinusImage"
                  className="subscribeEditItemQtyMinusImage"
                />
              </button>
              <input
                onChange={(e) => getQuantity(e)}
                className="subscribeEditItemQtyInput"
                type="number"
                value={produkDesc.qty || jumlah}
              />
              <button
                onClick={() => quantityHandler("more")}
                className="subscribeEditItemQtyPlus"
              >
                <img
                  src={IconPlus}
                  alt="subscribeEditItemQtyPlusImage"
                  className="subscribeEditItemQtyPlusImage"
                />
              </button>
            </div>
          </div>
          <div className="subscribeDetailDetailTextContainer">
            <p className="subscribeEditDetailText">Waktu</p>
            <p className="subscribeDetailDetailData">
              <div className="inputBorder">
                <select onChange={(e) => setTime(e.target.value)}>
                  <option value={produkDesc.time}>
                    {produkDesc.time == "month"
                      ? "Per bulan"
                      : produkDesc.time == "2_week"
                      ? "Per 2 minggu"
                      : "Pilih Waktu"}
                  </option>
                  <option value="2_week">Per 2 minggu</option>
                  <option value="month">Per bulan</option>
                </select>
              </div>
            </p>
          </div>
          <div className="subscribeDetailDetailTextContainer">
            <p className="subscribeEditDetailText">Pilih Waktu Langganan</p>
            <p className="subscribeDetailDetailData">
              {screenName == "Edit Subscribe" ? (
                <div
                  onClick={() => setShowPickerDate(!showPickerDate)}
                  className="inputBorder"
                  style={{ textAlign: "center", fontSize: "15px" }}
                >
                  <p className="text">{moment(date).format("YYYY-MM-DD")}</p>
                </div>
              ) : (
                <div
                  onClick={() => setShowPickerDate(!showPickerDate)}
                  className="inputBorder"
                  style={{ textAlign: "center", fontSize: "15px" }}
                >
                  <p className="text">{moment(date).format("YYYY-MM-DD")||"Pilih tanggal"}</p>
                </div>
              )}
            </p>
          </div>
          <Calendar
            date={date}
            setDate={(date) => {
              setDate(date);

              setShowPickerDate(false);
            }}
            openCalendar={showPickerDate}
            minDate={minDate}
          />
          {screenName == "Edit Subscribe" ? (
            <>
              {produkDesc.product?.satuan_online ? (
                <div className="subscribeDetailDetailTextContainer">
                  <p className="subscribeEditDetailText">Satuan</p>
                  <p className="subscribeDetailDetailData">
                    <div className="inputBorder">
                      <select onChange={(e) => setHalf(e.target.value)}>
                        <option value={produkDesc.half}>
                          {produkDesc.half === 1
                            ? "Setengah ( " +
                              produkDesc.product.konversi_sedang_ke_kecil / 2 +
                              " " +
                              produkDesc.product.kecil.charAt(0) +
                              produkDesc.product.kecil.slice(1).toLowerCase() +
                              " )"
                            : "Full ( " +
                              produkDesc.product.konversi_sedang_ke_kecil +
                              " " +
                              produkDesc.product.kecil.charAt(0) +
                              produkDesc.product.kecil.slice(1).toLowerCase() +
                              " )"}
                        </option>
                        <option value={1}>
                          {"Setengah ( " +
                            produkDesc.product.konversi_sedang_ke_kecil / 2 +
                            " " +
                            produkDesc.product.kecil.charAt(0) +
                            produkDesc.product.kecil.slice(1).toLowerCase() +
                            " )"}
                        </option>
                        <option value={0}>
                          {"Full ( " +
                            produkDesc.product.konversi_sedang_ke_kecil +
                            " " +
                            produkDesc.product.kecil.charAt(0) +
                            produkDesc.product.kecil.slice(1).toLowerCase() +
                            " )"}
                        </option>
                      </select>
                    </div>
                  </p>
                </div>
              ) : (
                <p>Satuan</p>
              )}
            </>
          ) : (
            <>
              <div className="subscribeDetailDetailTextContainer">
                <p className="subscribeEditDetailText">Satuan</p>
                <p className="subscribeDetailDetailData">
                  <div className="inputBorder">
                    <select onChange={(e) => setHalf(e.target.value)}>
                      <option value={1}>
                        {"Setengah " + produkDesc.satuan_online}
                      </option>
                      <option value={0} selected>
                        {"Full " + produkDesc.satuan_online}
                      </option>
                    </select>
                  </div>
                </p>
              </div>
            </>
          )}
          {screenName == "Edit Subscribe" ? (
            <div className="subscribeDetailDetailTextContainer">
              <p className="subscribeEditDetailText">Status</p>
              <p className="subscribeDetailDetailData">
                <div className="inputBorder">
                  <select onChange={(e) => setStatus(e.target.value)}>
                    <option value={produkDesc.status}>
                      {produkDesc.status === "1" ? "Aktif" : "Tidak Aktif"}
                    </option>
                    <option value={"1"}>Aktif</option>
                    <option value={"0"}>Tidak Aktif</option>
                  </select>
                </div>
              </p>
            </div>
          ) : (
            <div className="subscribeDetailDetailTextContainer">
              <p className="subscribeEditDetailText">Status</p>
              <p className="subscribeDetailDetailData">
                <div className="inputBorder">
                  <select onChange={(e) => setStatus(e.target.value)}>
                    <option value={"1"}>Aktif</option>
                    <option value={"0"}>Tidak Aktif</option>
                  </select>
                </div>
              </p>
            </div>
          )}
          <div className="subscribeDetailDetailTextContainer">
            <p className="subscribeEditDetailText">Catatan</p>
          </div>
          <div
            className="inputBorderCatatan"
            style={{ textAlign: "center", fontSize: "15px" }}
          >
            {screenName == "Edit Subscribe" ? (
              <input
                onChange={(e) =>
                  changeNotes(e)
                }
                value={produkDesc.notes}
              />
            ) : (
              <input onChange={(e) => setNotes(e.target.value)} value={notes} />
            )}
          </div>
        </div>
      </div>
      <div className="subscribeEditNavbar">
        <button
          onClick={() => {
            dispatch(screenAction("produk", "screenName"));
            navigate("/produk");
          }}
          className="subscribeEditNavbarButtonLeft"
        >
          <p className="subscribeEditNavbarTextLeft">Cari Barang Lainnya</p>
        </button>
        <button
          onClick={postSubscribe}
          className="subscribeEditNavbarButtonRight"
        >
          <p
            className="subscribeEditNavbarTextRight"
            style={{ marginLeft: 10 }}
          >
            Selesai
          </p>
        </button>
      </div>
      <Loading loadingApi={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SubscribeEdit);
