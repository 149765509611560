import React, { useRef } from "react";
import useSlider from "../hooks/useSlider";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import "./Slider.css";

const Slider = ({ images }) => {
  const slideImage = useRef(null);
  const slideText = useRef(null);
  const { goToPreviousSlide, goToNextSlide } = useSlider(
    slideImage,
    slideText,
    images
  );
  const navigate = useNavigate();

  return (
    <div className="slider" ref={slideImage}>
      <div className="slider--content">
        <button onClick={goToPreviousSlide} className="slider__btn-left">
          <ArrowBackIcon fontSize="large" />
        </button>
        <div className="slider--feature">
          {/* <h1 className="feature--title">Dreaming</h1> */}
          <p ref={slideText} className="feature--text"></p>
          <button
            onClick={() => {
              console.log('masuk')
              localStorage.setItem("intro", "true");
              navigate("login");
            }}
            className="feature__btn"
          >
            {"Mulai"}
          </button>
        </div>
        <button onClick={goToNextSlide} className="slider__btn-right">
          <ArrowForwardIcon fontSize="large" />
        </button>
      </div>
    </div>
  );
};

export default Slider;
