import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import IconBack from "../assets/images/backArrow.webp";
import BannerPromo from "../assets/dummy/banner-2021-10-18-100603.jpg";
import BannerPromo1 from "../assets/dummy/banner-2021-10-18-100554.jpg";
import "./PromoCalendar.css";
import NumberFormat from "react-number-format";
import ReactStars from "react-rating-stars-component";
import Product1 from "../assets/dummy/product1.jpg";
import Product2 from "../assets/dummy/product2.jpg";
import Product3 from "../assets/dummy/product3.jpg";
import Product4 from "../assets/dummy/product4.jpg";
import Product5 from "../assets/dummy/product5.jpg";
import Product6 from "../assets/dummy/product6.jpg";
import { useSelector } from "react-redux";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import Dialog from "../components/Dialog";
import {
  produkAction,
  screenAction,
  orderAction,
  paramsAction,
} from "../actions";
import moment from "moment";
import IconNextAtas from "../assets/images/Next2.webp";
import IconCart from "../assets/images/KeranjangActive.webp";
import error404 from "../assets/images/404.webp";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

function NoData() {
  return (
    <div className="NoData">
      <img style={{ height: "50%", width: "100%" }} src={error404} />
    </div>
  );
}

export const RewardCalendar = (props) => {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const { screenName } = useSelector((state) => state.ScreenReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [reward, setReward] = useState([]);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [alertTambahan, setAlertTambahan] = useState("");
  const [alertJudul, setAlertJudul] = useState("");
  const [loadingApi, setLoadingApi] = useState(true);
  const [
    onEndReachedCalledDuringMomentum,
    setOnEndReachedCalledDuringMomentum,
  ] = useState(false);
  const [shoppingCartItems, setShoppingCartItems] = useState({
    product_id: "",
    brand_id: "",
    satuan_online: "",
    konversi_sedang_ke_kecil: "",
    qty_konversi: "",
    qty: 1,
    notes: "",
    price_apps: "",
  });
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  useEffect(() => {
    getReward();
  });

  useEffect(() => {
    if (page) {
      getReward();
    }

    return () => {};
  }, [page, loadingMore]);

  async function getReward() {
    console.log(page);
    try {
      let response = await axios({
        method: "GET",
        url: `${CONFIG.BASE_URL}/api/redeem/products?&page=${page}`,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const data = response.data.data;
      // console.log(data);
      setLoadingApi(false);
      setReward(page == 1 ? data.data : [...reward, ...data.data]);
      setRefreshing(false);
      setLoadingMore(data.last_page > page);
      setMaxPage(data.last_page);
    } catch (error) {
      setLoadingApi(false);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  //load more data
  async function getMoreData() {
    console.log("cekk", !onEndReachedCalledDuringMomentum);
    console.log("cekk", page);
    console.log("cekk", maxPage);
    if (!onEndReachedCalledDuringMomentum && page < maxPage) {
      console.log("masuk load");
      setPage(page + 1);
      setLoadingMore(page < maxPage);
      console.log("ini ketiga", page, loadingMore);
    }
    setOnEndReachedCalledDuringMomentum(page >= maxPage);
  }

  function handleRefresh() {
    setPage(1);
    setRefreshing(true);
  }

  function handleLoadMore() {
    console.log("cekkkkk saiki", !loadingMore);
    if (!loadingMore) return null;
    return (
      <div>
        <CircularProgress color="success" />
      </div>
    );
  }

  async function postRecent(item) {
    console.log("masuk");
    console.log("postRecent", JSON.stringify(item));
    try {
      let response = await axios.post(
        `${CONFIG.BASE_URL}/api/recent/products`,
        {
          product_id: item.id,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      // let data = response.data.data.data;
      // const dataTotal = response.data.data.total;
      console.log("postRecent", response.data.data);
      handleRefresh();
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error==========POST RECENT==============",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  return (
    <div className="promoCalendarContainer">
      <div className="promoCalendarHeader">
        <div className="headerComponent">
          <div onClick={() => navigate(-1)} className="headerArrowContainer">
            <img
              src={IconBack}
              alt="headerIconArrow"
              className="headerIconArrow"
            />
          </div>
          <div className="headerTitleContainer">
            <p className="headerTitle">Kalender Reward</p>
          </div>
        </div>
        <div style={{ paddingTop: "10vh" }} className="rewardCard">
          {reward.length > 0 && !loadingApi ? (
            <InfiniteScroll
              dataLength={reward.length}
              next={() => getMoreData()}
              hasMore={true}
              loader={() => handleLoadMore()}
              // refreshFunction={handleRefresh}
              // pullDownToRefresh
              // pullDownToRefreshThreshold={50}
              // pullDownToRefreshContent={handleLoadMore}
            >
              {reward.map((item, index) => {
                return (
                  <div
                    onClick={() => {
                      dispatch(produkAction(item, "rewardDetail"));
                      navigate("/reward/detail");
                    }}
                    key={index}
                    className="productList"
                  >
                    <div className="main">
                      {item.image ? (
                        <img src={CONFIG.BASE_URL + item.image} />
                      ) : (
                        <img src={DummyImage} />
                      )}
                      {/* <div className="content">
                        <div className="name">{item.name}</div>
                      </div> */}
                    </div>
                    <div className="footer">
                      <p className="two">{item.name}</p>
                      <NumberFormat
                        value={Math.round(item.redeem_point)}
                        displayType={"text"}
                        thousandSeparator={true}
                        // prefix={"Rp "}
                        suffix={" poin"}
                        // decimalScale={2}
                        fixedDecimalScale={true}
                        renderText={(value) => (
                          <p style={{color: '#529F45'}} className="two">{value || 0}</p>
                        )}
                      />
                    </div>
                  </div>
                );
              })}
            </InfiniteScroll>
          ) : reward.length == 0 && !loadingApi ? (
            <NoData />
          ) : null}
        </div>
      </div>
      <Loading loadingApi={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RewardCalendar);
