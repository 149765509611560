import React, { useEffect, useState } from "react";
import "./DistributorComplaintReply.css";
import Header from "../components/HeaderDistributor";
import DummyImage from "../assets/images/Logo.webp";
import moment from "moment";
import NumberFormat from "react-number-format";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import CONFIG from "../config/config";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

export const DistributorComplaintReply = () => {
  const navigate = useNavigate();
  const [loadingApi, setLoadingApi] = useState(true);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);
  const [dataDetail, setDataDetail] = useState({});
  const [input, setInput] = useState({
    title: "",
    deskripsi: "",
  });
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("idComp");
  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  function Loading(props) {
    return props.visible ? (
      <div className="loading">
        <CircularProgress color="success" />
      </div>
    ) : null;
  }

  useEffect(() => {
    getDataComplaint();
  }, []);

  async function getDataComplaint() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/distributor-partner/complaint`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = response.data.data.data.find(
        (arr) => arr.id === parseInt(id)
      );
      setDataDetail(data);
      setLoadingApi(false);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========NOTIF================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }
  async function postReplyComplaint() {
    try {
      if (input.title === "" || input.deskripsi === "") {
        console.log("data kurang");
      } else {
        let response = await axios.post(
          `${CONFIG.BASE_URL}/api/distributor-partner/complaint/reply/${id}`,
          {
            title: input.title,
            content: input.deskripsi,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        navigate(-1)
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========NOTIF================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }
  return (
    <div className="containerBalasComp">
      <div className="headerBalasComp">
        <Header
          // notifMessages={notifMessages}
          // notifSubscribe={notifSubscribe}
          // notifOrders={notifOrders}
          // notifComplaints={notifComplaints}
          // notifBroadcast={notifBroadcast}
          // countNotifCart={countNotifCart}
          // simpanSearch={simpanSearch}
          titleHeader="Balas komplain"
          //   badge={true}
          //   linkProfile={true}
          item={false}
        />
      </div>
      <div className="viewProdukBalasComp">
        <div className="contentDateDetail">
          {moment(dataDetail.created_at).format("DD MMM YYYY HH:mm")}
        </div>
        <div className="contentCardDetail">
          {dataDetail?.order?.order_details[0].product.image != null ? (
            <img
              className="viewImgUlasan"
              src={
                CONFIG.BASE_URL + dataDetail?.order?.order_details[0].product
              }
            />
          ) : (
            <img className="contentImgDetail" src={DummyImage} />
          )}

          <div className="contentTextDetail">
            <div className="viewInvoice">
              <div className="titleInvoice">{"invoice : "}</div>
              <div className="invoiceNumber">
                {dataDetail?.order?.invoice || ""}
              </div>
            </div>
            <div className="nameTextDetail">
              {dataDetail?.order?.order_details[0].product.invoice_name || ""}
            </div>
            <div className="viewPriceDetail">
              <div className="priceTextDetail">
                <NumberFormat
                  value={
                    Math.round(
                      dataDetail?.order?.order_details[0].total_price
                    ) ?? "0"
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp "}
                  renderText={(value) => (
                    <div className="priceText">{value || "0"}</div>
                  )}
                />
              </div>
              <div className="produkDetail">
                + {dataDetail?.order?.order_details.length - 1} produk lainnya
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        {dataDetail?.complaint_detail?.length > 0 && !loadingApi ? (
          <InfiniteScroll dataLength={dataDetail?.complaint_detail?.length}>
            {dataDetail?.complaint_detail?.map((item, index) => {
              return (
                <div key={index} className="viewContentComp">
                  <div className="viewCardComp">
                    <div className="kiriContentComp">
                      <div className="textContentComp">Status complaint</div>
                      <div className="textContentComp">Judul</div>
                      <div className="textContentComp">Jumlah</div>
                      <div className="textContentComp">
                        Pilihan pengembalian
                      </div>
                    </div>
                    <div className="kananContentComp">
                      <div className="textContentComp">
                        {": "}
                        {dataDetail.status || "Belum dibalas"}
                      </div>
                      <div className="textContentComp">
                        {": "}
                        {item.title || ""}
                      </div>
                      <div className="textContentComp">
                        {": "}
                        {dataDetail.qty || ""}
                      </div>
                      <div className="textContentComp">
                        {": "}
                        {dataDetail.option || ""}
                      </div>
                    </div>
                  </div>
                  <div className="textLampiranComp">Penjelasan</div>
                  <div className="hijauLampiranComp">{item.content || ""}</div>
                  <div className="textLampiranComp">
                    {"Lampiran (opsional)"}
                  </div>
                  {item.file_1 === null ? (
                    <div className="textLampiran">Lampiran kosong</div>
                  ) : (
                    <img
                      className="opsionalImg"
                      src={CONFIG.BASE_URL + item.file_1 || DummyImage}
                    />
                  )}
                </div>
              );
            })}
          </InfiniteScroll>
        ) : null}
      </div>
      <div className="viewInputComp">
        <div className="inputTitleComp">
          <input
            placeholder="Ketik judul"
            type="text"
            onChange={(e) => {
              setInput((input) => ({ ...input, title: e.target.value }));
            }}
          />
        </div>
        <div className="inputTitleComp2">
          <input
            placeholder="Ketik deskripsi"
            type="text"
            onChange={(e) => {
              setInput((input) => ({ ...input, deskripsi: e.target.value }));
            }}
          />
        </div>
      </div>
      <div
        className="btnBalasComp"
        onClick={() => {
          postReplyComplaint();
        }}
      >
        {"Kirim"}
      </div>
      <Loading visible={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

export default DistributorComplaintReply;
