import React, { useEffect, useState } from "react";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import {
  screenAction,
  paramsAction,
  produkAction,
  promoAction,
} from "../actions";
import { useDispatch } from "react-redux";
import Dialog from "../components/Dialog";
import Loading from "../components/Loading";
import InfiniteScroll from "react-infinite-scroll-component";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import IconBack from "../assets/images/backArrow.webp";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import IconSend from "../assets/images/Send.webp";
import "./DiskusiProdukDistributor.css";
import Header from "../components/HeaderDistributor";

export default function DiskusiProdukInputDistributor() {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const idDiskusi = queryParams.get("idDiskusi");
  const [produkDesc, setProdukDesc] = useState({});
  // const { produkDesc } = useSelector((state) => state.ProdukReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const [
    onEndReachedCalledDuringMomentum,
    setOnEndReachedCalledDuringMomentum,
  ] = useState(false);
  const [loadingApi, setLoadingApi] = useState(true);
  const [open, setOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [alertTambahan, setAlertTambahan] = useState("");
  const [alertJudul, setAlertJudul] = useState("");
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);
  const [message, setMessage] = useState("");
  const location = useLocation();
  const screen = location.state.screen;
  const [diskusi, setDiskusi] = useState(location.state.diskusi);
  const [images, setImages] = useState([]);
  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  //load more data
  async function getMoreData() {
    if (!onEndReachedCalledDuringMomentum && page < maxPage) {
      console.log("masuk load");
      setPage(page + 1);
      setLoadingMore(page < maxPage);
      console.log("ini ketiga", page, loadingMore);
    }
    setOnEndReachedCalledDuringMomentum(page >= maxPage);
  }

  function handleRefresh() {
    setPage(1);
    setRefreshing(true);
    // getDataLoadAll();
  }

  function handleLoadMore() {
    console.log("cekkkkk saiki", !loadingMore);
    if (!loadingMore) return null;
    return (
      <div>
        <CircularProgress color="success" />
      </div>
    );
  }

  const handleClose = async (e) => {
    setOpen(false);
    if (alertData == "Waktu sesi anda sudah berakhir") {
      navigate("/login");
    }
  };

  useEffect(() => {
    setLoadingApi(false);
    getProduk();
    getDiskusi();
  }, []);
  async function getProduk() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/distributor-partner/products/${id}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      setProdukDesc(data);
      setImages(data.product_image);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }
  const postDiskusiReply = async () => {
    try {
      let response = await axios.post(
        `${CONFIG.BASE_URL}/api/distributor-partner/products/discussion/reply/${idDiskusi}`,
        {
          product_id: id,
          message: message,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      console.log("data diskusi===", data);
      setMessage("");
      getDiskusi();
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        // navigate('/home')
      }
    }
  };

  const getDiskusi = async () => {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/products/discussion/${id}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      const newObjArr = data.find((item) => {
        return item.id === diskusi.id;
      });
      setDiskusi(newObjArr);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        // navigate('/home')
      }
    }
  };
  // console.log("DATA",images[0]?.path)
  return (
    <div className="homeContainer">
      <Dialog
        alertData={alertData}
        alertJudul={alertJudul}
        handleClose={handleClose}
        open={open}
      />
      <Header titleHeader="Balas pertanyaan" />
      <div
        style={{
          marginTop: "28px",
          padding: "0px 10px",
          paddingBottom: "50px",
        }}
      >
        <div
          style={{
            display: "flex",
            margin: "10px 0px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "100px", height: "100px", marginRight: "10px" }}>
            {produkDesc.product_image?.length > 0}
            <img
              style={{ width: "100%", height: "100%" }}
              alt="diskusiProdukDistributorImage"
              src={CONFIG.BASE_URL + images[0]?.path}
            />
          </div>
          <div>
            <p className="produkDeskripsiDistributorDiskusiMessage">
              {produkDesc.name}
            </p>
          </div>
        </div>
        <hr />
        <div className="diskusiProdukDistributorScroll">
          <div className="produkDeskripsiDistributorDiskusiContainerMessage">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "15px 0px",
              }}
            >
              <div className="produkDeskripsiDistributorDiskusi">
                <img
                  alt="produkDeskripsiDistributorDiskusiImage"
                  src={CONFIG.BASE_URL + diskusi.photo}
                />
              </div>
              <p className="produkDeskripsiDistributorDiskusiNamaUser">
                {diskusi.name}
              </p>
              <p className="produkDeskripsiDistributorDiskusiTanggal">
                {moment(diskusi.created_at).format("MMM YYYY")}
              </p>
            </div>
            <div>
              <p className="produkDeskripsiDistributorDiskusiMessage">
                {diskusi.message}
              </p>
            </div>
            {diskusi.reply?.length > 0 ? (
              <>
                {diskusi.reply.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="produkDeskripsiDistributorDiskusiContainerReply"
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          margin: "15px 0px",
                        }}
                      >
                        <div className="produkDeskripsiDistributorDiskusi">
                          <img
                            alt="produkDeskripsiDistributorDiskusiImage"
                            src={CONFIG.BASE_URL + item.photo}
                          />
                        </div>
                        {item?.admin_id == item?.user_id ? (
                          <p className="produkDeskripsiDistributorDiskusiNamaToko">
                            {"Penjual"}
                          </p>
                        ) : (
                          <p className="produkDeskripsiDistributorDiskusiNamaUser">
                            {item.name}
                          </p>
                        )}
                        <p className="produkDeskripsiDistributorDiskusiTanggal">
                          {moment(item?.created_at).format("MMM YYYY")}
                        </p>
                      </div>
                      <div>
                        <p className="produkDeskripsiDistributorDiskusiMessage">
                          {item?.message}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : null}
          </div>
        </div>
        <div className="chatNavbarDiskusi">
          <div className="chatNavbarInputDiskusi">
            <input
              value={message}
              className="chatNavbarInputText"
              placeholder="Tulis Jawabanmu"
              onChange={(e) => setMessage(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  postDiskusiReply();
                }
              }}
            />
          </div>
          <div onClick={postDiskusiReply} className="chatNavbarIcon">
            <img
              src={IconSend}
              alt="chatNavbarIconLogo"
              className="chatNavbarIconLogo"
            />
          </div>
        </div>
      </div>
      <Loading loadingApi={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
}
