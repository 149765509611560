import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/images/IconLogo.webp";
import IconUser from "../assets/images/User.webp";
import IconSandi from "../assets/images/Sandi.webp";
import IconReSandi from "../assets/images/ReSandi.webp";
import "./ForgetPasswordForm.css";
import axios from "axios";
import CONFIG from "../config/config";
import { useDispatch } from "react-redux";
import { userAction, screenAction } from "../actions";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

export const ForgetPasswordForm = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { dataUser } = useSelector((state) => state.UserReducer);
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirm, setPassword_confirm] = useState("");
  const [alertPass, setAlertPass] = useState(false);
  const [alertPass2, setAlertPass2] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  async function handleSubmitForgetPassword() {
    setLoadingApi(true);
    // if (!phone.trim()) {
    //   // this.setState({
    //   //   alertData: 'Pastikan nomor telepon tidak ada yang kosong',
    //   //   modalAlert: !this.state.modalAlert,
    //   // });
    //   alert("Pastikan nomor telepon tidak ada yang kosong");
    //   return;
    // } else
    if (!password.trim()) {
      // this.setState({
      //   alertData: 'Pastikan password tidak ada yang kosong',
      //   modalAlert: !this.state.modalAlert,
      // });
      alert("Pastikan password tidak ada yang kosong");
      return;
    } else if (!password_confirm.trim()) {
      // this.setState({
      //   alertData: 'Pastikan password confirm tidak ada yang kosong',
      //   modalAlert: !this.state.modalAlert,
      // });
      alert("Pastikan konfirm password tidak ada yang kosong");
      return;
    } else if (alertPass == true) {
      // this.setState({
      //   alertData: 'Pastikan password diisi dengan benar',
      //   modalAlert: !this.state.modalAlert,
      // });
      alert("Pastikan password diisi dengan benar");
      return;
    } else if (alertPass2 == true) {
      // this.setState({
      //   alertData: 'Pastikan password diisi dengan benar',
      //   modalAlert: !this.state.modalAlert,
      // });
      alert("Pastikan password konfirm diisi dengan benar");
      return;
    } else {
      try {
        let response = await axios.post(
          `${CONFIG.BASE_URL}/api/auth/forgot-password`,
          {
            email_phone: dataUser.phone,
            password: password,
            confirmation_password: password_confirm,
          }
        );
        let data = response.data;
        if (data !== "" && data["success"] == true) {
          setLoadingApi(false);
          alert("Harap menunggu admin kami untuk memproses permintaan anda");
          navigate("/login");
          console.log("DATA", data.data);
        } else {
          let message = data.message;
          setLoadingApi(false);
          // console.log('message === yuhu', message);
          // this.props.loginAct('isLogin', false);
          let user = "Send forgot password failed";
          let userNot = "User not found";
          if (message === user) {
            // this.setState({
            //   alertData: "Edit password gagal",
            //   modalAlert: !modalAlert,
            // });
            alert("Edit password gagal");
          } else if (message == userNot) {
            // this.setState({
            //   alertData: "Nomor telepon tidak ditemukan",
            //   modalAlert: !modalAlert,
            // });
            alert("Nomor telepon tidak ditemukan");
          }
        }
      } catch (error) {
        setLoadingApi(false);
        let error429 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 429";
        let errorNetwork =
          JSON.parse(JSON.stringify(error)).message === "Network Error";
        let error400 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 400";
        console.log(
          "Cek Error========================",
          JSON.parse(JSON.stringify(error)).message
        );
        if (error429) {
          setAlertServerSibuk(true);
        } else if (errorNetwork) {
          setAlertInet(true);
        } else if (error400) {
          localStorage.removeItem("token");
          // navigate('/home')
        }
      }
    }
  }

  return (
    <div className="container">
      <img src={Logo} alt="Logo" className="logo" />
      <h1 className="judul">{"Lupa Password"}</h1>
      <div className="containerInputLogin">
        <img src={IconUser} alt="iconUser" className="IconUser" />
        <input
          // value={phone}
          type="number"
          // onChange={(e) => setPhone(e.target.value)}
          className="textInputLogin"
          placeholder={dataUser.phone}
          disabled
        />
      </div>
      <div className="containerInputLogin">
        <img src={IconSandi} alt="iconSandi" className="IconUser" />
        <input
          type="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            if (password.length < 7) {
              setAlertPass(true);
            } else {
              setAlertPass(false);
            }
          }}
          className="textInputLogin"
          placeholder="Kata Sandi Baru"
        />
      </div>
      {alertPass && <p className="textAlert">Password harus 8 character</p>}
      <div className="containerInputLogin">
        <img src={IconReSandi} alt="IconReSandi" className="IconUser" />
        <input
          type="password"
          value={password_confirm}
          onChange={(e) => {
            setPassword_confirm(e.target.value);
            if (e.target.value != password) {
              setAlertPass2(true);
            } else {
              setAlertPass2(false);
            }
          }}
          className="textInputLogin"
          placeholder="Konfirmasi Kata Sandi Baru"
        />
      </div>
      {alertPass2 && (
        <p className="textAlert">Password konfirmasi tidak sama</p>
      )}
      <button className="buttonOK" onClick={handleSubmitForgetPassword}>
        <p className="textButtonOK">{"Kirim"}</p>
      </button>
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPasswordForm);
