import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, Navigate } from "react-router-dom";
import Logo from "../assets/images/IconLogo.webp";
import IconTransaksi from "../assets/images/Transaksi.webp";
import IconRating from "../assets/images/Ulasan.webp";
import IconWishlist from "../assets/images/Whishlist.webp";
import IconSubscribe from "../assets/images/Subscribe.webp";
import IconMisi from "../assets/images/IconListMisi.webp";
import IconHerbal from "../assets/images/Herbal1.webp";
import IconSupmul from "../assets/images/Supplemen dan Multivitamin1.webp";
import IconMakmin from "../assets/images/Makanan dan Minuman1.webp";
import IconMinyak from "../assets/images/Minyak Angin dan Balsem1.webp";
import IconKomplain from "../assets/images/Komplain.webp";
import IconQuestion from "../assets/images/Question.webp";
import IconQr from "../assets/images/Qr-code.webp";
import IconLogout from "../assets/images/logout.webp";
import IconCredit from "../assets/images/Credit-card.webp";
import IconCoin from "../assets/images/Coin.webp";
import IconSetting from "../assets/images/Settings.webp";
import IconBack from "../assets/images/backArrow.webp";
import IconRedeem from "../assets/images/Redeem.webp";
import "./Menu.css";
import { screenAction, paramsAction, produkAction } from "../actions";
import { useDispatch } from "react-redux";
import NumberFormat from "react-number-format";
import Dialog from "../components/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import CONFIG from "../config/config";
import { Avatar } from "@mui/material";
import Alert from "@mui/material/Alert";
import Header from "../components/HeaderDistributor";
import Stack from "@mui/material/Stack";

function Loading() {
  return (
    <div className="loading">
      <CircularProgress color="success" />
    </div>
  );
}

export const Menu = (props) => {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [alertTambahan, setAlertTambahan] = useState("");
  const [alertJudul, setAlertJudul] = useState("");
  const [loadingApi, setLoadingApi] = useState(false);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);
  const [notifOrders, setNotifOrders] = useState(0);
  const [notifMessages, setNotifMessages] = useState(0);
  const [notifComplaints, setNotifComplaints] = useState(0);
  const [notifBroadcast, setNotifBroadcast] = useState(0);
  const [notifSubscribe, setNotifSubscribe] = useState(0);
  const [countNotifCart, setCountNotifCart] = useState(0);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  const handleClickOpen = () => {
    setOpen(true);
    setAlertData("Apakah yakin ingin keluar?");
    setAlertJudul("Pemberitahuan");
  };

  const handleClose = async (e) => {
    setOpen(false);
    if (e == "ok") {
      await axios
        .post(
          `${CONFIG.BASE_URL}/api/auth/logout`,
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          let data = response.data.success;
          if (data == true) {
            localStorage.removeItem("token");
            setLoadingApi(false);
            navigate("/login");
          } else {
          }
        })
        .catch((error) => {
          setLoadingApi(false);
          let error429 =
            JSON.parse(JSON.stringify(error)).message ===
            "Request failed with status code 429";
          let errorNetwork =
            JSON.parse(JSON.stringify(error)).message === "Network Error";
          let error400 =
            JSON.parse(JSON.stringify(error)).message ===
            "Request failed with status code 400";
          console.log(
            "Cek Error========================",
            JSON.parse(JSON.stringify(error)).message
          );
          if (error429) {
            setAlertServerSibuk(true);
          } else if (errorNetwork) {
            setAlertInet(true);
          } else if (error400) {
            localStorage.removeItem("token");
            navigate("/home");
          }
        });
    }
  };

  function getName() {
    let name = dataUser?.user?.name
      ?.match(/(\b\S)?/g)
      .join("")
      .toUpperCase();
    if (name == null || name == "" || name == undefined) {
      name = "NA"
    }
    return name;
  }

  if (!localStorage.getItem("token")) {
    return <Navigate to={"/login"} />;
  }

  return (
    <div className="menuContainer">
      <Dialog
        alertData={alertData}
        alertJudul={alertJudul}
        handleClose={handleClose}
        open={open}
      />
      {/* <div className="menuBackContainer">
            <div className="menuButtonBack">
                <img src={IconBack} alt="menuLogo" className="menuBack" />
                <div className="menuBackText">
                    Menu Utama
                </div>
            </div>
        </div> */}
        <Header
          notifMessages={notifMessages}
          notifSubscribe={notifSubscribe}
          notifOrders={notifOrders}
          notifComplaints={notifComplaints}
          notifBroadcast={notifBroadcast}
          countNotifCart={countNotifCart}
          titleHeader="Menu"
          item={false}
        />
      <div className="menuHeader">
        <div className="containerHeader">
          <div className="listHeader">
            {dataUser?.user.photo ? (
              <img
                src={CONFIG.BASE_URL + dataUser?.user.photo}
                alt="menuLogo"
                className="menuLogo"
              />
            ) : (
              <Avatar sx={{ bgcolor: "#529F45" }}>{getName()}</Avatar>
            )}
          </div>
        </div>
        <div className="containerHeader">
          <div className="listHeader">
            <p className="userName">Toko User</p>
          </div>
          <div onClick={() => navigate("/kredit")} className="listHeader">
            <img src={IconCredit} alt="menuIcon" className="menuIcon" />
            <NumberFormat
              value={
                dataUser &&
                dataUser?.user &&
                dataUser?.user?.credits &&
                dataUser?.user?.credits[0] &&
                dataUser?.user?.credits[0]?.credit
              }
              displayType={"text"}
              thousandSeparator={true}
              prefix={"Rp "}
              renderText={(value) => <p className="kredit">{value || 0}</p>}
            />
          </div>
          <div onClick={() => navigate("/point")} className="listHeader">
            <img src={IconCoin} alt="menuIcon" className="menuIcon" />
            <NumberFormat
              value={Math.round(dataUser?.user?.point)}
              displayType={"text"}
              thousandSeparator={true}
              // prefix={"Rp "}
              renderText={(value) =>  <p className="point">{value ?? 0}</p>}
            />
           
          </div>
          {/* <div onClick={() => navigate("/reward/calendar")} className="listHeader">
            <img src={IconRedeem} alt="menuIcon" className="menuIcon" />
            <p className="kredit">{'Redeem Poin'}</p>
          </div> */}
        </div>
        <div onClick={() => navigate("/user")} className="containerHeader">
          <div className="listHeader">
            <img src={IconSetting} alt="menuIcon" className="menuIcon" />
          </div>
        </div>
      </div>
      <div className="menuContent">
        <a onClick={() => navigate("/pembayaran")} className="subMenu">
          <img src={IconTransaksi} alt="menuIcon" className="menuIcon" />
          <p className="menuText">Semua Transaksi</p>
        </a>
        <a onClick={() => navigate("/waiting-rating")} className="subMenu">
          <img src={IconRating} alt="menuIcon" className="menuIcon" />
          <p className="menuText">Ulasan</p>
        </a>
        <a onClick={() => navigate("/wishlist")} className="subMenu">
          <img src={IconWishlist} alt="menuIcon" className="menuIcon" />
          <p className="menuText">Daftar Keinginan</p>
        </a>
        <a onClick={() => navigate("/subscribe")} className="subMenu">
          <img src={IconSubscribe} alt="menuIcon" className="menuIcon" />
          <p className="menuText">Daftar Langganan</p>
        </a>
        <a onClick={() => navigate("/mission")} className="subMenu">
          <img src={IconMisi} alt="menuIcon" className="menuIcon" />
          <p className="menuText">Misi</p>
        </a>
        <hr></hr>
        <p className="subMenuTitle">Semua Kategori</p>
        <a
          onClick={() => {
            dispatch(screenAction("DetailHerbal", "screenName"));
            navigate("/produk");
          }}
          className="subMenu"
        >
          <img src={IconHerbal} alt="menuIcon" className="menuIcon" />
          <p className="menuText">Herbal</p>
        </a>
        <a
          onClick={() => {
            dispatch(screenAction("DetailSupMul", "screenName"));
            navigate("/produk");
          }}
          className="subMenu"
        >
          <img src={IconSupmul} alt="menuIcon" className="menuIcon" />
          <p className="menuText">Suplemen dan Multivitamin</p>
        </a>
        <a
          onClick={() => {
            dispatch(screenAction("DetailFoodBev", "screenName"));
            navigate("/produk");
          }}
          className="subMenu"
        >
          <img src={IconMakmin} alt="menuIcon" className="menuIcon" />
          <p className="menuText">Makanan dan Minuman</p>
        </a>
        <a
          onClick={() => {
            dispatch(screenAction("DetailMinyak", "screenName"));
            navigate("/produk");
          }}
          className="subMenu"
        >
          <img src={IconMinyak} alt="menuIcon" className="menuIcon" />
          <p className="menuText">Minyak Angin dan Balsem</p>
        </a>
        <hr></hr>
        <p className="subMenuTitle">Pusat Bantuan</p>
        <a onClick={() => navigate("/complaint")} className="subMenu">
          <img src={IconKomplain} alt="menuIcon" className="menuIcon" />
          <p className="menuText">Komplain Pesanan</p>
        </a>
        <a onClick={() => navigate("/faq")} className="subMenu">
          <img src={IconQuestion} alt="menuIcon" className="menuIcon" />
          <p className="menuText">Bantuan</p>
        </a>
        <hr></hr>
        <a onClick={() => navigate("/user/qr")} className="subMenu">
          <img src={IconQr} alt="menuIcon" className="menuIcon" />
          <p className="menuText">Kode QR</p>
        </a>
        <a onClick={handleClickOpen} className="subMenu">
          <img src={IconLogout} alt="menuIcon" className="menuIcon" />
          <p className="menuText">Keluar</p>
        </a>
      </div>
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
