// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging,getToken, onMessage } from "firebase/messaging";
import { getFirebaseConfig } from './firebase-config';

const app = initializeApp(getFirebaseConfig());
const messaging = getMessaging(app);
const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = REACT_APP_VAPID_KEY;

export const getDataToken = async () => {
  let currentToken = "";
  try {
    currentToken = await getToken(messaging, {
      vapidKey: publicKey,
    });
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
    // ...
  }

  return currentToken;
};

export const messageListener = () => {
  new Promise((resolve)=>{
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      // ...
      resolve(payload);
    });
  })
}

export { messaging };
