import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/images/IconLogo.webp";
import Edit from "../assets/images/edit.webp";
import IconBack from "../assets/images/backArrow.webp";
import "./DataUser.css";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import ImageUploading from "react-images-uploading";
import Dialog from "../components/Dialog";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Header from "../components/HeaderDistributor";

export const DataUser = () => {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const navigate = useNavigate();
  const [photo, setPhoto] = useState(null);
  const [open, setOpen] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [alertTambahan, setAlertTambahan] = useState("");
  const [alertJudul, setAlertJudul] = useState("");
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  useEffect(() => {
    if (photo) {
      upload();
    }

    return () => {};
  }, [photo]);

  async function upload() {
    setLoadingApi(true);
    const formData = new FormData();
    formData.append("name", dataUser.user.name);
    formData.append("email", dataUser.user.email);
    formData.append("phone", dataUser.user.phone);
    // formData.append("photo", photo[0].data_url, photo[0].file.type);
    formData.append("photo", photo[0].file, photo[0].file.type);
    await axios
      .post(`${CONFIG.BASE_URL}/api/profile/${dataUser.user.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        const data = response.data;
        console.log("DATA", data);
        if (data != null && data["success"] == true) {
          console.log("MASUKKK");
          let dataTemp = dataUser;
          dataTemp.user = data.data;
          localStorage.setItem("dataUser", JSON.stringify(dataTemp));
          setLoadingApi(false);
        }
      })
      .catch((error) => {
        let error429 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 429";
        let errorNetwork =
          JSON.parse(JSON.stringify(error)).message === "Network Error";
        let error400 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 400";
        console.log(
          "Cek Error========================",
          JSON.parse(JSON.stringify(error)).message
        );
        if (error429) {
          setAlertServerSibuk(true);
        } else if (errorNetwork) {
          setAlertInet(true);
        } else if (error400) {
          localStorage.removeItem("token");
          navigate("/home");
        }
      });
  }

  const onChangePhoto = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setPhoto(imageList);
  };

  const handleClickOpen = () => {
    setAlertData("apakah anda ingin merubah nomor telepon?");
    setAlertTambahan("harap diingat untuk login berikutnya");
    setAlertJudul("Pemberitahuan");
    setOpen(true);
  };

  const handleClose = async (e) => {
    setOpen(false);
    if (e == "ok") {
      navigate("/user/edit/phone");
    }
  };

  return (
    <div className="userContainer">
      <Dialog
        alertData={alertData}
        alertJudul={alertJudul}
        alertTambahan={alertTambahan}
        handleClose={handleClose}
        open={open}
      />
      <Header 
      titleHeader="Profile"
      />
      <div className="userHeader">
        <ImageUploading
          // multiple
          value={photo}
          onChange={onChangePhoto}
          // maxNumber={maxNumber}
          dataURLKey="data_url"
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
            // write your building UI
            <div className="containerUserHeader">
              {photo ? (
                <img
                  src={photo[0].data_url}
                  alt="userLogo"
                  className="userLogo"
                />
              ) : dataUser.user.photo ? (
                <img
                  src={CONFIG.BASE_URL + dataUser.user.photo}
                  alt="userLogo"
                  className="userLogo"
                />
              ) : (
                <img src={DummyImage} alt="userLogo" className="userLogo" />
              )}
              <img
                onClick={onImageUpload}
                {...dragProps}
                src={Edit}
                className="editIcon"
              />
            </div>
          )}
        </ImageUploading>
      </div>
      <div className="userList">
        <div className="userTextTitle">Nama</div>
        <div className="subUser">
          <p className="userText">{dataUser.user.name}</p>
          <a onClick={() => navigate("/user/edit/name")} className="userLinkEdit">
            ubah
          </a>
        </div>
        <hr></hr>
      </div>
      <div className="userList">
        <div className="userTextTitle">Email</div>
        <a className="subUser">
          <p className="userText">{dataUser.user.email ?? "kosong"}</p>
          <a onClick={() => navigate("/user/edit/email")} className="userLinkEdit">
            ubah
          </a>
        </a>
        <hr></hr>
      </div>
      <div className="userList">
        <div className="userTextTitle">Nomor Telepon</div>
        <a href="#" className="subUser">
          <p className="userText">{dataUser.user.phone}</p>
          <a onClick={handleClickOpen} className="userLinkEdit">
            ubah
          </a>
        </a>
        <hr></hr>
      </div>
      <Loading loadingApi={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DataUser);
