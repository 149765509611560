import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import IconBack from "../assets/images/backArrow.webp";
import IconSearch from "../assets/images/Search.webp";
import Product1 from "../assets/dummy/product1.jpg";
import Product2 from "../assets/dummy/product2.jpg";
import Complaint1 from "../assets/dummy/complaint1.jpg";
import Plus from "../assets/images/Plus.webp";
import "./ComplaintDetail.css";
import { useSelector } from "react-redux";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import CircularProgress from "@mui/material/CircularProgress";
import Header from "../components/HeaderDistributor";
import { useDispatch } from "react-redux";
import { produkAction, screenAction, orderAction } from "../actions";
import moment from "moment";
import NumberFormat from "react-number-format";
import Dialog from "../components/Dialog";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

export const ComplaintDetail = (props) => {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const { screenName } = useSelector((state) => state.ScreenReducer);
  // const { detailComplaint } = useSelector((state) => state.OrderReducer);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);
  const [loadingApi, setLoadingApi] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id_detail");
  const [detailComplaint, setDetailComplaint] = useState({});

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  function getReplyComplaint() {
    navigate("/complaint/reply");
  }
  useEffect(() => {
    getDetailKomplain();
  }, []);

  async function getDetailKomplain() {
    try {
      let response = await axios.get(`${CONFIG.BASE_URL}/api/complaint`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const data = response.data.data.data?.find(
        (arr) => arr.id === parseInt(id)
      );
      setDetailComplaint(data);
      setLoadingApi(false);
    } catch (error) {
      setLoadingApi(false);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }
  return (
    <div className="complaintDetailContainer">
      <Header titleHeader="Detail Komplain" />
      <div className="complaintDetail">
        <div className="complaintDetailMain">
          <div className="complaintDetailTitle">
            <div className="complaintDetailTitleDate">
              {moment(
                detailComplaint.order?.order_details[0]?.created_at
              ).format("DD MMM YYYY HH:mm")}
            </div>
          </div>
          <div className="complaintDetailContent">
            <div className="complaintDetailContentGroupImage">
              {detailComplaint?.order?.order_details[0]?.product?.image ? (
                <img
                  src={
                    CONFIG.BASE_URL +
                    detailComplaint?.order?.order_details[0]?.product?.image
                  }
                  alt="complaintDetailContentImage"
                  className="complaintDetailContentImage"
                />
              ) : (
                <img
                  src={DummyImage}
                  alt="complaintDetailContentImage"
                  className="complaintDetailContentImage"
                />
              )}
              {detailComplaint.order?.order_details?.length > "1" ? (
                <div className="complaintDetailContentQty">
                  +{detailComplaint.order.order_details.length - 1} produk
                  lainnya
                </div>
              ) : null}
            </div>
            <div className="complaintDetailContent2">
              <div className="complaintDetailContentProduct">
                {detailComplaint.order?.order_details[0]?.product.name}
              </div>
              {detailComplaint.order?.order_details[0]?.half ? (
                <div className="complaintContentPriceHalf">
                  {"( "}
                  {detailComplaint?.order.order_details[0]?.qty_konversi}{" "}
                  {detailComplaint?.order.order_details[0]?.small_unit.charAt(
                    0
                  ) +
                    detailComplaint?.order.order_details[0]?.small_unit
                      .slice(1)
                      .toLowerCase()}
                  {" )"}
                </div>
              ) : null}
              <NumberFormat
                value={Math.round(detailComplaint.order?.payment_final) ?? "0"}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"Rp "}
                renderText={(value) => (
                  <div className="complaintDetailContentPrice">
                    Total : {value || 0}
                  </div>
                )}
              />
            </div>
          </div>
        </div>
        {detailComplaint.complaint_detail?.map(
          (itemComplaint, indexComplaint) => {
            return (
              <div key={indexComplaint} className="complaintDetailMain">
                <table>
                  <tr>
                    <td>Status Komplain</td>
                    <td> : </td>
                    {detailComplaint.status == "rejected" ? (
                      <td>Dibatalkan</td>
                    ) : detailComplaint.status == "confirmed" ? (
                      <td>Selesai</td>
                    ) : (detailComplaint.status == null ||
                        detailComplaint.status == "") &&
                      detailComplaint.responded == "true" &&
                      itemComplaint.user_id == dataUser.user.id &&
                      detailComplaint.complaint_detail.length - 1 !=
                        indexComplaint ? (
                      <td>Komplain sudah direspon admin</td>
                    ) : (detailComplaint.status == null ||
                        detailComplaint.status == "") &&
                      detailComplaint.responded == "true" &&
                      itemComplaint.user_id != dataUser.user.id &&
                      detailComplaint.complaint_detail.length - 1 !=
                        indexComplaint ? (
                      <td>Balesan dari admin</td>
                    ) : (detailComplaint.status == null ||
                        detailComplaint.status == "") &&
                      detailComplaint.responded == "true" &&
                      itemComplaint.user_id != dataUser.user.id &&
                      detailComplaint.complaint_detail.length - 1 ==
                        indexComplaint ? (
                      <td>Balesan dari admin</td>
                    ) : (detailComplaint.status == null ||
                        detailComplaint.status == "") &&
                      detailComplaint.responded == "true" &&
                      itemComplaint.user_id == dataUser.user.id &&
                      detailComplaint.complaint_detail.length - 1 ==
                        indexComplaint ? (
                      <td>Komplain sudah direspon admin</td>
                    ) : (detailComplaint.status == null ||
                        detailComplaint.status == "") &&
                      detailComplaint.responded == "false" &&
                      itemComplaint.user_id == dataUser.user.id &&
                      detailComplaint.complaint_detail.length - 1 !=
                        indexComplaint ? (
                      <td>Komplain sudah direspon admin</td>
                    ) : (detailComplaint.status == null ||
                        detailComplaint.status == "") &&
                      detailComplaint.responded == "false" &&
                      itemComplaint.user_id != dataUser.user.id &&
                      detailComplaint.complaint_detail.length - 1 !=
                        indexComplaint ? (
                      <td>Balesan dari admin</td>
                    ) : (detailComplaint.status == null ||
                        detailComplaint.status == "") &&
                      detailComplaint.responded == "false" &&
                      itemComplaint.user_id != dataUser.user.id &&
                      detailComplaint.complaint_detail.length - 1 ==
                        indexComplaint ? (
                      <td>Balesan dari admin</td>
                    ) : (detailComplaint.status == null ||
                        detailComplaint.status == "") &&
                      detailComplaint.responded == "false" &&
                      itemComplaint.user_id == dataUser.user.id &&
                      detailComplaint.complaint_detail.length - 1 ==
                        indexComplaint ? (
                      <td>Komplain belum direspon admin</td>
                    ) : null}
                  </tr>
                  <tr>
                    <td>Judul</td>
                    <td> : </td>
                    <td> {itemComplaint.title} </td>
                  </tr>
                  <tr>
                    <td>Penjelasan</td>
                    <td> : </td>
                    <td> {itemComplaint.content} </td>
                  </tr>
                  <tr>
                    <td>Jumlah</td>
                    <td> : </td>
                    <td> {detailComplaint.qty} </td>
                  </tr>
                  <tr>
                    <td>Pilihan pengembalian</td>
                    <td> : </td>
                    <td> {detailComplaint.option} </td>
                  </tr>
                  <tr>
                    {itemComplaint.file_1 ||
                    itemComplaint.file_2 ||
                    itemComplaint.file_3 ? (
                      <td>
                        <b>Lampiran</b> (Opsional)
                      </td>
                    ) : null}
                    <td> </td>
                    <td> </td>
                  </tr>
                </table>
                {itemComplaint.file_1 ? (
                  <div className="lampiran">
                    <img
                      src={CONFIG.BASE_URL + itemComplaint.file_1}
                      alt="complaintDetailContentImage"
                      className="image"
                    />
                  </div>
                ) : null}
                {itemComplaint.file_2 ? (
                  <div className="lampiran">
                    <img
                      src={CONFIG.BASE_URL + itemComplaint.file_2}
                      alt="complaintDetailContentImage"
                      className="image"
                    />
                  </div>
                ) : null}
                {itemComplaint.file_3 ? (
                  <div className="lampiran">
                    <img
                      src={CONFIG.BASE_URL + itemComplaint.file_3}
                      alt="complaintDetailContentImage"
                      className="image"
                    />
                  </div>
                ) : null}
              </div>
            );
          }
        )}
      </div>
      {detailComplaint.status != "confirmed" &&
        detailComplaint.status != "rejected" && (
          <div
            onClick={getReplyComplaint}
            className="complaintDetailButtonContent"
          >
            <button className="complaintDetailButton">
              <p className="complaintDetailTextButton">{"Balas Komplain"}</p>
            </button>
          </div>
        )}
      <Loading loadingApi={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ComplaintDetail);
