import { SET_PARAMS } from "../../actions";
const initialState = {
  params: [],
  paramsString: '',
  paramsObject: {},
};

const params = (state = initialState, action) => {
  switch (action.type) {
    case SET_PARAMS:
      return {
        ...state,
        [action.inputType]: action.inputValue,
      };
    default:
      return state;
  }
};

export default params;
