import React, { useEffect, useState } from "react";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import Navbar from "../components/Navbar";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import {
  screenAction,
  paramsAction,
  produkAction,
  promoAction,
} from "../actions";
import { useDispatch } from "react-redux";
import Dialog from "../components/Dialog";
import Loading from "../components/Loading";
import InfiniteScroll from "react-infinite-scroll-component";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import IconBack from "../assets/images/backArrow.webp";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import Header from "../components/Header";
import "./ProfileDistributor.css";
import IconNextAtas from "../assets/images/Next2.webp";
import IconCart from "../assets/images/KeranjangActive.webp";
import ModalShopping from "../components/DialogShopping";
import ReactStars from "react-rating-stars-component";
import IconHome from "../assets/images/HomePartner.webp";
import IconVoucher from "../assets/images/VoucherPartner.webp";
import IconProduk from "../assets/images/ProdukPartner.webp";
import IconHomeActive from "../assets/images/HomePartnerActive.webp";
import IconVoucherActive from "../assets/images/VoucherPartnerActive.webp";
import IconProdukActive from "../assets/images/ProdukPartnerActive.webp";

export default function ProfileDistributor() {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const { produkDesc } = useSelector((state) => state.ProdukReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const URL = CONFIG.BASE_URL;
  const [notifOrders, setNotifOrders] = useState(0);
  const [notifMessages, setNotifMessages] = useState(0);
  const [notifComplaints, setNotifComplaints] = useState(0);
  const [notifBroadcast, setNotifBroadcast] = useState(0);
  const [notifSubscribe, setNotifSubscribe] = useState(0);
  const [countNotifCart, setCountNotifCart] = useState(0);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const [
    onEndReachedCalledDuringMomentum,
    setOnEndReachedCalledDuringMomentum,
  ] = useState(false);
  const [loadingApi, setLoadingApi] = useState(true);
  const [open, setOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [alertTambahan, setAlertTambahan] = useState("");
  const [alertJudul, setAlertJudul] = useState("");
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);
  const [tombolHome, setTombolHome] = useState(true);
  const [tombolVoucher, setTombolVoucher] = useState(false);
  const [tombolProduk, setTombolProduk] = useState(false);
  const [dataPartners, setDataPartners] = useState([]);
  const [produk, setProduk] = useState([]);
  const [vouchers, setVouchers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [productTerlaris, setProductTerlaris] = useState([]);
  const [productRecomen, setProductRecomen] = useState([]);
  const [qtyProductTerlaris, setQtyProductTerlaris] = useState(0);
  const [qtyProductRecomen, setQtyProductRecomen] = useState(0);
  const [produkSemua, setProdukSemua] = useState(true);
  const [produkTerbaru, setProdukTerbaru] = useState(false);
  const [produkTerlaris, setProdukTerlaris] = useState(false);
  const [shoppingCartItems, setShoppingCartItems] = useState({
    product_id: "",
    brand_id: "",
    satuan_online: "",
    konversi_sedang_ke_kecil: "",
    qty_konversi: "",
    qty: 1,
    notes: "",
    price_apps: "",
  });
  const [openShopping, setOpenShopping] = useState(false);
  const [loadAll, setLoadAll] = useState(false);
  const location = useLocation();

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  //load more data
  async function getMoreData() {
    console.log("cekk", !onEndReachedCalledDuringMomentum);
    console.log("cekk", page);
    console.log("cekk", maxPage);
    if (!onEndReachedCalledDuringMomentum && page < maxPage) {
      console.log("masuk load");
      setPage(page + 1);
      setLoadingMore(page < maxPage);
      console.log("ini ketiga", page, loadingMore);
    }
    setOnEndReachedCalledDuringMomentum(page >= maxPage);
  }

  function handleRefresh() {
    setPage(1);
    setRefreshing(true);
    // getDataLoadAll();
  }

  function handleLoadMore() {
    console.log("cekkkkk saiki", !loadingMore);
    if (!loadingMore) return null;
    return (
      <div>
        <CircularProgress color="success" />
      </div>
    );
  }

  const handleClose = async (e) => {
    setOpen(false);
    if (alertData == "Waktu sesi anda sudah berakhir") {
      navigate("/login");
    }
  };

  useEffect(() => {
    getDashboardPartner();
    getNotifAll();
  }, []);

  useEffect(() => {
    console.log("tembakkkkkk");
    if (loadAll) {
      getDashboardPartner();
      getNotifAll();
    }

    return () => {
      setOnEndReachedCalledDuringMomentum(false);
    };
  }, [loadAll]);

  function handlerSetAlertServerSibuk() {
    setAlertServerSibuk(true);
  }

  function handlerSetAlertInet() {
    setAlertInet(true);
  }
  async function getNotifAll() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/notification-all`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      setNotifOrders(data.total_order);
      setNotifMessages(data.total_chat);
      setNotifComplaints(data.total_complaint);
      setNotifSubscribe(data.total_subscribe);
      setNotifBroadcast(data.total_broadcast);
      setCountNotifCart(data.total_cart);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========NOTIF================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        // navigate('/home')
      }
    }
  }
  const getDashboardPartner = async () => {
    try {
      await axios({
        method: "get",
        url: `${CONFIG.BASE_URL}/api/distributor/dashboard/${produkDesc.partner_id}`,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }).then((response) => {
        const data = response.data.data;
        console.log("cek data", JSON.stringify(data));
        let dataPartners = [];
        dataPartners.push(data);
        setDataPartners(dataPartners);
        setProduk(data.products.data);
        setVouchers(data.vouchers);
        setCategories(data.categories);
        setProductTerlaris(data.products_terlaris.data);
        setQtyProductTerlaris(data.products_terlaris.total);
        setProductRecomen(data.products_rekomen.data);
        setQtyProductRecomen(data.products_rekomen.total);
        setLoadingApi(false);
        // console.log('dataPartners', JSON.stringify(dataPartners));
      });
    } catch (error) {
      setLoadingApi(false);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
      }
    }
  };

  async function postRecent(item) {
    console.log("masuk");
    console.log("postRecent", JSON.stringify(item));
    try {
      let response = await axios.post(
        `${CONFIG.BASE_URL}/api/recent/products`,
        {
          product_id: item.id,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      // let data = response.data.data.data;
      // const dataTotal = response.data.data.total;
      console.log("postRecent", response.data.data);
      handleRefresh();
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error==========POST RECENT==============",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
      }
    }
  }

  async function postShoppingCart(item) {
    const brand_id_1 = ["001"];
    const brand_id_2 = [
      "002",
      "003",
      "004",
      "007",
      "008",
      "009",
      "010",
      "011",
      "012",
      "013",
      "014",
    ];
    const brand_id_3 = ["005"];

    // console.log('price=====>',item)
    let price = item.price.harga_ritel_gt ?? "0";
    if (brand_id_1.includes(item.brand_id)) {
      if (item.status_promosi_coret == 1) {
        // console.log('if 1');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          default:
            break;
        }
      } else if (
        (item.status_promosi_coret !== "0" ||
          item.status_promosi_coret !== null) &&
        item.status_herbana !== "1"
      ) {
        // console.log('if 2');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_ritel_gt;
            break;
          default:
            break;
        }
      } else if (item.status_herbana === "1") {
        // console.log('if 3');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_grosir_mt;
            break;
          default:
            break;
        }
      } else {
        // console.log('if 4');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_ritel_gt;
            break;
          default:
            break;
        }
      }
    } else if (brand_id_2.includes(item.brand_id)) {
      if (item.status_promosi_coret == 1) {
        // console.log('if 5');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          default:
            break;
        }
      } else if (
        item.status_promosi_coret !== "0" ||
        item.status_promosi_coret !== null
      ) {
        // console.log('if 6');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_grosir_mt;
            break;
          default:
            break;
        }
      } else {
        // console.log('if 7');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_grosir_mt;
            break;
          default:
            break;
        }
      }
    } else if (brand_id_3.includes(item.brand_id)) {
      if (item.status_promosi_coret == 1) {
        // console.log('if 8');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          default:
            break;
        }
      } else if (
        item.status_promosi_coret !== "0" ||
        item.status_promosi_coret !== null
      ) {
        // console.log('if 9');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_ritel_gt;
            break;
          default:
            break;
        }
      } else {
        // console.log('if 10');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_ritel_gt;
            break;
          default:
            break;
        }
      }
    }

    console.log("price_apps", price);

    try {
      let response = await axios.post(
        `${CONFIG.BASE_URL}/api/shopping-cart`,
        {
          product_id: item.id,
          brand_id: item.brand_id,
          satuan_online: item.satuan_online,
          konversi_sedang_ke_kecil: item.konversi_sedang_ke_kecil,
          qty_konversi: shoppingCartItems.qty * item.konversi_sedang_ke_kecil,
          qty: shoppingCartItems.qty,
          notes: shoppingCartItems.notes,
          price_apps: price ?? "0",
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data;
      if (data !== "" && data["success"] == true) {
        setOpenShopping(true);
        setLoadAll(true);
        setPage(1);
      } else {
        console.log("Gagal memasukkan keranjang===>", data);
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
      }
    }
  }

  const handleClickOpenShopping = () => {
    setOpenShopping(true);
  };

  const handleCloseShopping = (e) => {
    setOpenShopping(false);
    if (e == "ok") {
      navigate("/keranjang");
    }
  };

  const handleStatusChange = (value) => {
    console.log("handleStatusChange", value);
    setPage(1);
    setTimeout(() => {
      getProdukPage(value);
    }, 200);
  };

  const getProdukPage = async (value) => {
    try {
      await axios({
        method: "get",
        url: `${CONFIG.BASE_URL}/api/distributor/dashboard/${produkDesc.partner_id}?page=${page}&status=${value}`,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }).then((response) => {
        const data = response.data.data;
        let dataPartners = [];
        dataPartners.push(data);
        setProduk(
          page === 1
            ? dataPartners[0].products.data
            : [...produk, ...dataPartners[0].products.data]
        );
        setLoadingMore(dataPartners[0].products.last_page > page);
        setMaxPage(dataPartners[0].products.last_page);
        // console.log('dataPartners', JSON.stringify(data));
      });
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
      }
    }
  };

  const handleFollow = async () => {
    try {
      let response = await axios.post(
        `${CONFIG.BASE_URL}/api/follow`,
        { partner_id: dataPartners[0].user.id },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data;
      // console.log('DATA Follow====', data);
      if (data !== "" && data["success"] == true) {
        getDashboardPartner();
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
      }
    }
  };

  const handleUnFollow = async () => {
    try {
      let response = await axios.post(
        `${CONFIG.BASE_URL}/api/unfollow`,
        { partner_id: dataPartners[0].user.id },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data;
      console.log("DATA UnFollow====", data);
      if (data !== "" && data["success"] == true) {
        getDashboardPartner();
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
      }
    }
  };

  return (
    <div className="homeContainer">
      <Dialog
        alertData={alertData}
        alertJudul={alertJudul}
        handleClose={handleClose}
        open={open}
      />
      <ModalShopping
        handleCloseShopping={handleCloseShopping}
        openShopping={openShopping}
      />
      <Header
        notifMessages={notifMessages}
        notifSubscribe={notifSubscribe}
        notifOrders={notifOrders}
        notifComplaints={notifComplaints}
        notifBroadcast={notifBroadcast}
        countNotifCart={countNotifCart}
        titleHeader="Profile Distributor"
        item={false}
      />
      {dataPartners?.map((item, index) => {
        return (
          <div
          key={index}
            style={{
              display: "flex",
              marginTop: "4vh",
              alignItems: "center",
              padding: "0px 35px",
            }}
          >
            <div
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "100px",
                borderStyle: "solid",
                borderWidth: "1px",
              }}
            >
              <img
                style={{ width: "100%", height: "100%", borderRadius: "100%" }}
                src={URL + item.user.photo}
              />
            </div>
            <div
              style={{
                marginLeft: "10px",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <p className="profileDistributorTextBold">
                  {produkDesc?.distributor?.shop_name
                    ? produkDesc?.distributor?.shop_name
                    : produkDesc?.distributor?.name}
                </p>
                {moment().isSame(moment(item.user.last_login), "day") ? (
                  <p
                    style={{ color: "#7D7D7D", fontSize: "7pt" }}
                    className="profileDistributorTextMed"
                  >
                    {"Online"}
                    {moment(item.user.last_login).toNow(true)}
                  </p>
                ) : (
                  <p
                    style={{ color: "#7D7D7D", fontSize: "7pt" }}
                    className="profileDistributorTextMed"
                  >
                    {"Online"}
                    {moment(item.user.last_login).fromNow()}
                  </p>
                )}
              </div>
            </div>
          </div>
        );
      })}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "5px 0px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="profileDistributorTopButton">
            <ReactStars count={1} size={24} edit={false} color={"#ffd700"} />
            <p
              style={{ color: "#7D7D7D", fontSize: "10pt" }}
              className="profileDistributorTextMed"
            >
              {"5.0"}
            </p>
          </div>
          <p
            style={{ color: "#7D7D7D", fontSize: "10pt" }}
            className="profileDistributorTextMed"
          >
            {"Rating & Ulasan"}
          </p>
        </div>
        <div
          style={{
            borderStyle: "solid",
            borderColor: "#7D7D7D",
            height: "30px",
            borderWidth: "1px",
            margin: "0px 10px",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="profileDistributorTopButton">
            <p
              style={{ color: "#7D7D7D", fontSize: "10pt" }}
              className="profileDistributorTextMed"
            >
              {"±"}
            </p>
            <p
              style={{ color: "#7D7D7D", fontSize: "10pt" }}
              className="profileDistributorTextMed"
            >
              {"5 jam"}
            </p>
          </div>
          <p
            style={{ color: "#7D7D7D", fontSize: "10pt" }}
            className="profileDistributorTextMed"
          >
            {"Pesanan diproses"}
          </p>
        </div>
        <div
          style={{
            borderStyle: "solid",
            borderColor: "#7D7D7D",
            height: "30px",
            borderWidth: "1px",
            margin: "0px 10px",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="profileDistributorTopButton">
            <p
              style={{ color: "#7D7D7D", fontSize: "10pt" }}
              className="profileDistributorTextMed"
            >
              {"BEBAS"}
            </p>
          </div>
          <p
            style={{ color: "#7D7D7D", fontSize: "10pt" }}
            className="profileDistributorTextMed"
          >
            {"ONGKIR"}
          </p>
        </div>
        <div
          style={{
            borderStyle: "solid",
            borderColor: "#7D7D7D",
            height: "30px",
            borderWidth: "1px",
            margin: "0px 10px",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="profileDistributorTopButton">
            <p
              style={{ color: "#7D7D7D", fontSize: "10pt" }}
              className="profileDistributorTextMed"
            >
              {"08:30"}
            </p>
          </div>
          <p
            style={{ color: "#7D7D7D", fontSize: "10pt" }}
            className="profileDistributorTextMed"
          >
            {"Jam operasional"}
          </p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          // padding: "0px 10px",
          marginTop: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            onClick={() =>
              navigate("/chat", {
                state: { partner_id: produkDesc.partner_id },
              })
            }
            style={{ marginRight: "20px" }}
            className="profileDistributorTwoTopButton"
          >
            <p
              style={{ color: "#7D7D7D", fontSize: "8pt" }}
              className="profileDistributorTextMed"
            >
              Chat
            </p>
          </div>
          {dataPartners[0]?.status_follow == 0 ? (
            <div
              onClick={handleFollow}
              className="profileDistributorTwoTopButton"
            >
              <p
                style={{ color: "#7D7D7D", fontSize: "8pt" }}
                className="profileDistributorTextMed"
              >
                Follow
              </p>
            </div>
          ) : (
            <div
              onClick={handleUnFollow}
              className="profileDistributorTwoTopButton"
            >
              <p
                style={{ color: "#7D7D7D", fontSize: "8pt" }}
                className="profileDistributorTextMed"
              >
                Following
              </p>
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0px 10px",
          marginTop: "15px",
        }}
      >
        {tombolHome ? (
          <div className="profileDistributorTabMenuActive">
            <div className="profileDistributorIconContainerActive">
              <img
                alt="profileDistributorIconActive"
                className="profileDistributorIconActive"
                src={IconHomeActive}
              />
            </div>
          </div>
        ) : (
          <div
            onClick={() => {
              setTombolHome(true);
              setTombolVoucher(false);
              setTombolProduk(false);
            }}
            className="profileDistributorTabMenu"
          >
            <div className="profileDistributorIconContainer">
              <img
                alt="profileDistributorIconActive"
                className="profileDistributorIconActive"
                src={IconHome}
              />
            </div>
          </div>
        )}
        {tombolVoucher ? (
          <div className="profileDistributorTabMenuActive">
            <div
              style={{ padding: "8px 8px" }}
              className="profileDistributorIconContainerActive"
            >
              <img
                alt="profileDistributorIconActive"
                className="profileDistributorIconActive"
                src={IconVoucherActive}
              />
            </div>
          </div>
        ) : (
          <div
            onClick={() => {
              setTombolHome(false);
              setTombolVoucher(true);
              setTombolProduk(false);
            }}
            className="profileDistributorTabMenu"
          >
            <div
              style={{ padding: "8px 8px" }}
              className="profileDistributorIconContainer"
            >
              <img
                alt="profileDistributorIconActive"
                className="profileDistributorIconActive"
                src={IconVoucher}
              />
            </div>
          </div>
        )}
        {tombolProduk ? (
          <div className="profileDistributorTabMenuActive">
            <div className="profileDistributorIconContainerActive">
              <img
                alt="profileDistributorIconActive"
                className="profileDistributorIconActive"
                src={IconProdukActive}
              />
            </div>
          </div>
        ) : (
          <div
            onClick={() => {
              setTombolHome(false);
              setTombolVoucher(false);
              setTombolProduk(true);
            }}
            className="profileDistributorTabMenu"
          >
            <div className="profileDistributorIconContainer">
              <img
                alt="profileDistributorIconActive"
                className="profileDistributorIconActive"
                src={IconProduk}
              />
            </div>
          </div>
        )}
      </div>
      {tombolHome && (
        <>
          <div className="profileDistributorContainerProducts">
            {qtyProductRecomen > 10 ? (
              <div className="homeViewLihatSemua">
                <p className="notifProductTitle">Produk Rekomendasi</p>
                <div
                  onClick={() => {
                    dispatch(screenAction("GeneralPopular", "screenName"));
                    navigate("/produk/list");
                  }}
                  className="homeGroupLihatSemua"
                >
                  <p className="homeLihatSemua">Lihat Semua</p>
                  <img className="homeImageIconNextAtas" src={IconNextAtas} />
                </div>
              </div>
            ) : (
              <p className="notifProductTitle">Produk Rekomendasi</p>
            )}
            <div className="notifProductContainer">
              {productRecomen.map((item, index) => {
                return (
                  <div key={index} className="homeProductItem">
                    {/* {item.promo_sku.length > 0 && (
                  <div
                    onClick={() => {
                      postRecent(item);
                      dispatch(produkAction(item, "produkDesc"));
                      navigate("/produk/deskripsi?idProduct=" + item.id);
                    }}
                    className="homeProductItemPromo"
                  >
                    <p className="homeProductItemPromoText">Promo</p>
                  </div>
                )} */}
                    {item.product_image.length > 0 ? (
                      <img
                        onClick={() => {
                          postRecent(item);
                          dispatch(produkAction(item, "produkDesc"));
                          navigate("/produk/deskripsi?idProduct=" + item.id);
                        }}
                        src={URL + item.product_image[0].path}
                        alt="homeProductImage"
                        className="homeProductImage"
                      />
                    ) : (
                      <img
                        onClick={() => {
                          postRecent(item);
                          dispatch(produkAction(item, "produkDesc"));
                          navigate("/produk/deskripsi?idProduct=" + item.id);
                        }}
                        src={DummyImage}
                        alt="homeProductImage"
                        className="homeProductImage"
                      />
                    )}
                    <p
                      onClick={() => {
                        postRecent(item);
                        dispatch(produkAction(item, "produkDesc"));
                        navigate("/produk/deskripsi?idProduct=" + item.id);
                      }}
                      className="homeProductItemTitle"
                    >
                      {item.name}
                    </p>
                    {item.status_promosi_coret == 1 ? (
                      <NumberFormat
                        value={
                          Math.round(
                            item?.price?.harga_promosi_coret_ritel_gt ||
                              item?.price?.harga_promosi_coret_grosir_mt ||
                              item?.price?.harga_promosi_coret_semi_grosir
                          ) ?? "0"
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Rp "}
                        renderText={(value) => (
                          <p
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate(
                                "/produk/deskripsi?idProduct=" + item.id
                              );
                            }}
                            className="homeProductItemPrice"
                          >
                            {value || 0}
                          </p>
                        )}
                      />
                    ) : (
                      <NumberFormat
                        value={Math.round(item?.price?.harga_ritel_gt) ?? "0"}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Rp "}
                        renderText={(value) => (
                          <p
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate(
                                "/produk/deskripsi?idProduct=" + item.id
                              );
                            }}
                            className="homeProductItemPrice"
                          >
                            {value || 0}
                          </p>
                        )}
                      />
                    )}
                    <div
                      onClick={() => {
                        postRecent(item);
                        dispatch(produkAction(item, "produkDesc"));
                        navigate("/produk/deskripsi?idProduct=" + item.id);
                      }}
                      className="homeProductItemView"
                    >
                      <div className="homeProductItemRating">
                        <ReactStars
                          count={1}
                          size={24}
                          edit={false}
                          color={"#ffd700"}
                        />
                        <p className="homeProductItemRatingValue">
                          {item.review
                            ? item.review[0]
                              ? item.review[0].avg_rating
                              : "0"
                            : "0"}
                        </p>
                      </div>
                      {item.cart && (
                        <div className="homeProductItemRating">
                          <img className="notifListIcon" src={IconCart} />
                          <p className="homeProductItemRatingValue">
                            {item.cart.qty}
                          </p>
                        </div>
                      )}
                    </div>
                    {item.cart ? (
                      <button
                        onClick={() => postShoppingCart(item)}
                        className="homeProductItemButton"
                      >
                        <p className="homeProductItemButtonBeli">Tambah</p>
                      </button>
                    ) : (
                      <button
                        onClick={() => postShoppingCart(item)}
                        className="homeProductItemButton"
                      >
                        <p className="homeProductItemButtonBeli">Beli</p>
                      </button>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <hr className="hr"></hr>
          <div className="profileDistributorContainerProducts">
            {qtyProductTerlaris > 10 ? (
              <div className="homeViewLihatSemua">
                <p className="notifProductTitle">Produk Terlaris</p>
                <div
                  onClick={() => {
                    dispatch(screenAction("GeneralPopular", "screenName"));
                    navigate("/produk/list");
                  }}
                  className="homeGroupLihatSemua"
                >
                  <p className="homeLihatSemua">Lihat Semua</p>
                  <img className="homeImageIconNextAtas" src={IconNextAtas} />
                </div>
              </div>
            ) : (
              <p className="notifProductTitle">Produk Terlaris</p>
            )}
            <div className="notifProductContainer">
              {productTerlaris.map((item, index) => {
                return (
                  <div key={index} className="homeProductItem">
                    {/* {item.promo_sku.length > 0 && (
                  <div
                    onClick={() => {
                      postRecent(item);
                      dispatch(produkAction(item, "produkDesc"));
                      navigate("/produk/deskripsi?idProduct=" + item.id);
                    }}
                    className="homeProductItemPromo"
                  >
                    <p className="homeProductItemPromoText">Promo</p>
                  </div>
                )} */}
                    {item.product_image.length > 0 ? (
                      <img
                        onClick={() => {
                          postRecent(item);
                          dispatch(produkAction(item, "produkDesc"));
                          navigate("/produk/deskripsi?idProduct=" + item.id);
                        }}
                        src={URL + item.product_image[0].path}
                        alt="homeProductImage"
                        className="homeProductImage"
                      />
                    ) : (
                      <img
                        onClick={() => {
                          postRecent(item);
                          dispatch(produkAction(item, "produkDesc"));
                          navigate("/produk/deskripsi?idProduct=" + item.id);
                        }}
                        src={DummyImage}
                        alt="homeProductImage"
                        className="homeProductImage"
                      />
                    )}
                    <p
                      onClick={() => {
                        postRecent(item);
                        dispatch(produkAction(item, "produkDesc"));
                        navigate("/produk/deskripsi?idProduct=" + item.id);
                      }}
                      className="homeProductItemTitle"
                    >
                      {item.name}
                    </p>
                    {item.status_promosi_coret == 1 ? (
                      <NumberFormat
                        value={
                          Math.round(
                            item?.price?.harga_promosi_coret_ritel_gt ||
                              item?.price?.harga_promosi_coret_grosir_mt ||
                              item?.price?.harga_promosi_coret_semi_grosir
                          ) ?? "0"
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Rp "}
                        renderText={(value) => (
                          <p
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate(
                                "/produk/deskripsi?idProduct=" + item.id
                              );
                            }}
                            className="homeProductItemPrice"
                          >
                            {value || 0}
                          </p>
                        )}
                      />
                    ) : (
                      <NumberFormat
                        value={Math.round(item?.price?.harga_ritel_gt) ?? "0"}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Rp "}
                        renderText={(value) => (
                          <p
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate(
                                "/produk/deskripsi?idProduct=" + item.id
                              );
                            }}
                            className="homeProductItemPrice"
                          >
                            {value || 0}
                          </p>
                        )}
                      />
                    )}
                    <div
                      onClick={() => {
                        postRecent(item);
                        dispatch(produkAction(item, "produkDesc"));
                        navigate("/produk/deskripsi?idProduct=" + item.id);
                      }}
                      className="homeProductItemView"
                    >
                      <div className="homeProductItemRating">
                        <ReactStars
                          count={1}
                          size={24}
                          edit={false}
                          color={"#ffd700"}
                        />
                        <p className="homeProductItemRatingValue">
                          {item.review
                            ? item.review[0]
                              ? item.review[0].avg_rating
                              : "0"
                            : "0"}
                        </p>
                      </div>
                      {item.cart && (
                        <div className="homeProductItemRating">
                          <img className="notifListIcon" src={IconCart} />
                          <p className="homeProductItemRatingValue">
                            {item.cart.qty}
                          </p>
                        </div>
                      )}
                    </div>
                    {item.cart ? (
                      <button
                        onClick={() => postShoppingCart(item)}
                        className="homeProductItemButton"
                      >
                        <p className="homeProductItemButtonBeli">Tambah</p>
                      </button>
                    ) : (
                      <button
                        onClick={() => postShoppingCart(item)}
                        className="homeProductItemButton"
                      >
                        <p className="homeProductItemButtonBeli">Beli</p>
                      </button>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
      {tombolVoucher && (
        <div>
          <InfiniteScroll
            dataLength={vouchers.length}
            next={() => getMoreData()}
            hasMore={true}
            loader={() => handleLoadMore()}
            // refreshFunction={handleRefresh}
            // pullDownToRefresh
            // pullDownToRefreshThreshold={50}
            // pullDownToRefreshContent={handleLoadMore}
          >
            <div className="notifListMain">
              {vouchers.map((item, index) => {
                return (
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    key={index}
                    className="notifListCard"
                  >
                    <div style={{ width: "100px", height: "100px" }}>
                      <img
                        style={{ width: "100%", height: "100%" }}
                        src={URL + item.file}
                      />
                    </div>
                    <div style={{ marginLeft: "20px" }}>
                      <p
                        style={{ color: "#7D7D7D", fontSize: "10pt" }}
                        className="profileDistributorTextMed"
                      >
                        {item.code}
                      </p>
                      {item.type === "percent" ? (
                        <p
                          style={{ color: "#7D7D7D", fontSize: "10pt" }}
                          className="profileDistributorTextMed"
                        >
                          {"Diskon "}
                          {item.percent}
                          {"%"}
                        </p>
                      ) : (
                        <p
                          style={{ color: "#7D7D7D", fontSize: "10pt" }}
                          className="profileDistributorTextMed"
                        >
                          {"Potongan Rp"}
                          {item.nominal}
                        </p>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </InfiniteScroll>
        </div>
      )}
      {tombolProduk && (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              // padding: "0px 10px",
              marginTop: "15px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // padding: "0px 10px",
                width: "80%",
              }}
            >
              {produkSemua ? (
                <div
                  style={{ borderColor: "#7EDC6E" }}
                  className="profileDistributorFilter"
                >
                  <p
                    style={{ color: "#7EDC6E", fontSize: "8pt" }}
                    className="profileDistributorTextMed"
                  >
                    Semua
                  </p>
                </div>
              ) : (
                <div
                  onClick={() => {
                    setProdukSemua(true);
                    setProdukTerbaru(false);
                    setProdukTerlaris(false);
                    handleStatusChange("");
                  }}
                  className="profileDistributorFilter"
                >
                  <p
                    style={{ color: "#7D7D7D", fontSize: "8pt" }}
                    className="profileDistributorTextMed"
                  >
                    Semua
                  </p>
                </div>
              )}
              {produkTerbaru ? (
                <div
                  style={{ borderColor: "#7EDC6E" }}
                  className="profileDistributorFilter"
                >
                  <p
                    style={{ color: "#7EDC6E", fontSize: "8pt" }}
                    className="profileDistributorTextMed"
                  >
                    Terbaru
                  </p>
                </div>
              ) : (
                <div
                  onClick={() => {
                    setProdukSemua(false);
                    setProdukTerbaru(true);
                    setProdukTerlaris(false);
                    handleStatusChange("terbaru");
                  }}
                  className="profileDistributorFilter"
                >
                  <p
                    style={{ color: "#7D7D7D", fontSize: "8pt" }}
                    className="profileDistributorTextMed"
                  >
                    Terbaru
                  </p>
                </div>
              )}
              {produkTerlaris ? (
                <div
                  style={{ borderColor: "#7EDC6E" }}
                  className="profileDistributorFilter"
                >
                  <p
                    style={{ color: "#7EDC6E", fontSize: "8pt" }}
                    className="profileDistributorTextMed"
                  >
                    Terlaris
                  </p>
                </div>
              ) : (
                <div
                  onClick={() => {
                    setProdukSemua(false);
                    setProdukTerbaru(false);
                    setProdukTerlaris(true);
                    handleStatusChange("terlaris");
                  }}
                  className="profileDistributorFilter"
                >
                  <p
                    style={{ color: "#7D7D7D", fontSize: "8pt" }}
                    className="profileDistributorTextMed"
                  >
                    Terlaris
                  </p>
                </div>
              )}
            </div>
          </div>
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <hr style={{ marginTop: "10px", width: "80%" }} />
          </div>
          <InfiniteScroll
            dataLength={produk.length}
            next={() => getMoreData()}
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              marginTop: "20px",
            }}
            hasMore={true}
            loader={() => handleLoadMore()}
            // refreshFunction={handleRefresh}
            // pullDownToRefresh
            // pullDownToRefreshThreshold={50}
            // pullDownToRefreshContent={handleLoadMore}
          >
            {produk.map((item, index) => {
              return (
                <div key={index} className="homeProductRecomenItem">
                  {/* {item.promo_sku.length > 0 && (
                    <div
                      onClick={() => {
                        postRecent(item);
                        dispatch(produkAction(item, "produkDesc"));
                        navigate("/produk/deskripsi?idProduct=" + item.id);
                      }}
                      className="homeProductItemPromo"
                    >
                      <p className="homeProductItemPromoText">Promo</p>
                    </div>
                  )} */}
                  {item.product_image.length > 0 ? (
                    <img
                      onClick={() => {
                        postRecent(item);
                        dispatch(produkAction(item, "produkDesc"));
                        navigate("/produk/deskripsi?idProduct=" + item.id);
                      }}
                      src={URL + item.product_image[0].path}
                      alt="homeProductImage"
                      className="homeProductImage"
                    />
                  ) : (
                    <img
                      onClick={() => {
                        postRecent(item);
                        dispatch(produkAction(item, "produkDesc"));
                        navigate("/produk/deskripsi?idProduct=" + item.id);
                      }}
                      src={DummyImage}
                      alt="homeProductImage"
                      className="homeProductImage"
                    />
                  )}
                  <p
                    onClick={() => {
                      postRecent(item);
                      dispatch(produkAction(item, "produkDesc"));
                      navigate("/produk/deskripsi?idProduct=" + item.id);
                    }}
                    className="homeProductItemTitle"
                  >
                    {item.name}
                  </p>
                  {item.status_promosi_coret == 1 ? (
                    <NumberFormat
                      value={
                        Math.round(
                          item?.price?.harga_promosi_coret_ritel_gt ||
                            item?.price?.harga_promosi_coret_grosir_mt ||
                            item?.price?.harga_promosi_coret_semi_grosir
                        ) ?? "0"
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"Rp "}
                      renderText={(value) => (
                        <p
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemPrice"
                        >
                          {value || 0}
                        </p>
                      )}
                    />
                  ) : (
                    <NumberFormat
                      value={Math.round(item?.price?.harga_ritel_gt) ?? "0"}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"Rp "}
                      renderText={(value) => (
                        <p
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemPrice"
                        >
                          {value || 0}
                        </p>
                      )}
                    />
                  )}
                  <div
                    onClick={() => {
                      postRecent(item);
                      dispatch(produkAction(item, "produkDesc"));
                      navigate("/produk/deskripsi?idProduct=" + item.id);
                    }}
                    className="homeProductItemView"
                  >
                    <div className="homeProductItemRating">
                      <ReactStars
                        count={1}
                        size={24}
                        edit={false}
                        color={"#ffd700"}
                      />
                      <p className="homeProductItemRatingValue">
                        {item.review
                          ? item.review[0]
                            ? item.review[0].avg_rating
                            : "0"
                          : "0"}
                      </p>
                    </div>
                    {item.cart && (
                      <div className="homeProductItemRating">
                        <img className="notifListIcon" src={IconCart} />
                        <p className="homeProductItemRatingValue">
                          {item.cart.qty}
                        </p>
                      </div>
                    )}
                  </div>
                  {item.cart ? (
                    <button
                      onClick={() => postShoppingCart(item)}
                      className="homeProductItemButton"
                    >
                      <p className="homeProductItemButtonBeli">Tambah</p>
                    </button>
                  ) : (
                    <button
                      onClick={() => postShoppingCart(item)}
                      className="homeProductItemButton"
                    >
                      <p className="homeProductItemButtonBeli">Beli</p>
                    </button>
                  )}
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
      )}
      <Loading loadingApi={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
}
