import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import IconChat from "../assets/images/ChatActive.webp";
import IconSubscribe from "../assets/images/Subscribe.webp";
import IconCart from "../assets/images/Shopping-Cart.webp";
import IconComplaint from "../assets/images/Komplain.webp";
import IconBroadcast from "../assets/images/Broadcast.webp";
import Header from "../components/HeaderDistributor";
import NavbarDistributor from "../components/NavbarDistributor";
import Navbar from "../components/Navbar";
import "./Notification.css";
import NumberFormat from "react-number-format";
import ReactStars from "react-rating-stars-component";
import Product1 from "../assets/dummy/product1.jpg";
import Product2 from "../assets/dummy/product2.jpg";
import Product3 from "../assets/dummy/product3.jpg";
import Product4 from "../assets/dummy/product4.jpg";
import Product5 from "../assets/dummy/product5.jpg";
import Product6 from "../assets/dummy/product6.jpg";
import axios from "axios";
import CONFIG from "../config/config";
import { useSelector } from "react-redux";
import DummyImage from "../assets/images/Logo.webp";
import { useDispatch } from "react-redux";
import { screenAction, produkAction } from "../actions";
import ModalShopping from "../components/DialogShopping";
import SearchComponent from "../pages/SearchList";
import { CircularProgress } from "@mui/material";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";

export const ListChats = (props) => {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const { screenName } = useSelector((state) => state.ScreenReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [listChats, setListChats] = useState([]);
  const [notifOrders, setNotifOrders] = useState(0);
  const [notifMessages, setNotifMessages] = useState(0);
  const [notifComplaints, setNotifComplaints] = useState(0);
  const [notifBroadcast, setNotifBroadcast] = useState(0);
  const [notifSubscribe, setNotifSubscribe] = useState(0);
  const [countNotifCart, setCountNotifCart] = useState(0);
  const [search, setSearch] = useState("");
  const [openShopping, setOpenShopping] = useState(false);
  const [loadAll, setLoadAll] = useState(false);
  const [loadingApi, setLoadingApi] = useState(true);
  const [shoppingCartItems, setShoppingCartItems] = useState({
    product_id: "",
    brand_id: "",
    satuan_online: "",
    konversi_sedang_ke_kecil: "",
    qty_konversi: "",
    qty: 1,
    notes: "",
    price_apps: "",
  });
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const [recomenProduct, setRecomenProduct] = useState([]);
  const [
    onEndReachedCalledDuringMomentum,
    setOnEndReachedCalledDuringMomentum,
  ] = useState(false);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  useEffect(() => {
    getNotifAll();
    getListChats();

    return () => {};
  }, []);

  const getListChats = async () => {
    await axios({
      method: "get",
      url: `${CONFIG.BASE_URL}/api/list-chat?page=${page}`,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
      .then((response) => {
        const data = response.data.data;
        console.log("data getListChats==========", data.data);
        setListChats(page === 1 ? data.data : [...listChats, ...data.data]);
        setLoadingMore(data.last_page > page);
        setMaxPage(data.last_page);
        setLoadingApi(false);
      })
      .catch((error) => {
        setLoadingApi(false);
        let error429 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 429";
        let errorNetwork =
          JSON.parse(JSON.stringify(error)).message === "Network Error";
        let error400 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 400";
        console.log(
          "Cek Error========NOTIF================",
          JSON.parse(JSON.stringify(error)).message
        );
        if (error429) {
          setAlertServerSibuk(true);
        } else if (errorNetwork) {
          setAlertInet(true);
        } else if (error400) {
          localStorage.removeItem("token");
          navigate("/home");
        }
      });
  };

  const getToChat = (item) => {
    console.log("cek item", item);
    navigate("/chat", { state: { listChat: item } });
  };

  //load more data
  async function getMoreData() {
    console.log("cekk", !onEndReachedCalledDuringMomentum);
    console.log("cekk", page);
    console.log("cekk", maxPage);
    if (!onEndReachedCalledDuringMomentum && page < maxPage) {
      console.log("masuk load");
      setPage(page + 1);
      setLoadingMore(page < maxPage);
      console.log("ini ketiga", page, loadingMore);
    }
    setOnEndReachedCalledDuringMomentum(page >= maxPage);
  }

  function handleRefresh() {
    setPage(1);
    setRefreshing(true);
    getListChats();
  }

  function handleLoadMore() {
    console.log("cekkkkk saiki", !loadingMore);
    if (!loadingMore) return null;
    return (
      <div>
        <CircularProgress color="success" />
      </div>
    );
  }

  async function getNotifAll() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/notification-all`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      // console.log('DATA TEST', data.total_chat);
      setNotifOrders(data.total_order);
      setNotifMessages(data.total_chat);
      setNotifComplaints(data.total_complaint);
      setNotifSubscribe(data.total_subscribe);
      setNotifBroadcast(data.total_broadcast);
      setCountNotifCart(data.total_cart);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========NOTIF================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  const handleClickOpenShopping = () => {
    setOpenShopping(true);
  };

  const handleCloseShopping = (e) => {
    setOpenShopping(false);
    if (e == "ok") {
      navigate("/keranjang");
    }
  };

  const simpanSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div className="notificationContainer">
      <ModalShopping
        handleCloseShopping={handleCloseShopping}
        openShopping={openShopping}
      />
      <Header
        notifMessages={notifMessages}
        notifSubscribe={notifSubscribe}
        notifOrders={notifOrders}
        notifComplaints={notifComplaints}
        notifBroadcast={notifBroadcast}
        countNotifCart={countNotifCart}
        simpanSearch={simpanSearch}
        titleHeader={"List Chat"}
        badge={true}
        linkProfile={true}
        item={false}
      />
      <div className="notificationMain">
        <InfiniteScroll
          dataLength={listChats.length}
          next={() => getMoreData()}
          //   style={{
          //     display: "flex",
          //     flexDirection: "column",
          //     justifyContent: "center",
          //     alignItems: "center",
          //     borderRadius: "8px",
          //     marginLeft: "15px",
          //     marginRight: "15px",
          //     padding: "10px",
          //     marginTop: "20px",
          //   }}
          hasMore={true}
          loader={() => handleLoadMore()}
          // refreshFunction={handleRefresh}
          // pullDownToRefresh
          // pullDownToRefreshThreshold={50}
          // pullDownToRefreshContent={handleLoadMore}
        >
          {listChats.map((item, index) => {
            return (
              <>
                <a
                  //   onClick={() => {
                  //     dispatch(screenAction("chat", "screenName"));
                  //     navigate("/chat");
                  //   }}
                  key={index}
                  onClick={() => getToChat(item)}
                  className="subNotif"
                >
                  <div className="divListChat">
                    <img
                      src={CONFIG.BASE_URL + item.photo}
                      alt="menuIcon"
                      className="notifIcon"
                    />
                    <div className="viewTitle">
                      <p className="notifText">{item.name}</p>
                      <p className="notifTextMes">{item.message}</p>
                    </div>
                    {moment().isSame(moment(item.sended_at), "day") ? (
                      <p className="notifTextDate">
                        {moment(item.sended_at).toNow(true)}
                      </p>
                    ) : (
                      <p className="notifTextDate">
                        {moment(item.sended_at).format("DD MMM")}
                      </p>
                    )}
                  </div>
                </a>
                <hr></hr>
              </>
            );
          })}
        </InfiniteScroll>
        {loadingApi ? (
          <div className="notifProduct">
            <CircularProgress className="posisiLoading" color="success" />
          </div>
        ) : null}
      </div>
      {dataUser?.user?.account_type != 5 ? (
        <Navbar screenName={"chat"} />
      ) : (
        <NavbarDistributor screenName={"ListChats"} />
      )}

      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ListChats);
