import React, { useEffect, useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import CONFIG from "../config/config";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import "./MissionList.css";
import SimpleHeader from "../components/HeaderDistributor";
import IconListMisi from "../assets/images/IconListMisi.webp";
import IconAvailable from "../assets/images/availableUntil.webp";
import moment from "moment";
import IconMisiKosong from "../assets/images/IconMisiKosong.webp";
import IconMisiSelesaiKosong from "../assets/images/IconMisiSelesaiKosong.webp";

export const MissionList = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadingApi, setLoadingApi] = useState(true);
  const [dataListMisi, setDataListMisi] = useState({});
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);
  const [filter, setFilter] = useState(true);
  const [dataListParent, setDataListParent] = useState({});
  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }
  useEffect(() => {
    if (loadingApi) {
      getListMission();
      setTimeout(() => setLoadingApi(false), 10000);
    }
    return () => {};
  }, [loadingApi]);

  async function getListMission() {
    try {
      let response = await axios.get(`${CONFIG.BASE_URL}/api/mission/list`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const data = response.data.data;
      const time = moment().format("x");
      function newArray() {
        const date = data.filter(
          (array) =>
            parseFloat(moment(array.end_date).format("x")) > parseFloat(time)
        );
        return date;
      }
      function isFinish() {
        const isFinish = newArray().filter(
          (array) => array.is_finish === false
        );
        return isFinish;
      }
      if (response.data["success"] == true) {
        console.log("Mission start " + JSON.stringify(data));
        setLoadingApi(false);
        setDataListMisi(isFinish);
        setDataListParent(newArray);
      } else {
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========NOTIF================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        // navigate('/home')
      }
    }
  }
  async function getMissionStart(id) {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/mission-user/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = response.data;
      if (data["success"] == true) {
        getListMission();
      } else {
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========NOTIF================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        // navigate('/home')
      }
    }
  }
  function changeArray(param) {
    setFilter(param);
    if (param == true) {
      let newData = dataListParent?.filter((array) => array.is_finish == false);
      setDataListMisi(newData);
    } else if (param == false) {
      let newData = dataListParent?.filter((array) => array.is_finish == true);
      setDataListMisi(newData);
    }
  }
  return (
    <div className="container-list-mission">
      <SimpleHeader titleHeader="Daftar Misi" />
      <div className="content-list-mission">
        <div className="filter-list-misi">
          <div
            onClick={() => {
              changeArray(true);
            }}
            style={
              filter === true
                ? {
                    borderBottomColor: "#267CE0",
                    borderBottomWidth: "3px",
                    borderBottomStyle: "solid",
                    color: "#000000",
                  }
                : null
            }
            className="filter-list-misi-misi"
          >
            Misi
          </div>
          <div
            onClick={() => {
              changeArray(false);
            }}
            style={
              filter === false
                ? {
                    borderBottomColor: "#267CE0",
                    borderBottomWidth: "3px",
                    borderBottomStyle: "solid",
                    color: "#000000",
                  }
                : null
            }
            className="filter-list-misi-misi"
          >
            Misi Selesai
          </div>
        </div>
        {dataListMisi[0] !== undefined ? (
          dataListMisi?.map((item, index) => {
            return (
              <div
                key={index}
                className={
                  (index + 1) % 3 === 0
                    ? "div-card-mission-yellow"
                    : (index + 1) % 3 === 2
                    ? "div-card-mission-green"
                    : "div-card-mission-blue"
                }
              >
                <div className="card-flexdir-list-mission">
                  <div className="card-text-list-mission">
                    <div>{item?.name}</div>
                    <div className="card-desk-list-mission">
                      {item?.description}
                    </div>
                    <div className="card-reward-list-mission">
                      {"Dapatkan " + item?.reward + " Poin"}
                    </div>
                    <div className="card-date-list-mission">
                      <img className="icon-available" src={IconAvailable} />
                      Available Until : {item?.end_date}
                    </div>
                  </div>
                  <div className="card-icon-list-mission">
                    <img className="icon-list-mission" src={IconListMisi} />
                  </div>
                </div>
                <div
                  onClick={() => {
                    if (item.available_start === true) {
                      getMissionStart(item?.id);
                      navigate(
                        "/mission/detail?idMission=" +
                          item?.id +
                          "&index=" +
                          (parseInt(index) + 1)
                      );
                    }
                  }}
                  className="btn-card-list-mission"
                >
                  {item?.available_start === false
                    ? "Segera"
                    : item?.is_finish === true
                    ? "Selesai"
                    : item?.is_start === true
                    ? "Detail Misi"
                    : "Mulai Misi"}
                </div>
              </div>
            );
          })
        ) : filter == true ? (
          <div className="divIconMisiKosong">
            <img src={IconMisiKosong} className="IconMisiKosong" />
            <div>Misi Selesai</div>
          </div>
        ) : (
          <div className="divIconMisiKosong">
            <img src={IconMisiSelesaiKosong} className="IconMisiKosong" />
            <div>Misi Selesai</div>
          </div>
        )}
      </div>
      <Loading loadingApi={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

export default MissionList;
