import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Slider from "../components/Slider";
import Images from "../images";
import NumberFormat from "react-number-format";
import ReactStars from "react-rating-stars-component";
import "./Delivery.css";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import Product1 from "../assets/dummy/product1.jpg";
import Product2 from "../assets/dummy/product2.jpg";
import IconNotifAll from "../assets/images/NotifAll.webp";
import IconMenu from "../assets/images/Menu.webp";
import IconArrow from "../assets/images/backArrow.webp";
import IconUser from "../assets/images/User.webp";
import IconAlamat from "../assets/images/Address.webp";
import IconReward from "../assets/images/Reward.webp";
import IconPengiriman from "../assets/images/Pengiriman.webp";
import { useSelector } from "react-redux";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import CircularProgress from "@mui/material/CircularProgress";
import IconCart from "../assets/images/KeranjangActive.webp";
import { produkAction, screenAction } from "../actions";
import { useDispatch } from "react-redux";
import Dialog from "../components/Dialog";
import IconClose from "../assets/images/Closemodal.webp";
import RightArrow from "../assets/images/RightArrow.webp";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import IconShoppingCart from "../assets/images/Shopping-Cart.webp";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";
import IconSearch from "../assets/images/Search.webp";
import InfiniteScroll from "react-infinite-scroll-component";

export const Delivery = (props) => {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const { shop_total } = useSelector((state) => state.ProdukReducer);
  const { hasilPromo } = useSelector((state) => state.ProdukReducer);
  const { hasilPromoNotif } = useSelector((state) => state.ProdukReducer);
  const { hargaPromo } = useSelector((state) => state.ProdukReducer);
  const { shop } = useSelector((state) => state.ProdukReducer);
  const { partnerShop } = useSelector((state) => state.ProdukReducer);
  const { kredit } = useSelector((state) => state.ProdukReducer);
  const idPartners = Object.keys(partnerShop);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [totalResult, setTotalResult] = useState(null);
  const [totalDiscount, setTotalDiscount] = useState(null);
  const [pilihPengiriman, setPilihPengiriman] = useState("Bebas Ongkir");
  const [actionPilihReward, setActionPilihReward] = useState([]);
  const [hasilpilihReward, setHasilpilihReward] = useState("");
  const [pilihanReward, setPilihanReward] = useState([]);
  const [p_pilihanReward, setP_pilihanReward] = useState([]);
  const [modalIndex, setModalIndex] = useState(null);
  const [pilihPromo, setPilihPromo] = useState({
    code: "Gunakan Promo",
  });
  const [voucher, setVoucher] = useState([]);
  const [isSelected, setIsSelected] = useState(true);
  const [isSelected2, setIsSelected2] = useState(true);
  const [tombolReward, setTombolReward] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [alertTambahan, setAlertTambahan] = useState("");
  const [alertJudul, setAlertJudul] = useState("");
  const [notifOrders, setNotifOrders] = useState(0);
  const [notifMessages, setNotifMessages] = useState(0);
  const [notifComplaints, setNotifComplaints] = useState(0);
  const [notifBroadcast, setNotifBroadcast] = useState(0);
  const [notifSubscribe, setNotifSubscribe] = useState(0);
  const [countNotifCart, setCountNotifCart] = useState(0);
  const [modalRewards, setModalRewards] = useState(false);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);
  const [btnVoucher, setBtnVoucher] = useState(false);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            // setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            // setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  useEffect(() => {
    getNotifAll();
    let _pilihanReward = [];
    hasilPromo?.map((item) => {
      if (item.promo_choose == null) {
        return (_pilihanReward = [..._pilihanReward, ""]);
      } else if (item.promo_choose.length <= 1) {
        return (_pilihanReward = [..._pilihanReward, ""]);
      }
      _pilihanReward = [..._pilihanReward, "Pilih Reward"];
    });
    setP_pilihanReward(_pilihanReward);

    return () => {};
  }, []);

  function postOrder() {
    console.log("masuk");
    let order = false;
    let promo_choose = false;
    order = p_pilihanReward?.indexOf("Pilih Reward");
    promo_choose = hasilPromo?.every((item) => item.promo_choose == null);
    console.log("state._pilihanReward", p_pilihanReward);
    console.log("state.order", order);
    console.log("state.promo_choose", promo_choose);
    if (order != -1 && !promo_choose) {
      // this.changeObject2();
      // this.props.shopAct(this.state.totalResult, 'totalResult');
      // this.props.shopAct('bebasOngkir', 'pengirimanOrder');
      // this.props.navigation.replace('PaymentMethod');
      // this.setState({isSelected: true});
      setAlertData("Harap pilih tombol pilih reward");
      setAlertJudul("Pemberitahuan");
      handleClickOpen();
    } else if (order == -1 && promo_choose) {
      // this.props.shopAct(this.state.totalDiscount, 'totalDiscount');
      // this.changeObject2();
      dispatch(produkAction(totalResult, "totalResult"));
      dispatch(produkAction("bebasOngkir", "pengirimanOrder"));
      navigate("/payment");
      setIsSelected(true);
    } else {
      // this.props.shopAct(this.state.totalDiscount, 'totalDiscount');
      dispatch(produkAction(totalResult, "totalResult"));
      dispatch(produkAction("bebasOngkir", "pengirimanOrder"));
      navigate("/payment");
      setIsSelected(true);
    }
  }

  function reward(val) {
    let _pilihanReward = [...p_pilihanReward];
    _pilihanReward[modalIndex] = val.product_name + " (" + val.qty + "pcs )";

    setHasilpilihReward(val.product_name);
    setModalRewards(false);
    setP_pilihanReward(_pilihanReward);
    setModalIndex(null);
    changeObject(val);
  }

  function changeObject(val) {
    const newObjArr = hasilPromo?.map((item, index) => {
      item.promo_choose?.map((x, y) => {
        if (x === val) {
          item.promo_reward.product = [val];
          // console.log('hasil==', JSON.stringify(x))
          // console.log('hasil==', JSON.stringify(val))
          // console.log('hasil==', JSON.stringify(item))
        }
      });
      return item;
    });
    console.log("final==", JSON.stringify(newObjArr));
    dispatch(produkAction(newObjArr, "hasilPromo"));
  }

  async function getNotifAll() {
    console.log("cek hit notif");
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/notification-all`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      // console.log('DATA TEST', data.total_chat);
      setNotifOrders(data.total_order);
      setNotifMessages(data.total_chat);
      setNotifComplaints(data.total_complaint);
      setNotifSubscribe(data.total_subscribe);
      setNotifBroadcast(data.total_broadcast);
      setCountNotifCart(data.total_cart);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========NOTIF================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  function renderCountNotificationBadge() {
    try {
      let count = 0;
      let hasil = notifMessages;
      let hasil2 = notifSubscribe;
      let hasil3 = notifOrders;
      let hasil4 = notifComplaints;
      let hasil5 = notifBroadcast;
      count =
        count +
        parseInt(hasil) +
        parseInt(hasil2) +
        parseInt(hasil3) +
        parseInt(hasil4) +
        parseInt(hasil5);
      // console.log(count);
      if (count > 0) {
        return count;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const list = () => (
    <Box
      sx={{ width: "auto" }}
      role="presentation"
      onClick={() => setModalRewards(false)}
      onKeyDown={() => setModalRewards(false)}
    >
      <List>
        {pilihanReward?.promo_choose?.map((val, i) => {
          return (
            <ListItem
              button
              onClick={() => {
                console.log("masuk3333");
                // setModalRewards(false);
                reward(val);
              }}
              key={i}
            >
              <div>
                <ListItemText>{val.product_name}</ListItemText>
                <ListItemText>
                  {" Jumlah :"} {val.qty}
                </ListItemText>
              </div>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );

  const getVoucher = async (id) => {
    try {
      let response = await axios.get(`${CONFIG.BASE_URL}/api/vouchers/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      const data = response.data;
      if (data["success"] == true) {
        console.log("getVoucher " + JSON.stringify(data.data));
        setVoucher(data.data);
        setBtnVoucher(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const pakaiPromo = async (data) => {
    const voucher = [];
    if (data?.id) {
      voucher.push({
        partner_id: data.partner_id,
        voucher_id: data.id,
        start_at: data.start_at,
        end_at: data.end_at,
      });
    }
    try {
      let response = await axios.post(
        `${CONFIG.BASE_URL}/api/orders/voucher`,
        {
          data: {
            shoppingCartMpm: shop,
            shoppingCartPartner: partnerShop,
            vouchers: voucher,
          },
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data;
      if (data["success"] === true) {
        dispatch(produkAction(data.data?.shoppingCartPartner, "partnerShop"));
        dispatch(produkAction(data.data?.total_price, "shop_total"));
        setBtnVoucher(!btnVoucher);
      } else {
        setBtnVoucher(!btnVoucher);
        console.log(data.message);
      }
    } catch (e) {
      console.log("Error: " + e);
    }
  };
  console.log("Data " + JSON.stringify(partnerShop));
  return (
    <div className="homeContainer">
      <Drawer
        anchor={"bottom"}
        open={modalRewards}
        onClose={() => setModalRewards(false)}
      >
        {list()}
      </Drawer>
      <Dialog
        alertData={alertData}
        alertJudul={alertJudul}
        handleClose={handleClose}
        open={open}
      />
      <Header
        notifMessages={notifMessages}
        notifSubscribe={notifSubscribe}
        notifOrders={notifOrders}
        notifComplaints={notifComplaints}
        notifBroadcast={notifBroadcast}
        countNotifCart={countNotifCart}
        titleHeader={btnVoucher == true ? "Pakai Promo" : "Pengiriman"}
        item={false}
      />
      {btnVoucher == true ? (
        <div className="deliveryContent">
          <div className="content-pakai-promo">
            <div className="delivery-btn-promo">
              <input
                placeholder="Ketik nomor invoice yang ingin anda cari..."
                type="text"
              />
              <img
                src={IconSearch}
                alt="headerSearchLogo"
                className="content-pakai-promo-pencarian"
              />
            </div>
            <div className="delivery-list-promo">
              {voucher.map((item, i) => {
                return (
                  <div
                    key={i}
                    className="delivery-item"
                    onClick={() => pakaiPromo(item)}
                  >
                    <div className="delivery-item-image">
                      <img
                        src={CONFIG.BASE_URL + item.file}
                        className="delivery-item-image-image"
                      ></img>
                    </div>
                    <div className="delivery-item-code">
                      <div className="delivery-item-code-code">{item.code}</div>
                      <div className="delivery-item-code-code">
                        {item.description}
                      </div>
                      <NumberFormat
                        value={Math.round(item.min_transaction)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Rp "}
                        renderText={(value) => (
                          <div className="delivery-item-code-code">
                            dengan minimal transaksi {value || 0}
                          </div>
                        )}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div className="deliveryContent">
          {hasilPromoNotif && isSelected && (
            <>
              {hasilPromoNotif.map((item, index) => {
                if (item.promo_status == null && item.promo_id != null) {
                  return (
                    <div key={index} className="deliveryUserBubbleInfo">
                      <div
                        className="cartTopCardContainer"
                        style={{ marginBottom: "10px" }}
                      >
                        <p className="cartTopCardText">
                          {item.promo_description}
                        </p>
                        <img
                          className="cartIconClose"
                          onClick={() => setIsSelected(false)}
                          src={IconClose}
                        />
                      </div>
                    </div>
                  );
                }
              })}
            </>
          )}
          {kredit && isSelected2 && (
            <>
              {kredit.map((item, index) => {
                return (
                  <div key={index} className="deliveryUserBubbleInfo">
                    <div className="cartTopCardContainerKredit">
                      <p className="cartTopCardText">{item.message}</p>
                      <img
                        className="cartIconClose"
                        onClick={() => setIsSelected2(false)}
                        src={IconClose}
                      />
                    </div>
                  </div>
                );
              })}
            </>
          )}

          <div className="deliveryUserContainer">
            <div className="deliveryUserData">
              <div className="deliveryUserDataLeft">
                <img
                  src={IconUser}
                  alt="deliveryIcon"
                  className="deliveryIcon"
                />
              </div>
              <div className="deliveryUserDataRight">
                <p className="deliveryUserDataRightTitle">Nama Pelanggan :</p>
                <p className="deliveryUserDataRightText">
                  {dataUser.user.name}
                </p>
              </div>
            </div>
            <div className="deliveryUserData">
              <div className="deliveryUserDataLeft">
                <img
                  src={IconAlamat}
                  alt="deliveryIcon"
                  className="deliveryIcon"
                />
              </div>
              <div className="deliveryUserDataRight">
                <p className="deliveryUserDataRightTitle">
                  Alamat Pengiriman :
                </p>
                <p className="deliveryUserDataRightText">
                  {dataUser.user.user_address[0]?.kelurahan
                    ? dataUser.user.user_address[0]?.address +
                      "\n" +
                      dataUser.user.user_address[0]?.kelurahan +
                      ", " +
                      dataUser.user.user_address[0]?.kecamatan +
                      ", " +
                      dataUser.user.user_address[0]?.kota +
                      ", " +
                      dataUser.user.user_address[0]?.kode_pos
                    : dataUser.user.user_address[0]?.address}
                </p>
              </div>
            </div>
          </div>

          {shop?.products ? (
            <>
              <div className="deliveryProductContainer">
                <div className="nameDistibutor">Produk Semut Gajah</div>
                {shop?.products?.map((item, index) => {
                  return (
                    <div key={index} className="deliveryProductCard">
                      <div className="deliveryProductImageContainer">
                        {item.image ? (
                          <img
                            src={CONFIG.BASE_URL + item.image}
                            alt="deliveryProductImage"
                            className="deliveryProductImage"
                          />
                        ) : (
                          <img
                            src={DummyImage}
                            alt="deliveryProductImage"
                            className="deliveryProductImage"
                          />
                        )}
                      </div>
                      <div className="deliveryProductInfo">
                        <p className="deliveryProductInfoTitle">{item.name}</p>
                        {item.half ? (
                          <NumberFormat
                            value={Math.round(item.price_apps / 2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p className="deliveryProductInfoDetail">
                                {item.qty} barang x {value || 0}
                              </p>
                            )}
                          />
                        ) : (
                          <NumberFormat
                            value={Math.round(item.price_apps)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p className="deliveryProductInfoDetail">
                                {item.qty} barang x {value || 0}
                              </p>
                            )}
                          />
                        )}
                        {item.half ? (
                          <p className="deliveryProductInfoDetailHalf">
                            {" ( "}
                            {item.qty_konversi}{" "}
                            {item.kecil.charAt(0) +
                              item.kecil.slice(1).toLowerCase()}
                            {" )"}
                          </p>
                        ) : null}
                        {item.notes != null && <p>{item.notes}</p>}
                        <NumberFormat
                          value={Math.round(item.total_price)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"Rp "}
                          renderText={(value) => (
                            <p className="deliveryProductInfoPrice">
                              {value || 0}
                            </p>
                          )}
                        />
                      </div>
                      {hasilPromo?.map((val, i) => {
                        if (
                          item?.promo_id === val?.promo_id &&
                          val?.promo_all == null &&
                          val?.promo_choose &&
                          val?.promo_choose?.length > 1
                        ) {
                          return (
                            <div
                              key={i}
                              // onClick={toggleDrawer("bottom", true)}
                              onClick={() => {
                                setTombolReward(true);
                                setPilihanReward(val);
                                setModalIndex(i);
                                setModalRewards(true);
                              }}
                              className="deliveryRewardProductGeneralCard"
                            >
                              <div className="deliveryRewardIconContainer">
                                <img
                                  src={IconReward}
                                  alt="deliveryRewardIcon"
                                  className="deliveryRewardIcon"
                                />
                              </div>
                              <p className="deliveryRewardText">
                                {p_pilihanReward[i]}
                              </p>
                            </div>
                          );
                        }
                      })}
                    </div>
                  );
                })}
                {hasilPromo?.map((val, i) => {
                  if (val?.promo_choose?.length > 1 && val?.promo_all == 1) {
                    return (
                      <div
                        key={i}
                        className="deliveryRewardProductGeneralContainer"
                      >
                        <p className="deliveryRewardProductGeneralTitle">
                          Hadiah Umum
                        </p>
                        <div
                          onClick={() => {
                            setTombolReward(true);
                            setPilihanReward(val);
                            setModalIndex(i);
                          }}
                          className="deliveryRewardProductGeneralCard"
                        >
                          <div className="deliveryRewardIconContainer">
                            <img
                              src={IconReward}
                              alt="deliveryRewardIcon"
                              className="deliveryRewardIcon"
                            />
                          </div>
                          <p className="deliveryRewardText">
                            {p_pilihanReward[i]}
                          </p>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
              <div className="deliveryDetailContainer">
                <div className="delivery-detail-total">
                  <div className="delivery-detail-text-pengiriman">
                    <p>Pengiriman</p>
                    <div className="delivery-detail-text-pengiriman-detail">
                      <img
                        src={IconPengiriman}
                        alt="deliveryDetailIcon"
                        className="deliveryDetailIcon"
                      />
                      <p>Bebas Onkir</p>
                    </div>
                  </div>
                  <div className="delivery-detail-text-pengiriman">
                    <p>Total</p>
                    <NumberFormat
                      value={
                        Math.round(shop?.detail[1]?.total_price_after_promo) +
                        Math.round(shop?.detail[2]?.total_non_promo)
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"Rp "}
                      renderText={(value) => <p>{value || 0}</p>}
                    />
                  </div>
                </div>
              </div>
              <div className="line-pengiriman"></div>
            </>
          ) : null}

          {/* produk partner */}
          {idPartners.length !== 0
            ? idPartners.map((id, _index) => {
                const lastArray = partnerShop[id]?.promo_result.length - 1;
                return (
                  <div keys={_index}>
                    <div className="deliveryProductContainer">
                      <div className="nameDistibutor">
                        {partnerShop[id].products[0].shop_name}
                      </div>
                      {partnerShop[id].products?.map((item, index) => {
                        return (
                          <div key={index} className="deliveryProductCard">
                            <div className="deliveryProductImageContainer">
                              {item.data_product.product_image[0] ? (
                                <img
                                  src={
                                    CONFIG.BASE_URL +
                                    item.data_product.product_image[0].src_image
                                  }
                                  alt="deliveryProductImage"
                                  className="deliveryProductImage"
                                />
                              ) : (
                                <img
                                  src={DummyImage}
                                  alt="deliveryProductImage"
                                  className="deliveryProductImage"
                                />
                              )}
                            </div>
                            <div className="deliveryProductInfo">
                              <p className="deliveryProductInfoTitle">
                                {item.name}
                              </p>
                              {item.half ? (
                                <NumberFormat
                                  value={Math.round(item.price_apps / 2)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"Rp "}
                                  renderText={(value) => (
                                    <p className="deliveryProductInfoDetail">
                                      {item.qty} barang x {value || 0}
                                    </p>
                                  )}
                                />
                              ) : (
                                <NumberFormat
                                  value={Math.round(item.price_apps)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"Rp "}
                                  renderText={(value) => (
                                    <p className="deliveryProductInfoDetail">
                                      {item.qty} barang x {value || 0}
                                    </p>
                                  )}
                                />
                              )}
                              {item.half ? (
                                <p className="deliveryProductInfoDetailHalf">
                                  {" ( "}
                                  {item.qty_konversi}{" "}
                                  {item.kecil.charAt(0) +
                                    item.kecil.slice(1).toLowerCase()}
                                  {" )"}
                                </p>
                              ) : null}
                              {item.notes != null && <p>{item.notes}</p>}
                              <NumberFormat
                                value={Math.round(item.total_price)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"Rp "}
                                renderText={(value) => (
                                  <p className="deliveryProductInfoPrice">
                                    {value || 0}
                                  </p>
                                )}
                              />
                            </div>
                            {partnerShop[id]?.promo_result?.map((val, i) => {
                              if (
                                item?.promo_id === val?.promo_id &&
                                val?.promo_all == null &&
                                val?.promo_choose &&
                                val?.promo_choose?.length > 1
                              ) {
                                return (
                                  <div
                                    key={i}
                                    // onClick={toggleDrawer("bottom", true)}
                                    onClick={() => {
                                      setTombolReward(true);
                                      setPilihanReward(val);
                                      setModalIndex(i);
                                      setModalRewards(true);
                                    }}
                                    className="deliveryRewardProductGeneralCard"
                                  >
                                    <div className="deliveryRewardIconContainer">
                                      <img
                                        src={IconReward}
                                        alt="deliveryRewardIcon"
                                        className="deliveryRewardIcon"
                                      />
                                    </div>
                                    <p className="deliveryRewardText">
                                      {p_pilihanReward[i]}
                                    </p>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        );
                      })}
                      {partnerShop[id]?.promo_result?.map((val, i) => {
                        if (
                          val?.promo_choose?.length > 1 &&
                          val?.promo_all == 1
                        ) {
                          return (
                            <div
                              key={i}
                              className="deliveryRewardProductGeneralContainer"
                            >
                              <p className="deliveryRewardProductGeneralTitle">
                                Hadiah Umum
                              </p>
                              <div
                                onClick={() => {
                                  setTombolReward(true);
                                  setPilihanReward(val);
                                  setModalIndex(i);
                                }}
                                className="deliveryRewardProductGeneralCard"
                              >
                                <div className="deliveryRewardIconContainer">
                                  <img
                                    src={IconReward}
                                    alt="deliveryRewardIcon"
                                    className="deliveryRewardIcon"
                                  />
                                </div>
                                <p className="deliveryRewardText">
                                  {p_pilihanReward[i]}
                                </p>
                              </div>
                            </div>
                          );
                        }
                      })}
                    </div>
                    <div className="deliveryDetailContainer">
                      <div
                        className="delivery-btn-promo"
                        onClick={() => getVoucher(id)}
                      >
                        <p className="delivery-btn-promo-text">
                          {partnerShop[id]?.promo_result[lastArray]?.code ||
                            "Pakai promo makin hemat"}
                        </p>
                        <img
                          src={RightArrow}
                          className="delivery-btn-promo-icon"
                        />
                      </div>
                      <div className="delivery-detail-total">
                        <div className="delivery-detail-text-pengiriman">
                          <p>Pengiriman</p>
                          <div className="delivery-detail-text-pengiriman-detail">
                            <img
                              src={IconPengiriman}
                              alt="deliveryDetailIcon"
                              className="deliveryDetailIcon"
                            />
                            <p>Bebas Onkir</p>
                          </div>
                        </div>
                        {partnerShop[id]?.promo_result[lastArray].nominal !==
                          null ||
                        partnerShop[id]?.promo_result[lastArray].disc !==
                          null ? (
                          <div className="delivery-detail-text-pengiriman">
                            <p>{"Potongan Diskon (Voucher)"}</p>
                            <NumberFormat
                              value={Math.round(
                                partnerShop[id]?.promo_result[lastArray]
                                  ?.nominal || 0
                              )}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"Rp "}
                              renderText={(value) => (
                                <p>
                                  {partnerShop[id]?.promo_result[lastArray]
                                    .nominal
                                    ? value
                                    : partnerShop[id]?.promo_result[lastArray]
                                        .disc + "%"}
                                </p>
                              )}
                            />
                          </div>
                        ) : null}
                        <div className="delivery-detail-text-pengiriman">
                          <p>Total</p>
                          <NumberFormat
                            value={
                              Math.round(
                                partnerShop[id]?.detail[1]
                                  .total_price_after_promo
                              ) +
                              Math.round(
                                partnerShop[id]?.detail[2].total_non_promo
                              )
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => <p>{value || 0}</p>}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            : null}

          <div className="deliveryDetailContainer">
            <div className="deliveryDetailBottomContainer">
              <div className="deliveryDetailBottomLeftContainer">
                <p className="deliveryDetailBottomLeftTitle">Total Tagihan</p>
                <NumberFormat
                  value={Math.round(
                    Math.round(shop_total)
                    // hargaPromo[1]?.total_price_after_promo +
                    //   hargaPromo[2]?.total_non_promo
                  )}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp "}
                  renderText={(value) => (
                    <p className="deliveryDetailBottomLeftText">{value || 0}</p>
                  )}
                />
              </div>
              <div className="deliveryDetailBottomRightContainer">
                {pilihPengiriman != "Pilih Pengiriman" ? (
                  <button
                    onClick={() => postOrder()}
                    className="deliveryDetailButton"
                  >
                    <p className="deliveryDetailButtonText">Pilih Pembayaran</p>
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setAlertData("Harap Memilih Pilihan Pengiriman");
                      setAlertJudul("Pemberitahuan");
                      handleClickOpen();
                    }}
                    className="deliveryDetailButton"
                  >
                    <p className="deliveryDetailButtonText">Pilih Pembayaran</p>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <Navbar screenName={"Keranjang"} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  shop_total: state.ProdukReducer.produk,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Delivery);
