import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Slider from "../components/Slider";
import Images from "../images";
import NumberFormat from "react-number-format";
import ReactStars from "react-rating-stars-component";
import ProgressBar from "@ramonak/react-progress-bar";
import "./ProductRating.css";
import Header from "../components/HeaderDistributor";
import Navbar from "../components/Navbar";
import Product1 from "../assets/dummy/product1.jpg";
import IconNotifAll from "../assets/images/NotifAll.webp";
import IconShoppingCart from "../assets/images/Shopping-Cart.webp";
import IconMenu from "../assets/images/Menu.webp";
import IconArrow from "../assets/images/backArrow.webp";
import IconChat from "../assets/images/ChatThumbnail.webp";
import IconInfo from "../assets/images/Info.webp";
import IconLove from "../assets/images/Love.webp";
import IconCart from "../assets/images/Keranjang.webp";
import { useSelector } from "react-redux";
import axios from "axios";
import CONFIG from "../config/config";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import error404 from "../assets/images/404.webp";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

function NoData() {
  return (
    <div className="NoData">
      <img style={{ height: "50%", width: "100%" }} src={error404} />
    </div>
  );
}

export const Home = (props) => {
  const navigate = useNavigate();
  const { produkDesc } = useSelector((state) => state.ProdukReducer);
  const [ratingProduct, setRatingProduct] = useState([]);
  const [commentRating, setCommentRating] = useState([]);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const [loadingApi, setLoadingApi] = useState(true);
  const [
    onEndReachedCalledDuringMomentum,
    setOnEndReachedCalledDuringMomentum,
  ] = useState(false);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);
  const [pilihRating, setPilihRating] = useState("");

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  useEffect(() => {
    getProductRating();
    getCommentRating();
    getFilterRating();

    return () => {};
  }, []);

  //rata-rata rating
  async function getProductRating() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/products/ratings/${produkDesc.id}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      console.log("RatingProduct", data);
      setRatingProduct(data);
      setLoadingApi(false);
    } catch (error) {
      setLoadingApi(false);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  //komen rating
  async function getCommentRating() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/products/${produkDesc.id}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.message;
      setCommentRating(data.review);
      setLoadingApi(false);
    } catch (error) {
      setLoadingApi(false);
      console.log("getMessage====>", error);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  //get filter rating
  async function getFilterRating(star = "") {
    console.log("star", produkDesc.id);
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/products/${produkDesc.id}?star=${star}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.message;
      setCommentRating(data.review);
      setLoadingApi(false);
    } catch (error) {
      setLoadingApi(false);
      console.log(error);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  const handleStarChange = useCallback((item) => {
    setLoadingApi(true);
    console.log(item);
    setPilihRating(item);
    getFilterRating(item);
  });

  //hitung jumlah ulasan
  const subtotalRating = () => {
    if (ratingProduct) {
      return ratingProduct.reduce(
        (sum, item) =>
          sum +
          (parseInt(item.total_five_star) +
            parseInt(item.total_four_star) +
            parseInt(item.total_three_star) +
            parseInt(item.total_two_star) +
            parseInt(item.total_one_star)),
        0
      );
    }
  };

  //load more data
  async function getMoreData() {
    console.log("cekk", !onEndReachedCalledDuringMomentum);
    console.log("cekk", page);
    console.log("cekk", maxPage);
    if (!onEndReachedCalledDuringMomentum && page < maxPage) {
      console.log("masuk load");
      setPage(page + 1);
      setLoadingMore(page < maxPage);
      console.log("ini ketiga", page, loadingMore);
    }
    setOnEndReachedCalledDuringMomentum(page >= maxPage);
  }

  function handleRefresh() {
    setPage(1);
    setRefreshing(true);
  }

  function handleLoadMore() {
    console.log("cekkkkk saiki", !loadingMore);
    if (!loadingMore) return null;
    return (
      <div>
        <CircularProgress color="success" />
      </div>
    );
  }

  return (
    <div className="homeContainer">
      <div className="headerComponent">
        <div onClick={() => navigate(-1)} className="headerArrowContainer">
          <img
            src={IconArrow}
            alt="headerIconArrow"
            className="headerIconArrow"
          />
        </div>
        <div className="headerTitleContainer">
          <p className="headerTitle">Ulasan Produk</p>
        </div>
      </div>
      <div className="productReviewContent">
        {ratingProduct.map((item, index) => {
          return (
            <div key={index} className="productRatingTopContainer">
              <div className="productRatingTopLeftContainer">
                <p className="productRatingTopLeftFirst">{item.avg_star} / 5</p>
                <ReactStars
                  count={5}
                  value={item.avg_star}
                  size={24}
                  edit={false}
                  color={"#adabae"}
                  activeColor={"#ffd700"}
                  classNames="productRatingTopLeftSecond"
                />
                <p className="productRatingTopLeftThird">
                  {subtotalRating()} Ulasan
                </p>
              </div>
              <div className="productRatingTopRightContainer">
                <div className="productRatingTopRightRow">
                  <ReactStars
                    count={5}
                    value={5}
                    size={24}
                    edit={false}
                    color={"#adabae"}
                    activeColor={"#ffd700"}
                    classNames="productRatingTopRight"
                  />
                  <ProgressBar
                    completed={item.percent_five_star.replace("%", "") || 0}
                    maxCompleted={100}
                    className="productRatingWrapper"
                    bgColor="#fdc22f"
                    baseBgColor="#fce8b1"
                    height="10px"
                    isLabelVisible={false}
                  />
                  <p className="productRatingTopRightText">
                    {item.total_five_star}
                  </p>
                </div>
                <div className="productRatingTopRightRow">
                  <ReactStars
                    count={5}
                    value={4}
                    size={24}
                    edit={false}
                    color={"#adabae"}
                    activeColor={"#ffd700"}
                    classNames="productRatingTopRight"
                  />
                  <ProgressBar
                    completed={item.percent_four_star.replace("%", "") || 0}
                    maxCompleted={100}
                    className="productRatingWrapper"
                    bgColor="#fdc22f"
                    baseBgColor="#fce8b1"
                    height="10px"
                    isLabelVisible={false}
                  />
                  <p className="productRatingTopRightText">
                    {item.total_four_star}
                  </p>
                </div>
                <div className="productRatingTopRightRow">
                  <ReactStars
                    count={5}
                    value={3}
                    size={24}
                    edit={false}
                    color={"#adabae"}
                    activeColor={"#ffd700"}
                    classNames="productRatingTopRight"
                  />
                  <ProgressBar
                    completed={item.percent_three_star.replace("%", "") || 0}
                    maxCompleted={100}
                    className="productRatingWrapper"
                    bgColor="#fdc22f"
                    baseBgColor="#fce8b1"
                    height="10px"
                    isLabelVisible={false}
                  />
                  <p className="productRatingTopRightText">
                    {item.total_three_star}
                  </p>
                </div>
                <div className="productRatingTopRightRow">
                  <ReactStars
                    count={5}
                    value={2}
                    size={24}
                    edit={false}
                    color={"#adabae"}
                    activeColor={"#ffd700"}
                    classNames="productRatingTopRight"
                  />
                  <ProgressBar
                    completed={item.percent_two_star.replace("%", "") || 0}
                    maxCompleted={100}
                    className="productRatingWrapper"
                    bgColor="#fdc22f"
                    baseBgColor="#fce8b1"
                    height="10px"
                    isLabelVisible={false}
                  />
                  <p className="productRatingTopRightText">
                    {item.total_two_star}
                  </p>
                </div>
                <div className="productRatingTopRightRow">
                  <ReactStars
                    count={5}
                    value={1}
                    size={24}
                    edit={false}
                    color={"#adabae"}
                    activeColor={"#ffd700"}
                    classNames="productRatingTopRight"
                  />
                  <ProgressBar
                    completed={item.percent_one_star.replace("%", "") || 0}
                    maxCompleted={100}
                    className="productRatingWrapper"
                    bgColor="#fdc22f"
                    baseBgColor="#fce8b1"
                    height="10px"
                    isLabelVisible={false}
                  />
                  <p className="productRatingTopRightText">
                    {item.total_one_star}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
        <div className="productRatingCenterContainer">
          <div
            onClick={() => handleStarChange()}
            className="productRatingCenterCard"
          >
            <p className="productRatingCenterText">Semua</p>
          </div>
          <div
            onClick={() => handleStarChange("5")}
            className="productRatingCenterCard"
          >
            <ReactStars
              count={1}
              size={40}
              edit={false}
              color={"#ffd700"}
              classNames="productRatingCenterStar"
            />
            <p className="productRatingCenterText">5</p>
          </div>
          <div
            onClick={() => handleStarChange("4")}
            className="productRatingCenterCard"
          >
            <ReactStars
              count={1}
              size={40}
              edit={false}
              color={"#ffd700"}
              classNames="productRatingCenterStar"
            />
            <p className="productRatingCenterText">4</p>
          </div>
          <div
            onClick={() => handleStarChange("3")}
            className="productRatingCenterCard"
          >
            <ReactStars
              count={1}
              size={40}
              edit={false}
              color={"#ffd700"}
              classNames="productRatingCenterStar"
            />
            <p className="productRatingCenterText">3</p>
          </div>
          <div
            onClick={() => handleStarChange("2")}
            className="productRatingCenterCard"
          >
            <ReactStars
              count={1}
              size={40}
              edit={false}
              color={"#ffd700"}
              classNames="productRatingCenterStar"
            />
            <p className="productRatingCenterText">2</p>
          </div>
          <div
            onClick={() => handleStarChange("1")}
            className="productRatingCenterCard"
          >
            <ReactStars
              count={1}
              size={40}
              edit={false}
              color={"#ffd700"}
              classNames="productRatingCenterStar"
            />
            <p className="productRatingCenterText">1</p>
          </div>
        </div>
        <Loading loadingApi={loadingApi} />
        {commentRating.length > 0 && !loadingApi ? (
          <div className="productRatingBottomContainer">
            <p className="productRatingBottomTitle">Daftar Ulasan Produk</p>
            {commentRating.map((itemRating, indexRating) => {
              return (
                <div key={indexRating} className="productRatingBottomCard">
                  <ReactStars
                    count={5}
                    value={itemRating.star_review}
                    size={40}
                    edit={false}
                    color={"#adabae"}
                    activeColor={"#ffd700"}
                    classNames="productRatingBottomStar"
                  />
                  <p className="productRatingBottomText">
                    Oleh {itemRating.user?.name}
                  </p>
                  <p className="productRatingBottomDate">
                    {moment(itemRating.created_at).format("DD MMM YYYY HH:mm")}
                  </p>
                  {itemRating.detail_review != null && (
                    <p
                      className="productRatingBottomText"
                      style={{ marginBottom: 5 }}
                    >
                      {itemRating.detail_review}
                    </p>
                  )}
                  {itemRating.detail_review == null && (
                    <p
                      className="productRatingBottomText"
                      style={{ marginBottom: 5 }}
                    >
                      Tidak ada ulasan
                    </p>
                  )}
                </div>
              );
            })}
          </div>
        ) : commentRating.length == 0 && !loadingApi ? (
          <NoData />
        ) : null}
      </div>
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
