import React, { useEffect, useState } from "react";
import Header from "../components/HeaderDistributor";
import { connect, useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import CONFIG from "../config/config";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useNavigate, useParams } from "react-router-dom";
import error404 from "../assets/images/404.webp";
import IconSearch from "../assets/images/Search.webp";
import { produkAction, screenAction, orderAction } from "../actions";
import "./DistributorUlasan.css";
import InfiniteScroll from "react-infinite-scroll-component";
import DummyImage from "../assets/images/Logo.webp";
import moment from "moment";
import ReactStars from "react-rating-stars-component";

function Loading(props) {
  return props.visible ? (
    <div className="loading">
      <CircularProgress color="success" />
    </div>
  ) : null;
}

export const DistributorUlasan = (props, route) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [loadingApi, setLoadingApi] = useState(true);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);
  const [notifOrders, setNotifOrders] = useState(0);
  const [notifMessages, setNotifMessages] = useState(0);
  const [notifComplaints, setNotifComplaints] = useState(0);
  const [notifBroadcast, setNotifBroadcast] = useState(0);
  const [notifSubscribe, setNotifSubscribe] = useState(0);
  const [countNotifCart, setCountNotifCart] = useState(0);
  const [page, setPage] = useState(1);
  const [listDataReview, setListDataReview] = useState([]);
  const [params, setParams] = useState({});

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  useEffect(() => {
    getNotifAll();
    getHistoryRating("");
  }, []);

  async function getNotifAll() {
    // console.log("cek hit notif");
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/notification-all`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      // console.log('DATA TEST', data.total_chat);
      setNotifOrders(data.total_order);
      setNotifMessages(data.total_chat);
      setNotifComplaints(data.total_complaint);
      setNotifSubscribe(data.total_subscribe);
      setNotifBroadcast(data.total_broadcast);
      setCountNotifCart(data.total_cart);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========NOTIF================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  async function getHistoryRating(item) {
    setLoadingApi(true);
    try {
      let response;
      response =
        item === ""
          ? await axios.get(
              `${CONFIG.BASE_URL}/api/distributor-partner/reviews?review=true&page=${page}`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
          : await axios.get(
              `${CONFIG.BASE_URL}/api/distributor-partner/reviews?review=true&page=${page}&search=${item}`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            );
      const data = response.data.data;
      setLoadingApi(false);
      setListDataReview(data.data);
      // console.log(data.data);
    } catch (error) {
      setLoadingApi(false);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  function handleSearch(item) {
    console.log("handleSearch " + JSON.stringify(item));
    if (item === "") {
      setSearch("");
      getHistoryRating("");
    } else {
      setSearch(item);
      getHistoryRating(item);
    }
  }

  const Content = () =>
    listDataReview.length > 0 && !loadingApi ? (
      <InfiniteScroll dataLength={listDataReview.length}>
        {listDataReview?.map((item, index) => {
          return (
            <div
              key={index}
              className="viewContent"
              onClick={() => {
                dispatch(orderAction(item, "orderDetail"));
                navigate("/menu/distributor/ulasan/detail?id=" + item?.id);
              }}
            >
              <a className="textTanggal">
                {moment(item?.data_review[0]?.review?.created_at).format(
                  "DD MMM YYYY HH:mm"
                )}
              </a>
              <div className="ulasanCard">
                {item?.data_review[0]?.review?.product_image.length ? (
                  <img
                    className="viewImgUlasan"
                    src={
                      CONFIG.BASE_URL +
                      item?.data_review[0]?.review?.product_image[0]?.path
                    }
                  />
                ) : (
                  <img className="viewImgUlasan" src={DummyImage} />
                )}

                {/* </div> */}
                <div className="viewTextUlasan">
                  <span>
                    <a className="viewUlasanTitle">
                      {item?.data_review[0]?.review?.name}
                    </a>
                  </span>
                  <ReactStars
                    size={20}
                    value={parseInt(item?.data_review[0]?.review?.star_review)}
                    edit={false}
                  />
                  <div style={{ marginTop: "2%" }}>
                    <span>
                      <a className="viewValueUlasan">
                        {item?.data_review[0]?.review?.detail_review}
                      </a>
                    </span>
                    <span>
                      <a className="viewUlasanLainnya">
                        + {item?.data_review.length - 1} produk lainnya
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </InfiniteScroll>
    ) : listDataReview.length == 0 && !loadingApi ? null : null;

  const Content2 = () =>
    listDataReview.length > 0 && !loadingApi ? (
      <InfiniteScroll dataLength={listDataReview.length}>
        {listDataReview?.map((item) => {
          return item.data_review.map((_item, _index) => {
            return _item?.review !== null ? (
              <div
                // key={_index}
                className="viewContent"
                onClick={() => {
                  dispatch(orderAction(item, "orderDetail"));
                  navigate("/menu/distributor/ulasan/detail?id=" + item?.id);
                }}
              >
                <a className="textTanggal">
                  {moment(_item?.review?.created_at).format(
                    "DD MMM YYYY HH:mm"
                  )}
                </a>
                <div className="ulasanCard">
                  {_item?.review?.product_image.length ? (
                    <img
                      className="viewImgUlasan"
                      src={
                        CONFIG.BASE_URL + _item?.review?.product_image[0]?.path
                      }
                    />
                  ) : (
                    <img className="viewImgUlasan" src={DummyImage} />
                  )}
                  <div className="viewTextUlasan">
                    <span>
                      <a className="viewUlasanTitle">{_item?.review?.name}</a>
                    </span>
                    <ReactStars
                      size={20}
                      value={parseInt(_item?.review?.star_review)}
                      edit={false}
                    />
                    <div style={{ marginTop: "2%" }}>
                      <span>
                        <a className="viewValueUlasan">
                          {_item?.review?.detail_review}
                        </a>
                      </span>
                      <span>
                        <a className="viewUlasanLainnya">
                          + {item?.data_review.length - 1} produk lainnya
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ) : null;
          });
        })}
      </InfiniteScroll>
    ) : listDataReview.length == 0 && !loadingApi ? null : null;

  return (
    <div className="ulasanContainer">
      <div className="ulasanHeader">
        <Header
          notifMessages={notifMessages}
          notifSubscribe={notifSubscribe}
          notifOrders={notifOrders}
          notifComplaints={notifComplaints}
          notifBroadcast={notifBroadcast}
          countNotifCart={countNotifCart}
          // simpanSearch={simpanSearch}
          titleHeader="Riwayat Ulasan"
          badge={true}
          linkProfile={true}
          item={false}
        />
      </div>
      <div className="ulasanContent">
        <div className="viewCariUlasan">
          <input
            className="viewInputcariUlasan"
            placeholder="Pencarian..."
            type="text"
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
          />
          <img
            src={IconSearch}
            alt="headerSearchLogo"
            className="imageCariUlasan"
          />
        </div>
        {search === "" ? <Content /> : <Content2 />}
      </div>
      <Loading visible={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DistributorUlasan);
