import React, {useEffect} from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import IconArrow from "../assets/images/backArrow.webp";
import IconSearch from "../assets/images/Search.webp";
import "./ReviewHistory.css";
import Product1 from "../assets/dummy/product1.jpg";
import Product2 from "../assets/dummy/product2.jpg";
import ReactStars from "react-rating-stars-component";

export const ReviewHistroy = (props) => {
const navigate = useNavigate();
    return (
        <div className="reviewHistoryContainer">
            <div className="headerReview">
                <div className="headerFirstContainer">
                    <div className="headerArrowContainer">
                        <img src={IconArrow} alt="headerIconArrow" className="headerIconArrow" />
                    </div>
                    <div className="headerTitleContainer">
                        <p className="headerTitle">Daftar Ulasan</p>
                    </div>
                </div>
                <div className="headerSecondContainer">
                    <button className="headerSecondMenuButton">MENUNGGU ULASAN</button>
                    <button className="headerSecondMenuButton">RIWAYAT</button>
                </div>
                <div className="reviewHistorySearch">
                    <div className="reviewHistorySearchContainer">
                        <input
                            className="reviewHistoryTextInput"
                            placeholder="Ketik kata kunci yang ingin anda cari"
                            type="text"
                        />
                        <img src={IconSearch} alt="headerSearchLogo" className="reviewHistorySearchLogo" />
                    </div>
                </div>
            </div>
            <div className="reviewHistoryCard">
                <hr></hr>
                <div className="card">
                    <img src={Product1} alt="image" className="image" />
                    <div className="info">
                        <div className="name">
                            Rapet Wangin Kaplet Box (Isi 1 Blister)
                        </div>
                        <div className="star">
                            <ReactStars
                                count={5}
                                value={3}
                                size={20}
                                edit={false}
                                color={"#adabae"}
                                activeColor={"#ffd700"}
                                classNames="productRatingBottomStar"
                            />
                        </div>
                        <div className="date">
                            14 Februari 2022 pukul 10.55
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="card">
                    <img src={Product2} alt="image" className="image" />
                    <div className="info">
                        <div className="name">
                            Freshcare Press & Relax
                        </div>
                        <div className="star">
                            <ReactStars
                                count={5}
                                value={5}
                                size={20}
                                edit={false}
                                color={"#adabae"}
                                activeColor={"#ffd700"}
                                classNames="productRatingBottomStar"
                            />
                        </div>
                        <div className="date">
                            14 Februari 2022 pukul 10.55
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewHistroy);
