import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import IconBack from "../assets/images/backArrow.webp";
import "./TransactionDetail.css";
import Product1 from "../assets/dummy/product1.jpg";
import Product2 from "../assets/dummy/product2.jpg";
import Product3 from "../assets/dummy/product3.jpg";
import Product4 from "../assets/dummy/product4.jpg";
import { useSelector } from "react-redux";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import CircularProgress from "@mui/material/CircularProgress";
import { produkAction, screenAction, orderAction } from "../actions";
import { useDispatch } from "react-redux";
import FormDialog from "../components/Dialog";
import NumberFormat from "react-number-format";
import moment from "moment";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Header from "../components/HeaderDistributor";
import ModalTracking from "../components/ModalTracking";

export const TransactionDetail = (props) => {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  // const { orderDetail } = useSelector((state) => state.OrderReducer);
  // console.log("orderDetail", JSON.stringify(orderDetail));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadingApi, setLoadingApi] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [buttonDisabled2, setButtonDisabled2] = useState(false);
  const [notes, setNotes] = useState("");
  const [open, setOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [alertTambahan, setAlertTambahan] = useState("");
  const [alertJudul, setAlertJudul] = useState("");
  const [cancelOrder, setCancelOrder] = useState(false);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);
  const [orderDetail, setOrderDetail] = useState({});
  const [dataTracking, setDataTracking] = useState(false);
  const [openModalTracking, setOpenModalTracking] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("transaksi");

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  useEffect(() => {
    if (loadingApi) {
      getDetails();
      getDataTracking();
      setTimeout(() => setLoadingApi(false), 10000);
    }

    return () => {};
  }, [loadingApi]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async (e) => {
    setOpen(false);
  };

  async function postCancelOrder() {
    if (dataUser.user.account_type == 5) {
      try {
        let response = await axios({
          method: "post",
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          data: { status: 10 },
          url: `${CONFIG.BASE_URL}/api/distributor-partner/transactions/${orderDetail.id}`,
        });
        const data = response.data;
        if (data !== "" && data["success"] == true) {
          setButtonDisabled(!buttonDisabled);
          setCancelOrder(!cancelOrder);
          navigate(-1);
        } else {
          setAlertData("Gagal membatalkan pesanan");
          setAlertJudul("Pemberitahuan");
          handleClickOpen();
          setButtonDisabled(!buttonDisabled);
          setCancelOrder(!cancelOrder);
          console.log("Gagal membatalkan pesanan===>", data);
        }
      } catch (error) {
        setCancelOrder(!cancelOrder);
        setButtonDisabled(!buttonDisabled);
        let error429 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 429";
        let errorNetwork =
          JSON.parse(JSON.stringify(error)).message === "Network Error";
        let error400 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 400";
        console.log(
          "Cek Error========================",
          JSON.parse(JSON.stringify(error)).message
        );
        if (error429) {
          setAlertServerSibuk(true);
        } else if (errorNetwork) {
          setAlertInet(true);
        } else if (error400) {
          localStorage.removeItem("token");
          navigate("/home");
        }
      }
    } else {
      if (!notes.trim()) {
        setCancelOrder(!cancelOrder);
        setButtonDisabled(!buttonDisabled);
        setAlertData("Harap masukkan alesan kamu");
        setAlertJudul("Pemberitahuan");
        handleClickOpen();
      } else if (notes.length < 8) {
        setCancelOrder(!cancelOrder);
        setButtonDisabled(!buttonDisabled);
        setAlertData("Alasan pembatalan minimal 8 huruf");
        setAlertJudul("Pemberitahuan");
        handleClickOpen();
      } else {
        try {
          let response = await axios({
            method: "post",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            data: { notes: notes },
            url: `${CONFIG.BASE_URL}/api/orders/cancel/${orderDetail.id}`,
          });
          const data = response.data;
          if (data !== "" && data["success"] == true) {
            setButtonDisabled(!buttonDisabled);
            setCancelOrder(!cancelOrder);
            navigate(-1);
          } else {
            setAlertData("Gagal membatalkan pesanan");
            setAlertJudul("Pemberitahuan");
            handleClickOpen();
            setButtonDisabled(!buttonDisabled);
            setCancelOrder(!cancelOrder);
            console.log("Gagal membatalkan pesanan===>", data);
          }
        } catch (error) {
          setCancelOrder(!cancelOrder);
          setButtonDisabled(!buttonDisabled);
          let error429 =
            JSON.parse(JSON.stringify(error)).message ===
            "Request failed with status code 429";
          let errorNetwork =
            JSON.parse(JSON.stringify(error)).message === "Network Error";
          let error400 =
            JSON.parse(JSON.stringify(error)).message ===
            "Request failed with status code 400";
          console.log(
            "Cek Error========================",
            JSON.parse(JSON.stringify(error)).message
          );
          if (error429) {
            setAlertServerSibuk(true);
          } else if (errorNetwork) {
            setAlertInet(true);
          } else if (error400) {
            localStorage.removeItem("token");
            navigate("/home");
          }
        }
      }
    }
  }

  async function postCompleteOrder() {
    try {
      let response = await axios.post(
        `${CONFIG.BASE_URL}/api/orders/complete/${orderDetail.id}`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      let data = response.data;
      if (data !== "" && data["success"] == true) {
        setButtonDisabled2(!buttonDisabled2);
        navigate(-1);
      } else {
        setAlertData("Gagal menyelesaikan order");
        setAlertJudul("Pemberitahuan");
        handleClickOpen();
        setButtonDisabled2(!buttonDisabled2);
        console.log("Gagal menyelesaikan order===>", data);
      }
    } catch (error) {
      setButtonDisabled2(!buttonDisabled2);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  async function getDetails() {
    try {
      let response = await axios({
        method: "GET",
        url: `${
          CONFIG.BASE_URL
        }/api/distributor-partner/transactions/detail/${parseInt(id)}`,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const data = response.data;
      if (data["success"] == true) {
        setOrderDetail(data.data);
        setLoadingApi(false);
      } else {
        setLoadingApi(false);
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  function handleCloseCancelOrder() {
    setButtonDisabled(false);
    setCancelOrder(!cancelOrder);
  }

  async function getDataTracking() {
    try {
      let response = await axios.get(`${CONFIG.BASE_URL}/api/delivery`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const data = response?.data;
      console.log("tracking " + JSON.stringify(data));
      if (data["success"] == true) {
        setDataTracking(data?.data);
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  const postNextTransaction = async () => {
    try {
      let response = await axios({
        method: "post",
        url: `${CONFIG.BASE_URL}/api/distributor-partner/transactions/${orderDetail.id}`,
        headers: {
          // 'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        // data: createFormData(this.state.photo, {
        //   title: this.state.title,
        //   content: this.state.detail,
        // }),
      });
      let data = response.data;
      if (data !== "" && data["success"] == true) {
        navigate(-1);
      } else {
        console.log("Gagal membatalkan pesanan===>", data);
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  };
  function handleModalTracking() {
    setOpenModalTracking(false);
  }
  return (
    <div className="transactionDetailContainer">
      <FormDialog
        alertData={alertData}
        alertJudul={alertJudul}
        handleClose={handleClose}
        open={open}
      />
      <ModalTracking
        open={openModalTracking}
        handleClose={handleModalTracking}
        title={
          dataTracking?.city === undefined
            ? "Pesanan tidak dapat dilacak"
            : "Pesanan Anda Berada di daerah"
        }
        alamat={
          dataTracking?.city === undefined
            ? null
            : "Desa " +
              dataTracking?.village +
              ", Kecamatan " +
              dataTracking?.districts +
              ", Kota " +
              dataTracking?.city +
              ", Provinsi " +
              dataTracking?.province
        }
      ></ModalTracking>
      <Dialog open={cancelOrder} onClose={handleCloseCancelOrder}>
        <DialogTitle>Form Pembatalan</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Apakah anda yakin membatalkan pesanan?
          </DialogContentText>
          <TextField
            onChange={(e) => setNotes(e.target.value)}
            autoFocus
            margin="dense"
            id="name"
            label="Tuliskan alasanmu disni"
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCancelOrder}>Lewati</Button>
          <Button onClick={postCancelOrder}>Simpan dan lanjutkan</Button>
        </DialogActions>
      </Dialog>
      <div className="headerComponent">
        <Header titleHeader="Detail transaksi" />
      </div>
      <div className="transactionDetailCard">
        <div className="flex">
          <div className="title">Status</div>
          {orderDetail.status == 1 && orderDetail.status_faktur == "F" ? (
            <div className="value">Transaksi Baru</div>
          ) : orderDetail.status == 2 && orderDetail.status_faktur == "F" ? (
            <div className="value">Transaksi Terkonfirmasi</div>
          ) : orderDetail.status == 3 && orderDetail.status_faktur == "F" ? (
            <div className="value">Barang Diproses</div>
          ) : orderDetail.status == 4 && orderDetail.status_faktur == "F" ? (
            <div className="value">Selesai</div>
          ) : orderDetail.status == 10 && orderDetail.status_faktur == "F" ? (
            <div className="value">Batal</div>
          ) : (orderDetail.status == 1 || 2 || 3 || 4 || 10) &&
            orderDetail.status_faktur == "R" ? (
            <div className="value">Retur Barang</div>
          ) : (orderDetail.status == 1 || 2 || 3 || 4 || 10) &&
            orderDetail.status_faktur == "Redeem" ? (
            <div className="value">Redeem</div>
          ) : null}
        </div>
        <hr></hr>
        <div className="flex">
          <div className="title">Nomor Invoice</div>
          {orderDetail.invoice ? (
            <div className="value">{orderDetail.invoice}</div>
          ) : (
            <div className="value">{"Kosong"}</div>
          )}
        </div>
        <hr></hr>
        <div className="flex">
          <div className="title">Tanggal Pembelian</div>
          <div className="value">
            {moment(orderDetail.created_at).format("DD MMM YYYY HH:mm")}
          </div>
        </div>
      </div>
      <hr></hr>
      <div className="transactionDetailCard">
        Detail Produk
        {orderDetail.data_item?.map((item, index) => {
          if (item.product) {
            return (
              <div key={index} className="productList">
                <div className="main">
                  {item.product.product_image.length > 0 ? (
                    <img
                      src={CONFIG.BASE_URL + item.product.product_image[0].path}
                    />
                  ) : (
                    <img src={DummyImage} />
                  )}
                  <div className="content">
                    <div className="name">{item.product.name}</div>
                    <div className="halfView">
                      {orderDetail.status_faktur == "Redeem" ? (
                        <NumberFormat
                          value={Math.round(item?.price_apps) ?? "0"}
                          displayType={"text"}
                          thousandSeparator={true}
                          // prefix={"Rp "}
                          suffix={" poin"}
                          // decimalScale={2}
                          fixedDecimalScale={true}
                          renderText={(value) => (
                            <div className="price">
                              {item.qty}
                              {" x "}
                              {value || 0}
                            </div>
                          )}
                        />
                      ) : (
                        <>
                          {item.half ? (
                            <NumberFormat
                              value={Math.round(item?.price_apps / 2) ?? "0"}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"Rp "}
                              // decimalScale={2}
                              fixedDecimalScale={true}
                              renderText={(value) => (
                                <div className="price">
                                  {item.qty}
                                  {" x "}
                                  {value || 0}
                                </div>
                              )}
                            />
                          ) : (
                            <NumberFormat
                              value={Math.round(item?.price_apps) ?? "0"}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"Rp "}
                              // decimalScale={2}
                              fixedDecimalScale={true}
                              renderText={(value) => (
                                <div className="price">
                                  {item.qty}
                                  {" x "}
                                  {value || 0}
                                </div>
                              )}
                            />
                          )}
                        </>
                      )}
                      {item.half ? (
                        <div className="priceHalf">
                          {" ( "}
                          {item?.qty_konversi}{" "}
                          {item?.small_unit.charAt(0) +
                            item?.small_unit.slice(1).toLowerCase()}
                          {" )"}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className="footer">
                  <table>
                    <tr>
                      {orderDetail.status_faktur == "Redeem" ? (
                        <td className="first">Total Poin</td>
                      ) : (
                        <td className="first">Total Harga</td>
                      )}
                      <td> : </td>
                      {orderDetail.status_faktur == "Redeem" ? (
                        <NumberFormat
                          value={Math.round(item.total_price)}
                          displayType={"text"}
                          thousandSeparator={true}
                          // prefix={"Rp "}
                          suffix={" poin"}
                          // decimalScale={2}
                          fixedDecimalScale={true}
                          renderText={(value) => (
                            <td className="two">{value || 0}</td>
                          )}
                        />
                      ) : (
                        <NumberFormat
                          value={Math.round(item.total_price)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"Rp "}
                          // decimalScale={2}
                          fixedDecimalScale={true}
                          renderText={(value) => (
                            <td className="two">{value || 0}</td>
                          )}
                        />
                      )}
                    </tr>
                    {item.notes ? (
                      <tr>
                        <td className="first">Catatan</td>
                        <td> : </td>
                        <td className="two">{item.notes}</td>
                      </tr>
                    ) : null}
                  </table>
                </div>
              </div>
            );
          }
        })}
      </div>
      <hr></hr>
      <div className="transactionDetailCard">
        Info Pengiriman
        <div className="flex">
          <div className="title">Kurir</div>
          <div className="value">Bebas Ongkir</div>
        </div>
        <hr></hr>
        <div className="flex">
          <div className="title">Alamat</div>
          {orderDetail.kelurahan ? (
            <div className="value">
              {orderDetail.address}
              {", "}
              {orderDetail.kelurahan}
              {", "}
              {orderDetail.kecamatan}
              {", "}
              {orderDetail.kota}
              {", "}
              {orderDetail.provinsi}
              {", "}
              {orderDetail.kode_pos}
            </div>
          ) : (
            <div className="value">(orderDetail.address)</div>
          )}
        </div>
        {orderDetail.status === 3 ? (
          <>
            <hr></hr>
            <div className="flex">
              <div className="btnTracking">
                <button
                  onClick={() => {
                    setOpenModalTracking(true);
                  }}
                  className="textBtnTracking"
                >
                  Tracking Pesanan
                </button>
              </div>
            </div>
          </>
        ) : null}
      </div>
      <hr></hr>
      <div
        className={
          orderDetail.status_faktur == "Redeem"
            ? "_transactionDetailCardLastRedeem"
            : dataUser.user.account_type == 5
            ? "_transactionDetailCardLastRedeem"
            : "_transactionDetailCardLast"
        }
      >
        Rincian Pembayaran
        <div className="flex">
          <div className="title">Mode Pembayaran</div>
          {orderDetail.payment_method == "cod" ? (
            <div className="value">Bayar langsung di tempat (COD)</div>
          ) : orderDetail.payment_method == "tempo" ? (
            <div className="value">Tempo</div>
          ) : orderDetail.payment_method == "redeem" ? (
            <div className="value">Redeem</div>
          ) : null}
        </div>
        {orderDetail.payment_discount ? (
          <>
            <hr></hr>
            <div className="flex">
              <div className="title">Nominal Diskon</div>
              {orderDetail.payment_discount_type == "nominal" ? (
                <NumberFormat
                  value={Math.round(orderDetail.payment_discount) ?? "0"}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"-Rp "}
                  renderText={(value) => (
                    <div className="value">{value || 0}</div>
                  )}
                />
              ) : (
                <NumberFormat
                  value={Math.round(orderDetail.payment_discount) ?? "0"}
                  displayType={"text"}
                  thousandSeparator={true}
                  suffix={" %"}
                  renderText={(value) => (
                    <div className="value">{value || 0}</div>
                  )}
                />
              )}
            </div>
          </>
        ) : null}
        <hr></hr>
        {orderDetail.status_faktur == "Redeem" ? (
          <div className="flex">
            <div className="title">Total poin</div>
            <NumberFormat
              value={Math.round(orderDetail.payment_total) ?? "0"}
              displayType={"text"}
              thousandSeparator={true}
              // prefix={"Rp "}
              suffix={" poin"}
              renderText={(value) => <div className="value">{value || 0}</div>}
            />
          </div>
        ) : (
          <div className="flex">
            <div className="title">Total Harga</div>
            <NumberFormat
              value={Math.round(orderDetail.payment_total) ?? "0"}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"Rp "}
              renderText={(value) => <div className="value">{value || 0}</div>}
            />
          </div>
        )}
        {orderDetail.point ? (
          <>
            <hr></hr>
            <div className="flex">
              <div className="title">Promo Bonus Poin</div>
              <NumberFormat
                value={Math.round(orderDetail.point) ?? "0"}
                displayType={"text"}
                thousandSeparator={true}
                // prefix={"Rp "}
                suffix={" poin"}
                renderText={(value) => (
                  <div className="value">{value || 0}</div>
                )}
              />
            </div>
          </>
        ) : null}
        {orderDetail.payment_point ? (
          <>
            <hr></hr>
            <div className="flex">
              <div className="title">Poin Telah Digunakan</div>
              <NumberFormat
                value={Math.round(orderDetail.payment_point) ?? "0"}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"- "}
                suffix={" poin"}
                renderText={(value) => (
                  <div className="value">{value || 0}</div>
                )}
              />
            </div>
          </>
        ) : null}
        <hr></hr>
        <div className="flex">
          <div className="title">Total Ongkos Kirim</div>
          <NumberFormat
            value={Math.round(orderDetail.delivery_fee) ?? "0"}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"Rp "}
            renderText={(value) => <div className="value">{value || 0}</div>}
          />
        </div>
        <hr></hr>
        {orderDetail.status_faktur == "Redeem" ? (
          <div className="flex">
            <div className="total">Total Pembelian</div>
            <NumberFormat
              value={Math.round(orderDetail.payment_final) ?? "0"}
              displayType={"text"}
              thousandSeparator={true}
              // prefix={"Rp "}
              suffix={" poin"}
              renderText={(value) => (
                <div className="totalPrice">{value || 0}</div>
              )}
            />
          </div>
        ) : (
          <div className="flex">
            <div className="total">Total Bayar</div>
            <NumberFormat
              value={Math.round(orderDetail.payment_final) ?? "0"}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"Rp "}
              renderText={(value) => (
                <div className="totalPrice">{value || 0}</div>
              )}
            />
          </div>
        )}
        {orderDetail.status == "1" && dataUser.user.account_type == 5 && (
          <>
            {buttonDisabled ? (
              <button
                // onClick={() => setButtonDisabled(false)}
                className="buttonDisabled"
              >
                <p className="textDisabled">{"Konfirmasi Pesanan"}</p>
              </button>
            ) : (
              <button
                onClick={() => {
                  postNextTransaction();
                  setButtonDisabled(true);
                }}
                className="buttonSelesai"
              >
                <p className="textBatal">{"Konfirmasi Pesanan"}</p>
              </button>
            )}
          </>
        )}
        {orderDetail.status == "2" && dataUser.user.account_type == 5 && (
          <>
            {buttonDisabled ? (
              <button
                // onClick={() => setButtonDisabled(false)}
                className="buttonDisabled"
              >
                <p className="textDisabled">
                  {"Konfirmasi Pengiriman Pesanan"}
                </p>
              </button>
            ) : (
              <button
                onClick={() => {
                  postNextTransaction();
                  setButtonDisabled(true);
                }}
                className="buttonSelesai"
              >
                <p className="textBatal">{"Konfirmasi Pengiriman Pesanan"}</p>
              </button>
            )}
          </>
        )}
        {orderDetail.status == "3" && dataUser.user.account_type == 5 && (
          <>
            {buttonDisabled ? (
              <button
                // onClick={() => setButtonDisabled(false)}
                className="buttonDisabled"
              >
                <p className="textDisabled">{"Konfirmasi Pesanan Selesai"}</p>
              </button>
            ) : (
              <button
                onClick={() => {
                  postNextTransaction();
                  setButtonDisabled(true);
                }}
                className="buttonSelesai"
              >
                <p className="textBatal">{"Konfirmasi Pesanan Selesai"}</p>
              </button>
            )}
          </>
        )}
        {orderDetail.status == "3" && dataUser.user.account_type !== 5 && (
          <>
            {buttonDisabled2 ? (
              <button
                // onClick={() => setButtonDisabled2(false)}
                className="buttonDisabled"
              >
                <p className="textDisabled">{"Selesai"}</p>
              </button>
            ) : (
              <button
                onClick={() => {
                  postCompleteOrder();
                  setButtonDisabled2(true);
                }}
                className="buttonSelesai"
              >
                <p className="textSelesai">{"Selesai"}</p>
              </button>
            )}
          </>
        )}
        {/* {orderDetail.status == "1" && (
          <>
            {buttonDisabled ? (
              <button
                // onClick={() => setButtonDisabled(false)}
                className="buttonDisabled"
              >
                <p className="textDisabled">{"Batal Transaksi"}</p>
              </button>
            ) : (
              <button
                onClick={() => {
                  setCancelOrder(true);
                  setButtonDisabled(true);
                }}
                className="buttonBatal"
              >
                <p className="textBatal">{"Batal Transaksi"}</p>
              </button>
            )}
          </>
        )} */}
      </div>
      {orderDetail.status_faktur == "Redeem" ? null : (
        <>
          {dataUser.user.account_type == 5 ? (
            <div className="transactionDetailFooter">
              <button
                onClick={() => {
                  setLoadingApi(true);
                  getDetails();
                }}
                className="checkStatusButton"
              >
                <p className="checkStatusText">{"Check Status Pesanan"}</p>
              </button>
            </div>
          ) : null}
        </>
      )}
      <Loading loadingApi={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionDetail);
