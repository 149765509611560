import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Logo from "../assets/images/Logo.webp";
import IconSearch from "../assets/images/Search.webp";
import IconNotifAll from "../assets/images/NotifAll.webp";
import IconShoppingCart from "../assets/images/Shopping-Cart.webp";
import IconMenu from "../assets/images/Menu.webp";
import "./ProdukList.css";
import Navbar from "../components/Navbar";
import Product1 from "../assets/dummy/product1.jpg";
import Product2 from "../assets/dummy/product2.jpg";
import Product3 from "../assets/dummy/product3.jpg";
import Product4 from "../assets/dummy/product4.jpg";
import Product5 from "../assets/dummy/product5.jpg";
import Product6 from "../assets/dummy/product6.jpg";
import { useSelector } from "react-redux";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import CircularProgress from "@mui/material/CircularProgress";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import Header from "../components/Header";
import NumberFormat from "react-number-format";
import { produkAction } from "../actions";
import SearchComponent from "../pages/SearchList";
import error404 from "../assets/images/404.webp";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

function NoData() {
  return (
    <div className="NoData">
      <img style={{ height: "50%", width: "100%" }} src={error404} />
    </div>
  );
}

export const ProdukList = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const { screenName } = useSelector((state) => state.ScreenReducer);
  const { idPromo } = useSelector((state) => state.ParamsReducer);
  const [search, setSearch] = useState("");
  const [listSearch, setListSearch] = useState([]);
  const [qty, setQty] = useState(0);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const [notifOrders, setNotifOrders] = useState(0);
  const [notifMessages, setNotifMessages] = useState(0);
  const [notifComplaints, setNotifComplaints] = useState(0);
  const [notifBroadcast, setNotifBroadcast] = useState(0);
  const [notifSubscribe, setNotifSubscribe] = useState(0);
  const [countNotifCart, setCountNotifCart] = useState(0);
  const [loadingApi, setLoadingApi] = useState(true);
  const [pilihStatus, setPilihStatus] = useState("");
  const [buttonChange, setButtonChange] = useState(false);
  const [buttonSemua, setButtonSemua] = useState(true);
  const [buttonTerbaru, setButtonTerbaru] = useState(false);
  const [buttonHerbal, setButtonHerbal] = useState(false);
  const [buttonSupMul, setButtonSupMul] = useState(false);
  const [buttonFoodBev, setButtonFoodBev] = useState(false);
  const [buttonMinyak, setButtonMinyak] = useState(false);
  const [produkList, setProdukList] = useState([]);
  const [
    onEndReachedCalledDuringMomentum,
    setOnEndReachedCalledDuringMomentum,
  ] = useState(false);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  useEffect(() => {
    getPerCategory();
    getNotifAll();
  }, []);

  useEffect(() => {
    if (page) {
      getPerCategory();
    }

    return () => {
      setOnEndReachedCalledDuringMomentum(false);
    };
  }, [page, loadingMore]);

  async function getPerCategory(status = "") {
    let number;
    let category;
    if (screenName == "Herbal") {
      number = 1;
    } else if (screenName == "HerbalNew") {
      number = 1;
      category = "newest";
    } else if (screenName == "HerbalPopular") {
      number = 1;
      category = "popular";
    } else if (screenName == "HerbalRecent") {
      number = 1;
      category = "recent";
    } else if (screenName == "SupMul") {
      number = 2;
    } else if (screenName == "SupMulNew") {
      number = 2;
      category = "newest";
    } else if (screenName == "SupMulPopular") {
      number = 2;
      category = "popular";
    } else if (screenName == "SupMulRecent") {
      number = 2;
      category = "recent";
    } else if (screenName == "FoodBev") {
      number = 3;
    } else if (screenName == "FoodBevNew") {
      number = 3;
      category = "newest";
    } else if (screenName == "FoodBevPopular") {
      number = 3;
      category = "popular";
    } else if (screenName == "FoodBevRecent") {
      number = 3;
      category = "recent";
    } else if (screenName == "Minyak") {
      number = 4;
    } else if (screenName == "MinyakNew") {
      number = 4;
      category = "newest";
    } else if (screenName == "MinyakPopular") {
      number = 4;
      category = "popular";
    } else if (screenName == "MinyakRecent") {
      number = 4;
      category = "recent";
    } else if (screenName == "GeneralNew") {
      category = "newest";
    } else if (screenName == "GeneralPopular") {
      category = "popular";
    } else if (screenName == "GeneralPromo") {
      category = "promo";
    } else if (screenName == "GeneralRecent") {
      category = "recent";
    } else if (screenName == "GeneralProductsPromo") {
      category = "products_promo";
    } else if (screenName == "ProdukLainnya") {
      number = "";
    }
    console.log("number NH", number);
    console.log("category NH", category);
    console.log("CONTOH KEDUA", status);
    console.log("screenName", screenName);
    console.log("params", idPromo);
    try {
      let response;
      if (category && number) {
        console.log("masuk if 1");
        response = await axios.get(
          `${CONFIG.BASE_URL}/api/products?category_id=${number}&category=${category}&order=${status}&page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      } else if (
        category &&
        number == undefined &&
        screenName != "GeneralProductsPromo"
      ) {
        console.log("masuk if 2");
        response = await axios.get(
          `${CONFIG.BASE_URL}/api/products?category_id=${status}&category=${category}&page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      } else if (
        category &&
        number == undefined &&
        screenName == "GeneralProductsPromo"
      ) {
        console.log("masuk if 3");
        response = await axios.get(
          `${CONFIG.BASE_URL}/api/products?category=${number}&order=${status}&page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      } else if (category == undefined && number) {
        console.log("masuk if 4");
        response = await axios.get(
          `${CONFIG.BASE_URL}/api/products?category_id=${number}&order=${status}&page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      } else if (
        category == undefined &&
        number == "" &&
        screenName == "ProdukLainnya"
      ) {
        console.log("masuk if 5");
        response = await axios.get(
          `${CONFIG.BASE_URL}/api/products?category_id=${number}&order=${status}&page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      } else if (screenName == "KalenderPromo" && idPromo) {
        console.log("masuk if 6");
        response = await axios.get(
          `${CONFIG.BASE_URL}/api/promo/detail/${idPromo}?&page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      } else if (
        (screenName == "HerbalPromo" ||
          screenName == "SupMulPromo" ||
          screenName == "FoodBevPromo" ||
          screenName == "MinyakPromo") &&
        idPromo
      ) {
        console.log("masuk if 7");
        response = await axios.get(
          `${CONFIG.BASE_URL}/api/promo/detail/${idPromo}?&page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      }
      const data = response.data.data;
      // console.log("DATA KATEGORI PRODUK========", JSON.stringify(data));
      setLoadingApi(false);
      setProdukList(page === 1 ? data.data : [...produkList, ...data.data]);
      setLoadingMore(data.last_page > page);
      setMaxPage(data.last_page);
      setRefreshing(false);
    } catch (error) {
      setLoadingApi(false);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  //status
  async function handleStatusChange(item) {
    console.log("cekkkk", item);
    setPilihStatus(item);
    setLoadingApi(true);
    setPage(1);
    getPerCategory(item);
  }

  async function handleViewChange() {
    setButtonChange(!buttonChange);
    setLoadingApi(true);
    setPage(1);
    getPerCategory();
  }

  async function getNotifAll() {
    console.log("cek hit notif");
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/notification-all`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      // console.log('DATA TEST', data.total_chat);
      setNotifOrders(data.total_order);
      setNotifMessages(data.total_chat);
      setNotifComplaints(data.total_complaint);
      setNotifSubscribe(data.total_subscribe);
      setNotifBroadcast(data.total_broadcast);
      setCountNotifCart(data.total_cart);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========NOTIF================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  //load more data
  async function getMoreData() {
    console.log("cekk", !onEndReachedCalledDuringMomentum);
    console.log("cekk", page);
    console.log("cekk", maxPage);
    if (!onEndReachedCalledDuringMomentum && page < maxPage) {
      console.log("masuk load");
      await setPage(page + 1);
      setLoadingMore(page < maxPage);
      console.log("ini ketiga", page, loadingMore);
    }
    setOnEndReachedCalledDuringMomentum(page >= maxPage);
  }

  function handleRefresh() {
    setPage(1);
    setRefreshing(true);
    getPerCategory();
  }

  function handleLoadMore() {
    console.log("cekkkkk saiki", !loadingMore);
    if (!loadingMore) return null;
    return (
      <div>
        <CircularProgress color="success" />
      </div>
    );
  }

  async function postRecent(item) {
    console.log("postRecent", JSON.stringify(item));
    try {
      let response = await axios.post(
        `${CONFIG.BASE_URL}/api/recent/products`,
        {
          product_id: item.id,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      // let data = response.data.data.data;
      // const dataTotal = response.data.data.total;
      console.log("postRecent", response.data.data);
      handleRefresh();
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error==========POST RECENT==============",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  const simpanSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div className="produkListContainer">
      <div className="header1">
        {/* <div className="headerContainer">
          <div className="headerContainerLogo">
            <img src={Logo} alt="headerLogo" className="headerLogo" />
          </div>
          <div className="headerSearchContainer">
            <input
              className="headerTextInput"
              placeholder="Pencarian..."
              type="text"
            />
            <img
              src={IconSearch}
              alt="headerSearchLogo"
              className="headerSearchLogo"
            />
          </div>
          <div className="headerContainerMenu">
            <img
              src={IconNotifAll}
              alt="headerMenuLogo"
              className="headerMenuLogo"
            />
            <img
              src={IconShoppingCart}
              alt="headerMenuLogo"
              className="headerMenuLogo"
            />
            <img
              src={IconMenu}
              alt="headerMenuLogo"
              className="headerMenuLogo"
            />
          </div>
        </div> */}
        <Header
          notifMessages={notifMessages}
          notifSubscribe={notifSubscribe}
          notifOrders={notifOrders}
          notifComplaints={notifComplaints}
          notifBroadcast={notifBroadcast}
          countNotifCart={countNotifCart}
          titleHeader="Keranjang"
          simpanSearch={simpanSearch}
          text={search}
          item={true}
          search={false}
        />
        {search.length > 0 && <SearchComponent search={search} />}
        {search.length == 0 && (
          <>
            {screenName == "GeneralNew" ||
            screenName == "GeneralPopular" ||
            screenName == "GeneralPromo" ||
            screenName == "GeneralRecent" ? (
              <div className="headerFilter">
                {buttonSemua ? (
                  <button
                    onClick={() => handleStatusChange()}
                    className="active"
                  >
                    <p className="text">Semua</p>
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setButtonSemua(true);
                      setButtonHerbal(false);
                      setButtonSupMul(false);
                      setButtonFoodBev(false);
                      setButtonMinyak(false);
                      handleStatusChange();
                    }}
                  >
                    <p className="text">Semua</p>
                  </button>
                )}
                {buttonHerbal ? (
                  <button
                    onClick={() => handleStatusChange("1")}
                    className="active"
                  >
                    <p className="text">Herbal</p>
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setButtonSemua(false);
                      setButtonHerbal(true);
                      setButtonSupMul(false);
                      setButtonFoodBev(false);
                      setButtonMinyak(false);
                      handleStatusChange("1");
                    }}
                  >
                    <p className="text">Herbal</p>
                  </button>
                )}
                {buttonSupMul ? (
                  <button
                    onClick={() => handleStatusChange("2")}
                    className="active"
                  >
                    <p className="text">Supplemen dan Vitamin</p>
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setButtonSemua(false);
                      setButtonHerbal(false);
                      setButtonSupMul(true);
                      setButtonFoodBev(false);
                      setButtonMinyak(false);
                      handleStatusChange("2");
                    }}
                  >
                    <p className="text">Supplemen dan Vitamin</p>
                  </button>
                )}
                {buttonFoodBev ? (
                  <button
                    onClick={() => handleStatusChange("3")}
                    className="active"
                  >
                    <p className="text">Makanan dan Minuman</p>
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setButtonSemua(false);
                      setButtonHerbal(false);
                      setButtonSupMul(false);
                      setButtonFoodBev(true);
                      setButtonMinyak(false);
                      handleStatusChange("3");
                    }}
                  >
                    <p className="text">Makanan dan Minuman</p>
                  </button>
                )}
                {buttonMinyak ? (
                  <button
                    onClick={() => handleStatusChange("4")}
                    className="active"
                  >
                    <p className="text">Minyak Angin dan Balsem</p>
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setButtonSemua(false);
                      setButtonHerbal(false);
                      setButtonSupMul(false);
                      setButtonFoodBev(false);
                      setButtonMinyak(true);
                      handleStatusChange("4");
                    }}
                  >
                    <p className="text">Minyak Angin dan Balsem</p>
                  </button>
                )}
              </div>
            ) : (
              <div className="headerFilter">
                {buttonSemua ? (
                  <button
                    onClick={() => handleStatusChange()}
                    className="active"
                  >
                    <p className="text">Semua</p>
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setButtonSemua(!buttonSemua);
                      setButtonTerbaru(!buttonTerbaru);
                      handleStatusChange();
                    }}
                  >
                    <p className="text">Semua</p>
                  </button>
                )}
                {buttonTerbaru ? (
                  <button
                    onClick={() => handleStatusChange("desc")}
                    className="active"
                  >
                    <p className="text">Terbaru</p>
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setButtonSemua(!buttonSemua);
                      setButtonTerbaru(!buttonTerbaru);
                      handleStatusChange("desc");
                    }}
                  >
                    <p className="text">Terbaru</p>
                  </button>
                )}
              </div>
            )}
          </>
        )}
      </div>
      <div>
        {search.length == 0 && (
          <>
            {produkList.length > 0 && !loadingApi ? (
              <InfiniteScroll
                dataLength={produkList.length}
                next={() => getMoreData()}
                style={{ paddingTop: "20%" }}
                hasMore={true}
                loader={() => handleLoadMore()}
                // refreshFunction={handleRefresh}
                // pullDownToRefresh
                // pullDownToRefreshThreshold={50}
                // pullDownToRefreshContent={handleLoadMore}
              >
                {produkList.map((item, index) => {
                  if (
                    screenName === "KalenderPromo" ||
                    screenName === "HerbalPromo" ||
                    screenName === "SupMulPromo" ||
                    screenName === "FoodBevPromo" ||
                    screenName === "MinyakPromo"
                  ) {
                    return (
                      <div key={index} className="content">
                        <a
                          onClick={() => {
                            postRecent(item.product);
                            dispatch(produkAction(item.product, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.product.id);
                           
                          }}
                        >
                          <div className="product">
                            {item.product.image ? (
                              <img
                                src={CONFIG.BASE_URL + item.product.image}
                                alt="transactionContentImage"
                                className="image"
                              />
                            ) : (
                              <img
                                src={DummyImage}
                                alt="transactionContentImage"
                                className="image"
                              />
                            )}
                            <div className="desc">
                              <div className="name">{item.product.name}</div>
                              {item.status_promosi_coret == 1 ? (
                                <NumberFormat
                                  value={
                                    Math.round(
                                      item?.product?.price
                                        ?.harga_promosi_coret_ritel_gt ||
                                        item?.product?.price
                                          ?.harga_promosi_coret_grosir_mt ||
                                        item?.product?.price
                                          ?.harga_promosi_coret_semi_grosir
                                    ) ?? "0"
                                  }
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"Rp "}
                                  renderText={(value) => (
                                    <div className="price">{value || 0}</div>
                                  )}
                                />
                              ) : (
                                <NumberFormat
                                  value={
                                    Math.round(
                                      item?.product?.price?.harga_ritel_gt
                                    ) ?? "0"
                                  }
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"Rp "}
                                  renderText={(value) => (
                                    <div className="price">{value || 0}</div>
                                  )}
                                />
                              )}
                            </div>
                          </div>
                        </a>
                      </div>
                    );
                  }
                  return (
                    <div key={index} className="content">
                      <a
                        onClick={() => {
                          postRecent.bind(this, item);
                          navigate("/produk/deskripsi?idProduct=" + item.id);
                          dispatch(produkAction(item, "produkDesc"));
                        }}
                      >
                        <div className="product">
                          {item.image ? (
                            <img
                              src={CONFIG.BASE_URL + item.image}
                              alt="transactionContentImage"
                              className="image"
                            />
                          ) : (
                            <img
                              src={DummyImage}
                              alt="transactionContentImage"
                              className="image"
                            />
                          )}
                          <div className="desc">
                            <div className="name">{item.name}</div>
                            {item.status_promosi_coret == 1 ? (
                              <NumberFormat
                                value={
                                  Math.round(
                                    item?.price?.harga_promosi_coret_ritel_gt ||
                                      item?.price
                                        ?.harga_promosi_coret_grosir_mt ||
                                      item?.price
                                        ?.harga_promosi_coret_semi_grosir
                                  ) ?? "0"
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"Rp "}
                                renderText={(value) => (
                                  <div className="price">{value || 0}</div>
                                )}
                              />
                            ) : (
                              <NumberFormat
                                value={
                                  Math.round(item?.price?.harga_ritel_gt) ?? "0"
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"Rp "}
                                renderText={(value) => (
                                  <div className="price">{value || 0}</div>
                                )}
                              />
                            )}
                          </div>
                        </div>
                      </a>
                    </div>
                  );
                })}
              </InfiniteScroll>
            ) : produkList.length == 0 && !loadingApi ? (
              <NoData />
            ) : null}
          </>
        )}
      </div>
      <Navbar screenName={"Produk"}/>
      <Loading loadingApi={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProdukList);
