import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import IconBack from "../assets/images/backArrow.webp";
import BannerPromo from "../assets/dummy/banner-2021-10-18-100603.jpg";
import "./PromoDetail.css";
import { useSelector } from "react-redux";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import CircularProgress from "@mui/material/CircularProgress";
import { screenAction, paramsAction, promoAction } from "../actions";
import { useDispatch } from "react-redux";
import moment from "moment";
import Header from "../components/HeaderDistributor";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Loading from "../components/Loading";

const regEx = /(<([^>]+)>)/gi;
const regEx2 = /((&nbsp;))*/gim;

export const PromoDetail = (props) => {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);
  const [loadingApi, setLoadingApi] = useState(true);
  const { DataPromo } = useSelector((state) => state.PromoReducer);
  const [promo, setPromo] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (id != null && !DataPromo) {
      getPromo();
    }
    if (DataPromo) {
      setPromo(DataPromo);
    }
  }, []);

  async function getPromo() {
    try {
      let response = await axios.get(`${CONFIG.BASE_URL}/api/promo/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const data = response.data.data;
      if (response.data["success"] === true) {
        setPromo(data);
        setLoadingApi(false);
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }
  function cetakDataHightlight() {
    const cetak = promo.highlight.replace(regEx, "");
    const cetak2 = cetak.replace(regEx2, "");
    return cetak2;
  }
  return (
    <div className="promoDetailContainer">
      {loadingApi == false && promo ? (
        <div className="promoDetailHeader">
          <Header titleHeader="Promo" item={false} />
          <div className="promoDetail">
            <div className="promoDetailCardContainer">
              <div className="date">Masa Berlaku</div>
              {promo?.start == null && promo?.end == null ? (
                <div className="date">{"Belaku setiap pembelian pertama"}</div>
              ) : (
                <div className="date">
                  {moment(promo?.start).format("LL")}
                  {" to "}
                  {moment(promo?.end).format("LL")}
                </div>
              )}
            </div>
            <div className="promoDetailCardContainer">
              <table>
                {promo?.reward[0]?.reward_disc ? (
                  <tr>
                    <td className="first">Diskon</td>
                    <td> : </td>
                    <td className="two">
                      {promo?.reward[0]?.reward_disc}
                      {" %"}
                    </td>
                  </tr>
                ) : null}
                {promo?.reward[0]?.reward_qty ? (
                  <tr>
                    <td className="first">Bonus</td>
                    <td> : </td>
                    <td className="two">
                      {promo?.reward[0]?.reward_qty}
                      {" Produk"}
                    </td>
                  </tr>
                ) : null}
                {promo?.reward[0]?.reward_nominal ? (
                  <tr>
                    <td className="first">Potongan Harga</td>
                    <td> : </td>
                    <td className="two">
                      {"Rp"}
                      {promo.reward[0].reward_nominal}
                    </td>
                  </tr>
                ) : null}
                {promo.reward[0].reward_point ? (
                  <tr>
                    <td className="first">Bonus Poin</td>
                    <td> : </td>
                    <td className="two">
                      {"+ "}
                      {promo.reward[0].reward_point}
                    </td>
                  </tr>
                ) : null}
                {promo.min_qty ? (
                  <tr>
                    <td className="first">Syarat Pembelian</td>
                    <td> : </td>
                    <td className="two">
                      {"Minimal "}
                      {promo.min_qty}
                      {" item"}
                    </td>
                  </tr>
                ) : null}
                {promo.min_transaction ? (
                  <tr>
                    <td className="first">Syarat Pembelian</td>
                    <td> : </td>
                    <td className="two">
                      {"Minimal transaksi "}
                      {"\n"}
                      {"  Rp"}
                      {promo.min_transaction}
                    </td>
                  </tr>
                ) : null}
                {promo.multiple == 1 ? (
                  <tr>
                    <td className="first">Ketentuan</td>
                    <td> : </td>
                    <td className="two">{"Berlaku kelipatan"}</td>
                  </tr>
                ) : null}
              </table>
            </div>
            {promo.highlight ? (
              <div className="promoDetailCardContainer">
                <div className="date">Highlight</div>
                <div className="date">{cetakDataHightlight()}</div>
              </div>
            ) : null}
            <div className="promoDetailCardContainer">
              <div className="date">Syarat Dan Ketentuan</div>
              <div className="date">{promo.description}</div>
            </div>
          </div>
          <div className="promoDetailBanner">
            {promo.banner ? (
              <img
                src={CONFIG.BASE_URL + promo.banner}
                alt="homeBannerPromoImage"
                className="promoDetailBannerImage"
              />
            ) : (
              <img
                src={DummyImage}
                alt="homeBannerPromoImage"
                className="promoDetailBannerImage"
              />
            )}
          </div>
          <button
            onClick={() => {
              dispatch(screenAction("HerbalPromo", "screenName"));
              dispatch(paramsAction(id, "idPromo"));
              navigate("/produk/list");
            }}
            className="promoDetailButton"
          >
            <p className="promoDetailButtonText">{"Belanja Sekarang"}</p>
          </button>
          <Loading loadingApi={loadingApi} />
          {alertServerSibuk ? alertBusy() : null}
          {alertInet ? alertInternet() : null}
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PromoDetail);
