import { SET_SCREEN } from "../../actions";
const initialState = {
  screenName: "",
};

const screenName = (state = initialState, action) => {
  switch (action.type) {
    case SET_SCREEN:
      return {
        ...state,
        [action.inputType]: action.inputValue,
      };
    default:
      return state;
  }
};

export default screenName;
