import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import IconBack from "../assets/images/backArrow.webp";
import IconSearch from "../assets/images/Search.webp";
import "./ReviewDetail.css";
import Product1 from "../assets/dummy/product1.jpg";
import Product2 from "../assets/dummy/product2.jpg";
import ReactStars from "react-rating-stars-component";
import { useSelector } from "react-redux";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import NumberFormat from "react-number-format";
import moment from "moment";

export const ReviewDetail = (props) => {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const { ratingDetail } = useSelector((state) => state.ProdukReducer);
  const navigate = useNavigate();

  return (
    <div className="reviewHistoryContainer">
      <div className="headerComponent">
        <div onClick={() => navigate(-1)} className="headerArrowContainer">
          <img
            src={IconBack}
            alt="headerIconArrow"
            className="headerIconArrow"
          />
        </div>
        <div className="headerTitleContainer">
          <p className="headerTitle">Detail Ulasan</p>
        </div>
      </div>
      {ratingDetail.data_review.map((item, index) => {
        if (item.review) {
          return (
            <div className="reviewDetailCard">
              <div className="card">
                {item.review.image ? (
                  <img
                    src={CONFIG.BASE_URL + item.review.image}
                    alt="image"
                    className="image"
                  />
                ) : (
                  <img src={DummyImage} alt="image" className="image" />
                )}
                <div className="info">
                  <div className="name">{item.review.name}</div>
                  <div className="halfView">
                    {item.half ? (
                      <NumberFormat
                        value={Math.round(item.price_apps / 2) ?? "0"}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Rp "}
                        renderText={(value) => (
                          <div className="desc">
                            {item.qty ?? "0"} barang x {value || 0}
                          </div>
                        )}
                      />
                    ) : (
                      <NumberFormat
                        value={Math.round(item.price_apps) ?? "0"}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Rp "}
                        renderText={(value) => (
                          <div className="desc">
                            {item.qty ?? "0"} barang x {value || 0}
                          </div>
                        )}
                      />
                    )}
                    {item.half ? (
                      <div className="descHalf">
                        {" ( "}
                        {item?.qty_konversi} {item?.small_unit.charAt(0) +
                                  item?.small_unit.slice(1).toLowerCase()}
                        {" )"}
                      </div>
                    ) : null}
                  </div>
                  <NumberFormat
                    value={Math.round(item.total_price) ?? "0"}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"Rp "}
                    renderText={(value) => (
                      <div className="price">{value || 0}</div>
                    )}
                  />
                </div>
              </div>
              <div className="footer">
                <div className="star">
                  <ReactStars
                    count={5}
                    value={item.review.star_review}
                    size={35}
                    edit={false}
                    color={"#adabae"}
                    activeColor={"#ffd700"}
                    classNames="productRatingBottomStar"
                  />
                </div>
                <div className="desc">
                  {dataUser.user.name} ⬤{" "}
                  {moment(item.review.created_at).format("DD MMM YYYY HH:mm")}
                </div>
              </div>
              <div className="footer">
                <div className="descContent">{item.review.detail_review}</div>
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewDetail);
