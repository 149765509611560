import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import IconBack from "../assets/images/backArrow.webp";
import IconSearch from "../assets/images/Search.webp";
import "./FaqTopik.css";
import { useSelector } from "react-redux";
import axios from "axios";
import CONFIG from "../config/config";
import { useDispatch } from "react-redux";
import { helpAction } from "../actions";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Header from "../components/HeaderDistributor";

export const FaqTopik = (props) => {
  const dispatch = useDispatch();
  const { helpTopik } = useSelector((state) => state.HelpReducer);
  const navigate = useNavigate();
  const [help, setHelp] = useState([]);
  const [helpCare, setHelpCare] = useState([]);
  const [search, setSearch] = useState("");
  const [loadingApi, setLoadingApi] = useState(true);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  useEffect(() => {
    getHelpCareAccount();
  });

  useEffect(() => {
    if (search.length > 1) {
      getHelpCare();
    }
    console.log(search);

    return () => {};
  }, [search]);

  //help care account
  async function getHelpCareAccount() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/helps?category_id=${helpTopik.id}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      setHelpCare(data);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  async function getHelpCare() {
    console.log("masuk get all helps");

    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/helps?search=${search}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = response.data.data;
      console.log("DATA HELP ", JSON.stringify(data));
      // const dataTotal = response.data.data.total;
      setHelp(data);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error=======DATA LOAD=================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  return (
    <div className="faqTopikContainer">
      <div className="faqTopikHeader">
        <div className="headerComponent">
          <Header titleHeader="Bantuan" />
        </div>
        <div className="faqTopikHeaderMiddle">Ada yang bisa dibantu ?</div>
        <div className="faqTopikSearchContainer">
          <input
            onChange={(e) => setSearch(e.target.value)}
            className="faqTopikTextInput"
            placeholder="Ketik kata kunci yang ingin anda cari"
            type="text"
            value={search}
          />
          <img
            src={IconSearch}
            alt="headerSearchLogo"
            className="faqTopikSearchLogo"
          />
        </div>
      </div>
      {search.length > 0 && (
        <div className="faqMain">
          {help.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  dispatch(helpAction(item, "helpDetail"));
                  setSearch("");
                  navigate("/faq/detail?idFaq=" + item.id);
                }}
              >
                <div className="list">
                  <a>{item.name}</a>
                </div>
                <hr></hr>
              </div>
            );
          })}
        </div>
      )}
      {search.length == 0 && (
        <div className="faqTopikMain">
          {helpCare.map((item, index) => {
            return (
              <React.Fragment>
                <div
                  key={index}
                  onClick={() => {
                    dispatch(helpAction(item, "helpDetail"));
                    navigate("/faq/detail?idFaq=" + item.id);
                  }}
                  className="list"
                >
                  <a>{item.name}</a>
                </div>
                <hr></hr>
              </React.Fragment>
            );
          })}
        </div>
      )}
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FaqTopik);
