import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Slider from "../components/Slider";
import Images from "../images";
import NumberFormat from "react-number-format";
import ReactStars from "react-rating-stars-component";
import "./Home.css";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import Product1 from "../assets/dummy/product1.jpg";
import Product2 from "../assets/dummy/product2.jpg";
import Product3 from "../assets/dummy/product3.jpg";
import Product4 from "../assets/dummy/product4.jpg";
import Product5 from "../assets/dummy/product5.jpg";
import Product6 from "../assets/dummy/product6.jpg";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import CircularProgress from "@mui/material/CircularProgress";
import IconCart from "../assets/images/KeranjangActive.webp";
import { produkAction, screenAction } from "../actions";
import { useDispatch } from "react-redux";
import IconNextBawah from "../assets/images/Next.webp";
import IconNextAtas from "../assets/images/Next2.webp";
import ModalShopping from "../components/DialogShopping";
import SearchComponent from "../pages/SearchList";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import AlertBlacklist from "../components/AlertBlacklist";

export const Produk = (props) => {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const { screenName } = useSelector((state) => state.ScreenReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const parameter = queryParams.get("parameter");
  const [listSearch, setListSearch] = useState([]);
  const [qty, setQty] = useState(0);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const [recomenProduct, setRecomenProduct] = useState([]);
  const [loadingApi, setLoadingApi] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [rating, setRating] = useState(1);
  const [shoppingCartItems, setShoppingCartItems] = useState({
    product_id: "",
    brand_id: "",
    satuan_online: "",
    konversi_sedang_ke_kecil: "",
    qty_konversi: "",
    qty: 1,
    notes: "",
    price_apps: "",
  });
  const [herbalPopular, setHerbalPopular] = useState([]);
  const [herbalNewest, setHerbalNewest] = useState([]);
  const [herbalRecent, setHerbalRecent] = useState([]);
  const [herbalAll, setHerbalAll] = useState([]);
  const [supmulPopular, setSupmulPopular] = useState([]);
  const [supmulNewest, setSupmulNewest] = useState([]);
  const [supmulRecent, setSupmulRecent] = useState([]);
  const [supmulAll, setSupmulAll] = useState([]);
  const [foodbevPopular, setFoodbevPopular] = useState([]);
  const [foodbevNewest, setFoodbevNewest] = useState([]);
  const [foodbevRecent, setFoodbevRecent] = useState([]);
  const [foodbevAll, setFoodbevAll] = useState([]);
  const [minyakbalsemPopular, setMinyakbalsemPopular] = useState([]);
  const [minyakbalsemNewest, setMinyakbalsemNewest] = useState([]);
  const [minyakbalsemRecent, setMinyakbalsemRecent] = useState([]);
  const [minyakbalsemAll, setMinyakbalsemAll] = useState([]);
  const [qtyTotalHerbalNew, setQtyTotalHerbalNew] = useState(0);
  const [qtyTotalHerbalPopular, setQtyTotalHerbalPopular] = useState(0);
  const [qtyTotalHerbalRecentBuy, setQtyTotalHerbalRecentBuy] = useState(0);
  const [qtyTotalHerbalAll, setQtyTotalHerbalAll] = useState(0);
  const [qtyTotalMinyakNew, setQtyTotalMinyakNew] = useState(0);
  const [qtyTotalMinyakPopular, setQtyTotalMinyakPopular] = useState(0);
  const [qtyTotalMinyakRecentBuy, setQtyTotalMinyakRecentBuy] = useState(0);
  const [qtyTotalMinyakAll, setQtyTotalMinyakAll] = useState(0);
  const [qtyTotalSupMulNew, setQtyTotalSupMulNew] = useState(0);
  const [qtyTotalSupMulPopular, setQtyTotalSupMulPopular] = useState(0);
  const [qtyTotalSupMulRecentBuy, setQtyTotalSupMulRecentBuy] = useState(0);
  const [qtyTotalSupMulAll, setQtyTotalSupMulAll] = useState(0);
  const [qtyTotalFoodBevNew, setQtyTotalFoodBevNew] = useState(0);
  const [qtyTotalFoodBevPopular, setQtyTotalFoodBevPopular] = useState(0);
  const [qtyTotalFoodBevRecentBuy, setQtyTotalFoodBevRecentBuy] = useState(0);
  const [qtyTotalFoodBevAll, setQtyTotalFoodBevAll] = useState(0);
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [modalAlert, setModalAlert] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [
    onEndReachedCalledDuringMomentum,
    setOnEndReachedCalledDuringMomentum,
  ] = useState(false);
  const [notifOrders, setNotifOrders] = useState(0);
  const [notifMessages, setNotifMessages] = useState(0);
  const [notifComplaints, setNotifComplaints] = useState(0);
  const [notifBroadcast, setNotifBroadcast] = useState(0);
  const [notifSubscribe, setNotifSubscribe] = useState(0);
  const [countNotifCart, setCountNotifCart] = useState(0);
  const [openShopping, setOpenShopping] = useState(false);
  const [loadAll, setLoadAll] = useState(false);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [search, setSearch] = useState(parameter || "");

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  useEffect(() => {
    getDataLoadAll();
    getNotifAll();

    return () => {};
  }, []);

  useEffect(() => {
    if (loadAll) {
      getDataLoadAll();
      getNotifAll();
      setLoadAll(false);
    }
    return () => {};
  }, [loadAll]);

  async function getDataLoadAll() {
    // this.setState({loadingApi: true});
    try {
      let response = await axios.get(`${CONFIG.BASE_URL}/api/product-all`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const data = response.data.data;
      // const dataTotal = response.data.data.total;
      if (screenName === "DetailHerbal") {
        setHerbalPopular(data.herbal_popular[0].data);
        setHerbalNewest(data.herbal_newest[0].data);
        setHerbalRecent(data.herbal_recent[0].data);
        setHerbalAll(data.herbal[0].data);
        setQtyTotalHerbalPopular(data.herbal_popular[0].total);
        setQtyTotalHerbalNew(data.herbal_newest[0].total);
        setQtyTotalHerbalRecentBuy(data.herbal_recent[0].total);
        setQtyTotalHerbalAll(data.herbal[0].total);
        setLoadingApi(false);
      } else if (screenName === "DetailSupMul") {
        setSupmulPopular(data.supmul_popular[0].data);
        setSupmulNewest(data.supmul_newest[0].data);
        setSupmulRecent(data.supmul_recent[0].data);
        setSupmulAll(data.supmul[0].data);
        setQtyTotalSupMulPopular(data.supmul_popular[0].total);
        setQtyTotalSupMulNew(data.supmul_newest[0].total);
        setQtyTotalSupMulRecentBuy(data.supmul_recent[0].total);
        setQtyTotalSupMulAll(data.supmul[0].total);
        setLoadingApi(false);
      } else if (screenName === "DetailFoodBev") {
        setFoodbevPopular(data.foodbeverage_popular[0].data);
        setFoodbevNewest(data.foodbeverage_newest[0].data);
        setFoodbevRecent(data.foodbeverage_recent[0].data);
        setFoodbevAll(data.foodbeverage[0].data);
        setQtyTotalFoodBevPopular(data.foodbeverage_popular[0].total);
        setQtyTotalFoodBevNew(data.foodbeverage_newest[0].total);
        setQtyTotalFoodBevRecentBuy(data.foodbeverage_recent[0].total);
        setQtyTotalFoodBevAll(data.foodbeverage[0].total);
        setLoadingApi(false);
      } else if (screenName === "DetailMinyak") {
        setMinyakbalsemPopular(data.minyakbalsem_popular[0].data);
        setMinyakbalsemNewest(data.minyakbalsem_newest[0].data);
        setMinyakbalsemRecent(data.minyakbalsem_recent[0].data);
        setMinyakbalsemAll(data.minyakbalsem[0].data);
        setQtyTotalMinyakPopular(data.minyakbalsem_popular[0].total);
        setQtyTotalMinyakNew(data.minyakbalsem_newest[0].total);
        setQtyTotalMinyakRecentBuy(data.minyakbalsem_recent[0].total);
        setQtyTotalMinyakAll(data.minyakbalsem[0].total);
        setLoadingApi(false);
      } else {
        setHerbalPopular(data.herbal_popular[0].data);
        setHerbalNewest(data.herbal_newest[0].data);
        setHerbalRecent(data.herbal_recent[0].data);
        setHerbalAll(data.herbal[0].data);
        setQtyTotalHerbalPopular(data.herbal_popular[0].total);
        setQtyTotalHerbalNew(data.herbal_newest[0].total);
        setQtyTotalHerbalRecentBuy(data.herbal_recent[0].total);
        setQtyTotalHerbalAll(data.herbal[0].total);
        setSupmulPopular(data.supmul_popular[0].data);
        setSupmulNewest(data.supmul_newest[0].data);
        setSupmulRecent(data.supmul_recent[0].data);
        setSupmulAll(data.supmul[0].data);
        setQtyTotalSupMulPopular(data.supmul_popular[0].total);
        setQtyTotalSupMulNew(data.supmul_newest[0].total);
        setQtyTotalSupMulRecentBuy(data.supmul_recent[0].total);
        setQtyTotalSupMulAll(data.supmul[0].total);
        setFoodbevPopular(data.foodbeverage_popular[0].data);
        setFoodbevNewest(data.foodbeverage_newest[0].data);
        setFoodbevRecent(data.foodbeverage_recent[0].data);
        setFoodbevAll(data.foodbeverage[0].data);
        setQtyTotalFoodBevPopular(data.foodbeverage_popular[0].total);
        setQtyTotalFoodBevNew(data.foodbeverage_newest[0].total);
        setQtyTotalFoodBevRecentBuy(data.foodbeverage_recent[0].total);
        setQtyTotalFoodBevAll(data.foodbeverage[0].total);
        setMinyakbalsemPopular(data.minyakbalsem_popular[0].data);
        setMinyakbalsemNewest(data.minyakbalsem_newest[0].data);
        setMinyakbalsemRecent(data.minyakbalsem_recent[0].data);
        setMinyakbalsemAll(data.minyakbalsem[0].data);
        setQtyTotalMinyakPopular(data.minyakbalsem_popular[0].total);
        setQtyTotalMinyakNew(data.minyakbalsem_newest[0].total);
        setQtyTotalMinyakRecentBuy(data.minyakbalsem_recent[0].total);
        setQtyTotalMinyakAll(data.minyakbalsem[0].total);
        setLoadingApi(false);
      }
    } catch (error) {
      setLoadingApi(false);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error=======DATA LOAD=================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  async function getNotifAll() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/notification-all`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      setNotifOrders(data.total_order);
      setNotifMessages(data.total_chat);
      setNotifComplaints(data.total_complaint);
      setNotifSubscribe(data.total_subscribe);
      setNotifBroadcast(data.total_broadcast);
      setCountNotifCart(data.total_cart);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========NOTIF================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        // navigate('/home')
      }
    }
  }

  function handleRefresh() {
    setPage(1);
    setRefreshing(true);
    getDataLoadAll();
  }

  async function postRecent(item) {
    try {
      let response = await axios.post(
        `${CONFIG.BASE_URL}/api/recent/products`,
        {
          product_id: item.id,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      // let data = response.data.data.data;
      // const dataTotal = response.data.data.total;
      handleRefresh();
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error==========POST RECENT==============",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        // navigate('/home')
      }
    }
  }

  async function postShoppingCart(item) {
    const brand_id_1 = ["001"];
    const brand_id_2 = [
      "002",
      "003",
      "004",
      "007",
      "008",
      "009",
      "010",
      "011",
      "012",
      "013",
      "014",
    ];
    const brand_id_3 = ["005"];

    let price = item.price.harga_ritel_gt ?? "0";
    if (brand_id_1.includes(item.brand_id)) {
      if (item.status_promosi_coret == 1) {
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          default:
            break;
        }
      } else if (
        (item.status_promosi_coret !== "0" ||
          item.status_promosi_coret !== null) &&
        item.status_herbana !== "1"
      ) {
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_ritel_gt;
            break;
          default:
            break;
        }
      } else if (item.status_herbana === "1") {
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_grosir_mt;
            break;
          default:
            break;
        }
      } else {
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_ritel_gt;
            break;
          default:
            break;
        }
      }
    } else if (brand_id_2.includes(item.brand_id)) {
      if (item.status_promosi_coret == 1) {
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          default:
            break;
        }
      } else if (
        item.status_promosi_coret !== "0" ||
        item.status_promosi_coret !== null
      ) {
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_grosir_mt;
            break;
          default:
            break;
        }
      } else {
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_grosir_mt;
            break;
          default:
            break;
        }
      }
    } else if (brand_id_3.includes(item.brand_id)) {
      if (item.status_promosi_coret == 1) {
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          default:
            break;
        }
      } else if (
        item.status_promosi_coret !== "0" ||
        item.status_promosi_coret !== null
      ) {
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_ritel_gt;
            break;
          default:
            break;
        }
      } else {
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_ritel_gt;
            break;
          default:
            break;
        }
      }
    }


    try {
      let response = await axios.post(
        `${CONFIG.BASE_URL}/api/shopping-cart`,
        {
          product_id: item.id,
          brand_id: item.brand_id,
          satuan_online: item.satuan_online,
          konversi_sedang_ke_kecil: item.konversi_sedang_ke_kecil,
          qty_konversi: shoppingCartItems.qty * item.konversi_sedang_ke_kecil,
          qty: shoppingCartItems.qty,
          notes: shoppingCartItems.notes,
          price_apps: price ?? "0",
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data;
      if (data !== "" && data["success"] == true) {
        setOpenShopping(true);
        setLoadAll(true);
      }else if (data["message"] == "Anda Masuk Dalam Daftar Blacklist") {
        setOpenDialog(true);
        setMessage(data.message);
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        // navigate('/home')
      }
    }
  }

  const handleClickOpenShopping = () => {
    setOpenShopping(true);
  };

  const handleCloseShopping = (e) => {
    setOpenShopping(false);
    if (e == "ok") {
      navigate("/keranjang");
    }
  };

  const simpanSearch = (e) => {
    setSearch(e.target.value);
    setPage(1);
    if (e.target.value === "") {
      navigate("/produk");
    }
  };
  const handleCloseAlertBlacklist = () => {
    setOpenDialog(false);
  };
  return (
    <div className="homeContainer">
      <ModalShopping
        handleCloseShopping={handleCloseShopping}
        openShopping={openShopping}
      />
      <AlertBlacklist
        alertData={message}
        alertJudul="Pemberitahuan"
        handleClose={handleCloseAlertBlacklist}
        open={openDialog}
      />
      <Header
        notifMessages={notifMessages}
        notifSubscribe={notifSubscribe}
        notifOrders={notifOrders}
        notifComplaints={notifComplaints}
        notifBroadcast={notifBroadcast}
        countNotifCart={countNotifCart}
        titleHeader="Keranjang"
        simpanSearch={simpanSearch}
        text={search}
        item={true}
        search={false}
      />
      {search.length > 0 && <SearchComponent search={search} param="produk-param" />}
      {search.length === 0 && (
        <div className="productContent">
          {herbalAll.length > 0 && (
            <>
              <div className="homeProduct">
                {qtyTotalHerbalAll > 10 ? (
                  <div className="homeViewLihatSemua">
                    <p className="homeProductTitle">Produk Herbal Semua</p>
                    <div
                      onClick={() => {
                        dispatch(screenAction("Herbal", "screenName"));
                        navigate("/produk/list");
                      }}
                      className="homeGroupLihatSemua"
                    >
                      <p className="homeLihatSemua">Lihat Semua</p>
                      <img
                        className="homeImageIconNextAtas"
                        src={IconNextAtas}
                      />
                    </div>
                  </div>
                ) : (
                  <p className="homeProductTitle">Produk Herbal Semua</p>
                )}
                <div className="homeProductContainer">
                  {herbalAll.map((item, index) => {
                    return (
                      <div key={index} className="homeProductItem">
                        {item.promo_sku.length > 0 && (
                          <div
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            className="homeProductItemPromo"
                          >
                            <p className="homeProductItemPromoText">Promo</p>
                          </div>
                        )}
                        {item.image ? (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            src={CONFIG.BASE_URL + item.image}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        ) : (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            src={DummyImage}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        )}
                        <p
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemTitle"
                        >
                          {item.name}
                        </p>
                        {item.status_promosi_coret == 1 ? (
                          <NumberFormat
                            value={
                              Math.round(
                                item?.price?.harga_promosi_coret_ritel_gt ||
                                  item?.price?.harga_promosi_coret_grosir_mt ||
                                  item?.price?.harga_promosi_coret_semi_grosir
                              ) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate("/produk/deskripsi?idProduct=" + item.id);
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        ) : (
                          <NumberFormat
                            value={
                              Math.round(item?.price?.harga_ritel_gt) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate("/produk/deskripsi?idProduct=" + item.id);
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        )}
                        <div
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemView"
                        >
                          <div className="homeProductItemRating">
                            <ReactStars
                              count={1}
                              size={24}
                              edit={false}
                              color={"#ffd700"}
                            />
                            <p className="homeProductItemRatingValue">
                              {item.review
                                ? item.review[0]
                                  ? item.review[0].avg_rating
                                  : "0"
                                : "0"}
                            </p>
                          </div>
                          {item.cart && (
                            <div className="homeProductItemRating">
                              <img className="notifListIcon" src={IconCart} />
                              <p className="homeProductItemRatingValue">
                                {item.cart.qty}
                              </p>
                            </div>
                          )}
                        </div>
                        {item.cart ? (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Tambah</p>
                          </button>
                        ) : (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Beli</p>
                          </button>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <hr className="hr"></hr>
            </>
          )}
          {herbalPopular.length > 0 && (
            <>
              <div className="homeProduct">
                {qtyTotalHerbalPopular > 10 ? (
                  <div className="homeViewLihatSemua">
                    <p className="homeProductTitle">Produk Herbal Terlaris</p>
                    <div
                      onClick={() => {
                        dispatch(screenAction("HerbalPopular", "screenName"));
                        navigate("/produk/list");
                      }}
                      className="homeGroupLihatSemua"
                    >
                      <p className="homeLihatSemua">Lihat Semua</p>
                      <img
                        className="homeImageIconNextAtas"
                        src={IconNextAtas}
                      />
                    </div>
                  </div>
                ) : (
                  <p className="homeProductTitle">Produk Herbal Terlaris</p>
                )}
                <div className="homeProductContainer">
                  {herbalPopular.map((item, index) => {
                    return (
                      <div key={index} className="homeProductItem">
                        {item.promo_sku.length > 0 && (
                          <div
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            className="homeProductItemPromo"
                          >
                            <p className="homeProductItemPromoText">Promo</p>
                          </div>
                        )}
                        {item.image ? (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            src={CONFIG.BASE_URL + item.image}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        ) : (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            src={DummyImage}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        )}
                        <p
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemTitle"
                        >
                          {item.name}
                        </p>
                        {item.status_promosi_coret == 1 ? (
                          <NumberFormat
                            value={
                              Math.round(
                                item?.price?.harga_promosi_coret_ritel_gt ||
                                  item?.price?.harga_promosi_coret_grosir_mt ||
                                  item?.price?.harga_promosi_coret_semi_grosir
                              ) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate("/produk/deskripsi?idProduct=" + item.id);
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        ) : (
                          <NumberFormat
                            value={
                              Math.round(item?.price?.harga_ritel_gt) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate("/produk/deskripsi?idProduct=" + item.id);
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        )}
                        <div
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemView"
                        >
                          <div className="homeProductItemRating">
                            <ReactStars
                              count={1}
                              size={24}
                              edit={false}
                              color={"#ffd700"}
                            />
                            <p className="homeProductItemRatingValue">
                              {item.review
                                ? item.review[0]
                                  ? item.review[0].avg_rating
                                  : "0"
                                : "0"}
                            </p>
                          </div>
                          {item.cart && (
                            <div className="homeProductItemRating">
                              <img className="notifListIcon" src={IconCart} />
                              <p className="homeProductItemRatingValue">
                                {item.cart.qty}
                              </p>
                            </div>
                          )}
                        </div>
                        {item.cart ? (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Tambah</p>
                          </button>
                        ) : (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Beli</p>
                          </button>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <hr className="hr"></hr>
            </>
          )}
          {herbalNewest.length > 0 && (
            <>
              <div className="homeProduct">
                {qtyTotalHerbalPopular > 10 ? (
                  <div className="homeViewLihatSemua">
                    <p className="homeProductTitle">Produk Herbal Terbaru</p>
                    <div
                      onClick={() => {
                        dispatch(screenAction("HerbalNew", "screenName"));
                        navigate("/produk/list");
                      }}
                      className="homeGroupLihatSemua"
                    >
                      <p className="homeLihatSemua">Lihat Semua</p>
                      <img
                        className="homeImageIconNextAtas"
                        src={IconNextAtas}
                      />
                    </div>
                  </div>
                ) : (
                  <p className="homeProductTitle">Produk Herbal Terbaru</p>
                )}
                <div className="homeProductContainer">
                  {herbalNewest.map((item, index) => {
                    return (
                      <div key={index} className="homeProductItem">
                        {item.promo_sku.length > 0 && (
                          <div
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            className="homeProductItemPromo"
                          >
                            <p className="homeProductItemPromoText">Promo</p>
                          </div>
                        )}
                        {item.image ? (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            src={CONFIG.BASE_URL + item.image}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        ) : (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            src={DummyImage}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        )}
                        <p
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemTitle"
                        >
                          {item.name}
                        </p>
                        {item.status_promosi_coret == 1 ? (
                          <NumberFormat
                            value={
                              Math.round(
                                item?.price?.harga_promosi_coret_ritel_gt ||
                                  item?.price?.harga_promosi_coret_grosir_mt ||
                                  item?.price?.harga_promosi_coret_semi_grosir
                              ) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate("/produk/deskripsi?idProduct=" + item.id);
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        ) : (
                          <NumberFormat
                            value={
                              Math.round(item?.price?.harga_ritel_gt) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate("/produk/deskripsi?idProduct=" + item.id);
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        )}
                        <div
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemView"
                        >
                          <div className="homeProductItemRating">
                            <ReactStars
                              count={1}
                              size={24}
                              edit={false}
                              color={"#ffd700"}
                            />
                            <p className="homeProductItemRatingValue">
                              {item.review
                                ? item.review[0]
                                  ? item.review[0].avg_rating
                                  : "0"
                                : "0"}
                            </p>
                          </div>
                          {item.cart && (
                            <div className="homeProductItemRating">
                              <img className="notifListIcon" src={IconCart} />
                              <p className="homeProductItemRatingValue">
                                {item.cart.qty}
                              </p>
                            </div>
                          )}
                        </div>
                        {item.cart ? (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Tambah</p>
                          </button>
                        ) : (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Beli</p>
                          </button>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <hr className="hr"></hr>
            </>
          )}
          {herbalRecent.length > 0 && (
            <>
              <div className="homeProduct">
                {qtyTotalHerbalRecentBuy > 10 ? (
                  <div className="homeViewLihatSemua">
                    <p className="homeProductTitle">
                      Produk Herbal Terakhir Dipesan
                    </p>
                    <div
                      onClick={() => {
                        dispatch(screenAction("HerbalRecent", "screenName"));
                        navigate("/produk/list");
                      }}
                      className="homeGroupLihatSemua"
                    >
                      <p className="homeLihatSemua">Lihat Semua</p>
                      <img
                        className="homeImageIconNextAtas"
                        src={IconNextAtas}
                      />
                    </div>
                  </div>
                ) : (
                  <p className="homeProductTitle">
                    Produk Herbal Terakhir Dipesan
                  </p>
                )}
                <div className="homeProductContainer">
                  {herbalRecent.map((item, index) => {
                    return (
                      <div key={index} className="homeProductItem">
                        {item.promo_sku.length > 0 && (
                          <div
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            className="homeProductItemPromo"
                          >
                            <p className="homeProductItemPromoText">Promo</p>
                          </div>
                        )}
                        {item.image ? (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            src={CONFIG.BASE_URL + item.image}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        ) : (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            src={DummyImage}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        )}
                        <p
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemTitle"
                        >
                          {item.name}
                        </p>
                        {item.status_promosi_coret == 1 ? (
                          <NumberFormat
                            value={
                              Math.round(
                                item?.price?.harga_promosi_coret_ritel_gt ||
                                  item?.price?.harga_promosi_coret_grosir_mt ||
                                  item?.price?.harga_promosi_coret_semi_grosir
                              ) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate("/produk/deskripsi?idProduct=" + item.id);
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        ) : (
                          <NumberFormat
                            value={
                              Math.round(item?.price?.harga_ritel_gt) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate("/produk/deskripsi?idProduct=" + item.id);
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        )}
                        <div
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemView"
                        >
                          <div className="homeProductItemRating">
                            <ReactStars
                              count={1}
                              size={24}
                              edit={false}
                              color={"#ffd700"}
                            />
                            <p className="homeProductItemRatingValue">
                              {item.review
                                ? item.review[0]
                                  ? item.review[0].avg_rating
                                  : "0"
                                : "0"}
                            </p>
                          </div>
                          {item.cart && (
                            <div className="homeProductItemRating">
                              <img className="notifListIcon" src={IconCart} />
                              <p className="homeProductItemRatingValue">
                                {item.cart.qty}
                              </p>
                            </div>
                          )}
                        </div>
                        {item.cart ? (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Tambah</p>
                          </button>
                        ) : (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Beli</p>
                          </button>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <hr className="hr"></hr>
            </>
          )}
          {supmulAll.length > 0 && (
            <>
              <div className="homeProduct">
                {qtyTotalSupMulAll > 10 ? (
                  <div className="homeViewLihatSemua">
                    <p className="homeProductTitle">
                      Produk Supplemen dan Multivitamin Semua
                    </p>
                    <div
                      onClick={() => {
                        dispatch(screenAction("SupMul", "screenName"));
                        navigate("/produk/list");
                      }}
                      className="homeGroupLihatSemua"
                    >
                      <p className="homeLihatSemua">Lihat Semua</p>
                      <img
                        className="homeImageIconNextAtas"
                        src={IconNextAtas}
                      />
                    </div>
                  </div>
                ) : (
                  <p className="homeProductTitle">
                    Produk Supplemen dan Multivitamin Semua
                  </p>
                )}
                <div className="homeProductContainer">
                  {supmulAll.map((item, index) => {
                    return (
                      <div key={index} className="homeProductItem">
                        {item.promo_sku.length > 0 && (
                          <div
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            className="homeProductItemPromo"
                          >
                            <p className="homeProductItemPromoText">Promo</p>
                          </div>
                        )}
                        {item.image ? (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            src={CONFIG.BASE_URL + item.image}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        ) : (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            src={DummyImage}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        )}
                        <p
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemTitle"
                        >
                          {item.name}
                        </p>
                        {item.status_promosi_coret == 1 ? (
                          <NumberFormat
                            value={
                              Math.round(
                                item?.price?.harga_promosi_coret_ritel_gt ||
                                  item?.price?.harga_promosi_coret_grosir_mt ||
                                  item?.price?.harga_promosi_coret_semi_grosir
                              ) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate("/produk/deskripsi?idProduct=" + item.id);
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        ) : (
                          <NumberFormat
                            value={
                              Math.round(item?.price?.harga_ritel_gt) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate("/produk/deskripsi?idProduct=" + item.id);
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        )}
                        <div
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemView"
                        >
                          <div className="homeProductItemRating">
                            <ReactStars
                              count={1}
                              size={24}
                              edit={false}
                              color={"#ffd700"}
                            />
                            <p className="homeProductItemRatingValue">
                              {item.review
                                ? item.review[0]
                                  ? item.review[0].avg_rating
                                  : "0"
                                : "0"}
                            </p>
                          </div>
                          {item.cart && (
                            <div className="homeProductItemRating">
                              <img className="notifListIcon" src={IconCart} />
                              <p className="homeProductItemRatingValue">
                                {item.cart.qty}
                              </p>
                            </div>
                          )}
                        </div>
                        {item.cart ? (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Tambah</p>
                          </button>
                        ) : (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Beli</p>
                          </button>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <hr className="hr"></hr>
            </>
          )}
          {supmulPopular.length > 0 && (
            <>
              <div className="homeProduct">
                {qtyTotalSupMulPopular > 10 ? (
                  <div className="homeViewLihatSemua">
                    <p className="homeProductTitle">
                      Produk Supplemen dan Multivitamin Terlaris
                    </p>
                    <div
                      onClick={() => {
                        dispatch(screenAction("SupMulPopuler", "screenName"));
                        navigate("/produk/list");
                      }}
                      className="homeGroupLihatSemua"
                    >
                      <p className="homeLihatSemua">Lihat Semua</p>
                      <img
                        className="homeImageIconNextAtas"
                        src={IconNextAtas}
                      />
                    </div>
                  </div>
                ) : (
                  <p className="homeProductTitle">
                    Produk Supplemen dan Multivitamin Terlaris
                  </p>
                )}
                <div className="homeProductContainer">
                  {supmulPopular.map((item, index) => {
                    return (
                      <div key={index} className="homeProductItem">
                        {item.promo_sku.length > 0 && (
                          <div
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            className="homeProductItemPromo"
                          >
                            <p className="homeProductItemPromoText">Promo</p>
                          </div>
                        )}
                        {item.image ? (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            src={CONFIG.BASE_URL + item.image}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        ) : (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            src={DummyImage}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        )}
                        <p
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemTitle"
                        >
                          {item.name}
                        </p>
                        {item.status_promosi_coret == 1 ? (
                          <NumberFormat
                            value={
                              Math.round(
                                item?.price?.harga_promosi_coret_ritel_gt ||
                                  item?.price?.harga_promosi_coret_grosir_mt ||
                                  item?.price?.harga_promosi_coret_semi_grosir
                              ) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate("/produk/deskripsi?idProduct=" + item.id);
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        ) : (
                          <NumberFormat
                            value={
                              Math.round(item?.price?.harga_ritel_gt) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate("/produk/deskripsi?idProduct=" + item.id);
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        )}
                        <div
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemView"
                        >
                          <div className="homeProductItemRating">
                            <ReactStars
                              count={1}
                              size={24}
                              edit={false}
                              color={"#ffd700"}
                            />
                            <p className="homeProductItemRatingValue">
                              {item.review
                                ? item.review[0]
                                  ? item.review[0].avg_rating
                                  : "0"
                                : "0"}
                            </p>
                          </div>
                          {item.cart && (
                            <div className="homeProductItemRating">
                              <img className="notifListIcon" src={IconCart} />
                              <p className="homeProductItemRatingValue">
                                {item.cart.qty}
                              </p>
                            </div>
                          )}
                        </div>
                        {item.cart ? (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Tambah</p>
                          </button>
                        ) : (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Beli</p>
                          </button>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <hr className="hr"></hr>
            </>
          )}
          {supmulNewest.length > 0 && (
            <>
              <div className="homeProduct">
                {qtyTotalSupMulNew > 10 ? (
                  <div className="homeViewLihatSemua">
                    <p className="homeProductTitle">
                      Produk Supplemen dan Multivitamin Terbaru
                    </p>
                    <div
                      onClick={() => {
                        dispatch(screenAction("SupMulNew", "screenName"));
                        navigate("/produk/list");
                      }}
                      className="homeGroupLihatSemua"
                    >
                      <p className="homeLihatSemua">Lihat Semua</p>
                      <img
                        className="homeImageIconNextAtas"
                        src={IconNextAtas}
                      />
                    </div>
                  </div>
                ) : (
                  <p className="homeProductTitle">
                    Produk Supplemen dan Multivitamin Terbaru
                  </p>
                )}
                <div className="homeProductContainer">
                  {supmulNewest.map((item, index) => {
                    return (
                      <div key={index} className="homeProductItem">
                        {item.promo_sku.length > 0 && (
                          <div
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            className="homeProductItemPromo"
                          >
                            <p className="homeProductItemPromoText">Promo</p>
                          </div>
                        )}
                        {item.image ? (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            src={CONFIG.BASE_URL + item.image}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        ) : (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            src={DummyImage}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        )}
                        <p
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemTitle"
                        >
                          {item.name}
                        </p>
                        {item.status_promosi_coret == 1 ? (
                          <NumberFormat
                            value={
                              Math.round(
                                item?.price?.harga_promosi_coret_ritel_gt ||
                                  item?.price?.harga_promosi_coret_grosir_mt ||
                                  item?.price?.harga_promosi_coret_semi_grosir
                              ) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate("/produk/deskripsi?idProduct=" + item.id);
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        ) : (
                          <NumberFormat
                            value={
                              Math.round(item?.price?.harga_ritel_gt) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate("/produk/deskripsi?idProduct=" + item.id);
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        )}
                        <div
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemView"
                        >
                          <div className="homeProductItemRating">
                            <ReactStars
                              count={1}
                              size={24}
                              edit={false}
                              color={"#ffd700"}
                            />
                            <p className="homeProductItemRatingValue">
                              {item.review
                                ? item.review[0]
                                  ? item.review[0].avg_rating
                                  : "0"
                                : "0"}
                            </p>
                          </div>
                          {item.cart && (
                            <div className="homeProductItemRating">
                              <img className="notifListIcon" src={IconCart} />
                              <p className="homeProductItemRatingValue">
                                {item.cart.qty}
                              </p>
                            </div>
                          )}
                        </div>
                        {item.cart ? (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Tambah</p>
                          </button>
                        ) : (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Beli</p>
                          </button>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <hr className="hr"></hr>
            </>
          )}
          {supmulRecent.length > 0 && (
            <>
              <div className="homeProduct">
                {qtyTotalSupMulRecentBuy > 10 ? (
                  <div className="homeViewLihatSemua">
                    <p className="homeProductTitle">
                      Produk Supplemen dan Multivitamin Terakhir Dipesan
                    </p>
                    <div
                      onClick={() => {
                        dispatch(screenAction("SupMulRecent", "screenName"));
                        navigate("/produk/list");
                      }}
                      className="homeGroupLihatSemua"
                    >
                      <p className="homeLihatSemua">Lihat Semua</p>
                      <img
                        className="homeImageIconNextAtas"
                        src={IconNextAtas}
                      />
                    </div>
                  </div>
                ) : (
                  <p className="homeProductTitle">
                    Produk Supplemen dan Multivitamin Terakhir Dipesan
                  </p>
                )}
                <div className="homeProductContainer">
                  {supmulRecent.map((item, index) => {
                    return (
                      <div key={index} className="homeProductItem">
                        {item.promo_sku.length > 0 && (
                          <div
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            className="homeProductItemPromo"
                          >
                            <p className="homeProductItemPromoText">Promo</p>
                          </div>
                        )}
                        {item.image ? (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            src={CONFIG.BASE_URL + item.image}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        ) : (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            src={DummyImage}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        )}
                        <p
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemTitle"
                        >
                          {item.name}
                        </p>
                        {item.status_promosi_coret == 1 ? (
                          <NumberFormat
                            value={
                              Math.round(
                                item?.price?.harga_promosi_coret_ritel_gt ||
                                  item?.price?.harga_promosi_coret_grosir_mt ||
                                  item?.price?.harga_promosi_coret_semi_grosir
                              ) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate("/produk/deskripsi?idProduct=" + item.id);
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        ) : (
                          <NumberFormat
                            value={
                              Math.round(item?.price?.harga_ritel_gt) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate("/produk/deskripsi?idProduct=" + item.id);
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        )}
                        <div
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemView"
                        >
                          <div className="homeProductItemRating">
                            <ReactStars
                              count={1}
                              size={24}
                              edit={false}
                              color={"#ffd700"}
                            />
                            <p className="homeProductItemRatingValue">
                              {item.review
                                ? item.review[0]
                                  ? item.review[0].avg_rating
                                  : "0"
                                : "0"}
                            </p>
                          </div>
                          {item.cart && (
                            <div className="homeProductItemRating">
                              <img className="notifListIcon" src={IconCart} />
                              <p className="homeProductItemRatingValue">
                                {item.cart.qty}
                              </p>
                            </div>
                          )}
                        </div>
                        {item.cart ? (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Tambah</p>
                          </button>
                        ) : (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Beli</p>
                          </button>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <hr className="hr"></hr>
            </>
          )}
          {foodbevAll.length > 0 && (
            <>
              <div className="homeProduct">
                {qtyTotalFoodBevAll > 10 ? (
                  <div className="homeViewLihatSemua">
                    <p className="homeProductTitle">
                      Produk Makanan dan Minuman Semua
                    </p>
                    <div
                      onClick={() => {
                        dispatch(screenAction("FoodBev", "screenName"));
                        navigate("/produk/list");
                      }}
                      className="homeGroupLihatSemua"
                    >
                      <p className="homeLihatSemua">Lihat Semua</p>
                      <img
                        className="homeImageIconNextAtas"
                        src={IconNextAtas}
                      />
                    </div>
                  </div>
                ) : (
                  <p className="homeProductTitle">
                    Produk Makanan dan Minuman Semua
                  </p>
                )}
                <div className="homeProductContainer">
                  {foodbevAll.map((item, index) => {
                    return (
                      <div key={index} className="homeProductItem">
                        {item.promo_sku.length > 0 && (
                          <div
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            className="homeProductItemPromo"
                          >
                            <p className="homeProductItemPromoText">Promo</p>
                          </div>
                        )}
                        {item.image ? (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            src={CONFIG.BASE_URL + item.image}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        ) : (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            src={DummyImage}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        )}
                        <p
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemTitle"
                        >
                          {item.name}
                        </p>
                        {item.status_promosi_coret == 1 ? (
                          <NumberFormat
                            value={
                              Math.round(
                                item?.price?.harga_promosi_coret_ritel_gt ||
                                  item?.price?.harga_promosi_coret_grosir_mt ||
                                  item?.price?.harga_promosi_coret_semi_grosir
                              ) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate("/produk/deskripsi?idProduct=" + item.id);
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        ) : (
                          <NumberFormat
                            value={
                              Math.round(item?.price?.harga_ritel_gt) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate("/produk/deskripsi?idProduct=" + item.id);
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        )}
                        <div
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemView"
                        >
                          <div className="homeProductItemRating">
                            <ReactStars
                              count={1}
                              size={24}
                              edit={false}
                              color={"#ffd700"}
                            />
                            <p className="homeProductItemRatingValue">
                              {item.review
                                ? item.review[0]
                                  ? item.review[0].avg_rating
                                  : "0"
                                : "0"}
                            </p>
                          </div>
                          {item.cart && (
                            <div className="homeProductItemRating">
                              <img className="notifListIcon" src={IconCart} />
                              <p className="homeProductItemRatingValue">
                                {item.cart.qty}
                              </p>
                            </div>
                          )}
                        </div>
                        {item.cart ? (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Tambah</p>
                          </button>
                        ) : (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Beli</p>
                          </button>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <hr className="hr"></hr>
            </>
          )}
          {foodbevPopular.length > 0 && (
            <>
              <div className="homeProduct">
                {qtyTotalFoodBevPopular > 10 ? (
                  <div className="homeViewLihatSemua">
                    <p className="homeProductTitle">
                      Produk Makanan dan Minuman Terlaris
                    </p>
                    <div
                      onClick={() => {
                        dispatch(screenAction("FoodBevPopular", "screenName"));
                        navigate("/produk/list");
                      }}
                      className="homeGroupLihatSemua"
                    >
                      <p className="homeLihatSemua">Lihat Semua</p>
                      <img
                        className="homeImageIconNextAtas"
                        src={IconNextAtas}
                      />
                    </div>
                  </div>
                ) : (
                  <p className="homeProductTitle">
                    Produk Makanan dan Minuman Terlaris
                  </p>
                )}
                <div className="homeProductContainer">
                  {foodbevPopular.map((item, index) => {
                    return (
                      <div key={index} className="homeProductItem">
                        {item.promo_sku.length > 0 && (
                          <div
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            className="homeProductItemPromo"
                          >
                            <p className="homeProductItemPromoText">Promo</p>
                          </div>
                        )}
                        {item.image ? (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            src={CONFIG.BASE_URL + item.image}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        ) : (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            src={DummyImage}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        )}
                        <p
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemTitle"
                        >
                          {item.name}
                        </p>
                        {item.status_promosi_coret == 1 ? (
                          <NumberFormat
                            value={
                              Math.round(
                                item?.price?.harga_promosi_coret_ritel_gt ||
                                  item?.price?.harga_promosi_coret_grosir_mt ||
                                  item?.price?.harga_promosi_coret_semi_grosir
                              ) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate("/produk/deskripsi?idProduct=" + item.id);
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        ) : (
                          <NumberFormat
                            value={
                              Math.round(item?.price?.harga_ritel_gt) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate("/produk/deskripsi?idProduct=" + item.id);
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        )}
                        <div
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemView"
                        >
                          <div className="homeProductItemRating">
                            <ReactStars
                              count={1}
                              size={24}
                              edit={false}
                              color={"#ffd700"}
                            />
                            <p className="homeProductItemRatingValue">
                              {item.review
                                ? item.review[0]
                                  ? item.review[0].avg_rating
                                  : "0"
                                : "0"}
                            </p>
                          </div>
                          {item.cart && (
                            <div className="homeProductItemRating">
                              <img className="notifListIcon" src={IconCart} />
                              <p className="homeProductItemRatingValue">
                                {item.cart.qty}
                              </p>
                            </div>
                          )}
                        </div>
                        {item.cart ? (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Tambah</p>
                          </button>
                        ) : (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Beli</p>
                          </button>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <hr className="hr"></hr>
            </>
          )}
          {foodbevNewest.length > 0 && (
            <>
              <div className="homeProduct">
                {qtyTotalFoodBevNew > 10 ? (
                  <div className="homeViewLihatSemua">
                    <p className="homeProductTitle">
                      Produk Makanan dan Minuman Terbaru
                    </p>
                    <div
                      onClick={() => {
                        dispatch(screenAction("FoodBevNew", "screenName"));
                        navigate("/produk/list");
                      }}
                      className="homeGroupLihatSemua"
                    >
                      <p className="homeLihatSemua">Lihat Semua</p>
                      <img
                        className="homeImageIconNextAtas"
                        src={IconNextAtas}
                      />
                    </div>
                  </div>
                ) : (
                  <p className="homeProductTitle">
                    Produk Makanan dan Minuman Terbaru
                  </p>
                )}
                <div className="homeProductContainer">
                  {foodbevNewest.map((item, index) => {
                    return (
                      <div key={index} className="homeProductItem">
                        {item.promo_sku.length > 0 && (
                          <div
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            className="homeProductItemPromo"
                          >
                            <p className="homeProductItemPromoText">Promo</p>
                          </div>
                        )}
                        {item.image ? (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            src={CONFIG.BASE_URL + item.image}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        ) : (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            src={DummyImage}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        )}
                        <p
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemTitle"
                        >
                          {item.name}
                        </p>
                        {item.status_promosi_coret == 1 ? (
                          <NumberFormat
                            value={
                              Math.round(
                                item?.price?.harga_promosi_coret_ritel_gt ||
                                  item?.price?.harga_promosi_coret_grosir_mt ||
                                  item?.price?.harga_promosi_coret_semi_grosir
                              ) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate("/produk/deskripsi?idProduct=" + item.id);
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        ) : (
                          <NumberFormat
                            value={
                              Math.round(item?.price?.harga_ritel_gt) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate("/produk/deskripsi?idProduct=" + item.id);
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        )}
                        <div
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemView"
                        >
                          <div className="homeProductItemRating">
                            <ReactStars
                              count={1}
                              size={24}
                              edit={false}
                              color={"#ffd700"}
                            />
                            <p className="homeProductItemRatingValue">
                              {item.review
                                ? item.review[0]
                                  ? item.review[0].avg_rating
                                  : "0"
                                : "0"}
                            </p>
                          </div>
                          {item.cart && (
                            <div className="homeProductItemRating">
                              <img className="notifListIcon" src={IconCart} />
                              <p className="homeProductItemRatingValue">
                                {item.cart.qty}
                              </p>
                            </div>
                          )}
                        </div>
                        {item.cart ? (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Tambah</p>
                          </button>
                        ) : (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Beli</p>
                          </button>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <hr className="hr"></hr>
            </>
          )}
          {foodbevRecent.length > 0 && (
            <>
              <div className="homeProduct">
                {qtyTotalFoodBevRecentBuy > 10 ? (
                  <div className="homeViewLihatSemua">
                    <p className="homeProductTitle">
                      Produk Makanan dan Minuman Terakhir Dipesan
                    </p>
                    <div
                      onClick={() => {
                        dispatch(screenAction("FoodBevRecent", "screenName"));
                        navigate("/produk/list");
                      }}
                      className="homeGroupLihatSemua"
                    >
                      <p className="homeLihatSemua">Lihat Semua</p>
                      <img
                        className="homeImageIconNextAtas"
                        src={IconNextAtas}
                      />
                    </div>
                  </div>
                ) : (
                  <p className="homeProductTitle">
                    Produk Makanan dan Minuman Terakhir Dipesan
                  </p>
                )}
                <div className="homeProductContainer">
                  {foodbevRecent.map((item, index) => {
                    return (
                      <div key={index} className="homeProductItem">
                        {item.promo_sku.length > 0 && (
                          <div
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            className="homeProductItemPromo"
                          >
                            <p className="homeProductItemPromoText">Promo</p>
                          </div>
                        )}
                        {item.image ? (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            src={CONFIG.BASE_URL + item.image}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        ) : (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            src={DummyImage}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        )}
                        <p
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemTitle"
                        >
                          {item.name}
                        </p>
                        {item.status_promosi_coret == 1 ? (
                          <NumberFormat
                            value={
                              Math.round(
                                item?.price?.harga_promosi_coret_ritel_gt ||
                                  item?.price?.harga_promosi_coret_grosir_mt ||
                                  item?.price?.harga_promosi_coret_semi_grosir
                              ) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate("/produk/deskripsi?idProduct=" + item.id);
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        ) : (
                          <NumberFormat
                            value={
                              Math.round(item?.price?.harga_ritel_gt) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate("/produk/deskripsi?idProduct=" + item.id);
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        )}
                        <div
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemView"
                        >
                          <div className="homeProductItemRating">
                            <ReactStars
                              count={1}
                              size={24}
                              edit={false}
                              color={"#ffd700"}
                            />
                            <p className="homeProductItemRatingValue">
                              {item.review
                                ? item.review[0]
                                  ? item.review[0].avg_rating
                                  : "0"
                                : "0"}
                            </p>
                          </div>
                          {item.cart && (
                            <div className="homeProductItemRating">
                              <img className="notifListIcon" src={IconCart} />
                              <p className="homeProductItemRatingValue">
                                {item.cart.qty}
                              </p>
                            </div>
                          )}
                        </div>
                        {item.cart ? (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Tambah</p>
                          </button>
                        ) : (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Beli</p>
                          </button>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <hr className="hr"></hr>
            </>
          )}
          {minyakbalsemAll.length > 0 && (
            <>
              <div className="homeProduct">
                {qtyTotalMinyakAll > 10 ? (
                  <div className="homeViewLihatSemua">
                    <p className="homeProductTitle">
                      Produk Minyak Angin dan Balsem Semua
                    </p>
                    <div
                      onClick={() => {
                        dispatch(screenAction("Minyak", "screenName"));
                        navigate("/produk/list");
                      }}
                      className="homeGroupLihatSemua"
                    >
                      <p className="homeLihatSemua">Lihat Semua</p>
                      <img
                        className="homeImageIconNextAtas"
                        src={IconNextAtas}
                      />
                    </div>
                  </div>
                ) : (
                  <p className="homeProductTitle">
                    Produk Minyak Angin dan Balsem Semua
                  </p>
                )}
                <div className="homeProductContainer">
                  {minyakbalsemAll.map((item, index) => {
                    return (
                      <div key={index} className="homeProductItem">
                        {item.promo_sku.length > 0 && (
                          <div
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            className="homeProductItemPromo"
                          >
                            <p className="homeProductItemPromoText">Promo</p>
                          </div>
                        )}
                        {item.image ? (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            src={CONFIG.BASE_URL + item.image}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        ) : (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            src={DummyImage}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        )}
                        <p
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemTitle"
                        >
                          {item.name}
                        </p>
                        {item.status_promosi_coret == 1 ? (
                          <NumberFormat
                            value={
                              Math.round(
                                item?.price?.harga_promosi_coret_ritel_gt ||
                                  item?.price?.harga_promosi_coret_grosir_mt ||
                                  item?.price?.harga_promosi_coret_semi_grosir
                              ) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate("/produk/deskripsi?idProduct=" + item.id);
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        ) : (
                          <NumberFormat
                            value={
                              Math.round(item?.price?.harga_ritel_gt) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate("/produk/deskripsi?idProduct=" + item.id);
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        )}
                        <div
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemView"
                        >
                          <div className="homeProductItemRating">
                            <ReactStars
                              count={1}
                              size={24}
                              edit={false}
                              color={"#ffd700"}
                            />
                            <p className="homeProductItemRatingValue">
                              {item.review
                                ? item.review[0]
                                  ? item.review[0].avg_rating
                                  : "0"
                                : "0"}
                            </p>
                          </div>
                          {item.cart && (
                            <div className="homeProductItemRating">
                              <img className="notifListIcon" src={IconCart} />
                              <p className="homeProductItemRatingValue">
                                {item.cart.qty}
                              </p>
                            </div>
                          )}
                        </div>
                        {item.cart ? (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Tambah</p>
                          </button>
                        ) : (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Beli</p>
                          </button>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <hr className="hr"></hr>
            </>
          )}
          {minyakbalsemPopular.length > 0 && (
            <>
              <div className="homeProduct">
                {qtyTotalMinyakPopular > 10 ? (
                  <div className="homeViewLihatSemua">
                    <p className="homeProductTitle">
                      Produk Minyak Angin dan Balsem Terlaris
                    </p>
                    <div
                      onClick={() => {
                        dispatch(screenAction("MinyakPopular", "screenName"));
                        navigate("/produk/list");
                      }}
                      className="homeGroupLihatSemua"
                    >
                      <p className="homeLihatSemua">Lihat Semua</p>
                      <img
                        className="homeImageIconNextAtas"
                        src={IconNextAtas}
                      />
                    </div>
                  </div>
                ) : (
                  <p className="homeProductTitle">
                    Produk Minyak Angin dan Balsem Terlaris
                  </p>
                )}
                <div className="homeProductContainer">
                  {minyakbalsemPopular.map((item, index) => {
                    return (
                      <div key={index} className="homeProductItem">
                        {item.promo_sku.length > 0 && (
                          <div
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            className="homeProductItemPromo"
                          >
                            <p className="homeProductItemPromoText">Promo</p>
                          </div>
                        )}
                        {item.image ? (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            src={CONFIG.BASE_URL + item.image}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        ) : (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            src={DummyImage}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        )}
                        <p
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemTitle"
                        >
                          {item.name}
                        </p>
                        {item.status_promosi_coret == 1 ? (
                          <NumberFormat
                            value={
                              Math.round(
                                item?.price?.harga_promosi_coret_ritel_gt ||
                                  item?.price?.harga_promosi_coret_grosir_mt ||
                                  item?.price?.harga_promosi_coret_semi_grosir
                              ) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate("/produk/deskripsi?idProduct=" + item.id);
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        ) : (
                          <NumberFormat
                            value={
                              Math.round(item?.price?.harga_ritel_gt) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate("/produk/deskripsi?idProduct=" + item.id);
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        )}
                        <div
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemView"
                        >
                          <div className="homeProductItemRating">
                            <ReactStars
                              count={1}
                              size={24}
                              edit={false}
                              color={"#ffd700"}
                            />
                            <p className="homeProductItemRatingValue">
                              {item.review
                                ? item.review[0]
                                  ? item.review[0].avg_rating
                                  : "0"
                                : "0"}
                            </p>
                          </div>
                          {item.cart && (
                            <div className="homeProductItemRating">
                              <img className="notifListIcon" src={IconCart} />
                              <p className="homeProductItemRatingValue">
                                {item.cart.qty}
                              </p>
                            </div>
                          )}
                        </div>
                        {item.cart ? (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Tambah</p>
                          </button>
                        ) : (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Beli</p>
                          </button>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <hr className="hr"></hr>
            </>
          )}
          {minyakbalsemNewest.length > 0 && (
            <>
              <div className="homeProduct">
                {qtyTotalMinyakNew > 10 ? (
                  <div className="homeViewLihatSemua">
                    <p className="homeProductTitle">
                      Produk Minyak Angin dan Balsem Terbaru
                    </p>
                    <div
                      onClick={() => {
                        dispatch(screenAction("MinyakNew", "screenName"));
                        navigate("/produk/list");
                      }}
                      className="homeGroupLihatSemua"
                    >
                      <p className="homeLihatSemua">Lihat Semua</p>
                      <img
                        className="homeImageIconNextAtas"
                        src={IconNextAtas}
                      />
                    </div>
                  </div>
                ) : (
                  <p className="homeProductTitle">
                    Produk Minyak Angin dan Balsem Terbaru
                  </p>
                )}
                <div className="homeProductContainer">
                  {minyakbalsemNewest.map((item, index) => {
                    return (
                      <div key={index} className="homeProductItem">
                        {item.promo_sku.length > 0 && (
                          <div
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            className="homeProductItemPromo"
                          >
                            <p className="homeProductItemPromoText">Promo</p>
                          </div>
                        )}
                        {item.image ? (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            src={CONFIG.BASE_URL + item.image}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        ) : (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            src={DummyImage}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        )}
                        <p
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemTitle"
                        >
                          {item.name}
                        </p>
                        {item.status_promosi_coret == 1 ? (
                          <NumberFormat
                            value={
                              Math.round(
                                item?.price?.harga_promosi_coret_ritel_gt ||
                                  item?.price?.harga_promosi_coret_grosir_mt ||
                                  item?.price?.harga_promosi_coret_semi_grosir
                              ) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate("/produk/deskripsi?idProduct=" + item.id);
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        ) : (
                          <NumberFormat
                            value={
                              Math.round(item?.price?.harga_ritel_gt) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate("/produk/deskripsi?idProduct=" + item.id);
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        )}
                        <div
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemView"
                        >
                          <div className="homeProductItemRating">
                            <ReactStars
                              count={1}
                              size={24}
                              edit={false}
                              color={"#ffd700"}
                            />
                            <p className="homeProductItemRatingValue">
                              {item.review
                                ? item.review[0]
                                  ? item.review[0].avg_rating
                                  : "0"
                                : "0"}
                            </p>
                          </div>
                          {item.cart && (
                            <div className="homeProductItemRating">
                              <img className="notifListIcon" src={IconCart} />
                              <p className="homeProductItemRatingValue">
                                {item.cart.qty}
                              </p>
                            </div>
                          )}
                        </div>
                        {item.cart ? (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Tambah</p>
                          </button>
                        ) : (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Beli</p>
                          </button>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <hr className="hr"></hr>
            </>
          )}
          {minyakbalsemRecent.length > 0 && (
            <>
              <div className="homeProduct">
                {qtyTotalMinyakRecentBuy > 10 ? (
                  <div className="homeViewLihatSemua">
                    <p className="homeProductTitle">
                      Produk Minyak Angin dan Balsem Terakhir Dipesan
                    </p>
                    <div
                      onClick={() => {
                        dispatch(screenAction("MinyakRecent", "screenName"));
                        navigate("/produk/list");
                      }}
                      className="homeGroupLihatSemua"
                    >
                      <p className="homeLihatSemua">Lihat Semua</p>
                      <img
                        className="homeImageIconNextAtas"
                        src={IconNextAtas}
                      />
                    </div>
                  </div>
                ) : (
                  <p className="homeProductTitle">
                    Produk Minyak Angin dan Balsem Terakhir Dipesan
                  </p>
                )}
                <div className="homeProductContainer">
                  {minyakbalsemRecent.map((item, index) => {
                    return (
                      <div key={index} className="homeProductItem">
                        {item.promo_sku.length > 0 && (
                          <div
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            className="homeProductItemPromo"
                          >
                            <p className="homeProductItemPromoText">Promo</p>
                          </div>
                        )}
                        {item.image ? (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            src={CONFIG.BASE_URL + item.image}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        ) : (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi?idProduct=" + item.id);
                            }}
                            src={DummyImage}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        )}
                        <p
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemTitle"
                        >
                          {item.name}
                        </p>
                        {item.status_promosi_coret == 1 ? (
                          <NumberFormat
                            value={
                              Math.round(
                                item?.price?.harga_promosi_coret_ritel_gt ||
                                  item?.price?.harga_promosi_coret_grosir_mt ||
                                  item?.price?.harga_promosi_coret_semi_grosir
                              ) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate("/produk/deskripsi?idProduct=" + item.id);
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        ) : (
                          <NumberFormat
                            value={
                              Math.round(item?.price?.harga_ritel_gt) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate("/produk/deskripsi?idProduct=" + item.id);
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        )}
                        <div
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi?idProduct=" + item.id);
                          }}
                          className="homeProductItemView"
                        >
                          <div className="homeProductItemRating">
                            <ReactStars
                              count={1}
                              size={24}
                              edit={false}
                              color={"#ffd700"}
                            />
                            <p className="homeProductItemRatingValue">
                              {item.review
                                ? item.review[0]
                                  ? item.review[0].avg_rating
                                  : "0"
                                : "0"}
                            </p>
                          </div>
                          {item.cart && (
                            <div className="homeProductItemRating">
                              <img className="notifListIcon" src={IconCart} />
                              <p className="homeProductItemRatingValue">
                                {item.cart.qty}
                              </p>
                            </div>
                          )}
                        </div>
                        {item.cart ? (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Tambah</p>
                          </button>
                        ) : (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Beli</p>
                          </button>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <hr className="hr"></hr>
            </>
          )}
        </div>
      )}
      <Navbar screenName={"Produk"} />
      <Loading loadingApi={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Produk);
