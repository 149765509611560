import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import IconBack from "../assets/images/backArrow.webp";
import "./TransactionDetail.css";
import { useSelector } from "react-redux";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import CircularProgress from "@mui/material/CircularProgress";
import { produkAction, screenAction, orderAction } from "../actions";
import { useDispatch } from "react-redux";
import FormDialog from "../components/Dialog";
import NumberFormat from "react-number-format";
import moment from "moment";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import ModalTracking from "../components/ModalTracking";
import SimpleHeader from "../components/HeaderDistributor";
export const StokKosong = () => {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadingApi, setLoadingApi] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [buttonDisabled2, setButtonDisabled2] = useState(false);
  const [notes, setNotes] = useState("");
  const [open, setOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [alertTambahan, setAlertTambahan] = useState("");
  const [alertJudul, setAlertJudul] = useState("");
  const [cancelOrder, setCancelOrder] = useState(false);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const [dataTransaction, setDataTansaction] = useState({});
  const [openModalTracking, setOpenModalTracking] = useState(false);
  const [dataTracking, setDataTracking] = useState("");

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  useEffect(() => {
    if (loadingApi) {
      getDataTransaction();
      setTimeout(() => setLoadingApi(false), 10000);
    }
    getDataTracking();

    return () => {};
  }, [loadingApi]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async (e) => {
    setOpen(false);
  };

  async function postCancelOrder() {
    if (!notes.trim()) {
      setCancelOrder(!cancelOrder);
      setButtonDisabled(!buttonDisabled);
      setAlertData("Harap masukkan alesan kamu");
      setAlertJudul("Pemberitahuan");
      handleClickOpen();
    } else if (notes.length < 8) {
      setCancelOrder(!cancelOrder);
      setButtonDisabled(!buttonDisabled);
      setAlertData("Alasan pembatalan minimal 8 huruf");
      setAlertJudul("Pemberitahuan");
      handleClickOpen();
    } else {
      try {
        let response = await axios({
          method: "post",
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          data: { notes: notes },
          url: `${CONFIG.BASE_URL}/api/orders/cancel/${dataTransaction.id}`,
        });
        const data = response.data;
        if (data !== "" && data["success"] == true) {
          setButtonDisabled(!buttonDisabled);
          setCancelOrder(!cancelOrder);
          navigate(-1);
        } else {
          setAlertData("Gagal membatalkan pesanan");
          setAlertJudul("Pemberitahuan");
          handleClickOpen();
          setButtonDisabled(!buttonDisabled);
          setCancelOrder(!cancelOrder);
        }
      } catch (error) {
        setCancelOrder(!cancelOrder);
        setButtonDisabled(!buttonDisabled);
        let error429 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 429";
        let errorNetwork =
          JSON.parse(JSON.stringify(error)).message === "Network Error";
        let error400 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 400";

        if (error429) {
          setAlertServerSibuk(true);
        } else if (errorNetwork) {
          setAlertInet(true);
        } else if (error400) {
          localStorage.removeItem("token");
          navigate("/home");
        }
      }
    }
  }

  async function postCompleteOrder() {
    try {
      let response = await axios.post(
        `${CONFIG.BASE_URL}/api/orders/complete/${dataTransaction.id}`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      let data = response.data;
      if (data !== "" && data["success"] == true) {
        setButtonDisabled2(!buttonDisabled2);
        navigate(-1);
      } else {
        setAlertData("Gagal menyelesaikan order");
        setAlertJudul("Pemberitahuan");
        handleClickOpen();
        setButtonDisabled2(!buttonDisabled2);
      }
    } catch (error) {
      setButtonDisabled2(!buttonDisabled2);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";

      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }
  async function getDataTransaction() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/transactions/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = response.data.data;
      setDataTansaction(data);
      setLoadingApi(false);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";

      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }
  async function getDataTracking() {
    try {
      let response = await axios.get(`${CONFIG.BASE_URL}/api/delivery`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const data = response?.data?.data;
      setDataTracking(data);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";

      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }
  function handleCloseCancelOrder() {
    setButtonDisabled(false);
    setCancelOrder(!cancelOrder);
  }
  function handleModalTracking() {
    setOpenModalTracking(false);
  }
  console.log("trans " + JSON.stringify(dataTransaction));
  return (
    <div className="transactionDetailContainer">
      <ModalTracking
        open={openModalTracking}
        handleClose={handleModalTracking}
        title={
          dataTracking?.city === undefined
            ? "Pesanan tidak dapat dilacak"
            : "Pesanan Anda Berada di daerah"
        }
        alamat={
          dataTracking?.city === undefined
            ? null
            : "Desa " +
              dataTracking?.village +
              ", Kecamatan " +
              dataTracking?.districts +
              ", Kota " +
              dataTracking?.city +
              ", Provinsi " +
              dataTracking?.province
        }
      />
      <SimpleHeader titleHeader="Detail Pesanan" />
      <div className="transactionDetailCard">
        <div className="flex">
          <div className="title">Status</div>
          {dataTransaction.status == 1 &&
          dataTransaction.status_faktur == "F" ? (
            <div className="value">Transaksi Baru</div>
          ) : dataTransaction.status == 2 &&
            dataTransaction.status_faktur == "F" ? (
            <div className="value">Transaksi Terkonfirmasi</div>
          ) : dataTransaction.status == 3 &&
            dataTransaction.status_faktur == "F" ? (
            <div className="value">Barang Diproses</div>
          ) : dataTransaction.status == 4 &&
            dataTransaction.status_faktur == "F" ? (
            <div className="value">Selesai</div>
          ) : dataTransaction.status == 10 &&
            dataTransaction.status_faktur == "F" ? (
            <div className="value">Batal</div>
          ) : (dataTransaction.status == 1 || 2 || 3 || 4 || 10) &&
            dataTransaction.status_faktur == "R" ? (
            <div className="value">Retur Barang</div>
          ) : (dataTransaction.status == 1 || 2 || 3 || 4 || 10) &&
            dataTransaction.status_faktur == "Redeem" ? (
            <div className="value">Redeem</div>
          ) : null}
        </div>
        <hr></hr>
        <div className="flex">
          <div className="title">Nomor Invoice</div>
          {dataTransaction.invoice ? (
            <div className="value">{dataTransaction.invoice}</div>
          ) : (
            <div className="value">{"Kosong"}</div>
          )}
        </div>
        <hr></hr>
        <div className="flex">
          <div className="title">Tanggal Pembelian</div>
          <div className="value">
            {moment(dataTransaction.created_at).format("DD MMM YYYY HH:mm")}
          </div>
        </div>
        <hr></hr>
        <div className="flex">
          <div className="title">Pengiriman</div>
          <div
            className="value2"
            onClick={() => navigate("/pembayaran/detail/status")}
          >
            Panunggangan Timur,Pinang,Tangerang,Banten
          </div>
        </div>
      </div>
      <hr></hr>
      <div className="transactionDetailCard">
        Detail Produk
        {dataTransaction.data_item?.map((item, index) => {
          if (
            dataTransaction?.data_cancel?.find((arr) => arr.id === item.id)
              ?.id === item.id
          ) {
            const data = dataTransaction?.data_cancel?.find(
              (arr) => arr.id === item.id
            );
            const success = dataTransaction?.data_success?.find(
              (arr) => arr.id === item.id
            );
            return (
              <div key={index} className="productList">
                <div className="main">
                  {item.product.image ? (
                    <img src={CONFIG.BASE_URL + item.product.image} />
                  ) : (
                    <img src={DummyImage} />
                  )}
                  <div className="content">
                    <div className="name">{item.product.name}</div>
                    <div className="halfView">
                      {dataTransaction.status_faktur == "Redeem" ? (
                        <>
                          <NumberFormat
                            value={Math.round(item?.price_apps) ?? "0"}
                            displayType={"text"}
                            thousandSeparator={true}
                            // prefix={"Rp "}
                            suffix={" poin"}
                            // decimalScale={2}
                            fixedDecimalScale={true}
                            renderText={(value) => (
                              <div className="price">
                                {item.qty}
                                {" x "}
                                {value || 0}
                              </div>
                            )}
                          />
                          <NumberFormat
                            value={Math.round(item?.total_price) ?? "0"}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            // decimalScale={2}
                            fixedDecimalScale={true}
                            renderText={(value) => (
                              <div className="price">
                                <p>
                                  &nbsp;=&nbsp;
                                  {value || 0}
                                </p>
                              </div>
                            )}
                          />
                        </>
                      ) : (
                        <>
                          {item.half ? (
                            <>
                              <NumberFormat
                                value={Math.round(item?.price_apps / 2) ?? "0"}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"Rp "}
                                // decimalScale={2}
                                fixedDecimalScale={true}
                                renderText={(value) => (
                                  <div className="price">
                                    {item.qty}
                                    {" x "}
                                    {value || 0}
                                  </div>
                                )}
                              />
                              <NumberFormat
                                value={Math.round(item?.total_price) ?? "0"}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"Rp "}
                                // decimalScale={2}
                                fixedDecimalScale={true}
                                renderText={(value) => (
                                  <div className="price">
                                    <p>
                                      &nbsp;=&nbsp;
                                      {value || 0}
                                    </p>
                                  </div>
                                )}
                              />
                            </>
                          ) : (
                            <>
                              <NumberFormat
                                value={Math.round(item?.price_apps) ?? "0"}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"Rp "}
                                // decimalScale={2}
                                fixedDecimalScale={true}
                                renderText={(value) => (
                                  <div className="price">
                                    {item.qty}
                                    {" x "}
                                    {value || 0}
                                  </div>
                                )}
                              />
                              <NumberFormat
                                value={Math.round(item?.total_price) ?? "0"}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"Rp "}
                                // decimalScale={2}
                                fixedDecimalScale={true}
                                renderText={(value) => (
                                  <div className="price">
                                    <p>
                                      &nbsp;=&nbsp;
                                      {value || 0}
                                    </p>
                                  </div>
                                )}
                              />
                            </>
                          )}
                        </>
                      )}
                      {item.half ? (
                        <div className="priceHalf">
                          {" ( "}
                          {item?.qty_konversi}{" "}
                          {item?.small_unit.charAt(0) +
                            item?.small_unit.slice(1).toLowerCase()}
                          {" )"}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <hr className="main2"></hr>
                <div className="main main2">
                  {item.product.image ? (
                    <img src={CONFIG.BASE_URL + item.product.image} />
                  ) : (
                    <img src={DummyImage} />
                  )}
                  <div className="content">
                    <div className="name">{item.product.name}</div>
                    <div className="textStock">
                      Stock Kosong {Math.round(data?.qty_cancel)}
                    </div>
                    <div className="halfView">
                      {dataTransaction.status_faktur == "Redeem" ? (
                        <>
                          <NumberFormat
                            value={Math.round(data?.price_apps) ?? "0"}
                            displayType={"text"}
                            thousandSeparator={true}
                            // prefix={"Rp "}
                            suffix={" poin"}
                            // decimalScale={2}
                            fixedDecimalScale={true}
                            renderText={(value) => (
                              <div className="price">
                                {data?.qty_cancel}
                                {" x "}
                                {value || 0}
                              </div>
                            )}
                          />
                          <NumberFormat
                            value={Math.round(data?.total_price_cancel) ?? "0"}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            // decimalScale={2}
                            fixedDecimalScale={true}
                            renderText={(value) => (
                              <div className="price">
                                <p>
                                  &nbsp;=&nbsp;
                                  {value || 0}
                                </p>
                              </div>
                            )}
                          />
                        </>
                      ) : (
                        <>
                          {item.half ? (
                            <>
                              <NumberFormat
                                value={Math.round(data?.price_apps / 2) ?? "0"}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"Rp "}
                                // decimalScale={2}
                                fixedDecimalScale={true}
                                renderText={(value) => (
                                  <div className="price">
                                    {data?.qty_cancel}
                                    {" x "}
                                    {value || 0}
                                  </div>
                                )}
                              />
                              <NumberFormat
                                value={
                                  Math.round(data?.total_price_cancel) ?? "0"
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"Rp "}
                                // decimalScale={2}
                                fixedDecimalScale={true}
                                renderText={(value) => (
                                  <div className="price">
                                    <p>
                                      &nbsp;=&nbsp;
                                      {value || 0}
                                    </p>
                                  </div>
                                )}
                              />
                            </>
                          ) : (
                            <>
                              <NumberFormat
                                value={Math.round(data?.price_apps) ?? "0"}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"Rp "}
                                // decimalScale={2}
                                fixedDecimalScale={true}
                                renderText={(value) => (
                                  <div className="price">
                                    {data?.qty_cancel}
                                    {" x "}
                                    {value || 0}
                                  </div>
                                )}
                              />
                              <NumberFormat
                                value={
                                  Math.round(data?.total_price_cancel) ?? "0"
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"Rp "}
                                // decimalScale={2}
                                fixedDecimalScale={true}
                                renderText={(value) => (
                                  <div className="price">
                                    <p>
                                      &nbsp;=&nbsp;
                                      {value || 0}
                                    </p>
                                  </div>
                                )}
                              />
                            </>
                          )}
                        </>
                      )}
                      {item.half ? (
                        <div className="priceHalf">
                          {" ( "}
                          {item?.qty_konversi}{" "}
                          {item?.small_unit.charAt(0) +
                            item?.small_unit.slice(1).toLowerCase()}
                          {" )"}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <hr className="main2"></hr>
                <div className="footer">
                  <table>
                    <tr>
                      {dataTransaction.status_faktur == "Redeem" ? (
                        <td className="first">Total Poin</td>
                      ) : (
                        <td className="first" style={{width:'65%'}}>Total Harga Setelah Diskon</td>
                      )}
                      <td> : </td>
                      {dataTransaction.status_faktur == "Redeem" ? (
                        <NumberFormat
                          value={Math.round(success?.total_price_update) ?? "0"}
                          displayType={"text"}
                          thousandSeparator={true}
                          // prefix={"Rp "}
                          suffix={" poin"}
                          // decimalScale={2}
                          fixedDecimalScale={true}
                          renderText={(value) => (
                            <td className="two">{value || 0}</td>
                          )}
                        />
                      ) : (
                        <NumberFormat
                          value={Math.round(success?.total_price_update)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"Rp "}
                          // decimalScale={2}
                          fixedDecimalScale={true}
                          renderText={(value) => (
                            <td className="two">{value || 0}</td>
                          )}
                        />
                      )}
                    </tr>
                    {item.notes ? (
                      <tr>
                        <td className="first">Catatan</td>
                        <td> : </td>
                        <td className="two">{item.notes}</td>
                      </tr>
                    ) : null}
                  </table>
                </div>
              </div>
            );
          } else {
            return (
              <div key={index} className="productList">
                <div className="main">
                  {item.product.image ? (
                    <img src={CONFIG.BASE_URL + item.product.image} />
                  ) : (
                    <img src={DummyImage} />
                  )}
                  <div className="content">
                    <div className="name">{item.product.name}</div>
                    <div className="halfView">
                      {dataTransaction.status_faktur == "Redeem" ? (
                        <NumberFormat
                          value={Math.round(item?.price_apps) ?? "0"}
                          displayType={"text"}
                          thousandSeparator={true}
                          // prefix={"Rp "}
                          suffix={" poin"}
                          // decimalScale={2}
                          fixedDecimalScale={true}
                          renderText={(value) => (
                            <div className="price">
                              {item.qty}
                              {" x "}
                              {value || 0}
                            </div>
                          )}
                        />
                      ) : (
                        <>
                          {item.half ? (
                            <NumberFormat
                              value={Math.round(item?.price_apps / 2) ?? "0"}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"Rp "}
                              // decimalScale={2}
                              fixedDecimalScale={true}
                              renderText={(value) => (
                                <div className="price">
                                  {item.qty}
                                  {" x "}
                                  {value || 0}
                                </div>
                              )}
                            />
                          ) : (
                            <NumberFormat
                              value={Math.round(item?.price_apps) ?? "0"}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"Rp "}
                              // decimalScale={2}
                              fixedDecimalScale={true}
                              renderText={(value) => (
                                <div className="price">
                                  {item.qty}
                                  {" x "}
                                  {value || 0}
                                </div>
                              )}
                            />
                          )}
                        </>
                      )}
                      {item.half ? (
                        <div className="priceHalf">
                          {" ( "}
                          {item?.qty_konversi}{" "}
                          {item?.small_unit.charAt(0) +
                            item?.small_unit.slice(1).toLowerCase()}
                          {" )"}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className="footer">
                  <table>
                    <tr>
                      {dataTransaction.status_faktur == "Redeem" ? (
                        <td className="first">Total Poin</td>
                      ) : (
                        <td className="first">Total Harga</td>
                      )}
                      <td> : </td>
                      {dataTransaction.status_faktur == "Redeem" ? (
                        <NumberFormat
                          value={Math.round(item.total_price)}
                          displayType={"text"}
                          thousandSeparator={true}
                          // prefix={"Rp "}
                          suffix={" poin"}
                          // decimalScale={2}
                          fixedDecimalScale={true}
                          renderText={(value) => (
                            <td className="two">{value || 0}</td>
                          )}
                        />
                      ) : (
                        <NumberFormat
                          value={Math.round(item.total_price)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"Rp "}
                          // decimalScale={2}
                          fixedDecimalScale={true}
                          renderText={(value) => (
                            <td className="two">{value || 0}</td>
                          )}
                        />
                      )}
                    </tr>
                    {item.notes ? (
                      <tr>
                        <td className="first">Catatan</td>
                        <td> : </td>
                        <td className="two">{item.notes}</td>
                      </tr>
                    ) : null}
                  </table>
                </div>
              </div>
            );
          }
        })}
      </div>
      <hr></hr>
      <div className="transactionDetailCard">
        Info Pengiriman
        <div className="flex">
          <div className="title">Kurir</div>
          <div className="value">Bebas Ongkir</div>
        </div>
        <hr></hr>
        <div className="flex">
          <div className="title">Alamat</div>
          {dataTransaction.kelurahan ? (
            <div className="value">
              {dataTransaction.address}
              {", "}
              {dataTransaction.kelurahan}
              {", "}
              {dataTransaction.kecamatan}
              {", "}
              {dataTransaction.kota}
              {", "}
              {dataTransaction.provinsi}
              {", "}
              {dataTransaction.kode_pos}
            </div>
          ) : (
            <div className="value">(dataTransaction.address)</div>
          )}
        </div>
        {dataTransaction.status === 3 ? (
          <>
            <hr></hr>
            <div className="flex">
              <div className="btnTracking">
                <button
                  onClick={() => {
                    setOpenModalTracking(true);
                  }}
                  className="textBtnTracking"
                >
                  Tracking Pesanan
                </button>
              </div>
            </div>
          </>
        ) : null}
      </div>
      <hr></hr>
      <div
        className={
          dataTransaction.status_faktur == "Redeem"
            ? "_transactionDetailCardLastRedeem"
            : "_transactionDetailCardLast"
        }
      >
        Rincian Pembayaran
        <div className="flex">
          <div className="title">Mode Pembayaran</div>
          {dataTransaction.payment_method == "cod" ? (
            <div className="value">Bayar langsung di tempat (COD)</div>
          ) : dataTransaction.payment_method == "tempo" ? (
            <div className="value">Tempo</div>
          ) : dataTransaction.payment_method == "redeem" ? (
            <div className="value">Redeem</div>
          ) : null}
        </div>
        {dataTransaction.payment_discount ? (
          <>
            <hr></hr>
            <div className="flex">
              <div className="title">Nominal Diskon</div>
              {dataTransaction.payment_discount_type == "nominal" ? (
                <NumberFormat
                  value={Math.round(dataTransaction.payment_discount) ?? "0"}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"-Rp "}
                  renderText={(value) => (
                    <div className="value">{value || 0}</div>
                  )}
                />
              ) : (
                <NumberFormat
                  value={Math.round(dataTransaction.payment_discount) ?? "0"}
                  displayType={"text"}
                  thousandSeparator={true}
                  suffix={" %"}
                  renderText={(value) => (
                    <div className="value">{value || 0}</div>
                  )}
                />
              )}
            </div>
          </>
        ) : null}
        <hr></hr>
        {dataTransaction.status_faktur == "Redeem" ? (
          <div className="flex">
            <div className="title">Total poin</div>
            <NumberFormat
              value={Math.round(dataTransaction.payment_total) ?? "0"}
              displayType={"text"}
              thousandSeparator={true}
              // prefix={"Rp "}
              suffix={" poin"}
              renderText={(value) => <div className="value">{value || 0}</div>}
            />
          </div>
        ) : (
          <div className="flex">
            <div className="title">Total Harga</div>
            <NumberFormat
              value={Math.round(dataTransaction.payment_total) ?? "0"}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"Rp "}
              renderText={(value) => <div className="value">{value || 0}</div>}
            />
          </div>
        )}
        {dataTransaction.point ? (
          <>
            <hr></hr>
            <div className="flex">
              <div className="title">Promo Bonus Poin</div>
              <NumberFormat
                value={Math.round(dataTransaction.point) ?? "0"}
                displayType={"text"}
                thousandSeparator={true}
                // prefix={"Rp "}
                suffix={" poin"}
                renderText={(value) => (
                  <div className="value">{value || 0}</div>
                )}
              />
            </div>
          </>
        ) : null}
        {dataTransaction.payment_point ? (
          <>
            <hr></hr>
            <div className="flex">
              <div className="title">Poin Telah Digunakan</div>
              <NumberFormat
                value={Math.round(dataTransaction.payment_point) ?? "0"}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"- "}
                suffix={" poin"}
                renderText={(value) => (
                  <div className="value">{value || 0}</div>
                )}
              />
            </div>
          </>
        ) : null}
        <hr></hr>
        <div className="flex">
          <div className="title">Total Ongkos Kirim</div>
          <NumberFormat
            value={Math.round(dataTransaction.delivery_fee) ?? "0"}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"Rp "}
            renderText={(value) => <div className="value">{value || 0}</div>}
          />
        </div>
        <hr></hr>
        {dataTransaction.status_faktur == "Redeem" ? (
          <div className="flex">
            <div className="total">Total Pembelian</div>
            <NumberFormat
              value={Math.round(dataTransaction.payment_final) ?? "0"}
              displayType={"text"}
              thousandSeparator={true}
              // prefix={"Rp "}
              suffix={" poin"}
              renderText={(value) => (
                <div className="totalPrice">{value || 0}</div>
              )}
            />
          </div>
        ) : (
          <div className="flex">
            <div className="total">Total Bayar</div>
            <NumberFormat
              value={Math.round(dataTransaction.payment_final) ?? "0"}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"Rp "}
              renderText={(value) => (
                <div className="totalPrice">{value || 0}</div>
              )}
            />
          </div>
        )}
        {dataTransaction.status == "3" &&
          dataTransaction.status_faktur == "F" && (
            <>
              {buttonDisabled2 ? (
                <button
                  onClick={() => setButtonDisabled2(false)}
                  className="buttonDisabled"
                >
                  <p className="textDisabled">{"Selesai"}</p>
                </button>
              ) : (
                <button
                  onClick={() => {
                    postCompleteOrder();
                    setButtonDisabled2(true);
                  }}
                  className="buttonSelesai"
                >
                  <p className="textSelesai">{"Selesai"}</p>
                </button>
              )}
            </>
          )}
        {/* {dataTransaction.status == "1" && dataTransaction.status_faktur == "F" && (
          <>
            {buttonDisabled ? (
              <button
                onClick={() => setButtonDisabled(false)}
                className="buttonDisabled"
              >
                <p className="textDisabled">{"Batal Transaksi"}</p>
              </button>
            ) : (
              <button
                onClick={() => {
                  setCancelOrder(true);
                  setButtonDisabled(true);
                }}
                className="buttonBatal"
              >
                <p className="textBatal">{"Batal Transaksi"}</p>
              </button>
            )}
          </>
        )} */}
      </div>
      {dataTransaction.status_faktur == "Redeem" ? null : (
        <div className="transactionDetailFooter">
          <button
            onClick={() => {
              setLoadingApi(true);
              getDataTransaction();
            }}
            className="checkStatusButton"
          >
            <p className="checkStatusText">{"Check Status Pesanan"}</p>
          </button>
        </div>
      )}
      <Loading loadingApi={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

export default StokKosong;
