import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import IconBack from "../assets/images/backArrow.webp";
import Product1 from "../assets/dummy/product1.jpg";
import Complaint1 from "../assets/dummy/complaint1.jpg";
import "./ComplaintReply.css";
import { useSelector } from "react-redux";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import { produkAction, screenAction, orderAction } from "../actions";
import moment from "moment";
import NumberFormat from "react-number-format";
import Dialog from "../components/Dialog";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

export const ComplaintReply = (props) => {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const { screenName } = useSelector((state) => state.ScreenReducer);
  const { detailComplaint } = useSelector((state) => state.OrderReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [detail, setDetail] = useState("");
  const [open, setOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [alertTambahan, setAlertTambahan] = useState("");
  const [alertJudul, setAlertJudul] = useState("");
  const [loadingApi, setLoadingApi] = useState(false);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  async function postReplyComplaint() {
    console.log("aaaaa", title);
    console.log("bbbbb", detail);
    console.log("ccccc", detailComplaint.id);
    if (title == "") {
      setAlertData("Harap mengisi judul masalah yang akan dikomplain");
      setAlertJudul("Pemberitahuan");
      setOpen(true);
    } else if (detail == "") {
      setAlertData("Harap mengisi detail masalah yang akan dikomplain");
      setAlertJudul("Pemberitahuan");
      setOpen(true);
    } else {
      try {
        let response = await axios({
          method: "POST",
          url: `${CONFIG.BASE_URL}/api/complaint/reply/${detailComplaint.id}`,
          headers: {
            // 'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          data: {
            title: title,
            content: detail,
          },
        });
        let data = response.data;
        if (data !== "" && data["success"] == true) {
          navigate(-2);
        } else {
          console.log("Gagal input komplain===>", data);
        }
      } catch (error) {
        let error429 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 429";
        let errorNetwork =
          JSON.parse(JSON.stringify(error)).message === "Network Error";
        let error400 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 400";
        console.log(
          "Cek Error========================",
          JSON.parse(JSON.stringify(error)).message
        );
        if (error429) {
          setAlertServerSibuk(true);
        } else if (errorNetwork) {
          setAlertInet(true);
        } else if (error400) {
          localStorage.removeItem("token");
          navigate("/home");
        }
      }
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async (e) => {
    setOpen(false);
  };

  return (
    <div className="complaintReplyContainer">      
      <Dialog
        alertData={alertData}
        alertJudul={alertJudul}
        handleClose={handleClose}
        open={open}
      />
      <div className="headerComponent">
        <div onClick={() => navigate(-1)} className="headerArrowContainer">
          <img
            src={IconBack}
            alt="headerIconArrow"
            className="headerIconArrow"
          />
        </div>
        <div className="headerTitleContainer">
          <p className="headerTitle">Komplain Pesanan</p>
        </div>
      </div>
      <div className="complaintReply">
        <div className="complaintReplyMain">
          <div className="complaintReplyTitle">
            <div className="complaintReplyTitleDate">
              {moment(detailComplaint.order.order_details[0].created_at).format(
                "DD MMM YYYY HH:mm"
              )}
            </div>
          </div>
          <div className="complaintReplyContent">
            <div>
              {detailComplaint?.order?.order_details[0]?.product?.image ? (
                <img
                  src={
                    CONFIG.BASE_URL +
                    detailComplaint?.order?.order_details[0]?.product?.image
                  }
                  alt="complaintReplyContentImage"
                  className="complaintReplyContentImage"
                />
              ) : (
                <img
                  src={DummyImage}
                  alt="complaintReplyContentImage"
                  className="complaintReplyContentImage"
                />
              )}
              {detailComplaint.order.order_details.length > 1 ? (
                <div className="complaintReplyContentPrice">
                  +{detailComplaint.order.order_details.length - 1} produk
                  lainnya
                </div>
              ) : null}
            </div>
            <div className="complaintReplyContent2">
              <div className="complaintReplyContentProduct">
                {detailComplaint.order.order_details[0].product.name}
              </div>
              {detailComplaint.order.order_details[0].half ? (
                <div className="complaintContentPriceHalf">
                  {"( "}
                  {detailComplaint?.order.order_details[0]?.qty_konversi}{" "}
                  {detailComplaint?.order.order_details[0]?.small_unit.charAt(
                    0
                  ) +
                    detailComplaint?.order.order_details[0]?.small_unit
                      .slice(1)
                      .toLowerCase()}
                  {" )"}
                </div>
              ) : null}
              <NumberFormat
                value={Math.round(detailComplaint.order.payment_final) ?? "0"}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"Rp "}
                renderText={(value) => (
                  <div className="complaintReplyContentPrice">
                    Total : {value || 0}
                  </div>
                )}
              />
            </div>
          </div>
        </div>
        {detailComplaint.complaint_detail.map(
          (itemComplaint, indexComplaint) => {
            return (
              <div className="complaintReplyMain">
                <table>
                  <tr>
                    <td>Judul</td>
                    <td> : </td>
                    <td> {itemComplaint.title} </td>
                  </tr>
                  <tr>
                    <td>Penjelasan</td>
                    <td> : </td>
                    <td> {itemComplaint.content} </td>
                  </tr>
                  <tr>
                    <td>Jumlah</td>
                    <td> : </td>
                    <td> {detailComplaint.qty} </td>
                  </tr>
                  <tr>
                    <td>Pilihan pengembalian</td>
                    <td> : </td>
                    <td> {detailComplaint.option} </td>
                  </tr>
                  <tr>
                    {itemComplaint[1]?.title.length === 0 ? (
                      <>
                        <td>Jawaban Komplain</td>
                        <td> : </td>
                        <td> Belum ada jawaban </td>
                      </>
                    ) : null}
                  </tr>
                  <tr>
                    {itemComplaint.file_1 ||
                    itemComplaint.file_2 ||
                    itemComplaint.file_3 ? (
                      <td>
                        <b>Lampiran</b> (Opsional)
                      </td>
                    ) : null}
                    <td> </td>
                    <td> </td>
                  </tr>
                </table>
                {itemComplaint.file_1 ? (
                  <div className="lampiran">
                    <img
                      src={CONFIG.BASE_URL + itemComplaint.file_1}
                      alt="complaintDetailContentImage"
                      className="image"
                    />
                  </div>
                ) : null}
                {itemComplaint.file_2 ? (
                  <div className="lampiran">
                    <img
                      src={CONFIG.BASE_URL + itemComplaint.file_2}
                      alt="complaintDetailContentImage"
                      className="image"
                    />
                  </div>
                ) : null}
                {itemComplaint.file_3 ? (
                  <div className="lampiran">
                    <img
                      src={CONFIG.BASE_URL + itemComplaint.file_3}
                      alt="complaintDetailContentImage"
                      className="image"
                    />
                  </div>
                ) : null}
              </div>
            );
          }
        )}
        <div className="complaintReplyMain">
          <div className="complaintReplyJudulInput">
            <input
              onChange={(e) => setTitle(e.target.value)}
              value={title}
              placeholder="Judul Masalah"
              type="text"
            />
          </div>
          <div className="complaintReplyDetailInput">
            <input
              onChange={(e) => setDetail(e.target.value)}
              value={detail}
              placeholder="Detail Masalah"
              type="text"
            />
          </div>
        </div>
      </div>
      <div className="complaintReplyButton">
        <button
          onClick={postReplyComplaint}
          className="complaintReplyDetailButton"
        >
          <p className="complaintReplyDetailTextButton">{"Kirim"}</p>
        </button>
      </div>
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ComplaintReply);
