import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import IconBack from "../assets/images/backArrow.webp";
import Product1 from "../assets/dummy/product1.jpg";
import IconPlus from "../assets/images/Plus.webp";
import IconMinus from "../assets/images/Minus.webp";
import IconPlus2 from "../assets/images/Plus2.webp";
import "./ComplaintCreate.css";
import { useSelector } from "react-redux";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { produkAction, screenAction, orderAction } from "../actions";
import moment from "moment";
import NumberFormat from "react-number-format";
import Dialog from "../components/Dialog";
import ImageUploading from "react-images-uploading";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Header from "../components/HeaderDistributor"

export const ComplaintCreate = (props) => {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const { screenName } = useSelector((state) => state.ScreenReducer);
  const { orderComplaint } = useSelector((state) => state.OrderReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [complaint, setComplaint] = useState([]);
  const [title, setTitle] = useState("");
  const [detail, setDetail] = useState("");
  const [photo, setPhoto] = useState(null);
  const [photo2, setPhoto2] = useState(null);
  const [photo3, setPhoto3] = useState(null);
  const [qty, setQty] = useState(0);
  const [pilih, setPilih] = useState("");
  const [produk, setProduk] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [alertTambahan, setAlertTambahan] = useState("");
  const [alertJudul, setAlertJudul] = useState("");
  const [loadingApi, setLoadingApi] = useState(false);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  useEffect(() => {
    let arr = orderComplaint.data_item.map((item, index) => {
      item.isSelected = false;
      return { ...item };
    });
    setComplaint(arr);

    return () => {};
  }, []);

  const handleMinus = () => {
    if (qty == 0) {
      setQty(0);
    } else if (qty > 0) {
      setQty(qty - 1);
    }
  };

  const handlePlus = () => {
    if (qty == produk.qty) {
      setQty(produk.qty);
    } else if (qty < produk.qty) {
      setQty(qty + 1);
    } else {
      setAlertData("Gagal input jumlah, harap pilih produk diatas");
      setAlertJudul("Pemberitahuan");
      setOpen(true);
    }
  };

  const changeColour = (index) => {
    console.log("masuk", index);
    let arr = complaint.map((val, i) => {
      if (index == i) {
        val.isSelected = !val.isSelected;
        if (val.isSelected) {
          setProduk(val);
        } else {
          setProduk("");
        }
      } else {
        val.isSelected = false;
      }
      return { ...val };
    });
    setComplaint(arr);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async (e) => {
    setOpen(false);
  };

  const onChangePhoto = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setPhoto(imageList);
  };

  const onChangePhoto2 = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setPhoto2(imageList);
  };

  const onChangePhoto3 = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setPhoto3(imageList);
  };

  const postComplaint = async () => {
    setLoadingApi(true);
    setButtonDisabled(true);
    if (produk == "") {
      setAlertData("Harap memilih produk yang akan dikomplain");
      setAlertJudul("Pemberitahuan");
      setButtonDisabled(false);
      setLoadingApi(false);
      setOpen(true);
    } else if (title == "") {
      setAlertData("Harap mengisi judul masalah yang akan dikomplain");
      setAlertJudul("Pemberitahuan");
      setButtonDisabled(false);
      setLoadingApi(false);
      setOpen(true);
    } else if (detail == "") {
      setAlertData("Harap mengisi detail masalah yang akan dikomplain");
      setAlertJudul("Pemberitahuan");
      setButtonDisabled(false);
      setLoadingApi(false);
      setOpen(true);
    } else {
      const formData = new FormData();
      formData.append("order_id", orderComplaint.id);
      formData.append("brand_id", produk.product_id);
      formData.append("title", title);
      formData.append("content", detail);
      if (qty != 0) {
        formData.append("qty", qty);
      }
      if (produk.half) {
        formData.append("half", produk.half);
      }
      if (pilih != "Pilihan") {
        formData.append("option", pilih);
      }
      if (photo != null) {
        formData.append("file_1", photo[0].file, photo[0].file.type);
      }
      if (photo2 != null) {
        formData.append("file_2", photo2[0].file, photo2[0].file.type);
      }
      if (photo3 != null) {
        formData.append("file_3", photo3[0].file, photo3[0].file.type);
      }
      console.log("TESSSS", formData.get("file_1"));
      try {
        let response = await axios({
          method: "POST",
          url: `${CONFIG.BASE_URL}/api/complaint`,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          data: formData,
        });
        const data = response.data;
        if (data !== "" && data["success"] == true) {
          console.log("DATA SUKSESSS", data);
          setButtonDisabled(false);
          setPhoto(null);
          setPhoto2(null);
          setPhoto3(null);
          setLoadingApi(false);
          navigate(-1);
        } else {
          setAlertData("Gagal menginput komplain");
          setAlertJudul("Pemberitahuan");
          setLoadingApi(false);
          setOpen(true);
          setButtonDisabled(false);
          console.log("Gagal input komplain===>", data);
        }
      } catch (error) {
        setLoadingApi(false);
        setButtonDisabled(false);
        let error429 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 429";
        let errorNetwork =
          JSON.parse(JSON.stringify(error)).message === "Network Error";
        let error400 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 400";
        console.log(
          "Cek Error========================",
          JSON.parse(JSON.stringify(error)).message
        );
        if (error429) {
          setAlertServerSibuk(true);
        } else if (errorNetwork) {
          setAlertInet(true);
        } else if (error400) {
          localStorage.removeItem("token");
          navigate("/home");
        }
      }
    }
  };

  return (
    <div className="complaintCreateContainer1">
      <Dialog
        alertData={alertData}
        alertJudul={alertJudul}
        handleClose={handleClose}
        open={open}
      />
      <Header 
      titleHeader="Komplaint Pesanan"
      />
      <div className="complaintDetail">
        <p
          style={{
            marginTop: "10px",
            marginLeft: "10px",
            fontFamily: "Lato-Bold, sans-serif",
          }}
        >
          Ceritakan Komplain mu disini
        </p>
        <p
          style={{
            marginTop: "10px",
            marginLeft: "10px",
            fontFamily: "Lato-Bold, sans-serif",
          }}
        >
          Pilih produk yang akan dikomplain
        </p>
        {complaint?.map((item, index) => {
          return (
            <>
              {item.isSelected == true ? (
                <div
                  onClick={() => changeColour(index)}
                  key={index}
                  className="complaintCreateMainSelected"
                >
                  <div className="complaintCreateContent">
                    {item.product?.image ? (
                      <img
                        src={CONFIG.BASE_URL + item.product?.image}
                        alt="complaintCreateContentImage"
                        className="complaintCreateContentImage"
                      />
                    ) : (
                      <img
                        src={DummyImage}
                        alt="complaintCreateContentImage"
                        className="complaintCreateContentImage"
                      />
                    )}

                    <div className="complaintCreateContent2">
                      <div className="complaintCreateContentProduct">
                        {item.product.name}
                      </div>
                      {item.half ? (
                        <div className="isiHalf">
                          {"( "}
                          {item?.qty_konversi}{" "}
                          {item?.small_unit.charAt(0) +
                            item.small_unit.slice(1).toLowerCase()}
                          {" )"}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  onClick={() => changeColour(index)}
                  key={index}
                  className="complaintCreateMain"
                >
                  <div className="complaintCreateContent">
                    {item.product?.image ? (
                      <img
                        src={CONFIG.BASE_URL + item.product?.image}
                        alt="complaintCreateContentImage"
                        className="complaintCreateContentImage"
                      />
                    ) : (
                      <img
                        src={DummyImage}
                        alt="complaintCreateContentImage"
                        className="complaintCreateContentImage"
                      />
                    )}

                    <div className="complaintCreateContent2">
                      <div className="complaintCreateContentProduct">
                        {item.product.name}
                      </div>
                      {item.half ? (
                        <div className="isiHalf">
                          {"( "}
                          {item?.qty_konversi}{" "}
                          {item?.small_unit.charAt(0) +
                            item.small_unit.slice(1).toLowerCase()}
                          {" )"}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              )}
            </>
          );
        })}
        <div className="complaintCreateMain">
          <div className="complaintCreateJudulInput">
            <input
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Judul Masalah"
              type="text"
            />
          </div>
          <div className="complaintCreateDetailInput">
            <input
              onChange={(e) => setDetail(e.target.value)}
              placeholder="Detail Masalah"
              type="text"
            />
          </div>
          <div className="complaintCreateFooterInput">
            <div className="complaintCreateContentProduct">
              Jumlah (Opsional)
            </div>
            <div className="complaintCreateFooterInput">
              <div className="complaintCreateItemTrashContainer">
                <button
                  onClick={handleMinus}
                  className="complaintCreateItemQtyMinus"
                >
                  <img
                    src={IconMinus}
                    alt="complaintCreateItemQtyMinusImage"
                    className="complaintCreateItemQtyMinusImage"
                  />
                </button>
                <input
                  className="complaintCreateItemQtyInput"
                  type="text"
                  value={qty}
                />
                <button
                  onClick={handlePlus}
                  className="complaintCreateItemQtyPlus"
                >
                  <img
                    src={IconPlus}
                    alt="complaintCreateItemQtyPlusImage"
                    className="complaintCreateItemQtyPlusImage"
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="complaintCreateFooterDropdown">
            <div className="complaintCreateContentProduct">
              Pilihan Pengembalian (Opsional)
            </div>
            <div className="complaintCreateFooterInput">
              <div className="complaintCreateItemDropdownContainer">
                <select
                  onChange={(e) => setPilih(e.target.value)}
                  className="complaintCreateItemSelect"
                  placeholder="tes"
                >
                  <option value={"pilihan"}>Pilihan</option>
                  <option value={"pengembalian dana"}>Pengembalian Dana</option>
                  <option value={"tukar barang"}>Tukar Barang</option>
                </select>
              </div>
            </div>
          </div>
          <div className="complaintCreateFooterLampiran">
            <div className="complaintCreateContentProduct">
              Lampiran (Opsional)
            </div>
            {photo ? (
              <div className="complaintCreateFooterUploadHasil">
                <div className="complaintCreateFooterUploadLampiranHasil">
                  <div className="complaintCreateItemUploadContainerHasil">
                    <button className="uploadHasil">
                      <img
                        src={photo[0].data_url}
                        alt="complaintCreateItemQtyMinusImage"
                        className="complaintCreateItemQtyHasilImage"
                      />
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
            {photo2 ? (
              <div className="complaintCreateFooterUploadHasil">
                <div className="complaintCreateFooterUploadLampiranHasil">
                  <div className="complaintCreateItemUploadContainerHasil">
                    <button className="uploadHasil">
                      <img
                        src={photo2[0].data_url}
                        alt="complaintCreateItemQtyMinusImage"
                        className="complaintCreateItemQtyHasilImage"
                      />
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
            {photo3 ? (
              <div className="complaintCreateFooterUploadHasil">
                <div className="complaintCreateFooterUploadLampiranHasil">
                  <div className="complaintCreateItemUploadContainerHasil">
                    <button className="uploadHasil">
                      <img
                        src={photo3[0].data_url}
                        alt="complaintCreateItemQtyMinusImage"
                        className="complaintCreateItemQtyHasilImage"
                      />
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="complaintCreateFooterUpload">
              {photo ? null : (
                <ImageUploading
                  // multiple
                  value={photo}
                  onChange={onChangePhoto}
                  // maxNumber={maxNumber}
                  dataURLKey="data_url"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="complaintCreateFooterUploadLampiran">
                      <div className="complaintCreateItemUploadContainer">
                        <button
                          onClick={onImageUpload}
                          className="upload"
                          {...dragProps}
                        >
                          <img
                            src={IconPlus2}
                            alt="complaintCreateItemQtyMinusImage"
                            className="complaintCreateItemQtyMinusImage"
                          />
                          {/* {imageList.map((image, index) => {
                          console.log("cekkk", photo);
                          if (photo) {
                            return (
                              <div key={index} className="image-item">
                                <img
                                  src={image["data_url"]}
                                  alt=""
                                  width="100"
                                />
                                <div className="image-item__btn-wrapper">
                                  <button onClick={() => onImageUpdate(index)}>
                                    Update
                                  </button>
                                  <button onClick={() => onImageRemove(index)}>
                                    Remove
                                  </button>
                                </div>
                              </div>
                            );
                          }
                        })} */}
                        </button>
                      </div>
                    </div>
                  )}
                </ImageUploading>
              )}
              {photo2 ? null : (
                <ImageUploading
                  // multiple
                  value={photo2}
                  onChange={onChangePhoto2}
                  // maxNumber={maxNumber}
                  dataURLKey="data_url"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="complaintCreateFooterUploadLampiran">
                      <div className="complaintCreateItemUploadContainer">
                        <button
                          onClick={onImageUpload}
                          className="upload"
                          {...dragProps}
                        >
                          <img
                            src={IconPlus2}
                            alt="complaintCreateItemQtyMinusImage"
                            className="complaintCreateItemQtyMinusImage"
                          />
                        </button>
                      </div>
                    </div>
                  )}
                </ImageUploading>
              )}
              {photo3 ? null : (
                <ImageUploading
                  // multiple
                  value={photo3}
                  onChange={onChangePhoto3}
                  // maxNumber={maxNumber}
                  dataURLKey="data_url"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="complaintCreateFooterUploadLampiran">
                      <div className="complaintCreateItemUploadContainer">
                        <button
                          onClick={onImageUpload}
                          className="upload"
                          {...dragProps}
                        >
                          <img
                            src={IconPlus2}
                            alt="complaintCreateItemQtyMinusImage"
                            className="complaintCreateItemQtyMinusImage"
                          />
                        </button>
                      </div>
                    </div>
                  )}
                </ImageUploading>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="complaintCreateButton">
        {buttonDisabled ? (
          <button className="complaintCreateDetailButtonDisabled">
            <p className="complaintCreateDetailTextButton">{"Kirim"}</p>
          </button>
        ) : (
          <button
            onClick={postComplaint}
            className="complaintCreateDetailButton"
          >
            <p className="complaintCreateDetailTextButton">{"Kirim"}</p>
          </button>
        )}
      </div>
      <Loading loadingApi={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ComplaintCreate);
