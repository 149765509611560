import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, Navigate } from "react-router-dom";
import Logo from "../assets/images/IconLogo.webp";
import IconTransaksi from "../assets/images/Transaksi.webp";
import IconRating from "../assets/images/Ulasan.webp";
import IconWishlist from "../assets/images/Whishlist.webp";
import IconSubscribe from "../assets/images/Subscribe.webp";
import IconHerbal from "../assets/images/Herbal1.webp";
import IconSupmul from "../assets/images/Supplemen dan Multivitamin1.webp";
import IconMakmin from "../assets/images/Makanan dan Minuman1.webp";
import IconMinyak from "../assets/images/Minyak Angin dan Balsem1.webp";
import IconKomplain from "../assets/images/Komplain.webp";
import IconQuestion from "../assets/images/Question.webp";
import IconQr from "../assets/images/Qr-code.webp";
import IconLogout from "../assets/images/logout.webp";
import IconCredit from "../assets/images/Credit-card.webp";
import IconCoin from "../assets/images/Coin.webp";
import IconSetting from "../assets/images/Settings.webp";
import IconBack from "../assets/images/backArrow.webp";
import IconRedeem from "../assets/images/Redeem.webp";
import "./Menu.css";
import { screenAction, paramsAction, produkAction } from "../actions";
import { useDispatch } from "react-redux";
import NumberFormat from "react-number-format";
import Dialog from "../components/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import CONFIG from "../config/config";
import { Avatar } from "@mui/material";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import NavbarDistributor from "../components/NavbarDistributor";
import IconNotifAll from "../assets/images/NotifAll.webp";
import IconMenu from "../assets/images/Menu.webp";
import IconUlasan from "../assets/images/Ulasan.webp";
import IconSetelan from "../assets/images/SetelanDis.webp";
import IconLogo from "../assets/images/IconLogo.webp";
import Header from "../components/HeaderDistributor";
import Loading from "../components/Loading";

// function Loading() {
//   return (
//     <div className="loading">
//       <CircularProgress color="success" />
//     </div>
//   );
// }

export const MenuDistributor = (props, route) => {
  // console.log("cek router", route);
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [alertTambahan, setAlertTambahan] = useState("");
  const [alertJudul, setAlertJudul] = useState("");
  const [loadingApi, setLoadingApi] = useState(false);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);
  const [notifOrders, setNotifOrders] = useState(0);
  const [notifMessages, setNotifMessages] = useState(0);
  const [notifComplaints, setNotifComplaints] = useState(0);
  const [notifBroadcast, setNotifBroadcast] = useState(0);
  const [notifSubscribe, setNotifSubscribe] = useState(0);
  const [countNotifCart, setCountNotifCart] = useState(0);
  const [search, setSearch] = useState("");
  const [
    onEndReachedCalledDuringMomentum,
    setOnEndReachedCalledDuringMomentum,
  ] = useState(false);
  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  useEffect(() => {
    getNotifAll();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
    setAlertData("Apakah yakin ingin keluar?");
    setAlertJudul("Pemberitahuan");
  };

  async function getNotifAll() {
    console.log("cek hit notif");
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/notification-all`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      // console.log('DATA TEST', data.total_chat);
      setNotifOrders(data.total_order);
      setNotifMessages(data.total_chat);
      setNotifComplaints(data.total_complaint);
      setNotifSubscribe(data.total_subscribe);
      setNotifBroadcast(data.total_broadcast);
      setCountNotifCart(data.total_cart);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========NOTIF================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  const handleClose = async (e) => {
    setOpen(false);
    if (e == "ok") {
      await axios
        .post(
          `${CONFIG.BASE_URL}/api/auth/logout`,
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          let data = response.data.success;
          if (data == true) {
            localStorage.removeItem("token");
            setLoadingApi(false);
            navigate("/login");
          } else {
          }
        })
        .catch((error) => {
          setLoadingApi(false);
          let error429 =
            JSON.parse(JSON.stringify(error)).message ===
            "Request failed with status code 429";
          let errorNetwork =
            JSON.parse(JSON.stringify(error)).message === "Network Error";
          let error400 =
            JSON.parse(JSON.stringify(error)).message ===
            "Request failed with status code 400";
          console.log(
            "Cek Error========================",
            JSON.parse(JSON.stringify(error)).message
          );
          if (error429) {
            setAlertServerSibuk(true);
          } else if (errorNetwork) {
            setAlertInet(true);
          } else if (error400) {
            localStorage.removeItem("token");
            navigate("/home");
          }
        });
    }
  };

  function getName() {
    const name = dataUser?.user.name
      ?.match(/(\b\S)?/g)
      .join("")
      .toUpperCase();
    if (name == null || name == "" || name == undefined) {
      return (name = "NA");
    }
    return name;
  }

  if (!localStorage.getItem("token")) {
    return <Navigate to={"/login"} />;
  }

  const simpanSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div className="menuContainer">
      <Dialog
        alertData={alertData}
        alertJudul={alertJudul}
        handleClose={handleClose}
        open={open}
      />
        <Header
          notifMessages={notifMessages}
          notifSubscribe={notifSubscribe}
          notifOrders={notifOrders}
          notifComplaints={notifComplaints}
          notifBroadcast={notifBroadcast}
          countNotifCart={countNotifCart}
          simpanSearch={simpanSearch}
          titleHeader="Menu Distributor"
          badge={true}
          linkProfile={true}
          item={false}
        />
      <div className="menuHeaderDistributor">
        <div className="containerHeaderImage">
          <div className="listHeader">
            {dataUser?.user.photo ? (
              <img
                src={CONFIG.BASE_URL + dataUser?.user.photo}
                alt="menuLogo"
                className="menuLogoDistributor"
              />
            ) : (
              <Avatar sx={{ bgcolor: "#529F45" }}>{getName()}</Avatar>
            )}
          </div>
        </div>
        <div className="containerHeader">
          <div className="listHeader">
            <p className="userName">
              {dataUser?.user?.name
                ? dataUser?.user?.name
                : dataUser?.user?.shop_name}
            </p>
          </div>
          {/* <div onClick={() => navigate("/kredit")} className="listHeader">
            <img src={IconCredit} alt="menuIcon" className="menuIcon" />
            <NumberFormat
              value={
                dataUser &&
                dataUser?.user &&
                dataUser?.user?.credits &&
                dataUser?.user?.credits[0] &&
                dataUser?.user?.credits[0]?.credit
              }
              displayType={"text"}
              thousandSeparator={true}
              prefix={"Rp "}
              renderText={(value) => <p className="kredit">{value || 0}</p>}
            />
          </div>
          <div onClick={() => navigate("/point")} className="listHeader">
            <img src={IconCoin} alt="menuIcon" className="menuIcon" />
            <p className="point">{dataUser?.user?.point ?? 0}</p>
          </div> */}
          {/* <div onClick={() => navigate("/reward/calendar")} className="listHeader">
            <img src={IconRedeem} alt="menuIcon" className="menuIcon" />
            <p className="kredit">{'Redeem Poin'}</p>
          </div> */}
        </div>
        {/* <div onClick={() => navigate("/user")} className="containerHeader">
          <div className="listHeader">
            <img src={IconSetting} alt="menuIcon" className="menuIcon" />
          </div>
        </div> */}
      </div>
      <div className="menuContentDistributor">
        <hr></hr>
        <div className="menuSaldoDistributor">
          <p className="menuTextDistributor">{"Saldo"}</p>
          <NumberFormat
            value={Math.round("0") ?? "0"}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"Rp "}
            renderText={(value) => (
              <p className="menuTextDistributor">{value || 0}</p>
            )}
          />
        </div>
        <hr></hr>
        <p className="subMenuTitleDistributor">Penjualan</p>
        <a
          onClick={() => navigate("/transaksi/distributor")}
          className="subMenu"
        >
          <img src={IconTransaksi} alt="menuIcon" className="menuIcon" />
          <p className="menuText">Semua Transaksi</p>
        </a>
        <a
          onClick={() => navigate("/menu/voucher/distributor")}
          className="subMenu"
        >
          <img src={IconRedeem} alt="menuIcon" className="menuIcon" />
          <p className="menuText">Promosi Saya</p>
        </a>
        <p className="subMenuTitleDistributor">Produk</p>
        <div className="menuProdukDistributor">
          <div
            onClick={() => navigate("/produk/list/distributor")}
            className="menuButtonProdukDistributor"
          >
            <p className="menuTextbuttonDistributor">{"Produk Saya"}</p>
          </div>
          <div
            onClick={() => navigate("/produk/add")}
            className="menuButtonProdukDistributor"
          >
            <p className="menuTextbuttonDistributor">{"Tambah Produkmu"}</p>
          </div>
        </div>
        <hr></hr>
        <p className="subMenuTitleDistributor">Kata Pembeli</p>
        <a
          onClick={() => navigate("/menu/distributor/ulasan")}
          className="subMenu"
        >
          <img src={IconUlasan} alt="menuIcon" className="menuIcon" />
          <p className="menuText">Ulasan</p>
        </a>
        <a onClick={() => navigate("/menu/distributor/complaint")} className="subMenu">
          <img src={IconKomplain} alt="menuIcon" className="menuIcon" />
          <p className="menuText">Pesanan Dikomplain</p>
        </a>
        <hr></hr>
        <p className="subMenuTitleDistributor">Bantuan dan info lainnya</p>
        <a onClick={() => navigate("/faq")} className="subMenu">
          <img src={IconLogo} alt="menuIcon" className="menuIcon" />
          <p className="menuText">Semutgajah Care</p>
        </a>
        <a onClick={() => navigate("/user")} className="subMenu">
          <img src={IconSetelan} alt="menuIcon" className="menuIcon" />
          <p className="menuText">Pengaturan Toko</p>
        </a>
        <hr></hr>
        <a onClick={handleClickOpen} className="subMenu">
          <img src={IconLogout} alt="menuIcon" className="menuIcon" />
          <p className="menuText">Keluar</p>
        </a>
        <div
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
        >
          <p className="menuTextVersi">
            {"Versi "}
            {dataUser.user.app_version}
          </p>
        </div>
      </div>
      <NavbarDistributor screenName={"Profile"} />
      <Loading loadingApi={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MenuDistributor);
