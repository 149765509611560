import React from "react";
import { CircularProgress } from "@mui/material";
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

export default function ResponsiveDialog({
  loadingApi,
}) {

  return (
    <div>
      <Modal
        open={loadingApi}
        // onClose={handleClose}
        // aria-labelledby="modal-modal-title"
        // aria-describedby="modal-modal-description"
      >
        <CircularProgress sx={style} color="success" />
      </Modal>
    </div>
  );
}
