const config = {
  apiKey: "AIzaSyCxlcTS_NNGpTBrUQzh-TaHEQjf0QsQWMg",
  authDomain: "mpm-antangin.firebaseapp.com",
  projectId: "mpm-antangin",
  storageBucket: "mpm-antangin.appspot.com",
  messagingSenderId: "798332274625",
  appId: "1:798332274625:web:36404303cf1803f8b85140",
  measurementId: "G-M5TC0SDH5Q",
};

export function getFirebaseConfig() {
  if (!config || !config.apiKey) {
    throw new Error(
      "No Firebase configuration object provided." +
        "\n" +
        "Add your web app's configuration object to firebase-config.js"
    );
  } else {
    return config;
  }
}
