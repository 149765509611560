import { SET_USER } from "../../actions";
const initialState = {
  dataUser: [],
  isLogin: false,
  province: "Pilih Provinsi",
  city: "Kabupaten/Kota",
  district: "Kecamatan",
  subdistrict: "Kelurahan",
  postcode: "Kode Pos",
  other_province: "Pilih Provinsi",
  other_city: "Kota",
  other_district: "Kecamatan",
  other_subdistrict: "Kelurahan",
  other_postcode: "Kode Pos",
  codeUser: "",
  nama: "",
  phone: "",
  token: "",
  province2: "Pilih Provinsi",
  city2: "Kota",
  district2: "Kecamatan",
  subdistrict2: "Kelurahan",
  postcode2: "Kode Pos",
  other_province2: "Pilih Provinsi",
  other_city2: "Kota",
  other_district2: "Kecamatan",
  other_subdistrict2: "Kelurahan",
  other_postcode2: "Kode Pos",
  chat: "",
  alamat: {},
  credit: "",
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        [action.inputType]: action.inputValue,
      };
    default:
      return state;
  }
};

export default user;
