import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import IconBack from "../assets/images/backArrow.webp";
import IconTrash from "../assets/images/Trash.webp";
import Product1 from "../assets/dummy/product1.jpg";
import Product2 from "../assets/dummy/product2.jpg";
import Product3 from "../assets/dummy/product3.jpg";
import "./Subscribe.css";
import { useSelector } from "react-redux";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import CircularProgress from "@mui/material/CircularProgress";
import { screenAction, paramsAction, produkAction } from "../actions";
import { useDispatch } from "react-redux";
import error404 from "../assets/images/404.webp";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Header from "../components/HeaderDistributor";

export const Subscribe = (props) => {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const { produkDesc } = useSelector((state) => state.ProdukReducer);
  const { screenName } = useSelector((state) => state.ScreenReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [listTimeWeekSubscribe, setListTimeWeekSubscribe] = useState([]);
  const [listTimeMonthSubscribe, setListTimeMonthSubscribe] = useState([]);
  const [listDataWeekSubscribe, setListDataWeekSubscribe] = useState([]);
  const [listDataMonthSubscribe, setListDataMonthSubscribe] = useState([]);
  const [loadingApi, setLoadingApi] = useState(true);
  const [loadingApi2, setLoadingApi2] = useState(true);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  function NoData() {
    return (
      <div className="NoData">
        <img style={{ height: "50%", width: "100%" }} src={error404} />
        <p
          style={{
            fontSize: "14pt",
            fontFamily: "Lato-Bold",
            textAlign: "center",
            marginTop: "-20%",
          }}
        >
          {"Wah, daftar langganan mu kosong"}
        </p>
        <p
          style={{
            fontSize: "12pt",
            fontFamily: "Lato-Bold",
            width: "65%",
            textAlign: "center",
            marginTop: "2px",
          }}
        >
          {"Yuk, isi daftar langgananmu"}
        </p>
        <button
          onClick={() => {
            dispatch(screenAction("produk", "screenName"));
            navigate("/produk");
          }}
          style={{
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#529F45",
            width: "65%",
            height: "8%",
            borderRadius: "5px",
            marginTop: "5px",
            color: "#FFFFFF",
            fontSize: "10pt",
            fontFamily: "Lato-Bold",
          }}
        >
          {"Mencari Produk Langganan"}
        </button>
      </div>
    );
  }

  useEffect(() => {
    getTimeWeekSubscribe();
    getTimeMonthSubscribe();

    return () => {};
  }, []);

  //list time perweek subscribe
  async function getTimeWeekSubscribe() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/subscribes?type=2_week`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      // let dataTotal = response.data.data;
      // let data = response.data.data.data;
      setLoadingApi(false);
      setListTimeWeekSubscribe(response.data.data);
      setListDataWeekSubscribe(response.data.data.data);
    } catch (error) {
      setLoadingApi(false);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  async function getTimeMonthSubscribe() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/subscribes?type=month`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      // let dataTotal = response.data.data;
      // let data = response.data.data.data;
      setLoadingApi2(false);
      setListTimeMonthSubscribe(response.data.data);
      setListDataMonthSubscribe(response.data.data.data);
    } catch (error) {
      setLoadingApi2(false);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  return (
    <div className="subscribContainer">
      <Header 
      titleHeader="List daftar langganan"
      />
      <div className="subscribe">
        {listTimeWeekSubscribe.total != 0 ? (
          <div className="subscribeMain">
            <div className="subscribeContent">
              <div className="subscribeContentHeader">
                <div className="subscribeContentStatus">
                  <div className="subscribeTime">Per minggu</div>
                  <div className="subscribeProductQty">
                    Jumlah Produk : {listTimeWeekSubscribe.total}
                  </div>
                </div>
                <div className="subscribeContentHeaderDetail">
                  <button
                    onClick={() => {
                      dispatch(
                        produkAction(listDataWeekSubscribe, "produkSubscribe")
                      );
                      dispatch(screenAction("weekSubs", "screenName"));
                      navigate("/subscribe/list?list=weekSubs");
                    }}
                    className="subscribeButtonDetail"
                  >
                    <p className="subscribeTextDetail">{"Detail"}</p>
                  </button>
                </div>
              </div>
              <div className="subscribeContentMain">
                {listDataWeekSubscribe.map((item, index) => {
                  if (item.product.image) {
                    return (
                      <img
                        key={index}
                        src={CONFIG.BASE_URL + item.product.image}
                        alt="subscribeContentImage"
                        className="subscribeContentImage"
                      />
                    );
                  } else {
                    return (
                      <img
                        key={index}
                        src={DummyImage}
                        alt="subscribeContentImage"
                        className="subscribeContentImage"
                      />
                    );
                  }
                })}
              </div>
            </div>
          </div>
        ) : null}
        {listTimeMonthSubscribe.total != 0 ? (
          <div className="subscribeMain">
            <div className="subscribeContent">
              <div className="subscribeContentHeader">
                <div className="subscribeContentStatus">
                  <div className="subscribeTime">Per bulan</div>
                  <div className="subscribeProductQty">
                    Jumlah Produk : {listTimeMonthSubscribe.total}
                  </div>
                </div>
                <div className="subscribeContentHeaderDetail">
                  <button
                    onClick={() => {
                      dispatch(
                        produkAction(listDataMonthSubscribe, "produkSubscribe")
                      );
                      dispatch(screenAction("monthSubs", "screenName"));
                      navigate("/subscribe/list?list=monthSubs");
                    }}
                    className="subscribeButtonDetail"
                  >
                    <p className="subscribeTextDetail">{"Detail"}</p>
                  </button>
                </div>
              </div>
              <div className="subscribeContentMain">
                {listDataMonthSubscribe.map((item, index) => {
                  if (item.product.image) {
                    return (
                      <img
                        key={index}
                        src={CONFIG.BASE_URL + item.product.image}
                        alt="subscribeContentImage"
                        className="subscribeContentImage"
                      />
                    );
                  } else {
                    return (
                      <img
                        key={index}
                        src={DummyImage}
                        alt="subscribeContentImage"
                        className="subscribeContentImage"
                      />
                    );
                  }
                })}
              </div>
            </div>
          </div>
        ) : null}
        {listTimeWeekSubscribe.total == 0 &&
        listTimeMonthSubscribe.total == 0 &&
        !loadingApi ? (
          <NoData />
        ) : null}
      </div>
      <Loading loadingApi={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Subscribe);
