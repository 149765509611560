import { SET_HELP } from "../../actions";
const initialState = {
  helpTopik: [],
  helpDetail: [],
};

const helpCare = (state = initialState, action) => {
  switch (action.type) {
    case SET_HELP:
      return {
        ...state,
        [action.inputType]: action.inputValue,
      };
    default:
      return state;
  }
};

export default helpCare;
