import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import QR from "../assets/images/Qr-code.webp";
import IconBack from "../assets/images/backArrow.webp";
import "./UserEdit.css";
import QRCode from "qrcode.react";
import axios from "axios";
import CONFIG from "../config/config";
import { CircularProgress } from "@mui/material";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Header from "../components/HeaderDistributor"
import HeaderDistributor from "../components/HeaderDistributor";

export const QrCode = (props) => {
  const navigate = useNavigate();
  const [qrvalue, setQrvalue] = useState("");
  const [loadingApi, setLoadingApi] = useState(true);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  useEffect(() => {
    getQR();

    return () => {};
  }, []);

  async function getQR() {
    try {
      let response = await axios.get(`${CONFIG.BASE_URL}/api/qrcode`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      console.log(response.data);
      const data = response.data;
      if (data !== "" && data["success"] == true) {
        setQrvalue(data.data);
        console.log("DATA QR", data.data);
        setLoadingApi(false);
      } else {
        console.log("", typeof data);
        setLoadingApi(false);
        return false;
      }
    } catch (error) {
      setLoadingApi(false);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error===========getVersion=============",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  return (
    <div className="userEditContainer">
      {/* <div className="userButtonBack">
        <img onClick={() => navigate(-1)} src={IconBack} alt="userLogo" className="userBack" />
        <div className="userBackText">QR Code</div>
      </div> */}
      <Header
        titleHeader="QR Code"
      />
      <div className="QrContent">
        <div className="containerUserHeader">
          <QRCode size={300} fgColor="#529F45" value={qrvalue} />
          {/* <img src={QR} alt="userLogo" className="userLogo" /> */}
        </div>
      </div>
      <Loading loadingApi={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(QrCode);
