import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/images/IconLogo.webp";
import IconUser from "../assets/images/User.webp";
import IconSandi from "../assets/images/Sandi.webp";
import "./Login.css";
import axios from "axios";
import CONFIG from "../config/config";
import { getDataToken } from "../config/firebase";
import { useDispatch } from "react-redux";
import { userAction } from "../actions";
import CircularProgress from "@mui/material/CircularProgress";
import FormDialog from "../components/Dialog";
import LoadingComponent from "../components/Loading";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import SelectSearch, { fuzzySearch } from "react-select-search";
import IconEye from "../assets/images/Eye.webp";

function Loading() {
  return (
    <div className="loading">
      <CircularProgress color="success" />
    </div>
  );
}

export const Login = (props) => {
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingApi, setLoadingApi] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [alertTambahan, setAlertTambahan] = useState("");
  const [alertJudul, setAlertJudul] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);
  const [modalWhatsapp, setModalWhatsapp] = useState(false);
  const [numberWAPusat, setNumberWAPusat] = useState("");
  const [numberWASub, setNumberWASub] = useState("");
  const [hasilPilihWA, setHasilPilihWA] = useState("");
  const [numberSubArray, setNumberSubArray] = useState(false);
  const [hidePass, setHidePass] = useState(false);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  useEffect(() => {
    getToken();
    getFcmToken();
    getWhatsappNumber();
    // localStorage.removeItem('intro')

    return () => {};
  }, []);

  async function getFcmToken() {
    const tokenData = await localStorage.getItem("token");
    try {
      console.log("sini");
      await axios({
        method: "get",
        url: `${CONFIG.BASE_URL}/api/profile`,
        headers: { Authorization: `Bearer ${tokenData}` },
      }).catch((error) => {
        let error429 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 429";
        let errorNetwork =
          JSON.parse(JSON.stringify(error)).message === "Network Error";
        let error400 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 400";
        console.log(
          "Cek Error========================",
          JSON.parse(JSON.stringify(error)).message
        );
        if (error429) {
          setAlertServerSibuk(true);
        } else if (errorNetwork) {
          setAlertInet(true);
        } else if (error400) {
          localStorage.removeItem("token");
          // navigate('/home')
        }
      });
      console.log("token", tokenData);
      console.log("====================================");

      if (tokenData === null) {
        setLoading(false);
      } else {
        setLoading(false);
        navigate("/home");
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        // navigate('/home')
      }
    }
  }

  async function getToken() {
    const data = await getDataToken();
    if (data) {
      console.log("token adalah : ", data);
      setToken(data);
    } else {
      alert(
        "Harap izinkan notifikasi lonceng pada icon gembok yang berada di dalam kolom url, dan setelah itu reload"
      );
    }
    return data;
  }

  async function handlerLogin() {
    setLoadingApi(true);
    console.log("email", phone);
    console.log("password", password);
    console.log("token", token);
    try {
      let response = await axios({
        method: "POST",
        url: `${CONFIG.BASE_URL}/api/auth/login`,
        data: {
          email_phone: phone,
          password: password,
          fcm_token: token,
          app_version: "1.1.9",
        },
      });
      console.log(response.data);
      const data = response.data;
      if (data["success"] == true && data.data != "") {
        setLoadingApi(false);
        localStorage.setItem("dataUser", JSON.stringify(data.data));
        localStorage.setItem("token", data.data.token);
        if (data.data.user.account_type == 5) {
          navigate("/dashboardhome");
        } else {
          navigate("/home");
        }        
      } else {
        setLoadingApi(false); 
        const message = data.message;
        // console.log('message === yuhu', message);
        // this.props.loginAct('isLogin', false);
        const user = "User not found";
        const kosong = "Fill the blank";
        const userReg = "User not have site code";
        const password = "Password not match";
        if (message === user) {
          setAlertData("Nomor telepon/email tidak ditemukan");
          setAlertJudul("Pemberitahuan");
          setOpen(true);
        } else if (message === password) {
          setAlertData("Password yang dimasukkan salah");
          setAlertJudul("Pemberitahuan");
          setOpen(true);
        } else if (message === kosong) {
          setAlertData("Pastikan kolom tidak boleh ada yang kosong");
          setAlertJudul("Pemberitahuan");
          setOpen(true);
        } else if (message === userReg) {
          setAlertData("Mohon menunggu untuk verifikasi data di sistem kami");
          setAlertJudul("Pemberitahuan");
          setOpen(true);
        }
      }
    } catch (error) {
      setLoadingApi(false);
      console.error("handleLogin", error);
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async (e) => {
    setOpen(false);
  };

  const getWhatsappNumber = async () => {
    try {
      let response = await axios.get(`${CONFIG.BASE_URL}/api/wa/login/number`);
      // console.log('response===>', JSON.stringify(response, null, 2));
      const data = response.data;
      console.log("data", data);
      if (data.data !== "" && data["success"] == true) {
        if (data.data.length > 2) {
          console.log("masuk 1", data.data.length);
          setNumberWAPusat(data.data[0]);
          let arr = "";
          arr = data.data.shift();
          console.log(arr);
          const dataa = await arr.map((item) => {
            return {
              name: item.provinsi.toUpperCase(),
              value: item.telp_wa,
              id: item.id,
            };
          });
          setNumberWASub(dataa);
          setNumberSubArray(true);
        } else {
          console.log("masuk 2", data.data.length);
          setNumberWAPusat(data.data[0]);
          // setNumberWASub(data.data[1]);
          const dataa = await data.data.map((item) => {
            return {
              name: item.provinsi.toUpperCase(),
              value: item.telp_wa,
              id: item.id,
            };
          });
          setNumberWASub(dataa);
          setNumberSubArray(true);
        }
      } else {
        setAlertData("Gagal ambil data whatsapp");
        setAlertJudul("Pemberitahuan");
        setOpen(true);
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        // navigate('/home')
      }
    }
  };

  const handleChatWhatsapp = (chat) => {
    console.log(chat);
    setModalWhatsapp(false);
    if (chat == "pusat") {
      console.log("masuk wa pusat");
      let url = "https://wa.me/62" + numberWAPusat.telp_wa;
      window.open(url);
    } else if (chat == "sub") {
      console.log("masuk wa sub");
      let url = "https://wa.me/62" + numberWASub.telp_wa;
      window.open(url);
    } else {
      console.log("masuk wa random");
      let url = "https://wa.me/62" + chat;
      window.open(url);
    }
  };

  if (loading) {
    return (
      <div className="loginContainer">
        <Loading />
      </div>
    );
  } else {
    return (
      <div className="loginContainer">
        <FormDialog
          alertData={alertData}
          alertJudul={alertJudul}
          handleClose={handleClose}
          open={open}
        />
        <LoadingComponent loadingApi={loadingApi} />
        <img src={Logo} alt="loginLogo" className="loginLogo" />
        <h1 className="loginJudul">{"Masuk"}</h1>
        <div className="loginContainerInput">
          <img src={IconUser} alt="loginIconUser" className="loginIconUser" />
          <input
            id="phone"
            onChange={(e) => setPhone(e.target.value)}
            className="loginTextInput"
            placeholder="Nomor telepon / email"
          />
        </div>
        <div className="loginContainerInput">
          <img src={IconSandi} alt="loginIconUser" className="loginIconUser" />
          <input
            id="password"
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handlerLogin();
              }
            }}
            className="loginTextInput"
            placeholder="Kata sandi"
            type={hidePass ? "text" : "password"}
          />
          <img
          onClick={() => {
            setHidePass((previous) => !previous);
          }}
          src={IconEye}
          className="registerEye"
        />
        </div>
        <button className="loginButtonOK">
          <p className="loginTextButtonOK" onClick={handlerLogin}>
            {"OK"}
          </p>
        </button>
        <div className="loginLink">
          <a
            onClick={() => navigate("/identify")}
            // onClick={() => navigate("/register/old")}
            className="loginLinkRegister"
          >
            Daftar
          </a>
          <a
            onClick={() => navigate("/forget-password")}
            className="loginLinkForgetPassword"
          >
            Lupa Password ?
          </a>
        </div>
        <div
          onClick={() => setModalWhatsapp(!modalWhatsapp)}
          className="styles-module_whatsappButton"
          aria-hidden="true"
        >
          <svg focusable="false" viewBox="0 0 24 24" width="55" height="55">
            <path d="M16.75 13.96c.25.13.41.2.46.3.06.11.04.61-.21 1.18-.2.56-1.24 1.1-1.7 1.12-.46.02-.47.36-2.96-.73-2.49-1.09-3.99-3.75-4.11-3.92-.12-.17-.96-1.38-.92-2.61.05-1.22.69-1.8.95-2.04.24-.26.51-.29.68-.26h.47c.15 0 .36-.06.55.45l.69 1.87c.06.13.1.28.01.44l-.27.41-.39.42c-.12.12-.26.25-.12.5.12.26.62 1.09 1.32 1.78.91.88 1.71 1.17 1.95 1.3.24.14.39.12.54-.04l.81-.94c.19-.25.35-.19.58-.11l1.67.88M12 2a10 10 0 0 1 10 10 10 10 0 0 1-10 10c-1.97 0-3.8-.57-5.35-1.55L2 22l1.55-4.65A9.969 9.969 0 0 1 2 12 10 10 0 0 1 12 2m0 2a8 8 0 0 0-8 8c0 1.72.54 3.31 1.46 4.61L4.5 19.5l2.89-.96A7.95 7.95 0 0 0 12 20a8 8 0 0 0 8-8 8 8 0 0 0-8-8z"></path>
          </svg>
        </div>
        <Dialog open={modalWhatsapp} onClose={() => setModalWhatsapp(false)}>
          <DialogTitle
            sx={{
              color: "#000",
              fontSize: "1.2rem",
              textAlign: "center",
            }}
          >
            Punya pertanyaan? Silahkan Whatsapp kami
          </DialogTitle>
          <DialogActions
            sx={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
            }}
          >
            <Button
              sx={{
                alignItems: "center",
                justifyContent: "center",
                height: 50,
                backgroundColor: "#529F45",
                width: 200,
                borderRadius: 3,
                color: "white",
                textAlign: "center",
                marginBottom: 2,
              }}
              onClick={() => handleChatWhatsapp("pusat")}
            >
              Tanggerang
            </Button>
            {numberSubArray ? (
              // <FormControl
              //   sx={{ m: 1, width: 200, borderColor: "#529F45" }}
              //   success
              // >
              //   <InputLabel id="demo-multiple-name-label">
              //     Pilih Kota Anda
              //   </InputLabel>
              //   <Select
              //     defaultValue=""
              //     labelId="demo-simple-select-label"
              //     id="demo-simple-select"
              //     value={hasilPilihWA}
              //     label="Pilih Kota Anda"
              //     onChange={(e) => handleChatWhatsapp(e.target.value)}
              //   >
              //     {numberWASub &&
              //       numberWASub?.map((itemKota) => {
              //         return (
              //           <MenuItem value={itemKota.telp_wa}>
              //             {itemKota.provinsi}
              //           </MenuItem>
              //         );
              //       })}
              //   </Select>
              // </FormControl>
              <div className="inputSearchWA">
                <SelectSearch
                  options={numberWASub}
                  name="kota"
                  placeholder="Pilih Kota Anda"
                  search
                  filterOptions={fuzzySearch}
                  closeOnSelect
                  onChange={(items)=> handleChatWhatsapp(items)}
                />
              </div>
            ) : (
              <Button
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  height: 50,
                  backgroundColor: "#529F45",
                  width: 200,
                  borderRadius: 3,
                  color: "white",
                  textAlign: "center",
                  marginBottom: 2,
                }}
                onClick={() => handleChatWhatsapp("sub")}
              >
                Yogyakarta
              </Button>
            )}
          </DialogActions>
        </Dialog>
        {alertServerSibuk ? alertBusy() : null}
        {alertInet ? alertInternet() : null}
      </div>
    );
  }
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
