import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import IconBack from "../assets/images/backArrow.webp";
import BannerPromo from "../assets/dummy/banner-2021-10-18-100603.jpg";
import "./PromoDetail.css";
import { useSelector } from "react-redux";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import CircularProgress from "@mui/material/CircularProgress";
import {
  screenAction,
  paramsAction,
  promoAction,
  orderAction,
} from "../actions";
import { useDispatch } from "react-redux";
import moment from "moment";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

const regEx = /(<([^>]+)>)/gi;
const regEx2 = /((&nbsp;))*/gim;

export const RewardDetail = (props) => {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const { rewardDetail } = useSelector((state) => state.ProdukReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadingApi, setLoadingApi] = useState(false);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  function cetakDataHightlight() {
    const cetak = rewardDetail.highlight.replace(regEx, "");
    const cetak2 = cetak.replace(regEx2, "");
    return cetak2;
  }

  async function postClaim() {
    console.log(
      "masuk",
      JSON.stringify({
        products: [rewardDetail],
        data: {
          voucher_id: "",
          payment_method: "redeem",
          delivery_service: "",
          payment_total: `${rewardDetail.redeem_point ?? "0"}`,
          payment_discount: "",
          payment_final: `${rewardDetail.redeem_point ?? "0"}`,
        },
      })
    );
    try {
      let response = await axios({
        method: "POST",
        url: `${CONFIG.BASE_URL}/api/orders/redeem`,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        data: {
          products: [rewardDetail],
          data: {
            voucher_id: "",
            payment_method: "redeem",
            delivery_service: "",
            payment_total: `${rewardDetail.redeem_point ?? "0"}`,
            payment_discount: "",
            payment_final: `${rewardDetail.redeem_point ?? "0"}`,
          },
        },
      });
      const data = response.data;
      console.log(JSON.stringify(data));
      if (data.data !== "" && data["success"] == true) {
        dispatch(orderAction(data.data, "paymentOrder"));
        navigate("/order/confirmation");
      }
    } catch (error) {
      setLoadingApi(false);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        this.showSnackbarBusy();
      } else if (errorNetwork) {
        this.showSnackbarInet();
      } else if (error400) {
        Storage.removeItem("token");
        this.props.navigation.navigate("Home");
      }
    }
  }

  return (
    <div className="promoDetailContainer">
      <div className="promoDetailHeader">
        <div className="headerComponent">
          <div onClick={() => navigate(-1)} className="headerArrowContainer">
            <img
              src={IconBack}
              alt="headerIconArrow"
              className="headerIconArrow"
            />
          </div>
          <div className="headerTitleContainer">
            <p className="headerTitle">Klaim Reward</p>
          </div>
        </div>
        <div className="promoDetailBanner">
          {rewardDetail.image ? (
            <img
              src={CONFIG.BASE_URL + rewardDetail.image}
              alt="homeBannerPromoImage"
              className="promoDetailBannerImage"
            />
          ) : (
            <img
              src={DummyImage}
              alt="homeBannerPromoImage"
              className="promoDetailBannerImage"
            />
          )}
        </div>
        <div className="promoDetailReward">
          <div className="rewardDetailCardContainer">
            <div className="date">Poin</div>
            <div className="date">{rewardDetail.redeem_point}</div>
          </div>
          <div className="rewardDetailCardContainer">
            <table>
              {rewardDetail.name ? (
                <tr>
                  <td className="first">Nama Produk</td>
                  <td> : </td>
                  <td className="two">{rewardDetail.name}</td>
                </tr>
              ) : null}
              {rewardDetail.redeem_point ? (
                <tr>
                  <td className="first">Minimal Poin</td>
                  <td> : </td>
                  <td className="two">{rewardDetail.redeem_point}</td>
                </tr>
              ) : null}
            </table>
          </div>
          {rewardDetail.redeem_snk ? (
            <div className="rewardDetailCardContainer">
              <div className="date">Syarat Dan Ketentuan</div>
              <div className="date">{rewardDetail.redeem_snk}</div>
            </div>
          ) : null}
          {rewardDetail.redeem_desc ? (
            <div className="rewardDetailCardContainer">
              <div className="date">Cara Pakai</div>
              {/* <div className="date">{cetakDataHightlight()}</div> */}
              <div className="date">{rewardDetail.redeem_desc}</div>
            </div>
          ) : null}
        </div>
        <div className="rewardDetailButtonContainer">
          <button onClick={postClaim} className="rewardDetailButton">
            <p className="promoDetailButtonText">{"Klaim"}</p>
          </button>
        </div>
      </div>
      <Loading loadingApi={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RewardDetail);
