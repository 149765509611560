import React from "react";
import error404 from "../assets/images/404.webp";

function NoMatch() {
  return (
    <div className="homeContainer">
      <img style={{ height: "50%", width: "100%" }} src={error404} />
    </div>
  );
}

export default NoMatch;
