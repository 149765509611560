import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import "./AlertBlacklist.css";
import IconTracking from "../assets/images/2-order-tracking.webp";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalTracking({ open, handleClose, title, alamat }) {
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
            style: {
                width: '100%',
                borderRadius: 20,
            }
        }}
      >
        <div className="modal-tracking">
          <img
            style={{ height: "35%", width: "35%", alignSelf: "center" }}
            src={IconTracking}
            alt="Images"
          />
          <div className="text-title-tracking">
            {title}
          </div>
          <div className="text-alamat-tracking">
            {alamat}
          </div>
        </div>

        <div className="div-btn-tracking">
          <div
            className="btn-alert-tracking"
            onClick={(e) => handleClose((e = "ok"))}
          >
            Tutup
          </div>
        </div>
      </Dialog>
    </div>
  );
}
