import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import CONFIG from "../config/config";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import DummyImage from "../assets/images/Logo.webp";
import Header from "../components/HeaderDistributor";
import { useSelector } from "react-redux";
import "./DetailUlasan.css";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactStars from "react-rating-stars-component";
import NumberFormat from "react-number-format";

export const DetailUlasan = () => {
  const navigate = useNavigate();
  const [loadingApi, setLoadingApi] = useState(true);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);
  const { orderDetail } = useSelector((state) => state.OrderReducer);
  const [listDetailReview, setListDetailReview] = useState([]);
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const [page, setPage] = useState(1);
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");


  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  function Loading(props) {
    return props.visible ? (
      <div className="loading">
        <CircularProgress color="success" />
      </div>
    ) : null;
  }

  
  useEffect(() => {
    getDetailRating();
  }, []);

  async function getDetailRating() {
    setLoadingApi(true);
    try {
      let response;
      response = await axios.get(
        `${CONFIG.BASE_URL}/api/distributor-partner/reviews?review=true&page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = response.data.data.data.find(arr => arr.id === parseInt(id));
      setLoadingApi(false);
      setListDetailReview(data.data_review);
    } catch (error) {
      setLoadingApi(false);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  const Content = () =>
    listDetailReview.length > 0 ? (
      <InfiniteScroll dataLength={listDetailReview.length}>
        {listDetailReview.map((item, index) => {
          return (
            <div key={index} className="viewContent">
              <div className="satu">
                {item.review?.product_image.length ? (
                  <img
                    className="viewImgDetailUlasan"
                    src={CONFIG.BASE_URL + item.review?.product_image[0]?.path}
                  />
                ) : (
                  <img className="viewImgDetailUlasan" src={DummyImage} />
                )}
                <div className="viewTextUlasan">
                  <span>
                    <a className="viewUlasanTitle">{item.review?.name}</a>
                  </span>
                  <NumberFormat
                    value={Math.round(item.price_apps) ?? "0"}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"Rp "}
                    renderText={(value) => (
                      <a className="viewValueUlasan">
                        {item.qty || 0} x barang {value || 0}
                      </a>
                    )}
                  />
                  <div style={{ marginTop: "2%" }}>
                    <span>
                      <NumberFormat
                        value={Math.round(item.total_price) ?? "0"}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Rp "}
                        renderText={(value) => (
                          <a className="textTotalHarga">{value || 0}</a>
                        )}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="viewStar">
                <ReactStars
                  size={20}
                  value={parseInt(item.review?.star_review ) || 0}
                  edit={false}
                />
              </div>
              <div className="viewTextUser">
                {item.review ? (
                  <a className="textNamaUser">
                    {dataUser?.user.name}{" "}
                    {moment(item.review?.created_at).format(
                      "DD MMM YYYY HH:mm"
                    )}
                  </a>
                ) : (
                  <a className="textNamaUser">Null</a>
                )}
              </div>
              <div className="viewTextUser">
                <a className="viewValueUlasan">{item.review?.detail_review}</a>
              </div>
            </div>
          );
        })}
      </InfiniteScroll>
    ) : <div></div>;

  return (
    <div className="ulasanContainer">
      <div className="ulasanHeader">
        <Header
          // notifMessages={notifMessages}
          // notifSubscribe={notifSubscribe}
          // notifOrders={notifOrders}
          // notifComplaints={notifComplaints}
          // notifBroadcast={notifBroadcast}
          // countNotifCart={countNotifCart}
          // simpanSearch={simpanSearch}
          titleHeader="Detail Ulasan"
          item={false}
        />
      </div>
      <div className="ulasanContent">
        {<Content />}
      </div>
      <Loading visible={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

export default DetailUlasan;
