import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Slider from "../components/Slider";
import Images from "../images";
import NumberFormat from "react-number-format";
import ReactStars from "react-rating-stars-component";
import ProgressBar from "@ramonak/react-progress-bar";
import "./AddRating.css";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import Product1 from "../assets/dummy/product1.jpg";
import IconNotifAll from "../assets/images/NotifAll.webp";
import IconShoppingCart from "../assets/images/Shopping-Cart.webp";
import IconMenu from "../assets/images/Menu.webp";
import IconArrow from "../assets/images/backArrow.webp";
import IconChat from "../assets/images/ChatThumbnail.webp";
import IconInfo from "../assets/images/Info.webp";
import IconLove from "../assets/images/Love.webp";
import IconCart from "../assets/images/Keranjang.webp";
import { useSelector } from "react-redux";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import Dialog from "../components/Dialog";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

export const AddRating = (props) => {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const { ratingProduk } = useSelector((state) => state.ProdukReducer);
  console.log("cekkk=================", ratingProduk);
  const { screenName } = useSelector((state) => state.ScreenReducer);
  console.log("cekkk=================", screenName);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [shoppingCartItems, setShoppingCartItems] = useState({
    product_id: "",
    brand_id: "",
    satuan_online: "",
    konversi_sedang_ke_kecil: "",
    qty_konversi: "",
    qty: 1,
    notes: "",
    price_apps: "",
  });
  const [reviews, setReviews] = useState([]);
  const [search, setSearch] = useState("");
  const [qty, setQty] = useState(0);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [alertTambahan, setAlertTambahan] = useState("");
  const [alertJudul, setAlertJudul] = useState("");
  const [loadingApi, setLoadingApi] = useState(false);
  const [
    onEndReachedCalledDuringMomentum,
    setOnEndReachedCalledDuringMomentum,
  ] = useState(false);
  const [buttonUlasan, setButtonUlasan] = useState(true);
  const [buttonRiwayat, setButtonRiwayat] = useState(false);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  useEffect(() => {
    console.log(ratingProduk);
    if (screenName == "pembayaran") {
      let map = ratingProduk.data_item.map(function (item) {
        return {
          product_id: item.product_id,
          star_review: 0,
          detail_review: "",
        };
      });
      setReviews(map);
      console.log("reviews", reviews);
    } else {
      let map = ratingProduk.data_unreview.map(function (item) {
        return {
          product_id: item.product_id,
          star_review: 0,
          detail_review: "",
        };
      });
      setReviews(map);
      console.log("reviews", reviews);
    }

    return () => {};
  }, []);

  async function postRating() {
    let rating = reviews.every((item) => item.star_review > 0);
    console.log("rating", rating);
    if (rating) {
      try {
        let response = await axios.post(
          `${CONFIG.BASE_URL}/api/review`,
          {
            order_id: ratingProduk.id,
            products: reviews,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const data = response.data;
        if (data !== "" && data["success"] == true) {
          console.log("RATINGS", data);
          navigate(-1);
        } else {
          setAlertData("Input rating gagal");
          setAlertJudul("Pemberitahuan");
          handleClickOpen();
          console.log(response.data);
        }
      } catch (error) {
        let error429 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 429";
        let errorNetwork =
          JSON.parse(JSON.stringify(error)).message === "Network Error";
        let error400 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 400";
        console.log(
          "Cek Error========================",
          JSON.parse(JSON.stringify(error)).message
        );
        if (error429) {
          setAlertServerSibuk(true);
        } else if (errorNetwork) {
          setAlertInet(true);
        } else if (error400) {
          localStorage.removeItem("token");
          navigate("/home");
        }
      }
    } else {
      setAlertData("Harap isi rating bintang di setiap produk");
      setAlertJudul("Pemberitahuan");
      handleClickOpen();
    }
  }

  function handleRating(newRating, index) {
    console.log(newRating);
    console.log(index);
    let tmp = reviews;
    tmp[index].star_review = newRating;
    setReviews(tmp);
    // if (desc === "rating") {
    //   tmp[index].star_review = value;
    // } else {
    //   tmp[index].detail_review = value;
    // }
    // this.setState({ reviews: tmp });
  }

  function handleInput(e, index) {
    let tmp = reviews;
    tmp[index].detail_review = e;
    setReviews(tmp);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async (e) => {
    setOpen(false);
  };

  return (
    <div className="addRatingContainer">
      <Dialog
        alertData={alertData}
        alertJudul={alertJudul}
        handleClose={handleClose}
        open={open}
      />
      <div className="headerComponent">
        <div onClick={() => navigate(-1)} className="headerArrowContainer">
          <img
            src={IconArrow}
            alt="headerIconArrow"
            className="headerIconArrow"
          />
        </div>
        <div className="headerTitleContainer">
          <p className="headerTitle">Tulis Ulasan</p>
        </div>
      </div>
      {screenName == "pembayaran" ? (
        <>
          {ratingProduk.data_item.map((item, index) => {
            if (item.product) {
              return (
                <div key={index} className="addRatingContent">
                  <div className="addRatingTopContainer">
                    <div className="addRatingTopLeftContainer">
                      {item.product.image ? (
                        <img
                          src={CONFIG.BASE_URL + item.product.image}
                          alt="product1"
                          className="addRatingTopLeftImage"
                        />
                      ) : (
                        <img
                          src={DummyImage}
                          alt="product1"
                          className="addRatingTopLeftImage"
                        />
                      )}
                    </div>
                    <div className="addRatingTopRightContainer">
                      <p className="addRatingTopRightTitle">
                        {item.product?.name}
                      </p>
                      <div className="halfView">
                        {item.half ? (
                          <NumberFormat
                            value={Math.round(item.price_apps / 2) ?? "0"}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p className="addRatingTopRightText">
                                {item.qty} barang x {value || 0}
                              </p>
                            )}
                          />
                        ) : (
                          <NumberFormat
                            value={Math.round(item.price_apps) ?? "0"}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p className="addRatingTopRightText">
                                {item.qty} barang x {value || 0}
                              </p>
                            )}
                          />
                        )}
                        {item.half ? (
                          <p className="addRatingTopRightTextHalf">
                            {" ( "}
                            {item?.qty_konversi}{" "}
                            {item?.small_unit.charAt(0) +
                              item?.small_unit.slice(1).toLowerCase()}
                            {" )"}
                          </p>
                        ) : null}
                      </div>
                      <NumberFormat
                        value={Math.round(item.total_price) ?? "0"}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Rp "}
                        renderText={(value) => (
                          <p className="addRatingTopRightPrice">{value || 0}</p>
                        )}
                      />
                    </div>
                  </div>
                  <div className="addRatingBottomContainer">
                    <ReactStars
                      count={5}
                      value={0}
                      size={40}
                      //   edit={false}
                      onChange={(newRating) => {
                        handleRating(newRating, index);
                      }}
                      color={"#adabae"}
                      activeColor={"#ffd700"}
                      classNames="addRatingStar"
                    />
                    <div className="addRatingTextAreaContainer">
                      <input
                        onChange={(e) => handleInput(e.target.value, index)}
                        type="textarea"
                        className="addRatingTextArea"
                        placeholder="Berikan komentarmu terkait barang ini"
                      />
                    </div>
                  </div>
                  <hr></hr>
                </div>
              );
            }
          })}
        </>
      ) : (
        <>
          {ratingProduk.data_unreview.map((item, index) => {
            if (item.product) {
              return (
                <div key={index} className="addRatingContent">
                  <div className="addRatingTopContainer">
                    <div className="addRatingTopLeftContainer">
                      {item.product.image ? (
                        <img
                          src={CONFIG.BASE_URL + item.product.image}
                          alt="product1"
                          className="addRatingTopLeftImage"
                        />
                      ) : (
                        <img
                          src={DummyImage}
                          alt="product1"
                          className="addRatingTopLeftImage"
                        />
                      )}
                    </div>
                    <div className="addRatingTopRightContainer">
                      <p className="addRatingTopRightTitle">
                        {item.product?.name}
                      </p>
                      <div className="halfView">
                        {item.half ? (
                          <NumberFormat
                            value={Math.round(item.price_apps / 2) ?? "0"}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p className="addRatingTopRightText">
                                {item.qty} barang x {value || 0}
                              </p>
                            )}
                          />
                        ) : (
                          <NumberFormat
                            value={Math.round(item.price_apps) ?? "0"}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p className="addRatingTopRightText">
                                {item.qty} barang x {value || 0}
                              </p>
                            )}
                          />
                        )}
                        {item.half ? (
                          <p className="addRatingTopRightTextHalf">
                            {" ( "}
                            {item?.qty_konversi}{" "}
                            {item?.small_unit.charAt(0) +
                              item?.small_unit.slice(1).toLowerCase()}
                            {" )"}
                          </p>
                        ) : null}
                      </div>
                      <NumberFormat
                        value={Math.round(item.total_price) ?? "0"}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Rp "}
                        renderText={(value) => (
                          <p className="addRatingTopRightPrice">{value || 0}</p>
                        )}
                      />
                    </div>
                  </div>
                  <div className="addRatingBottomContainer">
                    <ReactStars
                      count={5}
                      value={0}
                      size={40}
                      //   edit={false}
                      onChange={(newRating) => {
                        handleRating(newRating, index);
                      }}
                      color={"#adabae"}
                      activeColor={"#ffd700"}
                      classNames="addRatingStar"
                    />
                    <div className="addRatingTextAreaContainer">
                      <input
                        onChange={(e) => handleInput(e.target.value, index)}
                        type="textarea"
                        className="addRatingTextArea"
                        placeholder="Berikan komentarmu terkait barang ini"
                      />
                    </div>
                  </div>
                  <hr></hr>
                </div>
              );
            }
          })}
        </>
      )}
      <div className="addRatingBottomButtonContainer">
        <button onClick={postRating} className="addRatingBottomButton">
          <p className="addRatingBottomButtonText">Kirim Ulasan</p>
        </button>
      </div>
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddRating);
