import React, {useEffect} from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import "./NotificationList.css";

export const NotificationListLangganan = (props) => {
  const navigate = useNavigate();
  return (
    <div className="notifListContainer">
        <Header />
        <div className="notifListMain">
            <div className="notifListCard">
                <div className="notifListHeader">
                    Langganan
                </div>
                <div className="notifListContent">
                    <div className="notifListContentMain">
                        Langganan mingguan anda tinggal 2 hari lagi, mohon cek kembali bila ingin merubah status berlangganan
                    </div>
                </div>
            </div>
        </div>
        <Navbar />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationListLangganan);
