import React from "react";
import Calendar from "react-calendar";
// import "react-calendar/dist/Calendar.css";
import "./Calendar.css"

function Kalender({
  setDate,
  date,
  selectRange,
  openCalendar,
  minDate,
  allowPartialRange,
}) {
  return (
    <div>
      {openCalendar ? (
        <Calendar
          locale={"id"}
          allowPartialRange={allowPartialRange}
            // onClickDay={setDate}
          onChange={setDate}
          value={date}
          minDate={minDate}
          selectRange={selectRange}
        />
      ) : null}
    </div>
  );
}

export default Kalender;
