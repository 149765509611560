import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/images/IconLogo.webp";
import IconBack from "../assets/images/backArrow.webp";
import "./UserEdit.css";
import axios from "axios";
import CONFIG from "../config/config";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { screenAction, userAction } from "../actions";
import Dialog from "../components/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

let interval;

export const UserEditSendOtp = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const { phone } = useSelector((state) => state.UserReducer);
  const { screenName } = useSelector((state) => state.ScreenReducer);
  const [timer, setTimer] = useState(60);
  const [otp_code, setOtp_code] = useState(0);
  const [loadingApi, setLoadingApi] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [alertTambahan, setAlertTambahan] = useState("");
  const [alertJudul, setAlertJudul] = useState("");
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  useEffect(() => {
    setTime();

    return () => {};
  }, []);

  useEffect(() => {
    return () => {
      clearInterval(interval);
    };
  }, [timer == 0 ? clearTime() : null]);

  function clearTime() {
    setTimer(60);
    clearInterval(interval);
  }

  function setTime() {
    interval = setInterval(() => setTimer((prevState) => prevState - 1), 1000);
  }

  const handleClickOpen = () => {
    setAlertData("Apakah nomor tersebut sudah benar?");
    setAlertTambahan("harap mengecek kembali nomor anda");
    setAlertJudul("Pemberitahuan");
    setOpen(true);
  };

  const handleClose = async (e) => {
    setOpen(false);
    if (alertData == "Verifikasi sukses") {
      console.log("masukkk", dataUser.user.id);
      await axios
        .post(
          `${CONFIG.BASE_URL}/api/profile/${dataUser.user.id}`,
          {
            phone: phone,
            name: dataUser.user.name,
            email: dataUser.user.email,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          const data = response.data;
          console.log("DATA", data);
          if (data != null && data["success"] == true) {
            console.log("MASUKKK");
            let dataTemp = dataUser;
            dataTemp.user = data.data;
            localStorage.setItem("dataUser", JSON.stringify(dataTemp));
            dispatch(userAction("", "phone"));
            navigate(-3);
          }
        })
        .catch((error) => {
          let error429 =
            JSON.parse(JSON.stringify(error)).message ===
            "Request failed with status code 429";
          let errorNetwork =
            JSON.parse(JSON.stringify(error)).message === "Network Error";
          let error400 =
            JSON.parse(JSON.stringify(error)).message ===
            "Request failed with status code 400";
          console.log(
            "Cek Error========================",
            JSON.parse(JSON.stringify(error)).message
          );
          if (error429) {
            setAlertServerSibuk(true);
          } else if (errorNetwork) {
            setAlertInet(true);
          } else if (error400) {
            localStorage.removeItem("token");
            navigate("/home");
          }
        });
    }
  };

  async function sendOTP() {
    const formData = {
      phone: phone,
      customer_code: dataUser.user.customer_code,
    };
    setLoadingApi(true);
    try {
      let response;
      if (screenName == "WAEDITTELEPON") {
        response = await axios({
          method: "POST",
          url: `${CONFIG.BASE_URL}/api/otp/phone-number/wa`,
          data: formData,
        });
      } else if (screenName == "SMSEDITTELEPON") {
        response = await axios({
          method: "POST",
          url: `${CONFIG.BASE_URL}/api/otp/phone-number/sms`,
          data: formData,
        });
      }
      // console.log(response)
      const data = response.data;
      if (data !== "" && data["success"] == true) {
        setLoadingApi(true);
        setTime();
        console.log("HASIL SEND OTP", data.data);
      } else {
        console.log("", typeof data);
        // this.setState({
        //   alertData: 'Gagal mengirim otp ' + data.message,
        //   modalAlert: !this.state.modalAlert,
        // });
        return false;
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error===========DataSearching=============",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  async function cekOTP() {
    const formData = {
      otp_code: otp_code,
      phone: phone,
    };
    console.log("data====>", formData);
    try {
      let response = await axios.post(
        `${CONFIG.BASE_URL}/api/otp/verify`,
        formData
        // {
        //   headers: {Authorization: `Bearer ${this.props.token}`},
        // },
        // {timeout: 1000},
      );
      const data = response.data;
      console.log(data);
      if (data !== "" && data["success"] == true) {
        //   this.props.loginAct(data.data.user, 'dataUser');
        //   this.props.loginAct(data.data.token, 'token');
        setAlertData("Verifikasi sukses");
        setAlertJudul("Pemberitahuan");
        setOpen(true);
      } else {
        setAlertData("Verifikasi gagal, pastikan kode otp sesuai");
        setAlertJudul("Pemberitahuan");
        setOpen(true);
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  return (
    <div className="userEditContainer">
      <Dialog
        alertData={alertData}
        alertJudul={alertJudul}
        alertTambahan={alertTambahan}
        handleClose={handleClose}
        open={open}
      />
      <div className="headerComponent">
        <div onClick={() => navigate(-1)} className="headerArrowContainer">
          <img
            src={IconBack}
            alt="headerIconArrow"
            className="headerIconArrow"
          />
        </div>
        <div className="headerTitleContainer">
          <p className="headerTitle">Verifikasi</p>
        </div>
      </div>
      <div className="userEditList">
        <div className="userEditTextTitle">Kode OTP</div>
        <div className="subUserEdit">
          <input
            onChange={(e) => setOtp_code(e.target.value)}
            className="userEditInput"
          ></input>
        </div>
        <hr></hr>
        {timer == 60 ? (
          <p onClick={sendOTP} className="resendOtp">
            Kirim Ulang OTP
          </p>
        ) : (
          <p className="resendOtp">
            Kirim Ulang OTP
            {" ("}
            {timer}
            {")"}
          </p>
        )}
        <button onClick={cekOTP} className="editButtonEditOK">
          <p className="editTextButtonOK">{"Ok"}</p>
        </button>
      </div>
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserEditSendOtp);
