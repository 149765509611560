import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/images/IconLogo.webp";
import IconUser from "../assets/images/User.webp";
import IconSandi from "../assets/images/Sandi.webp";
import IconAddress from "../assets/images/Address.webp";
import IconPhone from "../assets/images/Phone.webp";
import IconReSandi from "../assets/images/ReSandi.webp";
import "./RegisterOldForm.css";
import axios from "axios";
import CONFIG from "../config/config";
import { useDispatch } from "react-redux";
import { userAction, screenAction } from "../actions";
import CircularProgress from "@mui/material/CircularProgress";
import { getDataToken } from "../config/firebase";
import { useSelector } from "react-redux";
import Loading from "../components/Loading";
import FormDialog from "../components/Dialog";
import IconEye from "../assets/images/Eye.webp";

export const RegisterOldForm = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { dataUser } = useSelector((state) => state.UserReducer);
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirm, setPassword_confirm] = useState("");
  const [alertPass, setAlertPass] = useState(false);
  const [alertPass2, setAlertPass2] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const [token, setToken] = useState("");
  const [random_number_1, setRandom_number_1] = useState("");
  const [random_number_2, setRandom_number_2] = useState("");
  const [sum_holder, setSum_holder] = useState("");
  const [shouldShow, setShouldShow] = useState(false);
  const [textInput_text_holder, setTextInput_text_holder] = useState("");
  const [hidePass, setHidePass] = useState(false);
  const [hidePass2, setHidePass2] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [alertTambahan, setAlertTambahan] = useState("");
  const [alertJudul, setAlertJudul] = useState("");

  function generate_captcha() {
    var number_1 = Math.floor(Math.random() * 100) + 1;

    var number_2 = Math.floor(Math.random() * 100) + 1;

    var sum = number_1 + number_2;

    setRandom_number_1(number_1);
    setRandom_number_2(number_2);
    setSum_holder(sum);
  }

  function run_captcha_function() {
    var temp = random_number_1 + random_number_2;

    if (textInput_text_holder == temp) {
      //Write Your Code Here Which you want to execute on RIGHT Captcha Entered.
      setShouldShow(true);
    } else {
      //Write Your Code Here Which you want to execute on WRONG Captcha Entered.
      // this.setState({
      //   alertData: "Captcha Tidak Cocok",
      //   modalAlert: !this.state.modalAlert,
      // });
      alert("Captcha Tidak Cocok");
    }

    // Calling captcha function, So each time new captcha number generates on button clicks.
    generate_captcha();
  }

  useEffect(() => {
    let data;

    async function getToken() {
      data = await getDataToken();
      if (data) {
        console.log("token adalah : ", data);
        setToken(data);
      }
      return data;
    }

    getToken();
    generate_captcha();

    return () => {};
  }, []);

  function handleRegister() {
    if (!phone.trim()) {
      setAlertData("Pastikan nomor telepon tidak ada yang kosong");
      setAlertJudul("Pemberitahuan");
      setOpen(true);
      return;
    } else if (!password.trim()) {
      setAlertData("Pastikan password tidak ada yang kosong");
      setAlertJudul("Pemberitahuan");
      setOpen(true);
      return;
    } else if (!password_confirm.trim()) {
      setAlertData("Pastikan konfirm password tidak ada yang kosong");
      setAlertJudul("Pemberitahuan");
      setOpen(true);
      return;
    } else if (alertPass == true) {
      setAlertData("Pastikan password diisi dengan benar");
      setAlertJudul("Pemberitahuan");
      setOpen(true);
      return;
    } else if (alertPass2 == true) {
      setAlertData("Pastikan password konfirm diisi dengan benar");
      setAlertJudul("Pemberitahuan");
      setOpen(true);
      return;
    } else {
      setLoadingApi(true);
      const formData = {
        customer_code: dataUser?.customer_code,
        name: dataUser?.name,
        user_address: dataUser?.user_address,
        phone: phone,
        password: password,
        password_confirmation: password_confirm,
        fcm_token: token,
      };
      dispatch(userAction(formData, "dataUser"));
      dispatch(screenAction("RegisterOld", "screenName"));
      setLoadingApi(false);
      navigate("/choose-verification");
    }
  }

  const handleClose = async (e) => {
    setOpen(false);
  };

  return (
    <div className="containerRegisterOldForm">
      <FormDialog
        alertData={alertData}
        alertJudul={alertJudul}
        handleClose={handleClose}
        open={open}
      />
      <img src={Logo} alt="Logo" className="logo" />
      <h1 className="judul">{"Daftar"}</h1>
      <div className="containerAddressRegisterOld">
        <img src={IconUser} alt="iconUser" className="iconUser" />
        <input
          className="textInputRegisterOld"
          placeholder={dataUser?.name}
          disabled
        />
      </div>
      <div className="containerAddressRegisterOld">
        <img src={IconAddress} alt="iconUser" className="iconUser" />
        <input
          className="textInputRegisterOld"
          placeholder={
            dataUser?.user_address[0]?.kelurahan
              ? dataUser?.user_address[0]?.address +
                "\n" +
                dataUser?.user_address[0]?.kelurahan +
                ", " +
                dataUser?.user_address[0]?.kecamatan +
                ", " +
                dataUser?.user_address[0]?.kota +
                ", " +
                dataUser?.user_address[0]?.kode_pos
              : dataUser?.user_address[0]?.address
          }
          disabled
        />
      </div>
      <div className="containerInputRegisterOldForm">
        <img src={IconPhone} alt="iconUser" className="iconUser" />
        <input
          value={phone}
          type="number"
          onChange={(e) => setPhone(e.target.value)}
          className="textInputRegisterOld"
          placeholder="Nomor Telepon"
        />
      </div>
      <div className="containerInputRegisterOldForm">
        <img src={IconSandi} alt="iconUser" className="iconUser" />
        <input
          type={hidePass ? "text" : "password"}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            if (password.length < 7) {
              setAlertPass(true);
            } else {
              setAlertPass(false);
            }
          }}
          className="textInputRegisterOld"
          placeholder="Kata Sandi"
        />
        <img
          onClick={() => {
            setHidePass((previous) => !previous);
          }}
          src={IconEye}
          className="registerEye"
        />
      </div>
      {alertPass && (
        <p className="textAlert">Password harus 8 character</p>
      )}
      <div className="containerInputRegisterOldForm">
        <img src={IconReSandi} alt="iconUser" className="iconUser" />
        <input
          type={hidePass2 ? "text" : "password"}
          value={password_confirm}
          onChange={(e) => {
            setPassword_confirm(e.target.value);
            if (e.target.value != password) {
              setAlertPass2(true);
            } else {
              setAlertPass2(false);
            }
          }}
          className="textInputRegisterOld"
          placeholder="Konfirmasi Kata Sandi"
        />
        <img
          onClick={() => {
            setHidePass2((previous) => !previous);
          }}
          src={IconEye}
          className="registerEye"
        />
      </div>
      {alertPass2 && (
        <p className="textAlert">Password konfirmasi tidak sama</p>
      )}
      {shouldShow ? (
        <button className="buttonOkRegisterOldForm" onClick={handleRegister}>
          <p className="textButtonOK">{"OK"}</p>
        </button>
      ) : (
        <div className="containerCaptcha">
          <div className="captcha">
            <p className="captchaText">
              {random_number_1}
              {" + "}
              {random_number_2}
              {" = "}
            </p>
            <input
              type="number"
              className="captchaInput"
              onChange={(e) => setTextInput_text_holder(e.target.value)}
              placeholder={"Hasil"}
            ></input>
            <button className="buttonReload" onClick={generate_captcha}>
              <img
                className="captchaImage"
                src="https://reactnativecode.com/wp-content/uploads/2019/08/reload_image.jpg"
              />
            </button>
          </div>
          <button onClick={run_captcha_function} className="buttonCheckCaptcha">
            <p className="captchaText">Periksa Hasil Capctha</p>
          </button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterOldForm);
