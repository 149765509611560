import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import IconBack from "../assets/images/backArrow.webp";
import IconSearch from "../assets/images/Search.webp";
import IconUser from "../assets/images/User.webp";
import IconKomplain from "../assets/images/Komplain.webp";
import IconPromo from "../assets/images/Promo.webp";
import IconOther from "../assets/images/Others.webp";
import "./FaqDetail.css";
import { useSelector } from "react-redux";
import axios from "axios";
import CONFIG from "../config/config";
import { useDispatch } from "react-redux";
import { helpAction } from "../actions";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Header from "../components/HeaderDistributor";
import Loading from "../components/Loading"

export const FaqDetail = (props) => {
  const dispatch = useDispatch();
  const { helpDetail } = useSelector((state) => state.HelpReducer);
  const navigate = useNavigate();
  const [help, setHelp] = useState([]);
  const [search, setSearch] = useState("");
  const [loadingApi, setLoadingApi] = useState(true);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("idFaq")

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  useEffect(() => {
      getHelpCare();

    return () => {};
  }, []);

  async function getHelpCare() {

    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/helps`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = response.data.data.find(arr => arr.id === parseInt(id));
      setHelp(data);
      setLoadingApi(false);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error=======DATA LOAD=================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }
  return (
    <div className="faqDetailContainer">
      <div className="faqDetailHeader">
        <Header titleHeader="Bantuan detail" />
        <div className="faqDetailSearchContainer">
          <input
            onChange={(e) => setSearch(e.target.value)}
            className="faqDetailTextInput"
            placeholder="Ketik kata kunci yang ingin anda cari"
            type="text"
            value={search}
          />
          <img
            src={IconSearch}
            alt="headerSearchLogo"
            className="faqDetailSearchLogo"
          />
        </div>
      </div>
      {search.length > 0 && (
        <div className="faqMain">
          {help.map((item, index) => {
            return (
              <div
                onClick={() => {
                  dispatch(helpAction(item, "helpDetail"));
                  setSearch("");
                }}
                key={index}
              >
                <div className="list">
                  <a>{item.name}</a>
                </div>
                <hr></hr>
              </div>
            );
          })}
        </div>
      )}
      {search.length == 0 && (
        <div className="faqDetailMain">
          <div className="title">{help.name}</div>
          <div
            className="list"
            dangerouslySetInnerHTML={{
              __html: help.description,
            }}
          ></div>
        </div>
      )}
      <Loading loadingApi={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FaqDetail);
