import React, { useEffect, useState } from "react";
import Header from "../components/HeaderDistributor";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import CONFIG from "../config/config";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useNavigate, useParams } from "react-router-dom";
import error404 from "../assets/images/404.webp";
import { connect,useDispatch } from "react-redux";
import IconSearch from "../assets/images/Search.webp";
import "./DistributorComplaintList.css";
import InfiniteScroll from "react-infinite-scroll-component";
import DummyImage from "../assets/images/Logo.webp";
import moment from "moment";
import NumberFormat from "react-number-format";
import { idAction } from "../actions";

const arr = () => [
  { text: "Semua", status: " ", active: false },
  { text: "Belum direspon", status: "no response yet", active: false },
  { text: "Sudah direspon", status: "response", active: false },
  { text: "Di batalkan", status: "rejected", active: false },
  { text: "Sudah Selesai", status: "completed", active: false },
];
export const DistributorComplaintList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadingApi, setLoadingApi] = useState(true);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);
  const [notifOrders, setNotifOrders] = useState(0);
  const [notifMessages, setNotifMessages] = useState(0);
  const [notifComplaints, setNotifComplaints] = useState(0);
  const [notifBroadcast, setNotifBroadcast] = useState(0);
  const [notifSubscribe, setNotifSubscribe] = useState(0);
  const [countNotifCart, setCountNotifCart] = useState(0);
  const [params, setParams] = useState({});
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [listComplaint, setListComplaint] = useState([]);
  const [colorFilter, setColorFilter] = useState(true);
  const [arrBtn, setArrBtn] = useState(arr);
  const [btn,setBtn] = useState("");

  const updateArr = (item) => {
    const newState = arrBtn.map(obj => {
      if (obj.text === item){
        if(obj.active === true){
          getComplaint("status"," ")
          return {...obj,active: false};
        }else{
          return {...obj,active: true};
        }
        // return {...obj,active: true};
      }else{
        return {...obj,active: false};
      }
      // return obj;
    });
    setArrBtn(newState);
  }

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }
  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }
  function Loading(props) {
    return props.visible ? (
      <div className="loading">
        <CircularProgress color="success" />
      </div>
    ) : null;
  }
  function findInvoice() {
    getComplaint("invoice", search);
  }
  function findStatus(item) {
    getComplaint("status", item);
  }
  useEffect(() => {
    getNotifAll();
    getComplaint();
  }, []);

  async function getNotifAll() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/notification-all`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      setNotifOrders(data.total_order);
      setNotifMessages(data.total_chat);
      setNotifComplaints(data.total_complaint);
      setNotifSubscribe(data.total_subscribe);
      setNotifBroadcast(data.total_broadcast);
      setCountNotifCart(data.total_cart);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========NOTIF================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  async function getComplaint(name, value) {
    setLoadingApi(true);
    let parameter = {
      ...params,
      [name]: value,
    };
    let _params = "?";
    Object.keys(parameter).forEach((key, i) => {
      if (i > 0) {
        _params += "&";
      }
      _params += `${key}=${parameter[key]}`;
    });
    try {
      let response = await axios
        .get(
          `${CONFIG.BASE_URL}/api/distributor-partner/complaint${_params}&page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          setLoadingApi(false);
          const data = response.data.data;
          setListComplaint(
            page == 1 ? data.data : [...listComplaint, ...data.data]
          );
          setParams(parameter);
        });
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========NOTIF================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  const Content = () =>
    listComplaint.length > 0 && !loadingApi ? (
      <InfiniteScroll dataLength={listComplaint.length}>
        {listComplaint.map((item, index) => {
          return (
            <div key={index} className="viewContent">
              <div className="contentDate">
                {moment(item.created_at).format("DD MMM YYYY HH:mm")}
              </div>
              <div
                className="contentCard"
                onClick={() => {
                  // dispatch(idAction(item.id,"idComp"));
                  navigate("/menu/distributor/complaint/detail?idComp="+item.id);
                }}
              >
                {item.order.order_details[0].product.image != null ? (
                  <img
                    className="viewImgUlasan"
                    src={
                      CONFIG.BASE_URL +
                      item.order.order_details[0].product.image?.path
                    }
                  />
                ) : (
                  <img className="contentImg" src={DummyImage} />
                )}

                <div className="contentText">
                  <div className="invoiceText">
                    {"Invoice: "}{" "}
                    <p className="invoiceNumber">{item.order.invoice}</p>
                  </div>
                  <div className="nameText">
                    {item.order.order_details[0].product.invoice_name}
                  </div>
                  <div className="viewPrice">
                    <div className="priceText">
                      <NumberFormat
                        value={
                          Math.round(item.order.order_details[0].total_price) ??
                          "0"
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Rp "}
                        renderText={(value) => (
                          <div className="priceText">{value || 0}</div>
                        )}
                      />
                    </div>
                    <div className="produk">
                      +{item.order.order_details.length - 1} produk lainnya
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </InfiniteScroll>
    ) : null;

  return (
    <div className="complainContainer">
      <div className="complainHeader">
        <Header
          notifMessages={notifMessages}
          notifSubscribe={notifSubscribe}
          notifOrders={notifOrders}
          notifComplaints={notifComplaints}
          notifBroadcast={notifBroadcast}
          countNotifCart={countNotifCart}
          titleHeader="List Complaint"
          item={false}
        />
      </div>

      <div className="complainContent">
        <div className="viewCariComplaint">
          <input
            className="viewInputcariComplaint"
            placeholder="Ketik nomor invoice yang ingin anda cari..."
            type="text"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setSearch(e.target.value);
                findInvoice();
              }
            }}
          />
          <img
            src={IconSearch}
            alt="headerSearchLogo"
            className="imageCariComplaint"
          />
        </div>
        <div className="viewFillter">
          {arrBtn.map((filter, i) => (
            <div
              key={i}
              className={filter.active ? "buttonFilterActive" : "buttonFilter"}
              onClick={() => {
                setBtn()
                findStatus(filter.status);
                updateArr(filter.text)
              }}
            >
              {filter.text}
            </div>
          ))}
        </div>
        <Content />
      </div>
      <Loading visible={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(DistributorComplaintList);
