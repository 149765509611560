import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Slider from "../components/Slider";
import Images from "../images";
import NumberFormat from "react-number-format";
import ReactStars from "react-rating-stars-component";
import "./ProdukDeskripsi.css";
import Navbar from "../components/Navbar";
import Product1 from "../assets/dummy/product1.jpg";
import IconNotifAll from "../assets/images/NotifAll.webp";
import IconShoppingCart from "../assets/images/Shopping-Cart.webp";
import IconMenu from "../assets/images/Menu.webp";
import IconArrow from "../assets/images/backArrow.webp";
import IconChat from "../assets/images/ChatThumbnail.webp";
import IconInfo from "../assets/images/Info.webp";
import IconLove from "../assets/images/Love.webp";
import IconCart from "../assets/images/Keranjang.webp";
import { useSelector } from "react-redux";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import CircularProgress from "@mui/material/CircularProgress";
import {
  screenAction,
  paramsAction,
  promoAction,
  produkAction,
} from "../actions";
import { useDispatch } from "react-redux";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import ModalShopping from "../components/DialogShopping";
import Alert from "@mui/material/Alert";
import "./ProdukDeskripsiCarousel.css";
import { Carousel } from "react-responsive-carousel";
import moment from "moment";
import IconDiskusi from "../assets/images/EmptyDiskusi.webp";
import Header from "../components/Header";
import Loading from "../components/Loading";
import AlertBlacklist from "../components/AlertBlacklist";

export const ProdukDeskripsi = (props) => {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const { produkDesc } = useSelector((state) => state.ProdukReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [shoppingCartItems, setShoppingCartItems] = useState({
    product_id: "",
    brand_id: "",
    satuan_online: "",
    konversi_sedang_ke_kecil: "",
    qty_konversi: "",
    qty: 1,
    notes: "",
    price_apps: "",
  });
  const [listSearch, setListSearch] = useState([]);
  const [avgRating, setAvgRating] = useState({
    avg_star: "",
  });
  const [search, setSearch] = useState("");
  const [qty, setQty] = useState(0);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const [notifOrders, setNotifOrders] = useState(0);
  const [notifMessages, setNotifMessages] = useState(0);
  const [notifComplaints, setNotifComplaints] = useState(0);
  const [notifBroadcast, setNotifBroadcast] = useState(0);
  const [notifSubscribe, setNotifSubscribe] = useState(0);
  const [countNotifCart, setCountNotifCart] = useState(0);
  const [half, setHalf] = useState(false);
  const [qty_cart, setQty_cart] = useState(produkDesc?.cart?.qty || 0);
  const [openShopping, setOpenShopping] = useState(false);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);
  const [recomenProduct, setRecomenProduct] = useState([]);
  const [loadingApi, setLoadingApi] = useState(true);
  const [diskusi, setDiskusi] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dataDetail, setDataDetail] = useState({});
  const [message, setMessage] = useState("");
  const queryParams = new URLSearchParams(window?.location?.search);
  const id = queryParams.get("idProduct");
  const paramHome = queryParams.get("home-param");
  const paramProd = queryParams.get("produk-param");
  const [varian, setVarian] = useState([]);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            // setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            // setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  useEffect(() => {
    getProductRating();
    getNotifAll();
    getProdukRecomen();
    getDiskusi();
    getDataDetail(id);
    getVarian();
    return () => {};
  }, []);

  //rata-rata rating
  async function getProductRating() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/products/ratings/${produkDesc.id}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      setAvgRating(data);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }
  async function getDataDetail(param) {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/products/${param}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = response.data.data;
      console.log("data pro " + JSON.stringify(data));
      setLoadingApi(false);
      dispatch(produkAction(data, "produkDesc"));
      setDataDetail(data);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========NOTIF================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }
  async function getVarian() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/products/varian/${id}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data;
      console.log("data varian " + JSON.stringify(data));
      if (data["success"] == true) {
        setVarian(data?.data);
      } else {
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========NOTIF================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }
  async function getNotifAll() {
    console.log("cek hit notif");
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/notification-all`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      // console.log('DATA TEST', data.total_chat);
      setNotifOrders(data.total_order);
      setNotifMessages(data.total_chat);
      setNotifComplaints(data.total_complaint);
      setNotifSubscribe(data.total_subscribe);
      setNotifBroadcast(data.total_broadcast);
      setCountNotifCart(data.total_cart);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========NOTIF================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  function renderCountNotificationBadge() {
    try {
      let count = 0;
      let hasil = notifMessages;
      let hasil2 = notifSubscribe;
      let hasil3 = notifOrders;
      let hasil4 = notifComplaints;
      let hasil5 = notifBroadcast;
      count =
        count +
        parseInt(hasil) +
        parseInt(hasil2) +
        parseInt(hasil3) +
        parseInt(hasil4) +
        parseInt(hasil5);
      // console.log(count);
      if (count > 0) {
        return count;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getPromo(item) {
    try {
      let response = await axios({
        method: "get",
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        url: `${CONFIG.BASE_URL}/api/promo?id=${item}`,
      });
      const data = response.data;
      console.log(data);
      if (data != 0 && data["success"] == true) {
        dispatch(promoAction(data.data, "promo"));
        navigate("/promo/detail");
      } else {
        return;
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error=============getPromo===========",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  async function postShoppingCart(item) {
    const brand_id_1 = ["001"];
    const brand_id_2 = [
      "002",
      "003",
      "004",
      "007",
      "008",
      "009",
      "010",
      "011",
      "012",
      "013",
      "014",
    ];
    const brand_id_3 = ["005"];

    // console.log('price=====>',item)
    let price = item.price.harga_ritel_gt ?? "0";
    if (brand_id_1.includes(item.brand_id)) {
      if (item.status_promosi_coret == 1) {
        // console.log('if 1');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          default:
            break;
        }
      } else if (
        (item.status_promosi_coret !== "0" ||
          item.status_promosi_coret !== null) &&
        item.status_herbana !== "1"
      ) {
        // console.log('if 2');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_ritel_gt;
            break;
          default:
            break;
        }
      } else if (item.status_herbana === "1") {
        // console.log('if 3');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_grosir_mt;
            break;
          default:
            break;
        }
      } else {
        // console.log('if 4');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_ritel_gt;
            break;
          default:
            break;
        }
      }
    } else if (brand_id_2.includes(item.brand_id)) {
      if (item.status_promosi_coret == 1) {
        // console.log('if 5');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          default:
            break;
        }
      } else if (
        item.status_promosi_coret !== "0" ||
        item.status_promosi_coret !== null
      ) {
        // console.log('if 6');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_grosir_mt;
            break;
          default:
            break;
        }
      } else {
        // console.log('if 7');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_grosir_mt;
            break;
          default:
            break;
        }
      }
    } else if (brand_id_3.includes(item.brand_id)) {
      if (item.status_promosi_coret == 1) {
        // console.log('if 8');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          default:
            break;
        }
      } else if (
        item.status_promosi_coret !== "0" ||
        item.status_promosi_coret !== null
      ) {
        // console.log('if 9');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_ritel_gt;
            break;
          default:
            break;
        }
      } else {
        // console.log('if 10');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_ritel_gt;
            break;
          default:
            break;
        }
      }
    }

    try {
      let response;
      if (half) {
        console.log("masuk Half");
        response = await axios.post(
          `${CONFIG.BASE_URL}/api/shopping-cart`,
          {
            product_id: item.id,
            brand_id: item.brand_id,
            satuan_online: item.satuan_online,
            konversi_sedang_ke_kecil: item.konversi_sedang_ke_kecil,
            qty_konversi: shoppingCartItems.qty * item.konversi_sedang_ke_kecil,
            qty: shoppingCartItems.qty,
            notes: shoppingCartItems.notes,
            price_apps: price ?? "0",
            half: 1,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      } else {
        console.log("masuk byasa");
        response = await axios.post(
          `${CONFIG.BASE_URL}/api/shopping-cart`,
          {
            product_id: item.id,
            brand_id: item.brand_id,
            satuan_online: item.satuan_online,
            konversi_sedang_ke_kecil: item.konversi_sedang_ke_kecil,
            qty_konversi: shoppingCartItems.qty * item.konversi_sedang_ke_kecil,
            qty: shoppingCartItems.qty,
            notes: shoppingCartItems.notes,
            price_apps: price ?? "0",
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      }
      const data = response.data;
      if (data !== "" && data["success"] == true) {
        setOpenShopping(true);
        setQty_cart((parseInt(qty_cart) + 1).toString());
      } else if (data["message"] == "Anda Masuk Dalam Daftar Blacklist") {
        setOpenDialog(true);
        setMessage(data.message);
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  async function postWishlist(item) {
    const brand_id_1 = ["001"];
    const brand_id_2 = [
      "002",
      "003",
      "004",
      "007",
      "008",
      "009",
      "010",
      "011",
      "012",
      "013",
      "014",
    ];
    const brand_id_3 = ["005"];

    // console.log('price=====>',item)
    let price = item.price.harga_ritel_gt ?? "0";
    if (brand_id_1.includes(item.brand_id)) {
      if (item.status_promosi_coret == 1) {
        // console.log('if 1');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          default:
            break;
        }
      } else if (
        (item.status_promosi_coret !== "0" ||
          item.status_promosi_coret !== null) &&
        item.status_herbana !== "1"
      ) {
        // console.log('if 2');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_ritel_gt;
            break;
          default:
            break;
        }
      } else if (item.status_herbana === "1") {
        // console.log('if 3');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_grosir_mt;
            break;
          default:
            break;
        }
      } else {
        // console.log('if 4');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_ritel_gt;
            break;
          default:
            break;
        }
      }
    } else if (brand_id_2.includes(item.brand_id)) {
      if (item.status_promosi_coret == 1) {
        // console.log('if 5');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          default:
            break;
        }
      } else if (
        item.status_promosi_coret !== "0" ||
        item.status_promosi_coret !== null
      ) {
        // console.log('if 6');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_grosir_mt;
            break;
          default:
            break;
        }
      } else {
        // console.log('if 7');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_grosir_mt;
            break;
          default:
            break;
        }
      }
    } else if (brand_id_3.includes(item.brand_id)) {
      if (item.status_promosi_coret == 1) {
        // console.log('if 8');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          default:
            break;
        }
      } else if (
        item.status_promosi_coret !== "0" ||
        item.status_promosi_coret !== null
      ) {
        // console.log('if 9');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_ritel_gt;
            break;
          default:
            break;
        }
      } else {
        // console.log('if 10');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_ritel_gt;
            break;
          default:
            break;
        }
      }
    }

    console.log("price_apps", price);

    try {
      let response;
      if (half) {
        response = await axios.post(
          `${CONFIG.BASE_URL}/api/wishlist`,
          {
            product_id: item.id,
            price_apps: price ?? "0",
            half: 1,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const data = response.data;
        console.log("data whistlist", data);
        if (data !== "" && data["success"] == true) {
          // navigate('Wishlist');
        } else {
          console.log("Gagal memasukan wishlist===>", data);
        }
      } else {
        response = await axios.post(
          `${CONFIG.BASE_URL}/api/wishlist`,
          {
            product_id: item.id,
            price_apps: price ?? "0",
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const data = response.data;
        console.log("data whistlist", data);
        if (data !== "" && data["success"] == true) {
          navigate("/wishlist");
        } else {
          console.log("Gagal memasukan wishlist===>", data);
        }
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  const handleClickOpenShopping = () => {
    setOpenShopping(true);
  };

  const handleCloseShopping = (e) => {
    setOpenShopping(false);
    if (e == "ok") {
      navigate("/keranjang");
    }
  };

  async function getDiskusi() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/products/discussion/${produkDesc.id}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      console.log("data diskusi===", JSON.stringify(data));
      setDiskusi(data);
      setLoadingApi(false);
    } catch (error) {
      setLoadingApi(false);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  async function getProdukRecomen() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/recomen/products`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data.data;
      // const dataTotal = response.data.data.total;
      setRecomenProduct(data);
      setLoadingApi(false);
    } catch (error) {
      setLoadingApi(false);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  async function postRecent(item) {
    try {
      let response = await axios.post(
        `${CONFIG.BASE_URL}/api/recent/products`,
        {
          product_id: item.id,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error==========POST RECENT==============",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }
  function navigateTo() {
    if (paramHome !== null) {
      navigate("/home?parameter=" + paramHome);
    } else if (paramProd) {
      navigate("/produk?parameter=" + paramProd);
    } else {
      navigate(-1);
    }
  }
  const handleCloseAlertBlacklist = () => {
    setOpenDialog(false);
  };
  function onClickVarian(idVarian) {
    setLoadingApi(true);
    navigate("/produk/deskripsi?idProduct=" + idVarian, { replace: true });
    getDataDetail(idVarian);
  }

  return (
    <div className="homeContainer">
      <ModalShopping
        handleCloseShopping={handleCloseShopping}
        openShopping={openShopping}
      />
      <AlertBlacklist
        alertData={message}
        alertJudul="Pemberitahuan"
        handleClose={handleCloseAlertBlacklist}
        open={openDialog}
      />
      <Header
        notifMessages={notifMessages}
        notifSubscribe={notifSubscribe}
        notifOrders={notifOrders}
        notifComplaints={notifComplaints}
        notifBroadcast={notifBroadcast}
        countNotifCart={countNotifCart}
        back={true}
        navigateTo={navigateTo}
        titleHeader="Produk Detail"
        item={false}
      />
      <div className="produkDeskripsiContent">
        {produkDesc?.product_image?.length > 0 ? (
          <div className="produkDeskripsiDistributorImageContainer">
            <Carousel
              showArrows={true}
              showStatus={true}
              showIndicators={false}
              infiniteLoop={true}
              showThumbs={false}
              useKeyboardArrows={true}
              autoPlay={false}
              stopOnHover={false}
              swipeable={true}
              dynamicHeight={false}
              emulateTouch={false}
              autoFocus={false}
              interval={5000}
              swipeScrollTolerance={5}
            >
              {produkDesc?.product_image?.map((item, index) => {
                return (
                  <img
                    key={index}
                    src={CONFIG.BASE_URL + item.path}
                    alt="deskripsiImage"
                    className="produkDeskripsiDistributorDeskripsiImage"
                  />
                );
              })}
            </Carousel>
          </div>
        ) : (
          // <div className="produkDeskripsiDistributorImageContainer">
          <img
            src={DummyImage}
            alt="deskripsiImage"
            className="produkDeskripsiDistributorDeskripsiImage"
          />
          // </div>
        )}
        {varian?.length != 0 ? (
          <div className="varian-deskripsi-produk">Semua varian</div>
        ) : null}
        <div className="varian-horizontal-scroll">
          {varian?.length != 0
            ? varian?.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      onClickVarian(item?.id);
                    }}
                  >
                    {item?.product_image?.length != 0 ? (
                      <img
                        src={CONFIG.BASE_URL + item?.product_image[0]?.path}
                        className="varian-images-content"
                      />
                    ) : item?.product_image?.length == 0 ? (
                      <img src={DummyImage} className="varian-images-content" />
                    ) : (
                      <img
                        src={CONFIG.BASE_URL + item?.image}
                        className="varian-images-content"
                      />
                    )}
                  </div>
                );
              })
            : null}
        </div>
        <div className="deskripsiInfoContainer">
          <div className="deskripsiInfoTopContainer">
            {produkDesc?.status_promosi_coret == 1 ? (
              <NumberFormat
                value={
                  Math.round(
                    produkDesc?.price?.harga_promosi_coret_ritel_gt ||
                      produkDesc?.price?.harga_promosi_coret_grosir_mt ||
                      produkDesc?.price?.harga_promosi_coret_semi_grosir
                  ) ?? "0"
                }
                displayType={"text"}
                thousandSeparator={true}
                prefix={"Rp "}
                renderText={(value) => (
                  <p className="deskripsiPrice">{value || 0}</p>
                )}
              />
            ) : (
              <NumberFormat
                value={Math.round(produkDesc?.price?.harga_ritel_gt) ?? "0"}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"Rp "}
                renderText={(value) => (
                  <p className="deskripsiPrice">{value || 0}</p>
                )}
              />
            )}
            {produkDesc?.promo_sku?.length > 0 && (
              <div className="deskripsiViewPromo">
                <div className="deskripsiPromo">
                  <p className="deskripsiPromoText">Promo</p>
                </div>
                <img
                  src={IconInfo}
                  alt="deskripsiInfoImage"
                  className="deskripsiInfoImage"
                  onClick={getPromo.bind(
                    this,
                    produkDesc?.promo_sku[0]?.promo_id
                  )}
                />
              </div>
            )}
          </div>
          <div className="deskripsiInfoCenterContainer">
            <p className="deskripsiProductName">{produkDesc.name}</p>
          </div>
          <div className="deskripsiInfoBottomContainer">
            <button
              onClick={postWishlist.bind(this, produkDesc)}
              className="deskripsiWishlistContainer"
            >
              <img
                src={IconLove}
                alt="deskripsiLoveImage"
                className="deskripsiLoveImage"
              />
            </button>
            {avgRating[0]?.avg_star > 0 ? (
              <button
                onClick={() => navigate("/produk/rating")}
                className="deskripsiWishlistContainer"
              >
                <ReactStars
                  count={1}
                  size={24}
                  edit={false}
                  color={"#ffd700"}
                  classNames="deskripsiRating"
                />
                <p className="deskripsiRatingText">{avgRating[0]?.avg_star}</p>
              </button>
            ) : (
              <button className="deskripsiWishlistContainer">
                <ReactStars
                  count={1}
                  size={24}
                  edit={false}
                  color={"#ffd700"}
                  classNames="deskripsiRating"
                />
                <p className="deskripsiRatingText">0</p>
              </button>
            )}
          </div>
          {produkDesc.distributor ? (
            <div
              onClick={() => navigate("/produk/profile")}
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  width: "90%",
                  backgroundColor: "#529F45",
                  justifyContent: "center",
                  display: "flex",
                  padding: "5px 0px",
                  borderRadius: "5px",
                }}
              >
                <p className="produkDeskripsiStorePartnerName">
                  {produkDesc.distributor.shop_name
                    ? produkDesc.distributor.shop_name
                    : produkDesc.distributor.name}
                </p>
              </div>
            </div>
          ) : null}
        </div>
        <hr className="hr" style={{ margin: "auto", marginTop: 10 }}></hr>
        <div className="deskripsiDetailContainer">
          <p className="deskripsiDetailTitle">Detail Produk</p>
          <hr style={{ margin: "auto", marginTop: 10, width: "100%" }}></hr>
          <div className="deskripsiDetailTextContainer">
            <p className="deskripsiDetailText">Min. Pesanan</p>
            <p className="deskripsiDetailData">: 1 {produkDesc.small_unit}</p>
          </div>
          <hr style={{ margin: "auto", marginTop: 10, width: "100%" }}></hr>
          <div className="deskripsiDetailTextContainer">
            <p className="deskripsiDetailText">SKU</p>
            <p className="deskripsiDetailData">: {produkDesc.kodeprod}</p>
          </div>
          <hr style={{ margin: "auto", marginTop: 10, width: "100%" }}></hr>
          <div className="deskripsiDetailTextContainer">
            <p className="deskripsiDetailText">Satuan</p>
            <p className="deskripsiDetailData">: {produkDesc.satuan_online}</p>
          </div>
          <hr style={{ margin: "auto", marginTop: 10, width: "100%" }}></hr>
          {produkDesc.status_renceng ? (
            <div className="deskripsiDetailTextContainer">
              <p className="deskripsiDetailText">Satuan Renceng</p>
              <p className="deskripsiDetailData">
                :{" "}
                <select
                  onChange={(e) => {
                    if (e.target.value == "Setengah") {
                      setHalf(true);
                    } else {
                      setHalf(false);
                    }
                  }}
                  className="deskripsiSelect"
                >
                  {console.log(produkDesc)}
                  <option value={"Full"}>
                    Full ({produkDesc.konversi_sedang_ke_kecil}{" "}
                    {produkDesc.kecil.charAt(0) +
                      produkDesc.kecil.slice(1).toLowerCase()}
                    )
                  </option>
                  <option value={"Setengah"}>
                    Setengah ({produkDesc.konversi_sedang_ke_kecil / 2}{" "}
                    {produkDesc.kecil.charAt(0) +
                      produkDesc.kecil.slice(1).toLowerCase()}
                    )
                  </option>
                </select>
              </p>
            </div>
          ) : null}
          <div className="deskripsiDetailInfoContainer">
            <p className="deskripsiDetailInfoText">
              {produkDesc &&
                produkDesc.description &&
                produkDesc.description.replace(/\\n/g, "\n")}
            </p>
          </div>
        </div>
        <hr className="hr" style={{ margin: "auto", marginTop: 10 }}></hr>
        {diskusi.length != 0 ? (
          <div className="notifProductProdukDeskripsi">
            <p className="notifProductTitle">Diskusi Paling Membantu</p>
            <div
              onClick={() => {
                navigate("/diskusi/input", {
                  state: { diskusi: diskusi[0] },
                });
              }}
              className="produkDeskripsiDistributorDiskusiContainerMessage"
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "15px 0px",
                }}
              >
                <div className="produkDeskripsiDistributorDiskusi">
                  <img
                    alt="produkDeskripsiDistributorDiskusiImage"
                    src={CONFIG.BASE_URL + diskusi[0]?.photo}
                  />
                </div>
                <p className="produkDeskripsiDistributorDiskusiNamaUser">
                  {diskusi[0]?.name}
                </p>
                <p className="produkDeskripsiDistributorDiskusiTanggal">
                  {moment(diskusi[0]?.created_at).format("MMM YYYY")}
                </p>
              </div>
              <div>
                <p className="produkDeskripsiDistributorDiskusiMessage">
                  {diskusi[0]?.message}
                </p>
              </div>
              {diskusi[0]?.reply?.length > 0 ? (
                <div className="produkDeskripsiDistributorDiskusiContainerReply">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "15px 0px",
                    }}
                  >
                    <div className="produkDeskripsiDistributorDiskusi">
                      <img
                        alt="produkDeskripsiDistributorDiskusiImage"
                        src={CONFIG.BASE_URL + diskusi[0].reply[0].photo}
                      />
                    </div>
                    {diskusi[0]?.reply[0]?.admin_id ==
                    diskusi[0]?.reply[0]?.user_id ? (
                      <p className="produkDeskripsiDistributorDiskusiNamaToko">
                        {"Penjual"}
                      </p>
                    ) : (
                      <p className="produkDeskripsiDistributorDiskusiNamaUser">
                        {diskusi[0]?.reply[0].name}
                      </p>
                    )}
                    <p className="produkDeskripsiDistributorDiskusiTanggal">
                      {moment(diskusi[0]?.reply[0]?.created_at).format(
                        "MMM YYYY"
                      )}
                    </p>
                  </div>
                  <div>
                    <p className="produkDeskripsiDistributorDiskusiMessage">
                      {diskusi[0]?.reply[0]?.message}
                    </p>
                  </div>
                  {diskusi[0]?.reply?.length > 1 ? (
                    <div style={{ marginTop: "5px" }}>
                      <p className="produkDeskripsiDistributorDiskusiMessageLainnya">
                        {diskusi[0].reply.length - 1} jawaban lainnya
                      </p>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
            <div
              onClick={() => {
                navigate("/diskusi/input", {
                  state: { diskusi: diskusi[1] },
                });
              }}
              className="produkDeskripsiDistributorDiskusiContainerMessage"
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "15px 0px",
                }}
              >
                <div className="produkDeskripsiDistributorDiskusi">
                  <img
                    alt="produkDeskripsiDistributorDiskusiImage"
                    src={CONFIG.BASE_URL + diskusi[1]?.photo}
                  />
                </div>
                <p className="produkDeskripsiDistributorDiskusiNamaUser">
                  {diskusi[1]?.name}
                </p>
                <p className="produkDeskripsiDistributorDiskusiTanggal">
                  {moment(diskusi[1]?.created_at).format("MMM YYYY")}
                </p>
              </div>
              <div>
                <p className="produkDeskripsiDistributorDiskusiMessage">
                  {diskusi[1]?.message}
                </p>
              </div>
              {diskusi[1]?.reply?.length > 0 ? (
                <div className="produkDeskripsiDistributorDiskusiContainerReply">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "15px 0px",
                    }}
                  >
                    <div className="produkDeskripsiDistributorDiskusi">
                      <img
                        alt="produkDeskripsiDistributorDiskusiImage"
                        src={CONFIG.BASE_URL + diskusi[1].reply[0].photo}
                      />
                    </div>
                    {diskusi[1]?.reply[0]?.admin_id ==
                    diskusi[1]?.reply[0]?.user_id ? (
                      <p className="produkDeskripsiDistributorDiskusiNamaToko">
                        {"Penjual"}
                      </p>
                    ) : (
                      <p className="produkDeskripsiDistributorDiskusiNamaUser">
                        {diskusi[1]?.reply[0].name}
                      </p>
                    )}
                    <p className="produkDeskripsiDistributorDiskusiTanggal">
                      {moment(diskusi[1]?.reply[0]?.created_at).format(
                        "MMM YYYY"
                      )}
                    </p>
                  </div>
                  <div>
                    <p className="produkDeskripsiDistributorDiskusiMessage">
                      {diskusi[1]?.reply[0]?.message}
                    </p>
                  </div>
                  {diskusi[1]?.reply?.length > 1 ? (
                    <div style={{ marginTop: "5px" }}>
                      <p className="produkDeskripsiDistributorDiskusiMessageLainnya">
                        {diskusi[1].reply.length - 1} jawaban lainnya
                      </p>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
            <div
              onClick={() => {
                navigate("/diskusi?id=" + id, { state: { diskusi: diskusi } });
              }}
              style={{ margin: "5px 0px" }}
            >
              <p
                style={{ color: "#529F45" }}
                className="produkDeskripsiDistributorDiskusiMessageLainnya"
              >
                Lihat Semua
              </p>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              paddingBottom: "10vh",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0px 18px",
              }}
            >
              <p className="produkDeskripsiDistributorDiskusiMessage">
                {"Tidak ada diskusi saat ini"}
              </p>
            </div>
            <div style={{ width: "100px", height: "100px" }}>
              <img
                alt="produkDeskripsiDistributorDiskusiImage"
                style={{ width: "100%", height: "100%" }}
                src={IconDiskusi}
              />
            </div>
          </div>
        )}
        <hr className="hr"></hr>
        {loadingApi ? (
          <Loading loadingApi={loadingApi} />
        ) : (
          <div className="notifProductProdukDeskripsi">
            <p className="notifProductTitle">Produk Rekomendasi</p>
            <div className="notifProductContainer">
              {recomenProduct.map((item, index) => {
                return (
                  <div key={index} className="homeProductItem">
                    {item.promo_sku.length > 0 && (
                      <div
                        onClick={() => {
                          postRecent(item);
                          dispatch(produkAction(item, "produkDesc"));
                          navigate("/produk/deskripsi");
                        }}
                        className="homeProductItemPromo"
                      >
                        <p className="homeProductItemPromoText">Promo</p>
                      </div>
                    )}
                    {item.image ? (
                      <img
                        onClick={() => {
                          postRecent(item);
                          dispatch(produkAction(item, "produkDesc"));
                          navigate("/produk/deskripsi");
                        }}
                        src={CONFIG.BASE_URL + item.image}
                        alt="homeProductImage"
                        className="homeProductImage"
                      />
                    ) : (
                      <img
                        onClick={() => {
                          postRecent(item);
                          dispatch(produkAction(item, "produkDesc"));
                          navigate("/produk/deskripsi");
                        }}
                        src={DummyImage}
                        alt="homeProductImage"
                        className="homeProductImage"
                      />
                    )}
                    <p
                      onClick={() => {
                        postRecent(item);
                        dispatch(produkAction(item, "produkDesc"));
                        navigate("/produk/deskripsi");
                      }}
                      className="homeProductItemTitle"
                    >
                      {item.name}
                    </p>
                    {item.status_promosi_coret == 1 ? (
                      <NumberFormat
                        value={
                          Math.round(
                            item?.price?.harga_promosi_coret_ritel_gt ||
                              item?.price?.harga_promosi_coret_grosir_mt ||
                              item?.price?.harga_promosi_coret_semi_grosir
                          ) ?? "0"
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Rp "}
                        renderText={(value) => (
                          <p
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi");
                            }}
                            className="homeProductItemPrice"
                          >
                            {value || 0}
                          </p>
                        )}
                      />
                    ) : (
                      <NumberFormat
                        value={Math.round(item?.price?.harga_ritel_gt) ?? "0"}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Rp "}
                        renderText={(value) => (
                          <p
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi");
                            }}
                            className="homeProductItemPrice"
                          >
                            {value || 0}
                          </p>
                        )}
                      />
                    )}
                    <div
                      onClick={() => {
                        postRecent(item);
                        dispatch(produkAction(item, "produkDesc"));
                        navigate("/produk/deskripsi");
                      }}
                      className="homeProductItemView"
                    >
                      <div className="homeProductItemRating">
                        <ReactStars
                          count={1}
                          size={24}
                          edit={false}
                          color={"#ffd700"}
                        />
                        <p className="homeProductItemRatingValue">
                          {item.review
                            ? item.review[0]
                              ? item.review[0].avg_rating
                              : "0"
                            : "0"}
                        </p>
                      </div>
                      {item.cart && (
                        <div className="homeProductItemRating">
                          <img className="notifListIcon" src={IconCart} />
                          <p className="homeProductItemRatingValue">
                            {item.cart.qty}
                          </p>
                        </div>
                      )}
                    </div>
                    {item.cart ? (
                      <button
                        onClick={() => postShoppingCart(item)}
                        className="homeProductItemButton"
                      >
                        <p className="homeProductItemButtonBeli">Tambah</p>
                      </button>
                    ) : (
                      <button
                        onClick={() => postShoppingCart(item)}
                        className="homeProductItemButton"
                      >
                        <p className="homeProductItemButtonBeli">Beli</p>
                      </button>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div className="deskripsiNavbar">
        <div
          onClick={() =>
            navigate("/chat", {
              state: {
                partner_id: produkDesc.partner_id,
                item: produkDesc,
              },
            })
          }
          className="deskripsiChatPosition"
        >
          <button className="deskripsiChatButton">
            <img
              src={IconChat}
              alt="deskripsiChatImage"
              className="deskripsiChatImage"
            />
          </button>
        </div>
        <button
          onClick={() => navigate("/subscribe/edit?id=" + produkDesc.id)}
          className="deskripsiNavbarButtonLeft"
        >
          <p className="deskripsiNavbarTextLeft">Langganan</p>
        </button>
        <button
          onClick={() => {
            // showModal();
            postShoppingCart(produkDesc);
            getNotifAll();
          }}
          className="deskripsiNavbarButtonRight"
        >
          <p className="deskripsiNavbarTextRight">
            <img
              src={IconCart}
              alt="deskripsiNavbarImage"
              className="deskripsiNavbarImage"
              style={{ marginRight: 5, marginLeft: 5 }}
            />
            {qty_cart}
          </p>
          <p className="deskripsiNavbarTextRight" style={{ marginLeft: 10 }}>
            Masukkan Keranjang
          </p>
        </button>
      </div>
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProdukDeskripsi);
