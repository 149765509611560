import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import IconBag from "../assets/images/ShoppingBag.webp";
import IconComplaint from "../assets/images/Komplain.webp";
import IconBroadcast from "../assets/images/Broadcast.webp";
import "./NotificationList.css";
import axios from "axios";
import CONFIG from "../config/config";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { screenAction, orderAction, produkAction } from "../actions";
import InfiniteScroll from "react-infinite-scroll-component";
// import Moment from "react-moment";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import IconSubscribe from "../assets/images/Subscribe.webp";
import SearchComponent from "../pages/SearchList";
import error404 from "../assets/images/404.webp";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

function NoData() {
  return (
    <div className="NoData">
      <img style={{ height: "50%", width: "100%" }} src={error404} />
    </div>
  );
}

let idLocale = require("moment/locale/id");
moment.updateLocale("id", idLocale);

export const NotificationListBelanja = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const { screenName } = useSelector((state) => state.ScreenReducer);
  const [listAllOrder, setListAllOrder] = useState([]);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const [
    onEndReachedCalledDuringMomentum,
    setOnEndReachedCalledDuringMomentum,
  ] = useState(false);
  const [loadingApi, setLoadingApi] = useState(true);
  const [notifOrders, setNotifOrders] = useState(0);
  const [notifMessages, setNotifMessages] = useState(0);
  const [notifComplaints, setNotifComplaints] = useState(0);
  const [notifBroadcast, setNotifBroadcast] = useState(0);
  const [notifSubscribe, setNotifSubscribe] = useState(0);
  const [countNotifCart, setCountNotifCart] = useState(0);
  const [search, setSearch] = useState("");
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  useEffect(() => {
    getDetailNotif();
    getNotifAll();
  }, []);

  useEffect(() => {
    if (page) {
      getDetailNotif();
    }

    return () => {
      setOnEndReachedCalledDuringMomentum(false);
    };
  }, [page, loadingMore]);

  async function getDetailNotif() {
    console.log("masuk notif");
    console.log("page nh ajaaa", page);
    try {
      let response;
      if (screenName == "notifOrders") {
        response = await axios.get(
          `${CONFIG.BASE_URL}/api/orders/notifications?page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      } else if (screenName == "notifKomplain") {
        response = await axios.get(
          `${CONFIG.BASE_URL}/api/complaint/notifications?page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      } else if (screenName == "notifBroadcast") {
        response = await axios.get(
          `${CONFIG.BASE_URL}/api/broadcast/notification?page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      } else {
        response = await axios.get(
          `${CONFIG.BASE_URL}/api/subscribes/notifications?page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
          // {timeout: 1000},
        );
      }
      const data = response.data.data;
      console.log("DATA=======>", data);
      let allOrder = [];
      if (data.data != 0) {
        data.data.map((item, index) => {
          // console.log(JSON.parse(item.data_content));
          let parseData = JSON.parse(item.data_content);
          allOrder = [...allOrder, { ...item, parseData: parseData }];
          // allOrder = [...item, ...parseData];
          //   console.log("STRING", JSON.stringify(allOrder));
          if (index === data.data.length - 1) {
            console.log("masuk");
            setListAllOrder(
              page === 1 ? allOrder : [...listAllOrder, ...allOrder]
            );
            setLoadingMore(data.last_page > page);
            setMaxPage(data.last_page);
            setLoadingApi(false);
          }
        });
      } else {
        setLoadingApi(false);
      }
    } catch (error) {
      setLoadingApi(false);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error==========Detail Notif==============",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  //load more data
  async function getMoreData() {
    console.log("cekk", !onEndReachedCalledDuringMomentum);
    console.log("cekk", page);
    console.log("cekk", maxPage);
    if (!onEndReachedCalledDuringMomentum && page < maxPage) {
      console.log("masuk load");
      await setPage(page + 1);
      setLoadingMore(page < maxPage);
      console.log("ini ketiga", page, loadingMore);
    }
    setOnEndReachedCalledDuringMomentum(page >= maxPage);
  }

  function handleRefresh() {
    setPage(1);
    setRefreshing(true);
  }

  function handleLoadMore() {
    console.log("cekkkkk saiki", !loadingMore);
    if (!loadingMore) return null;
    return (
      <div>
        <CircularProgress color="success" />
      </div>
    );
  }

  async function getNotifAll() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/notification-all`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      // console.log('DATA TEST', data.total_chat);
      setNotifOrders(data.total_order);
      setNotifMessages(data.total_chat);
      setNotifComplaints(data.total_complaint);
      setNotifSubscribe(data.total_subscribe);
      setNotifBroadcast(data.total_broadcast);
      setCountNotifCart(data.total_cart);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========NOTIF================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  const simpanSearch = (e) => {
    setSearch(e.target.value);
  };

  async function getPutSeen(item) {
    console.log("item======================================", item.id);
    try {
      let response;
      if (screenName == "notifOrders") {
        response = await axios({
          method: "put",
          url: `${CONFIG.BASE_URL}/api/orders/notifications/${item.id}`,
          headers: {
            // 'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          // data: createFormData(this.state.photo, {
          //   title: this.state.title,
          //   content: this.state.detail,
          // }),
        });
        getDetails(item);
      } else if (screenName == "notifKomplain") {
        response = await axios({
          method: "put",
          url: `${CONFIG.BASE_URL}/api/complaint/notifications/${item.id}`,
          headers: {
            // 'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          // data: createFormData(this.state.photo, {
          //   title: this.state.title,
          //   content: this.state.detail,
          // }),
        });
        getDetails(item);
      } else if (screenName == "notifBroadcast") {
        response = await axios({
          method: "put",
          url: `${CONFIG.BASE_URL}/api/broadcast/notifications/${item.id}`,
          headers: {
            // 'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          // data: createFormData(this.state.photo, {
          //   title: this.state.title,
          //   content: this.state.detail,
          // }),
        });
        const data = response.data;
        console.log(data);
        if (data.data["success"] == "true") {
          setPage(1);
          getDetailNotif();
        }
        if(data["success"] == true) {
          const object = JSON.parse(data?.data?.data_content)
          if(object?.type == "cancel order"){
            
            navigate("/detail/transaksi/stok?id=" + object?.id);
          }
        }
      } else {
        response = await axios({
          method: "put",
          url: `${CONFIG.BASE_URL}/api/subscribes/notifications/${item.id}`,
          headers: {
            // 'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          // data: createFormData(this.state.photo, {
          //   title: this.state.title,
          //   content: this.state.detail,
          // }),
        });
        getDetails(item);
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      } else if (JSON.parse(JSON.stringify(error)).message == undefined) {
        navigate(-1);
      }
    }
  }

  async function getDetails(item) {
    console.log("item.parseData===========", item.parseData.id);
    // console.log(this.props.token);
    try {
      let response;
      if (screenName == "notifOrders") {
        response = await axios({
          method: "get",
          url: `${CONFIG.BASE_URL}/api/orders/detail/${item.parseData.id}`,
          headers: {
            // 'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          // data: createFormData(this.state.photo, {
          //   title: this.state.title,
          //   content: this.state.detail,
          // }),
        });
      } else if (screenName == "notifKomplain") {
        response = await axios({
          method: "get",
          url: `${CONFIG.BASE_URL}/api/complaint/detail/${item.parseData.id}`,
          headers: {
            // 'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          // data: createFormData(this.state.photo, {
          //   title: this.state.title,
          //   content: this.state.detail,
          // }),
        });
      } else {
        response = await axios({
          method: "get",
          url: `${CONFIG.BASE_URL}/api/subscribes/detail/${item.parseData.id}`,
          headers: {
            // 'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          // data: createFormData(this.state.photo, {
          //   title: this.state.title,
          //   content: this.state.detail,
          // }),
        });
      }
      const data = response.data;
      console.log("DATA NIHHH", JSON.stringify(data));
      if (data != 0 && data["success"] == true) {
        if (screenName == "notifOrders") {
          dispatch(orderAction(data.data, "orderDetail"));
          navigate("/pembayaran/detail?transaksi="+data.data.id);
        } else if (screenName == "notifKomplain") {
          dispatch(orderAction(data.data, "detailComplaint"));
          navigate("/complaint/detail");
        } else {
          // dispatch(produkAction(data.data, "produkDesc"));
          // navigate("/subscribe/detail");
          return;
        }
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  return (
    <div className="notifListContainer">
      <Header
        notifMessages={notifMessages}
        notifSubscribe={notifSubscribe}
        notifOrders={notifOrders}
        notifComplaints={notifComplaints}
        notifBroadcast={notifBroadcast}
        countNotifCart={countNotifCart}
        simpanSearch={simpanSearch}
        titleHeader="Detail notification"
      />
      {search.length > 0 && <SearchComponent search={search} />}
      {search.length == 0 && (
        <>
          {console.log("listAllOrder", listAllOrder)}
          {listAllOrder.length > 0 && !loadingApi ? (
            <InfiniteScroll
              dataLength={listAllOrder.length}
              next={() => getMoreData()}
              hasMore={true}
              loader={() => handleLoadMore()}
              // refreshFunction={handleRefresh}
              // pullDownToRefresh
              // pullDownToRefreshThreshold={50}
              // pullDownToRefreshContent={handleLoadMore}
            >
              <div className="notifListMain">
                {listAllOrder.map((item, index) => {
                  let title = "";
                  let desc = "";
                  if (screenName == "notifOrders") {
                    if (
                      item.activity === "new order" &&
                      item.parseData.payment_method === "transfer"
                    ) {
                      title = "Pesanan baru";
                      desc =
                        "Segera lakukan konfirmasi pembayaran agar pesananmu segera diproses";
                    } else if (item.activity === "new order from subscribe") {
                      title = "Pesanan baru";
                      desc =
                        "Ada pesanan baru dari daftar langganan kamu, Mohon menunggu konfirmasi dari kami untuk memproses pesanan";
                    } else if (item.activity === "new order") {
                      title = "Pesanan baru";
                      desc =
                        "Ada pesanan baru, Mohon menunggu konfirmasi dari kami untuk memproses pesanan";
                    } else if (item.activity === "order confirm") {
                      title = "Transaksi berhasil";
                      desc =
                        "Transaksi anda sudah terkonfirmasi, mohon menunggu proses selanjutnya ";
                    } else if (item.activity === "order process") {
                      title = "Pesanan terkirim";
                      desc =
                        'Pesanan anda telah dikirim, konfirmasi pesanan diterima dapat dilakukan apabila tombol "Selesai" telah tersedia';
                    } else if (item.activity === "delivery process") {
                      title = "Pesanan diproses";
                      desc =
                        " Pesanan telah diproses, akan dilakukan pengiriman sesuai tanggal pengiriman";
                    } else if (item.activity === "cod h") {
                      title = "Pesanan telah dikirim";
                      desc =
                        " Pesanan sedang diantar ke alamat tujuan dalam waktu perkiraan hari ini, mohon bersabar menunggu pesanan. Pastikan klik selesai jika pesanan anda telah diterima";
                    } else if (item.activity === "cod h-1") {
                      title = "Pesanan telah dikirim";
                      desc =
                        " Pesanan sedang diantar ke alamat tujuan dalam waktu perkiraan 2 hari, pastikan klik selesai jika pesanan anda telah diterima";
                    } else if (item.activity === "cod h-2") {
                      title = "Pesanan telah dikirim";
                      desc =
                        " Pesanan sedang diantar ke alamat tujuan dalam waktu perkiraan 3 hari, pastikan klik selesai jika pesanan anda telah diterima";
                    } else if (
                      item.activity ===
                      // 'complete order with id : ' + `${item.table_name}`
                      "order completed"
                    ) {
                      title = "Pesanan selesai";
                      desc =
                        "Berikan rating dan ulasan kamu di semutgajah serta dapatkan point pembelian untuk menukarkan kupon potongan harga";
                    } else if (item.activity === "order cancel") {
                      title = "Pesanan batal";
                      desc = "Pesanan kamu telah dibatalkan";
                    } else {
                      return;
                    }
                  } else if (screenName == "notifKomplain") {
                    if (item.activity === "repply complaint") {
                      title = "Balasan komplain";
                      desc =
                        "Komplain pada pesanan anda dengan nomor invoice " +
                        item.parseData.order.invoice +
                        " sudah dibalas";
                    } else if (item.activity === "confirmed complaint") {
                      title = "Komplain terkonfirmasi";
                      desc =
                        "Komplain pada pesanan anda dengan nomor invoice " +
                        item.parseData.order.invoice +
                        " sudah dikonfirmasi";
                    } else if (item.activity === "rejected complaint") {
                      title = "Komplain dibatalkan";
                      desc =
                        "Komplain pada pesanan anda dengan nomor invoice " +
                        item.parseData.order.invoice +
                        " dibatalkan";
                    } else if (item.activity === "seen") {
                      title = "Komplain dilihat dan diperiksa";
                      desc =
                        "Komplain pada pesanan anda dengan nomor invoice " +
                        item.parseData.order.invoice +
                        " dilihat dan diperiksa";
                    } else if (item.activity === "sended stuff") {
                      title = "Proses tukar barang";
                      desc =
                        "Komplain pada pesanan anda dengan nomor invoice " +
                        item.parseData.order.invoice +
                        " sudah diproses dan barang pengganti sudah dikirim";
                    } else if (item.activity === "sended credit") {
                      title = "Proses pengembalian dana";
                      desc =
                        "Komplain pada pesanan anda dengan nomor invoice " +
                        item.parseData.order.invoice +
                        " sudah diproses dan dana credit sudah bertambah pada akun anda";
                    } else {
                      return;
                    }
                  } else if (screenName == "notifBroadcast") {
                    title = item.parseData.title;
                    desc = item.parseData.message;
                  } else {
                    if (
                      item.activity === "subscribe h-2" &&
                      item.parseData.time == "2_week"
                    ) {
                      title = "Langganan";
                      desc =
                        "Langganan mingguan anda tinggal 2 hari lagi, mohon cek kembali bila ingin merubah status berlangganan";
                    } else if (
                      item.activity === "subscribe h-1" &&
                      item.parseData.time == "2_week"
                    ) {
                      title = "Langganan";
                      desc =
                        "Langganan mingguan anda tinggal 1 hari lagi, mohon cek kembali bila ingin merubah status berlangganan";
                    } else if (
                      item.activity === "subscribe h-2" &&
                      item.parseData.time == "month"
                    ) {
                      title = "Langganan";
                      desc =
                        "Langganan bulanan anda tinggal 2 hari lagi, mohon cek kembali bila ingin merubah status berlangganan";
                    } else if (
                      item.activity === "subscribe h-1" &&
                      item.parseData.time == "month"
                    ) {
                      title = "Langganan";
                      desc =
                        "Langganan bulanan anda tinggal 1 hari lagi, mohon cek kembali bila ingin merubah status berlangganan";
                    } else {
                      return;
                    }
                  }
                  return (
                    <div
                      key={index}
                      className={
                        item.user_seen == null
                          ? "notifListCard"
                          : "notifListCardWhite"
                      }
                      onClick={() => getPutSeen(item)}
                    >
                      {screenName == "notifOrders" ? (
                        <div className="notifListHeader">
                          <img
                            src={IconBag}
                            alt="notifListIcon"
                            className="notifListIcon"
                          />
                          <p className="invoice">
                            {"Belanja #" + item?.parseData?.invoice}
                          </p>
                          <div className="notifListRight">
                            {moment(item?.parseData?.order_time).format(
                              "YYYY/MM/DD"
                            )}
                          </div>
                        </div>
                      ) : screenName == "notifKomplain" ? (
                        <div className="notifListHeader">
                          <img
                            src={IconComplaint}
                            alt="notifListIcon"
                            className="notifListIcon"
                          />
                          {"Komplain #" + item?.parseData?.order?.invoice}
                          <div className="notifListRight">
                            {moment(item?.parseData?.order_time).format(
                              "YYYY/MM/DD"
                            )}
                          </div>
                        </div>
                      ) : screenName == "notifBroadcast" ? (
                        <div className="notifListHeader">
                          <img
                            src={IconBroadcast}
                            alt="notifListIcon"
                            className="notifListIcon"
                          />
                          {"Info dari Semut Gajah"}
                          <div className="notifListRight">
                            {moment(item?.parseData?.order_time).format(
                              "YYYY/MM/DD"
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="notifListHeader">
                          <img
                            src={IconSubscribe}
                            alt="notifListIcon"
                            className="notifListIcon"
                          />
                          {"Langganan"}
                          <div className="notifListRight">
                            {moment(item?.parseData?.order_time).format(
                              "YYYY/MM/DD"
                            )}
                          </div>
                        </div>
                      )}
                      <div className="notifListContent">
                        <div className="notifListContentHeader">{title}</div>
                        <div className="notifListContentMain">{desc}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </InfiniteScroll>
          ) : listAllOrder.length == 0 && !loadingApi ? (
            <NoData />
          ) : null}
        </>
      )}
      {/* <Navbar /> */}
      <Loading loadingApi={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

export default NotificationListBelanja;
