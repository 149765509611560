import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ProdukList.css";
import NavbarDistributor from "../components/NavbarDistributor";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import CircularProgress from "@mui/material/CircularProgress";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import Header from "../components/HeaderDistributor";
import NumberFormat from "react-number-format";
import { produkAction } from "../actions";
import error404 from "../assets/images/404.webp";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import ReactStars from "react-rating-stars-component";

function NoData() {
  return (
    <div className="NoData">
      <img style={{ height: "50%", width: "100%" }} src={error404} />
    </div>
  );
}

export const ProdukListDistributor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const [notifOrders, setNotifOrders] = useState(0);
  const [notifMessages, setNotifMessages] = useState(0);
  const [notifComplaints, setNotifComplaints] = useState(0);
  const [notifBroadcast, setNotifBroadcast] = useState(0);
  const [notifSubscribe, setNotifSubscribe] = useState(0);
  const [countNotifCart, setCountNotifCart] = useState(0);
  const [loadingApi, setLoadingApi] = useState(true);
  const [pilihStatus, setPilihStatus] = useState("");
  const [buttonChange, setButtonChange] = useState(false);
  const [buttonSemua, setButtonSemua] = useState(true);
  const [buttonTerbaru, setButtonTerbaru] = useState(false);
  const [buttonHerbal, setButtonHerbal] = useState(false);
  const [buttonSupMul, setButtonSupMul] = useState(false);
  const [buttonFoodBev, setButtonFoodBev] = useState(false);
  const [buttonMinyak, setButtonMinyak] = useState(false);
  const [produkList, setProdukList] = useState([]);
  const [
    onEndReachedCalledDuringMomentum,
    setOnEndReachedCalledDuringMomentum,
  ] = useState(false);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  useEffect(() => {
    getlistProduk();
    getNotifAll();
  }, []);

  useEffect(() => {
    if (page) {
      getlistProduk();
      getNotifAll();
    }

    return () => {
      setOnEndReachedCalledDuringMomentum(false);
    };
  }, [page, loadingMore]);

  async function getlistProduk(status = "") {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/distributor-partner/products?category_id=${status}&page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = response.data.data;
      setLoadingApi(false);
      setProdukList(page === 1 ? data.data : [...produkList, ...data.data]);
      setLoadingMore(data.last_page > page);
      setMaxPage(data.last_page);
      setRefreshing(false);
    } catch (error) {
      setLoadingApi(false);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  //status
  async function handleStatusChange(item) {
    setPilihStatus(item);
    setLoadingApi(true);
    setPage(1);
    getlistProduk(item);
  }

  async function handleViewChange() {
    setButtonChange(!buttonChange);
    setLoadingApi(true);
    setPage(1);
    getlistProduk();
  }

  async function getNotifAll() {
    console.log("cek hit notif");
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/notification-all`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      // console.log('DATA TEST', data.total_chat);
      setNotifOrders(data.total_order);
      setNotifMessages(data.total_chat);
      setNotifComplaints(data.total_complaint);
      setNotifSubscribe(data.total_subscribe);
      setNotifBroadcast(data.total_broadcast);
      setCountNotifCart(data.total_cart);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========NOTIF================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  //load more data
  async function getMoreData() {
    if (!onEndReachedCalledDuringMomentum && page < maxPage) {
      await setPage(page + 1);
      setLoadingMore(page < maxPage);
    }
    setOnEndReachedCalledDuringMomentum(page >= maxPage);
  }

  function handleRefresh() {
    setPage(1);
    setRefreshing(true);
    getlistProduk();
  }

  function handleLoadMore() {
    if (!loadingMore) return null;
    return (
      <div>
        <CircularProgress color="success" />
      </div>
    );
  }

  async function postRecent(item) {
    try {
      let response = await axios.post(
        `${CONFIG.BASE_URL}/api/recent/products`,
        {
          product_id: item.id,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      handleRefresh();
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error==========POST RECENT==============",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  const simpanSearch = (e) => {
    setSearch(e.target.value);
  };
  return (
    <div className="produkListContainer">
      <div className="header1">
        <Header
          notifMessages={notifMessages}
          notifSubscribe={notifSubscribe}
          notifOrders={notifOrders}
          notifComplaints={notifComplaints}
          notifBroadcast={notifBroadcast}
          countNotifCart={countNotifCart}
          simpanSearch={simpanSearch}
          titleHeader={"Produk"}
          badge={true}
          linkProfile={true}
          item={false}
        />
        <div className="headerContainer"></div>

        {search.length == 0 && (
          <div className="headerFilter">
            {buttonSemua ? (
              <button onClick={() => handleStatusChange()} className="active">
                <p className="text">Semua</p>
              </button>
            ) : (
              <button
                onClick={() => {
                  setButtonSemua(true);
                  setButtonHerbal(false);
                  setButtonSupMul(false);
                  setButtonFoodBev(false);
                  setButtonMinyak(false);
                  handleStatusChange();
                }}
              >
                <p className="text">Semua</p>
              </button>
            )}
            {buttonHerbal ? (
              <button
                onClick={() => handleStatusChange("1")}
                className="active"
              >
                <p className="text">Herbal</p>
              </button>
            ) : (
              <button
                onClick={() => {
                  setButtonSemua(false);
                  setButtonHerbal(true);
                  setButtonSupMul(false);
                  setButtonFoodBev(false);
                  setButtonMinyak(false);
                  handleStatusChange("1");
                }}
              >
                <p className="text">Herbal</p>
              </button>
            )}
            {buttonSupMul ? (
              <button
                style={{ width: "120px" }}
                onClick={() => handleStatusChange("2")}
                className="active"
              >
                <p className="text">Supplemen dan Vitamin</p>
              </button>
            ) : (
              <button
                onClick={() => {
                  setButtonSemua(false);
                  setButtonHerbal(false);
                  setButtonSupMul(true);
                  setButtonFoodBev(false);
                  setButtonMinyak(false);
                  handleStatusChange("2");
                }}
                style={{ width: "120px" }}
              >
                <p className="text">Supplemen dan Vitamin</p>
              </button>
            )}
            {buttonFoodBev ? (
              <button
                style={{ width: "120px" }}
                onClick={() => handleStatusChange("3")}
                className="active"
              >
                <p className="text">Makanan dan Minuman</p>
              </button>
            ) : (
              <button
                onClick={() => {
                  setButtonSemua(false);
                  setButtonHerbal(false);
                  setButtonSupMul(false);
                  setButtonFoodBev(true);
                  setButtonMinyak(false);
                  handleStatusChange("3");
                }}
                style={{ width: "120px" }}
              >
                <p className="text">Makanan dan Minuman</p>
              </button>
            )}
            {buttonMinyak ? (
              <button
                style={{ width: "120px" }}
                onClick={() => handleStatusChange("4")}
                className="active"
              >
                <p className="text">Minyak Angin dan Balsem</p>
              </button>
            ) : (
              <button
                onClick={() => {
                  setButtonSemua(false);
                  setButtonHerbal(false);
                  setButtonSupMul(false);
                  setButtonFoodBev(false);
                  setButtonMinyak(true);
                  handleStatusChange("4");
                }}
                style={{ width: "120px" }}
              >
                <p className="text">Minyak Angin dan Balsem</p>
              </button>
            )}
          </div>
        )}
      </div>
      {search.length == 0 && (
        <>
          {produkList.length > 0 && !loadingApi ? (
            <InfiniteScroll
              dataLength={produkList.length}
              next={() => getMoreData()}
              style={{ paddingTop: "7vh" }}
              hasMore={true}
              loader={() => handleLoadMore()}
            >
              {produkList.map((item, index) => {
                return (
                  <div key={index} className="content">
                    <a
                      onClick={() => {
                        postRecent.bind(this, item);
                        navigate("/produk/deskripsi/distributor?id=" + item.id);
                        dispatch(produkAction(item, "produkDesc"));
                      }}
                    >
                      <div className="product">
                        {item.product_image.length > 0 ? (
                          <img
                            src={CONFIG.BASE_URL + item.product_image[0].path}
                            alt="transactionContentImage"
                            className="image"
                          />
                        ) : (
                          <img
                            src={DummyImage}
                            alt="transactionContentImage"
                            className="image"
                          />
                        )}
                        <div className="desc">
                          <div className="name">{item.name}</div>
                          {item.status_promosi_coret == 1 ? (
                            <NumberFormat
                              value={
                                Math.round(
                                  item?.price?.harga_promosi_coret_ritel_gt ||
                                    item?.price
                                      ?.harga_promosi_coret_grosir_mt ||
                                    item?.price?.harga_promosi_coret_semi_grosir
                                ) ?? "0"
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"Rp "}
                              renderText={(value) => (
                                <div className="price">{value || 0}</div>
                              )}
                            />
                          ) : (
                            <NumberFormat
                              value={
                                Math.round(item?.price?.harga_ritel_gt) ?? "0"
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"Rp "}
                              renderText={(value) => (
                                <div className="price">{value || 0}</div>
                              )}
                            />
                          )}
                          <ReactStars
                            size={20}
                            value={parseInt(item.review[0]?.avg_rating) ||0}
                            edit={false}
                          />
                        </div>
                      </div>
                    </a>
                  </div>
                );
              })}
            </InfiniteScroll>
          ) : produkList.length == 0 && !loadingApi ? (
            <NoData />
          ) : null}
        </>
      )}
      <NavbarDistributor screenName={"Produk"} />
      <Loading loadingApi={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

export default ProdukListDistributor;
