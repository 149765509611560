import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import "./SubscribeDetail.css";
import Product1 from "../assets/dummy/product1.jpg";
import IconNotifAll from "../assets/images/NotifAll.webp";
import IconShoppingCart from "../assets/images/Shopping-Cart.webp";
import IconMenu from "../assets/images/Menu.webp";
import IconArrow from "../assets/images/backArrow.webp";
import IconChat from "../assets/images/ChatThumbnail.webp";
import { useSelector } from "react-redux";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import { screenAction, paramsAction } from "../actions";
import { useDispatch } from "react-redux";
import moment from "moment";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import Header from "../components/HeaderDistributor";
import Loading from "../components/Loading";

export const SubscribeDetail = (props) => {
  const queryParams = new URLSearchParams(window.location.search);
  const id  = queryParams.get("id")
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [notifOrders, setNotifOrders] = useState(0);
  const [notifMessages, setNotifMessages] = useState(0);
  const [notifComplaints, setNotifComplaints] = useState(0);
  const [notifBroadcast, setNotifBroadcast] = useState(0);
  const [notifSubscribe, setNotifSubscribe] = useState(0);
  const [countNotifCart, setCountNotifCart] = useState(0);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);
  const [produkDesc, setProdukDesc] = useState({});
  const [loadingApi, setLoadingApi] = useState(true);


  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  useEffect(() => {
    // getNotifAll();
    getDataSubscribe()

    return () => {};
  }, []);

  async function getDataSubscribe() {
    try {
      let response;
        response = await axios.get(
          `${CONFIG.BASE_URL}/api/subscribes/detail/${parseInt(id)}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      const data = response.data.data;
      setProdukDesc(data)
      setLoadingApi(false)
    } catch (error) {
      setLoadingApi(false)
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }
  
  async function getNotifAll() {
    console.log("cek hit notif");
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/notification-all`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      // console.log('DATA TEST', data.total_chat);
      setNotifOrders(data.total_order);
      setNotifMessages(data.total_chat);
      setNotifComplaints(data.total_complaint);
      setNotifSubscribe(data.total_subscribe);
      setNotifBroadcast(data.total_broadcast);
      setCountNotifCart(data.total_cart);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========NOTIF================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  function renderCountNotificationBadge() {
    try {
      let count = 0;
      let hasil = notifMessages;
      let hasil2 = notifSubscribe;
      let hasil3 = notifOrders;
      let hasil4 = notifComplaints;
      let hasil5 = notifBroadcast;
      count =
        count +
        parseInt(hasil) +
        parseInt(hasil2) +
        parseInt(hasil3) +
        parseInt(hasil4) +
        parseInt(hasil5);
      // console.log(count);
      if (count > 0) {
        return count;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="subscribeDetailContainer">
      <Header 
      titleHeader="Detail Langganan"
      />
      <div className="subscribeDetailContent">
        <div className="subscribeDetailImageContainer">
          {produkDesc.product?.image ? (
            <img
              src={CONFIG.BASE_URL + produkDesc.product.image}
              alt="subscribeDetailImage"
              className="subscribeDetailImage"
            />
          ) : (
            <img
              src={DummyImage}
              alt="subscribeDetailImage"
              className="subscribeDetailImage"
            />
          )}
          <div className="subscribeDetailChatPosition">
            <button
              onClick={() => navigate("/chat")}
              className="subscribeDetailChatButton"
            >
              <img
                src={IconChat}
                alt="subscribeDetailChatImage"
                className="subscribeDetailChatImage"
              />
            </button>
          </div>
        </div>
        <div className="subscribeDetailDetailContainer">
          <p className="subscribeDetailDetailTitle">Langganan</p>
          <div className="subscribeDetailDetailTextContainer">
            <p className="subscribeDetailDetailText">Produk yang dipesan</p>
            <p className="subscribeDetailDetailDataKonjungsi">:</p>
            <p className="subscribeDetailDetailData">
              {produkDesc.product?.name}
            </p>
          </div>
          <div className="subscribeDetailDetailTextContainer">
            <p className="subscribeDetailDetailTextJumlah">Jumlah</p>
            <p className="subscribeDetailDetailDataKonjungsi">:</p>
            <p className="subscribeDetailDetailData">{produkDesc.qty}</p>
          </div>
          <div className="subscribeDetailDetailTextContainer">
            <p className="subscribeDetailDetailText">Waktu</p>
            <p className="subscribeDetailDetailDataKonjungsi">:</p>
            {produkDesc.time == "month" ? (
              <p className="subscribeDetailDetailData">Per bulan</p>
            ) : (
              <p className="subscribeDetailDetailData">Per 2 minggu</p>
            )}
          </div>
          <div className="subscribeDetailDetailTextContainer">
            <p className="subscribeDetailDetailText">Waktu Langganan</p>
            <p className="subscribeDetailDetailDataKonjungsi">:</p>
            <p className="subscribeDetailDetailData">
              {moment(produkDesc.start_at).format("DD MMM YYYY")}
            </p>
          </div>
          {produkDesc.product?.status_renceng ? (
            <div className="subscribeDetailDetailTextContainer">
              <p className="subscribeDetailDetailText">Satuan Renceng</p>
              <p className="subscribeDetailDetailDataKonjungsi">:</p>
              {produkDesc.half == 1 ? (
                <p className="subscribeDetailDetailData">
                  {"Setengah (" +
                    produkDesc.product.konversi_sedang_ke_kecil / 2 +
                    " " +
                    produkDesc.product.kecil.charAt(0) +
                    produkDesc.product.kecil.slice(1).toLowerCase() +
                    " )"}
                </p>
              ) : (
                <p className="subscribeDetailDetailData">
                  {"Full (" +
                    produkDesc.product.konversi_sedang_ke_kecil +
                    " " +
                    produkDesc.product.kecil.charAt(0) +
                    produkDesc.product.kecil.slice(1).toLowerCase() +
                    " )"}
                </p>
              )}
            </div>
          ) : null}
          <div className="subscribeDetailDetailTextContainer">
            <p className="subscribeDetailDetailText">Status</p>
            <p className="subscribeDetailDetailDataKonjungsi">:</p>
            {produkDesc.status == 1 ? (
              <p className="subscribeDetailDetailData">Aktif</p>
            ) : (
              <p className="subscribeDetailDetailData">Tidak Aktif</p>
            )}
          </div>
          <div className="subscribeDetailDetailTextContainer">
            <p className="subscribeDetailDetailText">Catatan</p>
            <p className="subscribeDetailDetailDataKonjungsi">:</p>
            <p className="subscribeDetailDetailData">{produkDesc.notes}</p>
          </div>
        </div>
        <div className="subscribeDetailBottom">
          <button
            onClick={() => {
              dispatch(screenAction("Edit Subscribe", "screenName"));
              navigate("/subscribe/edit?id="+produkDesc.id+"&screen=Edit Subscribe");
            }}
            className="btnUbah"
          >
            <p className="text">Ubah</p>
          </button>

          <button
            onClick={() => {
              dispatch(screenAction("produk", "screenName"));
              navigate("/produk");
            }}
            className="btnChange"
          >
            <p className="text">Cari barang lainnya</p>
          </button>
        </div>
      </div>
      <Loading loadingApi={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SubscribeDetail);
