import React from "react";
import "./SuccessLogin.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Modal from "@mui/material/Modal";
import "./SuccessLogin.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SuccessLogin({
  open,
  handleClose,
  alertJudul,
  titleAlert,
  titleDescription,
}) {
  return (
    <Modal
      open={open}
      keepMounted
      TransitionComponent={Transition}
      className="success-alert-images-dialog"
    >
      <div className="div-alert-success-content">
        {/* <img  onClick={(e) => handleClose((e = "ok"))} src={IconCLose} className="div-alert-custom-icon-close" /> */}
        {/* <img src={IconLonceng} className="div-alert-custom-icon-icon" /> */}
        <div className="div-alert-success">{titleAlert || "PEMBERITAHUAN"}</div>
        <div className="div-alert-success-desc">
          {titleDescription || " "}
        </div>
        <div className="div-btn-success">
          <div
            className="btn-close-success"
            onClick={(e) => handleClose((e = "ok"))}
          >
            {"OK"}
          </div>
        </div>
      </div>
    </Modal>
  );
}
