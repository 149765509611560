import React, { useEffect, useState } from "react";
import Header from "../components/HeaderDistributor";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import CONFIG from "../config/config";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import error404 from "../assets/images/404.webp";
import DummyImage from "../assets/images/Logo.webp";
import moment from "moment";
import NumberFormat from "react-number-format";
import "./DistributorComplaintDetail.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { yellow } from "@mui/material/colors";

export const DistributorComplaintDetail = () => {
  const navigate = useNavigate();
  const [loadingApi, setLoadingApi] = useState(true);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);
  const [notifOrders, setNotifOrders] = useState(0);
  const [notifMessages, setNotifMessages] = useState(0);
  const [notifComplaints, setNotifComplaints] = useState(0);
  const [notifBroadcast, setNotifBroadcast] = useState(0);
  const [notifSubscribe, setNotifSubscribe] = useState(0);
  const [countNotifCart, setCountNotifCart] = useState(0);
  const [dataDetail, setDataDetail] = useState({});
  const [complaintDetail, setComplaintDetail] = useState([]);
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("idComp");

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  function Loading(props) {
    return props.visible ? (
      <div className="loading">
        <CircularProgress color="success" />
      </div>
    ) : null;
  }

  useEffect(() => {
    getNotifAll();
    getComplaint();
  }, []);

  async function getNotifAll() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/notification-all`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      setNotifOrders(data.total_order);
      setNotifMessages(data.total_chat);
      setNotifComplaints(data.total_complaint);
      setNotifSubscribe(data.total_subscribe);
      setNotifBroadcast(data.total_broadcast);
      setCountNotifCart(data.total_cart);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========NOTIF================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }
  async function getComplaint() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/distributor-partner/complaint`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = response.data.data.data.find(
        (arr) => arr.id === parseInt(id)
      );
      setDataDetail(data);
      setComplaintDetail(data.complaint_detail);
      setLoadingApi(false);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========NOTIF================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }
  async function postTolakComplaint() {
    try {
      let response = await axios({
        method: "POST",
        url: `${CONFIG.BASE_URL}/api/distributor-partner/complaint/reject/${id}`,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      navigate(-1);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========NOTIF================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }
  async function postTerimaComplaint() {
    try {
      let response = await axios({
        method: "POST",
        url: `${CONFIG.BASE_URL}/api/distributor-partner/complaint/confirm/${id}`,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      navigate(-1);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========NOTIF================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }
  return (
    <div className="containerDetailComplaint">
      <div className="headerDetailComplaint">
        <Header
          // notifMessages={notifMessages}
          // notifSubscribe={notifSubscribe}
          // notifOrders={notifOrders}
          // notifComplaints={notifComplaints}
          // notifBroadcast={notifBroadcast}
          // countNotifCart={countNotifCart}
          // simpanSearch={simpanSearch}
          titleHeader="Detail Komplaint Pesanan"
          //   badge={true}
          //   linkProfile={true}
          item={false}
        />
      </div>
      <div className="contentDetailComplaint">
        <div className="">
          <div className="contentDateDetail">
            {moment(dataDetail.created_at).format("DD MMM YYYY HH:mm")}
          </div>
          <div className="contentCardDetail">
            {dataDetail?.order?.order_details[0].product.image != null ? (
              <img
                className="viewImgUlasan"
                src={
                  CONFIG.BASE_URL + dataDetail?.order?.order_details[0].product
                }
              />
            ) : (
              <img className="contentImgDetail" src={DummyImage} />
            )}

            <div className="contentTextDetail">
              <div className="viewInvoice">
                <div className="titleInvoice">{"invoice : "}</div>
                <div className="invoiceNumber">
                  {dataDetail?.order?.invoice || ""}
                </div>
              </div>
              <div className="nameTextDetail">
                {dataDetail?.order?.order_details[0].product.invoice_name || ""}
              </div>
              <div className="viewPriceDetail">
                <div className="priceTextDetail">
                  <NumberFormat
                    value={
                      Math.round(
                        dataDetail?.order?.order_details[0].total_price
                      ) ?? "0"
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"Rp "}
                    renderText={(value) => (
                      <div className="priceText">{value || "0"}</div>
                    )}
                  />
                </div>
                <div className="produkDetail">
                  + {dataDetail?.order?.order_details.length - 1} produk lainnya
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="viewContent">
          {complaintDetail.length > 0 && !loadingApi ? (
            <InfiniteScroll dataLength={complaintDetail.length}>
              {complaintDetail.map((item, index) => {
                return (
                  <div key={index} className="viewCompaintDetail">
                    {" "}
                    <div
                      style={{
                        marginTop: "4%",
                        background: "#7d7d7d",
                        height: "1px",
                        width: "100%",
                      }}
                    />
                    <div className="detailComplaint">
                      <div className="textKiri">
                        <div className="textIsi">Status komplain</div>
                        <div className="textIsi">Judul</div>
                        <div className="textIsi">Jumlah</div>
                        <div className="textIsi">Pilihan pengembalian</div>
                      </div>
                      <div className="textKanan">
                        <div className="textIsi">
                          {dataDetail.status || "Belum dibalas"}
                        </div>
                        <div className="textIsi">{item.title || ""}</div>
                        <div className="textIsi">{dataDetail.qty || ""}</div>
                        <div className="textIsi">{dataDetail.option || ""}</div>
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: "1%",
                        background: "#7d7d7d",
                        height: "1px",
                        width: "100%",
                      }}
                    />
                    <div className="lampiran">
                      <div className="textKiri">{"Lampiran (opsional)"}</div>
                      {item.file_1 === null ? (
                        <div className="textLampiran">Lampiran kosong</div>
                      ) : (
                        <img
                          className="opsionalImg"
                          src={CONFIG.BASE_URL + item.file_1 || DummyImage}
                        />
                      )}
                    </div>
                    <div
                      style={{
                        marginTop: "2%",
                        background: "#7d7d7d",
                        height: "1px",
                        width: "100%",
                      }}
                    />
                    <div className="viewPenjelasan">
                      <div className="textKiri">penjelasan</div>
                      <div className="isiPenjelasan">{item.content}</div>
                    </div>
                  </div>
                );
              })}
            </InfiniteScroll>
          ) : null}
        </div>
        <div
          style={{
            marginTop: "1%",
            background: "#7d7d7d",
            height: "1px",
            width: "100%",
          }}
        />
        <div className="btnDetailcomplaint">
          <div
            className="btnTolak"
            onClick={() => {
              postTolakComplaint();
            }}
          >
            Tolak complaint
          </div>

          <div
            className="btnBalas"
            onClick={() => {
              navigate(
                "/menu/distributor/complaint/balas?idComp=" + dataDetail.id
              );
            }}
          >
            Balas Komplaint
          </div>

          {dataDetail.status === "confirmed" ? (
            <div
              className="btnTerima"
              onClick={() => {
                postTerimaComplaint();
              }}
            >
              Terima Komplaint
            </div>
          ) : null}
        </div>
      </div>
      <Loading visible={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};
export default DistributorComplaintDetail;
