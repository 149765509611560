import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/images/IconLogo.webp";
import IconUser from "../assets/images/User.webp";
import IconToko from "../assets/images/Toko.webp";
import "./RegisterOld.css";
import axios from "axios";
import CONFIG from "../config/config";
import { useDispatch } from "react-redux";
import { userAction, screenAction } from "../actions";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

export const RegisterOld = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [code, setCode] = useState("");
  const [approval, setApproval] = useState("");
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            // setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            // setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  async function getRegisterUserLama() {
    console.log("cekkkk", code, approval);
    if (!code.trim()) {
      // this.setState({
      //   alertData: 'Pastikan nama tidak ada yang kosong',
      //   modalAlert: !this.state.modalAlert,
      // });
      alert("Pastikan code tidak ada yang kosong");
      return;
    } else if (!approval.trim()) {
      // this.setState({
      //   alertData: 'Pastikan nama toko tidak ada yang kosong',
      //   modalAlert: !this.state.modalAlert,
      // });
      alert("Pastikan kode unik tidak ada yang kosong");
      return;
    } else {
      try {
        let response = await axios.get(
          `${CONFIG.BASE_URL}/api/auth/erp/check?`,
          {
            params: {
              customer_code: code,
              code_approval: approval,
            },
          }
        );
        const data = response.data;
        console.log("DATA", JSON.stringify(data.data));
        console.log("DATA", data.data.otp_verified_at);
        // console.log(data.data.user_address[0].shop_name);
        // console.log(data.data.customer_code);
        if (data.data.otp_verified_at == null) {
          dispatch(userAction(data.data, "dataUser"));
          navigate("/register/old/form");
          // this.props.loginAct(data.data.customer_code, 'codeUser');
          // this.props.loginAct(data.data.name, 'nama');
        } else {
          alert("Akun kode customer ini sudah terdaftar");
          // this.setState({
          //   alertData: "Akun kode customer ini sudah terdaftar",
          //   modalAlert: !modalAlert,
          // });
        }
      } catch (error) {
        let error429 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 429";
        let errorNetwork =
          JSON.parse(JSON.stringify(error)).message === "Network Error";
        let error400 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 400";
        console.log(
          "Cek Error========================",
          JSON.parse(JSON.stringify(error)).message
        );
        if (error429) {
          setAlertServerSibuk(true);
        } else if (errorNetwork) {
          setAlertInet(true);
        } else if (error400) {
          localStorage.removeItem("token");
          // navigate('/home')
        }
      }
    }
  }

  return (
    <div className="registerOldContainer">
      <img src={Logo} alt="registerOldLogo" className="registerOldLogo" />
      <h1 className="registerOldJudul">{"Pelanggan Lama"}</h1>
      <div className="registerOldContainerInput">
        <img
          src={IconUser}
          alt="registerOldIconUser"
          className="registerOldIconUser"
        />
        <input
          value={code}
          onChange={(e) => setCode(e.target.value)}
          className="registerOldTextInput"
          placeholder="Kode User"
        />
      </div>
      <div className="registerOldContainerInput">
        <img
          src={IconToko}
          alt="registerOldIconUser"
          className="registerOldIconUser"
        />
        <input
          value={approval}
          onChange={(e) => setApproval(e.target.value)}
          className="registerOldTextInput"
          placeholder="Kode Unik Toko"
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              getRegisterUserLama();
            }
          }}
        />
      </div>
      <div className="buttonLink">
        <button
          onClick={() => navigate(-1)}
          className="registerOldButtonKembali"
        >
          <p className="registerOldTextButtonOK">{"Kembali"}</p>
        </button>
        <button
          className="registerOldButtonOk"
          onClick={() => getRegisterUserLama()}
        >
          <p className="registerOldTextButtonOK">{"OK"}</p>
        </button>
      </div>
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterOld);
