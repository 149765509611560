import { combineReducers } from "redux";
import UserReducer from "./user";
import ScreenReducer from "./screenName";
import ParamsReducer from "./params";
import ProdukReducer from "./produk";
import OrderReducer from "./order";
import HelpReducer from "./helpCare";
import PromoReducer from "./promo";
import IdReducer from "./id";
export default combineReducers({
  UserReducer,
  ScreenReducer,
  ParamsReducer,
  ProdukReducer,
  OrderReducer,
  HelpReducer,
  PromoReducer,
  IdReducer,
});

// const reducers = combineReducers({
//   UserReducer,
//   ScreenReducer,
//   ParamsReducer,
//   ProdukReducer,
// })

// export default reducers;
