import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.css";
import NumberFormat from "react-number-format";
import ReactStars from "react-rating-stars-component";
import "./Keranjang.css";
import Navbar from "../components/Navbar";
import IconCheck from "../assets/images/Check.webp";
import IconChecked from "../assets/images/Checked.webp";
import IconTrash from "../assets/images/Trash.webp";
import IconPlus from "../assets/images/Plus.webp";
import IconMinus from "../assets/images/Minus.webp";
import ZeroCart from "../assets/images/ZeroCart.webp";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import IconCart from "../assets/images/KeranjangActive.webp";
import { produkAction, screenAction } from "../actions";
import { useDispatch } from "react-redux";
import Dialog from "../components/Dialog";
import IconClose from "../assets/images/Closemodal.webp";
import Popper from "@mui/material/Popper";
import Box from "@mui/material/Box";
import IconInfo from "../assets/images/Info.webp";
import IconNotifAll from "../assets/images/NotifAll.webp";
import IconShoppingCart from "../assets/images/Shopping-Cart.webp";
import IconMenu from "../assets/images/Menu.webp";
import ModalShopping from "../components/DialogShopping";
import IconArrow from "../assets/images/backArrow.webp";
import Badge from "@mui/material/Badge";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Loading from "../components/Loading";
import Header from "../components/Header";
import ProdukPartner from "../components/ProdukPartner";
import DialogPartner from "../components/DialogPartner";
import AlertBlacklist from "../components/AlertBlacklist";

export const Home = (props) => {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [qty, setQty] = useState(0);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const [recomenProduct, setRecomenProduct] = useState([]);
  const [loadingApi, setLoadingApi] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [shoppingCartItems, setShoppingCartItems] = useState([]);
  const [
    onEndReachedCalledDuringMomentum,
    setOnEndReachedCalledDuringMomentum,
  ] = useState(false);
  const [notifOrders, setNotifOrders] = useState(0);
  const [notifMessages, setNotifMessages] = useState(0);
  const [notifComplaints, setNotifComplaints] = useState(0);
  const [notifBroadcast, setNotifBroadcast] = useState(0);
  const [notifSubscribe, setNotifSubscribe] = useState(0);
  const [countNotifCart, setCountNotifCart] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [tempCheck, setTempCheck] = useState([]);
  const [tempCheckPartner, setTempCheckPartner] = useState([]);
  const [isSemiGrosir, setIsSemiGrosir] = useState(false);
  const [isGrosir, setIsGrosir] = useState(false);
  const [isStar, setIsStar] = useState(false);
  const [visibleButton, setVisibleButton] = useState(true);
  const [description, setDescription] = useState(null);
  const [open, setOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [alertTambahan, setAlertTambahan] = useState("");
  const [alertJudul, setAlertJudul] = useState("");
  // const [anchorEl, setAnchorEl] = useState(null);
  const [toolTip, setToolTip] = useState(true);
  const [inputHarga, setInputHarga] = useState("");
  const [indexTips, setIndexTips] = useState(0);
  const [dataPriceList, setDataPriceList] = useState([]);
  const [dataDelete, setDataDelete] = useState(null);
  const [dataDelete2, setDataDelete2] = useState(null);
  const [openShopping, setOpenShopping] = useState(false);
  const [loadAll, setLoadAll] = useState(false);
  const [shoppingCart, setShoppingCart] = useState({
    product_id: "",
    brand_id: "",
    satuan_online: "",
    konversi_sedang_ke_kecil: "",
    qty_konversi: "",
    qty: 1,
    notes: "",
    price_apps: "",
  });
  const [newShoppingCartPartner, setNewShoppingCartPartner] = useState({});
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertServerMantenance, setAlertServerMantenance] = useState(false);
  const [alertInet, setAlertInet] = useState(false);
  const [alertDataPartner, setAlertDataPartner] =
    useState("Alert Data partner");
  const [alertJudulPartner, setAlertJudulPartner] = useState(
    "Alert Judul partner"
  );
  const [openPartner, setOpenPartner] = useState(false);
  const [indexPartner, setIndexPartner] = useState([]);
  const [deletePartner, setDeletePartner] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [minBelanja, setMinBelanja] = useState(0);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  function alertMantenance() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerMantenance(false);
          }}
          variant="filled"
          severity="info"
        >
          Mohon maaf, Server sedang dalam perbaikan
        </Alert>
      </Stack>
    );
  }

  function NoData() {
    return (
      <div className="NoData">
        <img style={{ height: "50%", width: "100%" }} src={ZeroCart} />
        <p
          style={{
            fontSize: "14pt",
            fontFamily: "Lato-Bold",
            textAlign: "center",
            marginTop: "-20%",
          }}
        >
          {"Wah, keranjang mu kosong"}
        </p>
        <p
          style={{
            fontSize: "12pt",
            fontFamily: "Lato-Bold",
            width: "75%",
            textAlign: "center",
            marginTop: "2px",
          }}
        >
          {"Yuk, isi dengan barang-barang yang ingin kamu beli"}
        </p>
        <button
          onClick={() => {
            dispatch(screenAction("produk", "screenName"));
            navigate("/produk");
          }}
          style={{
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#529F45",
            width: "65%",
            height: "8%",
            borderRadius: "5px",
            marginTop: "5px",
            color: "#FFFFFF",
            fontSize: "10pt",
            fontFamily: "Lato-Bold",
          }}
        >
          {"Mulai Belanja"}
        </button>
      </div>
    );
  }

  useEffect(() => {
    getShoppingCart();
    cekMinTransaction();
    getNotifAll();
    getProdukRecomen();
    return () => {};
  }, []);

  useEffect(() => {
    if (loadAll) {
      getShoppingCart();
      cekMinTransaction();
      getNotifAll();
      getProdukRecomen();
      setLoadAll(false);
    }
  }, [loadAll]);

  async function getNotifAll() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/notification-all`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      setNotifOrders(data.total_order);
      setNotifMessages(data.total_chat);
      setNotifComplaints(data.total_complaint);
      setNotifSubscribe(data.total_subscribe);
      setNotifBroadcast(data.total_broadcast);
      setCountNotifCart(data.total_cart);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========NOTIF================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        // navigate('/home')
      }
    }
  }

  function renderCountNotificationBadge() {
    try {
      let count = 0;
      let hasil = notifMessages;
      let hasil2 = notifSubscribe;
      let hasil3 = notifOrders;
      let hasil4 = notifComplaints;
      let hasil5 = notifBroadcast;
      count =
        count +
        parseInt(hasil) +
        parseInt(hasil2) +
        parseInt(hasil3) +
        parseInt(hasil4) +
        parseInt(hasil5);
      // console.log(count);
      if (count > 0) {
        return count;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getProdukRecomen() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/recomen/products`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data.data;
      // const dataTotal = response.data.data.total;
      setRecomenProduct(data);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        // navigate('/home')
      }
    }
  }

  async function getShoppingCart() {
    try {
      let response = await axios.get(`${CONFIG.BASE_URL}/api/shopping-cart`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const data = response.data.data;

      const newData = [...data.shoppingCartMpm];
      const dataPartner = data.shoppingCartPartner;
      const key = Object.keys(data.shoppingCartPartner);
      let isGrosir = false;
      let isSemiGrosir = false;
      let isStar = false;
      if (dataUser?.user.class === "SEMI GROSIR") {
        isSemiGrosir = newData.some((i) => i.brand_id === "001");
      } else if (dataUser?.user.class === "GROSIR") {
        isGrosir = newData.some((i) => i.brand_id === "001");
      } else if (dataUser?.user.class === "STAR OUTLET") {
        isStar = newData.some((i) => i.brand_id === "001");
      }
      let _tempCheck = [];
      let filterChecked = [];
      if (tempCheck.length === 0) {
        setSelectAll(false);
        newData.map((item) => {
          _tempCheck = [..._tempCheck, { checked: 0 }];
          item.checked = false;
          return { ...item };
        });
      } else if (tempCheck.length !== 0) {
        newData.map((_, index) => {
          newData[index]["checked"] = tempCheck[index].checked; // set the new value
        });
        _tempCheck = tempCheck;
      }
      if (tempCheckPartner.length !== 0) {
        key.map((id) =>
          dataPartner[id].map((item) => {
            let findItem = tempCheckPartner.find((arr) => arr.id === item.id);
            if (item.id === findItem.id) {
              item.checked = findItem.checked;
              filterChecked.push(item);
            }
          })
        );
      }

      setShoppingCartItems(newData);
      setIsGrosir(isGrosir);
      setIsSemiGrosir(isSemiGrosir);
      setIsStar(isStar);
      setTempCheck(_tempCheck);
      setTempCheckPartner(filterChecked);
      setNewShoppingCartPartner(dataPartner);
      setLoadingApi(false);
      setIndexPartner(key);
    } catch (error) {
      setLoadingApi(false);
      console.log(error);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  //pilih salah satu produk
  function selectHandler(index, value) {
    let newItems = [...shoppingCartItems]; // clone the array
    let temp = [...tempCheck];
    newItems[index].checked = value == 1 ? 0 : 1; // set the new value
    temp[index].checked = value == 1 ? 0 : 1;
    setShoppingCartItems(newItems); // set new state
    let result = shoppingCartItems.every((item) => {
      return item.checked > 0;
    });

    setTempCheck(temp);
    cekSelectAll();
  }

  //pilih semua produk
  function selectHandlerAll(value) {
    let data;
    let selected = shoppingCartItems.every((item) => item["checked"] == 1);
    let dataPartner = [];
    if (value == "semut-gajah") {
      data = shoppingCartItems.map((item) => {
        selected == false ? (item["checked"] = 1) : (item["checked"] = 0);
        return { checked: item["checked"] };
      });
      setTempCheck(data);
      cekSelectAll();
    } else if (value == "selectAll") {
      let itemPartner =
        tempCheckPartner.length == 0
          ? false
          : tempCheckPartner?.every((item) => item?.checked == 1);
      let checkedItem;
      if (selected && itemPartner == true) {
        checkedItem = 0;
        setSelectAll(false);
      } else {
        checkedItem = 1;
        setSelectAll(true);
      }
      indexPartner.map((item) => {
        newShoppingCartPartner[item].map((_item) => {
          _item.checked = checkedItem;
          return dataPartner.push(_item);
        });
      });
      data = shoppingCartItems.map((item) => {
        item["checked"] = checkedItem;
        return { checked: item["checked"] };
      });
      setTempCheck(data);
      setTempCheckPartner([...dataPartner]);
    } else {
      selected = newShoppingCartPartner[value].every(
        (item) => item["checked"] == 1
      );
      indexPartner.map((item) => {
        newShoppingCartPartner[item].map((_item) => {
          if (item == value) {
            selected == false ? (_item.checked = 1) : (_item.checked = 0);
          }
          return dataPartner.push(_item);
        });
      });
      setTempCheckPartner([...dataPartner]);
      cekSelectAll();
    }
  }

  //fungsi untuk cek checked semua produk
  function cekSelectAll() {
    let data = shoppingCartItems.every((item) => item["checked"] == 1);
    let dataPartner =
      tempCheckPartner.length == 0
        ? false
        : tempCheckPartner?.every((item) => item?.checked == 1);
    if (data && dataPartner == true) {
      setSelectAll(true);
      // console.log("log 490 true");
    } else {
      setSelectAll(false);
      // console.log("log 493 false");
    }
  }
  function deleteHandler(index) {
    setAlertData("Apakah yakin ingin menghapus item ini?");
    setAlertJudul("Pemberitahuan");
    setDataDelete(index);
    setDataDelete2(null);
    handleClickOpen();
    // this.setState({
    //   loading: true,
    //   alertDelete: 'Apakah yakin ingin menghapus item ini?',
    //   buttonDelete: 'Hapus',
    //   modalDelete: !modalDelete,
    //   dataDelete: index,
    // });
  }

  //update jumlah barang
  async function quantityHandler(action, id) {
    let items = [...shoppingCartItems];
    let accept = false;

    if (action === "more") {
      items[id].qty = parseInt(items[id].qty) + 1;
      accept = true;
    } else if (action === "less" && items[id].qty > 1) {
      items[id].qty = parseInt(items[id].qty) - 1;
      accept = true;
    } else {
      setAlertData("Apakah yakin ingin menghapus item ini?");
      setAlertJudul("Pemberitahuan");
      setDataDelete2(id);
      setDataDelete(null);
      handleClickOpen();
    }
    if (accept) {
      setShoppingCartItems(items);
      // setLoadingApi(true);
      try {
        let response = await axios.post(
          `${CONFIG.BASE_URL}/api/shopping-cart/${items[id].id}`,
          {
            product_id: items[id].product_id,
            brand_id: items[id].brand_id,
            satuan_online: items[id].satuan_online,
            konversi_sedang_ke_kecil:
              items[id].qty * items[id].konversi_sedang_ke_kecil,
            qty: items[id].qty,
            notes: items[id].notes,
            price_apps: items[id].price_apps,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        // const data = response.data.success;
        const data = response.data.data;
        // console.log("data tambahkurang===>", data);
        // console.log("data update===>", JSON.stringify(response.data.data));
        if (response.data["success"] == true) {
          const newData = [...data?.shoppingCartMpm];
          let isGrosir = false;
          let isSemiGrosir = false;
          let isStar = false;

          // console.log(JSON.stringify(dataUser.user.class));
          if (dataUser.user.class === "SEMI GROSIR") {
            isSemiGrosir = newData.some((i) => i.brand_id === "001");
          } else if (dataUser.user.class === "GROSIR") {
            isGrosir = newData.some((i) => i.brand_id === "001");
          } else if (dataUser.user.class === "STAR OUTLET") {
            isStar = newData.some((i) => i.brand_id === "001");
          }

          let _tempCheck = [];
          if (tempCheck.length === 0) {
            // console.log('masuk sini 1');
            newData.map((item) => {
              _tempCheck = [..._tempCheck, { checked: 0 }];
            });
          } else if (newData.length != tempCheck.length) {
            tempCheck.splice(0, 0, { checked: 0 });
            newData.map((_, index) => {
              // console.log("index", index);
              newData[index]["checked"] = tempCheck[index].checked; // set the new value
            });
            _tempCheck = tempCheck;
          } else {
            newData.map((_, index) => {
              // console.log("index", index);
              newData[index]["checked"] = tempCheck[index].checked; // set the new value
            });
            _tempCheck = tempCheck;
          }
          setLoadingApi(false);
          setShoppingCartItems(newData);
          setIsGrosir(isGrosir);
          setIsSemiGrosir(isSemiGrosir);
          setIsStar(isStar);
          setTempCheck(_tempCheck);
        }
      } catch (error) {
        setLoadingApi(false);
        console.log(error);
        let error429 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 429";
        let errorNetwork =
          JSON.parse(JSON.stringify(error)).message === "Network Error";
        let error400 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 400";
        console.log(
          "Cek Error========================",
          JSON.parse(JSON.stringify(error)).message
        );
        if (error429) {
          setAlertServerSibuk(true);
        } else if (errorNetwork) {
          setAlertInet(true);
        } else if (error400) {
          localStorage.removeItem("token");
          navigate("/home");
        }
      }
    }
  }

  // update quantity input
  async function getQuantity(e, id) {
    let hasilText = e.target.value; // this is the search text
    const items = [...shoppingCartItems];
    if (parseInt(hasilText) < 0) {
      // items[id].qty = parseInt(hasilText) * 0;
      setAlertData("tidak boleh kurang dari 0");
      setAlertJudul("Pemberitahuan");
      handleClickOpen();
      callGetShoppingCart();
    } else if (
      parseInt(hasilText) == 0 ||
      parseInt(hasilText) == null ||
      parseInt(hasilText) == "" ||
      isNaN(parseInt(hasilText)) ||
      parseInt(hasilText) == "undefined"
    ) {
      setAlertData("Apakah yakin ingin menghapus item ini?");
      setAlertJudul("Pemberitahuan");
      setDataDelete2(id);
      setDataDelete(null);
      handleClickOpen();
    } else {
      // items[id].qty = hasilText;
      items[id].qty = parseInt(hasilText);
      // setLoadingApi(true);
      setShoppingCartItems(items);
      try {
        let response = await axios.post(
          `${CONFIG.BASE_URL}/api/shopping-cart/${items[id].id}`,
          {
            product_id: items[id].product_id,
            brand_id: items[id].brand_id,
            satuan_online: items[id].satuan_online,
            konversi_sedang_ke_kecil: items[id].konversi_sedang_ke_kecil,
            qty_konversi: items[id].qty * items[id].konversi_sedang_ke_kecil,
            qty: items[id].qty,
            notes: items[id].notes,
            price_apps: items[id].price_apps,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        // const data = response.data.success;
        const data = response.data.data;
        // if (data == true) {
        //   this.getShoppingCart();
        // } else {
        // }
        if (response.data["success"] == true) {
          const newData = [...data?.shoppingCartMpm];
          let isGrosir = false;
          let isSemiGrosir = false;
          let isStar = false;
          // let notif = response.data.promo_result.map((item, index) => {
          //   item.isSelected = true;
          //   return {...item};
          // });
          if (dataUser.user.class === "SEMI GROSIR") {
            isSemiGrosir = newData.some((i) => i.brand_id === "001");
          } else if (dataUser.user.class === "GROSIR") {
            isGrosir = newData.some((i) => i.brand_id === "001");
          } else if (dataUser.user.class === "STAR OUTLET") {
            isStar = newData.some((i) => i.brand_id === "001");
          }

          let _tempCheck = [];
          if (tempCheck.length === 0) {
            newData.map((item) => {
              _tempCheck = [..._tempCheck, { checked: 0 }];
            });
          } else if (newData.length != tempCheck.length) {
            tempCheck.splice(0, 0, { checked: 0 });
            newData.map((_, index) => {
              newData[index]["checked"] = tempCheck[index].checked; // set the new value
            });
            _tempCheck = tempCheck;
          } else {
            newData.map((_, index) => {
              newData[index]["checked"] = tempCheck[index].checked; // set the new value
            });
            _tempCheck = tempCheck;
          }

          setLoadingApi(false);
          setShoppingCartItems(newData);
          setIsGrosir(isGrosir);
          setIsSemiGrosir(isSemiGrosir);
          setIsStar(isStar);
          setTempCheck(_tempCheck);
        } else if (
          response.data["message"] == "Anda Masuk Dalam Daftar Blacklist"
        ) {
          setOpenDialog(true);
          setMessage(response.data.message);
        }
      } catch (error) {
        setLoadingApi(false);
        let error429 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 429";
        let errorNetwork =
          JSON.parse(JSON.stringify(error)).message === "Network Error";
        let error400 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 400";
        console.log(
          "Cek Error========================",
          JSON.parse(JSON.stringify(error)).message
        );
        if (error429) {
          setAlertServerSibuk(true);
        } else if (errorNetwork) {
          setAlertInet(true);
        } else if (error400) {
          localStorage.removeItem("token");
          navigate("/home");
        }
      }
    }
  }

  //update catatan
  async function notesHandler(e, id) {
    // console.log("masuk notes handler");
    let items = [...shoppingCartItems];
    items[id].notes = e.target.value;

    setShoppingCartItems(items);
    try {
      let response = await axios.post(
        `${CONFIG.BASE_URL}/api/shopping-cart/${items[id].id}`,
        {
          product_id: items[id].product_id,
          brand_id: items[id].brand_id,
          satuan_online: items[id].satuan_online,
          konversi_sedang_ke_kecil: items[id].konversi_sedang_ke_kecil,
          qty_konversi: items[id].qty * items[id].konversi_sedang_ke_kecil,
          qty: items[id].qty,
          notes: items[id].notes,
          price_apps: items[id].price_apps,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      let data = response.data;
      if (data === "" || !data["success"]) {
        setAlertData("Gagal menambahkan catatan");
        setAlertJudul("Pemberitahuan");
        handleClickOpen();
        // this.setState({
        //   alertData: "Gagal menambahkan catatan",
        //   modalAlert: !modalAlert,
        // });
        console.log("Edit catatan gagal===>", data);
      } else if (data["message"] == "Anda Masuk Dalam Daftar Blacklist") {
        setOpenDialog(true);
        setMessage(data.message);
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  //hitung total jumlah harga
  function subtotalPrice() {
    if (shoppingCartItems) {
      return shoppingCartItems.reduce(
        (sum, item) =>
          sum + (item.checked == 1 ? Math.round(item.total_price) : 0),
        0
      );
    }
    return 0;
  }
  //hitung total harga produk ditributor
  function subTotalPricePartner() {
    if (tempCheckPartner != null) {
      return tempCheckPartner.reduce(
        (sum, item) => sum + (item.checked == 1 ? Number(item.total_price) : 0),
        0
      );
    } else {
      return 0;
    }
  }

  //filter product 
  function filterChecked() {
    const result = shoppingCartItems.filter((item) => {
      return item.checked === 1;
    });
    return result;
  }

  //filter product distributor
  function filterChecked2() {
    let data = newShoppingCartPartner;
    let temp;
    indexPartner.map((item) => {
      temp = data[item].filter((data) => {
        return data.checked === 1;
      });

      if (temp.length > 0) {
        data[item] = temp;
      } else {
        delete data[item];
      }
    });
    return data;
  }

 //cek promo produk  
  async function postBuyShoppingCart() {
    let isZero = false;
    let isZeroPartner = false;
    let isMinbuy = false;
    let isMinBuyPartner = false;
    let minPembelian = 0;
    let minPembelianPartner = 0;
    let filtered = filterChecked();
    let filtered2 = filterChecked2();
    const idObj = Object.keys(filtered2);
    let arrChecked = {
      shoppingCartMpm: filtered,
      shoppingCartPartner: filtered2,
    };

    idObj?.map(
      (id) =>
        (isMinBuyPartner = filtered2[id]?.every(
          (val) =>
            parseInt(val.min_pembelian) >=
            ((val.min_pembelian === "" || val.min_pembelian === null) ?? 1)
        ))
    );
    idObj?.map(
      (id) =>
        (isZeroPartner = filtered2[id]?.every(
          (val) => parseInt(val.min_pembelian) >= parseInt(val.qty) >= 1
        ))
    );
    isMinbuy = filtered.every(
      (val) =>
        parseInt(val.qty) >=
        ((val.min_pembelian === "" || val.min_pembelian === null) ?? 1)
    );

    isZero = filtered.every((i) => parseInt(i.qty) >= 1);

    if ((isZero && isMinbuy) || (isZeroPartner && isMinBuyPartner)) {
      // dispatch(produkAction(subtotalPrice(), "shop_total"));
      await axios({
        method: "post",
        url: `${CONFIG.BASE_URL}/api/orders/count`,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        data: { data: arrChecked },
      })
        .then((response) => {
          const data = response.data;
          console.log("data " + JSON.stringify(data));
          if (data["success"] == true) {
            if (data.data?.validate_price?.length > 0) {
              let data = data?.validate_price[0];
              getPopUp(data);
            } else {
            }
            dispatch(
              produkAction(
                data.data?.shoppingCartMpm?.promo_result,
                "hasilPromo"
              )
            );
            dispatch(
              produkAction(
                data.data?.shoppingCartMpm?.promo_result,
                "hasilPromoNotif"
              )
            );
            dispatch(
              produkAction(data.data?.shoppingCartMpm?.detail, "hargaPromo")
            );
            dispatch(produkAction(data.data?.shoppingCartMpm, "shop"));
            dispatch(
              produkAction(data.data?.shoppingCartPartner, "partnerShop")
            );
            dispatch(produkAction(data.data?.total_price, "shop_total"));
            dispatch(produkAction(data.credit_limit, "kredit"));
            navigate("/delivery");
          } else if (data["message"] == "Anda Masuk Dalam Daftar Blacklist") {
            setOpenDialog(true);
            setMessage(data.message);
          } else {
            setAlertServerMantenance(true);
          }
        })
        .catch((error) => {
          let error429 =
            JSON.parse(JSON.stringify(error)).message ===
            "Request failed with status code 429";
          let errorNetwork =
            JSON.parse(JSON.stringify(error)).message === "Network Error";
          let error400 =
            JSON.parse(JSON.stringify(error)).message ===
            "Request failed with status code 400";
          console.log(
            "Cek Error========================",
            JSON.parse(JSON.stringify(error))
          );
          if (error429) {
            setAlertServerSibuk(true);
          } else if (errorNetwork) {
            setAlertInet(true);
          } else if (error400) {
            localStorage.removeItem("token");
            navigate("/home");
          }
        });
    } else if ((!isMinbuy && isZero) || (!isMinBuyPartner && isZeroPartner)) {
      setAlertData("Minimal pembelian produk ini berjumlah " + minPembelian);
      setAlertJudul("Pemberitahuan");
      handleClickOpen();
    } else if ((!isZero && isMinbuy) || (!isZeroPartner && isMinBuyPartner)) {
      setAlertData("Jumlah tidak boleh kosong");
      setAlertJudul("Pemberitahuan");
      handleClickOpen();
    } else if ((!isZero && !isMinbuy) || (!isZeroPartner && !isMinBuyPartner)) {
      setAlertData("Jumlah tidak boleh kosong");
      setAlertJudul("Pemberitahuan");
      handleClickOpen();
    }
  }

  //alert perubahan harga
  async function getPopUp(data) {
    setAlertData("Update keranjang sekarang?");
    setAlertTambahan("Order gagal! harga telah berubah");
    setAlertJudul("Pemberitahuan");
    setDataPriceList(data);
    setLoadingApi(true);
    handleClickOpen();
  }

  // alert open
  const handleClickOpen = () => {
    setOpen(true);
  };

  const callGetShoppingCart = useCallback(() => {
    getShoppingCart();
  }, []);

  //hapus produk
  const handleClose = async (e) => {
    setOpen(false);
    const newItems = [...shoppingCartItems]; // clone the array
    // console.log('items nih2===', newItems);
    // console.log("dataDelete", dataDelete);
    // console.log("dataDelete2", dataDelete2);
    // console.log("eeeeeee", e);
    // console.log("alertData", alertData);
    if (
      e == "ok" &&
      dataDelete != null &&
      dataDelete2 == null &&
      alertData == "Apakah yakin ingin menghapus item ini?"
    ) {
      // setLoadingApi(true);
      try {
        let response = await axios.delete(
          `${CONFIG.BASE_URL}/api/shopping-cart/${dataDelete.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const data = response.data.success;
        if (data == true) {
          setDataDelete(null);
          // setTempCheck([])
          // setShoppingCartItems([]);
          callGetShoppingCart();
        } else if (
          response.data["message"] == "Anda Masuk Dalam Daftar Blacklist"
        ) {
          setOpenDialog(true);
          setMessage(response.data.message);
        } else {
          setLoadingApi(false);
        }
      } catch (error) {
        setLoadingApi(false);
        let error429 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 429";
        let errorNetwork =
          JSON.parse(JSON.stringify(error)).message === "Network Error";
        let error400 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 400";
        console.log(
          "Cek Error========================",
          JSON.parse(JSON.stringify(error)).message
        );
        if (error429) {
          setAlertServerSibuk(true);
        } else if (errorNetwork) {
          setAlertInet(true);
        } else if (error400) {
          localStorage.removeItem("token");
          navigate("/home");
        }
      }
    } else if (
      e == "cancel" &&
      dataDelete2 != null &&
      dataDelete == null &&
      alertData == "Apakah yakin ingin menghapus item ini?"
    ) {
      // console.log("masuk 2");
      newItems[dataDelete2].qty = "load...";
      setShoppingCartItems(newItems);
      setDataDelete2(null);
      callGetShoppingCart();
    } else if (
      e == "ok" &&
      dataDelete2 != null &&
      dataDelete == null &&
      alertData == "Apakah yakin ingin menghapus item ini?"
    ) {
      // console.log("masuk 3");
      // setLoadingApi(true);
      try {
        let response = await axios.delete(
          `${CONFIG.BASE_URL}/api/shopping-cart/${newItems[dataDelete2].id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const data = response.data.success;

        // console.log("data delete===>", response.data);
        if (data == true) {
          setDataDelete2(null);
          // setTempCheck([])
          // setShoppingCartItems([]);
          callGetShoppingCart();
        } else if (
          response.data["message"] == "Anda Masuk Dalam Daftar Blacklist"
        ) {
          setOpenDialog(true);
          setMessage(response.data.message);
        } else {
          console.log("data gagal dihapus===>", data);
        }
      } catch (error) {
        setLoadingApi(false);
        let error429 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 429";
        let errorNetwork =
          JSON.parse(JSON.stringify(error)).message === "Network Error";
        let error400 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 400";
        console.log(
          "Cek Error========================",
          JSON.parse(JSON.stringify(error)).message
        );
        if (error429) {
          setAlertServerSibuk(true);
        } else if (errorNetwork) {
          setAlertInet(true);
        } else if (error400) {
          localStorage.removeItem("token");
          navigate("/home");
        }
      }
    } else if (
      e == "ok" &&
      dataPriceList != null &&
      alertData == "Update keranjang sekarang?"
    ) {
      // console.log("masuk 4");
      // setLoadingApi(true);
      try {
        let response = await axios.post(
          `${CONFIG.BASE_URL}/api/shopping-cart/validate`,
          {
            validate_price: dataPriceList,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const dataIsi = response.data.success;
        // console.log("data Valid keranjang===>", JSON.stringify(response.data));
        if (dataIsi == true) {
          setLoadingApi(false);
          callGetShoppingCart();
        } else {
          console.log("data gagal===>", dataIsi);
        }
      } catch (error) {
        setLoadingApi(false);
        let error429 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 429";
        let errorNetwork =
          JSON.parse(JSON.stringify(error)).message === "Network Error";
        let error400 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 400";
        console.log(
          "Cek Error========================",
          JSON.parse(JSON.stringify(error)).message
        );
        if (error429) {
          setAlertServerSibuk(true);
        } else if (errorNetwork) {
          setAlertInet(true);
        } else if (error400) {
          localStorage.removeItem("token");
          navigate("/home");
        }
      }
    } else if (
      e == "cancel" &&
      dataPriceList != null &&
      alertData == "Update keranjang sekarang?"
    ) {
      // console.log("masuk 5");
      setDataPriceList(null);
      callGetShoppingCart();
    }
    setAlertData("");
  };

  //get data promo
  async function getPromo(item, status) {
    // console.log("cekkkkk", item, status);
    try {
      let response = await axios({
        method: "get",
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        url: `${CONFIG.BASE_URL}/api/promo?id=${item}&product_id=${status}`,
      });
      // console.log(response.data.data);
      const data = response.data;
      if (data != 0 && data["success"] == true) {
        // this.props.navigation.navigate(
        //   'DetailPromo',
        //   this.props.bannerAct(data.data, 'banner'),
        // );
        setDescription(data.data);
      } else {
        return;
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error=============getPromo===========",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }


  async function postRecent(item) {
    // console.log("masuk");
    // console.log("postRecent", JSON.stringify(item));
    try {
      let response = await axios.post(
        `${CONFIG.BASE_URL}/api/recent/products`,
        {
          product_id: item.id,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      // let data = response.data.data.data;
      // const dataTotal = response.data.data.total;
      // console.log("postRecent", response.data.data);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error==========POST RECENT==============",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  async function postShoppingCart(item) {
    const brand_id_1 = ["001"];
    const brand_id_2 = [
      "002",
      "003",
      "004",
      "007",
      "008",
      "009",
      "010",
      "011",
      "012",
      "013",
      "014",
    ];
    const brand_id_3 = ["005"];

    // console.log('price=====>',item)
    let price = item.price.harga_ritel_gt ?? "0";
    if (brand_id_1.includes(item.brand_id)) {
      if (item.status_promosi_coret == 1) {
        // console.log('if 1');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          default:
            break;
        }
      } else if (
        (item.status_promosi_coret !== "0" ||
          item.status_promosi_coret !== null) &&
        item.status_herbana !== "1"
      ) {
        // console.log('if 2');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_ritel_gt;
            break;
          default:
            break;
        }
      } else if (item.status_herbana === "1") {
        // console.log('if 3');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_grosir_mt;
            break;
          default:
            break;
        }
      } else {
        // console.log('if 4');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_ritel_gt;
            break;
          default:
            break;
        }
      }
    } else if (brand_id_2.includes(item.brand_id)) {
      if (item.status_promosi_coret == 1) {
        // console.log('if 5');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          default:
            break;
        }
      } else if (
        item.status_promosi_coret !== "0" ||
        item.status_promosi_coret !== null
      ) {
        // console.log('if 6');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_grosir_mt;
            break;
          default:
            break;
        }
      } else {
        // console.log('if 7');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_grosir_mt;
            break;
          default:
            break;
        }
      }
    } else if (brand_id_3.includes(item.brand_id)) {
      if (item.status_promosi_coret == 1) {
        // console.log('if 8');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          default:
            break;
        }
      } else if (
        item.status_promosi_coret !== "0" ||
        item.status_promosi_coret !== null
      ) {
        // console.log('if 9');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_ritel_gt;
            break;
          default:
            break;
        }
      } else {
        // console.log('if 10');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_ritel_gt;
            break;
          default:
            break;
        }
      }
    }
    try {
      let response = await axios.post(
        `${CONFIG.BASE_URL}/api/shopping-cart`,
        {
          product_id: item.id,
          brand_id: item.brand_id,
          satuan_online: item.satuan_online,
          konversi_sedang_ke_kecil: item.konversi_sedang_ke_kecil,
          qty_konversi: shoppingCart.qty * item.konversi_sedang_ke_kecil,
          qty: shoppingCart.qty,
          notes: shoppingCart.notes,
          price_apps: price ?? "0",
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data;
      if (data !== "") {
        setOpenShopping(true);
        // setTempCheck([])
        setLoadAll(true);
      } else {
        console.log("Gagal memasukkan keranjang===>", data);
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  const selectHandlerPartner = (id, value, object) => {
    // console.log("select " + id + " " + value + " " + object);
    let newItemsPartner = newShoppingCartPartner;
    let dataDistributor = newItemsPartner[object];
    let newItemsPartner2 = [];
    indexPartner.map((item) => {
      newItemsPartner[item].map((_item) => {
        return newItemsPartner2.push(_item);
      });
    });
    const findData = dataDistributor.find((arr) => {
      return arr.id === id;
    });
    findData.checked = value == 1 ? 0 : 1;
    setTempCheckPartner([...newItemsPartner2]);
    cekSelectAll();
  };

  const deleteHandlerPartner = (id, object) => {
    setDeletePartner({
      id: id,
      idPartner: object,
    });
    setAlertDataPartner("Apakah yakin ingin menghapus item ini?");
    setAlertJudulPartner("Pemberitahuan");
    setOpenPartner(true);
  };

  async function quantityHandlerPartner(action, id, object) {
    let dataItem = { ...newShoppingCartPartner };
    const findArray = dataItem[object].find((item) => item.id === id);
    // console.log("quantityHandlerPartner " + JSON.stringify(findArray));
    let accept = false;
    let temp;
    if (action === "more") {
      indexPartner.map((item) => {
        temp = dataItem[item].filter((data) => {
          if (data.id == id) {
            data.qty = parseInt(data.qty) + 1;
          }
          console.log("more" + JSON.stringify(data));
          return data;
        });
        dataItem[item] = temp;
      });
      accept = true;
    } else if (action === "less" && parseInt(findArray.qty) > 1) {
      indexPartner.map((item) => {
        temp = dataItem[item].filter((data) => {
          if (data.id == id) {
            data.qty = parseInt(data.qty) - 1;
          }
          return data;
        });
        dataItem[item] = temp;
      });
      accept = true;
    } else {
      deleteHandlerPartner(id, object);
    }
    console.log("Find " + JSON.stringify(findArray));
    if (accept) {
      try {
        let response = await axios.post(
          `${CONFIG.BASE_URL}/api/shopping-cart/${findArray.id}`,
          {
            product_id: findArray.product_id,
            brand_id: findArray.brand_id,
            satuan_online: findArray.satuan_online,
            konversi_sedang_ke_kecil:
              findArray.qty * findArray.konversi_sedang_ke_kecil,
            qty: findArray.qty,
            notes: findArray.notes,
            price_apps: findArray.price_apps,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const data = response.data;
        if (data.success === true) {
          getShoppingCart();
        } else if (data["message"] == "Anda Masuk Dalam Daftar Blacklist") {
          setOpenDialog(true);
          setMessage(data.message);
        }
      } catch (e) {
        console.log("error " + e);
      }
    }
  }

  async function getQuantityPartner(e, id_prod, id_toko) {
    console.log(e.target.value, " id ", id_prod, " id_toko ", id_toko);
    let hasilText = e.target.value;
    if (parseInt(hasilText) < 0) {
      setDeletePartner({
        id: id_prod,
        idPartner: id_toko,
      });
      setAlertDataPartner("Apakah yakin ingin menghapus item ini?");
      setAlertJudulPartner("Pemberitahuan");
      setOpenPartner(true);
      callGetShoppingCart();
    } else if (
      parseInt(hasilText) == 0 ||
      parseInt(hasilText) == null ||
      parseInt(hasilText) == "" ||
      isNaN(parseInt(hasilText)) ||
      parseInt(hasilText) == "undefined"
    ) {
      setDeletePartner({
        id: id_prod,
        idPartner: id_toko,
      });
      setAlertDataPartner("Apakah yakin ingin menghapus item ini?");
      setAlertJudulPartner("Pemberitahuan");
      setOpenPartner(true);
      callGetShoppingCart();
    } else {
      let dataPartner = newShoppingCartPartner;
      let data;
      indexPartner.map((id) => {
        data = dataPartner[id].map((item) => {
          if (item.id === id_prod) {
            item.qty = parseInt(hasilText);
            return item;
          } else {
            return item;
          }
        });
        dataPartner[id] = data;
      });
      const dataItem = dataPartner[id_toko].find((arr) => arr.id == id_prod);
      try {
        let response = await axios.post(
          `${CONFIG.BASE_URL}/api/shopping-cart/${dataItem.id}`,
          {
            product_id: dataItem.product_id,
            brand_id: dataItem.brand_id,
            satuan_online: dataItem.satuan_online,
            konversi_sedang_ke_kecil: dataItem.konversi_sedang_ke_kecil,
            qty_konversi: dataItem.qty * dataItem.konversi_sedang_ke_kecil,
            qty: dataItem.qty,
            notes: dataItem.notes,
            price_apps: dataItem.price_apps,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        const data = response.data;
        if (data.success == true) {
          console.log("true");
          getShoppingCart();
        } else if (data["message"] == "Anda Masuk Dalam Daftar Blacklist") {
          setOpenDialog(true);
          setMessage(data.message);
        }
      } catch (e) {
        console.log("error " + e);
      }
    }
  }

  async function notesHandlerPartner(e, id, id_prod) {
    // console.log("event " + e.target.value);
    let dataPartner = newShoppingCartPartner;
    let data;
    indexPartner.map((id) => {
      data = dataPartner[id].map((item) => {
        if (item.id === id_prod) {
          item.notes = e.target.value;
          return item;
        } else {
          return item;
        }
      });
      dataPartner[id] = data;
    });
    const item = dataPartner[id].find((arr) => arr.id == id_prod);
    try {
      let response = await axios.post(
        `${CONFIG.BASE_URL}/api/shopping-cart/${item.id}`,
        {
          product_id: item.product_id,
          brand_id: item.brand_id,
          satuan_online: item.satuan_online,
          konversi_sedang_ke_kecil: item.konversi_sedang_ke_kecil,
          qty_konversi: item.qty * item.konversi_sedang_ke_kecil,
          qty: item.qty,
          notes: item.notes,
          price_apps: item.price_apps,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      let data = response.data;
      if (data === "" || !data["success"]) {
        setAlertData("Gagal menambahkan catatan");
        setAlertJudul("Pemberitahuan");
        handleClickOpen();
        // this.setState({
        //   alertData: "Gagal menambahkan catatan",
        //   modalAlert: !modalAlert,
        // });
      } else if (data["message"] == "Anda Masuk Dalam Daftar Blacklist") {
        setOpenDialog(true);
        setMessage(data.message);
      }
      // console.log("Data"+JSON.stringify(response));
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }
  async function handleClosePartner(e) {
    let newData;
    let dataDelete;

    setOpenPartner(false);
    if (e == "ok") {
      indexPartner.map((item) => {
        newData = newShoppingCartPartner[item].filter((data) => {
          if (data.id !== deletePartner?.id) {
            return data;
          } else if (data.id == deletePartner?.id) {
            dataDelete = data;
          }
        });
        newShoppingCartPartner[item] = newData;
      });
      // console.log("delete id " + dataDelete?.id);
      let response = await axios.delete(
        `${CONFIG.BASE_URL}/api/shopping-cart/${dataDelete.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.data.success === true) {
        getShoppingCart();
      } else if (
        response.data["message"] == "Anda Masuk Dalam Daftar Blacklist"
      ) {
        setOpenDialog(true);
        setMessage(response.data.message);
      }
    }
  }
  async function cekMinTransaction() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/minimum-transaction/${dataUser?.user?.site_code}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data;
      if (data["success"] === true) {
        setMinBelanja(data?.data[0]?.min_transaction);
      } 
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error==========POST RECENT==============",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }
  const handleCloseShopping = (e) => {
    setOpenShopping(false);
    setTempCheck([]);
  };

  const handleCloseAlertBlacklist = () => {
    setOpenDialog(false);
    getShoppingCart();
  };
  function hitungMinBelanja() {
    let total =
      Math.round(subtotalPrice()) + Math.round(subTotalPricePartner()) ?? 0;
    let minOrder = Math.round(minBelanja) - total;
    if (Math.round(minBelanja) <= total || total == 0) {
      return total;
    } else {
      return minOrder;
    }
  }
  function cekMinBelanja() {
    let total =
      Math.round(subtotalPrice()) + Math.round(subTotalPricePartner()) ?? 0;
    if (Math.round(minBelanja) < total) {
      postBuyShoppingCart();
    } else {
      setAlertData("Minimal transaksi adalah Rp " + Math.round(minBelanja));
      setAlertJudul("Pemberitahuan");
      setOpen(true);
    }
  }
  // console.log("DataPartner " + JSON.stringify(minBelanja));
  return (
    <div className="cartContainer">
      <ModalShopping
        handleCloseShopping={handleCloseShopping}
        openShopping={openShopping}
      />
      <Dialog
        alertData={alertData}
        alertJudul={alertJudul}
        handleClose={handleClose}
        open={open}
      />
      <AlertBlacklist
        alertData={message}
        alertJudul="Pemberitahuan"
        handleClose={handleCloseAlertBlacklist}
        open={openDialog}
      />
      <DialogPartner
        alertData={alertDataPartner}
        alertJudul={alertJudulPartner}
        handleClose={handleClosePartner}
        open={openPartner}
      />
      <Header
        notifMessages={notifMessages}
        notifSubscribe={notifSubscribe}
        notifOrders={notifOrders}
        notifComplaints={notifComplaints}
        notifBroadcast={notifBroadcast}
        countNotifCart={countNotifCart}
        titleHeader="Keranjang"
        item={false}
      />
      {shoppingCartItems.length > 0 && !loadingApi ? (
        <>
          <div
            className="cartContent"
            style={{ marginTop: isGrosir || isStar || isSemiGrosir ? 150 : 85 }}
          >
            <div className="cartProduct" style={{ paddingTop: "5%" }}>
              <div
                onClick={() => selectHandlerAll("semut-gajah")}
                className="shop-name"
              >
                {shoppingCartItems.every((item) => item.checked == 1) ? (
                  <img
                    src={IconChecked}
                    alt="cartCheckBoxImage"
                    className="cartCheckBoxImage"
                  />
                ) : (
                  <img
                    src={IconCheck}
                    alt="cartCheckBoxImage"
                    className="cartCheckBoxImage"
                  />
                )}
                <p className="cartCheckBoxText">Produk semut gajah</p>
              </div>
              {shoppingCartItems.map((item, index) => {
                return (
                  <div key={index} className="cartProductContainer">
                    <div
                      onClick={() => selectHandler(index, item.checked)}
                      className="cartProductCheckBox"
                    >
                      {item.checked == 1 ? (
                        <img
                          src={IconChecked}
                          alt="cartCheckBoxImage"
                          className="cartCheckBoxImage"
                        />
                      ) : (
                        <img
                          src={IconCheck}
                          alt="cartCheckBoxImage"
                          className="cartCheckBoxImage"
                        />
                      )}
                    </div>
                    <div className="cartProductItem">
                      <div className="cartProductItemTop">
                        {item.image ? (
                          <img
                            src={CONFIG.BASE_URL + item.image}
                            alt="cartProductItemImage"
                            className="cartProductItemImage"
                          />
                        ) : (
                          <img
                            src={DummyImage}
                            alt="cartProductItemImage"
                            className="cartProductItemImage"
                          />
                        )}
                        <div className="cartProductItemText">
                          <p className="cartProductItemName">{item.name}</p>
                          <div className="halfView">
                            {dataUser.user.class == "GROSIR" &&
                              item.brand_id === "001" && (
                                <p className="textDeltomed">(Deltomed)</p>
                              )}
                            {dataUser.user.class == "SEMI GROSIR" &&
                              item.brand_id === "001" && (
                                <p className="textDeltomed">(Deltomed)</p>
                              )}
                            {dataUser.user.class == "STAR OUTLET" &&
                              item.brand_id === "001" && (
                                <p className="textDeltomed">(Deltomed)</p>
                              )}
                            {item.half ? (
                              <p className="isiHalf">
                                {"( "}
                                {item.qty_konversi}{" "}
                                {item.data_product.kecil.charAt(0) +
                                  item.data_product.kecil
                                    .slice(1)
                                    .toLowerCase()}
                                {" )"}
                              </p>
                            ) : null}
                          </div>
                          <NumberFormat
                            value={parseInt(item.total_price)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p className="cartProductItemPrice">
                                {value || 0}
                              </p>
                            )}
                          />
                          <div className="cartProductItemTrashContainer">
                            {visibleButton ? (
                              <img
                                onClick={() => deleteHandler(item)}
                                src={IconTrash}
                                alt="cartProductItemTrash"
                                className="cartProductItemTrash"
                              />
                            ) : (
                              <img
                                src={IconTrash}
                                alt="cartProductItemTrash"
                                className="cartProductItemTrash"
                              />
                            )}
                            {visibleButton ? (
                              <button
                                onClick={() => quantityHandler("less", index)}
                                className="cartProductItemQtyMinus"
                              >
                                <img
                                  src={IconMinus}
                                  alt="cartProductItemQtyMinusImage"
                                  className="cartProductItemQtyMinusImage"
                                />
                              </button>
                            ) : (
                              <button className="cartProductItemQtyMinus">
                                <img
                                  src={IconMinus}
                                  alt="cartProductItemQtyMinusImage"
                                  className="cartProductItemQtyMinusImage"
                                />
                              </button>
                            )}
                            {visibleButton ? (
                              <input
                                onChange={(ev) => getQuantity(ev, index)}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    getQuantity(e, index);
                                  }
                                }}
                                className="cartProductItemQtyInput"
                                type="number"
                                value={item.qty}
                                // placeholder={item.qty}
                              />
                            ) : (
                              <input
                                className="cartProductItemQtyInput"
                                type="text"
                                value={item.qty}
                                disabled
                              />
                            )}
                            {visibleButton ? (
                              <button
                                onClick={() => quantityHandler("more", index)}
                                className="cartProductItemQtyPlus"
                              >
                                <img
                                  src={IconPlus}
                                  alt="cartProductItemQtyPlusImage"
                                  className="cartProductItemQtyPlusImage"
                                />
                              </button>
                            ) : (
                              <button className="cartProductItemQtyPlus">
                                <img
                                  src={IconPlus}
                                  alt="cartProductItemQtyPlusImage"
                                  className="cartProductItemQtyPlusImage"
                                />
                              </button>
                            )}
                          </div>
                          {item.data_product?.promo_sku?.length > 0 && (
                            <div
                              className={description ? null : "viewPromoPopper"}
                            >
                              <div className="viewListPromo">
                                <p>
                                  {"( "}
                                  {item.data_product?.promo_sku[0]?.title}
                                  {" )"}
                                </p>
                              </div>
                              {description?.sku?.map((val, i) => {
                                if (
                                  item.data_product?.promo_sku[0]?.product_id ==
                                    val.product_id &&
                                  indexTips == index
                                ) {
                                  return (
                                    <Box
                                      key={i}
                                      // ref={setAnchorEl}
                                      sx={{
                                        border: 1,
                                        borderColor: "#ddd",
                                        borderRadius: 1,
                                        marginTop: 2,
                                        p: 2.5,
                                        bgcolor: "#f8f8f8",
                                        position: "relative",
                                      }}
                                    >
                                      <div className="arrow"></div>
                                      {description.description}
                                      <img
                                        onClick={() => {
                                          setDescription("");
                                          setToolTip(false);
                                        }}
                                        className="iconInfoInfo"
                                        src={IconClose}
                                      />
                                    </Box>
                                  );
                                }
                              })}
                              {!description && (
                                <img
                                  onClick={() => {
                                    setToolTip(true);
                                    setIndexTips(index);
                                    getPromo(
                                      item.data_product?.promo_sku[0]?.promo_id,
                                      item.data_product.id
                                    );
                                  }}
                                  className="iconInfo"
                                  src={IconInfo}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="cartProductItemBottom">
                        <input
                          onChange={(ev) => notesHandler(ev, index)}
                          type="text"
                          className="cartProductItemBottomInput"
                          placeholder="Tulis catatan untuk barang ini"
                          value={item.notes || ""}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <ProdukPartner
              // listData = {shoppingCartPartner}
              listDataList={newShoppingCartPartner}
              selectHandler={selectHandlerPartner}
              visibleButton={visibleButton}
              deleteHandler={deleteHandlerPartner}
              quantityHandler={quantityHandlerPartner}
              getQuantity={getQuantityPartner}
              description={description}
              indexTips={indexTips}
              notesHandler={notesHandlerPartner}
              selectHandlerAll={selectHandlerAll}
            />
            <hr></hr>
            {recomenProduct.length > 0 && (
              <div className="notifProduct" style={{ marginTop: 10 }}>
                <p className="homeProductTitle">Produk Rekomendasi</p>
                <div className="homeProductContainer">
                  {recomenProduct.map((item, index) => {
                    return (
                      <div key={index} className="homeProductItem">
                        {item.promo_sku.length > 0 && (
                          <div
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi");
                            }}
                            className="homeProductItemPromo"
                          >
                            <p className="homeProductItemPromoText">Promo</p>
                          </div>
                        )}
                        {item.image ? (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi");
                            }}
                            src={CONFIG.BASE_URL + item.image}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        ) : (
                          <img
                            onClick={() => {
                              postRecent(item);
                              dispatch(produkAction(item, "produkDesc"));
                              navigate("/produk/deskripsi");
                            }}
                            src={DummyImage}
                            alt="homeProductImage"
                            className="homeProductImage"
                          />
                        )}
                        <p
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi");
                          }}
                          className="homeProductItemTitle"
                        >
                          {item.name}
                        </p>
                        {item.status_promosi_coret == 1 ? (
                          <NumberFormat
                            value={
                              Math.round(
                                item?.price?.harga_promosi_coret_ritel_gt ||
                                  item?.price?.harga_promosi_coret_grosir_mt ||
                                  item?.price?.harga_promosi_coret_semi_grosir
                              ) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate("/produk/deskripsi");
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        ) : (
                          <NumberFormat
                            value={
                              Math.round(item?.price?.harga_ritel_gt) ?? "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp "}
                            renderText={(value) => (
                              <p
                                onClick={() => {
                                  postRecent(item);
                                  dispatch(produkAction(item, "produkDesc"));
                                  navigate("/produk/deskripsi");
                                }}
                                className="homeProductItemPrice"
                              >
                                {value || 0}
                              </p>
                            )}
                          />
                        )}
                        <div
                          onClick={() => {
                            postRecent(item);
                            dispatch(produkAction(item, "produkDesc"));
                            navigate("/produk/deskripsi");
                          }}
                          className="homeProductItemView"
                        >
                          <div className="homeProductItemRating">
                            <ReactStars
                              count={1}
                              size={24}
                              edit={false}
                              color={"#ffd700"}
                            />
                            <p className="homeProductItemRatingValue">
                              {item.review
                                ? item.review[0]
                                  ? item.review[0].avg_rating
                                  : "0"
                                : "0"}
                            </p>
                          </div>
                          {item.cart && (
                            <div className="homeProductItemRating">
                              <img className="notifListIcon" src={IconCart} />
                              <p className="homeProductItemRatingValue">
                                {item.cart.qty}
                              </p>
                            </div>
                          )}
                        </div>
                        {item.cart ? (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Tambah</p>
                          </button>
                        ) : (
                          <button
                            onClick={() => postShoppingCart(item)}
                            className="homeProductItemButton"
                          >
                            <p className="homeProductItemButtonBeli">Beli</p>
                          </button>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            <hr></hr>
            <div className="viewTotalHarga">
              <div>
                {Math.round(minBelanja) <=
                  Math.round(subtotalPrice()) +
                    Math.round(subTotalPricePartner()) ||
                Math.round(subtotalPrice()) +
                  Math.round(subTotalPricePartner()) ==
                  0 ? (
                  <>
                    <p>Total Harga</p>
                    <NumberFormat
                      value={Math.round(hitungMinBelanja()) ?? "0"}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"Rp "}
                      renderText={(value) => (
                        <p className="homeProductItemPrice">{value || 0}</p>
                      )}
                    />
                  </>
                ) : (
                  <>
                    <p>Minimal belanja {minBelanja}</p>
                    <NumberFormat
                      value={Math.round(hitungMinBelanja()) ?? "0"}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"- Rp "}
                      renderText={(value) => (
                        <p className="homeProductItemPrice">{value || 0}</p>
                      )}
                    />
                  </>
                )}
                {/* <p>Total Harga</p>
                <NumberFormat
                  value={
                    Math.round(subtotalPrice()) +
                      Math.round(subTotalPricePartner()) ?? "0"
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Rp "}
                  renderText={(value) => (
                    <p className="homeProductItemPrice">{value || 0}</p>
                  )}
                /> */}
              </div>
              {visibleButton ? (
                <>
                  {selectAll === true ||
                  shoppingCartItems.find(
                    (item) =>
                      item.checked === 1 ||
                      Math.round(subTotalPricePartner()) != 0
                  ) ? (
                    <button
                      className="viewTotalHargaButton"
                      onClick={() => cekMinBelanja()}
                    >
                      <p className="viewTotalHargaButtonText">{"Beli"}</p>
                    </button>
                  ) : (
                    <button
                      className="viewTotalHargaButton"
                      onClick={() => {
                        setAlertData("Harap Memilih Produk Diatas");
                        setAlertJudul("Pemberitahuan");
                        handleClickOpen();
                      }}
                    >
                      <p className="viewTotalHargaButtonText">{"Beli"}</p>
                    </button>
                  )}
                </>
              ) : null}
            </div>
          </div>
          <div className="subHeaderComponent">
            {isGrosir ? (
              <div className="cartTopCardContainer">
                <p className="cartTopCardText">
                  {
                    "Anda terdaftar sebagai kelas GROSIR. Sehingga mendapatkan diskon sebesar 4.5% pada brand DELTOMED"
                  }
                </p>
                <img
                  className="cartIconClose"
                  onClick={() => setIsGrosir(false)}
                  src={IconClose}
                />
              </div>
            ) : null}
            {isStar ? (
              <div className="cartTopCardContainer">
                <p className="cartTopCardText">
                  {
                    "Anda terdaftar sebagai kelas STAR OUTLET. Sehingga mendapatkan diskon sebesar 4.5% pada brand DELTOMED"
                  }
                </p>
                <img
                  className="cartIconClose"
                  onClick={() => setIsStar(false)}
                  src={IconClose}
                />
              </div>
            ) : null}
            {isSemiGrosir ? (
              <div className="cartTopCardContainer">
                <p className="cartTopCardText">
                  {
                    "Anda terdaftar sebagai kelas SEMI GROSIR. Sehingga mendapatkan diskon sebesar 3% pada brand DELTOMED"
                  }
                </p>
                <img
                  className="cartIconClose"
                  onClick={() => setIsSemiGrosir(false)}
                  src={IconClose}
                />
              </div>
            ) : null}
            <div
              onClick={() => selectHandlerAll("selectAll")}
              className="cartCheckBoxContainer"
            >
              {selectAll ? (
                <img
                  src={IconChecked}
                  alt="cartCheckBoxImage"
                  className="cartCheckBoxImage"
                />
              ) : (
                <img
                  src={IconCheck}
                  alt="cartCheckBoxImage"
                  className="cartCheckBoxImage"
                />
              )}
              <p className="cartCheckBoxText">Pilih Semua</p>
            </div>
          </div>
        </>
      ) : shoppingCartItems.length === 0 && !loadingApi ? (
        <NoData />
      ) : null}
      <Navbar screenName={"Keranjang"} />
      <Loading loadingApi={loadingApi} />
      <Popper
        // anchorEl={anchorEl}
        placement="bottom-end"
        open={toolTip}
        modifiers={[
          {
            name: "flip",
            enabled: true,
          },
          {
            name: "arrow",
            enabled: true,
            options: {
              element: ".arrow",
            },
          },
        ]}
      ></Popper>
      {alertServerSibuk ? alertBusy() : null}
      {alertServerMantenance ? alertMantenance() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
