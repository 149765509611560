import * as React from "react";
import { Routes, Route, Link } from "react-router-dom";
import "./App.css";
import Slider from "./components/Slider";
import Images from "./images";
import page from "./pages";
import FirstPage from "./pages/FirstPage";
import Home from "./pages/Home";
import NoMatch from "./pages/NoMatch";
import Produk from "./pages/Produk";
import ProdukList from "./pages/ProdukList";
import ProdukDeskripsi from "./pages/ProdukDeskripsi";
import ProductRating from "./pages/ProductRating";
import SearchList from "./pages/SearchList";
import Cart from "./pages/Keranjang";
import Delivery from "./pages/Delivery";
import PaymentMethod from "./pages/PaymentMethod";
import Chat from "./pages/Chat";
import SplashScreen from "./pages/SplashScreen";
import Login from "./pages/Login";
import RegisterOld from "./pages/RegisterOld";
import RegisterOldForm from "./pages/RegisterOldForm";
import RegisterNew from "./pages/RegisterNew";
import Identify from "./pages/Identify";
import ForgetPassword from "./pages/ForgetPassword";
import ChooseVerification from "./pages/ChooseVerification";
import SendOtp from "./pages/SendOtp";
import ForgetPasswordForm from "./pages/ForgetPasswordForm";
import Menu from "./pages/Menu";
import Notification from "./pages/Notification";
import NotificationListBelanja from "./pages/NotificationListBelanja";
import NotificationListKomplain from "./pages/NotificationListKomplain";
import NotificationListLangganan from "./pages/NotificationListLangganan";
import DataUser from "./pages/DataUser";
import UserEditName from "./pages/UserEditName";
import UserEditEmail from "./pages/UserEditEmail";
import UserEditPhone from "./pages/UserEditPhone";
import UserEditSendOtp from "./pages/UserEditSendOtp";
import Qr from "./pages/Qr";
import Transaction from "./pages/Transaction";
import TransactionDetail from "./pages/TransactionDetail";
import Pembayaran from "./pages/Pembayaran";
import Wishlist from "./pages/Wishlist";
import Subscribe from "./pages/Subscribe";
import ListSubscribe from "./pages/ListSubscribe";
import SubscribeDetail from "./pages/SubscribeDetail";
import SubscribeEdit from "./pages/SubscribeEdit";
import Complaint from "./pages/Complaint";
import ComplaintDetail from "./pages/ComplaintDetail";
import ComplaintReply from "./pages/ComplaintReply";
import ComplaintChoose from "./pages/ComplaintChoose";
import Keranjang from "./pages/Keranjang";
import ComplaintCreate from "./pages/ComplaintCreate";
import Faq from "./pages/Faq";
import FaqDetail from "./pages/FaqDetail";
import FaqTopik from "./pages/FaqTopik";
import PromoDetail from "./pages/PromoDetail";
import RewardDetail from "./pages/RewardDetail";
import PromoCalendar from "./pages/PromoCalendar";
import RewardCalendar from "./pages/RewardCalendar";
import Point from "./pages/Point";
import Kredit from "./pages/Kredit";
import ReviewHistory from "./pages/ReviewHistory";
import ReviewDetail from "./pages/ReviewDetail";
import WaitingRating from "./pages/WaitingRating";
import AddRating from "./pages/AddRating";
import ConfirmationOrder from "./pages/ConfirmationOrder";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import RegisterNewDistributor from "./pages/RegisterNewDistributor";
import DashboardHome from "./pages/DashboardHome";
import MenuDistributor from "./pages/MenuDistributor";
import TransactionDistributor from "./pages/TransactionDistributor";
import ProdukListDistributor from "./pages/ProdukListDistributor";
import TambahProdukDistributor from "./pages/TambahProdukDistributor";
import VoucherPartner from "./pages/VoucherPartner";
import ListChats from "./pages/ListChats";
import ProdukDeskripsiDistributor from "./pages/ProdukDeskripsiDistributor";
import DiskusiProdukDistributor from "./pages/DiskusiProdukDistributor";
import DiskusiProdukInputDistributor from "./pages/DiskusiProdukInputDistributor";
import ProfileDistributor from "./pages/ProfileDistributor";
import DistributorUlasan from "./pages/DistributorUlasan";
import DetailUlasan from "./pages/DetailUlasan";
import DistributorComplaintList from "./pages/DistributorComplaintList";
import DistributorComplaintDetail from "./pages/DistributorComplaintDetail";
import DistributorComplaintReply from "./pages/DistributorComplaintReply";
import DetailVoucher  from "./pages/DetailVoucher";
import SplashMission from "./pages/SplashMission"
import MissionList from "./pages/MissionList"
import MissionDetail from "./pages/MissionDetail"
import TopSpender from "./pages/TopSpender";
import StokKosong from "./pages/StokKosong";

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: ["Roboto", "sans-serif"],
      textTransform: "capitalize",
      fontSize: 16,
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Routes>
          <Route path="/" element={<SplashScreen />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route
            path="/forget-password/form"
            element={<ForgetPasswordForm />}
          />
          <Route path="/register/old" element={<RegisterOld />} />
          <Route path="/register/old/form" element={<RegisterOldForm />} />
          <Route path="/register/new" element={<RegisterNew />} />
          <Route path="/register/distributor" element={<RegisterNewDistributor />} />
          <Route path="/choose-verification" element={<ChooseVerification />} />
          <Route path="/identify" element={<Identify />} />
          <Route path="/home" element={<Home />} />
          <Route path="/dashboardhome" element={<DashboardHome />} />
          <Route path="/produk" element={<Produk />} />
          <Route path="/produk/add" element={<TambahProdukDistributor />} />
          <Route path="/produk/list" element={<ProdukList />} />
          <Route path="/produk/list/distributor" element={<ProdukListDistributor />} />
          <Route path="/produk/deskripsi" element={<ProdukDeskripsi />} />
          <Route path="/produk/deskripsi/distributor" element={<ProdukDeskripsiDistributor />} />
          <Route path="/produk/rating" element={<ProductRating />} />
          <Route path="/produk/profile" element={<ProfileDistributor />} />
          <Route path="/diskusi" element={<DiskusiProdukDistributor />} />
          <Route path="/diskusi/input" element={<DiskusiProdukInputDistributor />} />
          {/* <Route path="/search/list" element={<SearchList />} /> */}
          <Route path="/cart" element={<Cart />} />
          <Route path="/delivery" element={<Delivery />} />
          <Route path="/payment" element={<PaymentMethod />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/listchats" element={<ListChats />} />
          <Route path="/send-otp" element={<SendOtp />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/menu/distributor" element={<MenuDistributor />} />
          <Route path="/menu/voucher/distributor" element={<VoucherPartner />} />
          <Route path="/menu/voucher/detail" element={<DetailVoucher />} />
          <Route path="/menu/distributor/ulasan" element={<DistributorUlasan />}/>
          <Route path="/menu/distributor/ulasan/detail" element={<DetailUlasan/>}/>
          <Route path="/menu/distributor/complaint" element={<DistributorComplaintList/>}/>
          <Route path="/menu/distributor/complaint/detail" element={<DistributorComplaintDetail/>}/>
          <Route path="/menu/distributor/complaint/balas" element={<DistributorComplaintReply/>}/>
          <Route path="/notification" element={<Notification />} />
          <Route
            path="/notification/belanja"
            element={<NotificationListBelanja />}
          />
          <Route
            path="/notification/komplain"
            element={<NotificationListKomplain />}
          />
          <Route
            path="/notification/langganan"
            element={<NotificationListLangganan />}
          />
          <Route path="/user" element={<DataUser />} />
          <Route path="/user/edit/name" element={<UserEditName />} />
          <Route path="/user/edit/email" element={<UserEditEmail />} />
          <Route path="/user/edit/phone" element={<UserEditPhone />} />
          <Route path="/user/edit/phone/otp" element={<UserEditSendOtp />} />
          <Route path="/user/qr" element={<Qr />} />
          {/* <Route path="/transaction" element={<Transaction />} /> */}
          {/* <Route path="/transaction/detail" element={<TransactionDetail />} /> */}
          <Route path="/pembayaran" element={<Pembayaran />} />
          <Route path="/transaksi/distributor" element={<TransactionDistributor />} />
          <Route path="/pembayaran/detail" element={<TransactionDetail />} />
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/subscribe" element={<Subscribe />} />
          <Route path="/subscribe/list" element={<ListSubscribe />} />
          <Route path="/subscribe/detail" element={<SubscribeDetail />} />
          <Route path="/subscribe/edit" element={<SubscribeEdit />} />
          <Route path="/complaint" element={<Complaint />} />
          <Route path="/complaint/detail" element={<ComplaintDetail />} />
          <Route path="/complaint/reply" element={<ComplaintReply />} />
          <Route path="/complaint/choose" element={<ComplaintChoose />} />
          <Route path="/keranjang" element={<Keranjang />} />
          <Route path="/complaint/create" element={<ComplaintCreate />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/faq/detail" element={<FaqDetail />} />
          <Route path="/faq/topik" element={<FaqTopik />} />
          <Route path="/promo/detail" element={<PromoDetail />} />
          
          <Route path="/reward/detail" element={<RewardDetail />} />
          <Route path="/promo/calendar" element={<PromoCalendar />} />
          <Route path="/reward/calendar" element={<RewardCalendar />} />
          <Route path="/point" element={<Point />} />
          <Route path="/kredit" element={<Kredit />} />
          <Route path="/waiting-rating" element={<WaitingRating />} />
          <Route path="/rating/add" element={<AddRating />} />
          <Route path="/review/history" element={<ReviewHistory />} />
          <Route path="/review/detail" element={<ReviewDetail />} />
          <Route path="/order/confirmation" element={<ConfirmationOrder />} />
          <Route path="/mission" element={<SplashMission />} />
          <Route path="/mission/list" element={<MissionList />} />
          <Route path="/mission/detail" element={<MissionDetail />} />
          <Route path="/top-spender" element={<TopSpender />} />
          <Route path="/detail/transaksi/stok" element={<StokKosong />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
