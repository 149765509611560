import React, { useState } from "react";
import "../pages/Keranjang.css";
import IconCheck from "../assets/images/Check.webp";
import IconChecked from "../assets/images/Checked.webp";
import DummyImage from "../assets/images/Logo.webp";
import IconCart from "../assets/images/KeranjangActive.webp";
import Dialog from "../components/Dialog";
import IconClose from "../assets/images/Closemodal.webp";
import Popper from "@mui/material/Popper";
import Box from "@mui/material/Box";
import IconInfo from "../assets/images/Info.webp";
import NumberFormat from "react-number-format";
import IconTrash from "../assets/images/Trash.webp";
import IconPlus from "../assets/images/Plus.webp";
import IconMinus from "../assets/images/Minus.webp";
import ZeroCart from "../assets/images/ZeroCart.webp";
import CONFIG from "../config/config";
export default function ProdukPartner(props) {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  // console.log("log 28 " + JSON.stringify(props?.listDataList));
  const object = Object.keys(props?.listDataList);
  return props?.listDataList[object]?.length != 0 ? (
    <div>
      {object.map((_item, i) => {
        return (
          <div key={i} className="cartProduct">
            <div className="shop-name">
              {props?.listDataList[_item].every((item) => item.checked == 1) ? (
                <img
                  src={IconChecked}
                  alt="cartCheckBoxImage"
                  className="cartCheckBoxImage"
                  onClick={() => props.selectHandlerAll(_item)}
                />
              ) : (
                <img
                  src={IconCheck}
                  alt="cartCheckBoxImage"
                  className="cartCheckBoxImage"
                  onClick={() => props.selectHandlerAll(_item)}
                />
              )}
              <p className="cartCheckBoxText">
                {props?.listDataList[_item][0]?.shop_name}
              </p>
            </div>
            {props?.listDataList[_item]?.length != 0
              ? props?.listDataList[_item].map((item, index) => {
                  return (
                    <div key={index} className="cartProductContainer">
                      <div
                        onClick={() =>
                          props.selectHandler(item.id, item.checked, _item)
                        }
                        className="cartProductCheckBox"
                      >
                        {item.checked === 1 ? (
                          <img
                            src={IconChecked}
                            alt="cartCheckBoxImage"
                            className="cartCheckBoxImage"
                          />
                        ) : (
                          <img
                            src={IconCheck}
                            alt="cartCheckBoxImage"
                            className="cartCheckBoxImage"
                          />
                        )}
                      </div>
                      <div className="cartProductItem">
                        <div className="cartProductItemTop">
                          {item.data_product.product_image[0]?.path ? (
                            <img
                              src={
                                CONFIG.BASE_URL +
                                item.data_product.product_image[0]?.path
                              }
                              alt="cartProductItemImage"
                              className="cartProductItemImage"
                            />
                          ) : (
                            <img
                              src={DummyImage}
                              alt="cartProductItemImage"
                              className="cartProductItemImage"
                            />
                          )}
                          <div className="cartProductItemText">
                            <p className="cartProductItemName">
                              {item?.name || "Nama produk"}
                            </p>
                            <div className="halfView">
                              {dataUser.user.class == "GROSIR" &&
                                item.brand_id === "001" && (
                                  <p className="textDeltomed">(Deltomed)</p>
                                )}
                              {dataUser.user.class == "SEMI GROSIR" &&
                                item.brand_id === "001" && (
                                  <p className="textDeltomed">(Deltomed)</p>
                                )}
                              {dataUser.user.class == "STAR OUTLET" &&
                                item.brand_id === "001" && (
                                  <p className="textDeltomed">(Deltomed)</p>
                                )}
                              {item.half ? (
                                <p className="isiHalf">
                                  {"( "}
                                  {item.qty_konversi}{" "}
                                  {item.data_product.kecil.charAt(0) +
                                    item.data_product.kecil
                                      .slice(1)
                                      .toLowerCase()}
                                  {" )"}
                                </p>
                              ) : null}
                            </div>
                            <NumberFormat
                              value={parseInt(item.total_price)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"Rp "}
                              renderText={(value) => (
                                <p className="cartProductItemPrice">
                                  {value || 0}
                                </p>
                              )}
                            />
                            <div className="cartProductItemTrashContainer">
                              {props.visibleButton ? (
                                <img
                                  onClick={() =>
                                    props.deleteHandler(item.id, _item)
                                  }
                                  src={IconTrash}
                                  alt="cartProductItemTrash"
                                  className="cartProductItemTrash"
                                />
                              ) : (
                                <img
                                  src={IconTrash}
                                  alt="cartProductItemTrash"
                                  className="cartProductItemTrash"
                                />
                              )}
                              {props.visibleButton ? (
                                <button
                                  onClick={() =>
                                    props.quantityHandler(
                                      "less",
                                      item.id,
                                      _item
                                    )
                                  }
                                  className="cartProductItemQtyMinus"
                                >
                                  <img
                                    src={IconMinus}
                                    alt="cartProductItemQtyMinusImage"
                                    className="cartProductItemQtyMinusImage"
                                  />
                                </button>
                              ) : (
                                <button className="cartProductItemQtyMinus">
                                  <img
                                    src={IconMinus}
                                    alt="cartProductItemQtyMinusImage"
                                    className="cartProductItemQtyMinusImage"
                                  />
                                </button>
                              )}
                              {props.visibleButton ? (
                                <input
                                  onChange={(ev) =>
                                    props.getQuantity(ev, item.id, _item)
                                  }
                                  onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                      props.getQuantity(e, item.id, _item);
                                    }
                                  }}
                                  className="cartProductItemQtyInput"
                                  type="number"
                                  value={item.qty}
                                  // placeholder={item.qty}
                                />
                              ) : (
                                <input
                                  className="cartProductItemQtyInput"
                                  type="text"
                                  value={item.qty}
                                  disabled
                                />
                              )}
                              {props.visibleButton ? (
                                <button
                                  onClick={() =>
                                    props.quantityHandler(
                                      "more",
                                      item.id,
                                      _item
                                    )
                                  }
                                  className="cartProductItemQtyPlus"
                                >
                                  <img
                                    src={IconPlus}
                                    alt="cartProductItemQtyPlusImage"
                                    className="cartProductItemQtyPlusImage"
                                  />
                                </button>
                              ) : (
                                <button className="cartProductItemQtyPlus">
                                  <img
                                    src={IconPlus}
                                    alt="cartProductItemQtyPlusImage"
                                    className="cartProductItemQtyPlusImage"
                                  />
                                </button>
                              )}
                            </div>
                            <div className="viewPromoPopper">
                              <div className="viewListPromo">
                                <p>
                                  {"( "}
                                  "Promo"
                                  {" )"}
                                </p>
                              </div>
                              {/* looping */}
                              {/* <Box
                       sx={{
                         border: 1,
                         borderColor: "#ddd",
                         borderRadius: 1,
                         marginTop: 2,
                         p: 2.5,
                         bgcolor: "#f8f8f8",
                         position: "relative",
                       }}
                     >
                       <div className="arrow"></div>
                       description
                       <img
                         // onClick={() => {
                         //   setDescription("");
                         //   setToolTip(false);
                         // }}
                         className="iconInfoInfo"
                         src={IconClose}
                       />
                     </Box> */}
                            </div>
                          </div>
                        </div>
                        <div className="cartProductItemBottom">
                          <input
                            onChange={(ev) =>
                              props.notesHandler(ev, _item, item.id)
                            }
                            type="text"
                            className="cartProductItemBottomInput"
                            placeholder="Tulis catatan untuk barang ini"
                            defaultValue={item.notes || ""}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        );
      })}
    </div>
  ) : null;
}
