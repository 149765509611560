import React from "react";
import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import "react-calendar/dist/Calendar.css";
import "./CalendarDateRange.css";

function Kalender({
  setDate,
  date,
  selectRange,
  openCalendar,
  minDate,
  allowPartialRange,
  startDate,
  endDate,
}) {
  return (
    <div>
      {openCalendar ? (
        <DatePicker
        //   selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={setDate}
          withPortal
          selectsDisabledDaysInRange
          inline
          selectsRange
        />
      ) : null}
    </div>
  );
}

export default Kalender;
