import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Slider from "../components/Slider";
import Images from "../images";
import NumberFormat from "react-number-format";
import ReactStars from "react-rating-stars-component";
import ProgressBar from "@ramonak/react-progress-bar";
import "./ConfirmationOrder.css";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import Product1 from "../assets/dummy/product1.jpg";
import IconArrow from "../assets/images/backArrow.webp";
import IconNotifAll from "../assets/images/NotifAll.webp";
import IconMenu from "../assets/images/Menu.webp";
import { useSelector } from "react-redux";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import CircularProgress from "@mui/material/CircularProgress";
import { produkAction, screenAction, orderAction } from "../actions";
import { useDispatch } from "react-redux";
import FormDialog from "../components/Dialog";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";

export const Home = (props) => {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const { paymentOrder } = useSelector((state) => state.OrderReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [alertData, setAlertData] = useState("Pesanan sedang diproses");
  const [alertTambahan, setAlertTambahan] = useState("");
  const [alertJudul, setAlertJudul] = useState("");
  const [buttonDialogCancel, setButtonDialogCancel] = useState("Feedback");
  const [notifOrders, setNotifOrders] = useState(0);
  const [notifMessages, setNotifMessages] = useState(0);
  const [notifComplaints, setNotifComplaints] = useState(0);
  const [notifBroadcast, setNotifBroadcast] = useState(0);
  const [notifSubscribe, setNotifSubscribe] = useState(0);
  const [countNotifCart, setCountNotifCart] = useState(0);
  const [feedback, setFeedback] = useState(false);
  const [notes, setNotes] = useState("");
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            // setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            // setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  useEffect(() => {
    getNotifAll();

    return () => {};
  }, []);

  const handleClose = (e) => {
    setOpen(false);
    if (e == "cancel") {
      setFeedback(true);
    }
  };

  const handleCloseFeedback = () => {
    setFeedback(false);
  };

  async function postFeedBack() {
    try {
      console.log("masuk feedback", notes);
      let response = await axios({
        method: "post",
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        data: { message: notes },
        url: `${CONFIG.BASE_URL}/api/store/feedback`,
      });
      console.log("response==feedback", response.data);
      const data = response.data;
      if (data !== "" && data["success"] == true) {
        setFeedback(false);
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  async function getNotifAll() {
    console.log("cek hit notif");
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/notification-all`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      // console.log('DATA TEST', data.total_chat);
      setNotifOrders(data.total_order);
      setNotifMessages(data.total_chat);
      setNotifComplaints(data.total_complaint);
      setNotifSubscribe(data.total_subscribe);
      setNotifBroadcast(data.total_broadcast);
      setCountNotifCart(data.total_cart);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========NOTIF================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  function renderCountNotificationBadge() {
    try {
      let count = 0;
      let hasil = notifMessages;
      let hasil2 = notifSubscribe;
      let hasil3 = notifOrders;
      let hasil4 = notifComplaints;
      let hasil5 = notifBroadcast;
      count =
        count +
        parseInt(hasil) +
        parseInt(hasil2) +
        parseInt(hasil3) +
        parseInt(hasil4) +
        parseInt(hasil5);
      // console.log(count);
      if (count > 0) {
        return count;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
    }
  }

  function paymentFinal(){
    const sumTotal = (arr = []) =>
    arr.reduce(
      (sum, { payment_final }) =>
        sum + parseFloat(payment_final),
      0
    );
    const total = sumTotal(paymentOrder ?? 0);
    return total
  }

  return (
    <div className="homeContainer">
      <Dialog open={feedback} onClose={handleCloseFeedback}>
        <DialogTitle>Form Feedback</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Apakah ada saran dan masukkan untuk aplikasi ini?
          </DialogContentText>
          <TextField
            onChange={(e) => setNotes(e.target.value)}
            autoFocus
            margin="dense"
            id="name"
            label="Tuliskan feedback disni"
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFeedback}>Lewati</Button>
          <Button onClick={postFeedBack}>Kirim Feedback</Button>
        </DialogActions>
      </Dialog>
      <FormDialog
        alertData={alertData}
        alertJudul={alertJudul}
        handleClose={handleClose}
        open={open}
        buttonDialogCancel={buttonDialogCancel}
      />
      <div className="headerComponent">
        <div className="headerFirstContainer">
          <div onClick={() => navigate(-3)} className="headerArrowContainer">
            <img
              src={IconArrow}
              alt="headerIconArrow"
              className="headerIconArrow"
            />
          </div>
          <div className="headerTitleContainer">
            <p className="headerTitle">Menunggu Konfirmasi Order</p>
          </div>
          <div className="headerContainerMenu">
            <Stack>
              <Badge
                badgeContent={renderCountNotificationBadge()}
                color="success"
              >
                <img
                  src={IconNotifAll}
                  alt="headerMenuLogo"
                  className="headerMenuLogo"
                  onClick={() => navigate("/notification")}
                />
              </Badge>
            </Stack>
            <img
              onClick={() => navigate("/menu")}
              src={IconMenu}
              alt="headerMenuLogo"
              className="headerMenuLogo"
            />
          </div>
        </div>
      </div>
      <div className="homeContent">
        <div className="confirmationOrderTopContainer">
          <p className="confirmationOrderTopTitle">Detail Produk</p>
          {paymentOrder?.map((itemData, index) => {
            return itemData?.data_item.map((item, i) => {
              return (
                <div key={i} className="confirmationOrderTopCard">
                  {console.log("Data " + JSON.stringify(item.product))}
                  <div className="confirmationOrderTopFirst">
                    <div className="confirmationOrderTopCardLeft">
                      {item.product?.product_image.length != 0 ? (
                        <img
                          src={
                            CONFIG.BASE_URL +
                            item.product?.product_image[0]?.src_image
                          }
                          alt="product1"
                          className="confirmationOrderTopCardLeftImage"
                        />
                      ) : (
                        <img
                          src={DummyImage}
                          alt="product1"
                          className="confirmationOrderTopCardLeftImage"
                        />
                      )}
                    </div>
                    <div className="confirmationOrderTopCardRight">
                      <p className="confirmationOrderTopCardRightTitle">
                        {item.product?.name}
                      </p>
                      <div className="halfView">
                        {paymentOrder.status_faktur === "Redeem" ? (
                          <NumberFormat
                            value={Math.round(item?.price_apps) ?? "0"}
                            displayType={"text"}
                            thousandSeparator={true}
                            // prefix={'Rp '}
                            suffix={" poin"}
                            renderText={(value) => (
                              <p className="confirmationOrderTopCardRightText">
                                {value || 0}
                              </p>
                            )}
                          />
                        ) : (
                          <>
                            {item.half ? (
                              <NumberFormat
                                value={Math.round(item.price_apps / 2) ?? "0"}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"Rp "}
                                renderText={(value) => (
                                  <p className="confirmationOrderTopCardRightText">
                                    {item.qty} x {value || 0}
                                  </p>
                                )}
                              />
                            ) : (
                              <NumberFormat
                                value={Math.round(item.price_apps) ?? "0"}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"Rp "}
                                renderText={(value) => (
                                  <p className="confirmationOrderTopCardRightText">
                                    {item.qty} x {value || 0}
                                  </p>
                                )}
                              />
                            )}
                          </>
                        )}
                        {item.half ? (
                          <p className="confirmationOrderTopCardRightTextHalf">
                            {" ( "}
                            {item.qty_konversi}{" "}
                            {item.small_unit.charAt(0) +
                              item?.small_unit.slice(1).toLowerCase()}
                            {" )"}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <hr></hr>
                  <table>
                    {item.notes ? (
                      <tr>
                        <td className="confirmationOrderTopCardTotal">
                          {"Catatan"}
                        </td>
                        <td className="confirmationOrderTopCardTotal">{":"}</td>
                        <td className="confirmationOrderTopCardTotal">
                          {item.notes}
                        </td>
                      </tr>
                    ) : null}
                    {paymentOrder.status_faktur === "Redeem" ? (
                      <tr>
                        <td className="confirmationOrderTopCardTotal">
                          {"Total Poin"}
                        </td>
                        <td className="confirmationOrderTopCardTotal">{":"}</td>
                        <NumberFormat
                          value={Math.round(itemData.total_price) ?? "0"}
                          displayType={"text"}
                          thousandSeparator={true}
                          // prefix={"Rp "}
                          suffix={" poin"}
                          renderText={(value) => (
                            <td className="confirmationOrderTopCardTotal">
                              {value || 0}
                            </td>
                          )}
                        />
                      </tr>
                    ) : (
                      <tr>
                        <td className="confirmationOrderTopCardTotal">
                          {"Total Harga"}
                        </td>
                        <td className="confirmationOrderTopCardTotal">{":"}</td>
                        <NumberFormat
                          value={Math.round(item.total_price) ?? "0"}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"Rp "}
                          renderText={(value) => (
                            <td className="confirmationOrderTopCardTotal">
                              {value || 0}
                            </td>
                          )}
                        />
                      </tr>
                    )}
                  </table>
                </div>
              );
            });
          })}
          {paymentOrder.status_faktur === "Redeem" ? (
            <div className="confirmationOrderCenterContainer">
              <p className="confirmationOrderCenterTitle">Total Pembelian</p>
              <NumberFormat
                value={Math.round(paymentFinal()) ?? "0"}
                displayType={"text"}
                thousandSeparator={true}
                // prefix={"Rp "}
                suffix={" poin"}
                renderText={(value) => (
                  <p className="confirmationOrderCenterText">{value || 0}</p>
                )}
              />
            </div>
          ) : (
            <div className="confirmationOrderCenterContainer">
              <p className="confirmationOrderCenterTitle">Total Pembayaran</p>
              <NumberFormat
                value={Math.round(paymentFinal()) ?? "0"}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"Rp "}
                renderText={(value) => (
                  <p className="confirmationOrderCenterText">{value || 0}</p>
                )}
              />
            </div>
          )}
          <div className="confirmationOrderBottomContainer">
            <div className="confirmationOrderBottomFirst">
              <p className="confirmationOrderBottomFirstTitle">
                Pesananmu akan dikonfirmasi oleh penjual
              </p>
            </div>
            <div className="confirmationOrderBottomSecond">
              {paymentOrder.status_faktur === "Redeem" ? (
                <button
                  onClick={() => {
                    dispatch(screenAction("produk", "screenName"));
                    navigate(-2);
                  }}
                  className="confirmationOrderBottomSecondButton"
                >
                  <p className="confirmationOrderBottomSecondButtonText">
                    Redeem Poin Lagi
                  </p>
                </button>
              ) : (
                <button
                  onClick={() => {
                    dispatch(screenAction("produk", "screenName"));
                    navigate("/produk");
                  }}
                  className="confirmationOrderBottomSecondButton"
                >
                  <p className="confirmationOrderBottomSecondButtonText">
                    Belanja Lagi
                  </p>
                </button>
              )}
              {paymentOrder.status_faktur === "Redeem" ? (
                <button
                  onClick={() => navigate("/point")}
                  className="confirmationOrderBottomSecondButton"
                >
                  <p className="confirmationOrderBottomSecondButtonText">
                    Lihat History Poin
                  </p>
                </button>
              ) : (
                <button
                  onClick={() => navigate("/pembayaran")}
                  className="confirmationOrderBottomSecondButton"
                >
                  <p className="confirmationOrderBottomSecondButtonText">
                    Lihat Daftar Transaksi
                  </p>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Navbar screenName={"Pembayaran"} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
