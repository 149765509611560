export const SET_USER = "SET_USER";
export const SET_SCREEN = "SET_SCREEN";
export const SET_PARAMS = "SET_PARAMS";
export const SET_PRODUK = "SET_PRODUK";
export const SET_ORDER = "SET_ORDER";
export const SET_HELP = "SET_HELP";
export const SET_PROMO = "SET_PROMO";
export const SET_ID = "SET_ID";

export const userAction = (value, tipe) => {
  return (dispatch) => {
    dispatch({ type: "SET_USER", inputValue: value, inputType: tipe });
  };
};

export const screenAction = (value, tipe) => {
  return (dispatch) => {
    dispatch({ type: "SET_SCREEN", inputValue: value, inputType: tipe });
  };
};

export const paramsAction = (value, tipe) => {
  return (dispatch) => {
    dispatch({ type: "SET_PARAMS", inputValue: value, inputType: tipe });
  };
};

export const produkAction = (value, tipe) => {
  return (dispatch) => {
    dispatch({ type: "SET_PRODUK", inputValue: value, inputType: tipe });
  };
};

export const orderAction = (value, tipe) => {
  return (dispatch) => {
    dispatch({ type: "SET_ORDER", inputValue: value, inputType: tipe });
  };
};

export const helpAction = (value, tipe) => {
  return (dispatch) => {
    dispatch({ type: "SET_HELP", inputValue: value, inputType: tipe });
  };
};

export const promoAction = (value, tipe) => {
  return (dispatch) => {
    dispatch({ type: "SET_PROMO", inputValue: value, inputType: tipe });
  };
};

export const idAction = (value, tipe) => {
  return (dispatch) => {
    dispatch({ type: "SET_ID", inputValue: value, inputType: tipe });
  };
};