import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Slider from "../components/Slider";
import Images from "../images";
import NumberFormat from "react-number-format";
import ReactStars from "react-rating-stars-component";
import "./Chat.css";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import Logo from "../assets/images/Logo.webp";
import IconNotifAll from "../assets/images/NotifAll.webp";
import IconShoppingCart from "../assets/images/Shopping-Cart.webp";
import IconMenu from "../assets/images/Menu.webp";
import IconSend from "../assets/images/Send.webp";
import axios from "axios";
import CONFIG from "../config/config";
import { useSelector } from "react-redux";
import DummyImage from "../assets/images/Logo.webp";
import { useDispatch } from "react-redux";
import { screenAction } from "../actions";
import moment from "moment";
import { getDisplayName } from "@mui/utils";
import { CircularProgress } from "@mui/material";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useLocation } from "react-router-dom";
import IconClose from "../assets/images/Closemodalsearch.webp";

export const Home = (props) => {
  // const { itemId, otherParam } = route.params;
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const { screenName } = useSelector((state) => state.ScreenReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [item, setItem] = useState(location?.state?.item);
  const [messages, setMessages] = useState([]);
  const [textMessages, setTextMessages] = useState("");
  const [chat, setChat] = useState([]);
  const [loadingApi, setLoadingApi] = useState(true);
  const [notifOrders, setNotifOrders] = useState(0);
  const [notifMessages, setNotifMessages] = useState(0);
  const [notifComplaints, setNotifComplaints] = useState(0);
  const [notifBroadcast, setNotifBroadcast] = useState(0);
  const [notifSubscribe, setNotifSubscribe] = useState(0);
  const [countNotifCart, setCountNotifCart] = useState(0);
  const [isVisibleScroll, setIsVisibleScroll] = useState(false);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  let setIntervalTime;

  useEffect(() => {
    getNotifAll();
    getChatFirst();

    return () => {
      clearInterval(setIntervalTime);
    };
  }, []);

  async function getChatFirst() {
    try {
      let response;
      if (location.state?.partner_id && location.state?.listChat == undefined) {
        console.log("masuk kesatu with params partner_id");
        response = await axios.get(
          `${CONFIG.BASE_URL}/api/chats?partner_id=${location.state?.partner_id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const data = response.data.data;

        callback(data[0]?.chat_id);
        setChat(data);
        setLoadingApi(false);
        setIntervalTime = setInterval(() => {
          console.log("dataWillMount====>", data);
          callback(data[0]?.chat_id);
        }, 5000);
      } else if (
        location.state?.listChat == undefined &&
        location.state?.partner_id == undefined
      ) {
        console.log("masuk kedua tanpa params");
        response = await axios.get(
          `${CONFIG.BASE_URL}/api/chats?partner_id=${""}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const data = response.data.data.data;
        callback(data[0]?.chat_id);
        setChat(data);
        setLoadingApi(false);
        setIntervalTime = setInterval(() => {
          console.log("dataWillMount====>", data);
          callback(data[0]?.chat_id);
        }, 5000);
      } else if (location.state?.listChat) {
        console.log("masuk ketiga with params listChat");
        callback(location.state?.listChat?.chat_id);
        setLoadingApi(false);
        setIntervalTime = setInterval(() => {
          callback(location.state?.listChat?.chat_id);
        }, 5000);
      }
    } catch (error) {
      setLoadingApi(false);
      let error429 =
        JSON.parse(JSON.stringify(error))?.message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error))?.message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error))?.message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error))?.message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        // navigate('/home')
      }
    }
  }

  async function callback(chatId) {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/message/${chatId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = response.data.data;
      let message = [];
      data.map((item, i) => {
        let parseData = JSON.parse(item.data_content);
        let newContent = { ...item };

        newContent = {
          ...newContent,
          parseData,
        };
        message = [...message, newContent];
      });
      setMessages(message.reverse());
    } catch (error) {
      console.log("getMessage====>", error);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  async function onSend() {
    const formData = new FormData();
    if (textMessages !== "") {
      if (dataUser?.user?.account_type != 5) {
        formData.append("message", textMessages);
        if (location?.state?.listChat?.chat_id) {
          formData.append("chat_id", "8881de9d-8d80-438b-ad49-6f148ac330d5");
        }

        location.state.partner_id
          ? formData.append("partner_id", location.state.partner_id)
          : location.state.listChat
          ? formData.append("partner_id", location.state.listChat.to_id)
          : formData.append("partner_id", "");

        if (location.state.item) {
          formData.append("data_content", JSON.stringify(location.state.item));
        }
      }

      if (dataUser?.user?.account_type == 5) {
        formData.append("message", textMessages);

        formData.append("chat_id", location?.state?.listChat?.chat_id);

        if (location.state.item) {
          formData.append("data_content", JSON.stringify(location.state.item));
        }
      }
    }
    for (var pair of formData.entries()) {
      console.log(pair[0] + " - " + pair[1]);
    }
    try {
      let response = await axios({
        method: "post",
        url: `${CONFIG.BASE_URL}/api/message`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: formData,
      });
      const data = response.data.data;
      console.log("data send message", data);
      setMessages((currentMessages) => [...currentMessages, textMessages]);
      deleteItem();
      setTextMessages("");
      clearInterval(this.setIntervalTime);
    } catch (error) {
      console.log(error);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        // navigate('/home')
      }
    }
  }

  async function getNotifAll() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/notification-all`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      setNotifOrders(data.total_order);
      setNotifMessages(data.total_chat);
      setNotifComplaints(data.total_complaint);
      setNotifSubscribe(data.total_subscribe);
      setNotifBroadcast(data.total_broadcast);
      setCountNotifCart(data.total_cart);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========NOTIF================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        // navigate('/home')
      }
    }
  }

  function getDisplayName(value) {
    let name = value
      ?.match(/(\b\S)?/g)
      .join("")
      .toUpperCase();
    if (name == null || name == "" || name == undefined) {
      return (name = "NA");
    }
    return name;
  }

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisibleScroll(true);
    } else {
      setIsVisibleScroll(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    scrollToBottom.remove();
  };

  const deleteItem = () => {
    setItem(null);
  };
  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);
  return (
    <div className="chatContainer">
      <Header
        notifMessages={notifMessages}
        notifSubscribe={notifSubscribe}
        notifOrders={notifOrders}
        notifComplaints={notifComplaints}
        notifBroadcast={notifBroadcast}
        countNotifCart={countNotifCart}
        titleHeader="Keranjang"
        lastSeen={chat ? chat[0]?.last_login : null}
        screenName={"chat"}
        item={true}
        link={dataUser.user.account_type}
        search={false}
      />
      <div className="chatContent">
        <div className="chatHeader">
          <p className="chatHeaderText">Feb 14, 2022</p>
        </div>
        {messages.map((i, ind) => {
          if (i.from_id === dataUser.user.id) {
            console.log("masuk chat kanan");
            return (
              <div key={ind} className="chatContentRight">
                {i.data_content ? (
                  <div className="chatContentRightBubble-image">
                    <div className="chatContentRightBubble-image-div-image">
                      <img
                        style={{ width: "30%", height: "30%" }}
                        src={
                          CONFIG.BASE_URL + i.parseData.product_image[0].path
                        }
                      />
                      <div className="chatContentRightBubble-image-text">
                        <p>{i.parseData.name}</p>
                        <NumberFormat
                          value={
                            Math.round(i.parseData.price.harga_ritel_gt) ?? "0"
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"Rp "}
                          renderText={(value) => <p>{value || 0}</p>}
                        />
                      </div>
                    </div>
                    {/* <div>
                      <p>Beli</p>
                    </div> */}
                  </div>
                ) : null}
                <div className="chatContentRightBubble">
                  <p className="chatContentRightText">{i.message}</p>
                  <p className="chatContentRightTime">
                    {moment(i.sended_at).format("MM:hh")}
                  </p>
                </div>
              </div>
            );
          } else if (ind.length - 1 && isVisibleScroll) {
            return (
              <div onClick={scrollToTop} className="chatScrollUp">
                <img src={require("../assets/images/ArrowDown.webp")} />
              </div>
            );
          } else {
            console.log("masuk chat kiri");
            return (
              <div key={ind} className="chatContentLeft">
                <div className="chatContentLeftProfile">
                  <p className="chatContentLeftProfileText">
                    {i.name ? getDisplayName(i.name) : null}
                  </p>
                </div>
                {i.data_content !== null ? (
                  <div>
                    <div className="chatContentItemLeft">
                      {i.parseData?.product_image?.length > 0 ? (
                        <img
                          style={{ width: "100px", height: "200px" }}
                          src={
                            CONFIG.BASE_URL +
                            i.parseData?.product_image[0]?.path
                          }
                        />
                      ) : (
                        <img
                          style={{ width: "100px", height: "200px" }}
                          src={DummyImage}
                        />
                      )}
                      <div>
                        <p className="chatContentItemText">
                          {i.parseData?.name}
                        </p>
                        <p className="chatContentItemText">
                          Rp {i.parseData?.price?.harga_ritel_gt}
                        </p>
                      </div>
                    </div>
                    <div className="chatContentLeftBubble">
                      <p className="chatContentLeftText">{i.message}</p>
                      <p className="chatContentLeftTime">
                        {moment(i.sended_at).format("MM:hh")}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="chatContentLeftBubble">
                    <p className="chatContentLeftText">{i.message}</p>
                    <p className="chatContentLeftTime">
                      {moment(i.sended_at).format("MM:hh")}
                    </p>
                  </div>
                )}
              </div>
            );
          }
        })}

        <div ref={messagesEndRef} />
      </div>
      {item != undefined ? (
        <div className="chat-content-item">
          <img
            className="chat-content-item-image"
            src={CONFIG.BASE_URL + item.product_image[0].path}
          />

          <div className="chat-content-item-text">
            <p>{item.name}</p>
            <NumberFormat
              value={Math.round(item?.price?.harga_ritel_gt) ?? "0"}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"Rp "}
              renderText={(value) => <p>{value || 0}</p>}
            />
          </div>
          <div className="chat-content-item-icon" onClick={() => deleteItem()}>
            <img
              style={{ backgroundColor: "#FFF", width: "20px", height: "20px" }}
              src={IconClose}
            />
          </div>
        </div>
      ) : null}
      <div className="chatNavbar">
        <div className="chatNavbarInput">
          <input
            value={textMessages}
            className="chatNavbarInputText"
            placeholder="Silahkan Ketik Chat Disini..."
            onChange={(e) => setTextMessages(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                onSend();
              }
            }}
          />
        </div>
        <div onClick={() => onSend()} className="chatNavbarIcon">
          <img
            src={IconSend}
            alt="chatNavbarIconLogo"
            className="chatNavbarIconLogo"
          />
        </div>
      </div>
      <Loading loadingApi={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
