import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import IconBack from "../assets/images/backArrow.webp";
import IconBag from "../assets/images/ShoppingBag.webp";
import "./Kredit.css";
import { useSelector } from "react-redux";
import axios from "axios";
import CONFIG from "../config/config";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import NumberFormat from "react-number-format";
import error404 from "../assets/images/404.webp";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { orderAction } from "../actions";
import Header from "../components/HeaderDistributor";

function NoData() {
  return (
    <div className="NoData">
      <img style={{ height: "50%", width: "100%" }} src={error404} />
    </div>
  );
}

export const Point = (props) => {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const { screenName } = useSelector((state) => state.ScreenReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pointHistory, setPointHistory] = useState([]);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const [loadingApi, setLoadingApi] = useState(true);
  const [
    onEndReachedCalledDuringMomentum,
    setOnEndReachedCalledDuringMomentum,
  ] = useState(false);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  useEffect(() => {
    getPage();

    return () => {};
  }, []);

  useEffect(() => {
    if (page) {
      getPage();
    }

    return () => {};
  }, [page, loadingMore]);

  async function getPage() {
    await axios({
      method: "get",
      url: `${CONFIG.BASE_URL}/api/point/history`,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
      .then((response) => {
        const data = response.data.data;
        console.log("data==========", data);
        setLoadingApi(false);
        setPointHistory(
          page == 1 ? data.data : [...pointHistory, ...data.data]
        );
        setRefreshing(false);
        setLoadingMore(data.last_page > page);
        setMaxPage(data.last_page);
      })
      .catch((error) => {
        setLoadingApi(false);
        let error429 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 429";
        let errorNetwork =
          JSON.parse(JSON.stringify(error)).message === "Network Error";
        let error400 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 400";
        console.log(
          "Cek Error========================",
          JSON.parse(JSON.stringify(error)).message
        );
        if (error429) {
          setAlertServerSibuk(true);
        } else if (errorNetwork) {
          setAlertInet(true);
        } else if (error400) {
          localStorage.removeItem("token");
          navigate("/home");
        }
      });
  }

  //load more data
  async function getMoreData() {
    console.log("cekk", !onEndReachedCalledDuringMomentum);
    console.log("cekk", page);
    console.log("cekk", maxPage);
    if (!onEndReachedCalledDuringMomentum && page < maxPage) {
      console.log("masuk load");
      setPage(page + 1);
      setLoadingMore(page < maxPage);
      console.log("ini ketiga", page, loadingMore);
    }
    setOnEndReachedCalledDuringMomentum(page >= maxPage);
  }

  function handleRefresh() {
    setPage(1);
    setRefreshing(true);
  }

  function handleLoadMore() {
    console.log("cekkkkk saiki", !loadingMore);
    if (!loadingMore) return null;
    return (
      <div>
        <CircularProgress color="success" />
      </div>
    );
  }
  console.log("point " + JSON.stringify(pointHistory));
  return (
    <div className="pointContainer">
      <div className="pointHeader">
        <Header titleHeader="Point" />
        <div className="pointHeaderCard">
          <div className="pointHeaderMain">Poin</div>
          <div className="pointHeaderMain">{dataUser.user.point ?? 0}</div>
        </div>
        <div className="pointHeaderFooter">Transaksi Terakhir</div>
      </div>
      <div className="pointListMain">
        {pointHistory.length > 0 && !loadingApi ? (
          <InfiniteScroll
            dataLength={pointHistory.length}
            next={() => getMoreData()}
            hasMore={true}
            loader={() => handleLoadMore()}
            // refreshFunction={handleRefresh}
            // pullDownToRefresh
            // pullDownToRefreshThreshold={50}
            // pullDownToRefreshContent={handleLoadMore}
          >
            {pointHistory.map((item, index) => {
              return (
                <div
                  onClick={() => {
                    dispatch(orderAction(item.order, "orderDetail"));
                    navigate("/pembayaran/detail");
                  }}
                  key={index}
                  className="pointListCard"
                >
                  <div className="pointListHeader">
                    <img
                      src={IconBag}
                      alt="pointListIcon"
                      className="pointListIcon"
                    />
                    {item?.order
                      ?  "Transaksi # " + item?.order?.invoice
                      : "Reward Misi  "}
                    <div className="pointListRight">
                      {moment(item.created_at).format("DD MMM YYYY HH:mm")}
                    </div>
                  </div>
                  <div className="pointListContent">
                    <div className="middle">
                      <div className="pointListContentHeader">
                        {item.status}
                      </div>
                      {item.deposit ? (
                        <div className="point">+ {item.deposit}</div>
                      ) : null}
                      {item.kredit ? (
                        <div className="point">- {item.kredit}</div>
                      ) : null}
                    </div>
                    {item.kredit ? (
                      <div className="pointListContentMain">
                        pembelian dari poin anda berhasil
                      </div>
                    ) : (
                      <div className="pointListContentMain">
                        poin dari semutgajah berhasil
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </InfiniteScroll>
        ) : pointHistory.length == 0 && !loadingApi ? (
          <NoData />
        ) : null}
      </div>
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
      <Loading loadingApi={loadingApi} />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Point);
