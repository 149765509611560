import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import "./AlertBlacklist.css";
import Modal from "@mui/material/Modal";
import IconCLose from "../assets/images/IconClose.webp";
import IconLonceng from "../assets/images/IconLonceng.webp";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomAlert({
  open,
  handleClose,
  titleAlert,
  titleDescription,
  textBtn,
  typeOf,
  images,
}) {
  return typeOf ? (
    <Modal
      open={open}
      keepMounted
      TransitionComponent={Transition}
      className="custom-alert-images-dialog"
    >
      <div className="custom-alert-type-image">
        <div
          onClick={(e) => handleClose((e = "ok"))}
          className="icon-custom-alert-type-image"
        >
          <img src={IconCLose} className="icon-close-custom-alert-type-image" />
        </div>
        <img src={images} className="custom-alert-type-image-image" />
      </div>
    </Modal>
  ) : (
    <Modal
      open={open}
      TransitionComponent={Transition}
      keepMounted
      // onClose={handleClose}
      className="container-alert-custom"
    >
      <div className="div-alert-content">
        <img  onClick={(e) => handleClose((e = "ok"))} src={IconCLose} className="div-alert-custom-icon-close" />
        <img src={IconLonceng} className="div-alert-custom-icon-icon" />
        <div className="div-alert-custom">{titleAlert}</div>
        <div className="div-alert-custom-desc">{titleDescription}</div>
        <div className="div-btn-alert-custom">
          <div
            className="btn-alert-custom"
            onClick={(e) => handleClose((e = "ok"))}
          >
            {textBtn}
          </div>
        </div>
      </div>
    </Modal>
  );
}
