import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Slider from "../components/Slider";
import Images from "../images";
import Logo from "../assets/images/Logo.webp";
import IconSearch from "../assets/images/Search.webp";
import IconNotifAll from "../assets/images/NotifAll.webp";
import IconShoppingCart from "../assets/images/Shopping-Cart.webp";
import IconMenu from "../assets/images/Menu.webp";
import "./Header.css";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import axios from "axios";
import CONFIG from "../config/config";
import { useSelector } from "react-redux";
import moment from "moment";
import IconBack from "../assets/images/backArrow.webp";

export default function HeaderDistributor(props) {
  // console.log("cek props header======", props);
  const navigate = useNavigate();

  function renderCountNotificationBadge() {
    try {
      let count = 0;
      let hasil = props.notifMessages;
      let hasil2 = props.notifSubscribe;
      let hasil3 = props.notifOrders;
      let hasil4 = props.notifComplaints;
      let hasil5 = props.notifBroadcast;
      count =
        count +
        parseInt(hasil) +
        parseInt(hasil2) +
        parseInt(hasil3) +
        parseInt(hasil4) +
        parseInt(hasil5);
      // console.log(count);
      if (count > 0) {
        return count;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
    }
  }

  const item = [
    <Link to="/dashboardhome">
      <img src={Logo} alt="headerLogo" className="headerLogo" />
    </Link>,
    <div className="divTitle">
      <div onClick={() => navigate(-1)} className="headerArrowContainer">
        <img src={IconBack} alt="headerIconArrow" className="headerIconArrow" />
      </div>
      <div className="headerTitleContainer">
        {props.titleHeader}
      </div>
    </div>,
  ];

  // props.item={item[0]}
  return (
    <div className="headerDistributor">
      <div className="headerContainerLogo">
        {props.item == true ? item[0] : item[1]}
      </div>
      {/* {props.screenName == "chat" ? (
        <div className="headerSeenContainer">
          {props.lastSeen ? (
            <p className="headerSeenText">
              {"Terakhir Login : "}
              {moment(props.lastSeen).fromNow()}
            </p>
          ) : null}
        </div>
      ) : null} */}
      <div className="headerContainerMenu">
        <div className="headerContainerIcon">
          {props.badge == true ? (
            <Link to="/notification">
              <Stack>
                <Badge
                  badgeContent={renderCountNotificationBadge()}
                  color="success"
                >
                  <img
                    src={IconNotifAll}
                    alt="headerMenuLonceng"
                    className="headerMenuLonceng"
                  />
                </Badge>
              </Stack>
            </Link>
          ) : null}
        </div>
        <div className="headerContainerIcon">
          {props.linkProfile == true ? (
            <Link to="/menu/distributor">
              <img
                src={IconMenu}
                alt="headerMenuLogo"
                className="headerMenuLogo"
              />
            </Link>
          ) : null}
        </div>
      </div>
    </div>
  );
}
