import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Slider from "../components/Slider";
import Images from "../images";
import axios from "axios";
import CONFIG from "../config/config";
import { Alert } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import "./SplashScreen.css";

export const SplashScreen = (props) => {
  const [version, setVersion] = useState("");
  const [require_update, setRequire_update] = useState(false);
  const [optional_update, setOptional_update] = useState(false);
  const [loading, setLoading] = useState(true);

  function Loading() {
    return (
      <div className="loading">
        <CircularProgress color="success" />
      </div>
    );
  }
  const navigate = useNavigate();

  useEffect(() => {
    const intro = localStorage.getItem("intro");
    console.log("intro", intro);
    console.log("require_update", require_update);
    console.log("optional_update", optional_update);
    if (intro == "true") {
      console.log("masuk ke 1");
      navigate("login");
    } else if (intro == null || intro == "") {
      console.log("masuk ke 2");
      setLoading(false);
    } else {
      console.log("masuk ke 3");
      setLoading(false);
      return;
    }
  });

  if (loading) {
    return (
      <div className="loginContainer">
        <Loading />
      </div>
    );
  } else {
    return <Slider images={Images} />;
  }
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SplashScreen);
