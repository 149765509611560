import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ResponsiveDialog({
  openShopping,
  handleCloseShopping,
}) {
  //   const [openShopping, setOpenShopping] = useState(false);

  //   const handleClickOpenShopping = () => {
  //     setOpenShopping(true);
  //   };

  //   const handleCloseShopping = () => {
  //     setOpenShopping(false);
  //   };

  return (
    <div>
      <Dialog
        open={openShopping}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseShopping}
        aria-describedby="alert-dialog-slide-description"
        sx={{borderRadius: 10}}
      >
        {/* <DialogTitle>{alertJudulShopping}</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {"Pesanan sudah ditambahkan kedalam keranjang"}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{justifyContent: 'center', marginBottom: '10px'}}>
          <Button
            sx={{
              width: "45%",
              color: "#000",
              borderRadius: 3,
              borderColor: "#000",
              border: 1,
              backgroundColor: "#fff",
              textTransform: 'capitalize'
            }}
            onClick={(e) => handleCloseShopping((e = "cancel"))}
          >
            Kembali
          </Button>
          <Button
            sx={{
              width: "45%",
              color: "#fff",
              borderRadius: 3,
              backgroundColor: "#529F45",
              textTransform: 'capitalize'
            }}
            onClick={(e) => handleCloseShopping((e = "ok"))}
          >
            Lihat Keranjang
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
