import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import IconBack from "../assets/images/backArrow.webp";
import IconSearch from "../assets/images/Search.webp";
import Product1 from "../assets/dummy/product1.jpg";
import Product2 from "../assets/dummy/product2.jpg";
import Plus from "../assets/images/Plus.webp";
import "./Complaint.css";
import { useSelector } from "react-redux";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import Dialog from "../components/Dialog";
import { produkAction, screenAction, orderAction } from "../actions";
import moment from "moment";
import NumberFormat from "react-number-format";
import error404 from "../assets/images/404.webp";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Header from "../components/HeaderDistributor";

export const Complaint = (props) => {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const { screenName } = useSelector((state) => state.ScreenReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [listDataComplaint, setListDataComplaint] = useState([]);
  const [pilihStatus, setPilihStatus] = useState("");
  const [search, setSearch] = useState("");
  const [qty, setQty] = useState(0);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [alertTambahan, setAlertTambahan] = useState("");
  const [alertJudul, setAlertJudul] = useState("");
  const [loadingApi, setLoadingApi] = useState(true);
  const [
    onEndReachedCalledDuringMomentum,
    setOnEndReachedCalledDuringMomentum,
  ] = useState(false);
  const [buttonSemua, setButtonSemua] = useState(true);
  const [buttonNoResponse, setButtonNoResponse] = useState(false);
  const [buttonResponse, setButtonResponse] = useState(false);
  const [buttonBatal, setButtonBatal] = useState(false);
  const [buttonSelesai, setButtonSelesai] = useState(false);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  function NoData() {
    return (
      <div className="NoData">
        <img style={{ height: "50%", width: "100%" }} src={error404} />
        <p
          style={{
            fontSize: "14pt",
            fontFamily: "Lato-Bold",
            textAlign: "center",
            marginTop: "-20%",
          }}
        >
          {"Pesanan mu bermasalah?"}
        </p>
        <p
          style={{
            fontSize: "12pt",
            fontFamily: "Lato-Bold",
            width: "75%",
            textAlign: "center",
            marginTop: "2px",
          }}
        >
          {"Kini kamu bisa mengajukan komplain"}
        </p>
        <button
          onClick={() => navigate("/complaint/choose")}
          style={{
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#529F45",
            width: "65%",
            height: "8%",
            borderRadius: "5px",
            marginTop: "5px",
            color: "#FFFFFF",
            fontSize: "10pt",
            fontFamily: "Lato-Bold",
          }}
        >
          {"Ajukan Komplain"}
        </button>
      </div>
    );
  }

  useEffect(() => {
    getComplaint();

    return () => {};
  }, []);

  useEffect(() => {
    if (page) {
      getComplaint();
    } else if (search.length > 1) {
      getComplaint();
    } else if (pilihStatus) {
      getComplaint();
    }

    return () => {
      setOnEndReachedCalledDuringMomentum(false);
    };
  }, [page, loadingMore, search, pilihStatus]);

  // get complaint
  async function getComplaint() {
    try {
      let response;
      if (screenName == "Distributor") {
        if (search) {
          response = await axios.get(
            `${CONFIG.BASE_URL}/api/distributor-partner/complaint?invoice=${search}&status=${pilihStatus}&page=${page}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
        } else {
          response = await axios.get(
            `${CONFIG.BASE_URL}/api/distributor-partner/complaint?status=${pilihStatus}&page=${page}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
        }
      } else {
        if (search) {
          response = await axios.get(
            `${CONFIG.BASE_URL}/api/complaint?invoice=${search}&status=${pilihStatus}&page=${page}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
        } else {
          response = await axios.get(
            `${CONFIG.BASE_URL}/api/complaint?status=${pilihStatus}&page=${page}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
        }
      }
      const data = response.data.data;
      setLoadingApi(false);
      setListDataComplaint(
        page == 1 ? data.data : [...listDataComplaint, ...data.data]
      );
      setRefreshing(false);
      setLoadingMore(data.last_page > page);
      setMaxPage(data.last_page);
    } catch (error) {
      setLoadingApi(false);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  function handleStatusChange(item) {
    setLoadingApi(true);
    setPilihStatus(item);
    setSearch("");
  }

  //load more data
  async function getMoreData() {
    if (!onEndReachedCalledDuringMomentum && page < maxPage) {
      setPage(page + 1);
      setLoadingMore(page < maxPage);
    }
    setOnEndReachedCalledDuringMomentum(page >= maxPage);
  }

  function handleRefresh() {
    setPage(1);
    setRefreshing(true);
  }

  function handleLoadMore() {
    if (!loadingMore) return null;
    return (
      <div>
        <CircularProgress color="success" />
      </div>
    );
  }

  return (
    <div className="complaintContainer">
      <div className="complaintHeader">
        <div className="complaintHeaderTitle">
          <Header 
          titleHeader="Komplain pesanan"
          />
        </div>
        <a
          onClick={() => navigate("/complaint/choose")}
          className="complaintCreateContainer"
        >
          <div className="title">Ajukan Komplain</div>
          <div className="button">
            <img src={Plus} alt="headerSearchLogo" className="plus" />
          </div>
        </a>
        <hr></hr>
        <div className="complaintSearchContainer">
          <input
            onChange={(e) => setSearch(e.target.value)}
            className="complaintTextInput"
            placeholder="Ketuk nomor invoice yang ingin anda cari"
            type="text"
          />
          <img
            src={IconSearch}
            alt="headerSearchLogo"
            className="complaintSearchLogo"
          />
        </div>
        <div className="complaintHeaderFilter">
          {buttonSemua ? (
            <div className="complaintStatusContainer active">
              <div className="complaintSelect">Semua</div>
            </div>
          ) : (
            <div
              onClick={() => {
                setButtonSemua(true);
                setButtonNoResponse(false);
                setButtonResponse(false);
                setButtonBatal(false);
                setButtonSelesai(false);
                handleStatusChange();
              }}
              className="complaintStatusContainer"
            >
              <div className="complaintSelect">Semua</div>
            </div>
          )}
          {buttonNoResponse ? (
            <div className="complaintStatusContainer active">
              <div className="complaintSelect">Belum Direspon</div>
            </div>
          ) : (
            <div
              onClick={() => {
                setButtonSemua(false);
                setButtonNoResponse(true);
                setButtonResponse(false);
                setButtonBatal(false);
                setButtonSelesai(false);
                handleStatusChange("no response yet");
              }}
              className="complaintStatusContainer"
            >
              <div className="complaintSelect">Belum Direspon</div>
            </div>
          )}
          {buttonResponse ? (
            <div className="complaintStatusContainer active">
              <div className="complaintSelect">Sudah Direspon</div>
            </div>
          ) : (
            <div
              onClick={() => {
                setButtonSemua(false);
                setButtonNoResponse(false);
                setButtonResponse(true);
                setButtonBatal(false);
                setButtonSelesai(false);
                handleStatusChange("response");
              }}
              className="complaintStatusContainer"
            >
              <div className="complaintSelect">Sudah Direspon</div>
            </div>
          )}
          {buttonBatal ? (
            <div className="complaintStatusContainer active">
              <div className="complaintSelect">Di Batalkan</div>
            </div>
          ) : (
            <div
              onClick={() => {
                setButtonSemua(false);
                setButtonNoResponse(false);
                setButtonResponse(false);
                setButtonBatal(true);
                setButtonSelesai(false);
                handleStatusChange("rejected");
              }}
              className="complaintStatusContainer"
            >
              <div className="complaintSelect">Di Batalkan</div>
            </div>
          )}
          {buttonSelesai ? (
            <div className="complaintStatusContainer active">
              <div className="complaintSelect">Sudah Selesai</div>
            </div>
          ) : (
            <div
              onClick={() => {
                setButtonSemua(false);
                setButtonNoResponse(false);
                setButtonResponse(false);
                setButtonBatal(false);
                setButtonSelesai(true);
                handleStatusChange("completed");
              }}
              className="complaintStatusContainer"
            >
              <div className="complaintSelect">Sudah Selesai</div>
            </div>
          )}
        </div>
      </div>
      <div className="complaint">
        <Loading loadingApi={loadingApi} />
        {listDataComplaint.length > 0 && !loadingApi ? (
          <InfiniteScroll
            dataLength={listDataComplaint.length}
            next={() => getMoreData()}
            hasMore={true}
            loader={() => handleLoadMore()}
            // refreshFunction={handleRefresh}
            // pullDownToRefresh
            // pullDownToRefreshThreshold={50}
            // pullDownToRefreshContent={handleLoadMore}
          >
            {listDataComplaint.map((item, index) => {
              return (
                <div
                  onClick={() => {
                    dispatch(orderAction(item, "detailComplaint"));
                    navigate("/complaint/detail?id_detail="+item.id);
                  }}
                  key={index}
                  className="complaintMain"
                >
                  <div className="complaintTitle">
                    <div className="complaintTitleDate">
                      {moment(item.created_at).format(
                        "DD MMM YYYY HH:mm"
                      )}
                    </div>
                    <div className="complaintTitleInvoice">
                      Invoice: {item.order?.invoice}
                    </div>
                  </div>
                  <div className="complaintContent">
                    <div>
                      {item.order?.order_details[0]?.product.image ? (
                        <img
                          src={
                            CONFIG.BASE_URL +
                            item.order?.order_details[0]?.product.image
                          }
                          alt="complaintContentImage"
                          className="complaintContentImage"
                        />
                      ) : (
                        <img
                          src={DummyImage}
                          alt="complaintContentImage"
                          className="complaintContentImage"
                        />
                      )}
                      {item.order?.order_details?.length > 1 ? (
                        <p className="complaintContentQty">
                          +{item.order?.order_details?.length - 1} produk
                          lainnya
                        </p>
                      ) : null}
                    </div>
                    <div className="complaintContent2">
                      <div className="complaintContentProduct">
                        {item.order?.order_details[0]?.product.name}
                      </div>
                      {item.order.order_details[0].half ? (
                        <div className="complaintContentPriceHalf">
                          {"( "}
                          {item?.order.order_details[0]?.qty_konversi}{" "}
                          {item?.order.order_details[0]?.small_unit.charAt(0) +
                            item?.order.order_details[0]?.small_unit
                              .slice(1)
                              .toLowerCase()}
                          {" )"}
                        </div>
                      ) : null}
                      <NumberFormat
                        value={
                          Math.round(
                            item.order?.order_details[0]?.total_price
                          ) ?? "0"
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Rp "}
                        renderText={(value) => (
                          <div className="complaintContentPrice">
                            {value || 0}
                            {""} (1 barang)
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </InfiniteScroll>
        ) : listDataComplaint.length == 0 && !loadingApi ? (
          <NoData />
        ) : null}
      </div>
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Complaint);
