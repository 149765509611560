import React, {useEffect} from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import IconComplaint from "../assets/images/Komplain.webp";
import "./NotificationList.css";

export const NotificationListKomplain = (props) => {
  const navigate = useNavigate();
  return (
    <div className="notifListContainer">
        <Header />
        <div className="notifListMain">
            <div className="notifListCard">
                <div className="notifListHeader">
                    <img src={IconComplaint} alt="notifListIcon" className="notifListIcon" />
                    Komplain #JK1100000000011523
                        <div className="notifListRight">
                            5 Februari 2022 Pukul 14.06
                        </div>
                </div>
                <div className="notifListContent">
                    <div className="notifListContentHeader">
                        Komplain Terkonfirmasi
                    </div>
                    <div className="notifListContentMain">
                        Komplain pada pesanan anda dengan nomor invoice JK1100000000011523 sudah dikonfirmasi
                    </div>
                </div>
            </div>
            <div className="notifListCard">
                <div className="notifListHeader">
                    <img src={IconComplaint} alt="notifListIcon" className="notifListIcon" />
                    Komplain #JK1100000000011523
                        <div className="notifListRight">
                            5 Februari 2022 Pukul 14.06
                        </div>
                </div>
                <div className="notifListContent">
                    <div className="notifListContentHeader">
                        Balasan Komplain
                    </div>
                    <div className="notifListContentMain">
                        Komplain pada pesanan anda dengan nomor invoice JK1100000000011523 sudah dibalas
                    </div>
                </div>
            </div>
        </div>
        <Navbar />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationListKomplain);
