import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/images/IconLogo.webp";
import IconBack from "../assets/images/backArrow.webp";
import "./UserEdit.css";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import Dialog from "../components/Dialog";
import { screenAction, userAction } from "../actions";
import Header from "../components/HeaderDistributor";

export const UserEditPhone = (props) => {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [open, setOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [alertTambahan, setAlertTambahan] = useState("");
  const [alertJudul, setAlertJudul] = useState("");

  const handleClickOpen = () => {
    setAlertData("Apakah nomor tersebut sudah benar?");
    setAlertTambahan("harap mengecek kembali nomor anda");
    setAlertJudul("Pemberitahuan");
    setOpen(true);
  };

  const handleClose = async (e) => {
    setOpen(false);
    if (e == "ok") {
      if (phone == dataUser.user.phone) {
        setAlertData(
          "Nomor telepon yang anda masukan masih sama dengan sebelumnya"
        );
        setAlertTambahan("");
        setAlertJudul("Pemberitahuan");
        setOpen(true);
      } else {
        dispatch(screenAction("EditTelepon", "screenName"));
        dispatch(userAction(phone, "phone"));
        navigate("/choose-verification");
      }
    }
  };

  return (
    <div className="userEditContainer">
      <Dialog
        alertData={alertData}
        alertJudul={alertJudul}
        alertTambahan={alertTambahan}
        handleClose={handleClose}
        open={open}
      />
      <Header
      titleHeader={"Ubah nomor telepon"}
      />
      <div className="userEditList">
        {/* <div className="userEditTextTitle">Kode Kustomer</div>
        <div className="subUserEdit">
          <input
            onChange={(e) => setPhone(e.target.value)}
            className="userEditInput"
            placeholder="kode kustomer"
            value={phone}
          ></input>
        </div>
        <hr></hr> */}
        <div className="userEditTextTitle">Nomor Telepon</div>
        <div className="subUserEdit">
          <input
            onChange={(e) => setPhone(e.target.value)}
            className="userEditInput"
            placeholder="081123124"
            value={phone}
          ></input>
        </div>
        <hr></hr>
        <button onClick={handleClickOpen} className="editButtonOK">
          <p className="editTextButtonOK">{"Simpan"}</p>
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserEditPhone);
