import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import IconBack from "../assets/images/backArrow.webp";
import IconSearch from "../assets/images/Search.webp";
import IconUser from "../assets/images/User.webp";
import IconKomplain from "../assets/images/Komplain.webp";
import IconPromo from "../assets/images/Promo.webp";
import IconOther from "../assets/images/Others.webp";
import "./Faq.css";
import { useSelector } from "react-redux";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import { helpAction } from "../actions";
import Loading from "../components/Loading";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import  Header  from "../components/HeaderDistributor";

export const Faq = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [help, setHelp] = useState([]);
  const [helpCategories, setHelpCategories] = useState([]);
  const [search, setSearch] = useState("");
  const [loadingApi, setLoadingApi] = useState(true);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  useEffect(() => {
    getHelpCare();
    getHelpCategories();

    return () => {};
  }, []);

  useEffect(() => {
    if (search.length > 1) {
      getHelpCare();
    }

    return () => {};
  }, [search]);

  async function getHelpCare() {
    try {
      let response;
      if (search) {
        response = await axios.get(
          `${CONFIG.BASE_URL}/api/helps?search=${search}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      } else {
        response = await axios.get(`${CONFIG.BASE_URL}/api/helps`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
      }
      const data = response.data.data;
      setHelp(data);
      setLoadingApi(false);
    } catch (error) {
      setLoadingApi(false);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error=======DATA LOAD=================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  //help care kategori
  async function getHelpCategories() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/helps/categories`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      setHelpCategories(data);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }

  return (
    <div className="faqContainer">
      <div className="faqHeader">
        <Header
        titleHeader="Bantuan"
        />
        <div className="faqHeaderMiddle">Ada yang bisa dibantu ?</div>
        <div className="faqSearchContainer">
          <input
            onChange={(e) => setSearch(e.target.value)}
            className="faqTextInput"
            placeholder="Ketik kata kunci yang ingin anda cari"
            type="text"
            value={search}
          />
          <img
            src={IconSearch}
            alt="headerSearchLogo"
            className="faqSearchLogo"
          />
        </div>
      </div>
      {search.length == 0 ? (
        <div className="faqMiddle">
          <div className="title">Pilih topik sesuai dengan kendala kamu</div>
          <div className="main">
            {helpCategories.map((item, index) => {
              return (
                <div
                key={index}
                  onClick={() => {
                    dispatch(helpAction(item, "helpTopik"));
                    navigate("/faq/topik");
                  }}
                  className="item"
                >
                  <div className="round">
                    {item.icon ? (
                      <img
                        src={CONFIG.BASE_URL + item.icon}
                        alt="image"
                        className="image"
                      />
                    ) : (
                      <img src={DummyImage} alt="image" className="image" />
                    )}
                  </div>
                  <div className="title">{item.name}</div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
      <div className="faqMain">
        {search.length == 0 ? <div className="title">FAQ</div> : null}
        {help.map((item, index) => {
          return (
            <div
              onClick={() => {
                dispatch(helpAction(item, "helpDetail"));
                navigate("/faq/detail?idFaq="+item.id);
              }}
              key={index}
            >
              <div className="list">
                <a>{item.name}</a>
              </div>
              <hr></hr>
            </div>
          );
        })}
      </div>
      <Loading loadingApi={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
