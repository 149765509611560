import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/images/IconLogo.webp";
import IconUser from "../assets/images/User.webp";
import IconToko from "../assets/images/Toko.webp";
import IconPhone from "../assets/images/Phone.webp";
import IconEmail from "../assets/images/Email.webp";
import IconSandi from "../assets/images/Sandi.webp";
import IconReSandi from "../assets/images/ReSandi.webp";
import IconProvinsi from "../assets/images/Provinsi.webp";
import IconKabupaten from "../assets/images/Kabupaten.webp";
import IconKecamatan from "../assets/images/Kecamatan.webp";
import IconKelurahan from "../assets/images/Kelurahan.webp";
import IconKodepos from "../assets/images/Kodepos.webp";
import "./RegisterNew.css";
import axios from "axios";
import CONFIG from "../config/config";
import { useDispatch } from "react-redux";
import { userAction, screenAction } from "../actions";
import CircularProgress from "@mui/material/CircularProgress";
import { getDataToken } from "../config/firebase";
import FormDialog from "../components/Dialog";
import IconEye from "../assets/images/Eye.webp";
import AlertBlacklist from "../components/AlertBlacklist";
import LoadingComponent from "../components/Loading";
import PopUP from "../components/SuccessLogin";

export const RegisterNewDistributor = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [store, setStore] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirm, setPassword_confirm] = useState("");
  const [address, setAddress] = useState("");
  const [provinsi, setProvinsi] = useState([]);
  const [city, setCity] = useState([]);
  const [kecamatan, setKecamatan] = useState([]);
  const [kelurahan, setKelurahan] = useState([]);
  const [poscode, setPoscode] = useState([]);
  const [provinsi_id, setProvinsi_id] = useState([]);
  const [city_id, setCity_id] = useState([]);
  const [kecamatan_id, setKecamatan_id] = useState([]);
  const [dataprovinsi, setDataprovinsi] = useState("");
  const [datacity, setDatacity] = useState("");
  const [datakecamatan, setDatakecamatan] = useState("");
  const [datakelurahan, setDatakelurahan] = useState("");
  const [dataposcode, setDataposcode] = useState("");
  const [alertPass, setAlertPass] = useState(false);
  const [alertPass2, setAlertPass2] = useState(false);
  const [alertAdress, setAlertAdress] = useState(false);
  const [token, setToken] = useState("");
  const [random_number_1, setRandom_number_1] = useState("");
  const [random_number_2, setRandom_number_2] = useState("");
  const [sum_holder, setSum_holder] = useState("");
  const [shouldShow, setShouldShow] = useState(false);
  const [textInput_text_holder, setTextInput_text_holder] = useState("");
  const [hidePass, setHidePass] = useState(false);
  const [hidePass2, setHidePass2] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [alertTambahan, setAlertTambahan] = useState("");
  const [alertJudul, setAlertJudul] = useState("");
  const [possition, setPossition] = useState({});
  const [nextBtn, setNextBtn] = useState(false);
  const [fotoKTP, setFotoKTP] = useState(null);
  const [fotoNPWP, setFotoNPWP] = useState(null);
  const [fotoToko, setFotoToko] = useState(null);
  const [fotoSelfie, setFotoSelfie] = useState(null);
  const [linkLokasi, setLinkLokasi] = useState(null);
  const [warning, setWarning] = useState("");
  const [waringView, setWaringView] = useState(false);
  const [popUpTitle, setPopUpTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [popUpView, setPopUpView] = useState(false);

  function generate_captcha() {
    var number_1 = Math.floor(Math.random() * 100) + 1;

    var number_2 = Math.floor(Math.random() * 100) + 1;

    var sum = number_1 + number_2;

    setRandom_number_1(number_1);
    setRandom_number_2(number_2);
    setSum_holder(sum);
  }

  function run_captcha_function() {
    var temp = random_number_1 + random_number_2;

    if (textInput_text_holder == temp) {
      //Write Your Code Here Which you want to execute on RIGHT Captcha Entered.
      setShouldShow(true);
    } else {
      //Write Your Code Here Which you want to execute on WRONG Captcha Entered.
      // this.setState({
      //   alertData: "Captcha Tidak Cocok",
      //   modalAlert: !this.state.modalAlert,
      // });
      alert("Captcha Tidak Cocok");
    }

    // Calling captcha function, So each time new captcha number generates on button clicks.
    generate_captcha();
  }

  useEffect(() => {
    let data;

    async function getToken() {
      data = await getDataToken();
      if (data) {
        // console.log("token adalah : ", data);
        setToken(data);
      }
      return data;
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (pos) {
        const crd = pos.coords;
        setPossition({ latitude: crd.latitude, longitude: crd.longitude });
      });
    } else {
    }
    getToken();
    getProvinsi();
    generate_captcha();

    return () => {};
  }, []);

  async function getProvinsi() {
    try {
      let response = await axios({
        method: "GET",
        url: `${CONFIG.BASE_URL}/api/location`,
        params: {
          type: "province",
        },
      });
      // console.log("getProvinsi", response.data);
      const data = response.data.data;
      setProvinsi(data);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      console.log(
        "Cek Error============getProvinsi============",
        JSON.parse(JSON.stringify(error)).message
      );
      //   if (errorNetwork) {
      //     this.setState({
      //       alertData: 'jaringan bermasalah',
      //       modalAlert: !this.state.modalAlert,
      //     });
      //   }
    }
  }

  async function pickerProvinsi(e) {
    let dataProvinsi;
    provinsi?.filter((item, index) => {
      if (item.id == e) {
        dataProvinsi = item;
      }
    });
    if (dataProvinsi) {
      try {
        let response = await axios({
          method: "GET",
          url: `${CONFIG.BASE_URL}/api/location`,
          params: {
            type: "city",
            province_id: dataProvinsi.id,
          },
        });
        const data = response.data.data;
        // console.log(dataProvinsi.name);
        setDataprovinsi(dataProvinsi.name);
        setProvinsi_id(dataProvinsi.id);
        setCity(data);
      } catch (error) {
        let error429 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 429";
        let errorNetwork =
          JSON.parse(JSON.stringify(error)).message === "Network Error";
        console.log(
          "Cek Error============getCity============",
          JSON.parse(JSON.stringify(error)).message
        );
        //   if (errorNetwork) {
        //     this.setState({
        //       alertData: 'jaringan bermasalah',
        //       modalAlert: !this.state.modalAlert,
        //     });
        //   }
      }
    }
  }

  async function pickerCity(e) {
    let dataCity;
    city?.filter((item, index) => {
      if (item.id == e) {
        dataCity = item;
      }
    });
    if (dataCity) {
      try {
        let response = await axios({
          method: "GET",
          url: `${CONFIG.BASE_URL}/api/location`,
          params: {
            type: "district",
            city_id: dataCity.id,
          },
        });
        // console.log("getKecamatan", response.data);
        const data = response.data.data;
        setDatacity(dataCity.name);
        setCity_id(dataCity.id);
        setKecamatan(data);
      } catch (error) {
        let error429 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 429";
        let errorNetwork =
          JSON.parse(JSON.stringify(error)).message === "Network Error";
        console.log(
          "Cek Error============getKecamatan============",
          JSON.parse(JSON.stringify(error)).message
        );
        //   if (errorNetwork) {
        //     this.setState({
        //       alertData: 'jaringan bermasalah',
        //       modalAlert: !this.state.modalAlert,
        //     });
        //   }
      }
    }
  }

  async function pickerKecamatan(e) {
    let dataKecamatan;
    kecamatan?.filter((item, index) => {
      if (item.id == e) {
        dataKecamatan = item;
      }
    });
    if (dataKecamatan) {
      try {
        let response = await axios({
          method: "GET",
          url: `${CONFIG.BASE_URL}/api/location`,
          params: {
            type: "subdistrict",
            district_id: dataKecamatan.id,
          },
        });
        // console.log("getKelurahan", response.data);
        const data = response.data.data;
        setDatakecamatan(dataKecamatan.name);
        setKecamatan_id(dataKecamatan.id);
        setKelurahan(data);
      } catch (error) {
        let error429 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 429";
        let errorNetwork =
          JSON.parse(JSON.stringify(error)).message === "Network Error";
        console.log(
          "Cek Error============getKelurahan============",
          JSON.parse(JSON.stringify(error)).message
        );
        //   if (errorNetwork) {
        //     this.setState({
        //       alertData: 'jaringan bermasalah',
        //       modalAlert: !this.state.modalAlert,
        //     });
        //   }
      }
    }
  }

  async function pickerKelurahan(e) {
    // console.log("cekkk", e);
    // console.log("cekkk", provinsi_id, city_id, kecamatan_id);
    let dataKelurahan;
    kelurahan?.filter((item, index) => {
      if (item.id == e) {
        dataKelurahan = item;
      }
    });
    // console.log("dataKelurahan", dataKelurahan);
    if (dataKelurahan) {
      try {
        let response = await axios({
          method: "GET",
          url: `${CONFIG.BASE_URL}/api/location`,
          params: {
            type: "postal_code",
            province_id: provinsi_id,
            city_id: city_id,
            district_id: kecamatan_id,
          },
        });
        // console.log("getPoscode", response.data);
        const data = response.data.data;
        const pos = [];
        data.forEach((obj) => {
          if (!pos.some((o) => o.postcode === obj.postcode)) {
            pos.push({ ...obj });
          }
        });
        setDatakelurahan(dataKelurahan.name);
        setPoscode(pos);
      } catch (error) {
        let error429 =
          JSON.parse(JSON.stringify(error)).message ===
          "Request failed with status code 429";
        let errorNetwork =
          JSON.parse(JSON.stringify(error)).message === "Network Error";
        console.log(
          "Cek Error============getPoscode============",
          JSON.parse(JSON.stringify(error)).message
        );
        //   if (errorNetwork) {
        //     this.setState({
        //       alertData: 'jaringan bermasalah',
        //       modalAlert: !this.state.modalAlert,
        //     });
        //   }
      }
    }
  }

  async function pickerPos(e) {
    console.log("cekkk", e);
    if (e) {
      setDataposcode(e);
    }
  }

  async function handleRegister(param) {
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!name.trim()) {
      // alert("Pastikan nama tidak ada yang kosong");
      setWaringView(true);
      setWarning("Pastikan kolom nama tidak kosong");
      return;
    } else if (!store.trim()) {
      // alert("Pastikan nama toko tidak ada yang kosong");
      setWaringView(true);
      setWarning("Pastikan kolom nama toko tidak kosong");
      return;
    } else if (!phone.trim()) {
      // alert("Pastikan nomor telepon tidak ada yang kosong");
      setWaringView(true);
      setWarning("Pastikan kolom nomor telepon tidak kosong");
      return;
    } else if (!password.trim()) {
      // alert("Pastikan password tidak ada yang kosong");
      setWaringView(true);
      setWarning("Pastikan kolom kata sandi tidak kosong");
      return;
    } else if (!password_confirm.trim()) {
      // alert("Pastikan konfirm password tidak ada yang kosong");
      setWaringView(true);
      setWarning("Pastikan kolom konfirmasi kata sandi tidak kosong");
      return;
    } else if (alertPass == true) {
      // alert("Pastikan password diisi dengan benar");
      setWaringView(true);
      setWarning("Pastikan kolom kata sandi diisi dengan tepat");
      return;
    } else if (alertPass2 == true) {
      // alert("Pastikan password konfirm diisi dengan benar");
      setWaringView(true);
      setWarning("Pastikan kolom konfirmasi diisi dengan tepat");
      return;
    } else if (!address.trim()) {
      // alert("Pastikan alamat tidak ada yang kosong");
      setWaringView(true);
      setWarning("Pastikan kolom alamat tidak kosong");
      return;
    } else if (alertAdress == true) {
      // alert("Alamat harap diisi dengan lengkap");
      setWaringView(true);
      setWarning("Pastikan kolom alamat diisi dengan lengkap");
      return;
    } else if (dataprovinsi == "") {
      setWaringView(true);
      setWarning("Pastikan kolom provinsi tidak kosong");
    } else if (datacity == "") {
      setWaringView(true);
      setWarning("Pastikan kolom kota tidak kosong");
    } else if (datakecamatan == "") {
      setWaringView(true);
      setWarning("Pastikan kolom kecamatan tidak kosong");
    } else if (datakelurahan == "") {
      setWaringView(true);
      setWarning("Pastikan kolom kelurahan tidak kosong");
    } else if (dataposcode == "") {
      // alert("Pastikan diisi kodeposnya");
      setWaringView(true);
      setWarning("Pastikan kolom kodepos tidak kosong");
      return;
    } else {
      if (param == true) {
        setLoading(true);
        const dataUser = {
          name: name,
          shop_name: store,
          phone: phone,
          email: email,
          password: password,
          password_confirmation: password_confirm,
          address: address,
          province: dataprovinsi,
          city: datacity,
          fcm_token: token,
          district: datakecamatan,
          subdistrict: datakelurahan,
          postal_code: dataposcode,
          shareloc: linkLokasi,
          photo_ktp: fotoKTP,
          photo_npwp: fotoNPWP,
          photo_toko: fotoToko,
          selfie_ktp: fotoSelfie,
          latitude: possition?.latitude,
          longitude: possition?.longitude,
        };
        const formData = new FormData();
        formData.append("name", name);
        formData.append("shop_name", store);
        formData.append("phone", phone);
        formData.append("email", email);
        formData.append("password", password);
        formData.append("password_confirmation", password_confirm);
        formData.append("address", address);
        formData.append("province", dataprovinsi);
        formData.append("city", datacity);
        formData.append("fcm_token", token);
        formData.append("district", datakecamatan);
        formData.append("subdistrict", datakelurahan);
        formData.append("postal_code", dataposcode);
        formData.append("shareloc", linkLokasi);
        formData.append("latitude", possition?.latitude)
        formData.append("longitude", possition?.longitude)
        // formData.append("photo_ktp", fotoKTP);
        // formData.append("photo_npwp", fotoNPWP);
        formData.append("photo_toko", fotoToko);
        formData.append("selfie_ktp", fotoSelfie);
        if (fotoNPWP && fotoKTP != null) {
          formData.append("photo_ktp", fotoKTP);
          formData.append("photo_npwp", fotoNPWP);
        } else {
          fotoNPWP == null
            ? formData.append("photo_ktp", fotoKTP)
            : formData.append("photo_npwp", fotoNPWP);
        }
        await axios
          .post(`${CONFIG.BASE_URL}/api/auth/register?status=1`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              // Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setLoading(false);
            const data = response.data;
            if (data != null && data["success"] == true) {
              dispatch(userAction(dataUser, "dataUser"));
              dispatch(screenAction("RegisterNew", "screenName"));
              navigate("/choose-verification");
            } else {
              if (data["message"] == "The phone has already been taken.") {
                setWarning("Nomor telepon yang didaftarkan telah dipakai");
              } else if (data["message"] == "Account has been registered") {
                setWarning("Akun anda sudah terdaftar");
              } else if (
                data["message"] == "The email has already been taken."
              ) {
                setWarning("Akun anda sudah terdaftar");
              } else {
                setWarning(data?.message);
              }
              setPopUpTitle("VERIFIKASI GAGAL");
              setPopUpView(true);
            }
          })
          .catch((error) => {});
      } else {
        setLoading(false);
        setNextBtn(!nextBtn);
      }
    }
  }

  function cek(e, param) {
    const name = e?.target?.files[0];
    if (param == "ktp") {
      setFotoKTP(name);
    } else if (param == "npwp") {
      setFotoNPWP(name);
    } else if (param == "toko") {
      setFotoToko(name);
    } else if (param == "selfie") {
      setFotoSelfie(name);
    }
  }

  function setCloseWarning() {
    setWaringView(false);
  }
  function closeView() {
    setPopUpView(false);
  }
  const handleClose = async (e) => {
    setOpen(false);
  };

  console.log("possition" + JSON.stringify(possition));
  return (
    <div className="registerNewContainer">
      <FormDialog
        alertData={alertData}
        alertJudul={alertJudul}
        handleClose={handleClose}
        open={open}
      />
        <AlertBlacklist
        alertData={warning}
        alertJudul={popUpTitle}
        handleClose={setCloseWarning}
        open={waringView}
      />
      <PopUP
        titleDescription={warning}
        titleAlert={popUpTitle}
        handleClose={closeView}
        open={popUpView}
      />
            <LoadingComponent loadingApi={loading} />
      <img src={Logo} alt="registerNewLogo" className="registerNewLogo" />
      <h1 className="registerNewJudul">{"Daftar"}</h1>
      {!nextBtn ? (
        <>
          <div className="registerNewContainerInput">
            <img
              src={IconUser}
              alt="registerNewIconUser"
              className="registerNewIconUser"
            />
            <input
              onChange={(e) => setName(e.target.value)}
              className="registerNewTextInput"
              placeholder="Nama"
              value={name != "" ? name : ""}
            />
          </div>
          <div className="registerNewContainerInput">
            <img
              src={IconToko}
              alt="registerNewIconUser"
              className="registerNewIconUser"
            />
            <input
              onChange={(e) => setStore(e.target.value)}
              className="registerNewTextInput"
              placeholder="Nama Toko"
              value={store != "" ? store : ""}
            />
          </div>
          <div className="registerNewContainerInput">
            <img
              src={IconPhone}
              alt="registerNewIconUser"
              className="registerNewIconUser"
            />
            <input
              type="number"
              onChange={(e) => setPhone(e.target.value)}
              className="registerNewTextInput"
              placeholder="Nomor Telepon"
              value={phone != "" ? phone : ""}
            />
          </div>
          <div className="registerNewContainerInput">
            <img
              src={IconEmail}
              alt="registerNewIconUser"
              className="registerNewIconUser"
            />
            <input
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              className="registerNewTextInput"
              placeholder="Email (Tidak Wajib Diisi)"
              value={email != "" ? email : ""}
            />
          </div>
          <div className="registerNewContainerInput">
            <img
              src={IconSandi}
              alt="registerNewIconUser"
              className="registerNewIconUser"
            />
            <input
              type="password"
              onChange={(e) => {
                setPassword(e.target.value);
                if (password.length < 7) {
                  setAlertPass(true);
                } else {
                  setAlertPass(false);
                }
              }}
              className="registerNewTextInput"
              placeholder="Kata Sandi"
              value={password != "" ? password : ""}
            />
          </div>
          {alertPass && <p className="textAlert">Password harus 8 character</p>}
          <div className="registerNewContainerInput">
            <img
              src={IconReSandi}
              alt="registerNewIconUser"
              className="registerNewIconUser"
            />
            <input
              type="password"
              onChange={(e) => {
                setPassword_confirm(e.target.value);
                if (e.target.value != password) {
                  setAlertPass2(true);
                } else {
                  setAlertPass2(false);
                }
              }}
              className="registerNewTextInput"
              placeholder="Konfirmasi Kata Sandi"
              value={password_confirm != "" ? password_confirm : ""}
            />
          </div>
          {alertPass2 && (
            <p className="textAlert">Password konfirmasi tidak sama</p>
          )}
          <div className="registerNewContainerInput">
            {/* <img
              src={IconProvinsi}
              alt="registerNewIconUser"
              className="registerNewIconUser"
            /> */}
            <input
              onChange={(e) => {
                setLinkLokasi(e.target.value);
              }}
              className="registerNewTextInput"
              placeholder="Link lokasi toko"
              value={linkLokasi != "" ? linkLokasi : ""}
            />
          </div>
          <div className="registerNewContainerInput">
            <img
              src={IconProvinsi}
              alt="registerNewIconUser"
              className="registerNewIconUser"
            />
            <input
              onChange={(e) => {
                setAddress(e.target.value);
                if (address.length < 10) {
                  setAlertAdress(true);
                } else {
                  setAlertAdress(false);
                }
              }}
              className="registerNewTextInput"
              placeholder="Alamat Detail"
              value={address != "" ? address : ""}
            />
          </div>
          {alertAdress && (
            <p className="textAlert">Alamat minimal 8 karakter</p>
          )}
          <div className="registerNewContainerInput">
            <img
              src={IconProvinsi}
              alt="registerNewIconUser"
              className="registerNewIconUser"
            />
            <select
              onChange={(e) => pickerProvinsi(e.target.value)}
              placeholder="tes"
              className="registerNewSelect"
              style={
                dataprovinsi === "" ? { color: "#8e8e8e" } : { color: "#000" }
              }
            >
              <option value={provinsi}>
                {dataprovinsi || "Pilih Provinsi"}
              </option>
              {provinsi &&
                provinsi.map((province) => {
                  return (
                    <option
                      key={province.id}
                      label={province.name}
                      value={province.id}
                    />
                  );
                })}
            </select>
          </div>
          <div className="registerNewContainerInput">
            <img
              src={IconKabupaten}
              alt="registerNewIconUser"
              className="registerNewIconUser"
            />
            <select
              onChange={(e) => pickerCity(e.target.value)}
              placeholder="tes"
              className="registerNewSelect"
              style={datacity === "" ? { color: "#8e8e8e" } : { color: "#000" }}
            >
              <option value={city}>{datacity || "Pilih Kabupaten/Kota"}</option>
              {city &&
                city.map((city) => {
                  return (
                    <option key={city.id} label={city.name} value={city.id} />
                  );
                })}
            </select>
          </div>
          <div className="registerNewContainerInput">
            <img
              src={IconKecamatan}
              alt="registerNewIconUser"
              className="registerNewIconUser"
            />
            <select
              onChange={(e) => pickerKecamatan(e.target.value)}
              placeholder="tes"
              className="registerNewSelect"
              style={
                datakecamatan === "" ? { color: "#8e8e8e" } : { color: "#000" }
              }
            >
              <option value={kecamatan}>
                {datakecamatan || "Pilih Kecamatan"}
              </option>
              {kecamatan &&
                kecamatan.map((kecamatan) => {
                  return (
                    <option
                      key={kecamatan.id}
                      label={kecamatan.name}
                      value={kecamatan.id}
                    />
                  );
                })}
            </select>
          </div>
          <div className="registerNewContainerInput">
            <img
              src={IconKelurahan}
              alt="registerNewIconUser"
              className="registerNewIconUser"
            />
            <select
              onChange={(e) => pickerKelurahan(e.target.value)}
              placeholder="tes"
              className="registerNewSelect"
              style={
                datakelurahan === "" ? { color: "#8e8e8e" } : { color: "#000" }
              }
            >
              <option value={kelurahan}>
                {datakelurahan || "Pilih Kelurahan"}
              </option>
              {kelurahan &&
                kelurahan.map((kelurahan) => {
                  return (
                    <option
                      key={kelurahan.id}
                      label={kelurahan.name}
                      value={kelurahan.id}
                    />
                  );
                })}
            </select>
          </div>
          <div className="registerNewContainerInput">
            <img
              src={IconKodepos}
              alt="registerNewIconUser"
              className="registerNewIconUser"
            />
            <select
              onChange={(e) => pickerPos(e.target.value)}
              placeholder="tes"
              className="registerNewSelect"
              style={
                dataposcode === "" ? { color: "#8e8e8e" } : { color: "#000" }
              }
            >
              <option value={poscode}>{dataposcode || "Pilih Kodepos"}</option>
              {poscode &&
                poscode.map((poscode) => {
                  return (
                    <option
                      key={poscode.id}
                      label={poscode.postcode}
                      value={poscode.postcode}
                    />
                  );
                })}
            </select>
          </div>
          <div
            className="nextBtnRegisterNew"
            onClick={() => handleRegister(false)}
          >
            Berikutnya
          </div>
        </>
      ) : (
        <>
          <div className="registerNewContainerInput">
            <input
              type="file"
              id="ktp-btn"
              accept="image/png, image/jpeg"
              hidden
              onChange={(e) => cek(e, "ktp")}
            />
            <label
              for="ktp-btn"
              className="registerNewTextInput btnTextColor"
              style={fotoKTP == null ? { color: "#aaa" } : { color: "#000" }}
            >
              {fotoKTP?.name || "Upload foto ktp"}
            </label>
          </div>
          <div className="registerNewContainerInput">
            <input
              type="file"
              id="npwp-btn"
              accept="image/png, image/jpeg"
              hidden
              onChange={(e) => cek(e, "npwp")}
            />
            <label
              for="npwp-btn"
              className="registerNewTextInput btnTextColor"
              style={fotoNPWP == null ? { color: "#aaa" } : { color: "#000" }}
            >
              {fotoNPWP?.name || "Upload Foto NPWP"}
            </label>
          </div>
          <div className="registerNewContainerInput">
            <input
              type="file"
              id="toko-btn"
              accept="image/png, image/jpeg"
              hidden
              onChange={(e) => cek(e, "toko")}
            />
            <label
              for="toko-btn"
              className="registerNewTextInput btnTextColor"
              style={fotoToko == null ? { color: "#aaa" } : { color: "#000" }}
            >
              {fotoToko?.name || "Upload Foto Toko"}
            </label>
          </div>
          <div className="registerNewContainerInput">
            <input
              type="file"
              id="selfie-btn"
              accept="image/png, image/jpeg"
              hidden
              onChange={(e) => cek(e, "selfie")}
            />
            <label
              for="selfie-btn"
              className="registerNewTextInput btnTextColor"
              style={fotoSelfie == null ? { color: "#aaa" } : { color: "#000" }}
            >
              {fotoSelfie?.name || "Upload Foto Selfie"}
            </label>
          </div>
          {shouldShow ? (
            <button
              onClick={() => handleRegister(true)}
              className="registerNewButtonOk"
            >
              <p className="registerNewTextButtonOK">{"OK"}</p>
            </button>
          ) : (
            <div className="containerCaptcha">
              <div className="captcha">
                <p className="captchaText">
                  {random_number_1}
                  {" + "}
                  {random_number_2}
                  {" = "}
                </p>
                <input
                  type="number"
                  className="captchaInput"
                  onChange={(e) => setTextInput_text_holder(e.target.value)}
                  placeholder={"Hasil"}
                ></input>
                <button className="buttonReload" onClick={generate_captcha}>
                  <img
                    className="captchaImage"
                    src="https://reactnativecode.com/wp-content/uploads/2019/08/reload_image.jpg"
                  />
                </button>
              </div>
              <button
                onClick={run_captcha_function}
                className="buttonCheckCaptcha"
              >
                <p className="captchaTextBtn">Periksa Hasil Capctha</p>
              </button>
            </div>
          )}
          <div
            className="nextBtnRegisterNew"
            onClick={() => handleRegister(false)}
          >
            Kembali
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterNewDistributor);
