import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Slider from "../components/Slider";
import Images from "../images";
import IconHome from "../assets/images/Home.webp";
import IconHomeActive from "../assets/images/HomeActive.webp";
import IconProduk from "../assets/images/Produk.webp";
import IconProdukActive from "../assets/images/ProdukActive.webp";
import IconKeranjang from "../assets/images/Keranjang.webp";
import IconKeranjangActive from "../assets/images/KeranjangActive.webp";
import IconPembayaran from "../assets/images/Pembayaran.webp";
import IconPembayaranActive from "../assets/images/PembayaranActive.webp";
import IconChat from "../assets/images/Chat.webp";
import IconChatActive from '../assets/images/ChatActive.webp';
import IconAkun from '../assets/images/Akun.webp';
import IconAkunActive from '../assets/images/AkunActive.webp';
import "./Navbar.css";
import { useDispatch } from "react-redux";
import { screenAction } from "../actions";

export const Header = (props) => {
  console.log("cek props navbar=======", props);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // return (
  //   <div>
  //     <div className="container">
  //       <div className="jumbotron">
  //         <h1>Auto rentcar</h1>
  //         <button
  //           onClick={() => navigate("/about")}
  //         >
  //           Test00
  //         </button>
  //         {/* <Link to="/SplashScreen">Home</Link> */}
  //       </div>
  //     </div>
  //   </div>
  // );
  // return <Slider images={Images} />
  return (
    <div className="navbar">
      {props.screenName == "Home" ? (
        <div
          className="containerNavbar"
          // onClick={() => {
          //   dispatch(screenAction("home", "screenName"));
          //   navigate("/home");
          // }}
        >
          <img src={IconHomeActive} alt="navbarIcon" className="navbarIcon" />
          <p>Home</p>
        </div>
      ) : (
        <div
          className="containerNavbar"
          onClick={() => {
            dispatch(screenAction("home", "screenName"));
            navigate("/dashboardhome");
          }}
        >
          <img src={IconHome} alt="navbarIcon" className="navbarIcon" />
          <p>Home</p>
        </div>
      )}
      {props.screenName == "Produk" ? (
        <div
          className="containerNavbar"
          // onClick={() => {
          //   dispatch(screenAction("produk", "screenName"));
          //   navigate("/produk");
          // }}
        >
          <img src={IconProdukActive} alt="navbarIcon" className="navbarIcon" />
          <p>Produk</p>
        </div>
      ) : (
        <div
          className="containerNavbar"
          onClick={() => {
            dispatch(screenAction("produk", "screenName"));
            navigate("/produk/list/distributor");
          }}
        >
          <img src={IconProduk} alt="navbarIcon" className="navbarIcon" />
          <p>Produk</p>
        </div>
      )}
      {props.screenName == "ListChats" ? (
        <div
          className="containerNavbar"
          // onClick={() => {
          //   dispatch(screenAction("produk", "screenName"));
          //   navigate("/produk");
          // }}
        >
          <img src={IconChatActive} alt="navbarIcon" className="navbarIcon" />
          <p>Chat</p>
        </div>
      ) : (
        <div
          className="containerNavbar"
          onClick={() => {
            dispatch(screenAction("ListChats", "screenName"));
            navigate("/listchats");
          }}
        >
          <img src={IconChat} alt="navbarIcon" className="navbarIcon" />
          <p>Chat</p>
        </div>
      )}
      {props.screenName == "Profile" ? (
        <div
          className="containerNavbar"
          // onClick={() => {
          //   dispatch(screenAction("produk", "screenName"));
          //   navigate("/produk");
          // }}
        >
          <img src={IconAkunActive} alt="navbarIcon" className="navbarIcon" />
          <p>Profile</p>
        </div>
      ) : (
        <div
          className="containerNavbar"
          onClick={() => {
            dispatch(screenAction("profile", "screenName"));
            navigate("/menu/distributor");
          }}
        >
          <img src={IconAkun} alt="navbarIcon" className="navbarIcon" />
          <p>Profile</p>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
