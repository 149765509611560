import React, { useEffect, useState } from "react";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import Slider from "../components/Slider";
import Images from "../images";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.css";
import NumberFormat from "react-number-format";
import ReactStars from "react-rating-stars-component";
import "./Home.css";
import Header from "../components/HeaderDistributor";
import NavbarDistributor from "../components/NavbarDistributor";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import CONFIG from "../config/config";
import DummyImage from "../assets/images/Logo.webp";
import CircularProgress from "@mui/material/CircularProgress";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  screenAction,
  paramsAction,
  produkAction,
  promoAction,
} from "../actions";
import { useDispatch } from "react-redux";
import IconCart from "../assets/images/KeranjangActive.webp";
import IconNextBawah from "../assets/images/Next.webp";
import IconNextAtas from "../assets/images/Next2.webp";
import ModalShopping from "../components/DialogShopping";
import SearchComponent from "../pages/SearchList";
import { getDataToken } from "../config/firebase";
import { getMessaging, onMessage } from "firebase/messaging";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Dialog from "../components/Dialog";
import Loading from "../components/Loading";
import moment from "moment";
import IconNewOrder from "../assets/images/NewOrderDis.webp";
import IconPostOrder from "../assets/images/PostOrderDis.webp";
import IconProduk from "../assets/images/ProdukDis.webp";
import IconRevenue from "../assets/images/RevenueDis.webp";

export const DashboardHome = (props) => {
  const [steps, setSteps] = useState([
    {
      target: ".headerMenuLonceng",
      content: "Lonceng!",
    },
    {
      target: ".headerMenuKeranjang",
      content: "Keranjang!",
    },
    {
      target: ".homeCategory",
      content: "This another awesome feature!",
    },
  ]);
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [listSearch, setListSearch] = useState([]);
  const [qty, setQty] = useState(0);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const [recomenProduct, setRecomenProduct] = useState([]);
  const [loadingApi, setLoadingApi] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [rating, setRating] = useState(1);
  const [shoppingCartItems, setShoppingCartItems] = useState({
    product_id: "",
    brand_id: "",
    satuan_online: "",
    konversi_sedang_ke_kecil: "",
    qty_konversi: "",
    qty: 1,
    notes: "",
    price_apps: "",
  });
  const [banners, setBanners] = useState([]);
  const [promo, setPromo] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products_newest, setProducts_newest] = useState([]);
  const [products_popular, setProducts_popular] = useState([]);
  const [products_recent, setProducts_recent] = useState([]);
  const [products_promo, setProducts_promo] = useState([]);
  const [qtyTotalNew, setQtyTotalNew] = useState(0);
  const [qtyTotalPopular, setQtyTotalPopular] = useState(0);
  const [qtyTotalPromo, setQtyTotalPromo] = useState(0);
  const [open, setOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [alertTambahan, setAlertTambahan] = useState("");
  const [alertJudul, setAlertJudul] = useState("");
  const [
    onEndReachedCalledDuringMomentum,
    setOnEndReachedCalledDuringMomentum,
  ] = useState(false);
  const [notifOrders, setNotifOrders] = useState(0);
  const [notifMessages, setNotifMessages] = useState(0);
  const [notifComplaints, setNotifComplaints] = useState(0);
  const [notifBroadcast, setNotifBroadcast] = useState(0);
  const [notifSubscribe, setNotifSubscribe] = useState(0);
  const [countNotifCart, setCountNotifCart] = useState(0);
  const [openShopping, setOpenShopping] = useState(false);
  const [loadAll, setLoadAll] = useState(false);
  const [alertServerSibuk, setAlertServerSibuk] = useState(false);
  const [alertInet, setAlertInet] = useState(false);
  const [numberWASub, setNumberWASub] = useState("");
  const [userDistributor, setUserDistributor] = useState({});
  const [new_order, setNew_order] = useState([]);
  const [new_order_count, setNew_order_count] = useState(0);
  const [products, setProducts] = useState([]);
  const [products_count, setProducts_count] = useState(0);
  const [order, setOrder] = useState([]);
  const [order_count, setOrder_count] = useState(0);
  const [total_trans, setTotal_trans] = useState(0);
  const [total_trans_today, setTotal_trans_today] = useState(0);
  const [this_month, setThis_month] = useState(0);
  const [this_weeks, setThis_weeks] = useState(0);
  const [today, setToday] = useState(0);

  function alertInternet() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertInet(false);
          }}
          variant="filled"
          severity="info"
        >
          Jaringan internet anda bermasalah
        </Alert>
      </Stack>
    );
  }

  function alertBusy() {
    return (
      <Stack
        sx={{ position: "fixed", bottom: "11vh" }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Alert
          onClose={() => {
            setLoadingApi(false);
            setAlertServerSibuk(false);
          }}
          variant="filled"
          severity="info"
        >
          Server sedang sibuk
        </Alert>
      </Stack>
    );
  }

  async function getToken() {
    let data;
    data = await getDataToken();
    if (data) {
      console.log("token adalah : ", data);
    }
    return data;
  }

  useEffect(() => {
    getNotifAll();
    getDataDashboard();
  }, []);
  async function getNotifAll() {
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/notification-all`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      // console.log('DATA TEST', data.total_chat);
      setNotifOrders(data.total_order);
      setNotifMessages(data.total_chat);
      setNotifComplaints(data.total_complaint);
      setNotifSubscribe(data.total_subscribe);
      setNotifBroadcast(data.total_broadcast);
      setCountNotifCart(data.total_cart);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========NOTIF================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        navigate("/home");
      }
    }
  }
  // useEffect(() => {
  //   getToken();
  //   const messaging = getMessaging();
  //   onMessage(messaging, (payload) => {
  //     console.log("Message received. ", payload);
  //     // ...
  //   });
  //   getDataLoadAll();
  //   getWhatsappNumber();
  //   getNotifAll();
  // }, []);

  // useEffect(() => {
  //   console.log("tembakkkkkk");
  //   if (page && !loadAll) {
  //     addPage();
  //   } else if (page && loadAll) {
  //     getDataLoadAll();
  //     getNotifAll();
  //     setLoadAll(false);
  //   }

  //   return () => {
  //     setOnEndReachedCalledDuringMomentum(false);
  //   };
  // }, [page, loadingMore, loadAll]);

  const getDataDashboard = async () => {
    console.log("masuk dashboard");
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/distributor-partner/dashboard`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data;
      // console.log('DASHBOARD ', JSON.stringify(data.data));
      // console.log('TEST ', data.data.order_count);
      // const dataTotal = response.data.data.total;
      setNew_order(data.data.new_order);
      setNew_order_count(data.data.new_order_count);
      setProducts(data.data.products);
      setProducts_count(data.data.products_count);
      setOrder(data.data.order);
      setOrder_count(data.data.order_count);
      setTotal_trans(data.data.total_trans);
      setTotal_trans_today(data.data.total_trans_today);
      setThis_month(data.data.this_month);
      setThis_weeks(data.data.this_weeks);
      setToday(data.data.today);
      setUserDistributor(data.data.data_user);
      setLoadingApi(false);
    } catch (error) {
      setLoadingApi(false);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error=======DATA LOAD=================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
      }
    }
  };

  async function getDataLoadAll() {
    console.log("masuk load all");
    // this.setState({loadingApi: true});
    console.log("page", page);
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/load-all?page=${page}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      // console.log("DATA LOAD ALL ", JSON.stringify(data));
      // const dataTotal = response.data.data.total;
      setRecomenProduct(
        page === 1
          ? data.products_recomen[0].data
          : [...recomenProduct, ...data.products_recomen[0].data]
      );
      setLoadingMore(data.products_recomen[0].last_page > page);
      setMaxPage(data.products_recomen[0].last_page);
      setCategories(data.categories[0]);
      setBanners(data.banners[0]);
      setPromo(data.promo[0].data);
      setProducts_newest(data.products_newest[0].data);
      setProducts_popular(data.products_popular[0].data);
      setProducts_recent(data.products_recent[0].data);
      setProducts_promo(data.products_promo[0].data);
      setQtyTotalNew(data.products_newest[0].total);
      setQtyTotalPopular(data.products_popular[0].total);
      // setQtyTotalPromo(data.products_promo[0].total);
      setLoadingApi(false);
    } catch (error) {
      setLoadingApi(false);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error=======DATA LOAD=================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        setAlertData("Waktu sesi anda sudah berakhir");
        setAlertJudul("Pemberitahuan");
        setOpen(true);
      }
    }
  }

  async function addPage() {
    console.log("masuk addPage load all");
    // this.setState({loadingApi: true});
    console.log("page addPage", page);
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/load-all?page=${page}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      console.log("DATA addPage LOAD ALL ", JSON.stringify(data));
      // const dataTotal = response.data.data.total;
      setRecomenProduct(
        page === 1
          ? data.products_recomen[0].data
          : [...recomenProduct, ...data.products_recomen[0].data]
      );
      setLoadingMore(data.products_recomen[0].last_page + 1 > page);
      setMaxPage(data.products_recomen[0].last_page);
      setLoadingApi(false);
    } catch (error) {
      setLoadingApi(false);
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error=======DATA LOAD=================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
      }
    }
  }

  //load more data
  async function getMoreData() {
    console.log("cekk", !onEndReachedCalledDuringMomentum);
    console.log("cekk", page);
    console.log("cekk", maxPage);
    if (!onEndReachedCalledDuringMomentum && page < maxPage) {
      console.log("masuk load");
      setPage(page + 1);
      setLoadingMore(page < maxPage);
      console.log("ini ketiga", page, loadingMore);
    }
    setOnEndReachedCalledDuringMomentum(page >= maxPage);
  }

  function handleRefresh() {
    setPage(1);
    setRefreshing(true);
    getDataLoadAll();
  }

  function handleLoadMore() {
    console.log("cekkkkk saiki", !loadingMore);
    if (!loadingMore) return null;
    return (
      <div>
        <CircularProgress color="success" />
      </div>
    );
  }

  function getNavigasiCategories(item) {
    console.log("masuk navigasi", item);
    if (item.id == 1) {
      dispatch(screenAction("DetailHerbal", "screenName"));
      navigate("/produk");
    } else if (item.id == 2) {
      dispatch(screenAction("DetailSupMul", "screenName"));
      navigate("/produk");
    } else if (item.id == 3) {
      dispatch(screenAction("DetailFoodBev", "screenName"));
      navigate("/produk");
    } else if (item.id == 4) {
      dispatch(screenAction("DetailMinyak", "screenName"));
      navigate("/produk");
    }
  }

  function getNavigasiBanners(item, index) {
    if (index == 0) {
      dispatch(screenAction("HerbalPromo", "screenName"));
      dispatch(paramsAction(item.id, "idPromo"));
      navigate("/produk/list");
    } else if (index == 1) {
      dispatch(screenAction("SupMulPromo", "screenName"));
      dispatch(paramsAction(item.id, "idPromo"));
      navigate("/produk/list");
    } else if (index == 2) {
      dispatch(screenAction("FoodBevPromo", "screenName"));
      dispatch(paramsAction(item.id, "idPromo"));
      navigate("/produk/list");
    } else if (index == 3) {
      dispatch(screenAction("MinyakPromo", "screenName"));
      dispatch(paramsAction(item.id, "idPromo"));
      navigate("/produk/list");
    }
  }

  async function getNotifAll() {
    console.log("cek hit notif");
    try {
      let response = await axios.get(
        `${CONFIG.BASE_URL}/api/notification-all`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data.data;
      // console.log('DATA TEST', data.total_chat);
      setNotifOrders(data.total_order);
      setNotifMessages(data.total_chat);
      setNotifComplaints(data.total_complaint);
      setNotifSubscribe(data.total_subscribe);
      setNotifBroadcast(data.total_broadcast);
      setCountNotifCart(data.total_cart);
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========NOTIF================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
      }
    }
  }

  const pull_data_search = (data) => {
    console.log(data); // ambil data search
  };

  async function postRecent(item) {
    console.log("masuk");
    console.log("postRecent", JSON.stringify(item));
    try {
      let response = await axios.post(
        `${CONFIG.BASE_URL}/api/recent/products`,
        {
          product_id: item.id,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      // let data = response.data.data.data;
      // const dataTotal = response.data.data.total;
      console.log("postRecent", response.data.data);
      handleRefresh();
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error==========POST RECENT==============",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
      }
    }
  }

  async function postShoppingCart(item) {
    const brand_id_1 = ["001"];
    const brand_id_2 = [
      "002",
      "003",
      "004",
      "007",
      "008",
      "009",
      "010",
      "011",
      "012",
      "013",
      "014",
    ];
    const brand_id_3 = ["005"];

    // console.log('price=====>',item)
    let price = item.price.harga_ritel_gt ?? "0";
    if (brand_id_1.includes(item.brand_id)) {
      if (item.status_promosi_coret == 1) {
        // console.log('if 1');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          default:
            break;
        }
      } else if (
        (item.status_promosi_coret !== "0" ||
          item.status_promosi_coret !== null) &&
        item.status_herbana !== "1"
      ) {
        // console.log('if 2');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_ritel_gt;
            break;
          default:
            break;
        }
      } else if (item.status_herbana === "1") {
        // console.log('if 3');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_grosir_mt;
            break;
          default:
            break;
        }
      } else {
        // console.log('if 4');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_ritel_gt;
            break;
          default:
            break;
        }
      }
    } else if (brand_id_2.includes(item.brand_id)) {
      if (item.status_promosi_coret == 1) {
        // console.log('if 5');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_promosi_coret_grosir_mt;
            break;
          default:
            break;
        }
      } else if (
        item.status_promosi_coret !== "0" ||
        item.status_promosi_coret !== null
      ) {
        // console.log('if 6');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_grosir_mt;
            break;
          default:
            break;
        }
      } else {
        // console.log('if 7');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_grosir_mt;
            break;
          case "SO":
            price = item.price.harga_grosir_mt;
            break;
          case "SW":
            price = item.price.harga_grosir_mt;
            break;
          default:
            break;
        }
      }
    } else if (brand_id_3.includes(item.brand_id)) {
      if (item.status_promosi_coret == 1) {
        // console.log('if 8');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_promosi_coret_ritel_gt;
            break;
          default:
            break;
        }
      } else if (
        item.status_promosi_coret !== "0" ||
        item.status_promosi_coret !== null
      ) {
        // console.log('if 9');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_ritel_gt;
            break;
          default:
            break;
        }
      } else {
        // console.log('if 10');
        switch (dataUser.user.salur_code) {
          case "RT":
            price = item.price.harga_ritel_gt;
            break;
          case "WS":
            price = item.price.harga_ritel_gt;
            break;
          case "SO":
            price = item.price.harga_ritel_gt;
            break;
          case "SW":
            price = item.price.harga_ritel_gt;
            break;
          default:
            break;
        }
      }
    }

    console.log("price_apps", price);

    try {
      let response = await axios.post(
        `${CONFIG.BASE_URL}/api/shopping-cart`,
        {
          product_id: item.id,
          brand_id: item.brand_id,
          satuan_online: item.satuan_online,
          konversi_sedang_ke_kecil: item.konversi_sedang_ke_kecil,
          qty_konversi: shoppingCartItems.qty * item.konversi_sedang_ke_kecil,
          qty: shoppingCartItems.qty,
          notes: shoppingCartItems.notes,
          price_apps: price ?? "0",
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = response.data;
      if (data !== "" && data["success"] == true) {
        setOpenShopping(true);
        setLoadAll(true);
        setPage(1);
      } else {
        console.log("Gagal memasukkan keranjang===>", data);
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
      }
    }
  }

  const handleClickOpenShopping = () => {
    setOpenShopping(true);
  };

  const handleCloseShopping = (e) => {
    setOpenShopping(false);
    if (e == "ok") {
      navigate("/keranjang");
    }
  };

  const simpanSearch = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async (e) => {
    setOpen(false);
    if (alertData == "Waktu sesi anda sudah berakhir") {
      navigate("/login");
    }
  };

  if (!localStorage.getItem("token")) {
    return <Navigate to={"/login"} />;
  }

  const getWhatsappNumber = async () => {
    try {
      let response = await axios.get(`${CONFIG.BASE_URL}/api/wa/number`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      // console.log('response===>', JSON.stringify(response, null, 2));
      const data = response.data;
      console.log("data", data);
      if (data.data !== "" && data["success"] == true) {
        setNumberWASub(data.data[0]);
      } else {
        setAlertData("Gagal ambil data whatsapp");
        setAlertJudul("Pemberitahuan");
        setOpen(true);
      }
    } catch (error) {
      let error429 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 429";
      let errorNetwork =
        JSON.parse(JSON.stringify(error)).message === "Network Error";
      let error400 =
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 400";
      console.log(
        "Cek Error========================",
        JSON.parse(JSON.stringify(error)).message
      );
      if (error429) {
        setAlertServerSibuk(true);
      } else if (errorNetwork) {
        setAlertInet(true);
      } else if (error400) {
        localStorage.removeItem("token");
        // navigate('/home')
      }
    }
  };

  const handleChatWhatsapp = () => {
    let url = "https://wa.me/62" + numberWASub.telp_wa;
    window.open(url);
  };
  const Content = (props) => (
    <div
      className="homeCategoryItemContainerDistributor"
      onClick={() => {
        navigate(props.onClick);
      }}
    >
      <div
        className="homeCategoryImageContainerDistributor"
        style={{ backgroundColor: props.backgroundColor }}
      >
        <img src={props.icon} />
      </div>
      <div className="homeCategoryContainerTextItem">
        <p className="homeDashboardTitleContent">{props.text_title}</p>
        <p className="homeDashboardTextContent">
          {props.count} {props.text_order}
        </p>
      </div>
    </div>
  );
  return (
    <div className="homeContainer">
      <Dialog
        alertData={alertData}
        alertJudul={alertJudul}
        handleClose={handleClose}
        open={open}
      />
      <ModalShopping
        handleCloseShopping={handleCloseShopping}
        openShopping={openShopping}
      />
      <Header
        notifMessages={notifMessages}
        notifSubscribe={notifSubscribe}
        notifOrders={notifOrders}
        notifComplaints={notifComplaints}
        notifBroadcast={notifBroadcast}
        countNotifCart={countNotifCart}
        simpanSearch={simpanSearch}
        // titleHeader="DashboardHome"
        badge={true}
        linkProfile={true}
        item={true}
      />
      <div className="homeContent">
        <div className="homeDashboardBackground">
          <p className="homeDashboardTitle">{"Halo Selamat Datang,"}</p>
          <p className="homeDashboardName">{userDistributor.name}</p>
          <div className="homeContentDashboard">
            <div className="homeDashboardContainerLastActivity">
              <p className="homeDashboardTextContent">
                {"Aktifitas terakhir "}
              </p>
              <p
                className="homeDashboardTextContent"
                style={{ color: "#E07126", marginLeft: "5px" }}
              >
                {moment(userDistributor.last_login).format("LLL")}
              </p>
            </div>
            <div className="homeDashboardContainerActivity">
              <div className="homeDashboardContainerActivityNumber">
                <p
                  className="homeDashboardTextContent"
                  style={{ color: "#6EA7DC", fontSize: "25pt" }}
                >
                  {today}
                </p>
                <p className="homeDashboardTitleContent">{"Harian"}</p>
              </div>
              <div className="homeDashboardContainerActivityNumber">
                <p
                  className="homeDashboardTextContent"
                  style={{ color: "#E0C226", fontSize: "25pt" }}
                >
                  {this_weeks}
                </p>
                <p className="homeDashboardTitleContent">{"Mingguan"}</p>
              </div>
              <div className="homeDashboardContainerActivityNumber">
                <p
                  className="homeDashboardTextContent"
                  style={{ color: "#DE7126", fontSize: "25pt" }}
                >
                  {this_month}
                </p>
                <p className="homeDashboardTitleContent">{"Bulanan"}</p>
              </div>
            </div>
            <p className="homeDashboardTitleContent">{"Pendapatan"}</p>
            <NumberFormat
              value={Math.round(total_trans_today)}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"Rp "}
              // decimalScale={2}
              fixedDecimalScale={true}
              renderText={(value) => (
                <p
                  className="homeDashboardTextContent"
                  style={{ color: "#529F45", fontSize: "19pt" }}
                >
                  {value || "Rp 00.000,-"}
                </p>
              )}
            />
          </div>
        </div>
        <div className="homeCategoryContainerDistributor">
          <Content
            onClick="/transaksi/distributor"
            backgroundColor="#E07126"
            icon={IconNewOrder}
            count={new_order_count}
            text_order="New Order"
            text_title="New Order"
          />
          <Content
            onClick="/produk/list/distributor"
            backgroundColor="#E9D415"
            icon={IconProduk}
            count={products_count}
            text_order=" Items"
            text_title="Your Product"
          />
          <Content
            backgroundColor="#529F45"
            icon={IconPostOrder}
            count={order_count}
            text_order=" Orders"
            text_title="Post Orders"
          />
          <Content
            backgroundColor="#267CE0"
            icon={IconRevenue}
            count={
              <NumberFormat
                value={Math.round(total_trans)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"Rp "}
                // decimalScale={2}
                fixedDecimalScale={true}
                renderText={(value) => (
                  <p className="homeDashboardTextContent">
                    {value || "00.000,-"}
                  </p>
                )}
              />
            }
            text_title="Total Revenue"
          />
        </div>
      </div>
      <NavbarDistributor screenName={"Home"} />
      {/* <div
        onClick={handleChatWhatsapp}
        className="styles-module_whatsappButton"
        aria-hidden="true"
      >
        <svg focusable="false" viewBox="0 0 24 24" width="55" height="55">
          <path d="M16.75 13.96c.25.13.41.2.46.3.06.11.04.61-.21 1.18-.2.56-1.24 1.1-1.7 1.12-.46.02-.47.36-2.96-.73-2.49-1.09-3.99-3.75-4.11-3.92-.12-.17-.96-1.38-.92-2.61.05-1.22.69-1.8.95-2.04.24-.26.51-.29.68-.26h.47c.15 0 .36-.06.55.45l.69 1.87c.06.13.1.28.01.44l-.27.41-.39.42c-.12.12-.26.25-.12.5.12.26.62 1.09 1.32 1.78.91.88 1.71 1.17 1.95 1.3.24.14.39.12.54-.04l.81-.94c.19-.25.35-.19.58-.11l1.67.88M12 2a10 10 0 0 1 10 10 10 10 0 0 1-10 10c-1.97 0-3.8-.57-5.35-1.55L2 22l1.55-4.65A9.969 9.969 0 0 1 2 12 10 10 0 0 1 12 2m0 2a8 8 0 0 0-8 8c0 1.72.54 3.31 1.46 4.61L4.5 19.5l2.89-.96A7.95 7.95 0 0 0 12 20a8 8 0 0 0 8-8 8 8 0 0 0-8-8z"></path>
        </svg>
      </div> */}
      <Loading loadingApi={loadingApi} />
      {alertServerSibuk ? alertBusy() : null}
      {alertInet ? alertInternet() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardHome);
